import { ReactElement } from "react";
import styled from "styled-components";

/* eslint-disable-next-line */
export interface KeyInformationTextIconProps {
  icon: ReactElement;
  iconText: string;
}

const StyledOemBannerIcon = styled.span`
  display: flex;
  align-items: center;
`;
const StyledOemBannerIconText = styled.span`
  font-weight: 500;
  margin-left: 8px;
`;

export const KeyInformationTextIcon = ({
  icon,
  iconText
}: KeyInformationTextIconProps) => {
  return (
    <StyledOemBannerIcon>
      {icon}
      <StyledOemBannerIconText>{iconText}</StyledOemBannerIconText>
    </StyledOemBannerIcon>
  );
};

export default KeyInformationTextIcon;
