import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "cx";
import {
  AboutConnectResultListItem,
  getAppVersion,
  getBrowserInfo,
  getLDInformation,
  getMemoryInformation,
  getServerName
} from "./about-connect-helper";
import AboutConnectItem from "./about-connect-item";
import { CopyIcon } from "./copy-icon";
import { AboutConnectButtonProps } from "../button/about-connect-button";
import { dateFormatUTC } from "@vinsolutions/ccrm/util";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { SetupStatus } from "@vinsolutions/ccrm/interfaces";

export interface AboutConnectModalProps extends AboutConnectButtonProps {
  currentAppVersion: string | null;
  onHide: () => void;
}

const StyledAboutConnectModalContent = styled.div`
  font-weight: 800;
  font-size: 14pt;
  color: #16171a;
  font-family: Roboto, sans-serif;
  #account-menu-about-connect-modal-buttons {
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    #account-menu-about-connect-modal-buttons-copy {
      padding: 0;
      svg {
        vertical-align: middle;
      }
    }
  }
`;

const textSetupStatus: { [key: string]: string } = {
  [SetupStatus.InSetup]: "In setup",
  [SetupStatus.Inactive]: "Inactive"
};

function getDealerStatusText(dealerStatus: SetupStatus): string {
  if (
    dealerStatus !== SetupStatus.InSetup &&
    dealerStatus !== SetupStatus.Inactive
  ) {
    return "Active";
  }
  return textSetupStatus[dealerStatus];
}

export function AboutConnectModal({
  bridgeUsername,
  cardashboardServerName,
  currentAppVersion,
  dealerId,
  dealerName,
  dealerStatus,
  userFullName,
  userName,
  userRole,
  onHide
}: AboutConnectModalProps) {
  const [aboutConnectResultList, setAboutConnectResultList] = useState<
    AboutConnectResultListItem[] | null
  >(null);
  const ldClient = useLDClient();
  useEffect(() => {
    const _results = [] as AboutConnectResultListItem[];
    const actualDate = new Date();
    _results.push({
      title: "Date/Time",
      value: dateFormatUTC(actualDate),
      isoDate: actualDate.toISOString()
    });

    if (dealerId) {
      _results.push({ title: "Dealer Id", value: dealerId.toString() });
    }
    if (dealerName) {
      _results.push({ title: "Dealer Name", value: dealerName });
    }
    if (dealerStatus) {
      _results.push({
        title: "Dealer Status",
        value: getDealerStatusText(dealerStatus)
      });
    }
    if (userFullName) {
      _results.push({ title: "User Full Name", value: userFullName });
    }
    if (userName) {
      _results.push({ title: "Username", value: userName });
    }
    if (userRole) {
      _results.push({ title: "User Role", value: userRole });
    }
    if (bridgeUsername) {
      _results.push({ title: "Bridge ID", value: bridgeUsername });
    }

    getBrowserInfo(_results);

    getAppVersion(_results, currentAppVersion);

    getServerName(_results, cardashboardServerName);

    _results.push({
      title:
        "The information below is there to provide additional information used for" +
        " troubleshooting purposes",
      value: "",
      hideOnModal: true
    });

    getMemoryInformation(_results);

    getLDInformation(ldClient, _results);

    setAboutConnectResultList(_results);
  }, [
    bridgeUsername,
    cardashboardServerName,
    currentAppVersion,
    dealerId,
    dealerName,
    ldClient,
    userFullName,
    userName,
    userRole
  ]);

  const copyToClipboard = () => {
    function fallbackCopyTextToClipboard(text: string) {
      const textArea = document.createElement("textarea");
      textArea.value = text;

      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand("copy");

      document.body.removeChild(textArea);
    }
    if (!aboutConnectResultList) return;
    const clipboardText = aboutConnectResultList.map(
      r => `${r.title}: ${r.value}`
    ).join(`
`);
    try {
      navigator.clipboard.writeText(clipboardText);
      return;
    } catch (ex) {
      fallbackCopyTextToClipboard(clipboardText);
      return;
    }
  };

  return (
    <StyledAboutConnectModalContent>
      {aboutConnectResultList &&
        aboutConnectResultList.map(r => (
          <AboutConnectItem
            hideOnModal={r.hideOnModal}
            isoDate={r.isoDate}
            key={r.title}
            title={r.title}
            value={r.value}
          />
        ))}
      <div id="account-menu-about-connect-modal-buttons">
        <Button
          buttonStyle="link"
          htmlId="account-menu-about-connect-modal-buttons-copy"
          onClick={copyToClipboard}
        >
          <CopyIcon />
          {"Additional information available in the copy"}
        </Button>
        <Button
          buttonStyle="primary"
          htmlId="account-menu-about-connect-modal-buttons-close"
          onClick={() => {
            onHide();
          }}
        >
          Close
        </Button>
      </div>
    </StyledAboutConnectModalContent>
  );
}

export default AboutConnectModal;
