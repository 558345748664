import { AddScriptTag } from "@vinsolutions/core-helpers";
import { environments } from "@vinsolutions/core/config";
import config from "./config";
import { getBridgeBarScript, shouldShowBridgeBar } from "./helper";
import { OidcUser } from "@vinsolutions/tenant/jwt-utils";

export interface TenantBridgeBarProps {
  environment: environments;
  odicUser: OidcUser;
  solutionGroupCode: "VIN";
  /** Not recommended: for debug purposes only */
  forceBridgeBar?: boolean;
}

export function TenantBridgeBar(props: TenantBridgeBarProps) {
  if (!shouldShowBridgeBar(props)) return <></>;
  return (
    <div id="bridge-bar-wrapper" style={{ height: config.bridgeBarHeight }}>
      <bridge-bar data-testid="bridge-bar" />
      <AddScriptTag src={config.scriptUrl} testid="TenantBridgeBarSrcScript" />
      <AddScriptTag
        script={getBridgeBarScript(props)}
        testid="TenantBridgeBarInlineScript"
      />
    </div>
  );
}

export default TenantBridgeBar;
