import { shallowEqual, useSelector } from "react-redux";
import { getDealerDashboardSliceState } from "../dealer-dashboard.slice";
import { useGetSummaryQuery, useSetEmptyDetailsQuery } from "../rtk";
import { useCallback } from "react";
import { LeadType, ResponseTimeType } from "../enums";
import { SummaryResponse } from "../models";

interface Props {
  areDependenciesLoaded: boolean;
  leadType: LeadType;
  responseTimeType: ResponseTimeType;
  userGroupId?: number;
  userId?: number;
}

export function useGetSummary({
  areDependenciesLoaded,
  leadType,
  responseTimeType,
  userGroupId,
  userId
}: Props) {
  const {
    startDate,
    endDate,
    jwt,
    dealerId,
    requestRefresh: refresh
  } = useSelector(getDealerDashboardSliceState, shallowEqual);

  const skip = useCallback(
    () => startDate.toString() === endDate.toString() || !areDependenciesLoaded,
    [startDate, endDate, areDependenciesLoaded]
  );

  const {
    currentData: summaryResponse,
    error: isError,
    isSuccess,
    isLoading,
    isFetching,
    isUninitialized
  } = useGetSummaryQuery(
    {
      jwt,
      dealerId,
      startDateLocal: startDate,
      endDateLocal: endDate,
      leadType,
      responseTimeType,
      userGroupId,
      userId,
      refresh
    },
    {
      pollingInterval: 3 * 60 * 1000,
      skip: skip()
    }
  );

  useSetEmptyDetailsQuery(
    {
      jwt,
      dealerId,
      leadType,
      startDateLocal: startDate,
      endDateLocal: endDate,
      responseTimeType,
      userId,
      userGroupId,
      summaryResponse: summaryResponse ?? ({} as SummaryResponse),
      refresh
    },
    { skip: skip() || !isSuccess || summaryResponse === undefined }
  );

  return {
    summaryResponse,
    isError,
    isSuccess:
      isSuccess &&
      areDependenciesLoaded &&
      !isLoading &&
      !isFetching &&
      !isUninitialized
  };
}
