import { configureStore } from "@reduxjs/toolkit";
import {
  DYNAMIC_HEADER_FEATURE_KEY,
  MAIN_NAVIGATION_FEATURE_KEY,
  META_DATA_FEATURE_KEY,
  NAVIGATIONITEMS_FEATURE_KEY,
  NAVIGATION_FEATURE_KEY,
  NOTIFICATIONS_FEATURE_KEY,
  PROFILE_FEATURE_KEY,
  THEME_FEATURE_KEY,
  USER_STATUS_FEATURE_KEY,
  dynamicHeaderReducer,
  mainNavigationReducer,
  metaDataReducer,
  navigationItemsReducer,
  navigationReducer,
  notificationsReducer,
  profileReducer,
  themeReducer,
  userStatusReducer
} from "@vinsolutions/ccrm/store";
import {
  CARDASHBOARD_FEATURE_KEY,
  cardashboardReducer
} from "@vinsolutions/legacy-cardashboard/store";
import {
  CcrmApi,
  defaultDashboardApi,
  versionControlApi
} from "@vinsolutions/ccrm/api";
import {
  USER_BRIDGE_STATE_FEATURE_KEY,
  userBridgeStateReducer
} from "@vinsolutions/tenant/store";
import storageSession from "redux-persist/lib/storage/session";
import { PersistConfig } from "redux-persist/es/types";
import { persistReducer, persistStore } from "redux-persist";
import {
  DEALER_DASHBOARD_FEATURE_KEY,
  DealerDashboardApi,
  dealerDashboardReducer
} from "@vinsolutions/data-access/dashboard";

const persistNavigationItemsConfig: PersistConfig<any> = {
  key: "navigationItems",
  storage: storageSession
};

const persistNavigationConfig: PersistConfig<any> = {
  key: "navigation",
  storage: storageSession
};

const persistMainNavigationConfig: PersistConfig<any> = {
  key: "mainNavigation",
  storage: storageSession
};

export const store = configureStore({
  reducer: {
    [THEME_FEATURE_KEY]: themeReducer,
    [USER_STATUS_FEATURE_KEY]: userStatusReducer,
    [NOTIFICATIONS_FEATURE_KEY]: notificationsReducer,
    [MAIN_NAVIGATION_FEATURE_KEY]: persistReducer(
      persistMainNavigationConfig,
      mainNavigationReducer
    ),
    [META_DATA_FEATURE_KEY]: metaDataReducer,
    [NAVIGATIONITEMS_FEATURE_KEY]: persistReducer(
      persistNavigationItemsConfig,
      navigationItemsReducer
    ),
    [NAVIGATION_FEATURE_KEY]: persistReducer(
      persistNavigationConfig,
      navigationReducer
    ),
    [CARDASHBOARD_FEATURE_KEY]: cardashboardReducer,
    [PROFILE_FEATURE_KEY]: profileReducer,
    [CcrmApi.reducerPath]: CcrmApi.reducer,
    [versionControlApi.reducerPath]: versionControlApi.reducer,
    [USER_BRIDGE_STATE_FEATURE_KEY]: userBridgeStateReducer,
    [DYNAMIC_HEADER_FEATURE_KEY]: dynamicHeaderReducer,
    [DEALER_DASHBOARD_FEATURE_KEY]: dealerDashboardReducer,
    [DealerDashboardApi.reducerPath]: DealerDashboardApi.reducer,
    [defaultDashboardApi.reducerPath]: defaultDashboardApi.reducer
  },
  // Additional middleware can be passed to this array
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"]
      }
    }),
    CcrmApi.middleware,
    versionControlApi.middleware,
    DealerDashboardApi.middleware,
    defaultDashboardApi.middleware
  ],
  devTools: process.env.NODE_ENV !== "production",
  // Optional Redux store enhancers
  enhancers: []
});

export const persistor = persistStore(store);
