import { Component } from "react";
// eslint-disable-next-line no-restricted-imports
import Popover from "@cx/ui/Popover";
// eslint-disable-next-line no-restricted-imports
import Button from "@cx/ui/Button";
import { DomainSavedValidationResult } from "@vinsolutions/data-access/vin.api.email";
// eslint-disable-next-line no-restricted-imports
import Alert from "@cx/ui/Alert";
// eslint-disable-next-line no-restricted-imports
import Row from "@cx/ui/Row";
// eslint-disable-next-line no-restricted-imports
import Col from "@cx/ui/Col";

class GetCopyButton extends Component<{ type: string; value: string }> {
  override render() {
    const { type, value } = this.props;
    if (!value) {
      return <span>No value to show</span>;
    }
    return (
      <span>
        <span>
          <Popover
            htmlId="popoverClick"
            popoverContent={"Copied " + value + " to clipboard"}
            trigger={["click", "outsideClick"]}
          >
            <Button
              htmlId={type + "_" + value + "_button"}
              type="button"
              value={value}
              onClick={e => {
                const text = e.target.value;
                navigator.clipboard
                  .writeText(text)
                  .then(() => {
                    // success
                  })
                  .catch(err => {
                    // failure
                  });
              }}
            >
              Copy
            </Button>
          </Popover>
        </span>
        <span>&nbsp;{value}</span>
      </span>
    );
  }
}

export class GetItem extends Component<{
  validationResult: DomainSavedValidationResult;
  gridSpacing: any;
}> {
  override render() {
    const { validationResult, gridSpacing } = this.props;
    let alert;
    if (validationResult.isValid) {
      alert = (
        <Alert htmlId={validationResult.type + "_alert"} type="success">
          {validationResult.type}
        </Alert>
      );
    } else {
      alert = (
        <Alert
          className="alert"
          htmlId={validationResult.type + "_alert"}
          type="danger"
        >
          {validationResult.type}
        </Alert>
      );
    }
    return (
      <Row className="line">
        <Col
          lg={gridSpacing.col1.lg}
          md={gridSpacing.col1.md}
          xs={gridSpacing.col1.xs}
        >
          {alert}
        </Col>
        <Col
          lg={gridSpacing.col2.lg}
          md={gridSpacing.col2.md}
          xs={gridSpacing.col2.xs}
        >
          <GetCopyButton
            type={validationResult.type}
            value={validationResult.host}
          />
        </Col>
        <Col
          lg={gridSpacing.col3.lg}
          md={gridSpacing.col3.md}
          xs={gridSpacing.col3.xs}
        >
          <GetCopyButton
            type={validationResult.type}
            value={validationResult.data}
          />
        </Col>
        <Col
          lg={gridSpacing.col4.lg}
          md={gridSpacing.col4.md}
          xs={gridSpacing.col4.xs}
        />
      </Row>
    );
  }
}
