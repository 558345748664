import styled from "styled-components";
import { useMemo } from "react";

/* eslint-disable-next-line */
export interface AmpDateProps {
  marketedDate: string;
}

const StyledAmpBannerDate = styled.span``;
const dateStringOptions: Intl.DateTimeFormatOptions = {
  month: "numeric",
  year: "numeric",
  day: "numeric"
};

export const AmpDate = ({ marketedDate }: AmpDateProps) => {
  const date = useMemo(
    () => new Date(marketedDate).toLocaleDateString("en-US", dateStringOptions),
    [marketedDate]
  );

  return <StyledAmpBannerDate>{date}</StyledAmpBannerDate>;
};

export default AmpDate;
