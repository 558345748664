import styled from "styled-components";

const StyledVinessaDate = styled.span``;

export interface VinessaDateProps {
  date?: string;
}

export const VinessaDate = ({ date }: VinessaDateProps) => {
  return <StyledVinessaDate>{date}</StyledVinessaDate>;
};

export default VinessaDate;
