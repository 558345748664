import { ReactElement } from "react";
import { KeyInformationTextIcon } from "@vinsolutions/ui/key-information-banner";

export interface CustomerEnrichmentIconProps {
  icon: ReactElement;
}

const CustomerEnrichmentIcon = ({ icon }: CustomerEnrichmentIconProps) => {
  return (
    <KeyInformationTextIcon icon={icon} iconText="Customer Intelligence" />
  );
};

export default CustomerEnrichmentIcon;
