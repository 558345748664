export const BANNER_NAME = "Amp";
export const TRY_AGAIN = "Try Again";

export const EMAIL_SENT = "EmailSent";
export const TEXT_SENT = "TextSent";
export const DIRECT_MAIL_SENT = "DirectMailSent";

export const CARDASHBOARD_RIMS_BASEPATH = "/CarDashboard/Pages/rims2.aspx";
export const CARDASHBOARD_LEAD_MANAGEMENT_BASEPATH =
  "/CarDashboard/LeadManagement/ViewEmail.aspx";
export const CARDASHBOARD_PAGES_SERVICE_BASEPATH =
  "/CarDashboard/Pages/Service/ViewEmail.aspx";
export const AMP_SIGNALS_SETTING_NAME =
  "urlSettingName=Communication.VinWFETextingBase.url";
