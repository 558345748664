/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable new-cap */

// Main repo: https://github.com/IdentityModel/oidc-client-js/wiki
// NPM used: https://github.com/AxaGuilDEv/react-oidc/tree/master/packages/context#readme.md
// Example: https://github.com/AxaGuilDEv/react-oidc/blob/master/examples/context/src/configuration.js
// https://github.com/AxaGuilDEv/react-oidc/tree/master/packages/context#readme.md
// https://github.com/AxaGuilDEv/react-oidc/tree/master/packages/context#readme.md

import React, { useMemo, useState } from "react";
import { AuthenticationProvider } from "@axa-fr/react-oidc-context";
import {
  Authenticating,
  CallbackComponentOverride,
  NotAuthenticated,
  NotAuthorized,
  SessionLostComponent
} from "./pages";
import getOidcConfiguration from "./oidcConfiguration";
import { Log, Logger } from "oidc-client";
import { FailureState } from "@vinsolutions/core/errors";
import { createLogger } from "@vinsolutions/logger";
import { environmentConfig } from "@vinsolutions/core/config";

const loggerForLogging = createLogger("Auth");

const logout = (carDashboardLogoutUrl, error): JSX.Element => {
  loggerForLogging.debug("Auth: Initializing logout.");
  return NotAuthenticated(carDashboardLogoutUrl, error);
};

export interface AuthProps {
  children: JSX.Element;
  config: environmentConfig;
}

class CustomStore {
  constructor() {}

  getItem(key: string) {
    loggerForLogging.debug(
      "CustomStore: Getting item from local storage. key: " + key
    );
    return localStorage.getItem(key);
  }

  setItem(key: string, value: any) {
    loggerForLogging.debug(
      "CustomStore: Setting item in local storage. key: " + key
    );
    localStorage.setItem(key, value);
  }

  removeItem(key: string) {
    loggerForLogging.debug(
      "CustomStore: Removing item from local storage. key: " + key
    );
    localStorage.removeItem(key);
  }

  key(index: number) {
    loggerForLogging.debug(
      "CustomStore: Getting key from local storage. index: " + index
    );
    return localStorage.key(index);
  }
}

export const Auth = ({ children, config }: AuthProps) => {
  loggerForLogging.debug("Auth: Initializing Auth.");
  const [error, setError] = useState("");
  const oidcConfiguration = useMemo(() => {
    return getOidcConfiguration(config);
  }, [config]);

  const carDashboardLogoutUrl = `${config.carDashboardUrl}/cardashboard/logout.aspx`;

  const logger = {
    info: () => {},
    error: errorMessage => {
      if (
        errorMessage === "JoseUtil._validateJwt: iat is in the future" ||
        errorMessage === "JoseUtil._validateJwt: exp is in the past"
      ) {
        setError(
          "Your computer time is invalid and you will be unable to login. Please set the correct time or contact support for assistance."
        );
      } else {
        setError(errorMessage);
      }
      loggerForLogging.error(errorMessage);
    },
    warn: () => {},
    debug: () => {}
  } as Logger;

  if (error) {
    loggerForLogging.error("Auth: Error occurred. Error: " + error);
    return (
      <FailureState
        {...{
          error,
          homepageUrl: config.root,
          isConsoleErrorEnabled: false
        }}
      />
    );
  }
  loggerForLogging.debug("Auth: Rendering Auth.");
  return (
    <AuthenticationProvider
      UserStore={CustomStore}
      authenticating={Authenticating}
      callbackComponentOverride={CallbackComponentOverride}
      configuration={oidcConfiguration}
      logger={logger}
      loggerLevel={Log.DEBUG}
      notAuthenticated={() => logout(carDashboardLogoutUrl, error)}
      notAuthorized={NotAuthorized}
      sessionLostComponent={SessionLostComponent}
    >
      {children}
    </AuthenticationProvider>
  );
};
