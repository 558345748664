import { NewRelicConfig } from "@vinsolutions/core/interfaces/third-party/newrelic";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("config");

type configType = {
  clientId: string;
  clientSecret: string;
  idsUrl: string;
  acrValues: string;
  carDashboardUrl: string;
};
enum environments {
  local = "local",
  localdev = "localdev",
  dev = "dev",
  qaOnPrem = "qaOnPrem",
  qaCDN = "qaCDN",
  staging = "staging",
  prod = "prod",
  cypress = "cypress",
  qaCDNImpersonation = "qaCDNImpersonation",
  devCoxAutoDomain = "devCoxAutoDomain",
  qaCoxAutoDomain = "qaCoxAutoDomain",
  stagingCoxAutoDomain = "stagingCoxAutoDomain",
  prodCoxAutoDomain = "prodCoxAutoDomain"
}

const envConfig: Map<string, environments> = new Map([
  ["localhost", environments.local],
  ["dev-apps.vinmanager.com", environments.dev],
  ["qa-apps.vinmanager.com", environments.qaCDN],
  ["moto.qa.vinsolutions.com", environments.qaOnPrem],
  ["staging-apps.vinmanager.com", environments.staging],
  ["apps.vinmanager.com", environments.prod],
  ["dev-vinsolutions.app.coxautoinc.com", environments.devCoxAutoDomain],
  ["qa-vinsolutions.app.coxautoinc.com", environments.qaCoxAutoDomain],
  [
    "staging-vinsolutions.app.coxautoinc.com",
    environments.stagingCoxAutoDomain
  ],
  ["vinsolutions.app.coxautoinc.com", environments.prodCoxAutoDomain]
]);

/**
 * Returns the environment name based on the hostname
 */
const getEnvironmentName = (): environments => {
  logger.debug("getEnvironmentName");
  const hostname: string = window.location.hostname;

  if (!hostname) {
    logger.error("window.location.hostname is undefined");
    throw new Error("window.location.hostname is undefined");
  }
  const env = envConfig.get(hostname);
  if (!env) {
    logger.error(`Environment not found for ${hostname}`);
    throw new Error(`Environment not found for ${hostname}`);
  }
  logger.debug(`Environment is ` + env);

  return env;
};

/**
 * Returns the config object for the current environment
 */
const getConfig = (): environmentConfig => {
  logger.debug("getConfig");
  const environmentName = getEnvironmentName();
  const configValue = config[environmentName];
  if (!configValue) {
    const msg = "Config not found for " + environmentName;
    logger.error(msg);
    throw new Error(msg);
  }
  if ("true" === window.sessionStorage.getItem("impersonationStarted")) {
    configValue.acrValues = "idp:vinconnect";
  }
  return configValue;
};

/**
 * represents the config object for the a given environment
 */
interface environmentConfig {
  clientId: string;
  clientSecret: string;
  idsUrl: string;
  acrValues: string;
  root: string;
  carDashboardUrl: string;
  LegacyProxyUrl: string;
  newRelic?: NewRelicConfig | null;
  clockSkew?: number;
  launchDarklyId?: string;
}

/**
 * represents the config object for all environments
 */
const config: { [key: string]: environmentConfig } = {
  cypress: {
    clientId: "VCTOP0000001",
    clientSecret: "8D852CC6C4B94A289F69288EF9AE5CCA",
    idsUrl: "https://dev-authentication.vinsolutions.com",
    acrValues: "idp:vinconnect",
    root: "https://localhost/vinconnect/",
    carDashboardUrl: `http://localhost:${window.location.port}`,
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b42b7d7913c0f13d98d0d"
  },
  local: {
    clientId: "VCTOP0000001",
    clientSecret: "8D852CC6C4B94A289F69288EF9AE5CCA",
    idsUrl: "https://dev-authentication.vinsolutions.com",
    acrValues: "idp:vinconnect",
    root: "https://localhost/vinconnect/",
    carDashboardUrl: "https://localhost",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    clockSkew: 3600,
    launchDarklyId: "605b42b7d7913c0f13d98d0d",
    newRelic: {
      accountID: "2755601",
      trustKey: "1190893",
      agentID: "1120033685",
      licenseKey: "NRJS-c686c3bcfdcd1bd390a",
      applicationID: "1120033685",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: ["https://localhost"]
    }
  },
  localdev: {
    clientId: "VCTOP0000001",
    clientSecret: "8D852CC6C4B94A289F69288EF9AE5CCA",
    idsUrl: "https://dev-authentication.vinsolutions.com",
    acrValues: "idp:vinconnect",
    root: "http://localdev-vinconnect.vinstickers.com",
    carDashboardUrl: "https://dev-apps.vinmanager.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b42b7d7913c0f13d98d0d",
    newRelic: {
      accountID: "2755601",
      trustKey: "1190893",
      agentID: "1120033685",
      licenseKey: "NRJS-c686c3bcfdcd1bd390a",
      applicationID: "1120033685",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: ["https://localhost"]
    }
  },
  dev: {
    clientId: "VCTOP0000001",
    clientSecret: "8D852CC6C4B94A289F69288EF9AE5CCA",
    idsUrl: "https://dev-authentication.vinsolutions.com",
    acrValues: "idp:vinconnect",
    root: "https://dev-apps.vinmanager.com/vinconnect/",
    carDashboardUrl: "https://dev-apps.vinmanager.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b42b7d7913c0f13d98d0d",
    newRelic: {
      accountID: "2755601",
      trustKey: "1190893",
      agentID: "1120033686",
      licenseKey: "NRJS-c686c3bcfdcd1bd390a",
      applicationID: "1120033686",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: [
        "https://dev-apps.vinmanager.com",
        "https://dev-vinsolutions.app.coxautoinc.com"
      ]
    }
  },
  devCoxAutoDomain: {
    clientId: "VCTOP0000001",
    clientSecret: "8D852CC6C4B94A289F69288EF9AE5CCA",
    idsUrl: "https://dev-authentication.vinsolutions.com",
    acrValues: "idp:vinconnect",
    root: "https://dev-apps.vinmanager.com/vinconnect/",
    carDashboardUrl: "https://dev-apps.vinmanager.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b42b7d7913c0f13d98d0d",
    newRelic: {
      accountID: "2755601",
      trustKey: "1190893",
      agentID: "1120033686",
      licenseKey: "NRJS-c686c3bcfdcd1bd390a",
      applicationID: "1120033686",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: [
        "https://dev-apps.vinmanager.com",
        "https://dev-vinsolutions.app.coxautoinc.com"
      ]
    }
  },
  qaOnPrem: {
    clientId: "VCTOP0000001",
    clientSecret: "B34D678D314B4A458775F6254B97A577",
    acrValues: "idp:bridge2",
    idsUrl: "https://qa-authentication.vinsolutions.com",
    root: "https://moto.qa.vinsolutions.com/vinconnect/",
    carDashboardUrl: "https://moto.qa.vinsolutions.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b4289da69c40ead952fc7",
    newRelic: {
      accountID: "2755601",
      trustKey: "1190893",
      agentID: "1120033687",
      licenseKey: "NRJS-c686c3bcfdcd1bd390a",
      applicationID: "1120033687",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: ["https://qa-vinsolutions.app.coxautoinc.com"]
    }
  },
  qaCoxAutoDomain: {
    clientId: "VCTOP0000001",
    clientSecret: "B34D678D314B4A458775F6254B97A577",
    acrValues: "idp:bridge2",
    idsUrl: "https://qa-authentication.vinsolutions.com",
    root: "https://qa-vinsolutions.app.coxautoinc.com/vinconnect/",
    carDashboardUrl: "https://qa-vinsolutions.app.coxautoinc.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b4289da69c40ead952fc7",
    newRelic: {
      accountID: "2755601",
      trustKey: "1190893",
      agentID: "1120033687",
      licenseKey: "NRJS-c686c3bcfdcd1bd390a",
      applicationID: "1120033687",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: ["https://qa-vinsolutions.app.coxautoinc.com"]
    }
  },
  qaCDN: {
    clientId: "VCTOP0000001",
    clientSecret: "B34D678D314B4A458775F6254B97A577",
    acrValues: "idp:bridge2",
    idsUrl: "https://qa-authentication.vinsolutions.com",
    root: "https://qa-apps.vinmanager.com/vinconnect/",
    carDashboardUrl: "https://qa-apps.vinmanager.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b4289da69c40ead952fc7",
    newRelic: {
      accountID: "2755601",
      trustKey: "1190893",
      agentID: "1120033687",
      licenseKey: "NRJS-c686c3bcfdcd1bd390a",
      applicationID: "1120033687",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: ["https://qa-vinsolutions.app.coxautoinc.com"]
    }
  },
  qaCDNImpersonation: {
    clientId: "VCTOP0000001",
    clientSecret: "B34D678D314B4A458775F6254B97A577",
    acrValues: "idp:vinconnect",
    idsUrl: "https://qa-authentication.vinsolutions.com",
    root: "https://qa-apps.vinmanager.com/vinconnect/",
    carDashboardUrl: "https://qa-apps.vinmanager.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b4289da69c40ead952fc7",
    newRelic: {
      accountID: "2755601",
      trustKey: "1190893",
      agentID: "1120033687",
      licenseKey: "NRJS-c686c3bcfdcd1bd390a",
      applicationID: "1120033687",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: ["https://qa-vinsolutions.app.coxautoinc.com"]
    }
  },
  staging: {
    clientId: "VCTOP0000002",
    clientSecret: "02184176850A45F39AEA573F681F18A5",
    idsUrl: "https://staging-authentication.vinsolutions.com",
    acrValues: "idp:vinconnect",
    root: "https://staging-apps.vinmanager.com/vinconnect/",
    carDashboardUrl: "https://staging-apps.vinmanager.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b42b0c4087b0ed08a3464",
    newRelic: {
      accountID: "2755601",
      trustKey: "1190893",
      agentID: "1120033688",
      licenseKey: "NRJS-c686c3bcfdcd1bd390a",
      applicationID: "1120033688",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: ["https://staging-vinsolutions.app.coxautoinc.com"]
    }
  },
  stagingCoxAutoDomain: {
    clientId: "VCTOP0000002",
    clientSecret: "02184176850A45F39AEA573F681F18A5",
    idsUrl: "https://staging-authentication.vinsolutions.com",
    acrValues: "idp:vinconnect",
    root: "https://staging-vinsolutions.app.coxautoinc.com/vinconnect/",
    carDashboardUrl: "https://staging-vinsolutions.app.coxautoinc.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b42b0c4087b0ed08a3464",
    newRelic: {
      accountID: "2755601",
      trustKey: "1190893",
      agentID: "1120033688",
      licenseKey: "NRJS-c686c3bcfdcd1bd390a",
      applicationID: "1120033688",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: ["https://staging-vinsolutions.app.coxautoinc.com"]
    }
  },
  prod: {
    clientId: "VCTOP0000001",
    clientSecret: "46FCAE8CFE1C47BE8FB40889599D119E",
    idsUrl: "https://authentication.vinsolutions.com",
    acrValues: "idp:bridge2",
    root: "https://apps.vinmanager.com/vinconnect/",
    carDashboardUrl: "https://apps.vinmanager.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b4289da69c40ead952fc8",
    newRelic: {
      accountID: "1950602",
      trustKey: "1190893",
      agentID: "575750893",
      licenseKey: "e6afbc9257",
      applicationID: "575750893",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: [
        "https://vinsolutions.app.coxautoinc.com",
        "https://apps.vinmanager.com"
      ]
    }
  },
  prodCoxAutoDomain: {
    clientId: "VCTOP0000001",
    clientSecret: "46FCAE8CFE1C47BE8FB40889599D119E",
    idsUrl: "https://authentication.vinsolutions.com",
    acrValues: "idp:bridge2",
    root: "https://vinsolutions.app.coxautoinc.com/vinconnect/",
    carDashboardUrl: "https://vinsolutions.app.coxautoinc.com",
    LegacyProxyUrl: "/CarDashboard/pages/blankpage.aspx",
    launchDarklyId: "605b4289da69c40ead952fc8",
    newRelic: {
      accountID: "1950602",
      trustKey: "1190893",
      agentID: "575750893",
      licenseKey: "e6afbc9257",
      applicationID: "575750893",
      corsUseNewRelicHeader: true,
      corsUseTraceContextHeaders: true,
      distributedTracing: true,
      corsEnabled: true,
      allowedOrigins: [
        "https://vinsolutions.app.coxautoinc.com",
        "https://apps.vinmanager.com"
      ]
    }
  }
};

export {
  environments,
  config,
  configType,
  environmentConfig,
  getConfig,
  getEnvironmentName
};
