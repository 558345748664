export const LightThemeAccountIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    height="50px"
    version="1.1"
    viewBox="0 0 50 50"
    width="50px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Artboard</title>
    <defs>
      <filter
        filterUnits="objectBoundingBox"
        height="162.5%"
        id="filter-1"
        width="162.5%"
        x="-31.2%"
        y="-31.2%"
      >
        <feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          type="matrix"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <path
        d="M19.2073171,19.2073171 C22.7432865,19.2073171 25.6097561,16.3408475 25.6097561,12.804878 C25.6097561,9.26890861 22.7432865,6.40243902 19.2073171,6.40243902 C15.6713476,6.40243902 12.804878,9.26890861 12.804878,12.804878 C12.804878,16.3408475 15.6713476,19.2073171 19.2073171,19.2073171 Z M32.0122252,27.5304878 C32.0176157,26.2789368 31.2892277,25.1403216 30.150686,24.6205793 C26.6999368,23.119023 22.9703555,22.3651424 19.2073171,22.4085366 C15.4442787,22.3651424 11.7146973,23.119023 8.26394817,24.6205793 C7.12540649,25.1403216 6.39701841,26.2789368 6.40240898,27.5304878 L6.40240898,32.0121951 L32.0122252,32.0121951 L32.0122252,27.5304878 Z"
        id="path-2"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      id="Artboard"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Group" transform="translate(2.500000, 2.500000)">
        <circle cx="22.5" cy="22.5" fill="#2B6BDD" id="Oval" r="22.5" />
        <path
          d="M19.2049513,52.9549513 C31.6313582,52.9549513 41.7049513,42.8813582 41.7049513,30.4549513 C41.7049513,18.0285444 31.6313582,7.95495129 19.2049513,7.95495129 L19.2049513,52.9549513 Z"
          fill="#2053AB"
          fillOpacity="0.4"
          id="Path"
          transform="translate(30.454951, 30.454951) rotate(-315.000000) translate(-30.454951, -30.454951) "
        />
        <g
          filter="url(#filter-1)"
          id="Icons/person"
          transform="translate(3.292683, 3.292683)"
        >
          <mask fill="white" id="mask-3">
            <use xlinkHref="#path-2" />
          </mask>
          <use fill="#FFFFFF" id="fill" xlinkHref="#path-2" />
        </g>
        <circle
          cx="22.5"
          cy="22.5"
          id="Oval"
          r="21.75"
          stroke="#2053AB"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
);
