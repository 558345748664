import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { OidcSecure } from "@axa-fr/react-oidc-context";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("SecureRoute");

export type SecureRouteProps = {
  element: React.ReactNode;
  path?: string;
};

export const SecureRoute = ({ element, path }: SecureRouteProps) => {
  logger.debug("SecureRoute", { element, path });
  return <Route element={<OidcSecure>{element}</OidcSecure>} path={path} />;
};

SecureRoute.propTypes = {
  component: PropTypes.func
};
