import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getDynamicHeaderData } from "@vinsolutions/ccrm/api";
import {
  DynamicHeaderEntity,
  DynamicHeaderState
} from "@vinsolutions/ccrm/interfaces";

export const DYNAMIC_HEADER_FEATURE_KEY = "dynamicHeader";
export const initialDynamicHeader: DynamicHeaderState = {
  dynamicHeaderLoadingStatus: "not loaded",
  dynamicHeaderError: null,
  initialLandingTarget: undefined,
  dynamicHeaderComponents: {
    account: true,
    activityBar: true,
    customerSearch: true,
    dealerSelector: true,
    logo: true,
    navigation: true,
    versionControl: true,
    userSelector: true,
    walkMe: true
  },
  dynamicMenu: {
    shouldUseDynamicMenu: false,
    dynamicMenuItems: null
  }
};

export const fetchDynamicHeader = createAsyncThunk(
  "dynamic-header/fetchStatus",
  async (args: { jwt: string; dealerId: number }) => {
    return await getDynamicHeaderData(args);
  }
);

export const dynamicHeaderSlice = createSlice({
  name: DYNAMIC_HEADER_FEATURE_KEY,
  initialState: initialDynamicHeader,
  reducers: {
    loaded: state => {
      return { ...state, dynamicHeaderLoadingStatus: "loaded" };
    },
    update: (state, action) => {
      return { ...state, ...action.payload };
    },
    pending: state => {
      return { ...state, dynamicHeaderLoadingStatus: "loading" };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(
        fetchDynamicHeader.fulfilled,
        (
          state: DynamicHeaderState,
          action: PayloadAction<DynamicHeaderEntity>
        ) => {
          return {
            ...state,
            ...action.payload,
            dynamicHeaderLoadingStatus: "loaded",
            dynamicHeaderError: null
          };
        }
      )
      .addCase(fetchDynamicHeader.pending, (state: DynamicHeaderState) => {
        state.dynamicHeaderLoadingStatus = "loading";
        state.dynamicMenu = initialDynamicHeader.dynamicMenu;
      })
      .addCase(
        fetchDynamicHeader.rejected,
        (state: DynamicHeaderState, action) => {
          state.dynamicHeaderLoadingStatus = "error";
          state.dynamicHeaderError = action.error.message || null;
        }
      );
  }
});

export const dynamicHeaderReducer = dynamicHeaderSlice.reducer;

export const dynamicHeaderActions = dynamicHeaderSlice.actions;

export const getDynamicHeaderState = (rootState: any): DynamicHeaderState =>
  rootState[DYNAMIC_HEADER_FEATURE_KEY];
