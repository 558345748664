import {
  PortalComponentProps,
  usePortalsContext
} from "@vinsolutions/utility/portals";
import { FeatureKeyInformation } from "../feature-key-information";
import { useEffect } from "react";
import { CustomerContext, DealerContext } from "./PortalEventData";

/* eslint-disable-next-line */
export interface KeyInformationPortalProps extends PortalComponentProps {
  customerContext?: CustomerContext;
  dealerContext?: DealerContext;
  portalParentLocation?: string;
}

const KeyInformationPortalInterface = ({
  customerContext,
  dealerContext,
  portalId,
  portalParentLocation,
  portalEnabledCallback
}: KeyInformationPortalProps) => {
  const { setPortalVisibility } = usePortalsContext();

  useEffect(() => {
    setPortalVisibility(portalId, true);
    if (portalEnabledCallback) {
      portalEnabledCallback(true);
    }
  }, [portalId, portalEnabledCallback, setPortalVisibility]);

  return (
    <div>
      <FeatureKeyInformation
        activeLeadIds={customerContext?.ActiveLeadIds}
        customerId={customerContext?.CustomerId}
        dealerId={dealerContext?.DealerId || 0}
        //
        gtmEventLocationParent={portalParentLocation}
        selectedLeadId={customerContext?.SelectedLeadId}
      />
    </div>
  );
};

export default KeyInformationPortalInterface;
