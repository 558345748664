import React, { Suspense } from "react";
import { loadRemoteModule } from "@vinsolutions/utility/module-federation";
// core-components should be moved to ui_v2
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LoadingIndicator } from "@vinsolutions/core-components";
import ModuleErrorBoundary from "../error-boundary/module-error-boundary";
import { UnifiedInboxDefaultTab } from "../buttons/customer-dashboard-communications-button";

const UnifiedInbox = React.lazy(() => {
  return loadRemoteModule("communications", "./UnifiedInbox");
});

export interface UnifiedInboxModuleProps {
  consumerId: string;
  customerSystem: "COMMON" | "VIN";
  defaultReplyText?: string;
  defaultTab?: UnifiedInboxDefaultTab;
  disableReply: boolean;
  environment: "prod" | "nonprod";
  selectedLeadId: string;
  title?: string;
  errorCallback: (errorMessage: string) => void;
  getJwtOverride: () => Promise<string>;
}

export function UnifiedInboxModule({
  consumerId,
  customerSystem,
  defaultReplyText,
  defaultTab,
  disableReply,
  environment,
  selectedLeadId,
  title,
  errorCallback,
  getJwtOverride
}: UnifiedInboxModuleProps) {
  return (
    <ModuleErrorBoundary
      errorCallback={errorCallback}
      // need product input on the error message
      errorMessage="The Inbox is not available at this time. Please try again later."
      moduleName="Unified Inbox"
    >
      <Suspense
        fallback={
          <LoadingIndicator
            action="unified-inbox-loaded"
            htmlId="unified-inbox-loading"
          />
        }
      >
        <UnifiedInbox
          consumerId={consumerId}
          defaultReplyText={defaultReplyText}
          defaultTab={defaultTab}
          environment={environment}
          featureFlags={{
            DisableReply: disableReply
          }}
          idPConfig={{
            getJwtOverride,
            supportedIdP: "VIN",
            errorCallback
          }}
          selectedLeadId={selectedLeadId}
          systemOfRecordConfig={{
            customerSystem
          }}
          title={title}
        />
      </Suspense>
    </ModuleErrorBoundary>
  );
}

export default UnifiedInboxModule;
