export default function CustomerEnrichmentIconSmall() {
  return (
    <svg
      className="keyInfo-small-icon"
      height={20}
      viewBox="0 0 16 16"
      width={20}
    >
      <path
        d="M8 .32c1.065 0 2.08.217 3.002.609a2.876 2.876 0 00-.64 1.121 6.4 6.4 0 103.589 3.588 2.868 2.868 0 001.12-.64A7.68 7.68 0 118 .32zM8 8c4.117 0 3.835 3.087 3.824 3.192v.003H4.212S3.812 8 8 8zm0-4.48a1.92 1.92 0 110 3.84 1.92 1.92 0 010-3.84zM13.12.96a1.92 1.92 0 110 3.84 1.92 1.92 0 010-3.84z"
        fill="#2B6BDD"
        fillRule="nonzero"
      />
    </svg>
  );
}
