export const addLegacyRestyleEventListener = (callback: () => void) => {
  window.addEventListener(
    "storage",
    e => {
      if (e.key === "legacyRestyle") {
        callback();
      }
    },
    false
  );
};
