import styled from "styled-components";

/* eslint-disable-next-line */
export interface AmpErrorProps {
  errorMessage: string | null;
  onRetryClick: () => void;
}

const StyledAmpBannerError = styled.span`
  cursor: pointer;
  :hover {
    font-weight: 700;
  }
`;

export const AmpError = ({ errorMessage, onRetryClick }: AmpErrorProps) => {
  return (
    errorMessage && (
      <StyledAmpBannerError onClick={onRetryClick}>
        {errorMessage}
      </StyledAmpBannerError>
    )
  );
};

export default AmpError;
