/* eslint-disable @cspell/spellchecker */
export default function VinessaIconSmall() {
  return (
    <svg
      className="keyInfo-small-icon"
      height="16px"
      version="1.1"
      viewBox="0 0 16 16"
      width="16px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="Page-1"
        stroke="none"
        strokeWidth="1"
      >
        <g
          fill="#2B6BDD"
          id="key-info-list-copy"
          transform="translate(-31.000000, -445.000000)"
        >
          <g
            id="inteligence-icon-copy-8"
            transform="translate(31.000000, 445.000000)"
          >
            <path
              d="M15,4 C15.5522847,4 16,4.44771525 16,5 L16,15 C16,15.5522847 15.5522847,16 15,16 L1,16 C0.44771525,16 6.76353751e-17,15.5522847 0,15 L0,5 C-6.76353751e-17,4.44771525 0.44771525,4 1,4 L15,4 Z M12.6666667,10 L3.33333333,10 L3.33333333,13.3333333 L12.6666667,13.3333333 L12.6666667,10 Z M6,6.66666667 L3.33333333,6.66666667 L3.33333333,8 L6,8 L6,6.66666667 Z M12.6666667,6.66666667 L10,6.66666667 L10,8 L12.6666667,8 L12.6666667,6.66666667 Z M4.66666667,0 C5.0348565,4.33869274e-17 5.33333333,0.298476833 5.33333333,0.666666667 C5.33333333,1.0348565 5.0348565,1.33333333 4.66666667,1.33333333 L4,1.33333333 L4,2.66666667 L2.66666667,2.66666667 L2.66666667,1.33333333 L2,1.33333333 C1.63181017,1.33333333 1.33333333,1.0348565 1.33333333,0.666666667 C1.33333333,0.298476833 1.63181017,6.76353751e-17 2,0 L4.66666667,0 Z M14,0 C14.3681898,4.33869274e-17 14.6666667,0.298476833 14.6666667,0.666666667 C14.6666667,1.0348565 14.3681898,1.33333333 14,1.33333333 L13.3333333,1.33333333 L13.3333333,2.66666667 L12,2.66666667 L12,1.33333333 L11.3333333,1.33333333 C10.9651435,1.33333333 10.6666667,1.0348565 10.6666667,0.666666667 C10.6666667,0.298476833 10.9651435,6.76353751e-17 11.3333333,0 L14,0 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
