import { NavigationItemsEntity } from "@vinsolutions/ccrm/interfaces";
import {
  crmNavigationItemEntityIds,
  settingsNavigationItemEntityIds
} from "./navigation-ids";

const itemEntityIdsWithJavascriptUrls = [
  settingsNavigationItemEntityIds.usersManageUserGroups,
  crmNavigationItemEntityIds.email,
  crmNavigationItemEntityIds.duplicateEmail,
  crmNavigationItemEntityIds.resetEmailPassword
];

export const overrideJavascriptUrls = (
  navigationItemEntities: NavigationItemsEntity[]
) => {
  const navItemsWithJavascriptUrl = navigationItemEntities.filter(item =>
    itemEntityIdsWithJavascriptUrls.includes(item.id)
  );

  navItemsWithJavascriptUrl.forEach(item => {
    item.type = "CARDASHBOARD_JAVASCRIPT_URL";
  });
};
