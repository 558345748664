import { OemInsightsSummaryResponse } from "@vinsolutions/data-access/oem/vin.api.oeminsights";
import BmwMiniInsightsSummaryData from "../../interfaces/bmw-mini/bmw-mini-insights-summary-data";
import {
  getFormattedUpdatedUtcDate,
  getOemDataFromSummaryResponse
} from "../getOemDataItem";

const FALLBACK_OWNERSHIP_SUMMARY =
  "Customer has 0 current and 0 former BMW vehicles.";

export const parseBmwMiniInsights = (
  oemInsightsSummaryResponse: OemInsightsSummaryResponse | null,
  oem: "bmw" | "mini"
): BmwMiniInsightsSummaryData | null => {
  const dataItem = getOemDataFromSummaryResponse(
    oemInsightsSummaryResponse,
    oem
  );
  if (dataItem) {
    const parsedItem = JSON.parse(dataItem);
    return {
      likelyInMarket: parsedItem.LikelyInMarket || false,
      ownershipSummary:
        parsedItem.OwnershipSummary || FALLBACK_OWNERSHIP_SUMMARY,
      updatedDate: getFormattedUpdatedUtcDate(parsedItem.UpdatedUtc)
    };
  }
  return null;
};
