import { SubmitButton, toast } from "cx";
import { useDMCContext } from "../../hooks/useDmcContext.hook";
import { deleteDealerManufacturerCode } from "../../services/dealerManufacturerCodes.service";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useState } from "react";
import { DMCDeleteParams } from "../../types/dmcParams.type";
import { DMCActionEnum } from "../../Enums/dmcAction.enum";

export const DMCDelete = ({ onCancelClickHandler }: DMCDeleteParams) => {
  const {
    state: { dealerManufacturerCode, dealerManufacturerCodes },
    dispatch
  } = useDMCContext();
  const {
    oidcUser: { access_token: accessToken }
  } = useReactOidc();
  const [loading, setLoading] = useState(false);

  const showToastMsg = (hasError: boolean, message: string) => {
    if (hasError) {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  const onDeleteClickHandler = async () => {
    setLoading(true);

    const { hasError, message } = await deleteDealerManufacturerCode(
      accessToken,
      dealerManufacturerCode.id
    );

    if (!hasError) {
      if (dispatch) {
        dispatch({
          payload: { dealerManufacturerCode, dealerManufacturerCodes },
          type: DMCActionEnum.DMC_DELETE
        });
      }
    }

    setLoading(false);
    showToastMsg(hasError, message);
    onCancelClickHandler();
  };

  return (
    <SubmitButton
      data-testid={`dealer-manufacturer-codes-button-delete-${dealerManufacturerCode.id}`}
      htmlId={`dealer-manufacturer-codes-button-delete-${dealerManufacturerCode.id}`}
      isLoading={loading}
      loadingText="Deleting"
      onClick={onDeleteClickHandler}
    >
      Delete
    </SubmitButton>
  );
};
