const generateTimerLabel = (timeLeft: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  return timeLeft.days > 0
    ? "days"
    : timeLeft.hours > 0
    ? "hours"
    : timeLeft.minutes > 0
    ? "minutes"
    : timeLeft.seconds > 0
    ? "seconds"
    : "a moment";
};

export default generateTimerLabel;
