import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  AutoTraderInfo,
  getAutoTraderInfo
} from "@vinsolutions/data-access/lead/vin.crm.opportunityattributes";
import { KeyInformationBanner } from "@vinsolutions/ui/key-information-banner";
import { useCallback, useEffect, useState } from "react";
import AutoTraderDate from "./components/autotrader-date";
import { AutoTraderSummary } from "./components/autotrader-summary";
import AutoTraderIconSmall from "./icons/autotrader-icon-small";
import AutoTraderBannerProps from "./interfaces/autotrader-banner-props";
import AutoTraderIcon from "./components/autotrader-icon";
import { LaunchDarklyVinconnect } from "@vinsolutions/core/third-party/launch-darkly";

const bannerName = "AutoTrader";

export const AutoTraderBaseDisplayedBanner = {
  bannerName,
  iconName: "AutoTraderIconSmall",
  thumbnailName: "AutoTraderIconSmall",
  thumbnailIcon: <AutoTraderIconSmall />
};

export function AutoTraderBanner({
  customerId,
  onInitialize,
  onLoad
}: AutoTraderBannerProps) {
  // State
  const [autoTraderInfo, setAutoTraderInfo] = useState<
    AutoTraderInfo | null | undefined
  >(null);

  const jwt = useReactOidc().oidcUser.access_token;
  const bannerEnabled = LaunchDarklyVinconnect.useVinconnectFlagEnabled(
    "nx.vinconnect.key-information.autotrader"
  );

  const loadAutoTraderInfo = useCallback(async () => {
    const autoTraderInfo = bannerEnabled
      ? await getAutoTraderInfo(customerId, jwt)
      : null;

    setAutoTraderInfo(autoTraderInfo);
    const isDisplayed = !!autoTraderInfo;
    onLoad({ ...AutoTraderBaseDisplayedBanner, customerId, isDisplayed });
  }, [bannerEnabled, customerId, jwt, onLoad]);

  useEffect(() => {
    onInitialize?.(1);
    loadAutoTraderInfo();
  }, [loadAutoTraderInfo, onInitialize]);

  return (
    autoTraderInfo && (
      <KeyInformationBanner
        bannerDataDetail={<AutoTraderDate date={autoTraderInfo.date} />}
        bannerIcon={<AutoTraderIcon />}
        bannerName={bannerName}
        bannerSummary={
          <AutoTraderSummary
            badgeText={autoTraderInfo.details}
            summaryText={autoTraderInfo.service}
          />
        }
      />
    )
  );
}

export default AutoTraderBanner;
