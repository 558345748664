import { useMemo } from "react";
import styled from "styled-components";

export interface CustomerEnrichmentModalContentProps {
  customerId?: number;
  dealerId?: number;
}

export const CARDASHBOARD_RIMS_BASEPATH = "/CarDashboard/Pages/rims2.aspx";
export const CUSTOMER_INTELLIGENCE_SETTING_NAME =
  "Vin.Edison.CustomerIntelligence.KeyInfoModal.Url";

const StyledCustomerEnrichmentModalContent = styled.iframe`
  width: 100%;
  height: 900px;
  border: none;
`;

const CustomerEnrichmentModalContent = ({
  customerId,
  dealerId
}: CustomerEnrichmentModalContentProps) => {
  const iframeUrl = useMemo(() => {
    return customerId
      ? `${CARDASHBOARD_RIMS_BASEPATH}?urlSettingName=${CUSTOMER_INTELLIGENCE_SETTING_NAME}&customerId=${customerId}&dealerId=${dealerId}`
      : "";
  }, [customerId, dealerId]);

  return (
    iframeUrl && (
      <StyledCustomerEnrichmentModalContent
        id="CustomerEnrichmentKeyInfoIframe"
        src={iframeUrl}
        title="Customer-Intelligence-Modal"
      />
    )
  );
};

export default CustomerEnrichmentModalContent;
