import { Portals } from "@vinsolutions/utility/portals";
import VinConnectPortalEventChannels from "./vinconnect-portal-event-channels";
import VinconnectPortalFactoryRegistry from "./vinconnect-portal-factory-registry";
import RegisterVinconnectReactPortals from "./vinconnect-portal-register";

declare global {
  interface Window {
    RegisterVinconnectReactPortals: typeof RegisterVinconnectReactPortals;
  }
}

export const VinconnectPortals = () => {
  // define the vinconnect portal registration function
  if (window && window.top && !window.top.RegisterVinconnectReactPortals) {
    window.top.RegisterVinconnectReactPortals = RegisterVinconnectReactPortals;
  }
  // grab non-component specific styles from the current document to apply to created portal containers
  const portalStyles = Array.from(
    document?.head?.getElementsByTagName("style") || []
  ).filter(
    style =>
      !style.hasAttribute("data-emotion") && !style.hasAttribute("data-styled")
  );

  return (
    <Portals
      portalContainerId="vinconnect-portals"
      portalEventChannels={VinConnectPortalEventChannels}
      portalFactoryRegistry={VinconnectPortalFactoryRegistry}
      portalStyles={portalStyles}
    />
  );
};
