import { Col } from "@vinsolutions/core-cx";
import { ReactElement } from "react";
import styled from "styled-components";

/* eslint-disable-next-line */
export interface KeyInformationBannerSummaryProps {
  summary: ReactElement;
  htmlId: string;
}

const StyledKeyInformationBannerSummary = styled(Col)`
  padding-top: 16px;
  padding-bottom: 16px;
  line-height: 20px;
`;

export function KeyInformationBannerSummary({
  summary,
  htmlId
}: KeyInformationBannerSummaryProps) {
  return (
    <StyledKeyInformationBannerSummary
      htmlId={htmlId}
      sm={7}
      smPull={2}
      xs={12}
    >
      {summary}
    </StyledKeyInformationBannerSummary>
  );
}

export default KeyInformationBannerSummary;
