enum LeadType {
  ALL = 0,
  INTERNET = 1,
  WALK_IN = 2,
  PHONE = 3,
  IMPORT = 4,
  PARTS_ORDER = 5,
  SERVICE = 6,
  WEBSITE_CHAT = 7,
  WHOLESALE = 8,
  REFERRAL = 9,
  PREVIOUS_CUSTOMER = 10
}

export default LeadType;
