import { Col } from "@vinsolutions/core-cx";
import { ReactElement } from "react";
import styled from "styled-components";

/* eslint-disable-next-line */
export interface KeyInformationBannerIconProps {
  icon: ReactElement;
  htmlId: string;
}

const StyledKeyInformationBannerIcon = styled(Col)`
  padding-top: 16px;
  padding-bottom: 16px;
  line-height: 20px;
`;

export function KeyInformationBannerIcon({
  icon,
  htmlId
}: KeyInformationBannerIconProps) {
  return (
    <StyledKeyInformationBannerIcon htmlId={htmlId} sm={3} xs={6}>
      {icon}
    </StyledKeyInformationBannerIcon>
  );
}

export default KeyInformationBannerIcon;
