import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Profile,
  Status,
  SystemIds,
  VersionControlInformation
} from "@vinsolutions/ccrm/interfaces";
import { config } from "./endpoints";
import { getBaseUrl } from "@vinsolutions/core/environment";
import { getConfig } from "@vinsolutions/core/config";

const getBaseLegacyUrl = () => getConfig().carDashboardUrl + "/";

const legacyConfig = {
  headers: {
    timeout: 150000,
    headers: {
      Accept: `application/json; text/plain, */*`
    }
  }
};

const getVersionControlBaseUrl = () => {
  return window.location.origin.includes("localhost")
    ? "http://localhost:4400"
    : window.location.origin;
};

// https://codesandbox.io/s/rtk-query-demo-lbp7n?from-embed=&file=/src/app/services/counter.ts:274-282
export const CcrmApi = createApi({
  reducerPath: "CcrmApi",
  baseQuery: fetchBaseQuery({ baseUrl: getBaseUrl(config.baseUrls) }),
  endpoints: builder => ({
    getCrmUserInfo: builder.query<Profile, string>({
      query: (jwt: string) => ({
        url: "CrmUser/CrmUserInfo",
        headers: {
          // https://redux-toolkit.js.org/rtk-query/usage/examples#authentication
          Authorization: `Bearer ${jwt}`
        }
      })
    }),
    getStatus: builder.query<Status, void>({
      query: () => "Status"
    }),
    getSystemIds: builder.query<SystemIds, void>({
      query: () => "Version/SystemIds"
    })
  })
});

export const versionControlApi = createApi({
  reducerPath: "versionControlApi",
  baseQuery: fetchBaseQuery({ baseUrl: getVersionControlBaseUrl() }),
  endpoints: builder => ({
    getVersionControlInformation: builder.query<
      VersionControlInformation,
      void
    >({
      query: () =>
        window.location.hostname.includes("localhost") &&
        window.location.pathname.includes("vinconnect")
          ? "/vinconnect/local-version.json"
          : "/vinconnect/version.json"
    })
  })
});

export const defaultDashboardApi = createApi({
  reducerPath: "defaultDashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getBaseLegacyUrl()}CarDashboard/services/topnav.asmx`
  }),
  endpoints: builder => ({
    getDefaultDashboard: builder.query<
      {
        DashboardUrl: string;
        LeftPane: string;
      },
      { dealerId: number; userId: number }
    >({
      query: args => ({
        url: `GetDefaultManagerDashboardPageUrl?dealerId=${args.dealerId}&userId=${args.userId}`,
        headers: legacyConfig.headers.headers
      })
    })
  })
});

export const {
  useGetSystemIdsQuery,
  useGetStatusQuery,
  useGetCrmUserInfoQuery
} = CcrmApi;

export const {
  useGetVersionControlInformationQuery,
  useLazyGetVersionControlInformationQuery
} = versionControlApi;

export const { useGetDefaultDashboardQuery } = defaultDashboardApi;

export const { useLazyQuery } =
  versionControlApi.endpoints.getVersionControlInformation;
