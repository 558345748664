// TODO: Mark wrote this abomination, so he can fix it too. Only accept one type or creat overloads. Use ContentRef of iframe. Make pretty.
declare global {
  interface Window {
    getChildWindow: () => any;
  }
}

export const runCarDashboardScript = (functionString: any) => {
  try {
    let { functionName, variable } = functionString;
    if (!functionName) {
      const parsedFunctionString =
        getFunctionStringAndParamsFromString(functionString);
      functionName = parsedFunctionString.functionName;
      variable = parsedFunctionString.variable;
    }
    // TODO: Replace this with a ref to the frame
    const childWindow = window.getChildWindow();
    if (!childWindow) {
      // /* eslint-disable-next-line no-console */
      // console.error("No child window when looking for " + functionString);
      return null;
    }
    let functionReference;
    // TODO: Check if the function contains a dot, or use a function name array? That's crazy, right?
    if (functionName.startsWith("document")) {
      functionReference =
        childWindow["document"][functionName.split("document.")[1]];
    } else if (functionName.startsWith("localStorage")) {
      functionReference =
        childWindow.localStorage[functionName.split("localStorage.")[1]];
    } else {
      functionReference = childWindow[functionName];
    }
    if (typeof functionReference === "function") {
      if (functionName.startsWith("document")) {
        // get an exception when trying to use the function reference
        return childWindow["document"][functionName.split("document.")[1]](
          variable.replace(/'/g, "")
        );
      } else if (functionName.startsWith("localStorage")) {
        // get an exception when trying to use the function reference
        return childWindow["localStorage"][
          functionName.split("localStorage.")[1]
        ](...variable);
      } else {
        if (Array.isArray(variable)) {
          return functionReference(...variable);
        } else {
          return functionReference(variable);
        }
      }
    } else if (
      typeof functionReference === "object" ||
      typeof functionReference === "string"
    ) {
      return functionReference;
    }
  } catch (ex) {
    /* eslint-disable-next-line no-console */
    console.error("could not run on CarDashboard Frame", functionString, ex);
  }
  return null;
};

export const getFunctionStringAndParamsFromString = (
  functionString: string
) => {
  // TODO: This is really just used for topnav recent customers. Move this elsewhere
  /* eslint-disable-next-line no-script-url */
  const functionName = functionString.replace("javascript:top.", "");
  const regExp = /\(([^)]+)\)/;
  const matches = regExp.exec(functionName);
  let variable = "";
  if (matches) variable = matches[1];
  // TODO: Replace this with a ref to the frame
  return {
    functionName: functionName.split("(")[0],
    variable
  };
};
