import { DMCAlertParams } from "../../types/dmcParams.type";
import { StyledDMCAlert } from "./styles/dmc-alert.style";

export const DMCAlert = ({ htmlId, type, value }: DMCAlertParams) => {
  return (
    <StyledDMCAlert data-testid={htmlId} htmlId={htmlId} type={type}>
      {value}
    </StyledDMCAlert>
  );
};
