import { RightOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  getMainNavigationState,
  getThemeState,
  selectNavigationEntities,
  selectNavigationItemsEntities
} from "@vinsolutions/ccrm/store";
import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";
import { StyledNavigationMenu } from "../navigation-buttons";
import { getButtonToRender } from "./get-button-to-render";
import { ItemType } from "antd/es/menu/hooks/useItems";

export function NavigationMenu() {
  const navigationDictionary = useSelector(selectNavigationEntities);
  const { activeTabId } = useSelector(getMainNavigationState);
  const navigationItemsDictionary = useSelector(selectNavigationItemsEntities);
  const { secondary, aboveSecondary } = useSelector(getThemeState);

  const mapItems = useCallback(
    (itemsToMap: NavigationEntity[]): ItemType[] => {
      return itemsToMap.map(({ id, items }) => {
        const { label, target, url, type } = navigationItemsDictionary[
          id
        ] as NavigationItemsEntity;

        if (items && items.length > 0) {
          // return { key: id, label, children: mapItems(items) };
          return {
            key: id,
            label: <span id={id}>{label}</span>,
            children: mapItems(items)
          };
        } else {
          return {
            key: id,
            label: getButtonToRender(type, url, label, target, id)
          };
        }
      });
    },
    [navigationItemsDictionary]
  );

  const generateSubMenu = useCallback(() => {
    const currentNavigationEntity = navigationDictionary[activeTabId || ""];
    return currentNavigationEntity && currentNavigationEntity.items
      ? mapItems(currentNavigationEntity.items)
      : [];
  }, [navigationDictionary, activeTabId, mapItems]);

  return (
    <StyledNavigationMenu {...{ aboveSecondary, secondary }}>
      <Menu
        className="clear"
        expandIcon={<RightOutlined />}
        id="navigation-sub-menus"
        items={generateSubMenu()}
        mode="horizontal"
        subMenuCloseDelay={0.25}
      />
    </StyledNavigationMenu>
  );
}

export default NavigationMenu;
