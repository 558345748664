export const AMP_CRM_GATEWAY_BFF_LOCAL =
  "https://vinconnect-bff-dev.caampnp.cloud/api/";
export const AMP_CRM_GATEWAY_BFF_DEV =
  "https://vinconnect-bff-dev.caampnp.cloud/api/";
export const AMP_CRM_GATEWAY_BFF_QA =
  "https://vinconnect-bff-qa.caampnp.cloud/api/";
export const AMP_CRM_GATEWAY_BFF_STAGING =
  "https://vinconnect-bff-staging.caampnp.cloud/api/";
export const AMP_CRM_GATEWAY_BFF_PRODUCTION =
  "https://vinconnect-bff.caamp.cloud/api/";

export const SUMMARY = "This customer received an AMP marketing event";
export const ERROR_SUMMARY =
  "Something went wrong and we were unable to load the information";
export const TRY_AGAIN_CODE = "try_again";
export const TRY_AGAIN = "Try Again";
