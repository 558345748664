/* eslint-disable @cspell/spellchecker */
import { envEnum } from "@vinsolutions/core/environment";
import { KbbIcoInfo } from "../models/kbbico-info.model";

export const mockKbbIcoInfoResponses = {
  [envEnum.LOCAL]: {
    offerGuid: "b2bd42cc-b87e-4ecd-adac-866c88893b75",
    dealerGuid:
      "%242a%2411%24vLYUgYESxN3aDcAeEtIUF.g9pEbCasMtuCjaBXt1DURrUyLPXgznC",
    offerPrice: 26000,
    date: new Date().getTime(),
    env: envEnum.LOCAL
  } as KbbIcoInfo,

  [envEnum.DEV]: {
    offerGuid: undefined,
    dealerGuid: undefined,
    offerPrice: undefined,
    date: new Date().getTime(),
    env: envEnum.DEV
  } as KbbIcoInfo
};
