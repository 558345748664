import { TimeBreakdown } from "@vinsolutions/ccrm/interfaces";
import calculateTimeDifference from "./calculate-time-difference";

export default function calculateTimeRemaining(
  changeEffectDateUtc: Date
): TimeBreakdown {
  const difference = calculateTimeDifference(changeEffectDateUtc);
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / 1000 / 60) % 60);
  const seconds = Math.floor((difference / 1000) % 60);
  return {
    days,
    hours,
    minutes,
    seconds
  };
}
