import styled from "styled-components";
import HyundaiGenesisInsightsSummaryData from "../../interfaces/hyundai-genesis/hyundai-genesis-insights-summary-data";
import HyundaiGenesisConciergeUpdates from "./hyundai-genesis-concierge-updates";
import HyundaiGenesisOffersCount from "./hyundai-genesis-offers-count";
import HyundaiGenesisPreferredLanguage from "./hyundai-genesis-preferred-language";

export interface HyundaiGenesisInsightsSummaryProps {
  hyundaiGenesisInsights: HyundaiGenesisInsightsSummaryData;
}

const StyledGenesisInsightsSummary = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
`;

export const HyundaiGenesisInsightsSummary = ({
  hyundaiGenesisInsights: {
    conciergeMessagesCount,
    offersCount,
    preferredLanguage
  }
}: HyundaiGenesisInsightsSummaryProps) => {
  return (
    <StyledGenesisInsightsSummary>
      <HyundaiGenesisConciergeUpdates
        conciergeMessagesCount={conciergeMessagesCount}
      />
      <HyundaiGenesisOffersCount offersCount={offersCount} />
      <HyundaiGenesisPreferredLanguage preferredLanguage={preferredLanguage} />
    </StyledGenesisInsightsSummary>
  );
};

export default HyundaiGenesisInsightsSummary;
