import BuyingSignalsBannerProps from "./interfaces/buying-signals-banner-props";
import { KeyInformationBanner } from "@vinsolutions/ui/key-information-banner";
import {
  InsightsSummaryResponseItem,
  getBuyingSignalsSummary
} from "@vinsolutions/data-access/workflow/crm.workflow.keyinfo.automotiveintelligence";
import { useCallback, useEffect, useState } from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import BuyingSignalsLightBulb from "./icons/buying-signals-lightbulb";
import BuyingSignalsLightBulbInverse from "./icons/buying-signals-lightbulb-inverse";
import BuyingSignalsDate from "./components/buying-signals-date";
import BuyingSignalsIcon from "./icons/buying-signals-icon";
import BuyingSignalsSummary from "./components/buying-signals-summary";
import KeyInformationBuyingSignalsModal from "./components/buying-signals-modal";
import BuyingSignalsIconSmall from "./icons/buying-signals-icon-small";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";

const bannerName = "Buying Signals";
export const BuyingSignalsBaseDisplayedBanner = {
  bannerName,
  iconName: "BuyingSignalsIconSmall",
  thumbnailName: "BuyingSignalsIconSmall",
  thumbnailIcon: <BuyingSignalsIconSmall />
};

export function BuyingSignalsBanner({
  customerId,
  dealerId,
  onClickGtmEvent,
  onLoad
}: BuyingSignalsBannerProps) {
  const [insightsSummary, setInsightsSummary] = useState<
    InsightsSummaryResponseItem | null | undefined
  >(null);
  const jwt = useReactOidc().oidcUser.access_token;
  const [iconBulb, setIconBulb] = useState(BuyingSignalsLightBulb);
  const [showModal, setShowModal] = useState(false);

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "BuyingSignalsBanner",
    "Loading Buying Signals"
  );

  const loadInsightsSummary = useCallback(async () => {
    const summaryResult = customerId
      ? await getBuyingSignalsSummary(dealerId, customerId, jwt)
      : null;
    setInsightsSummary(summaryResult);
    const isDisplayed = summaryResult != null;
    onLoad({
      ...BuyingSignalsBaseDisplayedBanner,
      customerId,
      isDisplayed
    });
    setInteractionAttributes({
      customerId: customerId ?? 0,
      dealerId: dealerId ?? 0,
      isDisplayed
    });
    endInteraction();
  }, [
    customerId,
    dealerId,
    jwt,
    onLoad,
    setInteractionAttributes,
    endInteraction
  ]);

  useEffect(() => {
    loadInsightsSummary();
  }, [loadInsightsSummary]);

  const onBannerClick = useCallback(() => {
    onClickGtmEvent?.(bannerName);
    setShowModal(true);
  }, [onClickGtmEvent]);

  return (
    insightsSummary && (
      <>
        <KeyInformationBanner
          bannerDataDetail={
            <BuyingSignalsDate insightsSummary={insightsSummary} />
          }
          bannerIcon={<BuyingSignalsIcon lightbulbIcon={iconBulb} />}
          bannerName={bannerName}
          bannerSummary={
            <BuyingSignalsSummary insightsSummary={insightsSummary} />
          }
          onClick={onBannerClick}
          onMouseEnter={() => setIconBulb(BuyingSignalsLightBulbInverse)}
          onMouseLeave={() => setIconBulb(BuyingSignalsLightBulb)}
        />
        <KeyInformationBuyingSignalsModal
          customerId={customerId ?? 0}
          dealerId={dealerId}
          showModal={showModal}
          onHide={() => setShowModal(false)}
        />
      </>
    )
  );
}
