import {
  DynamicHeaderMenuItem,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";

/**
 * Create a Navigation Item from a Dynamic Menu Item
 * @param entity
 */
export const createNavEntity = (
  entity: DynamicHeaderMenuItem
): NavigationItemsEntity => {
  // The target is set to _blank for the http links so the reference opens a new browser tab
  const target = entity?.url?.startsWith("http") ? "_blank" : "";

  // The url is set to # because the navigationButtons use that to indicate an invalid URL
  return {
    id: entity.id,
    url: !entity.url || entity.url.length === 0 ? "#" : entity.url,
    label: entity.label,
    target,
    type: "GENERIC_URL"
  };
};
