export interface HttpResponse<T> {
  data?: T | null;
  statusCode?: number;
  hasError: boolean;
  error: Error | null;
}

export type HttpResponseText = HttpResponse<string>;

export interface HttpRequest {
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  url: string;
  headers?: HeadersInit;
  body?: unknown | null;
}

export interface FetchWrapper {
  sendAsync<T>(request: HttpRequest): Promise<HttpResponse<T>>;
  sendAsyncText(request: HttpRequest): Promise<HttpResponseText>;
  getAsync<T>(
    url: string,
    headers?: HeadersInit,
    options?: Partial<RequestInit>
  ): Promise<HttpResponse<T>>;
  postAsync<T>(
    url: string,
    body: unknown,
    headers?: HeadersInit
  ): Promise<HttpResponse<T>>;
  postAsyncText(
    url: string,
    body: unknown,
    headers?: HeadersInit
  ): Promise<HttpResponseText>;
  putAsync<T>(
    url: string,
    body: unknown,
    headers?: HeadersInit
  ): Promise<HttpResponse<T>>;
  deleteAsync<T>(
    url: string,
    body?: unknown,
    headers?: HeadersInit
  ): Promise<HttpResponse<T>>;
  patchAsync<T>(
    url: string,
    body: unknown,
    headers?: HeadersInit
  ): Promise<HttpResponse<T>>;
}

const getAsync = async <T>(
  url: string,
  headers?: Headers,
  options?: Partial<RequestInit>
) => {
  const request: HttpRequest = {
    url,
    method: "GET",
    headers
  };

  return sendAsync<T>(request, options);
};

const postAsync = async <T>(url: string, body: unknown, headers?: Headers) => {
  const request: HttpRequest = {
    url,
    method: "POST",
    body,
    headers
  };

  return sendAsync<T>(request);
};

const postAsyncText = async (url: string, body: unknown, headers?: Headers) => {
  const request: HttpRequest = {
    url,
    method: "POST",
    body,
    headers
  };

  return sendAsyncText(request);
};

const putAsync = async <T>(url: string, body: unknown, headers?: Headers) => {
  const request: HttpRequest = {
    url,
    method: "PUT",
    body,
    headers
  };

  return sendAsync<T>(request);
};

const deleteAsync = async <T>(
  url: string,
  body?: unknown,
  headers?: Headers
) => {
  const request: HttpRequest = {
    url,
    method: "DELETE",
    body,
    headers
  };

  return sendAsync<T>(request);
};

const patchAsync = async <T>(url: string, body: unknown, headers?: Headers) => {
  const request: HttpRequest = {
    url,
    method: "PATCH",
    body,
    headers
  };

  return sendAsync<T>(request);
};

const sendAsync = async <T>(
  request: HttpRequest,
  options?: Partial<RequestInit>
) => {
  try {
    const response = await apiCall(request, options);

    const data = (await response.json()) as T;

    const result: HttpResponse<T> = {
      data,
      statusCode: response.status,
      hasError: !response.ok,
      error: !response.ok ? new Error(response.statusText) : null
    };

    return result;
  } catch (error: unknown) {
    const failureResult: HttpResponse<T> = {
      hasError: true,
      error:
        error instanceof Error ? error : new Error("Request failed to complete")
    };

    return failureResult;
  }
};

const sendAsyncText = async (
  request: HttpRequest,
  options?: Partial<RequestInit>
) => {
  try {
    const response = await apiCall(request, options);

    const data = response.ok ? await response.text() : null;

    const result: HttpResponseText = {
      data,
      statusCode: response.status,
      hasError: !response.ok,
      error: !response.ok ? new Error(response.statusText) : null
    };

    return result;
  } catch (error: unknown) {
    const failureResult: HttpResponseText = {
      hasError: true,
      error:
        error instanceof Error ? error : new Error("Request failed to complete")
    };

    return failureResult;
  }
};

async function apiCall(request: HttpRequest, options?: Partial<RequestInit>) {
  const fetchHeaders = new Headers(request.headers);
  const hasBody = request.body;
  const hasContentType = fetchHeaders.has("Content-Type");

  if (hasBody && !hasContentType) {
    fetchHeaders.set("Content-Type", "application/json");
  }

  return await fetch(request.url, {
    method: request.method,
    headers: fetchHeaders,
    body: request.body ? JSON.stringify(request.body) : null,
    credentials: "include",
    ...options
  });
}

export const fetchWrapper: FetchWrapper = {
  sendAsync,
  sendAsyncText,
  getAsync,
  postAsync,
  postAsyncText,
  putAsync,
  deleteAsync,
  patchAsync
};
