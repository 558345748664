/* eslint-disable no-restricted-imports */
import { KeyInformationBannerModal } from "@vinsolutions/ui/key-information-banner";
import VinessaModalContent from "./vinessa-modal-content";

export interface VinessaModalProps {
  customerId?: number;
  dealerId?: number;
  showModal: boolean;
  onHide: () => void;
}

const VinessaModal = ({
  customerId,
  dealerId,
  showModal,
  onHide
}: VinessaModalProps) => {
  return (
    <KeyInformationBannerModal showModal={showModal} onHide={onHide}>
      <VinessaModalContent customerId={customerId} dealerId={dealerId} />
    </KeyInformationBannerModal>
  );
};

export default VinessaModal;
