import React from "react";
import styled from "styled-components";
import { StatusSelector } from "./selector";
import { ModalDialog } from "@vinsolutions/core/cx";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getUserStatusState,
  setUserStatus,
  toggleClockedIn
} from "@vinsolutions/ccrm/store";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { AnyAction } from "redux";

/* eslint-disable-next-line */
export interface StatusModalProps {
  showStatusModal: boolean;
  toggleShowStatusModal: () => void;
}

const StyledStatusModal = styled.div``;

export function StatusModal({
  showStatusModal,
  toggleShowStatusModal
}: StatusModalProps) {
  const { loadingStatus, userClockedIn, userCurrentStatus, userIsBusy } =
    useSelector(getUserStatusState, shallowEqual);
  const dispatch = useDispatch();

  const setSelectedStatus = (currentStatus: string, userIsBusy: boolean) => {
    dispatch(
      setUserStatus({
        currentStatus,
        userIsBusy
      }) as unknown as AnyAction
    );
    toggleShowStatusModal();
  };

  const toggleClockInStatus = () => {
    dispatch(toggleClockedIn() as unknown as AnyAction);
  };
  return (
    <StyledStatusModal>
      <ModalDialog
        header={<h4>Set Status</h4>}
        htmlId="UserStatusChangerModal"
        show={showStatusModal}
        onHide={() => {
          TagManager.event({
            eventElement: "TopNav: Close Action",
            eventAction: "Clicked",
            eventValue: "Cancel"
          });
          toggleShowStatusModal();
        }}
      >
        <StatusSelector
          hasError={loadingStatus === "error"}
          isBusy={userIsBusy}
          isLoading={loadingStatus === "loading"}
          isUserClockedIn={userClockedIn}
          reactGAApplicationName="TopNav"
          savedStatus={userCurrentStatus}
          onCancelButtonClick={toggleShowStatusModal}
          onClockInClick={toggleClockInStatus}
          onSaveClickCallback={setSelectedStatus}
        />
      </ModalDialog>
    </StyledStatusModal>
  );
}

export default StatusModal;
