export const addThemeChangeEventListener = (
  callback: (currentThemeId: string) => void
) => {
  window.addEventListener(
    "storage",
    e => {
      if (e.key === "vinconnectThemeId" && e.newValue !== null) {
        const currentThemeId = e.newValue;
        callback(currentThemeId);
      }
    },
    false
  );
};
