import { Log } from "@raynode/nx-logger";
import { fetchDealListAccess } from "@vinsolutions/ccrm/store";
import crwAuthClient from "@vinsolutions/core/module-federation/auth";
import { useVinconnectFlagEnabled } from "@vinsolutions/core/third-party/launch-darkly";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";

export const useDealListAccess = (logger: Log) => {
  const dispatch = useDispatch();
  const isDealListEnabled = useVinconnectFlagEnabled(
    "nx.vinconnect.show-crw-deallist"
  );
  const requestDealListAccess = useCallback(async () => {
    // Add to improve DX so don't show the alert dev react layout when working with the proxy
    try {
      const crwAuthToken = await crwAuthClient.getAuth();
      if (crwAuthToken.length > 0) {
        dispatch(fetchDealListAccess(crwAuthToken) as unknown as AnyAction);
        logger.debug("Requested CRW Deal List access.");
      }
    } catch (err) {
      const errorMsg = (err as Error).message;
      logger.error(errorMsg);
    }
  }, [dispatch, logger]);
  useEffect(() => {
    if (isDealListEnabled) {
      requestDealListAccess();
    }
  }, [requestDealListAccess, isDealListEnabled]);
};

export default useDealListAccess;
