// eslint-disable-next-line @nx/enforce-module-boundaries
import { useCallback, useEffect } from "react";
import { ModalIds } from "./Modal/SelectModal";
import { DrawerIds } from "./SliderDrawer/SelectDrawer";

export interface DefaultDetailData {
  componentId: ModalIds | DrawerIds;
}

export const useSetupPostMsgListener = <DetailData extends DefaultDetailData>(
  eventName: string,
  onEvent: (modalInfo: DetailData) => void
) => {
  const handlerCustomEvents = useCallback((event: CustomEvent<DetailData>) => {
    if (!event.detail.componentId) {
      console.error("componentId property inside detail is required");
      return;
    }
    onEvent(event.detail);
  }, []);

  useEffect(() => {
    window.addEventListener(eventName, handlerCustomEvents as EventListener);
    return () => {
      window.removeEventListener(
        eventName,
        handlerCustomEvents as EventListener
      );
    };
  }, [handlerCustomEvents, eventName]);
};
