const titleDictionary: {
  [index: string]: { title: string; titleAbbr: string };
} = {
  activity: { title: "Activity", titleAbbr: "Activity" },
  adminAppointments: { title: "Appointments", titleAbbr: "Appts" },
  appointments: { title: "Appointments", titleAbbr: "Appts" },
  leads: { title: "New Leads", titleAbbr: "Leads" },
  showroom: { title: "Showroom Visits", titleAbbr: "Visits" },
  tasks: { title: "Tasks", titleAbbr: "Tasks" }
};

export default titleDictionary;
