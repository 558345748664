import { Badge, BadgeColor, IconComplete, IconRestrict } from "cx";
import { DMCType } from "../../../types/dmc.type";
import { DMCTableActions } from "../components/dmc-table-actions/dmc-table-actions.component";
import { DMCComponentColor } from "../../dmc-component-color/dmc-component-color.component";
import {
  ACTIVE_DISABLE_COLOR,
  ACTIVE_ENABLE_COLOR,
  INACTIVE_COLOR
} from "../../../constants/colors.constant";

const getColumnColorTextComponent = (keyValue: string, { status }: DMCType) => {
  const isActive = status === "A";

  return (
    <DMCComponentColor color={isActive ? "" : INACTIVE_COLOR}>
      {keyValue}
    </DMCComponentColor>
  );
};
const getColumnColorIconComponent = (
  keyValue: boolean,
  { status }: DMCType
) => {
  const isActive = status === "A";
  let color = INACTIVE_COLOR;

  if (isActive && !keyValue) {
    color = ACTIVE_DISABLE_COLOR;
  } else if (isActive && keyValue) {
    color = ACTIVE_ENABLE_COLOR;
  }

  return (
    <DMCComponentColor color={color}>
      {keyValue ? <IconComplete /> : <IconRestrict />}
    </DMCComponentColor>
  );
};

export const getDMCTableColumnsConfig = () => [
  {
    title: "",
    fixed: "left",
    width: 90,
    render: (_: unknown, record: DMCType) => {
      return (
        <DMCTableActions
          dealerId={record.dealerId}
          dealerInitiatedLeadDisabledUTC={record.dealerInitiatedLeadDisabledUTC}
          dealerInitiatedLeadEnabled={record.dealerInitiatedLeadEnabled}
          id={record.id}
          key={record.key}
          oemDealerId={record.oemDealerId}
          oemId={record.oemId}
          oemLeadUpdateDisabledUTC={record.oemLeadUpdateDisabledUTC}
          oemLeadUpdateEnabled={record.oemLeadUpdateEnabled}
          oemName={record.oemName}
          status={record.status}
        />
      );
    }
  },
  {
    title: "Code",
    dataIndex: "oemDealerId",
    width: 400,
    render: getColumnColorTextComponent
  },
  {
    title: "Source",
    dataIndex: "oemName",
    width: 200,
    render: getColumnColorTextComponent
  },
  {
    title: "Enable Non OEM Lead Collection",
    dataIndex: "dealerInitiatedLeadEnabled",
    className: "text-center",
    width: 250,
    render: getColumnColorIconComponent
  },
  {
    title: "Enable OEM Lead Status Update",
    dataIndex: "oemLeadUpdateEnabled",
    className: "text-center",
    width: 250,
    render: getColumnColorIconComponent
  },
  {
    title: "Status",
    dataIndex: "status",
    fixed: "right",
    render: (keyValue: string) => {
      let name = "Active";
      let color: BadgeColor = ACTIVE_ENABLE_COLOR;

      if (keyValue !== "A") {
        name = "Inactive";
        color = "gray";
      }

      return (
        <Badge color={color} htmlId={`dmc-status-${name}`}>
          {name}
        </Badge>
      );
    }
  }
];
