import styled from "styled-components";
import { Badge } from "@vinsolutions/core-cx";

export interface HyundaiGenesisPreferredLanguageProps {
  preferredLanguage: string | null;
}

const StyledHyundaiGenesisPreferredLanguage = styled.span`
  justify-self: right;
  font-weight: 700;
`;

export const HyundaiGenesisPreferredLanguage = ({
  preferredLanguage
}: HyundaiGenesisPreferredLanguageProps) => {
  return (
    preferredLanguage && (
      <StyledHyundaiGenesisPreferredLanguage>
        <Badge htmlId="hyundai-genesis-preferred-language-badge">
          {preferredLanguage} Preferred
        </Badge>
      </StyledHyundaiGenesisPreferredLanguage>
    )
  );
};

export default HyundaiGenesisPreferredLanguage;
