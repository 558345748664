import styled from "styled-components";
import { OemInsightsSummaryData } from "../interfaces/oem-insights-summary-data";

/* eslint-disable-next-line */
export interface OemInsightsDateProps {
  oemInsights: OemInsightsSummaryData;
}

const StyledOemInsightsBannerDate = styled.span``;

export const OemInsightsDate = ({
  oemInsights: { updatedDate }
}: OemInsightsDateProps) => {
  return (
    updatedDate && (
      <StyledOemInsightsBannerDate>{updatedDate}</StyledOemInsightsBannerDate>
    )
  );
};

export default OemInsightsDate;
