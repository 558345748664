import { ReactElement } from "react";
import styled from "styled-components";

export interface KbbIcoIconProps {
  kbbIcon: ReactElement;
}

const StyledKbbIcoIcon = styled.span`
  margin-top: 10px;
`;

const KbbIcoIcon = ({ kbbIcon }: KbbIcoIconProps) => {
  return <StyledKbbIcoIcon>{kbbIcon}</StyledKbbIcoIcon>;
};

export default KbbIcoIcon;
