import PortalsContextProvider from "./state/portals-context";
import PortalsContainer from "./components/portals-container";
import {
  PortalEventChannels,
  PortalFactoryRegistry
} from "./interfaces/portals";

/* eslint-disable-next-line */
export interface PortalsProps {
  portalContainerId: string;
  portalFactoryRegistry: PortalFactoryRegistry;
  portalEventChannels: PortalEventChannels;
  /** Style elements applied to created portal container elements.
   * Useful when needing to apply styles defined outside the document the portal is being rendered in */
  portalStyles?: HTMLStyleElement[] | null;
}

export function Portals({
  portalContainerId,
  portalFactoryRegistry,
  portalEventChannels,
  portalStyles
}: PortalsProps) {
  return (
    <PortalsContextProvider
      portalEventChannels={portalEventChannels}
      portalFactoryRegistry={portalFactoryRegistry}
    >
      <PortalsContainer
        portalContainerId={portalContainerId}
        portalStyles={portalStyles}
      />
    </PortalsContextProvider>
  );
}

export default Portals;
