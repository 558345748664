import { DMCType } from "../types/dmc.type";
import { fetchWrapper } from "@vinsolutions/core/http";
import { getBaseUrl } from "@vinsolutions/core/environment";
import { BASE_URL } from "../constants/url.constant";
import { DMC_TYPES } from "../constants/dmcTypes.constant";
import { DMCSaveData } from "../types/dmcSaveData.type";
import {
  ERROR_MSG,
  SUCCESS_MSG_ON_DELETE,
  SUCCESS_MSG_ON_SAVE
} from "../constants/messages.constant";

export const getDealerManufacturerCodesByDealerIdAsync = async (
  jwt: string,
  dealerID: number | null
): Promise<{ data: DMCType[]; hasError: boolean }> => {
  let dmcCollection: DMCType[] = [];

  const { data, statusCode, hasError } = await fetchWrapper.getAsync<DMCType[]>(
    `${getBaseUrl(
      BASE_URL
    )}api/Dispositions/Configure/Dmc/Dealer/${dealerID}?api-version=2`,
    {
      Authorization: `Bearer ${jwt}`,
      "content-type": "application/json"
    }
  );

  if (statusCode === 200 && data) {
    dmcCollection = data.map((dmc: DMCType) => {
      const { name } = DMC_TYPES[dmc.oemId];

      return {
        ...dmc,
        key: dmc.id || 0,
        oemName: name
      };
    });
  }

  return { data: dmcCollection, hasError };
};

export const saveDealerManufacturerCode = async (
  jwt: string,
  dmc: DMCSaveData
): Promise<{
  hasError: boolean;
  message: string;
  data: { id: number };
}> => {
  let message = SUCCESS_MSG_ON_SAVE;
  let hasError = false;
  let data = { id: 0 };

  try {
    const response = await fetch(
      `${getBaseUrl(BASE_URL)}api/Dispositions/Save/Dmc?api-version=2`,
      {
        method: "POST",
        body: JSON.stringify(dmc),
        headers: {
          Authorization: `Bearer ${jwt}`,
          "content-type": "application/json"
        },
        credentials: "include"
      }
    );
    const textResponse = await response.text();

    hasError = !response.ok;

    if (hasError) {
      message = textResponse ?? ERROR_MSG;
    } else {
      data = { id: Number(textResponse) };
    }
  } catch (error) {
    message = ERROR_MSG;
    hasError = true;
  }

  return { data, hasError, message };
};

export const deleteDealerManufacturerCode = async (
  jwt: string,
  id: number | null
) => {
  let message = "";
  let hasError = false;

  try {
    const response = await fetch(
      `${getBaseUrl(BASE_URL)}api/Dispositions/Delete/Dmc/${id}?api-version=2`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "content-type": "application/json"
        },
        credentials: "include"
      }
    );

    const data = await response.text();

    hasError = !response.ok;
    message = hasError ? data : SUCCESS_MSG_ON_DELETE;
  } catch (error) {
    message = ERROR_MSG;
    hasError = true;
  }

  return { hasError, message };
};
