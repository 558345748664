import { TagManager } from "@vinsolutions/core-third-party-gtm";

export const gtmPageReloadedByOtherWindow = (eventCallback: () => void) => {
  return TagManager.event(
    {
      eventLocation: "Top Nav Header",
      eventElement: "Version Control Notification",
      eventAction: "No Action Taken",
      eventResult: "Page Reloaded By Other Tab/Window"
    },
    undefined,
    eventCallback,
    1000
  );
};

export const gtmPageReloadedBySystem = (eventCallback: () => void) => {
  TagManager.event(
    {
      eventLocation: "Top Nav Header",
      eventElement: "Version Control Notification",
      eventAction: "No Action Taken",
      eventResult: "Page Reloaded By System"
    },
    undefined,
    eventCallback,
    1000
  );
};

export const gtmPageReloadedBySystemForCriticalUpdate = (
  eventCallback: () => void
) => {
  TagManager.event(
    {
      eventLocation: "Top Nav Header",
      eventElement: "Version Control Notification",
      eventAction: "No Action Taken",
      eventResult: "Page Reloaded By System for Critical Update"
    },
    undefined,
    eventCallback,
    1000
  );
};

export const gtmCountdownNotificationDisplayedToUser = () => {
  TagManager.event({
    eventLocation: "Top Nav Header",
    eventElement: "Version Control Notification",
    eventAction: "No Action Taken",
    eventResult: "Notification Displayed to User"
  });
};

export const gtmMultiVersionNotificationDisplayedToUser = () => {
  TagManager.event({
    eventLocation: "Top Nav Header",
    eventElement: "Version Control MultiVersion Notification",
    eventAction: "No Action Taken",
    eventResult: "Notification Displayed to User"
  });
};

export const gtmNotificationHiddenBySystem = () => {
  TagManager.event({
    eventLocation: "Top Nav Header",
    eventElement: "Version Control Notification",
    eventAction: "No Action Taken",
    eventResult: "Notification Hidden by System"
  });
};

export const gtmCountdownNotificationHiddenForRollback = () => {
  TagManager.event({
    eventLocation: "Top Nav Header",
    eventElement: "Version Control Notification",
    eventAction: "No Action Taken",
    eventResult: "Hidden by System for Rollback"
  });
};

export const gtmMultiversionNotificationHiddenForRollback = () => {
  TagManager.event({
    eventLocation: "Top Nav Header",
    eventElement: "Version Control Multiversion Notification",
    eventAction: "No Action Taken",
    eventResult: "Hidden by System for Rollback"
  });
};

export const gtmPageReloadedByUserCountdownAlert = (
  eventValue: number,
  eventCallback: () => void
) => {
  TagManager.event(
    {
      eventLocation: "Top Nav Header",
      eventElement: "Version Control Notification",
      eventAction: "Reload Buttton Clicked",
      eventResult: "Page Reloaded By User",
      eventValue
    },
    undefined,
    eventCallback,
    1000
  );
};

export const gtmPageReloadedByUserMultiversionAlert = (
  eventCallback: () => void
) => {
  TagManager.event(
    {
      eventLocation: "Top Nav Header",
      eventElement: "Version Control Multiversion Notification",
      eventAction: "Reload Buttton Clicked",
      eventResult: "Page Reloaded By User"
    },
    undefined,
    eventCallback,
    1000
  );
};
