import styled from "styled-components";
import AutoTraderSummaryBadge from "./autotrader-summary-badge";

export interface AutoTraderSummaryProps {
  summaryText?: string;
  badgeText?: string;
}

const StyledAutoTraderSummary = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
`;

const StyledAutoTraderSummaryText = styled.span``;

export function AutoTraderSummary({
  summaryText,
  badgeText
}: AutoTraderSummaryProps) {
  return (
    <StyledAutoTraderSummary>
      <StyledAutoTraderSummaryText>{summaryText}</StyledAutoTraderSummaryText>
      <AutoTraderSummaryBadge badgeText={badgeText} />
    </StyledAutoTraderSummary>
  );
}
