import { FormEvent } from "react";
import { useModalContext } from "../Modal/ModalIframe";

interface NoteModalForm extends HTMLFormControlsCollection {
  field1: HTMLInputElement;
  field2: HTMLInputElement;
}
interface FormElements extends HTMLFormElement {
  readonly elements: NoteModalForm;
}

export interface NoteProps {
  user: string;
  autoLeadId: number;
}

export const Note = () => {
  const { dispatchCloseModalEv, hideModal, componentProps } = useModalContext();
  const { user, autoLeadId } = componentProps as NoteProps;

  const sendInfo = (ev: FormEvent<FormElements>) => {
    ev.preventDefault();
    dispatchCloseModalEv({
      formInfo: {
        field1: ev.currentTarget.elements.field1.value,
        field2: ev.currentTarget.elements.field2.value
      },
      cb: () => {
        console.log("hi");
      }
    });
    hideModal();
  };

  return (
    <div>
      <h2>Modal</h2>
      <p>username: {user}</p>
      <p>autoLeadId: {autoLeadId}</p>
      <form onSubmit={sendInfo}>
        <div>
          <label htmlFor="field1">Field 1</label>
          <input name="field1" />
        </div>
        <div>
          <label htmlFor="field2">Field 2</label>
          <input name="field2" />
        </div>
        <button type="submit">test</button>
      </form>
    </div>
  );
};
