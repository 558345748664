import { useMemo } from "react";
import styled from "styled-components";

export interface KbbIcoDateProps {
  date?: number;
}

const StyledKbbIcoBannerDate = styled.span``;
const dateStringOptions: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric"
};

export const KbbIcoDate = ({ date }: KbbIcoDateProps) => {
  const dateMemo = useMemo(() => {
    const lastUpdatedDate = date ? new Date(date) : new Date();

    return lastUpdatedDate?.toLocaleDateString("en-US", dateStringOptions);
  }, [date]);

  return <StyledKbbIcoBannerDate>{dateMemo}</StyledKbbIcoBannerDate>;
};

export default KbbIcoDate;
