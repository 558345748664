import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deriveCardashboardBaseUrl,
  deriveCardashboardUrl,
  deriveHasLegacyRestyle
} from "@vinsolutions/ccrm/util";

import {
  CardashboardEntity,
  CardashboardState
} from "@vinsolutions/ccrm/interfaces";
import { NewRelic } from "@vinsolutions/core/third-party/newrelic";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("cardashboard.slice");

export const CARDASHBOARD_FEATURE_KEY = "cardashboard";

/*
 * Update these interfaces according to your requirements.
 */

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchUserStatus())
 * }, [dispatch]);
 * ```
 */
export const fetchCardashboardStatus = createAsyncThunk(
  "cardashboard/fetchStatus",
  async (_, thunkAPI) => {
    const res = {
      frameUrl: deriveCardashboardUrl(),
      frameBaseUrl: deriveCardashboardBaseUrl(),
      hasLegacyRestyle: deriveHasLegacyRestyle()
    };
    const resolved = Promise.resolve(res);
    logInteractionStarted((await resolved).frameUrl, "initial system load");
    return resolved;
  }
);

export const initialCardashboardState: CardashboardState = {
  cardashboardServerName: "",
  cardashboardPageStatus: "not loaded",
  cdInitialized: false,
  forceRefresh: 0,
  frameUrl: "",
  frameBaseUrl: "",
  hasFrameUrl: false,
  hasLegacyRestyle: false,
  viewingAs: "",
  loadingStatus: "not loaded",
  error: null
};

interface updateFrameUrlInterface {
  frameUrl: string;
  name: string;
}

export const cardashboardSlice = createSlice({
  name: CARDASHBOARD_FEATURE_KEY,
  initialState: initialCardashboardState,
  reducers: {
    forceRefresh: (state, action) => {
      logger.debug("forceRefresh", action.payload);
      return { ...state, forceRefresh: action.payload };
    },
    toggleLegacyRestyle: state => {
      logger.debug("toggleLegacyRestyle", state.hasLegacyRestyle);
      localStorage.setItem("legacyRestyle", `${!state.hasLegacyRestyle}`);
      return {
        ...state,
        hasLegacyRestyle: !state.hasLegacyRestyle,
        forceRefresh: state.forceRefresh + 1
      };
    },
    update: (state, action) => {
      logger.debug("update", action.payload);
      return { ...state, ...action.payload };
    },
    updateCardashboardPageStatus: (state, action) => {
      logger.debug("updateCardashboardPageStatus", action.payload);
      if (action.payload !== "loading") {
        logInteractionEnded();
      }
      return { ...state, cardashboardPageStatus: action.payload };
    },
    updateCardashboardServerName: (state, action) => {
      logger.debug("updateCardashboardServerName", action.payload);
      return { ...state, cardashboardServerName: action.payload };
    },
    updateFrameUrl: (state, action: { payload: updateFrameUrlInterface }) => {
      logger.debug("updateFrameUrl", action.payload);
      let url = action.payload.frameUrl;
      // Trim any whitespace and decode if necessary
      url = url.trim();
      url = url && decodeURI(url) !== url ? decodeURI(url) : url;

      logInteractionStarted(url, action.payload.name);

      // Always refresh the frame, even if it is the same url.
      return state.frameUrl === state.frameBaseUrl + url
        ? {
            ...state,
            forceRefresh: state.forceRefresh + 1,
            cardashboardPageStatus: "loading"
          }
        : {
            ...state,
            frameUrl: state.frameBaseUrl + url,
            cardashboardPageStatus: "loading"
          };
    },
    updateViewingAs: (state, action) => {
      logger.debug("updateViewingAs", action.payload);
      return { ...state, viewingAs: action.payload };
    },
    pending: state => {
      logger.debug("pending");
      return { ...state, loadingStatus: "loading" };
    },
    loaded: state => {
      logger.debug("loaded");
      return { ...state, cdInitialized: true, loadingStatus: "loaded" };
    },
    error: (state, action) => {
      const error = action.payload;
      logger.error("Some error " + error);
      return { ...state, error, loadingStatus: "error" };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCardashboardStatus.pending, (state: CardashboardState) => {
        state.hasFrameUrl = false;
      })
      .addCase(
        fetchCardashboardStatus.fulfilled,
        (
          state: CardashboardState,
          action: PayloadAction<CardashboardEntity>
        ) => {
          return { ...state, hasFrameUrl: true, ...action.payload };
        }
      )
      .addCase(
        fetchCardashboardStatus.rejected,
        (state: CardashboardState, action) => {
          state.hasFrameUrl = false;
          state.error = action.error.message || null;
        }
      );
  }
});

/*
 * Export reducer for store configuration.
 */
export const cardashboardReducer = cardashboardSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(cardashboardActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const cardashboardActions = cardashboardSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllCardashboard);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
export const getCardashboardState = (rootState: any): CardashboardState =>
  rootState[CARDASHBOARD_FEATURE_KEY];

async function logInteractionStarted(url: string, name: string) {
  logger.debug("logInteractionStarted", url, name);
  NewRelic.interaction()?.ignore().end();
  NewRelic.setCurrentRouteName(name);
  NewRelic.interaction()?.actionText(name).save();
}

async function logInteractionEnded() {
  logger.debug("logInteractionEnded");
  NewRelic.interaction()?.end();
}
