/**
 * Helper function to dynamically import a module from a given URL.
 *
 * We use this function to wrap the dynamic `import` statement so that it can be
 * easily mocked in tests. The native `import` syntax can't be directly mocked
 * with Jest, but by using this helper, we can control its behavior in a testing
 * environment.
 *
 * @param url - The URL to import the module from.
 * @returns A promise that resolves to the imported module.
 */
export async function dynamicImport(url: string): Promise<any> {
  return import(/* webpackIgnore: true */ url);
}
