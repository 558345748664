import { useLDClient } from "launchdarkly-react-client-sdk";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { useEffect } from "react";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("UpdateLdClient");

export function UpdateLdClient() {
  logger.debug("Initializing UpdateLdClient.");
  const ldClient = useLDClient();
  const {
    dealerId,
    isVinEmployee,
    userFullName,
    userRole,
    userId,
    userName,
    dealerName
  } = useSelector(getProfileState, shallowEqual);

  useEffect(() => {
    logger.debug(
      `${
        ldClient === undefined
          ? "ldClient is undefined, skipping user update"
          : "LDClient has value"
      }`
    );
    if (ldClient && userId && dealerId) {
      logger.debug("Initializing ldClient.identify.");
      const ldUserInfo = {
        key: userName,
        name: userFullName,
        custom: {
          dealerId,
          dealerName,
          userId,
          isVinEmployee,
          userRole
        }
      };
      ldClient.identify(ldUserInfo).then(r => {
        logger.debug("LaunchDarkly user identified");
      });
    }
    logger.debug("UseEffect exiting.");
  }, [
    dealerId,
    dealerName,
    isVinEmployee,
    ldClient,
    userFullName,
    userId,
    userName,
    userRole
  ]);
  return null;
}
