import React from "react";
import styled from "styled-components";
import { Button } from "@vinsolutions/core/cx";
import { DealerStatusBadge } from "../status-badge/dealer-status-badge";
import { SetupStatus } from "@vinsolutions/ccrm/interfaces";

/* eslint-disable-next-line */
export interface DealerSelectorButtonProps {
  dealerCount: number;
  dealerId: number;
  dealerName: string;
  isEmployee?: boolean;
  onClick: () => void;
  dealerStatus: SetupStatus | "";
}

const StyledButton = styled(Button)`
  border: 0;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: auto;
  outline: 0;
  padding-top: 0;
  padding-bottom: 0;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    box-shadow: none;
  }

  .ccrm-dealer-header-display-title {
    flex: 0 1 auto;
    &.ccrm-dealer-header-display-title-name {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
      padding-left: 8px;
    }
  }
`;

const statusToShow = [SetupStatus.Inactive, SetupStatus.InSetup];

export function DealerSelectorButton({
  dealerCount,
  dealerId,
  dealerName,
  isEmployee,
  onClick,
  dealerStatus
}: DealerSelectorButtonProps) {
  return (
    <StyledButton
      buttonStyle="link"
      className={dealerCount > 1 || isEmployee ? undefined : "unclickable"}
      htmlId="ccrm-header-display-button"
      onClick={onClick}
    >
      {dealerStatus && statusToShow.includes(dealerStatus) ? (
        <DealerStatusBadge status={dealerStatus} />
      ) : null}
      <span className="ccrm-dealer-header-display-title ccrm-dealer-header-display-title-name">
        {!dealerName || dealerId <= 0 ? "Dealer Unknown" : dealerName}
      </span>
      {dealerName && dealerId > 0 && (
        <span className="ccrm-dealer-header-display-title">
          {"#"}
          {dealerId}
        </span>
      )}
    </StyledButton>
  );
}

export default DealerSelectorButton;
