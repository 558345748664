import { ReactElement } from "react";
import { KeyInformationTextIcon } from "@vinsolutions/ui/key-information-banner";

export interface BuyingSignalsIconProps {
  lightbulbIcon: ReactElement;
}

const BuyingSignalsIcon = ({ lightbulbIcon }: BuyingSignalsIconProps) => {
  return (
    <KeyInformationTextIcon icon={lightbulbIcon} iconText="Buying Signals" />
  );
};

export default BuyingSignalsIcon;
