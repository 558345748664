export const enum GmIconFill {
  None = "",
  Inverse = "rgb(100%,100%,100%)"
}

export interface GmIconProps {
  fillColor: GmIconFill;
}

const GmIconSmall = ({ fillColor }: GmIconProps) => {
  return (
    <svg
      className="keyInfo-GM-icon"
      fill={fillColor ? fillColor : undefined}
      height="15pt"
      style={{ transform: "none" }}
      viewBox="0 0 432 432"
      width="15pt"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="GM_Brandmark_Gradient_RGB_"
          x1="216"
          x2="216"
          y1="432"
        >
          <stop offset="0" stopColor="#0b8bd2" />
          <stop offset="1" stopColor="#4ee0fd" />
        </linearGradient>
      </defs>
      <style>{`.cls-1{fill:url('#GM_Brandmark_Gradient_RGB_');}`}</style>
      <g data-name="Layer 2" id="Layer_2">
        <g id="GM_Gradient_Brandmark">
          <path
            className="cls-1"
            d="M432,61.6C432,24.25,407.79,0,370.38,0H61.62C24.26,0,0,24.2,0,61.6V370.4C0,407.75,24.21,432,61.62,432H370.38c37.36,0,61.62-24.2,61.62-61.6Zm-64.49,343h-303C40,404.59,27.42,392,27.42,367.53V64.47C27.42,40,40,27.41,64.49,27.41h303c24.45,0,37.07,12.59,37.07,37.06V367.53C404.58,392,392,404.59,367.51,404.59ZM352,147.18V261.66H320.48V151.78c0-7.61-5-12.54-12.41-12.54h-17.8V261.66H258.79V139.24H228.57V261.66H197.1V111.92H316.69C338.66,111.92,352,125.53,352,147.18ZM173.2,111.92V267.74c0,18.33-8.19,52.34-61.69,52.34H98.27V292.44h13.25c21-.39,29.77-9.25,30.13-24.5v-6.28H115.31C94.38,261.66,80,247.55,80,226.4V147.18c0-21.08,14-35.26,35.27-35.26Zm-31.48,27.32H124c-7.44,0-12.48,5.21-12.48,12.43v69.89c0,7.55,5.31,12.74,12.83,12.74h17.37ZM197.1,320.09H352V292.44H197.1Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default GmIconSmall;
