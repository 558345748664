import styled from "styled-components";
import { useEffect } from "react";
import { useCardashboardChannel } from "@vinsolutions/legacy-cardashboard/hooks";
import { BroadcastChannelNames } from "@vinsolutions/ccrm/constants";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("LogoutRedirect");

/* eslint-disable-next-line */
export interface LogoutRedirectProps {}

const StyledLogout = styled.div`
  color: #000;
`;

export function LogoutRedirect(props: LogoutRedirectProps) {
  logger.debug("Initializing LogoutRedirect.");
  const postMessageLogout = useCardashboardChannel<{ tabContext: string }>(
    BroadcastChannelNames.topNavLogoutOtherTabs,
    BroadcastChannelNames.topNavLogoutOtherTabsContext
  );
  useEffect(() => {
    logger.debug("LogoutRedirect: useEffect.");
    document.cookie =
      "LogUserOutTopNav=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=-99999999;SameSite=None;Secure";
    logger.debug("postMessageLogout", postMessageLogout);
    postMessageLogout({ tabContext: window.tabContextId as string });
    logger.debug("redirect to cardashboard logout");
    window.location.href = window.location.origin + "/CarDashboard/logout.aspx";
  }, []);

  return (
    <StyledLogout>
      <h1>Logging out...</h1>
    </StyledLogout>
  );
}

export default LogoutRedirect;
