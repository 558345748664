import styled from "styled-components";
import { KeyInformationBannerLoad } from "@vinsolutions/ui/key-information-banner";
import KeyInformationIconBar, { IconBarIcon } from "./icon-bar";
import { useEffect, useMemo, useState } from "react";

export interface KeyInformationAccordionHeaderProps {
  intializedBanners: number;
  loadedBanners: KeyInformationBannerLoad[];
  onHeaderLoadComplete: () => void;
}

const StyledAccordionHeader = styled.div`
  .cx-accordion-collapse .cx-accordion-body {
    padding: 0em;
    margin: 0em;
  }
  display: flex;
`;
const StyledAccordionHeaderText = styled.div`
  font-weight: 700;
  width: 220px;
  align-self: center;
`;

export function KeyInformationAccordionHeader({
  intializedBanners,
  loadedBanners,
  onHeaderLoadComplete
}: KeyInformationAccordionHeaderProps) {
  const [showLoading, setShowLoading] = useState(false);
  const iconBanners = useMemo<IconBarIcon[]>(() => {
    return (
      loadedBanners
        .filter(banner => banner.isDisplayed && banner.thumbnailIcon)
        .map(banner => {
          return {
            icon: banner.thumbnailIcon,
            iconId: `${banner.bannerName}-icon`
          };
        }) || []
    );
  }, [loadedBanners]);
  useEffect(() => {
    const loading =
      iconBanners.length && loadedBanners.length < intializedBanners
        ? true
        : false;
    setShowLoading(loading);
    if (loadedBanners.length === intializedBanners) {
      onHeaderLoadComplete();
    }
  }, [loadedBanners, iconBanners, intializedBanners, onHeaderLoadComplete]);

  return (
    <StyledAccordionHeader id="keyInfo-AccordionHeader">
      <StyledAccordionHeaderText>Key Information</StyledAccordionHeaderText>
      <KeyInformationIconBar icons={iconBanners} isLoading={showLoading} />
    </StyledAccordionHeader>
  );
}

export default KeyInformationAccordionHeader;
