import usePortal from "../hooks/use-portal";
import { createPortal } from "react-dom";
import {
  Children,
  ReactElement,
  cloneElement,
  useEffect,
  useState
} from "react";
import PortalComponentProps, { RegisteredPortal } from "../interfaces/portals";

type props = {
  registeredPortal: RegisteredPortal<PortalComponentProps>;
  children?: ReactElement | null | undefined;
};

const Portal = ({ registeredPortal, children }: props) => {
  const [previousElement, setPreviousElement] = useState<Node | null>(null);
  useEffect(
    function getFoundElement() {
      const foundElement = registeredPortal.originElement;
      if (!foundElement) return;
      setPreviousElement(foundElement.cloneNode(true));
    },
    [registeredPortal.originElement]
  );
  const target = usePortal(registeredPortal);
  if (!target || children === null || children === undefined) return null;

  const updateChildrenWithProps = Children.map(children, child => {
    return cloneElement(child, {
      previousElement
    });
  });
  return createPortal(
    updateChildrenWithProps,
    target,
    registeredPortal.portalKey
  );
};

export default Portal;
