import { Bad, BadProps, Lost, LostProps, Note, NoteProps } from "../examples";
// eslint-disable-next-line no-restricted-imports
import { ModalDialogProps } from "@cx/ui/ModalDialog";
import { useModalContext } from "./ModalIframe";
import {
  MarkLeadBadModal,
  MarkLeadBadModalProps
} from "../lead/mark-lead-status/mark-lead-bad-modal";
import {
  MarkLeadLostModal,
  MarkLeadLostModalProps
} from "../lead/mark-lead-status/mark-lead-lost-modal";

export type ModalIds =
  | "modal-bad-lead"
  | "modal-note-lead"
  | "modal-lost-lead"
  | "modal-mark-lead-bad"
  | "modal-mark-lead-lost";

export interface ModalMessage {
  componentId: ModalIds;
  props: ComponentModalProps;
  modalProps: Partial<ModalDialogProps>;
  originElement: HTMLIFrameElement;
}

export type ComponentModalProps =
  | BadProps
  | NoteProps
  | LostProps
  | MarkLeadBadModalProps
  | MarkLeadLostModalProps;

const SelectModal = () => {
  const { componentId: modalId } = useModalContext();
  switch (modalId) {
    case "modal-lost-lead":
      return <Lost />;
    case "modal-bad-lead":
      return <Bad />;
    case "modal-note-lead":
      return <Note />;
    case "modal-mark-lead-bad":
      return <MarkLeadBadModal />;
    case "modal-mark-lead-lost":
      return <MarkLeadLostModal />;
    default:
      throw new Error("modal id does not match any component register");
  }
};

export default SelectModal;
