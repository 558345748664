import { Col, LoadingIndicator } from "@vinsolutions/core-cx";
import { ReactElement } from "react";
import styled from "styled-components";

/* eslint-disable-next-line */
export interface KeyInformationBannerDataDetailProps {
  dataDetail: string | ReactElement | null;
  htmlId: string;
  isLoading?: boolean;
}

const StyledKeyInformationBannerDataDetail = styled(Col)`
  text-align: right;
  font-size: 85%;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  display: flex;
  justify-content: flex-end;
`;

export function KeyInformationBannerDataDetail({
  dataDetail,
  htmlId,
  isLoading
}: KeyInformationBannerDataDetailProps) {
  return (
    <StyledKeyInformationBannerDataDetail
      htmlId={htmlId}
      sm={2}
      smPush={7}
      xs={6}
    >
      {isLoading ? (
        <StyledLoadingIndicator htmlId={`${htmlId}-loading`} />
      ) : (
        dataDetail
      )}
    </StyledKeyInformationBannerDataDetail>
  );
}

export default KeyInformationBannerDataDetail;
