import { UIEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Table } from "cx";
import { SubmitButton } from "cx";
import { SortOrder } from "antd/lib/table/interface";
import { tableId } from "./dealer-table-ids";
import { Dealer, SetupStatus } from "@vinsolutions/ccrm/interfaces";
import { DealerStatusBadge } from "../status-badge/dealer-status-badge";

export interface DealerTableProps {
  dealers: Dealer[];
  isEmployee: boolean;
  isLoading: boolean;
  onDealerSelect: (record: Dealer) => void;
  onMoreDealers: () => void;
  isLastPage: boolean;
  pagination?: {
    page: number;
    onPageChange: (page: number) => void;
  };
}

const StyledDealerTable = styled.div`
  .ant-table-body,
  .ant-table-body-inner {
    overflow-y: auto !important;
  }

  .ant-table-pagination.ant-pagination {
    margin: 12px 0 0 0;
  }

  .ant-table-column-sorters {
    line-height: 20px;
    height: 24px;
  }

  table {
    border-bottom: 0px !important;
    table-layout: fixed;

    tr.ant-table-row:hover > td {
      background-color: #f5f6f7;
    }

    .ant-table-thead > tr > th {
      background: #fff;
    }

    .ant-table-thead > tr > th:nth-child(2) .ant-table-column-sorters {
      padding-left: 25px;
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 3px;
      padding-left: 20px;
      cursor: pointer;
    }

    td.ant-table-column-sort {
      background: #fff;
    }

    .ant-table-row > .ant-table-cell {
      border-bottom: 1px solid #f0f0f0;
      border-right: none;
      border-left: none;
      border-top: none;
    }
  }

  .ant-pagination-jump-next:after {
    content: none;
  }

  .ant-table-pagination-right {
    justify-content: center;
  }

  .ant-table-column-sorter-up svg,
  .ant-table-column-sorter-down svg {
    display: none;
  }

  .ant-table-column-sorter-up + .ant-table-column-sorter-down {
    margin-top: 0;
  }
`;

const StyledLoadMoreBtn = styled(Button)`
  display: flex;
  margin: 0.5rem auto;
`;

const sortDirections = new Array<SortOrder>("ascend", "descend");

function checkStatusValue(dealers: Dealer[]) {
  return dealers.every(({ setupStatus }) => !setupStatus);
}

export function DealerTable({
  dealers,
  isEmployee,
  isLoading,
  onDealerSelect,
  onMoreDealers,
  isLastPage,
  pagination
}: DealerTableProps) {
  const [showMoreBtn, setShowMoreBtn] = useState(false);
  let columns = [
    {
      title: "Dealer ID",
      dataIndex: "dealerID",
      key: "dealerID",
      onFilter: (value: string | number | boolean, record: Dealer) =>
        record.dealerID.toString().indexOf(value.toString()) === 0,
      sorter: (a: Dealer, b: Dealer) => {
        if (a && a.dealerID && b && b.dealerID) {
          return a.dealerID - b.dealerID;
        }
        if (a && a.dealerID) {
          return -1;
        }
        if (b && b.dealerID) {
          return 1;
        }
        return 0;
      },
      sortDirections,
      width: "15%"
    },
    {
      title: "Dealer Name",
      key: "dealerName",
      defaultSortOrder: sortDirections[0],
      onFilter: (value: string | number | boolean, record: Dealer) =>
        record.dealerName.toString().indexOf(value.toString()) === 0,
      sorter: (a: Dealer, b: Dealer) => {
        if (
          a &&
          a.dealerName &&
          a.dealerName.length &&
          b &&
          b.dealerName &&
          b.dealerName.length
        ) {
          return a.dealerName.localeCompare(b.dealerName);
        }
        if (a && a.dealerName && a.dealerName.length) {
          return -1;
        }
        if (b && b.dealerName && b.dealerName.length) {
          return 1;
        }
        return 0;
      },
      // eslint-disable-next-line
      render: (record: Dealer) => (
        <SubmitButton
          buttonStyle="link"
          htmlId={`dealer-table-select-dealer-button-${record.dealerID}`}
          isLoading={isLoading}
        >
          {record.dealerName}
        </SubmitButton>
      ),
      sortDirections,
      width: "45%"
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      onFilter: (value: string | number | boolean, record: Dealer) =>
        record.city.toString().indexOf(value.toString()) === 0,
      sorter: (a: Dealer, b: Dealer) => {
        if (a && a.city && a.city.length && b && b.city && b.city.length) {
          return a.city.localeCompare(b.city);
        }
        if (a && a.city && a.city.length) {
          return -1;
        }
        if (b && b.city && b.city.length) {
          return 1;
        }
        return 0;
      },
      sortDirections,
      width: "30%"
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      onFilter: (value: string | number | boolean, record: Dealer) =>
        record.state.toString().indexOf(value.toString()) === 0,
      sorter: (a: Dealer, b: Dealer) => {
        if (a && a.state && a.state.length && b && b.state && b.state.length) {
          return a.state.localeCompare(b.state);
        }
        if (a && a.state && a.state.length) {
          return -1;
        }
        if (b && b.state && b.state.length) {
          return 1;
        }
        return 0;
      },
      sortDirections,
      width: "10%"
    },
    {
      title: "Status",
      dataIndex: "setupStatus",
      key: "status",
      width: "15%",
      render: (record: SetupStatus | "") => {
        return <DealerStatusBadge status={record} />;
      },
      sorter: (a: Dealer, b: Dealer) =>
        a.setupStatus.localeCompare(b.setupStatus)
    }
  ];
  // check if all records inside Dealer[] have setupStatus empty
  // if all are empty doesn't show that column
  if (checkStatusValue(dealers) || !isEmployee) {
    columns = columns.filter(col => col.title !== "Status");
  }

  const showMoreBtnWhenScrollReactLimit = useCallback(
    (ev: UIEvent) => {
      // height of the table view - height of whole table with scroll
      const limitScroll =
        (ev.currentTarget.scrollHeight - ev.currentTarget.clientHeight) * 0.7;
      const currentScroll = ev.currentTarget.scrollTop;
      if (currentScroll > limitScroll && dealers.length) {
        setShowMoreBtn(true);
      } else {
        setShowMoreBtn(false);
      }
    },
    [dealers.length]
  );

  useEffect(() => {
    const bodyTable = document.querySelector(".ant-table-body");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    bodyTable?.addEventListener("scroll", showMoreBtnWhenScrollReactLimit);
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      bodyTable?.removeEventListener("scroll", showMoreBtnWhenScrollReactLimit);
    };
  }, [showMoreBtnWhenScrollReactLimit]);

  const onShowMoreClickHandler = () => {
    setShowMoreBtn(false);
    onMoreDealers();
  };

  return (
    <StyledDealerTable>
      <Table
        columns={columns}
        data={dealers}
        dataDensity="compact"
        displayPageSizeSelector={false}
        emptyText="No records to display."
        enablePagination={isEmployee && dealers.length > 10}
        htmlId={tableId}
        isLoading={isLoading}
        pageSize={10}
        paginationCurrent={pagination?.page}
        scrollY={isEmployee ? 5401 : 540}
        sortableColumns={true}
        onChangePagination={pagination?.onPageChange}
        onRow={record => ({
          onClick: () => {
            onDealerSelect(record);
          }
        })}
      />
      {!isLastPage && showMoreBtn ? (
        <StyledLoadMoreBtn
          htmlId="load-more-dealers-btn"
          onClick={onShowMoreClickHandler}
        >
          Load more dealers
        </StyledLoadMoreBtn>
      ) : null}
    </StyledDealerTable>
  );
}

export default DealerTable;
