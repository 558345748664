import { useCallback, useRef, useState } from "react";
import { CcrmAccountMenuLinks } from "@vinsolutions/ccrm/top-nav/account-menu";
import { Button, LoadingIndicator, ToggleSwitch } from "@vinsolutions/core/cx";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getProfileState,
  getThemeState,
  getUserStatusState,
  selectNotificationsEntities,
  themeActions,
  toggleClockedIn
} from "@vinsolutions/ccrm/store";
import {
  cardashboardActions,
  getCardashboardState
} from "@vinsolutions/legacy-cardashboard/store";
import { ImpersonationButton } from "@vinsolutions/tenant/impersonation";
import { optOutOfTopNav } from "@vinsolutions/ccrm/api";
import LogoutButton from "./logout-button";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { getUserBridgeState } from "@vinsolutions/tenant/store";
import { AnyAction } from "redux";
import { useNavigate } from "react-router-dom";
import { vinconnectHashPrefix } from "@vinsolutions/ccrm/util";

/* eslint-disable-next-line */
export interface AccountMenuProps {
  toggleShowAccountSlidingDrawer: () => void;
  toggleShowFeedbackModal: () => void;
  toggleShowStatusModal: () => void;
}

const StyledAccountMenu = styled.div`
  .cx-top-nav-account-menu-sliding-drawer-title-text {
    font-size: 20px;
  }
`;

export function AccountMenu({
  toggleShowAccountSlidingDrawer,
  toggleShowFeedbackModal,
  toggleShowStatusModal
}: AccountMenuProps) {
  const [showDebug, setShowDebug] = useState(false);
  const {
    loadingStatus: userStatusLoadingStatus,
    timeClockEnabled,
    userClockedIn,
    userCurrentStatus,
    userIsBusy
  } = useSelector(getUserStatusState, shallowEqual);
  const {
    userFullName,
    userRole,
    dealerId,
    dealerName,
    dealerSetupStatus,
    userName,
    isVinEmployee
  } = useSelector(getProfileState, shallowEqual);
  const { cardashboardServerName, frameUrl, hasLegacyRestyle } =
    useSelector(getCardashboardState);
  const notificationsDictionary = useSelector(
    selectNotificationsEntities,
    shallowEqual
  );
  const { currentThemeId } = useSelector(getThemeState, shallowEqual);
  const clickCount = useRef(0);
  const { bridgeUsername } = useSelector(getUserBridgeState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deriveStatusText = useCallback(() => {
    return userIsBusy ? "Busy" : userCurrentStatus || "<No Status>";
  }, [userCurrentStatus, userIsBusy]);

  const toggleClockInStatus = () => {
    dispatch(toggleClockedIn() as unknown as AnyAction);
  };

  const setCarDashboardUrl = (path: string, name: string) => {
    navigate(`${vinconnectHashPrefix}${path}`, { replace: false });
    dispatch(
      cardashboardActions.updateFrameUrl({
        name,
        frameUrl: path
      })
    );
  };

  const onNewsLinkClick = () => {
    fireGAEvent("News");
    setCarDashboardUrl("/CarDashboard/Pages/News/News.aspx", "news");
    toggleShowAccountSlidingDrawer();
  };

  const onTrainingLinkClick = () => {
    fireGAEvent("Training");
    window.open("/CarDashboard/NetExamTraining.ashx", "_blank");
    toggleShowAccountSlidingDrawer();
  };

  const onSupportLinkClick = () => {
    fireGAEvent("Support");
    window.open("https://coxautoinc.force.com/vinsolutions/s/", "_blank");
    toggleShowAccountSlidingDrawer();
  };

  const onTermsOfUseLinkClick = () => {
    fireGAEvent("Terms of Use");
    window.open(
      "https://www.coxautoinc.com/terms/wp-content/uploads/sites/3/Common-Customer-TOU.pdf",
      "_blank"
    );
    toggleShowAccountSlidingDrawer();
  };

  const onFeedbackLinkClick = () => {
    fireGAEvent("Feedback");
    toggleShowFeedbackModal();
    toggleShowAccountSlidingDrawer();
  };

  const onPrivacyPolicyLinkClick = () => {
    fireGAEvent("Privacy Policy");
    window.open("https://www.coxautoinc.com/privacy-statement/", "_blank");
    toggleShowAccountSlidingDrawer();
  };

  const toggleTheme = () => {
    fireGAEvent(
      currentThemeId === "vinconnectDark" ? "Dark Theme Off" : "Dark Theme On"
    );
    dispatch(
      themeActions.set(
        currentThemeId === "vinconnectDark"
          ? "vinconnectLight"
          : "vinconnectDark"
      )
    );
  };

  const toggleLegacyRestyle = () => {
    fireGAEvent(hasLegacyRestyle ? "Restyle Off" : "Restyle On");
    dispatch(cardashboardActions.toggleLegacyRestyle());
  };

  const togglePilot = () => {
    fireGAEvent("Opt Out Clicked");
    optOutOfTopNav(userName || "unknown", frameUrl);
  };

  const fireGAEvent = (name: string) => {
    TagManager.event({
      eventElement: "TopNav: Account Menu",
      eventAction: "Item Clicked",
      eventValue: name
    });
  };

  const toggleShowDebug = () => {
    setShowDebug(b => !b);
  };

  const onConnectAccountClick = () => {
    if (clickCount.current === 0) {
      clickCount.current = clickCount.current + 1;
      window.setTimeout(() => {
        if (clickCount.current > 4) {
          toggleShowDebug();
        }
        clickCount.current = 0;
      }, 1000);
    } else {
      clickCount.current = clickCount.current + 1;
    }
  };

  const toggleVersionControlDebug = () => {
    const isEnabled = localStorage.getItem("versionControlDebugEnabled");
    if (isEnabled === "true") {
      localStorage.removeItem("versionControlDebugEnabled");
    } else {
      localStorage.setItem("versionControlDebugEnabled", "true");
    }
  };

  return (
    <StyledAccountMenu>
      <h1 className="cx-top-nav-account-menu-sliding-drawer-title">
        <span
          className="cx-top-nav-account-menu-sliding-drawer-title-text"
          onClick={isVinEmployee ? onConnectAccountClick : undefined}
        >
          Connect Account
        </span>
      </h1>
      <div
        className="cx-top-nav-account-menu-sliding-drawer-content"
        id="AccountMenu"
      >
        {timeClockEnabled && (
          <>
            <Button
              buttonStyle="link"
              className="account-menu-link-button"
              htmlId="cx-top-nav-account-menu-status-button"
              title={deriveStatusText()}
              onClick={() => {
                TagManager.event({
                  eventElement: "TopNav: User Status Button",
                  eventAction: "Clicked"
                });
                toggleShowStatusModal();
              }}
            >
              {deriveStatusText()}
            </Button>
            <Button
              buttonStyle="link"
              className="account-menu-link-button"
              disabled={userStatusLoadingStatus !== "loaded"}
              htmlId="clock-in-out-toggle-button"
              onClick={() => {
                TagManager.event({
                  eventElement: "TopNav: User Clock Button",
                  eventAction: "Clicked",
                  eventValue: userClockedIn ? "Clock Out" : "Clock In"
                });
                toggleClockInStatus(); // TODO: this api interaction isn't ideal since the response is simply true or false
              }}
            >
              {userStatusLoadingStatus === "loading" ? (
                <LoadingIndicator
                  color={"gray"}
                  htmlId="clock-in-out-toggle-button-loading-indicator"
                  size="small"
                />
              ) : userClockedIn ? (
                "Clock Out"
              ) : (
                "Clock In"
              )}
            </Button>
            <hr className="account-menu-divider account-menu-top-divider" />
          </>
        )}
        <CcrmAccountMenuLinks
          dealerStatus={dealerSetupStatus}
          newsItemCount={
            (notificationsDictionary["news"] || { count: 0 }).count
          }
          {...{
            cardashboardServerName,
            dealerId,
            dealerName,
            userFullName,
            userRole,
            userName,
            bridgeUsername,
            onFeedbackLinkClick,
            onNewsLinkClick,
            onPrivacyPolicyLinkClick,
            onSupportLinkClick,
            onTermsOfUseLinkClick,
            onTrainingLinkClick
          }}
        />
        <hr className="account-menu-divider account-menu-top-divider" />
        <div className="account-menu-toggle-switch">
          Dark Theme
          <ToggleSwitch
            defaultChecked={currentThemeId === "vinconnectDark"}
            htmlId={"account-menu-theme-toggle-switch"}
            onClick={toggleTheme}
          />
        </div>
        {isVinEmployee && (
          <div className="account-menu-toggle-switch">
            Page Titles
            <ToggleSwitch
              defaultChecked={hasLegacyRestyle}
              htmlId={"account-menu-legacy-restyle-toggle"}
              onClick={toggleLegacyRestyle}
            />
          </div>
        )}
        {isVinEmployee && (
          <div className="account-menu-toggle-switch">
            New Experience
            <ToggleSwitch
              defaultChecked={true}
              htmlId={"account-menu-new-experience-toggle"}
              onClick={togglePilot}
            />
          </div>
        )}
        {isVinEmployee && showDebug && (
          <div className="account-menu-toggle-switch">
            Version Control Debug
            <ToggleSwitch
              defaultChecked={
                localStorage.getItem("versionControlDebugEnabled") === "true"
              }
              htmlId={"account-menu-version-control-debug-toggle"}
              onClick={toggleVersionControlDebug}
            />
          </div>
        )}
        <hr className="account-menu-divider account-menu-bottom-divider" />
        <LogoutButton />
        <ImpersonationButton />
      </div>
    </StyledAccountMenu>
  );
}

export default AccountMenu;
