import styled from "styled-components";

export interface KbbIcoInfoSummaryProps {
  offerPrice?: number;
}

const StyledKbbIcoInfoSummary = styled.span``;
const StyledKbbIcoInfoSummaryPriceText = styled.span`
  color: "green";
`;

export function KbbIcoInfoSummary({ offerPrice }: KbbIcoInfoSummaryProps) {
  const offerPriceText = offerPrice ? ": $" + offerPrice : "";

  const textBlurb = `Kelley Blue Book Instant Cash Offer`;

  return (
    <StyledKbbIcoInfoSummary>
      {textBlurb}
      <StyledKbbIcoInfoSummaryPriceText>
        {offerPriceText}
      </StyledKbbIcoInfoSummaryPriceText>
    </StyledKbbIcoInfoSummary>
  );
}
