const customerQuickSearchURL =
  "/Cardashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_CRM&leftpaneframe=/CarDashboard/Pages/rims2.aspx&rightpaneframe=HIDE&variableleft=urlSettingName|CustomerLookup.CDN.URL";
const customerAdvancedSearchURL =
  "/Cardashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&rightpaneframe=HIDE&leftpaneframe=AdvancedSearch2.aspx";
const inventorySearchURL =
  "/Cardashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&rightpaneframe=VehicleList.aspx";

export enum searchModes {
  Customers = "Customers",
  Inventory = "Inventory"
}

export const getSearchBasePath = (
  searchMode: searchModes,
  hasAdvancedCustomerSearch: boolean
) => {
  switch (searchMode) {
    case searchModes.Inventory:
      return inventorySearchURL;
    case searchModes.Customers:
      return hasAdvancedCustomerSearch
        ? customerAdvancedSearchURL
        : customerQuickSearchURL;
  }
};

export const getSearchTermString = (
  searchMode: searchModes,
  hasAdvancedCustomerSearch: boolean,
  searchTerm: string
) => {
  switch (searchMode) {
    case searchModes.Inventory:
      return `?searchdata=${searchTerm}`;
    case searchModes.Customers:
      return hasAdvancedCustomerSearch
        ? `?searchdata=${searchTerm}`
        : searchTerm.trim() && `|searchText|${searchTerm.trim()}`;
  }
};

export const getSearchUrl = (
  searchMode: searchModes,
  hasAdvancedCustomerSearch: boolean,
  searchTerm: string
) => {
  const basePath = getSearchBasePath(searchMode, hasAdvancedCustomerSearch);

  const searchTermString = getSearchTermString(
    searchMode,
    hasAdvancedCustomerSearch,
    searchTerm
  );
  return basePath + searchTermString;
};
