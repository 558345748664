export interface Dealer {
  city: string;
  dealerID: number;
  dealerName: string;
  setupStatus: SetupStatus;
  showEula: boolean;
  state: string;
  userIsIPRestricted: boolean;
}

export enum SetupStatus {
  NewOrUnknown = "N",
  Unknown = "U",
  InSetup = "I",
  Complete = "C",
  SalesRep = "R",
  Active = "A",
  Inactive = "X"
}
