import styled from "styled-components";
import { useVinconnectFlagEnabled } from "@vinsolutions/core/third-party/launch-darkly";

type WalkMeType = "main" | "announcements";

export interface WalkMeProps {
  className: string;
  type: WalkMeType;
  displayExampleText?: boolean;
  useDarkTheme: boolean;
}

const WalkMeStyled = styled.div<WalkMeProps>`
  font-size: 12px;
  font-family: "walkme-opensans";
  color: ${({ type, useDarkTheme }) => {
    if (type === "main") {
      return "#fff";
    } else {
      return useDarkTheme ? "#fff" : "#000";
    }
  }};
  grid-area: ${({ type }) => `walkme-${type}`};
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-right: 16px;
  justify-content: flex-end;
  justify-self: end;
  z-index: 10;
  margin-left: 134px; /* walkme enablement */
`;

const WalkMeQuestionmarkStyled = styled.div`
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url("https://cdn.walkme.com/player/resources/launchers/question-20-blue.png");
  height: 22px;
  width: 22px;
  vertical-align: middle;
`;

const getContent = (type: WalkMeType) => {
  switch (type) {
    case "main":
      return (
        <>
          WalkMe
          <WalkMeQuestionmarkStyled />
        </>
      );
    case "announcements":
      return <>What&apos;s New?</>;
  }
};

export function WalkMe({
  className,
  type,
  displayExampleText,
  useDarkTheme
}: WalkMeProps) {
  const walkMeToggle = useVinconnectFlagEnabled("nx.vinconnect.walkme");
  if (!walkMeToggle) {
    return null;
  }

  return (
    <WalkMeStyled
      {...{ className, type, useDarkTheme }}
      data-testid="walkme-link-styled"
      id={`walk-me-${type}`}
    >
      {displayExampleText && getContent(type)}
    </WalkMeStyled>
  );
}

export default WalkMe;
