import {
  SlidingDrawer as SlidingDrawerCX,
  SlidingDrawerProps
} from "@vinsolutions/core-cx";
import styled from "styled-components";

export const StyledSlidingDrawerCX = styled(SlidingDrawerCX)`
  .cx-drawer__panel {
    z-index: 100001;
  }

  .cx-drawer__background {
    z-index: 100000;
  }
`;

export function SlidingDrawer(props: SlidingDrawerProps) {
  return (
    <StyledSlidingDrawerCX {...props}>{props.children}</StyledSlidingDrawerCX>
  );
}

export default SlidingDrawer;
