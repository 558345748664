import { OemInsightsSummaryResponse } from "@vinsolutions/data-access/oem/vin.api.oeminsights";
import HyundaiGenesisInsightsSummaryData from "../../interfaces/hyundai-genesis/hyundai-genesis-insights-summary-data";
import {
  getFormattedUpdatedUtcDate,
  getOemDataFromSummaryResponse
} from "../getOemDataItem";

export const parseHyundaiGenesisInsights = (
  oemInsightsSummaryResponse: OemInsightsSummaryResponse | null,
  oem: "hyundai" | "genesis"
): HyundaiGenesisInsightsSummaryData | null => {
  const dataItem = getOemDataFromSummaryResponse(
    oemInsightsSummaryResponse,
    oem
  );
  if (dataItem) {
    const parsedItem = JSON.parse(dataItem);
    return {
      conciergeMessagesCount: parsedItem.ConciergeMessagesCount || false,
      offersCount: parsedItem.OffersCount || "",
      preferredLanguage: parsedItem.PreferredLanguage || "",
      updatedDate: getFormattedUpdatedUtcDate(parsedItem.UpdatedUtc)
    };
  }
  return null;
};
