import { VinessaSummaryResponse } from "@vinsolutions/data-access/virtual-assistant/crm.workflow.keyinfo.vinessa";
import VinessaDate from "./vinessa-date";
import VinessaError from "./vinessa-error";

export interface VinessaDataDetailProps {
  vinessaSummary: VinessaSummaryResponse;
  onDataErrorRetryClick: () => void;
}

export const VinessaDataDetail = ({
  vinessaSummary: { date, errorMessage },
  onDataErrorRetryClick
}: VinessaDataDetailProps) => {
  return (
    <>
      {date ? <VinessaDate date={date} /> : null}
      <VinessaError
        errorMessage={errorMessage}
        onRetryClick={onDataErrorRetryClick}
      />
    </>
  );
};

export default VinessaDataDetail;
