enum ViewKind {
  DEALER_DASHBOARD = "DEALER_DASHBOARD",
  QUEUED_LETTERS = "QUEUED_LETTERS",
  RESPONSE_TIMES = "RESPONSE_TIMES",
  SHOW_ROOM_VISITS = "SHOW_ROOM_VISITS",
  PENDING_DEAL_COUNTS = "PENDING_DEAL_COUNTS",
  UNANSWERED_DETAIL = "UNANSWERED_DETAIL"
}

export default ViewKind;
