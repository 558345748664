import { Alert, Button } from "cx";
import { gtmPageReloadedByUserMultiversionAlert } from "../util/gtm-events";

/* eslint-disable-next-line */
export interface VersionControlMultiVersionAlertProps {
  refreshButtonClick: () => void;
}

export function VersionControlMultiVersionAlert({
  refreshButtonClick
}: VersionControlMultiVersionAlertProps) {
  return (
    <Alert htmlId="version-control-multiversion-warning" type="warning">
      <span>This window is using an outdated version.</span>
      <Button
        buttonStyle="link"
        htmlId="version-control-multiversion-button"
        onClick={() => {
          gtmPageReloadedByUserMultiversionAlert(refreshButtonClick);
        }}
      >
        Refresh now
      </Button>
    </Alert>
  );
}

export default VersionControlMultiVersionAlert;
