import { Reducer } from "react";

export type AuthType = {
  isCdInit: boolean; // Whether or not CD has been initialized
  logUserOut: boolean; // State variable for keeping track of whether or not the user has clicked logout.
  isCdHealthy: boolean; // Whether or not CD is in a healthy state when trying to initialize CD.
  initialCdHealthCheckComplete: boolean; // Has the initial CD Health Check been done.
  cdLogoutIsComplete: boolean;
};

export const AuthInitialState = {
  isCdInit: window.localStorage.getItem("cdIsInitialized") === "true",
  logUserOut: false,
  isCdHealthy: false,
  initialCdHealthCheckComplete: false,
  cdLogoutIsComplete: false
};

export enum ReducerActionType {
  CD_IS_INIT,
  CD_IS_UNHEALTHY,
  SSO_LOGOUT,
  CD_LOGOUT_FINISHED,
  SET_CD_HEALTH_STATUS,
  SET_CD_INIT_STATUS
}

export type AuthReducerActionType =
  | {
      readonly type: ReducerActionType.CD_IS_INIT;
    }
  | {
      readonly type: ReducerActionType.CD_IS_UNHEALTHY;
    }
  | {
      readonly type: ReducerActionType.SSO_LOGOUT;
      readonly payload: { useCarDashboard: boolean };
    }
  | {
      readonly type: ReducerActionType.CD_LOGOUT_FINISHED;
    }
  | {
      readonly type: ReducerActionType.SET_CD_HEALTH_STATUS;
      readonly payload: { healthStatus: boolean };
    }
  | {
      readonly type: ReducerActionType.SET_CD_INIT_STATUS;
      readonly payload: {
        healthStatus: boolean;
        initialCdHealthCheckComplete: boolean;
      };
    };

export type AuthPayloadType = {
  useCarDashboard?: boolean;
  healthStatus: boolean;
  initialCdHealthCheckComplete: boolean;
};

export const authReducer: Reducer<AuthType, AuthReducerActionType> = (
  state = AuthInitialState,
  action
) => {
  switch (action.type) {
    case ReducerActionType.CD_IS_INIT: {
      // Set state for to let us know cd has been initialized and
      // store in local storage so if the user refreshes or closes tab and comes back.
      window.localStorage.setItem("cdIsInitialized", "true");

      return {
        ...state,
        isCdInit: true,
        isCdHealthy: true,
        initialCdHealthCheckComplete: true
      };
    }
    case ReducerActionType.CD_IS_UNHEALTHY:
      return {
        ...state,
        initialCdHealthCheckComplete: true
      };

    case ReducerActionType.SSO_LOGOUT:
      return {
        ...state,
        logUserOut: true,
        cdLogoutIsComplete: !action.payload.useCarDashboard
      };

    case ReducerActionType.CD_LOGOUT_FINISHED:
      return { ...state, cdLogoutIsComplete: true };

    case ReducerActionType.SET_CD_HEALTH_STATUS:
      return {
        ...state,
        initialCdHealthCheckComplete: true,
        isCdHealthy: action.payload.healthStatus
      };

    case ReducerActionType.SET_CD_INIT_STATUS:
      // If user isn't logged into TopNav,
      // lets make sure any existing items in localstorage are gone.
      window.localStorage.removeItem("cdIsInitialized");

      return {
        ...state,
        initialCdHealthCheckComplete:
          action.payload.initialCdHealthCheckComplete,
        isCdInit: false,
        isCdHealthy: action.payload.healthStatus
      };
  }
};
