import React from "react";
import { createLogger } from "@vinsolutions/logger";
import {
  DomainMailStatus,
  DomainSavedValidationResult,
  getDomainSetupStatus,
  validateDomainSetup
} from "@vinsolutions/data-access/vin.api.email";
import { useSelector } from "react-redux";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getProfileState } from "@vinsolutions/ccrm/store";
// eslint-disable-next-line @nx/enforce-module-boundaries,no-restricted-imports
import ListContainer from "@cx/ui/ListContainer";
// eslint-disable-next-line @nx/enforce-module-boundaries,no-restricted-imports
import Card from "@cx/ui/Card";
// eslint-disable-next-line @nx/enforce-module-boundaries,no-restricted-imports
import Grid from "@cx/ui/Grid";
// eslint-disable-next-line @nx/enforce-module-boundaries,no-restricted-imports
import Row from "@cx/ui/Row";
// eslint-disable-next-line @nx/enforce-module-boundaries,no-restricted-imports
import Col from "@cx/ui/Col";
// eslint-disable-next-line @nx/enforce-module-boundaries,no-restricted-imports
import Button from "@cx/ui/Button";
// eslint-disable-next-line @nx/enforce-module-boundaries,no-restricted-imports
import LoadingIndicator from "@cx/ui/LoadingIndicator";
// eslint-disable-next-line @nx/enforce-module-boundaries,no-restricted-imports
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { GetItem } from "./components";

const logger = createLogger("PageEmailSetupStatusCheck");

const noDomainsToLoad = "No domains to load";

const gridSpacing = {
  col1: { lg: 2, md: 4, xs: 12 },
  col2: { lg: 5, md: 6, xs: 12 },
  col3: { lg: 5, md: 6, xs: 12 },
  col4: { lg: 2, md: 4, xs: 12 }
};
/* eslint-disable-next-line */
export interface PageEmailSetupStatusCheckProps {
  htmlId: string;
}

interface DomainMailPageState {
  domainMailStatuses: DomainMailStatus[];
  loading: boolean;
  dealerId: number | null;
}

export function PageEmailSetupStatusCheck(
  props: PageEmailSetupStatusCheckProps
): JSX.Element {
  const { dealerId } = useSelector(getProfileState);
  const { oidcUser } = useReactOidc();

  const [domainMailPageState, setState] = React.useState<DomainMailPageState>({
    domainMailStatuses: [],
    loading: true,
    dealerId
  });

  function logStateChanges(state: DomainMailPageState) {
    logger.debug("state", JSON.stringify(state));
    setState(state);
  }

  async function load() {
    console.debug("load called");
    const token = oidcUser?.access_token;

    logger.debug("loading ", props);

    if (dealerId === null) {
      return;
    }

    if (token === null) {
      return;
    }

    const domainStatusListResult = await getDomainSetupStatus(dealerId, token);

    if (!domainStatusListResult || domainStatusListResult?.length === 0) {
      logStateChanges({
        ...domainMailPageState,
        domainMailStatuses: [
          {
            validationResults: [],
            domain: noDomainsToLoad,
            domainId: "",
            dealerId
          }
        ],
        loading: false
      });
    } else {
      logStateChanges({
        ...domainMailPageState,
        domainMailStatuses: domainStatusListResult,
        loading: false
      });
    }
  }

  function validateClick(args: any) {
    console.debug("validateClick called");
    if (domainMailPageState.loading) {
      return;
    }

    const domainId = args.target.value;
    const token = oidcUser?.access_token;
    logger.debug("loading ", props);
    if (dealerId === null) {
      return;
    }
    if (token === null) {
      return;
    }

    logStateChanges({
      ...domainMailPageState,
      loading: true
    });

    validateDomainSetup(dealerId, domainId, token)
      .then(validationResults => {
        load().finally();
      })
      .catch(error => {
        logger.error(error);
      });
  }

  React.useEffect(() => {
    logger.debug("useEffect", props);
    load().finally();
  }, [domainMailPageState.dealerId]);

  logger.debug("render", JSON.stringify(domainMailPageState));

  if (domainMailPageState.loading) {
    return (
      <Grid>
        <Col mdOffset={6} />
        <Row className="show-grid">
          <LoadingIndicator htmlId="loadingIndicatorExample" />
        </Row>
      </Grid>
    );
  }

  return (
    <Card htmlId="card">
      <ListContainer
        className="listcontainer"
        header="Domain status page"
        htmlId={props.htmlId}
        list={domainMailPageState.domainMailStatuses.map(
          (data: DomainMailStatus) => {
            if (data.domain === noDomainsToLoad) {
              return <div>No domain found for this dealer</div>;
            }
            return (
              <Card htmlId={data.domain + "_card"}>
                <div>
                  <Button
                    buttonStyle="link"
                    htmlId={data.domain + "_button"}
                    type="button"
                    value={data.domainId}
                    onClick={validateClick}
                  >
                    Validate {data.domain}
                  </Button>
                </div>
                <div>
                  <Grid className="container">
                    <Row className="row">
                      <Col
                        lg={gridSpacing.col1.lg}
                        md={gridSpacing.col1.md}
                        xs={gridSpacing.col1.xs}
                      >
                        <strong>Validation result</strong>
                      </Col>
                      <Col
                        lg={gridSpacing.col2.lg}
                        md={gridSpacing.col2.md}
                        xs={gridSpacing.col2.xs}
                      >
                        <strong>Host</strong>
                      </Col>
                      <Col
                        lg={gridSpacing.col3.lg}
                        md={gridSpacing.col3.md}
                        xs={gridSpacing.col3.xs}
                      >
                        <strong>Value</strong>
                      </Col>
                      <Col
                        lg={gridSpacing.col4.lg}
                        md={gridSpacing.col4.md}
                        xs={gridSpacing.col4.xs}
                      />
                    </Row>
                    {data.validationResults?.map(
                      (validationResult: DomainSavedValidationResult) => (
                        <GetItem
                          gridSpacing={gridSpacing}
                          validationResult={validationResult}
                        />
                      )
                    )}
                  </Grid>
                </div>
              </Card>
            );
          }
        )}
        showCount={false}
        showMore={true}
      />
    </Card>
  );
}

export default PageEmailSetupStatusCheck;
