import { KeyInformationBanner } from "@vinsolutions/ui/key-information-banner";
import { useCallback, useEffect, useState } from "react";
import KeyInformationKbbIcoModal from "./components/kbbico-modal";
import KbbIcoBannerProps from "./interfaces/kbbico-banner-props";
import KbbIconLarge from "./icons/kbbico-icon-large";
import KbbIcoDate from "./components/kbbico-date";
import KbbIcoIcon from "./components/kbbico-icon";
import {
  KbbIcoInfo,
  getKbbIcoInfo
} from "@vinsolutions/data-access/lead/vin.crm.opportunityattributes";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import KbbIconInverse from "./icons/kbbico-icon-small-inverse";
import { KbbIcoInfoSummary } from "./components/kbbico-summary";
import KbbIconSmall from "./icons/kbbico-icon-small";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";

const bannerName = "KbbIco";
export const KbbIcoBaseDisplayedBanner = {
  bannerName,
  iconName: "KbbIcoIconSmall",
  thumbnailName: "KbbIcoIconSmall",
  thumbnailIcon: <KbbIconSmall />
};

export function KbbIcoBanner({
  customerId,
  onInitialize,
  onLoad,
  onClickGtmEvent
}: KbbIcoBannerProps) {
  // State
  const [kbbIcoInfo, setKbbIcoInfo] = useState<KbbIcoInfo | null | undefined>(
    null
  );
  const [kbbIcon, setKbbIcon] = useState(KbbIconLarge);
  const [showModal, setShowModal] = useState(false);
  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "KbbIcoBanner",
    "Loading KBB ICO Banner"
  );

  const jwt = useReactOidc().oidcUser.access_token;
  const loadKbbIcoInfo = useCallback(async () => {
    const kbbIcoInfo = await getKbbIcoInfo(customerId, jwt);

    setKbbIcoInfo(kbbIcoInfo);
    const isDisplayed = !!kbbIcoInfo;
    onLoad({ ...KbbIcoBaseDisplayedBanner, customerId, isDisplayed });
    setInteractionAttributes({
      customerId,
      isDisplayed
    });
    endInteraction();
  }, [customerId, jwt, onLoad, endInteraction, setInteractionAttributes]);

  useEffect(() => {
    onInitialize?.(1);
    loadKbbIcoInfo();
  }, [loadKbbIcoInfo, onInitialize]);

  const onBannerClick = useCallback(() => {
    onClickGtmEvent?.(bannerName);
    setShowModal(true);
  }, [onClickGtmEvent]);

  return (
    kbbIcoInfo && (
      <>
        <KeyInformationBanner
          bannerDataDetail={<KbbIcoDate date={kbbIcoInfo.date} />}
          bannerIcon={<KbbIcoIcon kbbIcon={kbbIcon} />}
          bannerName={bannerName}
          bannerSummary={
            <KbbIcoInfoSummary offerPrice={kbbIcoInfo.offerPrice} />
          }
          onClick={onBannerClick}
          onMouseEnter={() => setKbbIcon(KbbIconInverse)}
          onMouseLeave={() => setKbbIcon(KbbIconLarge)}
        />
        <KeyInformationKbbIcoModal
          dealerGuid={kbbIcoInfo.dealerGuid}
          env={kbbIcoInfo.env}
          offerGuid={kbbIcoInfo.offerGuid}
          showModal={showModal}
          onHide={() => setShowModal(false)}
        />
      </>
    )
  );
}
