import { createSlice } from "@reduxjs/toolkit";

export const META_DATA_FEATURE_KEY = "metaData";

export interface MetaDataState {
  currentAppVersion: string | null;
  loadingStatus: "not loaded" | "loading" | "loaded" | "error";
  error: string | null;
}

export const initialMetaDataState: MetaDataState = {
  currentAppVersion: null,
  loadingStatus: "not loaded",
  error: null
};

export const metaDataSlice = createSlice({
  name: META_DATA_FEATURE_KEY,
  initialState: initialMetaDataState,
  reducers: {
    setAppVersion: (state, action) => {
      return { ...state, currentAppVersion: action.payload };
    }
  }
});

export const metaDataReducer = metaDataSlice.reducer;
export const metaDataActions = metaDataSlice.actions;
export const getMetaDataState = (rootState: any): MetaDataState =>
  rootState[META_DATA_FEATURE_KEY];
