import styled from "styled-components";

export interface AmpSummaryProps {
  ampSummary?: string;
}

const StyledAmpBannerSummary = styled.span``;

const AmpSummary = ({ ampSummary }: AmpSummaryProps) => {
  return <StyledAmpBannerSummary>{ampSummary}</StyledAmpBannerSummary>;
};

export default AmpSummary;
