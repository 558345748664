import { useReducer, useState } from "react";
import { DMCReducer, InitialState } from "../../context/dmc.reducer";
import { DMCContext } from "../../context/dmc.context";
import { DMCModalType } from "../../types/dmcContext.type";

export const DMCProvider = ({
  children
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [state, dispatch] = useReducer(DMCReducer, InitialState);
  const [modalContent, setModalContent] = useState({
    show: false
  });
  const modalContext: DMCModalType = { modalContent, setModalContent };

  return (
    <DMCContext.Provider value={{ dispatch, modalContext, state }}>
      {children}
    </DMCContext.Provider>
  );
};
