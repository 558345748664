import { useMemo } from "react";
import styled from "styled-components";
import { OemPartnerKey } from "@vinsolutions/data-access/oem/vin.api.oeminsights";

export interface OemInsightsModalContentProps {
  customerId: number;
  customerFirstName: string;
  customerLastName: string;
  dealerId: number;
  oem: OemPartnerKey | null;
}

export const CARDASHBOARD_RIMS_BASEPATH = "/CarDashboard/Pages/rims2.aspx";
export const OEM_INSIGHTS_SETTING_NAME = "armada.wfe.oeminsights.url";

const StyledOemInsightsModalContent = styled.iframe`
  width: 100%;
  height: 900px;
  border: none;
`;

const OemInsightsModalContent = ({
  customerId,
  customerFirstName,
  customerLastName,
  dealerId,
  oem
}: OemInsightsModalContentProps) => {
  const iframeUrl = useMemo(() => {
    return customerId
      ? `${CARDASHBOARD_RIMS_BASEPATH}?urlSettingName=${OEM_INSIGHTS_SETTING_NAME}&customerId=${customerId}&dealerId=${dealerId}&oem=${oem}&firstName=${customerFirstName}&lastName=${customerLastName}`
      : "";
  }, [customerId, customerFirstName, customerLastName, dealerId, oem]);

  return (
    iframeUrl && (
      <StyledOemInsightsModalContent
        id="OemInsights-KeyInfo-Modal"
        src={iframeUrl}
        title="OemInsights-Modal"
      />
    )
  );
};

export default OemInsightsModalContent;
