import {
  KeyInformationBanner,
  KeyInformationTextIcon
} from "@vinsolutions/ui/key-information-banner";
import OemInsightsBannerProps from "../../interfaces/oem-insights-banner-interface-props";
import GmIconSmall, { GmIconFill } from "./gm-icon";
import GmInsightsSummary from "./gm-summary";
import { parseGmInsights } from "../../data-parse/gm/gm-summary-data";
import { useEffect, useMemo, useState } from "react";
import OemInsightsDate from "../oem-banner-date";

const bannerName = "GM Insights";
export const GmBaseDisplayedBanner = {
  bannerName,
  iconName: "GmIconSmall",
  thumbnailName: "GmIconSmall",
  thumbnailIcon: (
    <GmIconSmall fillColor={GmIconFill.None} key={"gm-thumbnail-icon"} />
  )
};

export function GmInsightsBanner({
  oemInsightsSummary,
  onLoad
}: OemInsightsBannerProps) {
  const [iconFillColor, setIconFillColor] = useState<GmIconFill>(
    GmIconFill.None
  );
  const gmInsights = useMemo(
    () => parseGmInsights(oemInsightsSummary),
    [oemInsightsSummary]
  );
  useEffect(() => {
    onLoad({
      ...GmBaseDisplayedBanner,
      isDisplayed: !!gmInsights
    });
  }, [onLoad, gmInsights]);
  return (
    gmInsights && (
      <KeyInformationBanner
        bannerDataDetail={<OemInsightsDate oemInsights={gmInsights} />}
        bannerIcon={
          <KeyInformationTextIcon
            icon={<GmIconSmall fillColor={iconFillColor} />}
            iconText="GM Lead Score"
          />
        }
        bannerName={bannerName}
        bannerSummary={<GmInsightsSummary gmInsights={gmInsights} />}
        onMouseEnter={() => setIconFillColor(GmIconFill.Inverse)}
        onMouseLeave={() => setIconFillColor(GmIconFill.None)}
      />
    )
  );
}

export default GmInsightsBanner;
