import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState
} from "react";
// eslint-disable-next-line no-restricted-imports
import { ComponentModalProps, ModalIds, ModalMessage } from "./SelectModal";
import { ModalListener } from "./ModalListener";
// eslint-disable-next-line no-restricted-imports
import { ModalDialogProps } from "@cx/ui/ModalDialog";
import { ModalsEventTypes } from "../types";

interface ModalContextValue {
  dispatchCloseModalEv: (detailData: any) => void;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setModalInfo: (modalDetailData: ModalMessage) => void;
  modalProps: Partial<ModalDialogProps> | null;
  componentProps: ComponentModalProps | null;
  hideModal: () => void;
  componentId: ModalIds | null;
  setModalProps: Dispatch<SetStateAction<Partial<ModalDialogProps> | null>>;
}

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] =
    useState<Partial<ModalDialogProps> | null>(null);
  const [componentId, setComponentId] = useState<ModalIds | null>(null);
  const [componentProps, setComponentProps] =
    useState<ComponentModalProps | null>(null);
  const [originElement, setOriginElement] = useState<HTMLIFrameElement | null>(
    null
  );

  const setModalInfo = useCallback(
    ({ componentId, modalProps, props, originElement }: ModalMessage) => {
      setModalProps(modalProps);
      setComponentProps(props);
      setOriginElement(originElement);
      setComponentId(componentId);
      setShowModal(true);
    },
    []
  );

  const dispatchCloseModalEv = (detailData: any) => {
    const customEvent = new CustomEvent(ModalsEventTypes.modalClose, {
      detail: {
        componentId,
        ...detailData
      }
    });
    if (originElement) {
      originElement.contentDocument?.body.dispatchEvent(customEvent);
    }
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <ModalContext.Provider
      value={{
        showModal,
        setShowModal,
        dispatchCloseModalEv,
        setModalInfo,
        modalProps,
        componentProps,
        hideModal,
        componentId,
        setModalProps
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error(
      "useModalContext must be used within a ModalContextProvider"
    );
  }
  return context;
};

// component

export const ModalIframe = () => {
  return (
    <ModalContextProvider>
      <ModalListener />
    </ModalContextProvider>
  );
};
