import {
  KeyInformationBanner,
  KeyInformationTextIcon
} from "@vinsolutions/ui/key-information-banner";
import OemInsightsBannerProps from "../../interfaces/oem-insights-banner-interface-props";
import HyundaiIconSmall from "./hyundai-icon";
import HyundaiIconSmallInverse from "./hyundai-icon-inverse";
import HyundaiInsightsSummary from "./hyundai-genesis-insights-summary";
import { parseHyundaiGenesisInsights } from "../../data-parse/hyundai-genesis/hyundai-genesis-summary-data";
import { useEffect, useMemo, useState } from "react";
import OemInsightsDate from "../oem-banner-date";

const bannerName = "Hyundai Insights";
export const HyundaiBaseDisplayedBanner = {
  bannerName,
  iconName: "HyundaiIconSmall",
  thumbnailName: "HyundaiIconSmall",
  thumbnailIcon: <HyundaiIconSmall key={"hyundai-thumbnail-icon"} />
};

export function HyundaiInsightsBanner({
  oemInsightsSummary,
  onLoad,
  onClick
}: OemInsightsBannerProps) {
  const [icon, setIcon] = useState(HyundaiIconSmall);
  const hyundaiInsights = useMemo(
    () => parseHyundaiGenesisInsights(oemInsightsSummary, "hyundai"),
    [oemInsightsSummary]
  );
  useEffect(() => {
    onLoad({
      ...HyundaiBaseDisplayedBanner,
      isDisplayed: !!hyundaiInsights
    });
  }, [onLoad, hyundaiInsights]);
  return (
    hyundaiInsights && (
      <KeyInformationBanner
        bannerDataDetail={<OemInsightsDate oemInsights={hyundaiInsights} />}
        bannerIcon={
          <KeyInformationTextIcon icon={icon} iconText="Hyundai Intelligence" />
        }
        bannerName={bannerName}
        bannerSummary={
          <HyundaiInsightsSummary hyundaiGenesisInsights={hyundaiInsights} />
        }
        onClick={() => onClick && onClick(bannerName, "hyundai")}
        onMouseEnter={() => setIcon(HyundaiIconSmallInverse)}
        onMouseLeave={() => setIcon(HyundaiIconSmall)}
      />
    )
  );
}

export default HyundaiInsightsBanner;
