import { interactionObject } from "@vinsolutions/core-interfaces-third-party-newrelic";
import { interaction } from "./interaction";
import { useMemo, useRef } from "react";

const applyAttributesToInteraction = (
  newRelicInteraction: interactionObject | undefined,
  attributes: Record<string, string | number | boolean>
) => {
  if (newRelicInteraction) {
    Object.entries(attributes).forEach(([key, value]) => {
      const attributeValue =
        typeof value === "boolean" ? value.toString() : value;
      newRelicInteraction.setAttribute(key, attributeValue);
    });
  }
};

/** Creates a New Relic browser interaction and returns an object to work with the created interaction */
export const useNewRelicInteraction = (
  interactionName: string,
  actionText: string
) => {
  const newRelicInteraction = interaction()
    ?.setName(interactionName)
    ?.actionText(actionText)
    ?.save();
  const interactionRef = useRef(newRelicInteraction);
  const wrappedInteraction = useMemo(() => {
    return {
      endInteraction: () => {
        interactionRef.current?.end();
      },
      createInteractionTracer: (name: string, callback: () => void) => {
        return interactionRef.current?.createTracer(name, callback);
      },
      getInteractionContext: (callback: () => void) => {
        return interactionRef.current?.getContext(callback);
      },
      setInteractionAttributes: (
        attributes: Record<string, string | number | boolean>
      ) => {
        applyAttributesToInteraction(interactionRef.current, attributes);
      }
    };
  }, [interactionRef]);

  return wrappedInteraction;
};

export default useNewRelicInteraction;
