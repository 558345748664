import {
  PortalMessage,
  RegisteredPortal,
  RegisteredPortalFactory
} from "@vinsolutions/utility/portals";
import KeyInformationPortalInterface, {
  KeyInformationPortalProps
} from "./key-information-portal-interface";
import { PortalEventData } from "./PortalEventData";

const getPortalParentFromLocation = (location: Location | undefined) => {
  return location?.pathname
    ?.substring(location.pathname.lastIndexOf("/") + 1) // page part
    ?.split(".")[0] // remove .aspx
    ?.replace(/([A-Z])/g, " $1") // apply spaces between camel-casings
    ?.trim();
};

export class KeyInformationPortalFactory
  implements RegisteredPortalFactory<KeyInformationPortalProps>
{
  create(
    portalMessage: PortalMessage
  ): RegisteredPortal<KeyInformationPortalProps> {
    const dataIsland = (portalMessage.portalProps?.data ||
      {}) as PortalEventData;
    const portalParentLocation = getPortalParentFromLocation(
      portalMessage.originDocument?.location
    );
    const keyInfoProps = {
      ...(portalMessage.portalProps || {}),
      customerContext: dataIsland?.CustomerContext,
      dealerContext: dataIsland?.DealerContext,
      portalParentLocation
    } as KeyInformationPortalProps;
    return {
      originDocument: portalMessage.originDocument,
      originElement: portalMessage.originElement,
      portalId: portalMessage.portalId,
      portalKey: portalMessage.portalKey,
      portalComponent: KeyInformationPortalInterface,
      portalComponentProps: keyInfoProps
    };
  }
}
