import { useReactOidc } from "@axa-fr/react-oidc-context";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchDynamicHeader,
  fetchProfile,
  fetchTheme,
  getProfileState,
  profileActions,
  themeActions
} from "@vinsolutions/ccrm/store";
import {
  addDealerChangeEventListener,
  addThemeChangeEventListener,
  getGTMReadyConfig,
  useMountEffect
} from "@vinsolutions/ccrm/util";
import { isUserLoggedIntoBridge } from "@vinsolutions/tenant/bridge-bar";
import { OidcUser } from "@vinsolutions/tenant/jwt-utils";
import {
  fetchUserBridgeState,
  getUserBridgeState
} from "@vinsolutions/tenant/store";
import { useEffect, useState } from "react";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import {
  cardashboardActions,
  fetchCardashboardStatus
} from "@vinsolutions/legacy-cardashboard/store";
import { useLocation } from "react-router-dom";
import { LDSingleKindContext } from "launchdarkly-js-sdk-common";
import { AnyAction } from "redux";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("useApp");

export const useApp = () => {
  const { oidcUser } = useReactOidc();
  const [ldUser, setLdUser] = useState<LDSingleKindContext>();
  const {
    bridgeUsername,
    platformId,
    loadingStatus: bridgeStateLoadingStatus
  } = useSelector(getUserBridgeState, shallowEqual);

  const {
    dealerId,
    dealerName,
    isVinEmployee,
    profileLoadingStatus,
    userRole,
    userId,
    userName,
    userFullName
  } = useSelector(getProfileState, shallowEqual);
  const dispatch = useDispatch();
  useMountEffect(() => {
    addDealerChangeEventListener((dealerId: number, dealerName: string) => {
      dispatch(profileActions.update({ dealerId, dealerName }));
    });

    addThemeChangeEventListener((currentThemeId: string) => {
      dispatch(themeActions.set(currentThemeId));
    });

    dispatch(fetchTheme() as unknown as AnyAction);

    if (isUserLoggedIntoBridge(oidcUser as unknown as OidcUser)) {
      // call service user.vinsolutions.com/userbridgestate/id/user/{id}
      dispatch(
        fetchUserBridgeState(oidcUser.access_token) as unknown as AnyAction
      );
    }

    dispatch(fetchProfile(oidcUser.access_token) as unknown as AnyAction);

    // new relic notification initial system load
    dispatch(fetchCardashboardStatus() as unknown as AnyAction);
  });

  useEffect(() => {
    if (dealerId) {
      dispatch(
        fetchDynamicHeader({
          jwt: oidcUser.access_token,
          dealerId
        }) as unknown as AnyAction
      );
    }
  }, [dealerId]);

  useEffect(() => {
    // when bridge is active check profile state and bridge state
    // when bridge is inactive just check profile state
    if (
      (isUserLoggedIntoBridge(oidcUser as unknown as OidcUser) &&
        profileLoadingStatus === "loaded" &&
        bridgeStateLoadingStatus === "loaded") ||
      (!isUserLoggedIntoBridge(oidcUser as unknown as OidcUser) &&
        profileLoadingStatus === "loaded")
    ) {
      TagManager.dataLayer({
        dataLayer: getGTMReadyConfig(
          isVinEmployee,
          userId ?? 0,
          (dealerId ?? 0).toString(),
          dealerName,
          userRole,
          bridgeUsername,
          platformId
        )
      });
      setLdUser({
        kind: "user",
        key: userName,
        name: userFullName,
        dealerId,
        dealerName,
        userId,
        isVinEmployee,
        userRole
      });
    }
  }, [
    dealerId,
    dealerName,
    isVinEmployee,
    profileLoadingStatus,
    bridgeStateLoadingStatus,
    userFullName,
    userId,
    userName,
    userRole,
    oidcUser
  ]);

  return {
    ldUser
  };
};

export const useSyncCDWithBackForwardBtn = () => {
  logger.debug("useSyncCDWithBackForwardBtn");
  const dispatch = useDispatch();
  const location = useLocation();

  /**
   * On location change make sure that the frameURL is updated in CarDashboard redux store
   * If this isn't done then a URL entered directly in the address bar won't be invoked
   * Automated tests depend on entering a URL directly
   */
  useEffect(() => {
    if (location.hash) {
      const cdPage = location.hash.replace("#", "");
      dispatch(
        cardashboardActions.updateFrameUrl({
          name: "useSyncCDWithBackForwardBtn",
          frameUrl: cdPage
        })
      );
    }
  }, [dispatch, location]);
};
