import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState
} from "react";
import { SlidingDrawerProps } from "@vinsolutions/core/cx";
import DrawerListener from "./DrawerListener";
import {
  ComponentDrawerProps,
  DrawerIds,
  SliderDrawerDetailData
} from "./SelectDrawer";
import { ModalsEventTypes } from "../types";

interface SliderDrawerContextValue {
  dispatchCloseEv: (detailData: any) => void;
  showDrawer: boolean;
  setShowDrawer: Dispatch<SetStateAction<boolean>>;
  setDrawerInfo: (drawerDetailData: SliderDrawerDetailData) => void;
  drawerProps: Partial<SlidingDrawerProps> | null;
  componentProps: ComponentDrawerProps | null;
  hideDrawer: () => void;
  componentId: DrawerIds | null;
}

const SliderDrawerContext = createContext<SliderDrawerContextValue | undefined>(
  undefined
);

const SliderDrawerProvider = ({ children }: { children: ReactNode }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerProps, setDrawerProps] =
    useState<Partial<SlidingDrawerProps> | null>(null);
  const [componentId, setComponentId] = useState<DrawerIds | null>(null);
  const [componentProps, setComponentProps] =
    useState<ComponentDrawerProps | null>(null);
  const [originElement, setOriginElement] = useState<HTMLIFrameElement | null>(
    null
  );

  const setDrawerInfo = useCallback(
    ({
      componentId,
      sliderDrawerProps,
      props,
      originElement
    }: SliderDrawerDetailData) => {
      setDrawerProps(sliderDrawerProps);
      setComponentProps(props);
      setOriginElement(originElement);
      setComponentId(componentId);
      setShowDrawer(true);
    },
    []
  );

  const dispatchCloseEv = (detailData: any) => {
    const customEvent = new CustomEvent(ModalsEventTypes.sliderDrawerClose, {
      detail: {
        componentId,
        ...detailData
      }
    });
    if (originElement) {
      originElement.contentDocument?.body.dispatchEvent(customEvent);
    }
  };

  const hideDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <SliderDrawerContext.Provider
      value={{
        showDrawer,
        setShowDrawer,
        dispatchCloseEv,
        setDrawerInfo,
        drawerProps,
        componentProps,
        hideDrawer,
        componentId
      }}
    >
      {children}
    </SliderDrawerContext.Provider>
  );
};

export const useSlideDrawer = () => {
  const context = useContext(SliderDrawerContext);
  if (context === undefined) {
    throw new Error(
      "useSlideDrawer must be used within a SliderDrawerProvider"
    );
  }
  return context;
};

// component

export const SliderDrawerIframe = () => {
  return (
    <SliderDrawerProvider>
      <DrawerListener />
    </SliderDrawerProvider>
  );
};
