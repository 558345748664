import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DMCAlert } from "../dmc-alert/dmc-alert.component";
import { DMCCheckboxParams } from "../../types/dmcParams.type";

export const DMCCheckbox = ({
  id,
  label,
  name,
  note,
  onChange,
  value
}: DMCCheckboxParams) => {
  const onInputChange = (event: CheckboxChangeEvent) => {
    onChange(event);
  };

  return (
    <>
      <Checkbox
        checked={value}
        data-testid={id}
        onChange={onInputChange}
        {...{ id, name }}
      >
        {label}
      </Checkbox>
      {note && (
        <DMCAlert
          htmlId={`dealer-manufacturer-codes-alert-${id}`}
          type="info"
          value={note}
        />
      )}
    </>
  );
};
