import { shallowEqual, useSelector } from "react-redux";
import { getDealerDashboardSliceState } from "../dealer-dashboard.slice";
import { useGetUserGroupsQuery } from "../rtk";

export function useGetUserGroups() {
  const { jwt, dealerId } = useSelector(
    getDealerDashboardSliceState,
    shallowEqual
  );

  const {
    currentData: userGroups,
    isSuccess,
    isLoading,
    isFetching,
    isUninitialized,
    isError
  } = useGetUserGroupsQuery(
    { jwt, dealerId },
    { skip: jwt === "" || dealerId === 0 }
  );

  return {
    userGroups,
    isError,
    isSuccess: isSuccess && !isLoading && !isFetching && !isUninitialized
  };
}
