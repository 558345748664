export const DarkThemeAccountIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    height="50px"
    version="1.1"
    viewBox="0 0 50 50"
    width="50px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Artboard Copy</title>
    <defs>
      <filter
        filterUnits="objectBoundingBox"
        height="159.4%"
        id="filter-1"
        width="159.4%"
        x="-29.7%"
        y="-29.7%"
      >
        <feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          type="matrix"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <path
        d="M20.207317,20.207317 C23.9273813,20.207317 26.9430893,17.191609 26.9430893,13.4715447 C26.9430893,9.75148033 23.9273813,6.73577233 20.207317,6.73577233 C16.4872527,6.73577233 13.4715447,9.75148033 13.4715447,13.4715447 C13.4715447,17.191609 16.4872527,20.207317 20.207317,20.207317 Z M33.6788933,28.963821 C33.6845645,27.6471099 32.918254,26.4492144 31.7204359,25.9024125 C28.0900287,24.32268 24.1662723,23.5295498 20.207317,23.5752032 C16.2483617,23.5295498 12.3246053,24.32268 8.69419814,25.9024125 C7.49638,26.4492144 6.7300695,27.6471099 6.73574072,28.963821 L6.73574072,33.6788617 L33.6788933,33.6788617 L33.6788933,28.963821 Z"
        id="path-2"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      id="Artboard-Copy"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Logo-1" transform="translate(2.500000, 2.500000)">
        <circle cx="22.5" cy="22.5" fill="#FFFFFF" id="Oval" r="22.5" />
        <path
          d="M19.2049513,52.9549513 C31.6313582,52.9549513 41.7049513,42.8813582 41.7049513,30.4549513 C41.7049513,18.0285444 31.6313582,7.95495129 19.2049513,7.95495129"
          fill="#F5F6F7"
          id="Path"
          transform="translate(30.454951, 30.454951) rotate(-315.000000) translate(-30.454951, -30.454951) "
        />
        <g
          filter="url(#filter-1)"
          id="Icons/person"
          transform="translate(2.292683, 2.292683)"
        >
          <mask fill="white" id="mask-3">
            <use xlinkHref="#path-2" />
          </mask>
          <use fill="#2053AB" id="fill" xlinkHref="#path-2" />
        </g>
        <circle
          cx="22.5"
          cy="22.5"
          id="Oval"
          r="23.25"
          stroke="#2053AB"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
);
