import { VinessaSummaryResponse } from "../models/vinessa-summary.model";

export const mockVinessaSummaryError: VinessaSummaryResponse = {
  textBlurb: "",
  date: "",
  errors: [
    {
      code: "try_again",
      message: "Failed to retrieve the Vinessa Summary."
    }
  ]
};

export const vinessaSummaryErrorResponse: VinessaSummaryResponse = {
  textBlurb: "Something went wrong and we were unable to load the information",
  date: "",
  errorMessage: "Try Again"
};
