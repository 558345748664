import React, { ReactNode } from "react";

export interface ModuleErrorBoundaryProps {
  children: ReactNode;
  moduleName: string;
  errorMessage: string;
  errorCallback: (errorMessage: string) => void;
}

interface ModuleErrorBoundaryState {
  hasError: boolean;
}

class ModuleErrorBoundary extends React.Component<
  ModuleErrorBoundaryProps,
  ModuleErrorBoundaryState
> {
  static getDerivedStateFromError(_: Error): ModuleErrorBoundaryState {
    return { hasError: true };
  }

  constructor(props: ModuleErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Probably need a new relic alert here of some kind.
    // Dunno what I'd do with the callback. Might remove it
    this.props.errorCallback(`Failed to load ${this.props.moduleName} module.`);
  }

  render() {
    if (this.state.hasError) {
      // Open to suggestions on what we want as a fallback. Maybe a product decision??
      // Bad deploys/AWS outages are probably the main concern here.
      // A bad contract is probably the next most likely
      return <div>{this.props.errorMessage}</div>;
    }

    return this.props.children;
  }
}

export default ModuleErrorBoundary;
