export default function CustomerEnrichmentIconSmallInverse() {
  return (
    <svg
      className="keyInfo-small-icon"
      height={20}
      viewBox="0 0 16 16"
      width={20}
    >
      <path
        d="M8 .32c1.065 0 2.08.217 3.002.609a2.869 2.869 0 00-.64 1.12 6.4 6.4 0 103.589 3.588A2.872 2.872 0 0015.07 5 7.68 7.68 0 118 .32zM8 8c4.118 0 3.835 3.09 3.824 3.192l-7.612.003S3.812 8 8 8zm0-4.48a1.92 1.92 0 110 3.84 1.92 1.92 0 010-3.84zM13.12.96a1.92 1.92 0 110 3.84 1.92 1.92 0 010-3.84z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  );
}
