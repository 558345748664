enum LeadStatus {
  ACTIVE_NEW_LEAD = 1,
  ACTIVE_WAITING_FOR_PROSPECT_RESPONSE = 2,
  ACTIVE_ACTIVE_LEAD = 3,
  SOLD_ON_ORDER = 4,
  LOST_LEAD_PROCESS_COMPLETED = 6,
  BAD_BAD_OR_NO_CONTACT_INFORMATION = 7,
  BAD_PROSPECT_CLAIMS_NEVER_SUBMITTED = 8,
  BAD_UNDERAGE_PROSPECT = 9,
  BAD_DUPLICATE_LEAD = 10,
  SOLD_PENDING_FINANCE = 11,
  SOLD_DELIVERED = 12,
  LOST_DID_NOT_RESPOND = 13,
  LOST_BAD_CREDIT = 14,
  LOST_NO_AGREEMENT_REACHED = 15,
  ACTIVE_SET_APPOINTMENT = 16,
  ACTIVE_EMAIL_INBOX = 20,
  LOST_IMPORT_LEAD = 21,
  LOST_PURCHASED_SAME_BRAND_DIFFERENT_DEALER = 22,
  LOST_PURCHASED_DIFFERENT_BRAND_DIFFERENT_DEALER = 23,
  LOST_WORKING_WITH_OTHER_SALESPERSON = 24,
  LOST_REQUESTED_NO_FURTHER_CONTACT = 25,
  LOST_PURCHASED_FROM_PRIVATE_PARTY = 26,
  LOST_OUT_OF_MARKET = 27,
  BAD_DEALER_TEST_LEAD = 28,
  BAD_INCORRECT_CUSTOMER_PHONE = 29,
  BAD_NO_INTENT_TO_BUY = 30,
  BAD_INCENTIVIZED = 31,
  BAD_SHOPPING_OUT_OF_AREA = 32,
  BAD_NO_CONTACT_IN_FIVE_DAYS = 33,
  SERVICE_COMPLETE = 34,
  SERVICE_APPOINTMENT_SCHEDULED = 35,
  SERVICE_APPOINTMENT_MISSED = 36,
  OPEN_RO = 37,
  CLOSED_RO = 38,
  NON_CUSTOMER_INITIATED_LEAD = 39,
  SERVICE_APPOINTMENT_CANCELED = 40,
  LOST_BOUGHT_FROM_DIFFERENT_DEALER_IN_GROUP = 41
}

export default LeadStatus;
