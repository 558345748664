import styled from "styled-components";

type DynamicPaneProps = {
  left?: boolean;
  expanded?: boolean;
};

export const resolveColumn = (isLeftPane?: boolean, isExpanded?: boolean) => {
  if (isExpanded) {
    return "1 / span 2";
  }
  return isLeftPane ? 1 : 2;
};

/**
 * Styled side panel for displaying React components in CarDashboard layout
 */
export const StyledSidePane = styled.div<DynamicPaneProps>`
  grid-column: ${props => {
    return resolveColumn(props.left, props.expanded);
  }};
  grid-row: 1;
  height: 100%;
`;
