import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { Dealer, SetupStatus } from "@vinsolutions/ccrm/interfaces";
import { removeInactiveDealers } from "./dealer-selector-helper";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { DealerSelectorModal } from "./modal";
import { DealerSelectorButton } from "./button";
import { HttpResponse } from "@vinsolutions/core/http";
import { GetAvailableDealersByPage } from "@vinsolutions/ccrm/api";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useDebounce } from "@vinsolutions/utility/hooks";

/* eslint-disable-next-line */
export interface DealerSelectorProps {
  dealerId: number;
  dealerName: string;
  dealerStatus: SetupStatus;
  isEmployee: boolean;
  onDealerChanged: (
    dealerId: number,
    dealerName: string,
    dealerStatus: SetupStatus | ""
  ) => void;
  searchDealers: (
    search: string
  ) => Promise<HttpResponse<GetAvailableDealersByPage>>;
  getDealersByPage: (
    page: number
  ) => Promise<HttpResponse<GetAvailableDealersByPage>>;
}

const useMountEffect = (fun: (...args: any) => any) => useEffect(fun, []);

export function DealerSelector({
  dealerId,
  dealerName,
  dealerStatus,
  isEmployee,
  onDealerChanged,
  getDealersByPage,
  searchDealers
}: DealerSelectorProps) {
  const [showDealerSelect, setShowDealerSelect] = useState(false);
  const [dealerList, setDealerList] = useState([] as Dealer[]);
  const [paginationInfo, setPaginationInfo] = useState<{
    pageSize: number;
    totalPages: number;
    currentPage: number;
  }>({ pageSize: 0, totalPages: 0, currentPage: 0 });
  const [filteredDealerList, setFilteredDealerList] = useState([] as Dealer[]);
  const [dealerSelectionError, setDealerSelectionError] = useState(false);
  const [selectorIsLoading, setSelectorIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({
    text: "",
    activeDealers: true
  });
  const [page, setPage] = useState(1);
  const searchDebounceValue = useDebounce(searchParams.text, 500);

  const fetchingError = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setDealerSelectionError(true);
      setSelectorIsLoading(false);
    });
  };

  useMountEffect(() => {
    (async function () {
      getDealersPaginated(1);
    })();
  });

  const getDealersPaginated = useCallback(
    async (page: number) => {
      const { data, statusCode, hasError } = await getDealersByPage(page);
      if (!hasError && data && statusCode === 200) {
        ReactDOM.unstable_batchedUpdates(() => {
          setPaginationInfo({
            totalPages: Math.ceil(data.totalDealers / data.pageSize),
            currentPage: page,
            pageSize: data.pageSize
          });
          setDealerList(prevDealerList => [
            ...prevDealerList,
            ...(data.dealers as Dealer[])
          ]);
          setDealerSelectionError(false);
          setSelectorIsLoading(false);
        });
      } else {
        fetchingError();
      }
    },
    [getDealersByPage]
  );

  const searchDealersByText = useCallback(
    async (debounceValue: string) => {
      if (debounceValue) {
        setSelectorIsLoading(true);
        const { data, hasError } = await searchDealers(debounceValue);

        // Filter out results with no thumbnail
        if (!hasError && data) {
          const filteredResults = data.dealers as Dealer[];
          setPage(1);
          setFilteredDealerList(filteredResults);
          setSelectorIsLoading(false);
        } else {
          setSelectorIsLoading(false);
          setFilteredDealerList([]);
          setPage(1);
        }
      } else {
        setFilteredDealerList([]);
        setPage(1);
      }
    },
    [searchDealers]
  );

  useEffect(() => {
    searchDealersByText(searchDebounceValue);
  }, [searchDealersByText, searchDebounceValue]);

  const handleMoreDealers = () => {
    if (paginationInfo?.totalPages > paginationInfo?.currentPage) {
      setSelectorIsLoading(true);
      getDealersPaginated(paginationInfo.currentPage + 1);
    }
  };

  const onChange = (
    dealerId: number,
    dealerName: string,
    dealerStatus: SetupStatus | ""
  ) => {
    onDealerChanged(dealerId, dealerName, dealerStatus);
    setShowDealerSelect(false);
  };

  const handleSearch = async (
    searchText: string,
    activeDealersOnly: boolean
  ) => {
    setSearchParams({
      text: searchText,
      activeDealers: activeDealersOnly
    });
  };

  const handleDealerClick = ({ dealerID, dealerName, setupStatus }: Dealer) => {
    onChange(dealerID, dealerName, setupStatus);
  };

  const onOpenDealerModalClick = () => {
    if (dealerList.length > 1 || isEmployee) {
      TagManager.event({
        eventElement: "TopNav: Dealer Selector",
        eventAction: "Clicked"
      });
      setShowDealerSelect(true);
    }
  };

  const onDealerModalHide = () => {
    setShowDealerSelect(false);
    setDealerSelectionError(false);
  };

  const dealersToShow = useMemo(() => {
    const list = searchParams.text ? filteredDealerList : dealerList;

    if (searchParams.activeDealers) {
      return removeInactiveDealers(list as Dealer[]);
    } else {
      return list;
    }
  }, [
    searchParams.text,
    filteredDealerList,
    dealerList,
    searchParams.activeDealers
  ]);

  return (
    <div
      data-testid={"ccrm-dealer-header-display"}
      id="ccrm-dealer-header-display"
    >
      <DealerSelectorButton
        dealerCount={dealerList.length}
        onClick={onOpenDealerModalClick}
        {...{ dealerId, dealerName, dealerStatus, isEmployee }}
      />
      <DealerSelectorModal
        dealers={dealersToShow}
        isLastPage={paginationInfo.totalPages === paginationInfo.currentPage}
        isLoading={selectorIsLoading}
        pagination={{ page, onPageChange: page => setPage(page) }}
        show={showDealerSelect}
        onDealerSearch={handleSearch}
        onDealerSelect={handleDealerClick}
        onHide={onDealerModalHide}
        onMoreDealers={handleMoreDealers}
        {...{ error: dealerSelectionError, isEmployee }}
      />
    </div>
  );
}

export default DealerSelector;
