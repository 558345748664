import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDynamicHeaderState,
  getMainNavigationState,
  mainNavigationActions
} from "@vinsolutions/ccrm/store";
import { Dispatch } from "redux";
import { DynamicHeaderMenuItem } from "@vinsolutions/ccrm/interfaces";

const selectInitialActiveTab = (
  dispatch: Dispatch<any>,
  dynamicHeaderMenuItems: DynamicHeaderMenuItem[] | null | undefined
) => {
  if (dynamicHeaderMenuItems && dynamicHeaderMenuItems.length > 0) {
    dispatch(mainNavigationActions.setActiveTab(dynamicHeaderMenuItems[0].id));
  }
};
/**
 * Evaluates the dynamic-header feature flag and then returns the dynamicHeaderState or if feature flag not set
 * returns the default dynamic header state.
 */
export const useDynamicHeader = () => {
  const dynamicHeaderState = useSelector(getDynamicHeaderState, shallowEqual);
  const { activeTabId } = useSelector(getMainNavigationState);
  const dispatch = useDispatch();

  if (!activeTabId) {
    selectInitialActiveTab(
      dispatch,
      dynamicHeaderState?.dynamicMenu?.dynamicMenuItems
    );
  }
  return dynamicHeaderState;
};
