export const enum FordDirectIconFill {
  FordDirectGreen = "#1aa8a5",
  FordDirectWhite = "#fff",
  FordDirectDarkBlue = "#1b3564"
}

export interface FordDirectIconProps {
  fillColor: FordDirectIconFill;
}

const FordDirectIconSmall = ({ fillColor }: FordDirectIconProps) => {
  const secondaryFillColor =
    fillColor === FordDirectIconFill.FordDirectGreen
      ? FordDirectIconFill.FordDirectDarkBlue
      : fillColor;
  return (
    <svg
      data-name="Layer 1"
      height="46px"
      id="keyInfo-Ford-logo"
      viewBox="0 0 230 46"
      width="190px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m155.56,46c.08-.16-.1-.16-.15-.18-.66-.27-.92-.8-.97-1.46-.12-1.52-.04-3.05-.05-4.58-.01-1.14,0-2.28,0-3.42,0-.07,0-.13.02-.22h3.43c.01.06.03.1.03.14,0,2.55-.02,5.1,0,7.64,0,.96-.28,1.7-1.29,2-.02,0-.03.05-.04.08h-.97Z"
        fill={fillColor}
      />
      <path
        d="m166.65,23.12c-.59,0-1.19,0-1.79,0-.11,0-.18-.09-.25-.17-1.07-1.27-2.15-2.54-3.22-3.81-.04-.04-.06-.1-.16-.1v4.06h-2.21v-7.88c.59,0,1.19-.01,1.79,0,.18,0,.28.21.39.34,1.01,1.2,2.01,2.41,3.02,3.62.04.05.06.1.09.16.03-.02.05-.03.08-.05v-4.06h2.26v7.87Z"
        fill={fillColor}
      />
      <path
        d="m87.86,15.22c.58,0,1.15,0,1.72,0,.14,0,.22.09.29.18,1.02,1.21,2.03,2.42,3.05,3.63.08.1.13.23.31.3v-4.08h2.25v7.88c-.6,0-1.2,0-1.81,0-.14,0-.25-.14-.34-.25-.97-1.15-1.94-2.31-2.91-3.47-.1-.12-.2-.25-.35-.43v4.14h-2.22v-7.88Z"
        fill={fillColor}
      />
      <path
        d="m212.01,23.12c-.59,0-1.19,0-1.78,0-.11,0-.18-.09-.25-.16-1.07-1.27-2.15-2.54-3.22-3.81-.04-.04-.06-.1-.16-.1v4.06h-2.21v-7.88c.59,0,1.2-.05,1.78.02.33.04.49.44.7.7.91,1.09,1.86,2.15,2.72,3.28.03.04.09.06.17.11v-4.09h2.25v7.87Z"
        fill={fillColor}
      />
      <path
        d="m153.69,23.34c-1.99-.05-3.41-.75-4.24-2.43-.99-2.02-.11-4.46,1.94-5.4,1.86-.86,4.08-.53,5.43.8,1.31,1.29,1.55,3.4.58,4.95-.9,1.44-2.26,2-3.71,2.08Zm1.97-4.15c0-.09,0-.19,0-.28-.06-1.34-1.36-2.31-2.66-1.99-.91.22-1.49.93-1.63,1.97-.18,1.31.69,2.44,2,2.6,1.22.14,2.29-.92,2.3-2.3Z"
        fill={fillColor}
      />
      <path
        d="m227.18,20.66c.57.81,1.13,1.61,1.72,2.45-.8,0-1.59.02-2.37-.01-.27-.01-.32-.34-.46-.53-.33-.41-.62-.85-.92-1.28-.14-.21-.31-.31-.57-.29-.28.02-.55,0-.86,0v2.11h-2.23c-.04-.19-.06-7.43-.02-7.89.09,0,.18,0,.27,0,1.18,0,2.35-.01,3.53,0,.73.01,1.41.19,2.06.53,1.94,1.02,1.68,3.77.09,4.68-.09.05-.19.08-.25.22Zm-3.45-1.39c.43,0,.85,0,1.27,0,.28,0,.54-.06.79-.17.41-.18.6-.52.6-.94,0-.44-.15-.82-.6-1.03-.67-.31-1.36-.12-2.06-.19v2.33Z"
        fill={fillColor}
      />
      <path
        d="m121,23.1v-7.88c.22,0,.43,0,.64,0,1.1,0,2.2-.04,3.3.01.93.04,1.82.29,2.52.97,1.23,1.2.98,3.32-.5,4.24-.08.05-.19.08-.26.22.56.8,1.13,1.61,1.72,2.46-.8,0-1.57.02-2.34,0-.29-.01-.33-.35-.49-.54-.33-.41-.63-.85-.92-1.28-.14-.21-.31-.31-.57-.29-.27.02-.55,0-.85,0v2.1h-2.26Zm2.22-3.84c.54,0,1.05.03,1.55,0,.7-.06,1.14-.51,1.15-1.09.01-.64-.4-1.12-1.1-1.19-.49-.05-.98-.01-1.47-.01-.03,0-.06.03-.09.05,0,.74.02,1.48-.03,2.26Z"
        fill={fillColor}
      />
      <path
        d="m190.98,21v2.1h-2.25v-7.88c.18,0,.36,0,.53,0,1.13,0,2.27-.03,3.4.01.92.04,1.77.3,2.49.93,1.22,1.06,1.04,3.42-.48,4.29-.08.05-.15.1-.26.17.3.43.58.84.87,1.25.29.41.57.81.88,1.24-.8,0-1.58.02-2.36-.01-.28-.01-.33-.35-.48-.54-.33-.41-.62-.85-.92-1.28-.14-.21-.32-.3-.57-.29-.27.02-.55,0-.85,0Zm0-1.73c.48,0,.94,0,1.41,0,.28,0,.55-.1.79-.24.64-.4.62-1.47-.02-1.84-.69-.4-1.43-.17-2.17-.24v2.33Z"
        fill={fillColor}
      />
      <path
        d="m35.48,23.12v-8.09c.08,0,.15-.01.22-.01,1.23,0,2.46-.03,3.68,0,1.07.04,2.05.35,2.9,1.04,1.34,1.09,1.66,3.16.96,4.71-.47,1.02-1.24,1.7-2.33,2.05-.65.21-1.3.29-1.98.29-1.14,0-2.28,0-3.46,0Zm6.21-4.38c-.13-1.14-.84-2-1.95-2.15-.76-.1-1.55-.02-2.32-.02v5.02c.79,0,1.57.08,2.33-.02,1.3-.18,2.08-1.37,1.94-2.81h.01s-.02-.01-.02-.01Z"
        fill={secondaryFillColor}
      />
      <path
        d="m104.53,23.1v-7.86h6.28v1.71h-4.03v1.31h3.55v1.74h-3.54v1.4h4.17v1.7h-6.42Z"
        fill={fillColor}
      />
      <path
        d="m215.97,19.99v1.41h4.19v1.7h-6.42v-7.86h6.27v1.71h-4.03v1.3h3.54v1.74h-3.55Z"
        fill={fillColor}
      />
      <path
        d="m50.6,22.7s0,.09,0,.14c-.03.04-.04.08,0,.11v.16h-1.9v-8.07c.07,0,.14-.01.21-.01,1.14,0,2.29-.03,3.43,0,.77.02,1.51.19,2.17.64.98.67,1.44,1.99,1.07,3.14-.24.75-.68,1.32-1.37,1.69-.04.02-.08.06-.12.09.59.84,1.17,1.66,1.76,2.51h-1.99c-.49-.71-1-1.45-1.51-2.2h-1.75v1.78s-.02,0-.02,0c0,0,.02,0,.02,0Zm.04-6.15v2.83c.59,0,1.16-.01,1.72,0,.33.01.61-.1.88-.26.47-.29.67-.95.51-1.6-.12-.49-.62-.94-1.12-.96-.66-.02-1.32,0-1.99,0Z"
        fill={secondaryFillColor}
      />
      <path
        d="m118.4,17.78c-1.21-1.11-2.48-1.37-3.74-.14-.7.69-.71,2.03-.15,2.85.66.97,1.74,1.24,3.11.76v-2.25h2.01v3.39c-.8.42-1.59.76-2.46.88-.63.09-1.26.07-1.9-.03-1.62-.25-2.89-1.46-3.28-2.91-.32-1.2-.19-2.36.49-3.4.73-1.14,1.86-1.68,3.16-1.84,1.28-.16,2.48.08,3.55.84.22.16.48.31.6.59-.46.43-.92.84-1.4,1.28Z"
        fill={fillColor}
      />
      <path
        d="m173.9,21v2.1h-2.25v-7.85c.06-.06.14-.03.21-.03,1.25,0,2.49-.03,3.74,0,.76.03,1.51.21,2.15.66.93.65,1.26,1.56,1.09,2.65-.16,1.05-.79,1.73-1.76,2.14-.76.32-1.55.34-2.36.32-.27,0-.53,0-.82,0Zm0-1.73c.47,0,.94,0,1.4,0,.28,0,.55-.09.79-.24.65-.4.62-1.49-.04-1.85-.68-.38-1.42-.16-2.15-.23v2.33Z"
        fill={fillColor}
      />
      <path
        d="m134.38,15.23c1.18,2.62,2.36,5.23,3.55,7.87h-2.36c-.24-.47-.39-1-.69-1.48h-3.39c-.19.48-.39.97-.59,1.48h-2.32c1.18-2.62,2.37-5.24,3.55-7.87h2.24Zm-1.16,2.14c-.35.89-.67,1.68-1.07,2.49h2.11c-.34-.82-.67-1.61-1.04-2.49Z"
        fill={fillColor}
      />
      <path
        d="m181.73,21.62c-.19.48-.38.97-.58,1.48h-2.32c1.19-2.63,2.37-5.25,3.55-7.87h2.24c1.18,2.61,2.36,5.23,3.55,7.87h-2.36c-.21-.47-.43-.97-.66-1.47h-3.41Zm1.77-4.15c-.39.8-.73,1.58-1.1,2.39h2.1c-.34-.82-.67-1.6-1-2.39Z"
        fill={fillColor}
      />
      <path
        d="m157.85,10.47h-3.46c0-2.9-.02-5.78,0-8.65,0-.97.57-1.61,1.37-1.78,1.07-.24,2.07.58,2.08,1.67,0,2.62,0,5.25,0,7.87v.89Z"
        fill={fillColor}
      />
      <path
        d="m63.16,15.21v1.34h-4.23v1.71h3.72v1.54h-3.72v1.79h4.37v1.52h-6.26v-8.06c.06,0,.13-.01.21-.01,1.89,0,3.78,0,5.67,0,.11,0,.29-.07.25.16,0,0-.02,0-.02,0h.02Z"
        fill={secondaryFillColor}
      />
      <path
        d="m64.34,18.58c.01-.59.23-1.12.51-1.63.39-.7.99-1.21,1.69-1.54,1.75-.83,3.81-.63,5.27.66.06.05.1.11.14.16-.39.4-.77.8-1.17,1.22-.64-.78-1.46-1.13-2.46-1.01-.58.07-1.07.33-1.45.76-.98,1.11-.92,2.91.11,3.86.95.88,2.61.98,3.8-.31.4.38.8.75,1.21,1.14-.48.6-1.14.86-1.8,1.11-.72.28-1.48.3-2.22.2-.71-.1-1.37-.39-1.98-.8-.9-.6-1.35-1.47-1.63-2.47-.04-.13-.01-.28-.02-.43,0,0,0,0,0,0-.05-.3-.05-.6,0-.9,0,0,0,0,0,0Z"
        fill={secondaryFillColor}
      />
      <path
        d="m139.77,16.95h-2.48v-1.71h7.21v1.73h-2.42v6.14h-2.31v-6.15Z"
        fill={fillColor}
      />
      <path
        d="m98.89,16.95h-2.47v-1.71h7.2v1.73h-2.43v6.14h-2.31v-6.16Z"
        fill={fillColor}
      />
      <path
        d="m201.06,23.1h-2.31v-6.14h-2.47v-1.72h7.2v1.72h-2.43v6.14Z"
        fill={fillColor}
      />
      <path
        d="m74.8,16.76c0-.06-.02-.13-.03-.22h-2.53v-1.5h7v1.49h-2.54v6.57h-1.9c0-2.11,0-4.22,0-6.33,0,0,.01,0,.01,0h-.01Z"
        fill={secondaryFillColor}
      />
      <path
        d="m26.32,15.02c.4,0,.81,0,1.22,0,.73,0,1.46-.03,2.18,0,1.54.08,2.87.62,3.65,2.03.66,1.18.63,2.42.18,3.69-.35.99-1.09,1.6-1.98,2.04-.42.21-.93.31-1.4.32-1.28.04-2.55.01-3.86.01v-8.1Zm.85,7.33s.07.04.09.04c1.06-.02,2.14.17,3.19-.08,2-.48,2.82-2.01,2.54-3.85-.24-1.53-1.3-2.48-2.91-2.69-.92-.12-1.86-.03-2.79-.04-.03,0-.06.02-.12.04v6.57Z"
        fill={secondaryFillColor}
      />
      <path
        d="m24.36,23.11h-.88c-.56-.79-1.11-1.57-1.66-2.35-.07-.09-.13-.17-.26-.17-.86,0-1.73,0-2.59.01-.03,0-.07.01-.14.03v2.46h-.84v-8.07c.07,0,.16,0,.25,0,1.01,0,2.02-.02,3.02,0,.61.02,1.22.16,1.75.49,1,.61,1.49,1.81,1.21,2.94-.21.87-.69,1.52-1.56,1.83-.05.02-.09.04-.14.07-.02.01-.03.04-.06.08.32.44.64.87.96,1.32.31.44.62.89.94,1.36Zm-.98-5.59c-.12-.89-.58-1.4-1.44-1.65-.72-.21-1.45-.11-2.17-.13-.3-.01-.6,0-.94,0v4.14c.79,0,1.58,0,2.36,0,.1,0,.2,0,.3-.02,1.33-.23,2.02-1.08,1.88-2.33h.01s-.02-.01-.02-.01Z"
        fill={secondaryFillColor}
      />
      <path
        d="m11.82,23.12c-.86.04-1.82-.29-2.64-.92-.61-.47-1.02-1.12-1.27-1.86-.4-1.2-.26-2.37.39-3.42.59-.96,1.5-1.54,2.61-1.78,1.37-.3,2.64-.05,3.75.8,1.15.88,1.6,2.12,1.46,3.51-.14,1.41-.82,2.54-2.12,3.22-.62.32-1.28.45-2.18.45Zm.12-.7c1.68.05,3.42-1.36,3.35-3.44-.06-1.87-1.55-3.3-3.5-3.23-1.82.07-3.26,1.42-3.25,3.46.01,2.04,1.87,3.29,3.39,3.22Z"
        fill={secondaryFillColor}
      />
      <path d="m147.65,23.11h-2.24v-7.88h2.24v7.88Z" fill={fillColor} />
      <path d="m86.23,23.11h-2.24v-7.87h2.24v7.87Z" fill={fillColor} />
      <path
        d="m159.88,27.78c.02.1.05.2.05.3,0,1.31,0,2.61,0,3.94h-.81v-6.04h.73c.82,1.39,1.64,2.8,2.5,4.28.73-1.51,1.71-2.81,2.44-4.28h.71v6.04h-.79v-4.25c-.74,1.19-1.5,2.34-2.23,3.51-.21.09-.34.02-.45-.16-.64-1.06-1.29-2.11-1.93-3.17-.05-.09-.09-.18-.22-.17,0,0,0,0,0,0h0Z"
        fill={fillColor}
      />
      <path d="m46.75,15.03v8.07h-1.9v-8.07h1.9Z" fill={secondaryFillColor} />
      <path
        d="m156.38,30.03c.47.66.94,1.31,1.43,1.99-.3,0-.56,0-.82,0-.12,0-.19-.08-.26-.18-.35-.49-.71-.98-1.07-1.47-.06-.08-.13-.16-.24-.16-.56,0-1.13,0-1.72,0v1.8h-.87v-6.07c1.11.09,2.22-.13,3.31.16.96.26,1.47.85,1.56,1.82.08.91-.32,1.61-1.18,2.03-.04.02-.08.05-.14.09Zm-2.68-.57c.46.08,1.62.03,2.06-.07.64-.15,1.02-.62,1.03-1.26,0-.73-.34-1.16-1.01-1.35-.63-.18-1.28-.07-1.91-.11-.06,0-.12.04-.17.06v2.73Z"
        fill={fillColor}
      />
      <path
        d="m6.63,15.04v.7H1.96v3.21h4.13c.07.25.03.47.03.71H1.94v3.43h-.84v-8.06h5.52Z"
        fill={secondaryFillColor}
      />
      <path
        d="m151.18,30.57c.2.2.43.33.54.57-1.13,1.18-3.34,1.21-4.58,0-1.22-1.19-1.19-3.05-.12-4.19,1.43-1.52,3.76-1.11,4.68-.09-.16.17-.32.35-.49.54-1.38-1.01-2.69-1.06-3.75.37-.64.86-.46,2.04.29,2.82.47.48,1.07.69,1.71.71.65.02,1.2-.3,1.73-.73Z"
        fill={fillColor}
      />
      <path
        d="m50.61,22.95s-.04-.08,0-.11c0,.04,0,.08,0,.11Z"
        fill={fillColor}
      />
      <polygon
        fill={fillColor}
        points="63.16 15.21 63.15 15.2 63.16 15.19 63.17 15.2 63.16 15.21"
      />
      <polygon
        fill={fillColor}
        points="50.6 22.7 50.58 22.69 50.6 22.68 50.61 22.69 50.6 22.7"
      />
      <polygon
        fill={fillColor}
        points="64.34 19.5 64.34 19.5 64.33 19.5 64.34 19.5"
      />
      <polygon
        fill={fillColor}
        points="74.8 16.76 74.81 16.77 74.8 16.77 74.8 16.76"
      />
      <polygon
        fill={fillColor}
        points="64.34 18.58 64.34 18.59 64.33 18.59 64.34 18.58"
      />
      <polygon
        fill={fillColor}
        points="41.69 18.75 41.69 18.73 41.71 18.74 41.69 18.75"
      />
      <polygon
        fill={fillColor}
        points="23.39 17.53 23.39 17.52 23.4 17.53 23.39 17.53"
      />
    </svg>
  );
};

export default FordDirectIconSmall;
