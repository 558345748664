import React, { useCallback, useEffect, useRef, useState } from "react";
import { elevatedRoles } from "./util";
import mapPartyBar from "./util/activity-bar-mapper";
import {
  GAContextLog,
  Metric,
  MetricsFilter,
  MetricsSection,
  PartyBar
} from "@vinsolutions/ccrm/interfaces";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import ActivityBar from "./bar/activity-bar";
import ActivityCards from "./cards/activity-cards";
import { HttpResponse } from "@vinsolutions/core/http";
import {
  addVisibilityChangeEventListener,
  removeVisibilityChangeEventListener
} from "@vinsolutions/ccrm/util";
import { SlidingDrawer } from "@vinsolutions/core/components";

/* eslint-disable-next-line */
export interface ActivityProps {
  dealerId: number;
  isDarkTheme: boolean;
  userRole: string;
  refreshRate?: number;
  getPartyBar: (
    dealerId: number,
    rangeMarker: number
  ) => Promise<HttpResponse<PartyBar>>;
  setCarDashboardUrl: (url: string, name: string) => void;
}

const StyledActivity = styled.div`
  .ccrm-metrics-card {
    margin: 16px 0;
  }
`;

type TimerControl = {
  startTime: Date | null;
  remainingTime: number | null;
  timeoutId: number;
};

export function Activity({
  dealerId,
  isDarkTheme,
  userRole,
  refreshRate = 900000,
  getPartyBar,
  setCarDashboardUrl
}: ActivityProps) {
  const [savedFilter, setSavedFilter] = useState({
    value: 1,
    label: "Today"
  } as MetricsFilter);
  const [{ cardMetrics, widgetMetrics }, setMetrics] = useState<{
    cardMetrics: MetricsSection[] | [];
    widgetMetrics: Metric[] | [];
  }>({ cardMetrics: [], widgetMetrics: [] });
  const [getMetricsWhenThisValueChanges, setGetMetricsWhenThisValueChanges] =
    useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showMetricsDrawer, setShowMetricsDrawer] = useState(false);
  const [hasError, setHasError] = useState(false);
  const isInitialCall = useRef(true);
  const stopPolling = useRef(false);
  const timerControl = useRef<TimerControl>({
    startTime: null,
    remainingTime: null,
    timeoutId: 0
  });

  const deriveHasManagerMetrics = useCallback(() => {
    return elevatedRoles.indexOf(userRole) > -1;
  }, [userRole]);

  useEffect(() => {
    if (!dealerId) return;
    setIsLoading(true);

    const onMetricClick = (GAContextLog: GAContextLog, url: string) => {
      TagManager.event({
        eventElement: "Dashboard",
        eventAction: GAContextLog.action,
        eventValue: GAContextLog.label
      });
      setCarDashboardUrl(url, `metrics ${GAContextLog.label}`);
      toggleMetricsDrawer();
    };

    timerControl.current.remainingTime = refreshRate;
    timerControl.current.startTime = new Date();

    (async function () {
      try {
        const response = await getPartyBar(dealerId, savedFilter.value);

        if (response && !response.hasError && response.data) {
          const hasManagerMetrics = deriveHasManagerMetrics();
          const metrics = mapPartyBar(
            response.data,
            hasManagerMetrics,
            onMetricClick,
            toggleMetricsDrawer
          ) || {
            cardMetrics: [],
            widgetMetrics: []
          };
          ReactDOM.unstable_batchedUpdates(() => {
            setMetrics(metrics);
            setIsLoading(false);
            setHasError(false);
          });
        } else {
          ReactDOM.unstable_batchedUpdates(() => {
            setIsLoading(false);
            setHasError(true);
          });
          if (response.statusCode === 403) {
            stopPolling.current = true;
          }
        }
      } catch (e) {
        ReactDOM.unstable_batchedUpdates(() => {
          setIsLoading(false);
          setHasError(true);
        });
      }
    })();
  }, [
    dealerId,
    deriveHasManagerMetrics,
    getPartyBar,
    getMetricsWhenThisValueChanges,
    savedFilter.value,
    setCarDashboardUrl
  ]);

  const stopPollingWindowNotVisible = () => {
    if (document.visibilityState === "hidden") {
      timerControl.current.remainingTime =
        (timerControl.current.remainingTime as number) -
        (new Date().valueOf() -
          (timerControl.current.startTime as Date).valueOf());
      window.clearTimeout(timerControl.current.timeoutId);
    }
    if (document.visibilityState === "visible") {
      if (!stopPolling.current) {
        const timeoutId = window.setTimeout(() => {
          isInitialCall.current = false;
          setGetMetricsWhenThisValueChanges(b => b + 1);
        }, timerControl.current.remainingTime as number);
        timerControl.current.timeoutId = timeoutId;
        timerControl.current.startTime = new Date();
      }
    }
  };

  useEffect(() => {
    addVisibilityChangeEventListener(stopPollingWindowNotVisible);
    return () => {
      removeVisibilityChangeEventListener(stopPollingWindowNotVisible);
    };
  }, []);

  useEffect(() => {
    if (!stopPolling.current) {
      const time = isInitialCall.current
        ? Math.floor(Math.random() * refreshRate + 1)
        : refreshRate;
      const timeoutId = window.setTimeout(() => {
        isInitialCall.current = false;
        setGetMetricsWhenThisValueChanges(b => b + 1);
      }, time);
      timerControl.current.remainingTime = time;
      timerControl.current.timeoutId = timeoutId;
      timerControl.current.startTime = new Date();
    }

    return () => {
      return window.clearTimeout(timerControl.current.timeoutId);
    };
  });

  const toggleMetricsDrawer = () => {
    setShowMetricsDrawer(b => !b);
  };

  const onFilterChange = (name: string, value: number) => {
    TagManager.event({
      eventElement: "Party Bar",
      eventAction: "Party Bar Date Range Option Clicked",
      eventValue: `Filtered by Date Range: ${name}`
    });
    setSavedFilter({ label: name, value });
  };

  return (
    <StyledActivity>
      <ActivityBar
        metrics={widgetMetrics}
        onFilterClick={toggleMetricsDrawer}
        {...{ isDarkTheme, isLoading, savedFilter }}
      />
      <SlidingDrawer
        htmlId="cx-top-nav-dashboard-sliding-drawer"
        panelWidth="300px"
        position="left"
        show={showMetricsDrawer}
        onHide={toggleMetricsDrawer}
      >
        <ActivityCards
          hasManagerMetrics={deriveHasManagerMetrics()}
          metrics={cardMetrics}
          {...{ hasError, isLoading, onFilterChange, savedFilter }}
        />
      </SlidingDrawer>
    </StyledActivity>
  );
}

export default React.memo(Activity);
