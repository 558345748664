import { ReactElement } from "react";
import styled from "styled-components";

export interface KeyInformationHeaderIconProps {
  icon: ReactElement | undefined;
  htmlId: string;
}

const StyledKeyInformationHeaderIcon = styled.span`
  max-width: 80px;
  padding-right: 1em;
  transform: none;
  svg {
    transform: none !important;
  }
`;

export function KeyInformationHeaderIcon({
  icon,
  htmlId
}: KeyInformationHeaderIconProps) {
  return (
    <StyledKeyInformationHeaderIcon id={htmlId}>
      {icon}
    </StyledKeyInformationHeaderIcon>
  );
}

export default KeyInformationHeaderIcon;
