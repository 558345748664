import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from "react";
import {
  PortalComponentProps,
  PortalContextActionTypes,
  PortalEventChannels,
  PortalEventMessage,
  PortalFactoryRegistry,
  PortalMessage,
  PortalsContextAction,
  PortalsContextValue,
  RegisteredPortal
} from "../interfaces/portals";
import { createLogger } from "@vinsolutions/logger";
import { usePortalListener } from "../hooks/use-portal-listener";

const logger = createLogger("portals-context");

const PortalsContext = createContext<PortalsContextValue | undefined>(
  undefined
);

const registeredPortalsReducer = (
  prevState: RegisteredPortal<PortalComponentProps>[],
  action: PortalsContextAction
) => {
  let registeredPortals;
  switch (action.type) {
    case PortalContextActionTypes.createPortals: {
      registeredPortals = [...prevState];
      const registeredPortalFactory =
        action.registerPortalMessage && action.portalFactoryRegistry
          ? action.portalFactoryRegistry[action.registerPortalMessage.portalId]
          : null;
      const registeredPortal = action.registerPortalMessage
        ? registeredPortalFactory?.create(action.registerPortalMessage)
        : null;
      if (registeredPortal) {
        let existingPortalCount = 0;
        registeredPortals.forEach(existingPortal => {
          if (existingPortal.portalId === action.targetPortalId) {
            logger.warn(
              `Attempt to create a portal id (${
                action.registerPortalMessage?.portalId || "unknown"
              }) that has already been created. Replacing the matching existing portals.`
            );
            existingPortal.portalId += `-to-delete-${++existingPortalCount}`;
            existingPortal.isMarkedForDeletion = true;
            existingPortal.replacedOriginChildren = [];
          }
        });
        registeredPortals.push(registeredPortal);
      } else {
        if (action.registerPortalMessage?.portalProps?.portalEnabledCallback) {
          action.registerPortalMessage.portalProps.portalEnabledCallback(false);
        }
        logger.error(
          `Attempt to create a portal (${
            action.registerPortalMessage?.portalId || "unknown"
          }) that doesn't exist in the portal registry`
        );
      }
      return registeredPortals;
    }
    case PortalContextActionTypes.deletePortals: {
      if (action.targetPortalId) {
        return [...prevState].filter(
          registeredPortal =>
            registeredPortal.portalId !== action.targetPortalId
        );
      }
      return [...prevState];
    }
    case PortalContextActionTypes.showPortal:
    case PortalContextActionTypes.hidePortal:
    case PortalContextActionTypes.markForDeletion: {
      if (action.targetPortalId) {
        registeredPortals = [...prevState].map(registeredPortal => {
          return {
            ...registeredPortal,
            isHidden:
              registeredPortal.portalId === action.targetPortalId
                ? action.type !== PortalContextActionTypes.showPortal
                : registeredPortal.isHidden,
            isMarkedForDeletion:
              registeredPortal.portalId === action.targetPortalId
                ? action.type === PortalContextActionTypes.markForDeletion
                : registeredPortal.isMarkedForDeletion
          };
        });
        return registeredPortals;
      }
      return [...prevState];
    }
    default:
      return [...prevState];
  }
};

export interface PortalContextProps {
  portalFactoryRegistry: PortalFactoryRegistry;
  portalEventChannels: PortalEventChannels;
  children: ReactNode;
}

const PortalsContextProvider = ({
  portalFactoryRegistry,
  portalEventChannels,
  children
}: PortalContextProps) => {
  const [registeredPortals, registeredPortalsDispatch] = useReducer(
    registeredPortalsReducer,
    []
  );

  useEffect(() => {
    registeredPortals.forEach(registerPortal => {
      if (registerPortal.isMarkedForDeletion === true) {
        registeredPortalsDispatch({
          type: PortalContextActionTypes.deletePortals,
          targetPortalId: registerPortal.portalId,
          portalFactoryRegistry
        });
      }
    });
  }, [registeredPortals, portalFactoryRegistry]);

  const createPortals = useCallback(
    (eventDetail: PortalEventMessage) => {
      eventDetail.registerPortals.forEach(registerPortal => {
        const originElement =
          registerPortal.targetElement ||
          eventDetail.originDocument?.getElementById(
            registerPortal.targetElementId || ""
          );
        if (originElement !== null && originElement !== undefined) {
          const portalKey = crypto.randomUUID();
          const portalHtmlId = `${originElement.id}-portal`;
          const registerPortalMessage: PortalMessage = {
            originDocument: eventDetail.originDocument,
            originElement,
            portalId: registerPortal.portalId,
            portalKey,
            portalProps: {
              portalId: registerPortal.portalId,
              data: eventDetail.data,
              portalHtmlId,
              portalEnabledCallback: registerPortal?.portalEnabledCallback
            }
          };
          registeredPortalsDispatch({
            type: PortalContextActionTypes.createPortals,
            portalFactoryRegistry,
            registerPortalMessage,
            targetPortalId: registerPortal.portalId
          });
        } else {
          logger.error(
            `Failed to find portal target element id ${registerPortal.targetElementId} on the provided frame`
          );
        }
      });
    },
    [portalFactoryRegistry]
  );

  /**
   * Marks provided portals for deletion.
   * This first hides the portal so any removed children are restored before the registered portal is deleted.
   */
  const preparePortalsForDeletion = useCallback(
    (eventDetail: PortalEventMessage) => {
      eventDetail.registerPortals.forEach(registerPortal => {
        registeredPortalsDispatch({
          type: PortalContextActionTypes.markForDeletion,
          targetPortalId: registerPortal.portalId,
          portalFactoryRegistry
        });
      });
    },
    [portalFactoryRegistry]
  );

  const setPortalVisibility = useCallback(
    (targetPortalId: string, isVisible: boolean) => {
      registeredPortalsDispatch({
        type: isVisible
          ? PortalContextActionTypes.showPortal
          : PortalContextActionTypes.hidePortal,
        targetPortalId,
        portalFactoryRegistry
      });
    },
    [portalFactoryRegistry]
  );

  usePortalListener(portalEventChannels.createPortals, createPortals);
  usePortalListener(
    portalEventChannels.deletePortals,
    preparePortalsForDeletion
  );
  return (
    <PortalsContext.Provider value={{ registeredPortals, setPortalVisibility }}>
      {children}
    </PortalsContext.Provider>
  );
};

export const usePortalsContext = () => {
  const portalsContext = useContext(PortalsContext);
  if (portalsContext === undefined) {
    throw new Error(
      "usePortalsContext must be used within a PortalsContextProvider"
    );
  }
  return portalsContext;
};

export default PortalsContextProvider;
