import { NavigationItemsEntity } from "@vinsolutions/ccrm/interfaces";
import {
  adminNavigationItemEntityIds,
  campaignsNavigationItemEntityIds,
  crmNavigationItemEntityIds,
  ilmNavigationItemEntityIds,
  insightsNavigationItemEntityIds,
  inventoryNavigationItemEntityIds,
  serviceCenterNavigationItemEntityIds,
  settingsNavigationItemEntityIds,
  vinLensNavigationItemEntityIds
} from "./navigation-ids";

interface UrlTargetPair {
  url: string;
  target?: UrlTarget;
}

type UrlTarget = "_blank";

export const overrideNavigationUrls = (
  navigationItemsEntities: NavigationItemsEntity[]
) => {
  overrideMappedUrls(navigationItemsEntities);
  overrideDynamicCarDashboardUrls(navigationItemsEntities);
  overrideRecentCustomersUrls(navigationItemsEntities);
};

export const overrideMap = new Map<string, UrlTargetPair>([
  [
    adminNavigationItemEntityIds.cdrImportSettings,
    {
      url: "/CarDashboard/Pages/dynamicframe.aspx?SelectedTab=t_Admin&leftpaneframe=CRM/CDR/CDRImportSettings.aspx&rightpaneframe=HIDE"
    }
  ],
  [
    settingsNavigationItemEntityIds.billing,
    { url: "https://www.vuebill.com/vinsolutions", target: "_blank" }
  ],
  [
    campaignsNavigationItemEntityIds.autoMarketing,
    { url: "/CarDashboard/Pages/Campaigns/DIYV2/DIYV2StartPage.aspx" }
  ],
  [
    campaignsNavigationItemEntityIds.campaigns,
    { url: "/CarDashboard/Pages/Campaigns/DIYV2/DIYV2StartPage.aspx" }
  ],
  [
    inventoryNavigationItemEntityIds.viewBmwPipeline,
    {
      url: "/CarDashboard/Pages/Search/VcdaVehicleSearch.aspx",
      target: "_blank"
    }
  ],
  [
    inventoryNavigationItemEntityIds.locateGmVehicle,
    {
      url: "/CarDashboard/Pages/Search/VehicleSearch.aspx",
      target: "_blank"
    }
  ]
]);

export const overrideMappedUrls = (
  navigationItemEntities: NavigationItemsEntity[]
) => {
  overrideMap.forEach(({ url, target }, id) => {
    overrideUrl(navigationItemEntities, id, url, target);
  });
};

export const overrideUrl = (
  navigationItemsEntities: NavigationItemsEntity[],
  id: string,
  url: string,
  target?: UrlTarget
) => {
  const founditem = navigationItemsEntities.filter(item => item.id === id)[0];
  if (founditem) {
    if (target) founditem.target = target;
    founditem.url = url;
  }
};

// These menu navigation urls dynamically change based on current location in CarDashboard.
export const overrideDynamicCarDashboardUrls = (
  navigationItemsEntities: NavigationItemsEntity[]
) => {
  const foundEntities = navigationItemsEntities.filter(
    item =>
      item.id === vinLensNavigationItemEntityIds.vinlens ||
      item.id === insightsNavigationItemEntityIds.insights ||
      item.id === serviceCenterNavigationItemEntityIds.serviceCenter
  );

  foundEntities.forEach(item => {
    if (item.url) {
      item.url = generateOverriddenDynamicCarDashboardUrl(item.url);
    }
  });
};

export const generateOverriddenDynamicCarDashboardUrl = (url: string) => {
  // Depending what page you are on in CD, the url being scraped changes.
  // This code is to make the url always the same.

  const baseUrlStartIndex = url.indexOf("dynamicframe");

  if (baseUrlStartIndex !== -1) {
    return "/CarDashboard/Pages/" + url.substring(baseUrlStartIndex);
  } else {
    return url;
  }

  return "";
};

export const overrideRecentCustomersUrls = (
  navigationItemsEntities: NavigationItemsEntity[]
) => {
  // recentViewCustomer CRM or ILM
  const recentlyViewedCustomersEntities = navigationItemsEntities.filter(
    item =>
      item.id.indexOf(crmNavigationItemEntityIds.recentViewedCustomers) !==
        -1 ||
      item.id.indexOf(ilmNavigationItemEntityIds.recentViewedCustomers) !== -1
  );

  const functionName = "viewRecentCustomer";

  recentlyViewedCustomersEntities.forEach(item => {
    if (item.url.indexOf(functionName) !== -1) {
      let gcid = item.url.split(functionName)[1];
      gcid = gcid.slice(1, gcid.length - 2);

      item.url =
        "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=HIDE&rightpaneframe=/CarDashboard/Pages/CRM/CustomerDashboard.aspx?GlobalCustomerID=" +
        gcid +
        "&r=" +
        Math.random();
    }
  });
};
