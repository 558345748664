import styled from "styled-components";
import {
  DynamicHeaderComponents,
  ThemeState
} from "@vinsolutions/ccrm/interfaces";

const mobileBreakPoint = "990px";

type StyledHeaderType = {
  background: string;
  showNavigation: boolean;
  dynamicHeaderComponents: DynamicHeaderComponents | undefined | null;
};

const resolveTemplateRows = (
  showNavigation: boolean,
  dynamicHeaderComponents?: DynamicHeaderComponents | null
) => {
  if (!showNavigation) {
    return "75px";
  }
  let secondRow: string;
  if (
    dynamicHeaderComponents?.logo ||
    dynamicHeaderComponents?.activityBar ||
    dynamicHeaderComponents?.account
  ) {
    secondRow = "75px";
  } else {
    secondRow = "0px";
  }

  return `min-content ${secondRow} 38px 32px`;
};
/**
 * The Header styled component
 * @param {boolean} showNavigation This is true if the getDeskingComponentsToShow.navigation is true
 */
export const StyledHeader = styled.div<StyledHeaderType>`
  display: grid;
  grid-template-columns: 140px min-content 1fr min-content 61px;
  grid-template-rows: ${({ showNavigation, dynamicHeaderComponents }) => {
    return resolveTemplateRows(showNavigation, dynamicHeaderComponents);
  }};
  grid-template-areas: ${({ showNavigation }) =>
    showNavigation
      ? `"versioncontrol versioncontrol versioncontrol versioncontrol versioncontrol"
        "logo activity . usercontext account"
        "tabs tabs tabs search search"
        "navigation navigation navigation navigation navigation"`
      : `"logo activity . usercontext account"`};
  grid-gap: 0 16px;
  background-color: ${({ background }) => background};

  @media (max-width: ${mobileBreakPoint}) {
    grid-template-columns: min-content 1fr min-content 61px;
    grid-template-areas: ${({ showNavigation }) =>
      showNavigation
        ? `"versioncontrol versioncontrol versioncontrol versioncontrol"
        "activity . usercontext account"
          "tabs tabs search search"
          "navigation navigation navigation navigation"`
        : `"activity . usercontext account"`};
  }

  .btn-link:hover,
  .btn-link:focus,
  .btn-link:active,
  .btn-:focus-visible {
    background: none;
    box-shadow: none;
    border-color: transparent;
  }
`;

const StyledLogo = styled.div`
  grid-area: "logo";
  height: 40px;
  width: 140px;
  margin: 16px;
  cursor: pointer;

  @media (max-width: ${mobileBreakPoint}) {
    display: none;
  }
`;

const StyledActivity = styled.div`
  grid-area: activity;
  margin: 16px 0;
`;

// prettier-ignore
const StyledUserContext = styled.div<Pick<ThemeState, "divider" | "actionItem">>`
  grid-area: usercontext;
  margin: 16px 0;
  border-right: ${({ divider }) => `1px solid ${divider}`};

  #user-selector {
    justify-content: flex-end;
    display: flex;
    flex: 1 1 0;
    margin-right: 16px;
  }

  #ccrm-header-display-button {
    color: ${({ actionItem }) => actionItem};
    justify-content: flex-end;
    width: 100%;
  }
`;

const StyledAccount = styled.div`
  grid-area: account;
  margin: 16px 16px 0 0;
`;

const StyledTabs = styled.div`
  grid-area: tabs;
  max-width: 100%;
`;

const StyledSearch = styled.div`
  grid-area: search;
  margin: 0 16px 0 0;
  display: flex;
  flex: 1 1 0;
  justify-self: end;
`;

const StyledNavigation = styled.div<Pick<ThemeState, "secondary">>`
  grid-area: navigation;
  display: grid;
  grid-template-columns: 1fr min-content;
  justify-content: space-between;
  background-color: ${({ secondary }) => secondary};
`;

const StyledWalkMeLink = styled.div`
  white-space: nowrap;
  display: flex;
`;

const StyledWalkMeButton = styled.div`
  display: flex;
  padding-bottom: 2px;
`;

const StyledUserSelector = styled.div<Pick<ThemeState, "actionItem">>`
  .btn {
    color: ${({ actionItem }) => actionItem};
    padding: 0;

    a {
      color: ${({ actionItem }) => actionItem};
      padding: 0;
    }
  }
`;

const StyledVersionControl = styled.div`
  grid-area: versioncontrol;
`;

const retval = {
  StyledAccount,
  StyledActivity,
  StyledHeader,
  StyledLogo,
  StyledNavigation,
  StyledSearch,
  StyledTabs,
  StyledUserContext,
  StyledUserSelector,
  StyledVersionControl,
  StyledWalkMeButton,
  StyledWalkMeLink
};
export default retval;
