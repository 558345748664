import { useCallback, useEffect, useState } from "react";
import CustomerEnrichmentBannerProps from "./interfaces/customer-enrichment-banner-props";
import CustomerEnrichmentIconSmall from "./icons/customer-enrichment-icon-small";
import { KeyInformationBanner } from "@vinsolutions/ui/key-information-banner";
import CustomerEnrichmentIconSmallInverse from "./icons/customer-enrichment-icon-small-inverse";
import CustomerEnrichmentDate from "./components/customer-enrichment-date";
import { CustomerEnrichmentSummary } from "./components/customer-enrichment-summary";
import CustomerEnrichmentModal from "./components/customer-enrichment-modal";
import CustomerEnrichmentIcon from "./icons/customer-enrichment-icon";

const bannerName = "Customer Enrichment";

export const CustomerEnrichmentBaseDisplayedBanner = {
  bannerName,
  iconName: "CustomerEnrichmentIconSmall",
  thumbnailName: "CustomerEnrichmentIconSmall",
  thumbnailIcon: <CustomerEnrichmentIconSmall />
};

export function CustomerEnrichmentBanner({
  automotiveIntelligenceSettings,
  customerId,
  dealerId,
  onClickGtmEvent,
  onLoad
}: CustomerEnrichmentBannerProps) {
  const [customerEnrichmentIcon, setCustomerEnrichmentIcon] = useState(
    CustomerEnrichmentIconSmall
  );
  const [showModal, setShowModal] = useState(false);
  const loadCustomerEnrichmentData = useCallback(async () => {
    const isDisplayed =
      automotiveIntelligenceSettings?.isCustomerEnrichmentEnabled ?? false;
    onLoad({
      ...CustomerEnrichmentBaseDisplayedBanner,
      customerId,
      isDisplayed
    });
  }, [automotiveIntelligenceSettings, customerId, onLoad]);

  useEffect(() => {
    loadCustomerEnrichmentData();
  }, [loadCustomerEnrichmentData]);

  const onBannerClick = useCallback(() => {
    onClickGtmEvent?.(bannerName);
    setShowModal(true);
  }, [onClickGtmEvent]);

  return (
    automotiveIntelligenceSettings &&
    automotiveIntelligenceSettings?.isCustomerEnrichmentEnabled && (
      <>
        <KeyInformationBanner
          bannerDataDetail={<CustomerEnrichmentDate />}
          bannerIcon={<CustomerEnrichmentIcon icon={customerEnrichmentIcon} />}
          bannerName={bannerName}
          bannerSummary={<CustomerEnrichmentSummary />}
          onClick={onBannerClick}
          onMouseEnter={() =>
            setCustomerEnrichmentIcon(CustomerEnrichmentIconSmallInverse)
          }
          onMouseLeave={() =>
            setCustomerEnrichmentIcon(CustomerEnrichmentIconSmall)
          }
        />
        <CustomerEnrichmentModal
          customerId={customerId}
          dealerId={dealerId}
          showModal={showModal}
          onHide={() => setShowModal(false)}
        />
      </>
    )
  );
}
