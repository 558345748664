import { RouteBuilder } from "../route-builder";
import { PageEmailSetupStatusCheck } from "@vinsolutions/page/email-setup-status-check";

export const domainSetupValidationRoute = RouteBuilder.create()
  .withRouteId("vinconnect", "email-setup-status-check")
  .withAliasId(
    "navigation-sub-menu-tab-settings-dealer-settings-email-setup-status-check"
  )
  .withFullComponent(
    <PageEmailSetupStatusCheck
      htmlId={
        "navigation-sub-menu-tab-settings-dealer-settings-email-setup-status-check"
      }
    />
  )
  .build();
