// this file could be moved to a util lib if we feel like it. It also needs tests

import { getConfig as getRealConfig } from "@vinsolutions/core/config";

declare global {
  interface Window {
    Cypress: any;
  }
}
/**
 * @deprecated The method should not be used
 */
export const getConfigFromEnvironment = (environmentName: string) => {
  return getRealConfig();
};

/**
 * @deprecated The method should not be used
 */
export const deriveUrl = (url?: string) => {
  if (url) return url;
  return window.location.href;
};

export const deriveQueryParam = (environment: string, url: string) => {
  return environment === "local" &&
    url.indexOf("CXTopNavHelper_LocalOverride") > -1
    ? url.indexOf("?") > 0
      ? "&CXTopNavHelper_LocalOverride=true"
      : "?CXTopNavHelper_LocalOverride=true"
    : "";
};
/**
 * @deprecated The method should not be used
 */
export const isLocal = (url: string) => {
  return url.startsWith("http://localhost");
};
/**
 * @deprecated The method should not be used
 */
export const isCypress = () => {
  const currentWindow = window as any;
  return currentWindow.Cypress !== undefined && currentWindow.Cypress !== null;
};

/**
 * @deprecated The method should not be used
 */
export const deriveCardashboardBaseUrl = (): string => {
  const config = getRealConfig();
  return config.carDashboardUrl;
};

export const deriveCardashboardUrl = () => {
  const href = window.location.href.replace("/?cdInitialized=true", "");
  const config = getRealConfig();
  const baseUrl = config.carDashboardUrl + "/CarDashboard";

  if (href.search(/\/cardashboard\//i) !== -1) {
    const existingPath = href
      .substring(href.search(/\/cardashboard\//i))
      .replace(/\/cardashboard\//i, "/");

    return baseUrl + existingPath;
  }

  return baseUrl;
};
