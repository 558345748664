import { OidcUser, parseAccessToken } from "@vinsolutions/tenant/jwt-utils";
import { environments } from "@vinsolutions/core/config";
import { BridgeBarSettings, BridgeEnvironments } from "./brigeBarSettings";
import { TenantBridgeBarProps } from "./tenant-bridge-bar";
import config from "./config";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("tenant-bridge-bar");

export const isEnvironmentEnabled = (environment: environments) => {
  if (config.enabledEnvironments.indexOf(environment) < 0) return false;
  return true;
};

export const isUserLoggedIntoBridge = (user: OidcUser) => {
  const accessToken = parseAccessToken(user);
  if (!accessToken) return false;
  return accessToken.isBridgeUser === "True";
};

export const shouldShowBridgeBar = (props: TenantBridgeBarProps) => {
  if (props.forceBridgeBar) {
    logger.debug("forceBridgeBar is true");
    return true;
  }
  if (!isEnvironmentEnabled(props.environment)) {
    logger.debug("environment is not enabled");
    return false;
  }
  if (!isUserLoggedIntoBridge(props.odicUser)) {
    logger.debug("user is not logged into bridge");
    return false;
  }
  logger.debug("shouldShowBridgeBar is true");
  return true;
};

export const getBridgeBarHeight = (props: TenantBridgeBarProps) => {
  if (!shouldShowBridgeBar(props)) return "0px";
  return config.bridgeBarHeight;
};

function getBridgeEnv(props: TenantBridgeBarProps): BridgeEnvironments {
  switch (props.environment) {
    case environments.dev:
      return "dev";
    case environments.qaCoxAutoDomain:
    case environments.qaCDN:
    case environments.qaOnPrem:
      return "qa";
    case environments.prodCoxAutoDomain:
    case environments.prod:
      return "production";
    default:
      return "production";
  }
}

export const getBridgeBarScript = (props: TenantBridgeBarProps) => {
  const getBridgeEnvironmentName: BridgeEnvironments = getBridgeEnv(props);
  logger.debug("getBridgeEnvironmentName", getBridgeEnvironmentName);

  const bridgeBarSettings = {
    solutionGroupCode: props.solutionGroupCode,
    sessionId: props.odicUser.access_token,
    env: getBridgeEnvironmentName
  } as BridgeBarSettings;

  const inlineScript = `var bbEl = document.querySelector('bridge-bar');
    bbEl.addEventListener('initialized', function() {
      bbEl.bb.render(${JSON.stringify(bridgeBarSettings)});
    });`;
  return inlineScript;
};
