import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasDealListProductAccess } from "@vinsolutions/ccrm/api";
import { MainNavigationState } from "@vinsolutions/ccrm/interfaces";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("main-navigation.slice");

export const MAIN_NAVIGATION_FEATURE_KEY = "mainNavigation";

export const initialMainNavigationState: MainNavigationState = {
  activeTabId: null,
  crwDealListEnabled: false,
  loadingStatus: "not loaded",
  isLeftPaneExpanded: false,
  isRightPaneExpanded: false
};

export const fetchDealListAccess = createAsyncThunk(
  "userProfile/fetchDealListAccess",
  async (authToken: string, thunkAPI) => {
    return await hasDealListProductAccess(authToken);
  }
);

export const mainNavigationSlice = createSlice({
  name: MAIN_NAVIGATION_FEATURE_KEY,
  initialState: initialMainNavigationState,
  reducers: {
    setActiveTab: (state, action) => {
      return { ...state, activeTabId: action.payload };
    },
    setCRWDealListEnabled: (state, action) => {
      return { ...state, crwDealListEnabled: action.payload };
    },
    toggleLeftPaneExpanded: state => {
      state.isLeftPaneExpanded = !state.isLeftPaneExpanded;

      if (state.isLeftPaneExpanded) {
        state.isRightPaneExpanded = false;
      }
    },
    setLeftPaneExpanded: (state, action: PayloadAction<boolean>) => {
      state.isLeftPaneExpanded = action.payload;

      if (action.payload) {
        state.isRightPaneExpanded = false;
      }
    },
    toggleRightPaneExpanded: state => {
      state.isRightPaneExpanded = !state.isRightPaneExpanded;

      if (state.isRightPaneExpanded) {
        state.isLeftPaneExpanded = false;
      }
    },
    setRightPaneExpanded: (state, action: PayloadAction<boolean>) => {
      state.isRightPaneExpanded = action.payload;

      if (action.payload) {
        state.isLeftPaneExpanded = false;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDealListAccess.pending, (state: MainNavigationState) => {
        state.loadingStatus = "loading";
      })
      .addCase(
        fetchDealListAccess.fulfilled,
        (state: MainNavigationState, action: PayloadAction<boolean>) => {
          state.loadingStatus = "loaded";
          state.crwDealListEnabled = action.payload;
        }
      )
      .addCase(
        fetchDealListAccess.rejected,
        (state: MainNavigationState, action) => {
          state.loadingStatus = "error";
          state.crwDealListEnabled = false;
        }
      );
  }
});

/*
 * Export reducer for store configuration.
 */
export const mainNavigationReducer = mainNavigationSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(mainNavigationActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const mainNavigationActions = mainNavigationSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllMainNavigation);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */

export const getMainNavigationState = (rootState: any): MainNavigationState => {
  logger.debug("getMainNavigationState: " + MAIN_NAVIGATION_FEATURE_KEY);
  const state = rootState[MAIN_NAVIGATION_FEATURE_KEY];
  try {
    logger.debug("getMainNavigationState: " + JSON.stringify(state));
  } catch (e) {
    logger.error("getMainNavigationState: " + e);
  }

  return state;
};
