import { Button, Dropdown, IconSearch, TextInput } from "cx";
import { Radio } from "antd";
import styled from "styled-components";
import { searchModes } from "./search-bar-helper";
import { ChangeEvent, KeyboardEvent } from "react";
import { TagManager } from "@vinsolutions/core-third-party-gtm";

interface SearchBarUIProps {
  searchMode: searchModes;
  onSettingsChange: ({ target }: ChangeEvent<HTMLInputElement>) => void;
  onInputChange: (cxEvent: { target: { value: any } }) => void;
  searchTerm: string;
  handleOnKeyUp: ({ key }: KeyboardEvent<HTMLInputElement>) => void;
  onSearch: () => void;
}

const StyledSearchBarUI = styled.div`
  width: max-content;
  line-height: initial;
  display: inline-block;

  #cx-top-nav-search-bar-input-wrapper-inputWrapper {
    margin: 2px 0;
    display: inline-block;

    #cx-top-nav-search-bar-button {
      background-color: theme-var($--theme-secondary);
    }
  }

  .svgicon .icon-search {
    border: theme-var($--theme-secondary);
    background-color: theme-var($--theme-secondary);
    border-color: theme-var($--theme-on-secondary);
  }

  #cx-top-nav-search-bar-dropdown {
    z-index: 20;
    background-color: #fff;
    width: 42px;
    height: 32px;
    border: solid 1px #cacfd9;
    border-left-width: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-weight: 600;
    &-selectButton {
      padding: 8px;
      padding-top: 0;
      .toggleBtn-text {
        color: theme-var($--theme-secondary);
        font-weight: 800;
        font-size: 24px;
      }
    }

    button {
      width: 100%;
      height: 100%;
    }

    .dropdown-menu {
      right: -2px;
      left: initial;
      width: 240px;
      li {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        &:hover {
          background-color: #fff;
        }
        .ant-radio-wrapper {
          color: #6d727a;
          font-size: 16px;
          .ant-radio-inner {
            border-color: theme-var($--theme-secondary);
          }
          .ant-radio-inner::after {
            background-color: theme-var($--theme-secondary);
          }
        }
      }
    }
  }
`;

const SearchBarUI = ({
  searchMode,
  onSettingsChange,
  onInputChange,
  searchTerm,
  handleOnKeyUp,
  onSearch
}: SearchBarUIProps) => {
  const options = [];
  for (const value in searchModes) {
    options.push({
      label: value,
      component: <Radio checked={searchMode === value}>{value}</Radio>,
      onSelect: onSettingsChange,
      value
    });
  }
  return (
    <StyledSearchBarUI data-testid={"search-bar"} id="search-bar">
      <TextInput
        appendChild={
          <Dropdown
            buttonStyle="link"
            displayCaret={false}
            htmlId="cx-top-nav-search-bar-dropdown"
            name="searchBarDropdown"
            options={options}
            onToggle={() =>
              TagManager.event({
                eventElement: "TopNav: Search Bar",
                eventAction: "Three dot click",
                eventValue: "Displayed Options Menu"
              })
            }
          >
            ...
          </Dropdown>
        }
        className="cx-top-nav-search-bar-input"
        displayLabel={false}
        htmlId="cx-top-nav-search-bar-input-wrapper"
        label=""
        name=""
        placeholder={`Search ${searchMode}`}
        prependChild={
          <Button
            htmlId="cx-top-nav-search-bar-button"
            icon={<IconSearch />}
            onClick={() => {
              TagManager.event({
                eventElement: "TopNav: Search Bar",
                eventAction: "TopNav Search button clicked",
                eventValue: "Displayed Search Results Page"
              });
              onSearch();
            }}
          />
        }
        value={searchTerm}
        onChange={onInputChange}
        onKeyUp={handleOnKeyUp}
      />
    </StyledSearchBarUI>
  );
};

export default SearchBarUI;
