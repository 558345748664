import { DealerDashboardPage } from "@vinsolutions/page/dealer-dashboard";
import { RouteBuilder } from "../route-builder";
import { RouteButtonPosition } from "../route-config-types";

export const dealerDashboardWithCustomerRoute = RouteBuilder.create()
  .withRouteId("vinconnect", "dealer-dashboard-customer")
  .withAliasId(
    "navigation-sub-menu-tab-crm-dashboard-dealer-dashboard-customer"
  )
  .withLeftPaneComponent(<DealerDashboardPage />, {
    expansionButtonPosition: RouteButtonPosition.BOTTOM_RIGHT
  })
  .withRightPaneComponent("/CarDashboard/Pages/CRM/CustomerDashboard.aspx", {
    requiredQueryParams: ["GlobalCustomerID"],
    fallbackComponent: "DealerDashboard1.aspx"
  })
  .build();
