import { DMCAction } from "../types/dmcAction.type";
import { DMCStateType } from "../types/dmcState.type";
import { DMCType } from "../types/dmc.type";
import { DMC_TYPES } from "../constants/dmcTypes.constant";
import { DMCActionEnum } from "../Enums/dmcAction.enum";

const defaultDMC: DMCType = Object.freeze({
  key: 0,
  id: null,
  dealerId: 0,
  oemDealerId: "",
  oemId: 1,
  oemName: DMC_TYPES[1].name,
  status: "A",
  dealerInitiatedLeadEnabled: false,
  dealerInitiatedLeadDisabledUTC: null,
  oemLeadUpdateEnabled: false,
  oemLeadUpdateDisabledUTC: null
});

export const InitialState: DMCStateType = {
  dealerManufacturerCode: defaultDMC,
  dealerManufacturerCodes: [],
  isLoading: true,
  isSaveable: false
};

export const DMCReducer = (
  state: DMCStateType,
  {
    type,
    payload: {
      dealerManufacturerCode = defaultDMC,
      dealerManufacturerCodes = [],
      isLoading = false,
      isSaveable = false
    }
  }: DMCAction
): DMCStateType => {
  switch (type) {
    case DMCActionEnum.UPDATE_DMC_COLLECTION: {
      const dmcIndex = dealerManufacturerCodes.findIndex(
        dmc => dmc.id === dealerManufacturerCode.id
      );

      if (dmcIndex >= 0) {
        dealerManufacturerCodes[dmcIndex] = dealerManufacturerCode;
      } else {
        dealerManufacturerCodes.push(dealerManufacturerCode);
      }

      return {
        ...state,
        dealerManufacturerCodes: [...dealerManufacturerCodes],
        dealerManufacturerCode: defaultDMC
      };
    }
    case DMCActionEnum.SET_DMC_COLLECTION: {
      return {
        ...state,
        dealerManufacturerCodes,
        isLoading
      };
    }
    case DMCActionEnum.DMC_UPDATE: {
      return {
        ...state,
        dealerManufacturerCode,
        isSaveable
      };
    }
    case DMCActionEnum.DMC_DELETE: {
      const dmcCollection = dealerManufacturerCodes.filter(
        dmc => dmc.id !== dealerManufacturerCode.id
      );

      return {
        ...state,
        dealerManufacturerCode: defaultDMC,
        dealerManufacturerCodes: dmcCollection
      };
    }
    default:
      return state;
  }
};
