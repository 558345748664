import { combineReducers, configureStore } from "@reduxjs/toolkit";
import type { PreloadedState } from "@reduxjs/toolkit";
import { CcrmApi, versionControlApi } from "../rtk";

const rootReducer = combineReducers({
  [CcrmApi.reducerPath]: CcrmApi.reducer,
  [versionControlApi.reducerPath]: versionControlApi.reducer
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware(),
      CcrmApi.middleware,
      versionControlApi.middleware
    ],
    preloadedState
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
