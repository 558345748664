import { FC } from "react";
import PortalComponentProps, { RegisteredPortal } from "../interfaces/portals";
import styled, {
  FlattenSimpleInterpolation,
  StyleSheetManager
} from "styled-components";

type PortalInterfaceProps = {
  registeredPortal: RegisteredPortal<PortalComponentProps>;
  /** Style elements applied to created portal container elements.
   * Useful when needing to apply styles defined outside the document the portal is being rendered in. */
  portalStylesCss?: FlattenSimpleInterpolation | null;
};

const PortalInterface: FC<PortalInterfaceProps> = ({
  portalStylesCss,
  registeredPortal
}: PortalInterfaceProps) => {
  return (
    // Target the portal origin document so styled components styles get applied in the frame where the portal is being rendered
    <StyleSheetManager target={registeredPortal.originDocument?.head}>
      <StyledPortalComponentWrapper portalStylesCss={portalStylesCss}>
        <registeredPortal.portalComponent
          {...registeredPortal.portalComponentProps}
        />
      </StyledPortalComponentWrapper>
    </StyleSheetManager>
  );
};

const StyledPortalComponentWrapper = styled.div<{
  portalStylesCss?: FlattenSimpleInterpolation | null;
}>`
  ${props => props.portalStylesCss}
`;

export default PortalInterface;
