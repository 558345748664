import { BaseUrlsObj, getCurrentEnv } from "@vinsolutions/core/environment";
import { envEnum } from "@vinsolutions/core/environment";
import {
  AMP_CRM_GATEWAY_BFF_DEV,
  AMP_CRM_GATEWAY_BFF_LOCAL,
  AMP_CRM_GATEWAY_BFF_PRODUCTION,
  AMP_CRM_GATEWAY_BFF_QA,
  AMP_CRM_GATEWAY_BFF_STAGING
} from "./constants";

export type Config = {
  baseUrls: BaseUrlsObj;
};

const basePaths: Config = {
  baseUrls: {
    [envEnum.LOCAL]: AMP_CRM_GATEWAY_BFF_LOCAL,
    [envEnum.DEV]: AMP_CRM_GATEWAY_BFF_DEV,
    [envEnum.QA]: AMP_CRM_GATEWAY_BFF_QA,
    [envEnum.STAGING]: AMP_CRM_GATEWAY_BFF_STAGING,
    [envEnum.PRODUCTION]: AMP_CRM_GATEWAY_BFF_PRODUCTION
  }
};

export const getAmpBaseUrl = (): string => {
  const currentEnv = getCurrentEnv();
  const baseUrl = basePaths.baseUrls[currentEnv];
  return baseUrl;
};
