import OemInsightsBannerProps from "./interfaces/oem-insights-banner-container-props";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  OemInsightsSummaryResponse,
  OemPartnerKey,
  getOemInsightsSummary
} from "@vinsolutions/data-access/oem/vin.api.oeminsights";
import { useCallback, useEffect, useState } from "react";
import BmwInsightsBanner from "./components/bmw-mini/bmw-insights-banner";
import OemInsightsModal from "./components/oem-insights-modal";
import MiniInsightsBanner from "./components/bmw-mini/mini-insights-banner";
import FordInsightsBanner from "./components/ford/ford-insights-banner";
import GmInsightsBanner from "./components/gm/gm-banner";
import HondaInsightsBanner from "./components/honda-acura/honda-insights-banner";
import AcuraInsightsBanner from "./components/honda-acura/acura-insights-banner";
import HyundaiInsightsBanner from "./components/hyundai-genesis/hyundai-insights-banner";
import GenesisInsightsBanner from "./components/hyundai-genesis/genesis-insights-banner";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";

export function OemInsightsBanners({
  customerFirstName,
  customerId,
  customerLastName,
  dealerId,
  onInitialize,
  onLoad,
  onClickGtmEvent
}: OemInsightsBannerProps) {
  const [modalOemDisplay, setModalOemDisplay] = useState<OemPartnerKey | null>(
    null
  );
  const [oemInsightsSummary, setOemInsightsSummary] = useState<
    OemInsightsSummaryResponse | null | undefined
  >(null);
  const jwt = useReactOidc().oidcUser.access_token;

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "OemInsightsBanners",
    "Getting OEM Insights"
  );
  setInteractionAttributes({
    customerId: customerId || 0,
    dealerId: dealerId || 0
  });

  const loadOemInsightsSummary = useCallback(async () => {
    const summaryResult = customerId
      ? await getOemInsightsSummary(dealerId, customerId, jwt)
      : null;
    setOemInsightsSummary(summaryResult);
    endInteraction();
  }, [customerId, dealerId, jwt, endInteraction]);

  useEffect(() => {
    onInitialize?.(1);
    loadOemInsightsSummary();
  }, [loadOemInsightsSummary, onInitialize]);

  const onOemBannerClick = useCallback(
    (bannerName: string, oem: OemPartnerKey) => {
      onClickGtmEvent?.(bannerName);
      setModalOemDisplay(oem);
    },
    [onClickGtmEvent]
  );

  return (
    oemInsightsSummary && (
      <>
        <HyundaiInsightsBanner
          oemInsightsSummary={oemInsightsSummary}
          onClick={onOemBannerClick}
          onLoad={onLoad}
        />
        <GenesisInsightsBanner
          oemInsightsSummary={oemInsightsSummary}
          onClick={onOemBannerClick}
          onLoad={onLoad}
        />
        <BmwInsightsBanner
          oemInsightsSummary={oemInsightsSummary}
          onClick={onOemBannerClick}
          onLoad={onLoad}
        />
        <MiniInsightsBanner
          oemInsightsSummary={oemInsightsSummary}
          onClick={onOemBannerClick}
          onLoad={onLoad}
        />
        <FordInsightsBanner
          oemInsightsSummary={oemInsightsSummary}
          onClick={onOemBannerClick}
          onLoad={onLoad}
        />
        <GmInsightsBanner
          oemInsightsSummary={oemInsightsSummary}
          onLoad={onLoad}
        />
        <HondaInsightsBanner
          oemInsightsSummary={oemInsightsSummary}
          onClick={onOemBannerClick}
          onLoad={onLoad}
        />
        <AcuraInsightsBanner
          oemInsightsSummary={oemInsightsSummary}
          onClick={onOemBannerClick}
          onLoad={onLoad}
        />
        <OemInsightsModal
          customerFirstName={customerFirstName}
          customerId={customerId || 0}
          customerLastName={customerLastName}
          dealerId={dealerId}
          oem={modalOemDisplay}
          showModal={!!modalOemDisplay}
          onHide={() => setModalOemDisplay(null)}
        />
      </>
    )
  );
}

export default OemInsightsBanners;
