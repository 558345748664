import React, { Suspense, useCallback, useState } from "react";
import styled from "styled-components";
import { loadRemoteModule } from "../load-remote-module";
import { TextInput } from "cx";
import { LoadingIndicator } from "@vinsolutions/core-components";
import { useVinconnectFlags } from "@vinsolutions/core/third-party/launch-darkly";
import { shallowEqual, useSelector } from "react-redux";
import { getMainNavigationState } from "@vinsolutions/ccrm/store";

const CRWDealList = React.lazy(() => loadRemoteModule("crw", "./CRWDealList"));

const StyledDealListWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTextInput = styled(TextInput)`
  flex: 1;
`;

const StyledDealListContent = styled.div`
  flex: 10;
`;

export function CRWDealListModule() {
  const [devBridgeAccessToken, setDevBridgeAccessToken] = useState<string>("");
  const devEnvironment = process.env.NODE_ENV;
  const vinconnectFlags = useVinconnectFlags();
  const { crwDealListEnabled } = useSelector(
    getMainNavigationState,
    shallowEqual
  );

  const getDevBridgeJwt = useCallback((): string => {
    return devBridgeAccessToken;
  }, [devBridgeAccessToken]);

  // You must manually pass your bridge access token to the deal list module in dev until Bridge is available
  // This can be obtained at np-pipelines.app.coxautoinc.com/admin/session-info
  const devBridgeTokenChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDevBridgeAccessToken(event.target.value);
  };

  return devEnvironment === "development" ? (
    <StyledDealListWrapper>
      <StyledTextInput
        data-testid="text-input-dev-bridge-token"
        htmlId="textInputDevBridgeToken"
        label="Paste Your Dev Bridge Token Here From np-pipelines.app.coxautoinc.com/admin/session-info"
        maxLength={2000}
        name="textInputDevBridgeToken"
        value={devBridgeAccessToken}
        onChange={devBridgeTokenChanged}
      />
      {devBridgeAccessToken.length > 0 && (
        <StyledDealListContent>
          <Suspense
            fallback={
              <LoadingIndicator
                action="crw-deal-list-loaded"
                htmlId="crw-deal-list-loading"
              />
            }
          >
            <CRWDealList getAccessTokenOverride={getDevBridgeJwt} />
          </Suspense>
        </StyledDealListContent>
      )}
    </StyledDealListWrapper>
  ) : vinconnectFlags["nx.vinconnect.show-crw-deallist"] &&
    crwDealListEnabled ? (
    <StyledDealListWrapper>
      <Suspense
        fallback={
          <LoadingIndicator
            action="crw-deal-list-loaded"
            htmlId="crw-deal-list-loading"
          />
        }
      >
        <CRWDealList />
      </Suspense>
    </StyledDealListWrapper>
  ) : (
    <div />
  );
}

export default CRWDealListModule;
