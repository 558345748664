import { ReactElement } from "react";
import { KeyInformationTextIcon } from "@vinsolutions/ui/key-information-banner";

export interface VinessaIconProps {
  icon: ReactElement;
}

const VinessaIcon = ({ icon }: VinessaIconProps) => {
  return <KeyInformationTextIcon icon={icon} iconText="Vinessa" />;
};

export default VinessaIcon;
