import { useLazyQuery } from "@vinsolutions/ccrm/api";
import {
  addVersionControlActiveVersionLoadedEventListener,
  addVersionControlChangeEventListener,
  addVisibilityChangeEventListener,
  removeVersionControlActiveVersionLoadedEventListener,
  removeVersionControlChangeEventListener,
  removeVisibilityChangeEventListener,
  useMountEffect
} from "@vinsolutions/ccrm/util";
import { useCallback, useEffect, useRef, useState } from "react";
import { VersionControlCountdownAlert } from "./alert/version-control-countdown-alert";
import addMinutes from "./util/add-minutes";
import calculateTimeDifference from "./util/calculate-time-difference";
import styled from "styled-components";
import {
  gtmCountdownNotificationDisplayedToUser,
  gtmCountdownNotificationHiddenForRollback,
  gtmMultiVersionNotificationDisplayedToUser,
  gtmMultiversionNotificationHiddenForRollback,
  gtmNotificationHiddenBySystem,
  gtmPageReloadedByOtherWindow,
  gtmPageReloadedBySystem,
  gtmPageReloadedBySystemForCriticalUpdate
} from "./util/gtm-events";
import shouldUseCachedVersionControlData from "./util/should-use-cached-version-control-data";
import { NewRelic } from "@vinsolutions/core/third-party/newrelic";
import { getMetaDataState, metaDataActions } from "@vinsolutions/ccrm/store";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import VersionControlMultiVersionAlert from "./alert/version-control-multiversion-alert";

export interface VersionControlProps {
  minutesPriorToDeadlineToShowWarning: number;
  pollingInterval: number;
  reloadCallback: () => void;
}

const StyledVersionControlAlert = styled.div`
  margin: 16px 32px;
  width: calc(100vw - 65px);

  .cx-alert {
    width: 100%;
    max-width: 1100px;
    margin: auto;

    .cx-alert__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (max-width: 1199px) {
    .cx-alert {
      max-width: 944px;
    }
  }
`;

export function VersionControl({
  minutesPriorToDeadlineToShowWarning,
  pollingInterval,
  reloadCallback
}: VersionControlProps) {
  const dispatch = useDispatch();
  const [showCountdownAlert, setShowCountdownAlert] = useState(false);
  const [showMultiVersionAlert, setShowMultiVersionAlert] = useState(false);
  const [hasLatestVersion, setHasLatestVersion] = useState(false);
  const [debugEnabled, setDebugEnabled] = useState(
    localStorage.getItem("versionControlDebugEnabled") === "true"
  );
  const reloadTimer = useRef<number>(0);
  const pollTimer = useRef<number>(0);
  const lastPolled = useRef<string>("0");
  const [trigger, { data, error, isLoading }] = useLazyQuery();
  const { currentAppVersion } = useSelector(getMetaDataState, shallowEqual);

  const reloadAllTabs = useCallback(() => {
    localStorage.setItem(
      "vinconnectActiveVersionRefresh",
      data?.releaseVersion || ""
    );
    localStorage.removeItem("vinconnectActiveVersionRefresh");
    reloadCallback();
  }, [reloadCallback]);

  const log = useCallback(
    (message: string) => {
      if (debugEnabled) {
        // eslint-disable-next-line no-console
        console.log(message);
      }
    },
    [debugEnabled]
  );

  const beginPolling = (preferCacheValue = false) => {
    log(
      `Poll was triggered and preferCacheValue was ${preferCacheValue}. Polling interval is ${pollingInterval} milliseconds`
    );
    trigger(undefined, preferCacheValue);
    if (!preferCacheValue) {
      lastPolled.current = new Date().toUTCString();
    }
    pollTimer.current = window.setTimeout(() => {
      beginPolling();
    }, pollingInterval);
  };

  const visibilityChangeCallback = () => {
    if (document.visibilityState === "hidden") {
      log("Window is no longer visible");
      window.clearTimeout(pollTimer.current);
      pollTimer.current = 0;
    } else {
      if (pollTimer.current === 0) {
        const preferCacheValue = shouldUseCachedVersionControlData(
          lastPolled.current,
          pollingInterval
        );
        log(
          `Window is visible. Last pulled from the server at ${new Date(
            lastPolled.current
          )}. Should pull from cache? ${preferCacheValue}`
        );
        beginPolling(preferCacheValue);
      }
    }
  };

  const versionControlChangeCallback = (e: StorageEvent) => {
    if (
      e.key === "vinconnectActiveVersionRefresh" &&
      e.newValue !== currentAppVersion &&
      e.newValue !== null
    ) {
      log("Page reloaded by another window/tab");
      gtmPageReloadedByOtherWindow(reloadCallback);
    }
  };

  const versionControlActiveVersionLoadedCallback = (e: StorageEvent) => {
    if (
      e.key === "vinconnectActiveVersionLoaded" &&
      e.newValue !== currentAppVersion &&
      e.newValue !== null
    ) {
      log("New Version loaded in another window/tab");
      gtmMultiVersionNotificationDisplayedToUser();
      setHasLatestVersion(false);
      setShowMultiVersionAlert(true);
    }
  };

  useMountEffect(() => {
    log(
      "They see me polling... The entry point to the version control app was hit."
    );
    beginPolling();

    return () => {
      log("The component was unmounted and all polling has stopped.");
      window.clearTimeout(pollTimer.current);
    };
  });

  useEffect(() => {
    addVisibilityChangeEventListener(visibilityChangeCallback);
    addVersionControlChangeEventListener(versionControlChangeCallback);
    addVersionControlActiveVersionLoadedEventListener(
      versionControlActiveVersionLoadedCallback
    );

    return () => {
      removeVisibilityChangeEventListener(visibilityChangeCallback);
      removeVersionControlChangeEventListener(versionControlChangeCallback);
      removeVersionControlActiveVersionLoadedEventListener(
        versionControlActiveVersionLoadedCallback
      );
    };
  });

  useEffect(() => {
    if (isLoading === false && !error && currentAppVersion === null && data) {
      log(`The initial version is ${data.releaseVersion}`);
      dispatch(metaDataActions.setAppVersion(data.releaseVersion));
      NewRelic.addRelease("vinconnect", data.releaseVersion);
      localStorage.setItem(
        "vinconnectActiveVersionLoaded",
        data.releaseVersion
      );
      localStorage.removeItem("vinconnectActiveVersionLoaded");
    }
  }, [currentAppVersion, dispatch, isLoading, data, error, log]);

  const convertChangeEffectDateUtc = useCallback(() => {
    return data?.changeEffectDateUtc
      ? new Date(data.changeEffectDateUtc)
      : new Date();
  }, [data?.changeEffectDateUtc]);

  useEffect(() => {
    if (
      isLoading === false &&
      !error &&
      currentAppVersion !== null &&
      data &&
      data.changeEffectDateUtc &&
      currentAppVersion !== data.releaseVersion &&
      addMinutes(new Date(), pollingInterval) >
        new Date(data.changeEffectDateUtc)
    ) {
      const changeEffectDate = convertChangeEffectDateUtc();
      const timeDifference = calculateTimeDifference(changeEffectDate);
      log(`Polling will refresh the page in ${timeDifference} milliseconds`);
      reloadTimer.current = window.setTimeout(() => {
        log(`Timer ran out and the page is refreshing`);
        gtmPageReloadedBySystem(reloadAllTabs);
      }, timeDifference);
    }

    return () => {
      if (reloadTimer.current) {
        log(`The component was unmounted and the refresh timer was removed`);
        window.clearTimeout(reloadTimer.current);
      }
    };
  }, [
    currentAppVersion,
    data,
    error,
    isLoading,
    log,
    pollingInterval,
    reloadAllTabs,
    convertChangeEffectDateUtc
  ]);

  useEffect(() => {
    if (isLoading === false) {
      if (
        !error &&
        currentAppVersion !== null &&
        data &&
        data.changeEffectDateUtc &&
        currentAppVersion !== data.releaseVersion
      ) {
        log(
          `New Version detected: ${data.releaseVersion}. The update type is ${data.updateType}.`
        );
        if (data.updateType === "CRITICAL") {
          log("Reloading the page for a critical version change");
          gtmPageReloadedBySystemForCriticalUpdate(reloadAllTabs);
        } else if (
          addMinutes(new Date(), minutesPriorToDeadlineToShowWarning) >
          new Date(data.changeEffectDateUtc)
        ) {
          log(
            `The ${data.changeEffectDateUtc} is sooner than ${minutesPriorToDeadlineToShowWarning} minutes from now. Displaying the alert`
          );
          gtmCountdownNotificationDisplayedToUser();
          setShowCountdownAlert(true);
        } else if (showCountdownAlert === true) {
          log(
            `The ${data.changeEffectDateUtc} is later than ${minutesPriorToDeadlineToShowWarning} minutes from now. Hiding any existing alerts`
          );
          gtmNotificationHiddenBySystem();
          setShowCountdownAlert(false);
        }
      }
    }
  }, [
    currentAppVersion,
    data,
    error,
    isLoading,
    log,
    minutesPriorToDeadlineToShowWarning,
    showCountdownAlert,
    reloadAllTabs,
    convertChangeEffectDateUtc
  ]);

  useEffect(() => {
    if (
      isLoading === false &&
      !error &&
      showCountdownAlert === true &&
      data &&
      currentAppVersion === data.releaseVersion
    ) {
      log(
        `Countdown notification hidden because the latest version ${data.releaseVersion} is equivalent to the current version ${currentAppVersion}`
      );
      gtmCountdownNotificationHiddenForRollback();
      setShowCountdownAlert(false);
      if (reloadTimer.current !== 0) {
        reloadTimer.current = 0;
      }
    }
  }, [currentAppVersion, data, error, isLoading, log, showCountdownAlert]);

  useEffect(() => {
    if (
      isLoading === false &&
      !error &&
      showMultiVersionAlert === true &&
      data &&
      currentAppVersion === data.releaseVersion &&
      hasLatestVersion === true
    ) {
      log(
        `Multiversion notification hidden because the latest version ${data.releaseVersion} is equivalent to the current version ${currentAppVersion}`
      );
      gtmMultiversionNotificationHiddenForRollback();
      setShowMultiVersionAlert(false);
    }
  }, [
    currentAppVersion,
    data,
    error,
    hasLatestVersion,
    isLoading,
    log,
    showMultiVersionAlert
  ]);

  useEffect(() => {
    if (!error && isLoading === false && data) {
      setHasLatestVersion(true);
    }
  }, [data, error, isLoading]);

  return showCountdownAlert === true ? (
    <StyledVersionControlAlert>
      <VersionControlCountdownAlert
        changeEffectDateUtc={convertChangeEffectDateUtc()}
        refreshButtonClick={reloadAllTabs}
      />
    </StyledVersionControlAlert>
  ) : showMultiVersionAlert === true ? (
    <StyledVersionControlAlert>
      <VersionControlMultiVersionAlert refreshButtonClick={reloadCallback} />
    </StyledVersionControlAlert>
  ) : null;
}

export default VersionControl;
