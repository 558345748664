// eslint-disable-next-line @nx/enforce-module-boundaries
import { KeyInformationPortalFactory } from "@vinsolutions/feature/key-information";
import { CustomerDashboardCommunicationsButtonPortalFactory } from "@vinsolutions/sub-feature/unified-inbox";

const VinconnectPortalFactoryRegistry = {
  "portal-customer-dashboard-communications-button":
    new CustomerDashboardCommunicationsButtonPortalFactory(),
  "portal-key-information": new KeyInformationPortalFactory(),
  unknown: null
};

export default VinconnectPortalFactoryRegistry;
