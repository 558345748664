import { RouteBackButton } from "@vinsolutions/ccrm/top-nav/route-config";
import styled from "styled-components";
import { RouteQueryParams } from "./render-view";
import { CdPathFrame } from "./cd-path-frame";

const CDStyled = styled.div`
  grid-column: 1 / -1;
  grid-row: 1;
`;

function queryParamsToQueryString(queryParams: RouteQueryParams) {
  if (!queryParams) {
    return "";
  }

  return Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
}

interface Props {
  path?: string;
  routeBackButton?: RouteBackButton;
  queryParams?: RouteQueryParams;
}

export function CarDashboardComponentPane({
  path,
  queryParams,
  routeBackButton
}: Props): any {
  const pathWithQueryString = queryParams
    ? `${path}?${queryParamsToQueryString(queryParams)}`
    : path;

  return (
    <CDStyled key={`cd-styled-${JSON.stringify(queryParams)}`}>
      <CdPathFrame
        routeBackButton={routeBackButton}
        src={pathWithQueryString}
      />
    </CDStyled>
  );
}
