import { Card, ToastContainer } from "cx";
import { DMCProvider } from "./components/dmc-provider/dmc-provider.component";
import { DMCTable } from "./components/dmc-table/dmc-table.component";
import { DMCModal } from "./components/dmc-modal/dmc-modal.component";
import { DMCErrorBoundary } from "./components/dmc-error-boundary/dmc-error-boundary.component";

/**
 * Main entry point to the Dealer Manufacturer Codes
 * @constructor
 */
export const CcrmDealerManufacturerCodes = () => {
  return (
    <DMCProvider>
      <ToastContainer />
      <DMCErrorBoundary>
        <DMCModal />
        <Card
          header={<h3>Dealer Manufacturer Codes</h3>}
          htmlId="dealer-manufacturer-codes-card"
        >
          <DMCTable />
        </Card>
      </DMCErrorBoundary>
    </DMCProvider>
  );
};
