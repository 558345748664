import styled from "styled-components";
import { ReactElement } from "react";

export interface AMPIconProps {
  icon: ReactElement;
}

const StyledAmpIcon = styled.span`
  margin-top: 10px;
`;

const AMPIcon = ({ icon }: AMPIconProps) => {
  return (
    <StyledAmpIcon>
      {icon}
      <strong>AMP</strong>
    </StyledAmpIcon>
  );
};

export default AMPIcon;
