export default function MiniIconSmall() {
  return (
    <svg
      className="keyInfo-small-icon"
      height={23}
      id="keyInfo-mini-logo"
      viewBox="0 0 23 23"
      width={23}
    >
      <image
        fillRule="evenodd"
        height={23}
        width={23}
        xlinkHref="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEwOTciIHZpZXdCb3g9IjAgLjAxIDEzMi4yOTIgNTguMDA5IiB3aWR0aD0iMjUwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtNjYuMTQ2LjAxYTI5LjAwNSAyOS4wMDUgMCAwIDAgLTI0LjQ0NiAxMy40MDJoLTQxLjdsNC4xNTEgNC4xNTFoMzUuMzgzYTI5LjAwNSAyOS4wMDUgMCAwIDAgLTEuNTk0IDQuODM3aC0yOC45NTJsNC4xIDQuMWgyNC4xODJhMjkuMDA1IDI5LjAwNSAwIDAgMCAtLjEyOSAyLjUxNSAyOS4wMDUgMjkuMDA1IDAgMCAwIC4xMTYgMi4zMjJoLTE5LjMzMmw0LjEwMSA0LjEwMWgxNS44NjJhMjkuMDA1IDI5LjAwNSAwIDAgMCAxLjUzIDQuODM2aC0xMi41NTVsNC4xNSA0LjE1MmgxMC42MDJhMjkuMDA1IDI5LjAwNSAwIDAgMCAyNC41MyAxMy41OTMgMjkuMDA1IDI5LjAwNSAwIDAgMCAyNC41MzItMTMuNTkzaDEwLjZsNC4xNTItNC4xNTJoLTEyLjU1NWEyOS4wMDUgMjkuMDA1IDAgMCAwIDEuNTMtNC44MzZoMTUuODYybDQuMS00LjFoLTE5LjMzMmEyOS4wMDUgMjkuMDA1IDAgMCAwIC4xMTYtMi4zMjMgMjkuMDA1IDI5LjAwNSAwIDAgMCAtLjEyOC0yLjUxNWgyNC4xODFsNC4xLTQuMWgtMjguOTUzYTI5LjAwNSAyOS4wMDUgMCAwIDAgLTEuNTkyLTQuODM3aDM1LjM4Mmw0LjE1Mi00LjE1MWgtNDEuN2EyOS4wMDUgMjkuMDA1IDAgMCAwIC0yNC40NDYtMTMuNDAyem0wIDMuOTMyYTI1LjA3MyAyNS4wNzMgMCAwIDEgMjUuMDczIDI1LjA3MyAyNS4wNzMgMjUuMDczIDAgMCAxIC0yNS4wNzMgMjUuMDczIDI1LjA3MyAyNS4wNzMgMCAwIDEgLTI1LjA3NC0yNS4wNzMgMjUuMDczIDI1LjA3MyAwIDAgMSAyNS4wNzQtMjUuMDczem0tMTkuOTQ2IDE4LjU3NHYxMi45OTdoMi43MXYtMTAuMjNsMi44NiAxMC4yM2gyLjgwOGwyLjg2OS0xMC4yM3YxMC4yM2gyLjcxdi0xMi45OTdoLTQuMzc2bC0yLjU5MiA4Ljg2Ni0yLjYyMi04Ljg2NnptMTYuNzg3IDB2MTIuOTk3aDIuOTE3di0xMi45OTd6bTUuNzE3IDB2MTIuOTk3aDIuNzF2LTguNDc1bDUuODI2IDguNDc1aDIuOTI4di0xMi45OTdoLTIuNzF2OC42OGwtNS45MTUtOC42OHptMTQuNDcgMHYxMi45OTdoMi45MTh2LTEyLjk5N3oiIGZpbGw9IiMxMjBhMDgiLz48L3N2Zz4="
      />
    </svg>
  );
}
