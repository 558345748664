import styled from "styled-components";

export interface HyundaiGenesisOffersCountProps {
  offersCount: number;
}

const StyledHyundaiGenesisOffersCount = styled.span`
  margin-left: 5px;
  font-weight: 700;
`;

export const HyundaiGenesisOffersCount = ({
  offersCount
}: HyundaiGenesisOffersCountProps) => {
  return (
    <span>
      Offers
      <StyledHyundaiGenesisOffersCount>
        {offersCount}
      </StyledHyundaiGenesisOffersCount>
    </span>
  );
};

export default HyundaiGenesisOffersCount;
