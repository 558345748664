import { NavigationEntity } from "@vinsolutions/ccrm/interfaces";

/**
 * Adds a Navigation Entity to the Navigation Entity tree
 * @param {NavigationEntity}    entity
 * @param {NavigationEntity[]}  navigationEntities
 */
export const addItemToEntities = (
  entity: NavigationEntity | undefined | null,
  navigationEntities: NavigationEntity[] | undefined | null
) => {
  if (!entity || !navigationEntities) {
    return;
  }
  // Check to see if the item is already in the list
  const foundItem = navigationEntities?.find(ne => ne.id === entity.id);
  if (!foundItem) {
    navigationEntities?.push(entity);
    return;
  }
  // Add the children
  entity?.items?.forEach(item => addItemToEntities(item, foundItem.items));
};
