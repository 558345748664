import ResponseTime from "../responseTime";

export const emptyResponseTimes: ResponseTime[] = [
  {
    label: "0.25",
    value: 0,
    type: "Target 1"
  },
  {
    label: "missed",
    value: 0,
    type: "Missed"
  },
  {
    label: "0.5",
    value: 0,
    type: "Target 2"
  },
  {
    label: "noContact",
    value: 0,
    type: "No Contact"
  }
];
