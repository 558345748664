export interface TrackInNewRelicProps {
  componentAction: string;
  locationLoadedFrom?: string;
  dealerId?: string;
  destinationUrl?: string;
  errorContext?: string;
}

export function trackInNewRelic(props: TrackInNewRelicProps) {
  const {
    componentAction,
    locationLoadedFrom,
    dealerId,
    destinationUrl = "",
    errorContext = ""
  } = props;

  if (window.newrelic) {
    window.newrelic.addPageAction(componentAction, {
      location: locationLoadedFrom || "",
      dealerId: dealerId || "",
      url: destinationUrl || "",
      error: errorContext || ""
    });
  }
}
