import { SetupStatus } from "@vinsolutions/ccrm/interfaces";
import { Badge } from "cx";
import styled from "styled-components";
import { BadgeColor } from "@vinsolutions/core/cx";

const StyledBadge = styled(Badge)`
  &.badge {
    color: white;
    &.orange {
      background-color: #ff6a1d;
    }
    &.green {
      background-color: #38bf2c;
    }
    &.blue {
      background-color: #2b6bdd;
    }
    &.gray {
      background-color: #6d727a;
    }
  }
`;

const STATUS_VALUES = {
  [SetupStatus.Inactive]: {
    text: "Inactive",
    color: "count"
  },
  [SetupStatus.InSetup]: {
    text: "In Setup",
    color: "orange"
  },
  [SetupStatus.Complete]: {
    text: "Active",
    color: "green"
  },
  [SetupStatus.NewOrUnknown]: {
    text: "New",
    color: "blue"
  },
  [SetupStatus.Unknown]: {
    text: "Unknown",
    color: "gray"
  }
};

export function DealerStatusBadge({ status }: { status: SetupStatus | "" }) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const badgeProps = STATUS_VALUES[status];
  if (badgeProps) {
    return (
      <StyledBadge
        color={badgeProps.color as BadgeColor}
        data-testid="dealer-status-badge"
        htmlId={`dealer-status-badge-${status}`}
      >
        {badgeProps.text}
      </StyledBadge>
    );
  }
  return null;
}
