export const addDealerChangeEventListener = (
  callback: (dealerId: number, dealerName: string) => void
) => {
  window.addEventListener(
    "storage",
    e => {
      if (e.key === "selectedDealerId" && e.newValue !== null) {
        const { dealerId, dealerName } = JSON.parse(e.newValue);

        callback(dealerId, dealerName);
      }
    },
    false
  );
};
