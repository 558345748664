import { useVinconnectFlags } from "@vinsolutions/core/third-party/launch-darkly";
import { useMemo } from "react";
import { RouteManager, RouteManagerContext } from "./route-manager";
import { RouteList } from "@vinsolutions/ccrm/top-nav/route-config";

export interface RoutesProps {
  routeList: RouteList;
  children: JSX.Element | JSX.Element[];
}

/**
 * The provider for routes
 * Loads the parameters that the routes component needs
 * @constructor
 */
export const RouteProvider = ({ routeList, children }: RoutesProps) => {
  const vinconnectFlags = useVinconnectFlags();
  const routeManager = useMemo(
    () => new RouteManager(routeList, vinconnectFlags),
    [routeList, vinconnectFlags]
  );
  return (
    <RouteManagerContext.Provider value={routeManager}>
      {children}
    </RouteManagerContext.Provider>
  );
};
