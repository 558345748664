import { BaseUrlsObj, envEnum } from "@vinsolutions/core/environment";

type Config = {
  baseUrls: BaseUrlsObj;
};

export const OpportunityAttributesApiConfig: Config = {
  baseUrls: {
    [envEnum.LOCAL]: "https://opportunity-attributes-dev.vinccrmnp.cloud",
    [envEnum.DEV]: "https://opportunity-attributes-dev.vinccrmnp.cloud",
    [envEnum.QA]: "https://opportunity-attributes-qa.vinccrmnp.cloud",
    [envEnum.STAGING]: "https://opportunity-attributes-staging.vinccrmnp.cloud",
    [envEnum.PRODUCTION]: "https://opportunity-attributes.vinccrm.cloud"
  }
};
