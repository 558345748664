import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LeadStatus, MarkLeadBadRouteParams } from "./interfaces";
import MarkLeadStatus from "./mark-lead-status";
import { useMemo } from "react";

export function MarkLeadBadPage() {
  const { leadId } = useParams<MarkLeadBadRouteParams>();
  const navigate = useNavigate();
  const parsedLeadId = useMemo(() => parseInt(leadId || "", 10), [leadId]);

  const onClosePage = () => {
    navigate(-1);
  };

  return (
    <MarkLeadStatus
      leadId={parsedLeadId}
      leadStatus={LeadStatus.Bad}
      onCancel={onClosePage}
      onSuccess={onClosePage}
    />
  );
}

export default MarkLeadBadPage;
