export default function AutoTraderIconSmall() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 238 238"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1417_582)">
        <path
          d="M217.38 207.96H218.05C218.2 209.05 218.8 209.99 220.61 209.99C221.97 209.99 222.86 209.2 222.86 208.13C222.86 206.91 222.28 206.49 220.53 206.22C218.64 205.94 217.72 205.31 217.72 203.94C217.72 202.57 218.82 201.67 220.45 201.67C222.08 201.67 223.05 202.47 223.24 203.85H222.62C222.41 202.77 221.73 202.22 220.45 202.22C219.17 202.22 218.35 202.84 218.35 203.88C218.35 204.83 218.82 205.34 220.63 205.61C222.57 205.9 223.51 206.45 223.51 208.07C223.51 209.44 222.35 210.54 220.61 210.54C218.46 210.54 217.56 209.37 217.38 207.95V207.96ZM225.6 201.78H226.57L229.63 209.23L232.7 201.78H233.65V210.46H232.98V202.63L229.71 210.46H229.49L226.22 202.63V210.46H225.59V201.78H225.6Z"
          fill="white"
        />
        <path
          d="M87.1099 33.86L72.4399 114.98H28.1699C29.8899 77.78 53.8699 46.41 87.1099 33.86ZM98.0999 142.75L86.9199 204.59C96.9999 208.42 107.92 210.54 119.34 210.54C130.76 210.54 141.68 208.43 151.76 204.59L140.58 142.75H98.0999ZM119.83 28C119.66 28 119.5 28 119.33 28C119.16 28 119 28 118.83 28L103.11 114.98H135.55L119.83 28ZM59.2499 187.95L67.4199 142.75H31.1199C35.8399 160.53 45.7999 176.18 59.2499 187.95ZM151.57 33.86L179.42 187.95C198.53 171.22 210.61 146.66 210.61 119.27C210.61 80.2 186.06 46.89 151.56 33.86H151.57Z"
          fill="#FF821C"
        />
        <path
          d="M119.34 0.640015C53.82 0.640015 0.699951 53.76 0.699951 119.28C0.699951 184.8 53.82 237.91 119.34 237.91C184.86 237.91 237.98 184.81 237.98 119.28C237.98 53.75 184.86 0.640015 119.34 0.640015ZM118.84 28C119.01 28 119.17 28 119.34 28C119.51 28 119.67 28 119.84 28L135.56 114.98H103.12L118.84 28ZM59.26 187.95C45.82 176.18 35.85 160.54 31.13 142.75H67.43L59.26 187.95ZM28.17 114.98C29.8899 77.78 53.87 46.41 87.11 33.86L72.4399 114.98H28.17ZM119.33 210.54C107.91 210.54 96.99 208.43 86.91 204.59L98.09 142.75H140.57L151.75 204.59C141.67 208.42 130.75 210.54 119.33 210.54ZM179.41 187.95L151.56 33.86C186.06 46.89 210.61 80.2 210.61 119.27C210.61 146.66 198.53 171.22 179.42 187.95H179.41Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1417_582">
          <rect
            fill="white"
            height="237.27"
            transform="translate(0.699951 0.640015)"
            width="237.28"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
