import { PartyBar, Profile, SetupStatus } from "@vinsolutions/ccrm/interfaces";
import { oidcContext } from "@axa-fr/react-oidc-context/dist/oidcContext/AuthenticationContext";
import { GetAvailableDealersByPage } from "../endpoints";
// eslint-disable-next-line @typescript-eslint/no-empty-function
const stub = () => {};

const mockedPartyBar: PartyBar = {
  Counts: {
    Sold: 0,
    InboundCalls: 0,
    OutboundCalls: 0,
    InboundEmails: 0,
    OutboundEmails: 0,
    ReadEmails: 0,
    PencilCount: 0, // This corresponds to "Desked"
    Visits: 0,
    Demos: 0,
    Writeups: 0,
    MGRTOs: 0,
    TotalLeads: 0,
    InternetLeads: 0,
    WalkinLeads: 0,
    PhoneLeads: 0,
    TotalAppointments: 0,
    SetAppointments: 0,
    CompletedAppointments: 0,
    CancelledAppointments: 0,
    MissedAppointments: 0,
    ConfirmedAppointments: 0,
    OverdueTasks: 0
  },
  RangeMarker: 1,
  DealerId: 1,
  StartDate: "8/3/2021 7:00:00 AM",
  EndDate: "8/4/2021 6:59:59 AM"
};

const mockedOidcContext = {
  logout: stub,
  oidcUser: {
    access_token: "stuffandthingswhoohoo",
    expired: false,
    expires_at: 99999999999,
    expires_in: 9999999999,
    id_token: "stuffandthingswhoohoo",
    profile: {
      iss: "",
      sub: "",
      aud: "",
      exp: 0,
      iat: 0
    },
    scope: "",
    scopes: [""],
    state: "",
    toStorageString: stub,
    token_type: "",
    refresh_token: "",
    session_state: ""
  },
  isEnabled: true,
  isLoading: false,
  authenticating: false,
  error: "",
  login: stub,
  signinSilent: stub,
  isLoggingOut: stub,
  userManager: stub
} as unknown as oidcContext;

const mockedStatus = {
  refreshIntervalInSeconds: 60,
  systemStatusId: 1,
  loginAllowed: true,
  loginLimited: false,
  cdAvailable: true,
  statusMessages: null
};

const mockedAuth = [] as any;
const mockedAvailableDealers: GetAvailableDealersByPage = {
  dealers: [
    {
      city: "test city",
      dealerID: 1,
      dealerName: "Test dealer",
      setupStatus: "A",
      showEula: false,
      state: "test state",
      userIsIPRestricted: false
    }
  ],
  totalDealers: 1,
  pageSize: 1,
  page: 1
};
const mockedUserInfo: Profile = {
  dealerId: 1,
  dealerName: "Test Dealer",
  dealerSetupStatus: SetupStatus.Complete,
  isVinEmployee: true,
  userFullName: "Testy Mc Test Face",
  userId: 12345,
  userName: "TestUser",
  userRole: "VinSolutions Employee"
};

const stubLaunchDarkly = {
  "vinconnect.ccrm-top-nav-main-header.show-party-bar": {
    flagVersion: 148,
    trackEvents: false,
    value: false,
    variation: 1,
    version: 153
  }
};
const mockedUserStatus = [] as any;
const mockedNewsCount = [] as any;

export const mocks = {
  mockedPartyBar,
  mockedAuth,
  mockedOidcContext,
  mockedAvailableDealers,
  mockedUserInfo,
  mockedUserStatus,
  mockedNewsCount,
  mockedStatus,
  stubLaunchDarkly
};
