import { Alert, Button } from "cx";
import { useCallback, useEffect, useRef, useState } from "react";
import calculateTimeDifference from "../util/calculate-time-difference";
import calculateTimeRemaining from "../util/calculate-time-remaining";
import formatTimeRemaining from "../util/format-time-remaining";
import generateTimerLabel from "../util/generate-timer-label";
import { gtmPageReloadedByUserCountdownAlert } from "../util/gtm-events";

/* eslint-disable-next-line */
export interface VersionControlCountdownAlertProps {
  changeEffectDateUtc: Date;
  refreshButtonClick: () => void;
}

export function VersionControlCountdownAlert({
  changeEffectDateUtc,
  refreshButtonClick
}: VersionControlCountdownAlertProps) {
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeRemaining(changeEffectDateUtc)
  );
  const timer = useRef(0);

  const timeoutCallback = () => {
    setTimeLeft(calculateTimeRemaining(changeEffectDateUtc));
  };

  useEffect(() => {
    return window.clearTimeout(timer.current);
  }, [changeEffectDateUtc, refreshButtonClick]);

  useEffect(() => {
    timer.current = window.setTimeout(timeoutCallback, 1000);
  });

  const generateTimer = useCallback(() => {
    return formatTimeRemaining(timeLeft);
  }, [timeLeft]);

  const getTimerLabel = useCallback(() => {
    return generateTimerLabel(timeLeft);
  }, [timeLeft]);

  return (
    <Alert htmlId="version-control-countdown-warning" type="warning">
      <span>
        This window will auto refresh in{" "}
        <span id="version-control-countdown-warning-timer">
          {generateTimer()}
        </span>{" "}
        {getTimerLabel()}. All unsaved changes will be lost.
      </span>
      <Button
        buttonStyle="link"
        htmlId="version-control-countdown-button"
        onClick={() => {
          gtmPageReloadedByUserCountdownAlert(
            calculateTimeDifference(changeEffectDateUtc),
            refreshButtonClick
          );
        }}
      >
        Refresh now
      </Button>
    </Alert>
  );
}

export default VersionControlCountdownAlert;
