import { dynamicImport } from "./dynamic-import-helper";
import { RemoteContainer, ResolveRemoteUrlFunction } from "./types"; // Import the types

// eslint-disable-next-line camelcase
declare const __webpack_init_sharing__: (scope: "default") => Promise<void>;
// eslint-disable-next-line camelcase
declare const __webpack_share_scopes__: { default: unknown };

/**
 * Loads a remote container by its name.
 * @param remoteName - The name of the remote module.
 * @param resolveRemoteUrl - The function to resolve remote URLs.
 * @param remoteUrlDefinitions - An object containing remote URL definitions.
 * @param remoteContainerMap - A map to store loaded remote containers.
 * @param setRemoteContainer - Function to update the container map.
 * @returns The loaded remote container.
 * @throws Error if definitions or resolvers are not set.
 */
export async function loadRemoteContainer(
  remoteName: string,
  resolveRemoteUrl: ResolveRemoteUrlFunction,
  remoteUrlDefinitions: Record<string, string>,
  remoteContainerMap: Map<string, RemoteContainer>,
  setRemoteContainer: (remoteName: string, container: RemoteContainer) => void
): Promise<RemoteContainer> {
  if (!resolveRemoteUrl && Object.keys(remoteUrlDefinitions).length === 0) {
    throw new Error(
      "Call setRemoteDefinitions or setRemoteUrlResolver to allow Dynamic Federation to find the remote apps correctly."
    );
  }

  // Initialize sharing scope only once
  if (!remoteContainerMap.has("sharingScopeInitialized")) {
    await __webpack_init_sharing__("default");
    remoteContainerMap.set("sharingScopeInitialized", {} as RemoteContainer); // Use an empty object to track initialization
  }

  const remoteUrl =
    remoteUrlDefinitions[remoteName] || (await resolveRemoteUrl(remoteName));

  if (!remoteUrl) {
    throw new Error(
      `Failed to resolve remote URL for ${remoteName}. Ensure that the remote URL is properly defined.`
    );
  }

  const containerUrl = `${remoteUrl}${
    remoteUrl.endsWith("/") ? "" : "/"
  }remoteEntry.js`;

  const container = (await dynamicImport(containerUrl)) as RemoteContainer;

  await container.init(__webpack_share_scopes__.default);
  setRemoteContainer(remoteName, container);

  return container;
}
