import { usePortalsContext } from "../state/portals-context";
import usePortalStylesCss from "../hooks/use-portal-styles-css";
import Portal from "./Portal";
import PortalInterface from "./portal-interface";
import { FC } from "react";

type PortalsContainerProps = {
  portalContainerId: string;
  /** Styles copied/applied to created portal container elements. */
  portalStyles?: HTMLStyleElement[] | null;
};

const PortalsContainer: FC<PortalsContainerProps> = ({
  portalContainerId,
  portalStyles
}: PortalsContainerProps) => {
  const { registeredPortals } = usePortalsContext();
  const portalStylesCss = usePortalStylesCss(portalStyles);

  return registeredPortals && registeredPortals.length ? (
    <div data-testid={portalContainerId} style={{ display: "none" }}>
      {registeredPortals.map(registeredPortal => {
        return (
          <Portal
            key={registeredPortal.portalKey}
            registeredPortal={registeredPortal}
          >
            <PortalInterface
              portalStylesCss={portalStylesCss}
              registeredPortal={registeredPortal}
            />
          </Portal>
        );
      })}
    </div>
  ) : null;
};

export default PortalsContainer;
