import {
  FeatureFlagType,
  RouteComponent,
  RouteComponentOptions,
  RouteEntityType
} from "./route-config-types";

/**
 * Builder for routes
 * @method withRouteId Adds the route identifier to the route
 * @method withLeftComponent Adds the left component to the route
 * @method withRightComponent Adds  the right component to the route
 * @method withFullComponent Adds the full component to the route
 * @method withAliasId Adds the route alias identifier
 * @method withFeatureFlag Adds the feature flag for the route
 * @method build  Build the route
 *
 */
export class RouteBuilder {
  #routeId = "";
  #leftComponent: RouteComponent = undefined;
  #leftComponentOptions?: RouteComponentOptions;
  #rightComponent: RouteComponent = undefined;
  #rightComponentOptions?: RouteComponentOptions;
  #fullComponent: RouteComponent = undefined;
  #featureFlag: FeatureFlagType;
  #aliasId: string | undefined = undefined;
  static create() {
    return new RouteBuilder();
  }
  build() {
    const route: RouteEntityType = {
      routeId: this.#routeId,
      aliasId: this.#aliasId,
      featureFlag: this.#featureFlag,
      panes: {
        left: this.#leftComponent
          ? {
              component: this.#leftComponent,
              options: this.#leftComponentOptions
            }
          : undefined,
        right: this.#rightComponent
          ? {
              component: this.#rightComponent,
              options: this.#rightComponentOptions
            }
          : undefined,
        full: this.#fullComponent
          ? { component: this.#fullComponent }
          : undefined
      }
    };
    return route;
  }

  /**
   * Adds the aliasId to the configuration.  The aliasId is an alternate key for the route.  It is useful for menuIds.
   * If the aliasId is set to the menuId then the route can be triggered by the menuId, but the URL can be a more user friendly URL
   * @param {string}  aliasId The alias for the route
   */
  withAliasId(aliasId: string) {
    this.#aliasId = aliasId;
    return this;
  }

  /**
   * Adds the routeId to the configuration.  The routeId is derived from the product name and the view name.  The combination
   * gives a user friendly URL
   * @param {string}  product   The product name
   * @param {string}  viewName  The name of the view associated with the route
   */
  withRouteId(product: string, viewName: string) {
    if (!product || !viewName) {
      throw new Error("RouteBuilder.build: product or view name is undefined");
    }
    this.#routeId = `${product}-${viewName}`;
    return this;
  }

  /**
   * The component for the left pane
   * @param {RouteComponent} component The component that will be displayed in the left pane
   * @param {RouteComponentOptions} options Options to apply to RouteComponent (optional)
   */
  withLeftPaneComponent(
    component: RouteComponent,
    options?: RouteComponentOptions
  ) {
    if (this.#fullComponent) {
      throw new Error(
        "RouteBuilder: A full pane component is already defined.  Full and left/right shouldn't be configured on same route"
      );
    }

    this.#leftComponent = component;
    this.#leftComponentOptions = options;
    return this;
  }

  /**
   * Adds the component for the right pane to the configuration
   * @param {RouteComponent}  component The component that will be displayed in the right pane
   * @param {RouteComponentOptions} options Options to apply to RouteComponent (optional)
   */
  withRightPaneComponent(
    component: RouteComponent,
    options?: RouteComponentOptions
  ) {
    if (this.#fullComponent) {
      throw new Error(
        "RouteBuilder: A full pane component is already defined.  Full and left/right shouldn't be configured on same route"
      );
    }

    this.#rightComponent = component;
    this.#rightComponentOptions = options;
    return this;
  }

  /**
   * The component that will be displayed in the full pane
   * @param {RouteComponent}  component The component that will span both panes
   */
  withFullComponent(component: RouteComponent) {
    if (this.#rightComponent || this.#leftComponent) {
      throw new Error(
        "RouteBuilder: A left pane or right pane component is already defined.  Full and left/right shouldn't be configured on same route"
      );
    }
    this.#fullComponent = component;
    this.#leftComponentOptions = undefined;
    this.#rightComponentOptions = undefined;
    return this;
  }

  /**
   * The feature flag for the route.  It is optional
   * @param {FeatureFlagType} featureFlag The feature flag
   */
  withFeatureFlag(featureFlag: FeatureFlagType) {
    this.#featureFlag = featureFlag;
    return this;
  }
}
