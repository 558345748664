import { DataIsland } from "@vinsolutions/legacy-cardashboard/interfaces";
import { runCarDashboardScript } from "./run-cardashboard-script";
export const deriveViewingAs = async () => {
  // Made no effort to rewrite this to standards. We should call an API instead
  const getViewAsElement = () => {
    return runCarDashboardScript({
      functionName: "document.getElementById",
      variable: "_topnavdealeruserlink"
    }) as HTMLAnchorElement | null;
  };

  const dataIsland = runCarDashboardScript({
    functionName: "_dataIsland"
  }) as DataIsland | null;
  const viewingAsId = dataIsland?.crmSettings?.viewingAsId;

  const getCanUserViewAs = (element: HTMLAnchorElement) => {
    if (!element) return false;
    return !!element.href;
  };
  const getIsUserViewingAs = (element: HTMLAnchorElement) => {
    if (!element) return false;
    if (!getCanUserViewAs(element)) return false;
    const elementText = element.innerText;
    return !!elementText.includes("viewing as");
  };
  const getWhatUserIsBeingViewedAs = (element: HTMLAnchorElement) => {
    if (!element) return "";
    if (!getCanUserViewAs(element)) return "";
    if (!getIsUserViewingAs(element)) return "";
    const elementText = element.innerText;
    return elementText.split("viewing as")[1].trim();
  };
  const viewAsElement = getViewAsElement();
  if (!viewAsElement)
    return {
      isViewingAs: false,
      viewingAsUsername: "",
      hasViewingAsFeature: false,
      viewingAsId: null
    };
  return {
    isViewingAs: getIsUserViewingAs(viewAsElement),
    viewingAsUsername: getWhatUserIsBeingViewedAs(viewAsElement),
    hasViewingAsFeature: getCanUserViewAs(viewAsElement),
    viewingAsId
  };
};
