import { OemInsightsSummaryResponse } from "@vinsolutions/data-access/oem/vin.api.oeminsights";
import OemInsightsSummaryData from "../../interfaces/oem-insights-summary-data";
import {
  getFormattedUpdatedUtcDate,
  getOemDataFromSummaryResponse
} from "../getOemDataItem";

export const parseHondaAcuraInsights = (
  oemInsightsSummaryResponse: OemInsightsSummaryResponse | null,
  oem: "honda" | "acura"
): OemInsightsSummaryData | null => {
  const dataItem = getOemDataFromSummaryResponse(
    oemInsightsSummaryResponse,
    oem
  );
  if (dataItem) {
    const parsedItem = JSON.parse(dataItem);
    return {
      updatedDate: getFormattedUpdatedUtcDate(parsedItem.UpdatedUtc)
    };
  }
  return null;
};
