import React from "react";
import { useEffect, useRef } from "react";

export interface addScriptTagInterface {
  src?: string;
  script?: string;
  async?: boolean;
  testid: string;
}
export function AddScriptTag(props: addScriptTagInterface) {
  const divElement = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const script = document.createElement("script");
    if (!divElement.current) return;
    updateScriptTag(props, script);
    divElement.current.appendChild(script);
  }, []);
  return <div ref={divElement} />;
}

export default AddScriptTag;

function updateScriptTag(
  requestedSettings: addScriptTagInterface,
  script: HTMLScriptElement
) {
  if (requestedSettings.src) {
    script.src = requestedSettings.src;
    script.async = requestedSettings.async ?? false;
  } else if (requestedSettings.script && !script.hasChildNodes()) {
    const inlineScriptText = document.createTextNode(requestedSettings.script);
    script.appendChild(inlineScriptText);
  }
  script.setAttribute("data-testid", requestedSettings.testid);
}
