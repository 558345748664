import styled from "styled-components";
import { useMemo } from "react";
import { InsightsSummaryResponseItem } from "@vinsolutions/data-access/workflow/crm.workflow.keyinfo.automotiveintelligence";

/* eslint-disable-next-line */
export interface BuyingSignalsDateProps {
  insightsSummary: InsightsSummaryResponseItem;
}

const StyledBuyingSignalsBannerDate = styled.span``;
const dateStringOptions: Intl.DateTimeFormatOptions = {
  month: "2-digit",
  year: "numeric",
  day: "2-digit"
};

export const BuyingSignalsDate = ({
  insightsSummary: { lastActivityUtc }
}: BuyingSignalsDateProps) => {
  const date = useMemo(() => {
    const lastActivityDate = lastActivityUtc ? new Date(lastActivityUtc) : null;
    return lastActivityDate?.toLocaleDateString("en-US", dateStringOptions);
  }, [lastActivityUtc]);

  return <StyledBuyingSignalsBannerDate>{date}</StyledBuyingSignalsBannerDate>;
};

export default BuyingSignalsDate;
