import { useCallback, useEffect, useMemo } from "react";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("use-cardashboard-channel");

declare global {
  interface Window {
    tabContextId: string | undefined;
  }
}

/**
 *
 * @param channelName Name of the channel to use
 * @param contextIdentifier Identifier that may be used to target specific frame/window contexts
 * @param handleMessage Callback tied to onmessage event
 * @param handleMessageError Callback tied to onmessageerror event
 * @returns Function to post a message to the channel
 */
export function useCardashboardChannel<T = string>(
  channelName: string,
  contextIdentifier: string,
  handleMessage?: (event: MessageEvent) => void,
  handleMessageError?: (event: MessageEvent) => void
): (data: T) => void {
  const channel = useMemo(() => {
    const appliedChannel =
      typeof window !== "undefined" && "BroadcastChannel" in window
        ? new BroadcastChannel(`${channelName}${contextIdentifier}`)
        : null;
    return appliedChannel;
  }, [channelName, contextIdentifier]);

  useChannelEventListener(channel, "message", handleMessage);
  useChannelEventListener(channel, "messageerror", handleMessageError);

  return useCallback(
    (data: T) => {
      logger.debug("Posting message to channel", channelName, data);
      channel?.postMessage(data);
    },
    [channel]
  );
}

/**
 *
 * @param channelName Name of the channel to use
 * @param handleMessage Callback tied to onmessage event
 * @param handleMessageError Callback tied to onmessageerror event
 * @returns Function to post a message to the channel
 */
export function useCardashboardTabChannel<T = string>(
  channelName: string,
  handleMessage?: (event: MessageEvent) => void,
  handleMessageError?: (event: MessageEvent) => void
): (data: T) => void {
  logger.debug("Using tab channel", channelName);
  if (!window?.top?.tabContextId) {
    // eslint-disable-next-line no-console
    logger.error("Tab context identifier is undefined!");
  }
  return useCardashboardChannel(
    channelName,
    window?.top?.tabContextId || "",
    handleMessage,
    handleMessageError
  );
}

/** subscribe/unsubscribe from the channel events. */
function useChannelEventListener<K extends keyof BroadcastChannelEventMap>(
  channel: BroadcastChannel | null,
  event: K,
  handler: (e: BroadcastChannelEventMap[K]) => void = () => undefined
) {
  useEffect(() => {
    if (channel && handler) {
      logger.debug("Adding event listener", event);
      channel.addEventListener(event, handler);
      return () => {
        logger.debug("Removing event listener", event);
        channel.removeEventListener(event, handler);
      };
    }
    return undefined;
  }, [channel, event, handler]);
}
