import { fetchWrapper } from "@vinsolutions/core/http";
import {
  LeadStatus,
  LeadStatusResponse,
  MarkLeadStatusResponse
} from "./interfaces";

import {
  BaseUrlsObj,
  envEnum,
  getBaseUrl
} from "@vinsolutions/core/environment";

type Config = {
  baseUrls: BaseUrlsObj;
};

export const config: Config = {
  baseUrls: {
    [envEnum.LOCAL]:
      "https://dev-wsvc.vinsolutions.com/coxauto.api.lead/leads/",
    [envEnum.DEV]: "https://dev-wsvc.vinsolutions.com/coxauto.api.lead/leads/",
    [envEnum.QA]: "https://qa-lead.vinsolutions.com/coxauto.api.lead/leads/",
    [envEnum.STAGING]:
      "https://staging-lead.vinsolutions.com/coxauto.api.lead/leads/",
    [envEnum.PRODUCTION]:
      "https://lead.vinsolutions.com/coxauto.api.lead/leads/"
  }
};

const baseUrl = getBaseUrl(config.baseUrls);
const badLeadStatusTypeId = 4;
const lostLeadStatusTypeId = 3;

export const badLeadStatusesUrl = `${baseUrl}Statuses/${badLeadStatusTypeId}`;
export const lostLeadStatusesUrl = `${baseUrl}Statuses/${lostLeadStatusTypeId}`;

export function markLeadBadUrl(id: number) {
  return `${baseUrl}id/${id}/bad`;
}

export function markLeadLostUrl(id: number) {
  return `${baseUrl}id/${id}/lost`;
}

export async function loadLeadStatusOptionsFromApi(
  accessToken: string,
  leadStatus: LeadStatus
) {
  const response = await fetchWrapper.getAsync<LeadStatusResponse>(
    getLeadStatusesUrl(leadStatus),
    [
      ["Accept", "application/vnd.coxauto.v4+json"],
      ["Authorization", `Bearer ${accessToken}`]
    ]
  );
  return response;
}

function getLeadStatusesUrl(leadStatus: LeadStatus) {
  if (leadStatus === LeadStatus.Bad) {
    return badLeadStatusesUrl;
  } else if (leadStatus === LeadStatus.Lost) {
    return lostLeadStatusesUrl;
  }
  return "";
}

export async function markLeadStatusViaApi(
  leadId: number,
  leadStatus: LeadStatus,
  selectedStatus: string,
  enteredNote: string,
  jwt: string
) {
  const response = await fetchWrapper.postAsync<MarkLeadStatusResponse>(
    getLeadStatusUrl(leadId, leadStatus),
    {
      leadStatus: selectedStatus,
      note: enteredNote
    },
    [
      ["Accept", "application/vnd.coxauto.v4+json"],
      ["Content-Type", "application/vnd.coxauto.v4+json"],
      ["Authorization", `Bearer ${jwt}`]
    ]
  );
  return response;
}

function getLeadStatusUrl(leadId: number, leadStatus: LeadStatus) {
  if (leadStatus === LeadStatus.Bad) {
    return markLeadBadUrl(leadId);
  } else if (leadStatus === LeadStatus.Lost) {
    return markLeadLostUrl(leadId);
  }
  return "";
}
