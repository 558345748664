import { StyledDMCModal } from "./styles/dmc-modal.style";
import { useDMCContext } from "../../hooks/useDmcContext.hook";
import { Button } from "cx";

export const DMCModal = () => {
  const {
    modalContext: {
      modalContent: { content, footer, onHide, show }
    }
  } = useDMCContext();

  return content ? (
    <StyledDMCModal
      data-testid="dealer-manufacturer-codes-modal"
      displayCloseButton={true}
      footer={
        <>
          {footer}
          <Button
            buttonStyle="secondary"
            data-testid="dealer-manufacturer-codes-button-cancel"
            htmlId="dealer-manufacturer-codes-button-cancel"
            onClick={onHide}
          >
            Cancel
          </Button>
        </>
      }
      header={<h4>Dealer Manufacturer Code</h4>}
      htmlId="dealer-manufacturer-codes-modal"
      {...{ show, onHide }}
    >
      {content}
    </StyledDMCModal>
  ) : null;
};
