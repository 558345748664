import { IVinManagerInventoryMaster } from "./vinmanager-inventory-master";
import { communicationsNavigationItemEntityIds } from "./navigation-ids";
import { generateDestinationUrl } from "./vinmanager-inventory-master";
import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";

const getAllEmailNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  const emailNavigationItemsEntities: NavigationItemsEntity[] = [
    {
      id: communicationsNavigationItemEntityIds.emailUnmatched,
      label: "Unmatched",
      url: "/CarDashboard/ploader.aspx?TargetControl=Pages/UnmatchedEmail.ascx",
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.emailInbound,
      label: "Inbound",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/LeadManagement/DailyLeadDetail.aspx",
          leftQueryString: {
            pageDisplayType: "6"
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.emailOutbound,
      label: "Outbound",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/LeadManagement/DailyLeadDetail.aspx",
          leftQueryString: {
            pageDisplayType: "5"
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.emailRead,
      label: "Read",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/LeadManagement/EmailsRead.aspx"
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.emailUndelivered,
      label: "Undelivered",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/LeadManagement/DailyEmailDetail.aspx",
          leftQueryString: {
            almType: "13",
            from: vinManagerInventoryMaster.formatDateTwo(new Date()),
            to: vinManagerInventoryMaster.formatDateTwo(
              new Date(new Date().setDate(new Date().getDate() - 1))
            )
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    }
  ];

  return emailNavigationItemsEntities;
};

const filterEmailNavigationItems = (
  emailNavigationItems: NavigationItemsEntity[],
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  let validItems = [] as NavigationItemsEntity[];

  validItems = validItems.concat(emailNavigationItems);

  if (!vinManagerInventoryMaster.SecurityPrincipalILMUserIsManagerOrAdmin) {
    const emailKeys = ["Read", "Undelivered", "Inbound", "Outbound"];
    validItems = validItems.filter(item => !emailKeys.includes(item.label));
  }

  if (
    !vinManagerInventoryMaster.IsDealerEmailDomainDiverted ||
    !vinManagerInventoryMaster.MailNoHostEnabled
  ) {
    validItems.splice(
      validItems.findIndex(item => item.label === "Unmatched"),
      1
    );
  }

  return validItems;
};

export const buildEmailNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  const allEmailNavigationItems = getAllEmailNavigationItems(
    vinManagerInventoryMaster
  );

  const filteredEmailNavigationItems = filterEmailNavigationItems(
    allEmailNavigationItems,
    vinManagerInventoryMaster
  );

  const emailNavigationEntity: NavigationEntity | null =
    filteredEmailNavigationItems.length > 0
      ? {
          id: communicationsNavigationItemEntityIds.email,
          items: filteredEmailNavigationItems.map(item => {
            return {
              id: item.id
            };
          })
        }
      : null;

  return {
    emailNavigationItemEntities: filteredEmailNavigationItems,
    emailNavigationEntity
  };
};
