/**
 * @desc format a date input into MM:DD:YYYY hh:mm am/pm UTC
 * @param date
 * @return {string} MM:DD:YYYY hh:mm am/pm UTC of the input
 */
export function dateFormatUTC(date: Date): string {
  const year = date.getUTCFullYear();
  const month = `0${1 + date.getUTCMonth()}`.slice(-2);
  const day = `0${date.getUTCDate()}`.slice(-2);
  let hours = `0${date.getUTCHours()}`.slice(-2);
  const minutes = `0${date.getUTCMinutes()}`.slice(-2);
  const ampm = Number(hours) >= 12 ? "PM" : "AM";
  hours = (Number(hours) % 12).toString();
  hours = hours ? hours : "12"; // hour 0 should be 12
  return `${month}/${day}/${year} ${hours}:${minutes} ${ampm} UTC`;
}
