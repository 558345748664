import { KeyInformationBanner } from "@vinsolutions/ui/key-information-banner";
import OemInsightsBannerProps from "../../interfaces/oem-insights-banner-interface-props";
import FordDirectIconSmall, { FordDirectIconFill } from "./ford-direct-icon";
import FordInsightsSummary from "./ford-insights-summary";
import { parseFordInsights } from "../../data-parse/ford/ford-summary-data";
import { useEffect, useMemo, useState } from "react";
import OemInsightsDate from "../oem-banner-date";

const bannerName = "Ford Insights";
export const FordBaseDisplayedBanner = {
  bannerName,
  iconName: "FordIconSmall",
  thumbnailName: "FordIconSmall"
};

export function FordInsightsBanner({
  oemInsightsSummary,
  onLoad,
  onClick
}: OemInsightsBannerProps) {
  const [iconColor, setIconColor] = useState<FordDirectIconFill>(
    FordDirectIconFill.FordDirectGreen
  );
  const fordInsights = useMemo(
    () => parseFordInsights(oemInsightsSummary),
    [oemInsightsSummary]
  );
  useEffect(() => {
    onLoad({
      ...FordBaseDisplayedBanner,
      isDisplayed: !!fordInsights
    });
  }, [onLoad, fordInsights]);
  return (
    fordInsights && (
      <KeyInformationBanner
        bannerDataDetail={<OemInsightsDate oemInsights={fordInsights} />}
        bannerIcon={<FordDirectIconSmall fillColor={iconColor} />}
        bannerName={bannerName}
        bannerSummary={<FordInsightsSummary fordInsights={fordInsights} />}
        onClick={() => onClick && onClick(bannerName, "ford_direct")}
        onMouseEnter={() => setIconColor(FordDirectIconFill.FordDirectWhite)}
        onMouseLeave={() => setIconColor(FordDirectIconFill.FordDirectGreen)}
      />
    )
  );
}

export default FordInsightsBanner;
