import { AmpInfoResponse } from "@vinsolutions/data-access/marketing/coxauto.amp.vinconnect.bff";
import AmpDate from "./amp-date";
import AmpError from "./amp-error";

/* eslint-disable-next-line */
export interface AmpDataDetailProps {
  ampSummary: AmpInfoResponse;
  onDataErrorRetryClick: () => void;
}

export const AmpDataDetail = ({
  ampSummary: { marketedDate, errorMessage },
  onDataErrorRetryClick
}: AmpDataDetailProps) => {
  return (
    <>
      {marketedDate ? <AmpDate marketedDate={marketedDate} /> : null}
      <AmpError
        errorMessage={errorMessage || null}
        onRetryClick={onDataErrorRetryClick}
      />
    </>
  );
};

export default AmpDataDetail;
