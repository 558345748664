import OemInsightsModalContent, {
  OemInsightsModalContentProps
} from "./oem-insights-modal-content";
import { OemPartnerKey } from "@vinsolutions/data-access/oem/vin.api.oeminsights";
import { KeyInformationBannerModal } from "@vinsolutions/ui/key-information-banner";

export interface OemInsightsModalProps extends OemInsightsModalContentProps {
  oem: OemPartnerKey | null;
  showModal: boolean;
  onHide: () => void;
}

const OemInsightsModal = ({
  customerId,
  customerFirstName,
  customerLastName,
  dealerId,
  oem,
  showModal,
  onHide
}: OemInsightsModalProps) => {
  return (
    oem && (
      <KeyInformationBannerModal showModal={showModal} onHide={onHide}>
        <OemInsightsModalContent
          customerFirstName={customerFirstName}
          customerId={customerId}
          customerLastName={customerLastName}
          dealerId={dealerId}
          oem={oem}
        />
      </KeyInformationBannerModal>
    )
  );
};

export default OemInsightsModal;
