import { OemInsightsSummaryResponse } from "@vinsolutions/data-access/oem/vin.api.oeminsights";
import GmInsightsSummaryData from "../../interfaces/gm/gm-insights-summary-data";
import {
  getFormattedUpdatedUtcDate,
  getOemDataFromSummaryResponse
} from "../getOemDataItem";

export const parseGmInsights = (
  oemInsightsSummaryResponse: OemInsightsSummaryResponse | null
): GmInsightsSummaryData | null => {
  const dataItem = getOemDataFromSummaryResponse(
    oemInsightsSummaryResponse,
    "gm"
  );
  if (dataItem) {
    const parsedItem = JSON.parse(dataItem);
    return {
      oemLeadId: parsedItem.OemLeadId || "",
      leadScore: parsedItem.LeadScore || "",
      updatedDate: getFormattedUpdatedUtcDate(parsedItem.UpdatedUtc)
    };
  }
  return null;
};
