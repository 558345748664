import styled from "styled-components";
import BmwMiniInsightsSummaryData from "../../interfaces/bmw-mini/bmw-mini-insights-summary-data";

export interface BmwMiniInsightsSummaryProps {
  bmwMiniInsights: BmwMiniInsightsSummaryData;
}

const StyledBmwMiniInsightsSummary = styled.span`
  float: left;
`;
const StyledLikeyInMarket = styled.strong`
  padding-right: 1em;
`;

export const BmwMiniInsightsSummary = ({
  bmwMiniInsights: { likelyInMarket, ownershipSummary }
}: BmwMiniInsightsSummaryProps) => {
  return (
    <StyledBmwMiniInsightsSummary>
      {likelyInMarket && (
        <StyledLikeyInMarket>Likely in Market:</StyledLikeyInMarket>
      )}
      {ownershipSummary}
    </StyledBmwMiniInsightsSummary>
  );
};

export default BmwMiniInsightsSummary;
