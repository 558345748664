// eslint-disable-next-line @nx/enforce-module-boundaries
import { envEnum } from "@vinsolutions/core/environment";
import { useMemo } from "react";
import styled from "styled-components";

export interface KeyInformationKbbIcoModalContentProps {
  offerGuid?: string;
  dealerGuid?: string;
  env?: envEnum;
}

const StyledKeyInformationKbbIcoModalContent = styled.iframe`
  width: 100%;
  height: 600px;
  border: none;
`;

const KeyInformationKbbIcoModalContent = ({
  offerGuid,
  dealerGuid,
  env
}: KeyInformationKbbIcoModalContentProps) => {
  const iframeUrl = useMemo(() => {
    const kbbBaseUrl =
      env !== undefined && env === envEnum.PRODUCTION
        ? "https://kbb.com"
        : "https://staging.kbb.com";

    const dealerUrl = dealerGuid ? `&did=${dealerGuid}` : "";

    const kbbIcoUrl = `${kbbBaseUrl}/instant-cash-offer/offerDetails/?OfferID=${offerGuid}${dealerUrl}`;
    return kbbIcoUrl;
  }, [offerGuid, dealerGuid, env]);

  return (
    iframeUrl && (
      <StyledKeyInformationKbbIcoModalContent
        id="KbbIcoKeyInfoIframe"
        src={iframeUrl}
        title="KbbIco-Modal"
      />
    )
  );
};

export default KeyInformationKbbIcoModalContent;
