import { shallowEqual, useSelector } from "react-redux";
import { getDealerDashboardSliceState } from "../dealer-dashboard.slice";
import { useGetDetailsQuery } from "../rtk";
import { ReportType } from "../enums";
import { useGetUserSettings } from "./useGetUserSettings";
import { useMemo, useState } from "react";
import { DetailRequest } from "../models/detailRequest";

interface Props {
  areDependenciesLoaded: boolean;
}

export function useGetDetails({ areDependenciesLoaded }: Props) {
  const {
    reportType,
    startDate,
    endDate,
    jwt,
    dealerId,
    requestRefresh: refresh
  } = useSelector(getDealerDashboardSliceState, shallowEqual);

  const {
    leadType,
    responseTimeType,
    userGroupId,
    userId,
    isSuccess: isSettingsSuccess
  } = useGetUserSettings();

  const [lastQueryParams, setLastQueryParams] = useState<DetailRequest>({
    jwt,
    dealerId,
    startDateLocal: startDate,
    endDateLocal: endDate,
    leadType,
    responseTimeType,
    reportType,
    userGroupId,
    userId,
    refresh
  });

  /* 
    Work Around - Storing the query params in state and only updating them if areDependenciesLoaded is true. This has two effects:
      1) The Opportunities page the user is on (e.g. page 2 of 5) wont be reset everytime Summary data is fetched
      2) useGetDetailsQuery will wait for Summary to finish. This avoids doing API calls if the ReportType has a 0 quantity.
  */
  const queryParams = useMemo(() => {
    const currentQueryParams = {
      jwt,
      dealerId,
      startDateLocal: startDate,
      endDateLocal: endDate,
      leadType,
      responseTimeType,
      reportType,
      userGroupId,
      userId,
      refresh
    };

    if (areDependenciesLoaded) {
      setLastQueryParams(currentQueryParams);
      return currentQueryParams;
    }

    return lastQueryParams;
  }, [
    jwt,
    dealerId,
    startDate,
    endDate,
    leadType,
    responseTimeType,
    userGroupId,
    userId,
    reportType,
    refresh,
    areDependenciesLoaded
  ]);

  const {
    currentData: detailResponse,
    error: isError,
    isSuccess,
    isFetching,
    isLoading,
    isUninitialized
  } = useGetDetailsQuery(queryParams, {
    skip:
      queryParams.jwt === "" ||
      queryParams.dealerId === 0 ||
      queryParams.startDateLocal.toString() ===
        queryParams.endDateLocal.toString() ||
      !isSettingsSuccess ||
      queryParams.reportType === ReportType.NONE
  });

  return {
    detailResponse,
    isError,
    isSuccess:
      isSuccess &&
      isSettingsSuccess &&
      !isFetching &&
      !isLoading &&
      !isUninitialized
  };
}
