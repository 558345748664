/* eslint-disable @cspell/spellchecker */
import { PreloadedState } from "@reduxjs/toolkit";
import {
  DynamicHeaderState,
  NotificationsState,
  ProfileState,
  SetupStatus
} from "@vinsolutions/ccrm/interfaces";
import { MetaDataState } from "../meta-data.slice";

export const defaultPreloadedState: {
  metaData: PreloadedState<MetaDataState>;
  notifications: PreloadedState<NotificationsState>;
  profile: PreloadedState<ProfileState>;
  dynamicHeader: PreloadedState<DynamicHeaderState>;
} = {
  metaData: {
    currentAppVersion: "1.0.1",
    loadingStatus: "loaded",
    error: null
  },
  notifications: {
    ids: ["news"],
    loadingStatus: "loaded",
    error: "",
    entities: {
      news: {
        id: "news",
        label: "News",
        count: 10
      }
    }
  },
  profile: {
    profileLoadingStatus: "loaded",
    userHasAccessToMultipleDealersLoadingStatus: "loaded",
    dealerId: 1,
    dealerName: "Cox Auto",
    dealerSetupStatus: SetupStatus.Complete,
    error: "",
    hasViewingAsFeature: true,
    isVinEmployee: true,
    userFullName: "John Jones",
    userHasAccessToMoreThanOneDealer: true,
    userId: 123456,
    userName: "jjones",
    userRole: "Admin",
    viewingAsUsername: ""
  },
  dynamicHeader: {
    dynamicHeaderLoadingStatus: "loaded",
    dynamicHeaderError: null,
    dynamicHeaderComponents: {
      account: true,
      activityBar: true,
      customerSearch: true,
      dealerSelector: true,
      logo: true,
      navigation: true,
      versionControl: true,
      userSelector: true,
      walkMe: true
    },
    dynamicMenu: {
      shouldUseDynamicMenu: false,
      dynamicMenuItems: [
        {
          id: "tab-amp-marketing-dashboard",
          label: "AMP Marketing Dashboard",
          url: "/CarDashboard/Pages/rims2.aspx?urlSettingName=Amp.Reporting.Wfe.Url&selectedDealerId=16548",
          menuItems: undefined
        },
        {
          id: "tab-dms-to-crm-sales-customer-matching",
          label: "DMS to CRM Sales/Customer Matching",
          url: "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?leftpaneframe=Reports/DMSSalesReportv2.aspx&rightpaneframe=HIDE",
          menuItems: undefined
        },
        {
          id: "tab-crm",
          label: "CRM",
          url: "",
          menuItems: [
            {
              id: "navigation-sub-menu-tab-crm-dashboard",
              label: "Dashboard",
              url: "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DealerDashboard2.aspx&rightpaneframe=DealerDashboard1.aspx",
              menuItems: [
                {
                  id: "navigation-sub-menu-tab-crm-dashboard-dealer-dashboard",
                  label: "Dealer Dashboard",
                  url: "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DealerDashboard2.aspx&rightpaneframe=DealerDashboard1.aspx",
                  menuItems: undefined
                },
                {
                  id: "navigation-sub-menu-tab-crm-dashboard-showroom-activity",
                  label: "Showroom Activity",
                  url: "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=ShowroomActivity.aspx&rightpaneframe=DealerDashboard1.aspx",
                  menuItems: undefined
                }
              ]
            }
          ]
        }
      ]
    },
    initialLandingTarget: {
      id: "navigation-sub-menu-tab-crm-dashboard-dealer-dashboard",
      label: "AMP Marketing Dashboard",
      url: "/CarDashboard/Pages/rims2.aspx?urlSettingName=Amp.Reporting.Wfe.Url&selectedDealerId=16548",
      menuItems: undefined
    }
  }
};
