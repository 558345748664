import { Button } from "cx";
import { StyledDMCTableAdd } from "./styles/dmc-table-add.style";
import { DMCDetail } from "../../../dmc-detail/dmc-detail.component";
import { DMCSave } from "../../../dmc-save/dmc-save.component";
import { useDMCContext } from "../../../../hooks/useDmcContext.hook";
import { DMCActionEnum } from "../../../../Enums/dmcAction.enum";

export const DMCTableAdd = () => {
  const {
    state: { isLoading },
    modalContext: { setModalContent },
    dispatch
  } = useDMCContext();

  const onHide = () => {
    if (dispatch) {
      dispatch({
        payload: {
          isSaveable: false
        },
        type: DMCActionEnum.DMC_UPDATE
      });
    }

    setModalContent({ show: false });
  };

  const onClick = () => {
    setModalContent({
      content: <DMCDetail />,
      footer: <DMCSave onCancelClickHandler={onHide} />,
      onHide,
      show: true
    });
  };

  return (
    <StyledDMCTableAdd>
      <Button
        aria-label="Add"
        data-testid="dealer-manufacturer-codes-add-button"
        disabled={isLoading}
        htmlId="dealer-manufacturer-codes-add-button"
        {...{ onClick }}
      >
        Add
      </Button>
    </StyledDMCTableAdd>
  );
};
