import { DMCComponentColorParams } from "../../types/dmcParams.type";
import { StyledDMCComponentColor } from "./styles/dmc-component-color.style";

export const DMCComponentColor = ({
  children,
  color
}: DMCComponentColorParams) => {
  return (
    <StyledDMCComponentColor color={color}>{children}</StyledDMCComponentColor>
  );
};
