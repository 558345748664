import { BaseUrlsObj, envEnum } from "@vinsolutions/core/environment";

export const BASE_URL: BaseUrlsObj = {
  [envEnum.LOCAL]: "http://localhost:3142/",
  [envEnum.DEV]: "https://dev-wsvc.vinsolutions.com/CoxAuto.Lead.OEM.DAL.Api/",
  [envEnum.QA]: "https://wsvc.qa.vinsolutions.com/CoxAuto.Lead.OEM.DAL.Api/",
  [envEnum.STAGING]:
    "https://staging-wsvc.vinsolutions.com/CoxAuto.Lead.OEM.DAL.API/",
  [envEnum.PRODUCTION]:
    "https://wsvc.vinsolutions.com/CoxAuto.Lead.OEM.DAL.Api/"
};
