// eslint-disable-next-line @nx/enforce-module-boundaries
import { BaseUrlsObj, envEnum } from "@vinsolutions/core/environment";

export const baseUrlsObj: BaseUrlsObj = {
  [envEnum.LOCAL]: "https://reporting-dev.np.vinsolutions.com/crm/dashboard",
  [envEnum.DEV]: "https://reporting-dev.np.vinsolutions.com/crm/dashboard",
  [envEnum.QA]: "https://reporting-qa.np.vinsolutions.com/crm/dashboard",
  [envEnum.STAGING]:
    "https://reporting-staging.np.vinsolutions.com/crm/dashboard",
  [envEnum.PRODUCTION]: "https://reporting.vinsolutions.com/crm/dashboard"
};
