import { Grid } from "@vinsolutions/core-cx";
import { AmpBanner } from "@vinsolutions/sub-feature/amp-banner";
import { AutomotiveIntelligenceBanners } from "@vinsolutions/sub-feature/automotive-intelligence-key-information";
import { KeyInformationDealBanner } from "@vinsolutions/sub-feature/deal-banner";
import { OemInsightsBanners } from "@vinsolutions/sub-feature/oem-insights-banner";
import { OpportunityAttributesBanners } from "@vinsolutions/sub-feature/opportunity-attributes-key-information";
import { VinessaBanner } from "@vinsolutions/sub-feature/vinessa-banner";
import { OnKeyInformationBannerLoad } from "@vinsolutions/ui/key-information-banner";
import styled from "styled-components";
import { KeyInformationProps } from "../interfaces/key-information-props";

export interface KeyInformationCollectionProps
  extends KeyInformationProps,
    OnKeyInformationBannerLoad {}

const StyledKeyInformationGrid = styled(Grid)`
  .cx-accordion-collapse .cx-accordion-body {
    padding: 0em;
    margin: 0em;
  }
`;

export function BannerCollection({
  customerId,
  customerFirstName,
  customerLastName,
  dealerId,
  activeLeadIds,
  selectedLeadId,
  onLoad,
  onInitialize,
  onClickGtmEvent
}: KeyInformationCollectionProps) {
  return (
    <StyledKeyInformationGrid htmlId="keyInfo-BannerCollection">
      <AutomotiveIntelligenceBanners
        customerId={customerId}
        dealerId={dealerId}
        onClickGtmEvent={onClickGtmEvent}
        onInitialize={onInitialize}
        onLoad={onLoad}
      />
      <KeyInformationDealBanner
        activeLeadIds={activeLeadIds}
        customerId={customerId}
        dealerId={dealerId}
        selectedLeadId={selectedLeadId}
        onClickGtmEvent={onClickGtmEvent}
        onInitialize={onInitialize}
        onLoad={onLoad}
      />
      <OpportunityAttributesBanners
        customerId={customerId || 0}
        onClickGtmEvent={onClickGtmEvent}
        onInitialize={onInitialize}
        onLoad={onLoad}
      />
      <OemInsightsBanners
        customerFirstName={customerFirstName || ""}
        customerId={customerId || 0}
        customerLastName={customerLastName || ""}
        dealerId={dealerId || 0}
        onClickGtmEvent={onClickGtmEvent}
        onInitialize={onInitialize}
        onLoad={onLoad}
      />
      <VinessaBanner
        customerId={customerId}
        dealerId={dealerId}
        onClickGtmEvent={onClickGtmEvent}
        onInitialize={onInitialize}
        onLoad={onLoad}
      />
      <AmpBanner
        customerId={customerId}
        dealerId={dealerId}
        onClickGtmEvent={onClickGtmEvent}
        onInitialize={onInitialize}
        onLoad={onLoad}
      />
    </StyledKeyInformationGrid>
  );
}

export default BannerCollection;
