import { getEpochTime } from "@vinsolutions/ccrm/api";
import { ClockService, UserManagerSettings } from "oidc-client";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("oidcConfiguration");

export type getOdicConfigurationProps = {
  clientId: string;
  clientSecret: string;
  root: string;
  acrValues: string;
  idsUrl: string;
  carDashboardUrl: string;
};

const getOidcConfiguration = (config: getOdicConfigurationProps) => {
  logger.debug("getOidcConfiguration: Getting OIDC configuration.");
  /* eslint-disable camelcase */
  return {
    client_id: config.clientId,
    client_secret: config.clientSecret,
    redirect_uri: config.root + "authentication/callback",
    response_type: "code",
    acr_values: config.acrValues,
    scope: "openid profile vin.users.query TrustedAPI",
    authority: config.idsUrl,
    silent_redirect_uri: config.root + "authentication/silent_callback",
    automaticSilentRenew: false,
    loadUserInfo: true,
    post_logout_redirect_uri: config.root,
    monitorSession: false,
    clockService: { getEpochTime } as ClockService,
    clockSkew: 3600
  } as UserManagerSettings;
  /* eslint-enable camelcase */
};

export default getOidcConfiguration;
