import {
  KeyInformationBanner,
  KeyInformationTextIcon
} from "@vinsolutions/ui/key-information-banner";
import OemInsightsBannerProps from "../../interfaces/oem-insights-banner-interface-props";
import BmwIconSmall from "./bmw-icon";
import { parseBmwMiniInsights } from "../../data-parse/bmw-mini/bmw-summary-data";
import BmwInsightsSummary from "./bmw-mini-insights-summary";
import OemInsightsDate from "../oem-banner-date";
import { useEffect, useMemo } from "react";

const bannerName = "BMW Insights";
export const BmwBaseDisplayedBanner = {
  bannerName,
  iconName: "BmwIconSmall",
  thumbnailName: "BmwIconSmall",
  thumbnailIcon: <BmwIconSmall key={"bmw-thumbnail-icon"} />
};

export function BmwInsightsBanner({
  oemInsightsSummary,
  onLoad,
  onClick
}: OemInsightsBannerProps) {
  const bmwInsights = useMemo(
    () => parseBmwMiniInsights(oemInsightsSummary, "bmw"),
    [oemInsightsSummary]
  );
  useEffect(() => {
    onLoad({
      ...BmwBaseDisplayedBanner,
      isDisplayed: !!bmwInsights
    });
  }, [onLoad, bmwInsights]);
  return (
    bmwInsights && (
      <KeyInformationBanner
        bannerDataDetail={<OemInsightsDate oemInsights={bmwInsights} />}
        bannerIcon={
          <KeyInformationTextIcon icon={<BmwIconSmall />} iconText="BMW" />
        }
        bannerName={bannerName}
        bannerSummary={<BmwInsightsSummary bmwMiniInsights={bmwInsights} />}
        onClick={() => onClick && onClick(bannerName, "bmw")}
      />
    )
  );
}

export default BmwInsightsBanner;
