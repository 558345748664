import styled from "styled-components";

export const StyledDMCTable = styled.div`
  .ant-table.ant-table-small {
    .ant-table-container {
      table {
        .ant-table-thead {
          th {
            &.ant-table-cell {
              background-color: #2053ab;
              color: white;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            font-weight: 400;
          }
        }
      }
    }
  }
`;
