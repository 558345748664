import { FunctionComponent } from "react";
import { VehicleInterest, VehicleInterestProps } from "../examples";
import { useSlideDrawer } from "./SliderDrawerIframe";
import { SlidingDrawerProps } from "@vinsolutions/core/cx";

export type DrawerIds = "drawer-vehicle-interests";
export type ComponentDrawerProps = VehicleInterestProps;

export interface SliderDrawerDetailData {
  componentId: DrawerIds;
  sliderDrawerProps: Partial<SlidingDrawerProps>;
  props: ComponentDrawerProps;
  originElement: HTMLIFrameElement;
}

export const SelectDrawer: FunctionComponent = () => {
  const { componentId } = useSlideDrawer();
  switch (componentId) {
    case "drawer-vehicle-interests":
      return <VehicleInterest />;
    default:
      throw new Error(`componentId ${componentId} does not exist`);
  }
};
