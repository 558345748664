import React, { useMemo } from "react";
import styled from "styled-components";
import { IconInsertInvitation, IconRefresh } from "cx";
import { LoadingIndicator } from "@vinsolutions/core/components";
import { Metric, MetricsFilter } from "@vinsolutions/ccrm/interfaces";
import getTheme, { Theme } from "../util/theme";
import { MemoizedMetrics } from "./memoized-metrics";

export interface ActivityBarProps {
  isDarkTheme: boolean;
  isLoading: boolean;
  metrics: Metric[];
  onFilterClick: () => void;
  savedFilter: MetricsFilter;
}

const StyledActivityBar = styled.div`
  display: grid;
  grid-template-columns: repeat(6, min-content);
  grid-template-rows: 44px;
  cursor: pointer;
`;

const StyledMetric = styled.div<Pick<Theme, "secondaryLabel" | "actionItem">>`
  min-width: 50px;
  border-right: 1px solid ${({ secondaryLabel }) => secondaryLabel};
  text-align: right;
  padding: 0 16px;
  line-height: 12px;

  :last-child {
    border-right: none;
  }

  &#ccrm-metrics-widget-refresh-button {
    align-self: center;

    svg {
      color: ${({ actionItem }) => actionItem};
    }
  }
`;

const StyledLabel = styled.span<Pick<Theme, "secondaryLabel">>`
  color: ${({ secondaryLabel }) => secondaryLabel};
  font-size: 12px;
  white-space: nowrap;
`;

const StyledIcon = styled.span<Pick<Theme, "actionItem">>`
  color: ${({ actionItem }) => actionItem};
  display: inline-block;
  height: 31px;
  width: 24px;
  position: relative;

  .cx-loadingIndicator {
    line-height: 11px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export function ActivityBar({
  isDarkTheme,
  isLoading,
  metrics,
  onFilterClick,
  savedFilter
}: ActivityBarProps) {
  // wondering if this is causing extra renders...
  const { secondaryLabel, actionItem } = useMemo(() => {
    return getTheme(isDarkTheme);
  }, [isDarkTheme]);

  const htmlId = "ccrm-metrics-widget";

  const refreshOnClick = () => {
    window.location.reload();
  };

  return (
    <StyledActivityBar id={htmlId}>
      <StyledMetric
        id={`${htmlId}-filters`}
        onClick={onFilterClick}
        {...{ secondaryLabel, actionItem }}
      >
        <StyledIcon id={`${htmlId}-filters-icon-wrapper`} {...{ actionItem }}>
          {isLoading ? (
            <LoadingIndicator htmlId={`${htmlId}-count-loading-indicator`} />
          ) : (
            <IconInsertInvitation htmlId={`${htmlId}-calendar-icon`} />
          )}
        </StyledIcon>
        <StyledLabel id={`${htmlId}-filters-label`} {...{ secondaryLabel }}>
          {savedFilter.label || ""}
        </StyledLabel>
      </StyledMetric>
      <MemoizedMetrics {...{ actionItem, htmlId, metrics, secondaryLabel }} />
      <StyledMetric
        id={`${htmlId}-refresh-button`}
        onClick={refreshOnClick}
        {...{ secondaryLabel, actionItem }}
      >
        <IconRefresh htmlId={`${htmlId}-refresh-icon`} />
      </StyledMetric>
    </StyledActivityBar>
  );
}

export { StyledLabel, StyledMetric };
export default ActivityBar;
