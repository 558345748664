import styled from "styled-components";
import { LoadingIndicator } from "@vinsolutions/core/components";
const StyledDisplay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;
const Authenticating = () => (
  <StyledDisplay>
    <LoadingIndicator action="Authenticating" htmlId="loading" />
    Authenticating
  </StyledDisplay>
);
export default Authenticating;
