import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReportType, ViewKind } from "./enums";

export const DEALER_DASHBOARD_FEATURE_KEY = "dealerDashboard";

export interface DealerDashboardState {
  viewKind: ViewKind;
  viewPath: string;
  reportType: ReportType;
  startDate: Date;
  endDate: Date;
  jwt: string;
  dealerId: number;
  isCustomDateRange: boolean;
  requestRefresh: number;
}

const now = new Date();

export const initialDealerDashboardDataState: DealerDashboardState = {
  viewKind: ViewKind.DEALER_DASHBOARD,
  viewPath: "",
  reportType: ReportType.NONE,
  startDate: now,
  endDate: now,
  jwt: "",
  dealerId: 0,
  isCustomDateRange: false,
  requestRefresh: Math.random()
};

const dealerDashboardSlice = createSlice({
  name: DEALER_DASHBOARD_FEATURE_KEY,
  initialState: initialDealerDashboardDataState,
  reducers: {
    setView: (
      state,
      action: PayloadAction<{ viewKind: ViewKind; viewPath: string }>
    ) => {
      state.viewKind = action.payload.viewKind;
      state.viewPath = action.payload.viewPath;
    },
    setReportType: (state, action: PayloadAction<ReportType>) => {
      state.reportType = action.payload;
    },
    setStartDate: (state, action: PayloadAction<Date>) => {
      if (state.startDate.toString() !== action.payload.toString()) {
        state.startDate = action.payload;
      }
    },
    setEndDate: (state, action: PayloadAction<Date>) => {
      if (state.endDate.toString() !== action.payload.toString()) {
        state.endDate = action.payload;
      }
    },
    setDates: (
      state,
      action: PayloadAction<{ startDate: Date; endDate: Date }>
    ) => {
      if (state.startDate.toString() !== action.payload.startDate.toString()) {
        state.startDate = action.payload.startDate;
      }

      if (state.endDate.toString() !== action.payload.endDate.toString()) {
        state.endDate = action.payload.endDate;
      }
    },
    setJwt: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    setDealerId: (state, action: PayloadAction<number>) => {
      state.dealerId = action.payload;
    },
    setIsCustomDateRange: (state, action: PayloadAction<boolean>) => {
      state.isCustomDateRange = action.payload;
    },
    setRequestRefresh: state => {
      state.requestRefresh = Math.random();
    }
  }
});

export const dealerDashboardReducer = dealerDashboardSlice.reducer;

export const {
  setView,
  setReportType,
  setStartDate,
  setEndDate,
  setDates,
  setJwt,
  setDealerId,
  setIsCustomDateRange,
  setRequestRefresh
} = dealerDashboardSlice.actions;

export const getDealerDashboardSliceState = (
  rootState: any
): DealerDashboardState => rootState[DEALER_DASHBOARD_FEATURE_KEY];
