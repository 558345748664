import { MetricsSection } from "@vinsolutions/ccrm/interfaces";
import { memo } from "react";
import MetricsCard from "../metrics-card";

/* eslint-disable-next-line */
export interface CardsProps {
  isLoading?: boolean;
  metrics: MetricsSection[];
}

export function Cards({ isLoading, metrics }: CardsProps) {
  return (
    <>
      {metrics.length > 0 &&
        metrics.map(({ metrics, title, titleCount }) => {
          const htmlId = `ccrm-metric-card-${title
            .toLowerCase()
            .split(" ")
            .join("-")}`;
          return (
            <MetricsCard
              className="ccrm-metrics-card"
              key={htmlId}
              {...{ htmlId, isLoading, metrics, title, titleCount }}
            />
          );
        })}
    </>
  );
}

const MemoizedCards = memo(Cards);
export default MemoizedCards;
