import { User } from "oidc-client";
import { AccessToken, OidcUser } from "./types";
import { IdToken } from "./types";
const jwtCache: { [name: string]: { [claimType: string]: string } } = {};

export function isJwtCacheOkay(cachedJWT: User) {
  if (!cachedJWT) return false;
  const fiveMinutes = 60000 * 5;
  const expiration = cachedJWT.expires_at * 1000;
  return new Date() <= new Date(expiration - fiveMinutes);
}

export const parseIdToken = (user: User) => {
  try {
    return JSON.parse(atob(user.id_token.split(".")[1])) as IdToken;
  } catch (e) {
    return null;
  }
};

export const unParseAccessToken = (accessToken: AccessToken) => {
  return "0." + btoa(JSON.stringify(accessToken));
};

export const parseAccessToken = (user: OidcUser) => {
  try {
    return JSON.parse(atob(user.access_token.split(".")[1])) as AccessToken;
  } catch (e) {
    return null;
  }
};

export const parseJwt = (token: string): { [claimType: string]: string } => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return {};
  }
};

export const getClaims = (token: string) => {
  if (jwtCache[token] && jwtCache[token] !== null) {
    return jwtCache[token];
  }

  return parseJwt(token);
};

export const getClaim = (token: string, claimType: string) => {
  if (token) {
    const claims = getClaims(token) as any;
    if (!claims) return "";
    return claims[claimType];
  }

  return "";
};
