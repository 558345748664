import CustomerEnrichmentModalContent from "./customer-enrichment-modal-content";
import { KeyInformationBannerModal } from "@vinsolutions/ui/key-information-banner";

export interface CustomerEnrichmentModalProps {
  customerId?: number;
  dealerId?: number;
  showModal: boolean;
  onHide: () => void;
}

const CustomerEnrichmentModal = ({
  customerId,
  dealerId,
  showModal,
  onHide
}: CustomerEnrichmentModalProps) => {
  return (
    <KeyInformationBannerModal showModal={showModal} onHide={onHide}>
      <CustomerEnrichmentModalContent
        customerId={customerId}
        dealerId={dealerId}
      />
    </KeyInformationBannerModal>
  );
};

export default CustomerEnrichmentModal;
