import {
  DynamicHeaderMenuItem,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";
import { createNavEntity } from "./create-nav-entity";

/**
 * Adds a dynamic header item to the navigation item entities
 * @param {DynamicHeaderMenuItem}   menuItem
 * @param {NavigationItemsEntity[]} navigationItemEntities
 */
export const addItemToItemEntities = (
  menuItem: DynamicHeaderMenuItem | null | undefined,
  navigationItemEntities: NavigationItemsEntity[] | null | undefined
) => {
  if (!menuItem) {
    return;
  }
  // check to see if it is already in the list, we don't want duplicates
  if (!navigationItemEntities?.find(ne => ne.id === menuItem?.id)) {
    navigationItemEntities?.push(createNavEntity(menuItem));
  }
  menuItem?.menuItems?.forEach(i => {
    addItemToItemEntities(i, navigationItemEntities);
  });
};
