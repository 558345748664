import { OemInsightsSummaryResponse } from "@vinsolutions/data-access/oem/vin.api.oeminsights";
import FordInsightsSummaryData from "../../interfaces/ford/ford-insights-summary-data";
import {
  getFormattedUpdatedUtcDate,
  getOemDataFromSummaryResponse
} from "../getOemDataItem";

export const parseFordInsights = (
  oemInsightsSummaryResponse: OemInsightsSummaryResponse | null
): FordInsightsSummaryData | null => {
  const dataItem = getOemDataFromSummaryResponse(
    oemInsightsSummaryResponse,
    "ford_direct"
  );
  if (dataItem) {
    const parsedItem = JSON.parse(dataItem);
    return {
      iciOfferCount: parsedItem.IciOfferCount || 0,
      rmtOfferCount: parsedItem.RmtOfferCount || 0,
      updatedDate: getFormattedUpdatedUtcDate(parsedItem.UpdatedUtc)
    };
  }
  return null;
};
