const BuyingSignalsLightBulb = () => {
  return (
    <svg
      className="keyInfo-small-icon lightbulb"
      height={20}
      viewBox="0 0 16 16"
      width={20}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="#2B6BDD"
          id="Spec-Redline"
          transform="translate(-9.000000, -11.000000)"
        >
          <g id="Light-icon-16" transform="translate(7.000000, 11.000000)">
            <g id="bulb-62" transform="translate(2.000000, 0.000000)">
              <path
                d="M6,0 C2.7,0 0,2.7 0,6 C0,8.2 1.2,10.1 3,11.2 L3,15 C3,15.6 3.4,16 4,16 L8,16 C8.6,16 9,15.6 9,15 L9,11.2 C10.8,10.1 12,8.2 12,6 C12,2.7 9.3,0 6,0 Z M5,14 L5,13 L7,13 L7,14 L5,14 Z M7.6,9.7 C6.71924939,10.2303888 5.43568178,10.1657275 4.4,9.7 C2.89065846,9.02127628 2,7.6 2,6 C2,3.8 3.8,2 6,2 C8.2,2 10,3.8 10,6 C10,7.6 8.81179226,8.97025765 7.6,9.7 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BuyingSignalsLightBulb;
