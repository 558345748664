import {
  DynamicHeaderMenuItem,
  NavigationEntity
} from "@vinsolutions/ccrm/interfaces";

/**
 * Converts a dynamic header menu item to the Navigation Entity
 * These are converted to Navigation Entity objects so that they can be added to the Navigation menu and tabs
 * @param {DynamicHeaderMenuItem[]} dynamicMenuItems
 */
export const dynamicMenuToNavEntity = (
  dynamicMenuItems: DynamicHeaderMenuItem[]
): NavigationEntity[] => {
  return dynamicMenuItems.map(i => {
    let items;
    if (i.menuItems) {
      items = dynamicMenuToNavEntity(i.menuItems);
    }
    return {
      id: i.id,
      items
    };
  });
};
