import { ChangeEvent, KeyboardEvent, useState } from "react";
import { getSearchUrl, searchModes } from "./search-bar-helper";
import SearchBarUI from "./search-bar-ui";
import { TagManager } from "@vinsolutions/core-third-party-gtm";

interface SearchBarProps {
  hasAdvancedCustomerSearch: boolean;
  setCarDashboardUrl: (path: string, name: string) => void;
}

const SearchBar = ({
  hasAdvancedCustomerSearch,
  setCarDashboardUrl
}: SearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchMode, setSearchMode] = useState(searchModes.Customers);
  const onInputChange = (cxEvent: { target: { value: any } }) => {
    const { value } = cxEvent.target;
    setSearchTerm(value);
  };

  const onSettingsChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchMode(searchModes[target.value as keyof typeof searchModes]);

    TagManager.event({
      eventElement: "TopNav: Search Bar",
      eventAction: `${target.value} search option click`,
      eventValue: `Switched Search mode to ${target.value}`
    });
  };

  const handleOnKeyUp = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === "Enter") {
      TagManager.event({
        eventElement: "TopNav: Search Bar",
        eventAction: `TopNav - Search enter keyboard button clicked for ${searchMode}`,
        eventValue: "Displayed Search Results page"
      });
      onSearch();
    }
    if (key === "Escape") {
      setSearchTerm("");
    }
  };

  const onSearch = () => {
    TagManager.event({
      eventElement: "TopNav: Search Bar",
      eventAction: `${searchMode} Search: ${searchTerm}`,
      eventValue: "Displayed Search Results page"
    });
    setCarDashboardUrl(
      getSearchUrl(searchMode, hasAdvancedCustomerSearch, searchTerm),
      "search bar"
    );
    setSearchTerm("");
    setSearchMode(searchModes.Customers);
  };

  return (
    <SearchBarUI
      {...{
        handleOnKeyUp,
        onInputChange,
        onSearch,
        onSettingsChange,
        searchMode,
        searchTerm
      }}
    />
  );
};

export default SearchBar;
