import styled from "styled-components";
import { Badge } from "@vinsolutions/core-cx";

const StyledNoModalDetailsMessage = styled.span`
  justify-self: right;
  font-weight: 700;
`;

export const GmNoModalDetailsMessage = () => {
  return (
    <StyledNoModalDetailsMessage>
      <Badge
        data-testid="gm-no-modal-details-badge"
        htmlId="gm-no-modal-details-badge"
      >
        Additional Details Unavailable
      </Badge>
    </StyledNoModalDetailsMessage>
  );
};

export default GmNoModalDetailsMessage;
