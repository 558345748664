const regexDealerCodeValidation = /^[0-9A-Za-z]+$/;
const regexDealerCodeValidationMiniBMW =
  /^(?<DealerNumber>[0-9]+)\.(?<StoreNumber>[0-9]+)$/;
const codeValidationErrorMsg =
  "Code must be letters and numbers with no symbols or spaces.";
const codeValidationErrorMsgMiniBMW =
  'BMW/MINI Dealer Code must be in the form of "<Center ID>.<Location>"';

// LD support to be removed
export const rollbackHondaAcuraUpdate = () => {
  DMC_TYPES[7].selectNonOemLeadCollection = false;
  DMC_TYPES[8].selectOemLeadStatusUpdate = true;
};
export const DMC_TYPES = Object.freeze({
  1: {
    name: "GM",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  3: {
    name: "Audi",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  4: {
    name: "Chrysler",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning:
      "Please Note: FIAT and Chrysler have been separated. Chrysler dealers have their own source and" +
      " dealer code. Please be sure that this is not a Chrysler dealer code.",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  5: {
    name: "VW",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  6: {
    name: "Ford_Direct",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  7: {
    name: "Honda",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  8: {
    name: "Acura",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  9: {
    name: "Mazda",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  10: {
    name: "Hyundai",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  11: {
    name: "Nissan",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  12: {
    // eslint-disable-next-line @cspell/spellchecker
    name: "Infiniti",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  13: {
    name: "Volvo",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  14: {
    name: "Toyota",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  15: {
    name: "Saab",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  16: {
    name: "Mitsubishi",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  17: {
    name: "JaguarLandRover",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  18: {
    name: "Mercedes",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  19: {
    name: "Porsche",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  20: {
    name: "Subaru",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: true
  },
  21: {
    name: "BMW",
    regexDealerCodeValidation: regexDealerCodeValidationMiniBMW,
    codeValidationErrorMsg: codeValidationErrorMsgMiniBMW,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  22: {
    name: "FIAT",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning:
      "Please Note: FIAT and Chrysler have been separated. FIAT dealers have their own source and" +
      " dealer code. Please be sure that this is not a FIAT dealer code.",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  23: {
    name: "MINI",
    regexDealerCodeValidation: regexDealerCodeValidationMiniBMW,
    codeValidationErrorMsg: codeValidationErrorMsgMiniBMW,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  24: {
    name: "Kia",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  25: {
    name: "Smart",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  26: {
    name: "Bentley",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  27: {
    name: "Maserati",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  28: {
    name: "Nissan_Canada",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  29: {
    name: "Ferrari",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  30: {
    name: "Chrysler_Canada",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  31: {
    name: "Genesis",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  32: {
    name: "RollsRoyce",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  33: {
    name: "RapidRTC",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  34: {
    name: "GM_Canada",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: false,
    selectOemLeadStatusUpdate: false,
    selectLeadTypeSwitching: false
  },
  35: {
    name: "Mercedes_Canada",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  },
  36: {
    name: "HarleyDavidson",
    regexDealerCodeValidation,
    codeValidationErrorMsg,
    sourceWarning: "",
    selectNonOemLeadCollection: true,
    selectOemLeadStatusUpdate: true,
    selectLeadTypeSwitching: false
  }
});
