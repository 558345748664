const BuyingSignalsLightBulbInverse = () => {
  return (
    <svg
      className="keyInfo-small-icon lightbulb"
      height={20}
      viewBox="0 0 16 16"
      width={20}
    >
      <defs>
        <path
          d="M6 0C2.7 0 0 2.7 0 6c0 2.2 1.2 4.1 3 5.2V15c0 .6.4 1 1 1h4c.6 0 1-.4 1-1v-3.8c1.8-1.1 3-3 3-5.2 0-3.3-2.7-6-6-6zM5 14v-1h2v1H5zm2.6-4.3c-.88.53-2.164.466-3.2 0A3.999 3.999 0 012 6c0-2.2 1.8-4 4-4s4 1.8 4 4c0 1.6-1.188 2.97-2.4 3.7z"
          id="prefix__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask fill="#fff" id="prefix__b">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g fill="#FFF" mask="url(#prefix__b)">
          <path d="M-2 0h16v16H-2z" />
        </g>
      </g>
    </svg>
  );
};

export default BuyingSignalsLightBulbInverse;
