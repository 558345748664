import { CcrmDealerManufacturerCodes } from "@vinsolutions/ccrm/dealer-manufacturer-codes";
import { RouteBuilder } from "../route-builder";

export const dealerManufacturerCodesRoute = RouteBuilder.create()
  .withRouteId("vinconnect", "dealer-manufacturer-codes")
  .withAliasId(
    "navigation-sub-menu-tab-admin-selected-dealer-dealer-manufacturer-codes"
  )
  .withFullComponent(<CcrmDealerManufacturerCodes />)
  .build();
