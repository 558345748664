/* eslint-disable camelcase */
import { useEffect } from "react";
import { SetupStatus, TopNavWindow } from "@vinsolutions/ccrm/interfaces";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { reverseMapStringEnum } from "@vinsolutions/ccrm/util";
import { useVinconnectFlagEnabled } from "@vinsolutions/core/third-party/launch-darkly";

declare global {
  interface Window {
    _walkmeConfig: {
      smartLoad: boolean;
    };
    _walkMe: {
      removeWalkMe(): void;
    };
  }
}

export const WalkMeDataIsland = () => {
  const {
    dealerId,
    dealerName,
    dealerSetupStatus,
    isVinEmployee,
    profileLoadingStatus,
    userFullName,
    userRole,
    userId
  } = useSelector(getProfileState, shallowEqual);
  const walkMeToggle = useVinconnectFlagEnabled("nx.vinconnect.walkme");

  useEffect(() => {
    const walkMeScriptNode = document.getElementById("walk-me-script-tag");
    if (walkMeToggle) {
      if (!walkMeScriptNode) {
        const walkMe = document.createElement("script");
        walkMe.type = "text/javascript";
        walkMe.async = true;
        walkMe.id = "walk-me-script-tag";
        walkMe.src =
          "https://cdn.walkMe.com/users/dc2fa48c7fdc4707ad3a8806069a4c8f/walkme_dc2fa48c7fdc4707ad3a8806069a4c8f_https.js";
        const head = document.getElementsByTagName("head")[0];
        head.appendChild(walkMe);
        window._walkmeConfig = { smartLoad: true };
      }
    } else {
      const walkMeScript = document.getElementById("walk-me-script-tag");
      if (walkMeScript) {
        walkMeScript.remove();
        window._walkMe.removeWalkMe();
      }
    }
  }, [walkMeToggle]);

  useEffect(() => {
    if (profileLoadingStatus && profileLoadingStatus === "loaded") {
      (window as TopNavWindow).walk_me_data_island = {
        dealerId: dealerId ?? 0,
        dealerName,
        dealerSetupStatus: reverseMapStringEnum(SetupStatus, dealerSetupStatus),
        isVinEmployee,
        userFullName,
        userRole,
        userId
      };
    }
  }, [
    userId,
    dealerId,
    dealerName,
    dealerSetupStatus,
    isVinEmployee,
    profileLoadingStatus,
    userFullName,
    userRole
  ]);

  return null;
};
