import { vinconnectDark } from "./vinconnect-dark";
import { vinconnectLight } from "./vinconnect-light";

const defaultTheme = "vinconnectLight";

export const deriveTheme = (key: string) => {
  return (
    {
      vinconnectDark,
      vinconnectLight
    } as { [key: string]: any }
  )[key];
};

export const getCurrentTheme = async () => {
  const savedThemeId =
    localStorage.getItem("vinconnectThemeId") || defaultTheme;
  const theme = deriveTheme(savedThemeId);
  return {
    currentThemeId: savedThemeId,
    ...theme
  };
};
