import { loadManifest } from "./load-manifest";
import { loadRemoteContainer } from "./load-remote-container";
import { RemoteContainer } from "./types";
import { ComponentType } from "react"; // Import React's ComponentType for typing

// Internal state to manage loaded modules and containers
const remoteModuleMap = new Map<string, unknown>();
const remoteContainerMap = new Map<string, RemoteContainer>();
let remoteUrlDefinitions: Record<string, string> = {};

/**
 * Sets the remote URL definitions for remote modules.
 * @param definitions - The object containing remote URL definitions.
 */
export function setRemoteDefinitions(
  definitions: Record<string, string>
): void {
  remoteUrlDefinitions = definitions;
}

/**
 * Clears the cache of loaded remote modules and containers.
 * This is useful for testing purposes.
 */
export function clearCache(): void {
  remoteModuleMap.clear();
  remoteContainerMap.clear();
  remoteUrlDefinitions = {};
}

/**
 * Loads a remote module by its name and module.
 * This is the main function to orchestrate loading modules dynamically
 * using Webpack's Module Federation.
 *
 * @param remoteName - The name of the remote.
 * @param moduleName - The module to load from the remote.
 * @returns A Promise that resolves to an object with a default export of a React component.
 * @throws Error if the remote module cannot be loaded.
 */
export async function loadRemoteModule(
  remoteName: string,
  moduleName: string
): Promise<{ default: ComponentType<any> }> {
  // Load remote definitions if they are not set
  if (Object.keys(remoteUrlDefinitions).length === 0) {
    const definitions = await loadManifest();
    setRemoteDefinitions(definitions);
  }

  // Construct the key for the remote module
  const remoteModuleKey = `${remoteName}:${moduleName}`;
  if (remoteModuleMap.has(remoteModuleKey)) {
    return remoteModuleMap.get(remoteModuleKey) as {
      default: ComponentType<any>;
    };
  }

  // Load the remote container if not already loaded
  const container = remoteContainerMap.has(remoteName)
    ? remoteContainerMap.get(remoteName)
    : await loadRemoteContainer(
        remoteName,
        async name => remoteUrlDefinitions[name],
        remoteUrlDefinitions,
        remoteContainerMap,
        (name, container) => remoteContainerMap.set(name, container)
      );

  if (!container) {
    throw new Error(`Failed to load remote container for ${remoteName}`);
  }

  // Fetch the module factory function from the container
  const factory = await container.get(moduleName);

  if (!factory) {
    throw new Error(
      `Failed to load module '${moduleName}' from remote '${remoteName}'.`
    );
  }

  const Module = factory();

  // Cache the loaded module
  remoteModuleMap.set(remoteModuleKey, Module);

  // Ensure the loaded module conforms to the expected shape for React.lazy
  if (!Module || typeof Module !== "object" || !("default" in Module)) {
    throw new Error(
      `The loaded module does not have a default export that is a React component`
    );
  }

  return Module as { default: ComponentType<any> };
}
