import { IVinManagerInventoryMaster } from "./vinmanager-inventory-master";
import { communicationsNavigationItemEntityIds } from "./navigation-ids";
import { generateDestinationUrl } from "./vinmanager-inventory-master";
import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";

const getAllMessagingNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  const messagingNavigationItemsEntities: NavigationItemsEntity[] = [
    {
      id: communicationsNavigationItemEntityIds.messageProviders,
      label: "Message Providers",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/rims2.aspx",
          leftQueryString: {
            urlSettingName: "brawndo.communicationsui.url",
            type: "third party providers",
            mp: true
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    }
  ];

  return messagingNavigationItemsEntities;
};

export const buildMessagingNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  if (!vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging) {
    return {
      messagingNavigationItemEntities: [],
      messagingNavigationEntity: null
    };
  }

  const messagingNavigationItemEntities = getAllMessagingNavigationItems(
    vinManagerInventoryMaster
  );

  const messagingNavigationEntity: NavigationEntity = {
    id: communicationsNavigationItemEntityIds.messageProviders
  };

  return {
    messagingNavigationItemEntities,
    messagingNavigationEntity
  };
};
