import { FunctionComponent } from "react";
import { useModalContext } from "../Modal/ModalIframe";

export interface LostProps {
  autoLeadId: string;
}

export const Lost: FunctionComponent = () => {
  const { componentProps } = useModalContext();
  const { autoLeadId } = componentProps as LostProps;
  return <>Lost: {autoLeadId}</>;
};
