import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserBridgeStateById } from "@vinsolutions/tenant/api";

export const USER_BRIDGE_STATE_FEATURE_KEY = "userBridgeState";

export interface UserBridgeStateEntity {
  bridgeStatus: null | number;
  bridgeUsername: null | string;
  platformId: null | string;
  givenName: null | string;
  familyName: null | string;
  isLocked: null | boolean;
}

export interface UserBridgeState extends UserBridgeStateEntity {
  loadingStatus: "not loaded" | "loading" | "loaded" | "error";
  error: string | null;
}

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchUserBridgeState())
 * }, [dispatch]);
 * ```
 */
export const fetchUserBridgeState = createAsyncThunk(
  "userBridgeState/fetchStatus",
  async (jwt: string) => {
    return await getUserBridgeStateById(jwt);
  }
);

export const initialUserBridgeState: UserBridgeState = {
  bridgeStatus: null,
  bridgeUsername: null,
  platformId: null,
  givenName: null,
  familyName: null,
  isLocked: null,
  loadingStatus: "not loaded",
  error: null
};

export const userBridgeStateSlice = createSlice({
  name: USER_BRIDGE_STATE_FEATURE_KEY,
  initialState: initialUserBridgeState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserBridgeState.pending, (state: UserBridgeState) => {
        state.loadingStatus = "loading";
      })
      .addCase(
        fetchUserBridgeState.fulfilled,
        (
          state: UserBridgeState,
          action: PayloadAction<UserBridgeStateEntity>
        ) => {
          return {
            ...state,
            ...action.payload,
            loadingStatus: "loaded",
            error: null
          };
        }
      )
      .addCase(
        fetchUserBridgeState.rejected,
        (state: UserBridgeState, action) => {
          state.loadingStatus = "error";
          if (action.error.message) {
            state.error = action.error.message;
          }
        }
      );
  }
});

/*
 * Export reducer for store configuration.
 */
export const userBridgeStateReducer = userBridgeStateSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(userBridgeStateActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const userBridgeStateActions = userBridgeStateSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllUserBridgeState);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
export const getUserBridgeState = (rootState: any): UserBridgeState =>
  rootState[USER_BRIDGE_STATE_FEATURE_KEY];
