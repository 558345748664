import { Loading, path as LoadingPath } from "./loading";
import {
  CardashboardIframe,
  path as CardashboardIframePath
} from "@vinsolutions/legacy-cardashboard/cardashboard-iframe/feature";
import {
  SystemUnavailable,
  path as SystemUnavailablePath
} from "./system-unavailable";

const baseRoute = "/";
export const Paths = {
  CarDashboardUnavailable: baseRoute + SystemUnavailablePath,
  Loading: baseRoute + LoadingPath,
  CardashboardIframePath: baseRoute + CardashboardIframePath
};
export { Loading, CardashboardIframe, SystemUnavailable };
