import { useState } from "react";
import {
  Alert,
  Button,
  ModalDialog,
  SubmitButton,
  TextArea
} from "@vinsolutions/core/cx";
import styled from "styled-components";
import { sendFeedbackEmail } from "@vinsolutions/ccrm/api";
import ReactDOM from "react-dom";
import { TagManager } from "@vinsolutions/core-third-party-gtm";

/* eslint-disable-next-line */
export interface FeedbackModalProps {
  showFeedbackModal: boolean;
  toggleShowFeedbackModal: () => void;
}

const StyledFeedbackModal = styled.div``;

const StyledFeedbackModalButtons = styled.span`
  margin-left: auto;
`;
const StyledFeedbackModalBottomContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const characterLimit = 4000;

export function FeedbackModal({
  showFeedbackModal,
  toggleShowFeedbackModal
}: FeedbackModalProps) {
  const savedFeedbackLength =
    (sessionStorage.getItem("feebackMessageStorage") || {}).length || 0;
  const [isLoading, setIsLoading] = useState(false);
  const [charsLeft, setCharsLeft] = useState(
    characterLimit - savedFeedbackLength
  );
  const [feedbackMessage, setFeedbackMessage] = useState(
    sessionStorage.getItem("feebackMessageStorage") || ""
  );
  const [hasError, setHasError] = useState(false);

  const closeModal = async () => {
    TagManager.event({
      eventElement: "TopNav: Feedback Modal",
      eventAction: "TopNav Feedback Cancel button clicked",
      eventValue: "TopNav Feedback cancel click"
    });
    toggleShowFeedbackModal();
  };

  const onInputChange = (cxEvent: {
    target: { value: string; name: string };
  }) => {
    sessionStorage.setItem("feebackMessageStorage", cxEvent.target.value);
    setFeedbackMessage(cxEvent.target.value);
    setCharsLeft(characterLimit - cxEvent.target.value.length);
  };

  const sendFeedbackMessage = () => {
    TagManager.event({
      eventElement: "TopNav: Feedback Modal",
      eventAction: "TopNav Feedback Submit button clicked",
      eventValue: "TopNav Feedback Submit click"
    });

    setIsLoading(true);

    sendFeedbackEmail(feedbackMessage).then(res => {
      // If this breaks after deploying the app rewrite, probably because I couldn't test the axios conversion locally
      if (res && res.statusCode === 200) {
        sessionStorage.setItem("feebackMessageStorage", "");
        ReactDOM.unstable_batchedUpdates(() => {
          setIsLoading(false);
          if (hasError) {
            setHasError(false);
          }
          toggleShowFeedbackModal();
        });
      } else {
        ReactDOM.unstable_batchedUpdates(() => {
          setHasError(true);
          setIsLoading(false);
        });
      }
    });
  };
  return (
    <StyledFeedbackModal>
      <ModalDialog
        className={"FeedbackModal"}
        header={<h4>Feedback</h4>}
        htmlId={"Feedback-modal"}
        show={showFeedbackModal}
        onHide={closeModal}
      >
        {hasError && (
          <Alert htmlId="feedback-modal-error-alert" type="danger">
            Unable to submit feedback at this time. Please try again.
          </Alert>
        )}
        <p id="feedback-upper-content">
          If you need immediate assistance, please contact Support. Feedback may
          not receive a response.
        </p>
        <TextArea
          htmlId="feedback-modal-feedback-text-area"
          label="Comment"
          maxLength={characterLimit}
          name="Feedback entry field"
          rows={5}
          value={feedbackMessage}
          onChange={onInputChange}
        />
        <StyledFeedbackModalBottomContent>
          <span id="character-counter">Characters remaining: {charsLeft}</span>
          <StyledFeedbackModalButtons>
            <Button
              buttonStyle="link"
              htmlId="feedback-modal-cancel-button"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <SubmitButton
              disabled={!feedbackMessage.length}
              htmlId="feedback-modal-submit-button"
              isLoading={isLoading}
              onClick={sendFeedbackMessage}
            >
              Submit
            </SubmitButton>
          </StyledFeedbackModalButtons>
        </StyledFeedbackModalBottomContent>
      </ModalDialog>
    </StyledFeedbackModal>
  );
}

export default FeedbackModal;
