// prettier-ignore
import styled from "styled-components";
import { ThemeState } from "@vinsolutions/ccrm/interfaces";

export const StyledNavigationMenu = styled.div<
  Pick<ThemeState, "secondary" | "aboveSecondary">
>`
  min-width: 0;

  .ant-menu-horizontal {
    background-color: ${({ secondary }) => secondary};
    color: ${({ aboveSecondary }) => aboveSecondary};

    .ant-menu-submenu:not(.ant-menu-overflow-item-rest) {
      .ant-menu-submenu-title {
        padding-right: 16px;

        .ant-menu-submenu-arrow {
          display: inline-block;
          color: #fff;
          right: -8px;
          transform: rotate(90deg);
          top: 14px;

          &:before {
            width: 10px;
            height: 1px;
            border-radius: 0;
            transform: rotate(35deg) translateY(-3.5px);
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 25px;
          }
          &:after {
            width: 10px;
            height: 1px;
            border-radius: 0;
            transform: rotate(-35deg) translateY(3.5px);
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            border-top-left-radius: 25px;
          }
        }
      }
    }

    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
    .ant-menu-overflow-item,
    .ant-menu-overflow-item:hover,
    .ant-menu-submenu-title:hover {
      color: ${({ aboveSecondary }) => aboveSecondary};
    }
  }

  .ant-menu-horizontal,
  .ant-menu-horizontal .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after,
  .ant-menu-horizontal .ant-menu-submenu-title,
  .ant-menu-horizontal .ant-menu-submenu-title::after,
  .ant-menu-submenu-title,
  .ant-menu-submenu-title::after,
  .ant-menu-title-content,
  .ant-menu-overflow-item {
    border-bottom: none;
    transition: none;
  }

  .ant-menu-overflow-item {
    height: 32px;
    line-height: 32px;
    border-bottom: none;

    .btn-link {
      padding: 0;
      margin: 0;
      vertical-align: inherit;
      color: ${({ aboveSecondary }) => aboveSecondary};

      a,
      a:hover {
        color: ${({ aboveSecondary }) => aboveSecondary};
      }
    }
  }

  .ant-menu-horizontal > .ant-menu-item:hover::after,
  .ant-menu-horizontal > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal > .ant-menu-item-active::after,
  .ant-menu-horizontal > .ant-menu-submenu-active::after,
  .ant-menu-horizontal > .ant-menu-item-open::after,
  .ant-menu-horizontal > .ant-menu-submenu-open::after,
  .ant-menu-horizontal > .ant-menu-item-selected::after,
  .ant-menu-horizontal > .ant-menu-submenu-selected::after {
    border-bottom: none;
    box-shadow: none;
    transition: none;
  }
`;
