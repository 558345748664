import {
  RouteBackButton,
  RouteButtonPosition,
  RouteComponent,
  RouteComponentOptions,
  RouteEntityType,
  RouteList,
  RoutePanePlacement
} from "@vinsolutions/ccrm/top-nav/route-config";
import { RenderView } from "./render-view";
import { createContext } from "react";
import {
  GenericFlags,
  VinconnectFlagNames
} from "@vinsolutions/core/third-party/launch-darkly";
import { testRouteList } from "./testRouteList";
import { RouteObject } from "react-router-dom";
import { CardashboardLayout } from "./cardashboard-layout";
import { createLogger } from "@vinsolutions/logger";
export type RouteManagerType = RouteManager | null | undefined;

const logger = createLogger("RouteManager");

/**
 * Contains the current instance of the router manager
 */
export const RouteManagerContext = createContext<RouteManagerType>(undefined);

/**
 * Used to manage the routes for views
 */
export class RouteManager {
  configuration = new Map<string, RouteEntityType>();
  vinconnectFlags: GenericFlags<VinconnectFlagNames>;

  /**
   * Constructor
   * @param {RouteEntityType[]} routeList The routing configuration
   * @param {GenericFlags<VinconnectFlagNames>} vinconnectFlags The vinconnect feature flags
   */
  constructor(
    routeList: RouteList,
    vinconnectFlags: GenericFlags<VinconnectFlagNames>
  ) {
    logger.debug("RouteManager constructor");
    this.buildConfiguration(routeList);
    this.vinconnectFlags = vinconnectFlags;
  }
  /**
   * Get the path for react routes
   * @param {string} routeId The route Identifier
   * @returns {string} Returns the URI decoded path
   * @private
   */

  getRoutePath(routeId: string) {
    logger.debug("RouteManager getRoutePath routeId:" + routeId);
    const pane = this.getPanePlacement(routeId);
    return encodeURI(`/pane-${pane}/${routeId}`);
  }

  /**
   * Get the path for a route based on product name and view name
   * @param {string}  productName The product name
   * @param {string}  viewName    The view name
   */
  getPathForRoute(productName: string, viewName: string) {
    return this.getRoutePath(
      RouteManager.routeNameBuilder(productName, viewName)
    );
  }

  /**
   * Gets the route configuration from the route configuration object
   * @param {string}  routeId The unique route identifier
   */
  getConfigForRoute(routeId: string) {
    logger.debug("RouteManager getConfigForRoute routeId:" + routeId);
    return this.configuration.get(routeId);
  }

  /**
   * Used to determine if a menu button item is configured for react applications
   * Requires that there is a feature flag and that it is active
   * @param {string}  productName The name of the product for the route
   * @param {string}  viewName    The name of the view for the route
   */
  isActiveRoute(productName: string | undefined, viewName: string | undefined) {
    logger.debug("RouteManager isActiveRoute productName:" + productName);
    if (!productName || !viewName) {
      return false;
    }
    const routeConfig = this.getConfigForRoute(
      RouteManager.routeNameBuilder(productName, viewName)
    );

    const isActive = routeConfig && this.checkFeatureFlag(routeConfig);
    logger.debug("RouteManager isActiveRoute return:" + isActive);
    return isActive;
  }

  isActiveRouteByAlias(aliasId: string) {
    logger.debug("RouteManager isActiveRouteByAlias aliasId:" + aliasId);
    const routeConfig = this.getConfigForRoute(aliasId);

    return routeConfig && this.checkFeatureFlag(routeConfig);
  }
  checkFeatureFlag(routeConfig: RouteEntityType | undefined) {
    logger.debug("RouteManager checkFeatureFlag routeConfig:" + routeConfig);
    return (
      !routeConfig?.featureFlag ||
      this.vinconnectFlags[routeConfig?.featureFlag]
    );
  }

  /**
   * Gets the type of pane for a route.  The pane type defines the placement of a pane
   * If full is defined it takes precedence over left or right
   * @param {string} routeId The identifier of the route
   * @return {RoutePanePlacement | undefined}
   */
  getPanePlacement(routeId: string): RoutePanePlacement {
    logger.debug("RouteManager getPanePlacement routeId:" + routeId);
    const route = this.getConfigForRoute(routeId);
    let paneType: RoutePanePlacement = RoutePanePlacement.UNDEFINED;

    if (!route) {
      return paneType;
    }
    if (route?.panes?.full) {
      paneType = RoutePanePlacement.FULL;
    } else if (route?.panes?.left && route?.panes?.right) {
      paneType = RoutePanePlacement.BOTH;
    } else if (route?.panes?.left) {
      paneType = RoutePanePlacement.LEFT;
    } else if (route?.panes?.right) {
      paneType = RoutePanePlacement.RIGHT;
    }
    return paneType;
  }
  /**
   * Builds a route based on the navigation items from the routeConfiguration
   * The routeConfiguration object contains the views that have been converted to React pages within Vinconnect
   * React pages are rendered with the RenderView component
   * */
  routeBuilder(): RouteObject[] {
    const routes: RouteObject[] = [];
    this.configuration.forEach((route, key) => {
      const panePlacement = this.getPanePlacement(key);
      if (panePlacement === RoutePanePlacement.UNDEFINED) {
        // eslint-disable-next-line no-console
        logger.error(
          `routeBuilder: pane placement is undefined for route:  ${route.routeId}, route will not be in routing list`
        );
      } else {
        logger.debug("RouteManager routeBuilder key:", key);
        routes.push({
          path: this.getRoutePath(key),
          element: (
            <CardashboardLayout>
              <RenderView routeId={route.routeId} />
            </CardashboardLayout>
          ),
          id: route.routeId
        });
      }
    });
    return routes;
  }

  /**
   * Gets the component for the route and the pane
   * @param {string}              routeId The route identifier
   * @param {RoutePanePlacement}  pane    The pane
   */
  getComponentForPane(
    routeId: string,
    pane: RoutePanePlacement
  ): RouteComponent {
    logger.debug("RouteManager getComponentForPane routeId:", routeId);
    const config = this.configuration.get(routeId);
    switch (pane) {
      case RoutePanePlacement.LEFT: {
        return config?.panes?.left?.component;
      }
      case RoutePanePlacement.RIGHT: {
        return config?.panes?.right?.component;
      }
      case RoutePanePlacement.FULL: {
        return config?.panes?.full?.component;
      }
      default:
        return undefined;
    }
  }

  /**
   * Gets the componentOptions for the route and the pane
   * @param {string}              routeId The route identifier
   * @param {RoutePanePlacement}  pane    The pane
   */
  getOptionsForPane(
    routeId: string,
    pane: RoutePanePlacement
  ): RouteComponentOptions | undefined {
    logger.debug("RouteManager getOptionsForPane routeId:", routeId);

    const config = this.configuration.get(routeId);

    switch (pane) {
      case RoutePanePlacement.LEFT: {
        return config?.panes?.left?.options;
      }
      case RoutePanePlacement.RIGHT: {
        return config?.panes?.right?.options;
      }
      default:
        return undefined;
    }
  }

  /**
   * Gets expansionButtonPosition for the route and the pane
   * @param {string}              routeId The route identifier
   * @param {RoutePanePlacement}  pane    The pane
   */
  getExpansionButtonPositionForPane(
    routeId: string,
    pane: RoutePanePlacement
  ): RouteButtonPosition | undefined {
    logger.debug("RouteManager getIsPaneExpandable routeId:" + routeId);

    const config = this.configuration.get(routeId);

    switch (pane) {
      case RoutePanePlacement.LEFT: {
        return (
          config?.panes?.left?.options?.expansionButtonPosition ?? undefined
        );
      }
      case RoutePanePlacement.RIGHT: {
        return (
          config?.panes?.right?.options?.expansionButtonPosition ?? undefined
        );
      }
      default:
        return undefined;
    }
  }

  /**
   * Builds the configuration based on the route list.
   * An error is thrown if the route is a duplicate route.
   *
   * @param {RouteEntityType} routeList The list of routes
   * @private
   */
  buildConfiguration(routeList: RouteList) {
    logger.debug("RouteManager buildConfiguration routeList:", routeList);
    const effectiveList = routeList.concat(testRouteList);
    effectiveList?.filter(Boolean).forEach((route: RouteEntityType) => {
      if (
        this.configuration.get(route.routeId) ||
        (route.aliasId && this.configuration.get(route.aliasId))
      ) {
        throw new Error(
          `buildConfiguration: Attempting to add duplicate route: [${route.routeId}]`
        );
      }
      logger.debug(
        "RouteManager buildConfiguration adding route:\n" +
          JSON.stringify(route) +
          "\nrouteId:" +
          route.routeId
      );
      this.configuration.set(route.routeId, route);
      if (route.aliasId) {
        logger.debug(
          "RouteManager buildConfiguration adding alias ",
          JSON.stringify(route),
          route.aliasId
        );
        this.configuration.set(route.aliasId, route);
      }
    });
  }
  static routeNameBuilder(productName: string, viewName: string) {
    logger.debug(
      "RouteManager routeNameBuilder productName",
      productName,
      viewName
    );
    return `${productName}-${viewName}`;
  }

  /**
   * This sets the state that is added to the route when the history is pushed
   * the forceRefresh is used to force a re-render, for instance, if a user clicks a menu button again it should re-render
   * @param forceRefresh
   * @param queryParams
   */
  setRouteState(
    forceRefresh: boolean,
    queryParams: any,
    routeBackButton?: RouteBackButton
  ) {
    logger.debug(
      "RouteManager setRouteState forceRefresh:" +
        forceRefresh +
        " queryParams:" +
        queryParams
    );
    return {
      refreshTimeStamp: forceRefresh ? Date.now().toString() : undefined,
      queryParams,
      routeBackButton
    };
  }
}
