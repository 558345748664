import OpportunityAttributesKeyInformationProps from "./interfaces/opportunity-attributes-key-information-props";
import { KbbIcoBanner } from "./kbbico-banner";
import AutoTraderBanner from "./sub-feature-autotrader-banner";

export function OpportunityAttributesBanners({
  customerId = 0,
  onClickGtmEvent,
  onInitialize,
  onLoad
}: OpportunityAttributesKeyInformationProps) {
  return (
    <>
      <KbbIcoBanner
        customerId={customerId}
        onClickGtmEvent={onClickGtmEvent}
        onInitialize={onInitialize}
        onLoad={onLoad}
      />
      <AutoTraderBanner
        customerId={customerId}
        onInitialize={onInitialize}
        onLoad={onLoad}
      />
    </>
  );
}
