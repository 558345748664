import {
  Log,
  LogLevel,
  TransportFn,
  configure,
  create
} from "@raynode/nx-logger";
import { NewRelic } from "@vinsolutions/core-third-party-newrelic";

const loggers = new Map<string, Log>();

const appInstanceId =
  crypto !== undefined && crypto.randomUUID instanceof Function
    ? crypto.randomUUID()
    : "Jest test so no crypto";

const transport: TransportFn = (configuration, messages, verbosity) => {
  const getMessage = () => {
    return (
      appInstanceId + ":" + configuration.namespace + ":" + messages.join("\n")
    );
  };
  try {
    switch (verbosity) {
      case LogLevel.DEBUG:
        // eslint-disable-next-line no-console
        console.debug(getMessage());
        break;
      case LogLevel.INFO:
        // eslint-disable-next-line no-console
        console.info(getMessage());
        break;
      case LogLevel.WARN:
        // eslint-disable-next-line no-console
        console.warn(getMessage());
        break;
      case LogLevel.ERROR:
        // eslint-disable-next-line no-case-declarations
        const errMessage = getMessage();
        // eslint-disable-next-line no-console
        console.error(errMessage);
        // eslint-disable-next-line no-case-declarations
        const err = new Error(errMessage);
        NewRelic.noticeError(err);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(getMessage());
        break;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(appInstanceId + " : " + error);
  }
};

const getLogLevel = (): LogLevel => {
  const hostname = window.location.hostname.toLowerCase();

  if (process.env["NX_CUSTOM_LOGGER"] === "false") {
    return LogLevel.ERROR;
  }

  if (hostname === "localhost") {
    return LogLevel.DEBUG;
  }
  if (hostname.indexOf("dev") > -1) {
    return LogLevel.DEBUG;
  }
  if (hostname.indexOf("qa") > -1) {
    return LogLevel.DEBUG;
  }

  return LogLevel.ERROR;
};

configure({
  transport,
  verbosity: getLogLevel(),
  enabled: true
});
function createLoggerForNamespace(name: string): Log {
  const context = create(name);
  return context;
}

function loggerFactory(namespace: string): Log {
  let logger = loggers.get(namespace);
  if (!logger) {
    logger = createLoggerForNamespace(namespace);
    loggers.set(namespace, logger);
  }
  return logger;
}

const logger = loggerFactory("logger");

export function createLogger(namespace: string): Log {
  logger.debug("createLogger: " + namespace);
  return loggerFactory(namespace);
}

export function getAppInstanceId(): string {
  return appInstanceId;
}

export function logFunction() {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const functionLogger = createLogger(target.constructor.name);
    functionLogger.debug(
      target.constructor.name + "." + propertyKey + " called"
    );
  };
}
