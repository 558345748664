export enum DateRangeType {
  /**
   * This member is not in the server enum.
   * Here in the UI it's used to unselect all date range chips.
   */
  CUSTOM = 0,
  TODAY = 1,
  YESTERDAY = 2,
  LAST_7_DAYS = 3,
  MONTH_TO_DATE = 5,
  LAST_MONTH = 6
}

export const dateRangeLabel: { [key in DateRangeType]: string } = {
  [DateRangeType.CUSTOM]: "Custom dates",
  [DateRangeType.TODAY]: "Today",
  [DateRangeType.YESTERDAY]: "Yesterday",
  [DateRangeType.LAST_7_DAYS]: "Last 7 days",
  [DateRangeType.MONTH_TO_DATE]: "MTD",
  [DateRangeType.LAST_MONTH]: "Last 30 days"
};

export const dateRangeOpenVisitsFrom: { [key in DateRangeType]: number } = {
  [DateRangeType.TODAY]: 3,
  [DateRangeType.YESTERDAY]: 3,
  [DateRangeType.CUSTOM]: 3,
  [DateRangeType.LAST_7_DAYS]: 7,
  [DateRangeType.LAST_MONTH]: 30,
  [DateRangeType.MONTH_TO_DATE]: 30
};
