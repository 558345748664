import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deriveTheme, getCurrentTheme } from "@vinsolutions/ccrm/util";

import { ThemeState } from "@vinsolutions/ccrm/interfaces";
export const THEME_FEATURE_KEY = "theme";

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchTheme())
 * }, [dispatch]);
 * ```
 */
export const fetchTheme = createAsyncThunk(
  "theme/fetchStatus",
  async (_, thunkAPI) => {
    /**
     * Replace this with your custom fetch call.
     * For example, `return myApi.getThemes()`;
     * Right now we just return an empty array.
     */
    const response = await getCurrentTheme();
    return response;
  }
);

export const initialThemeState: ThemeState = {
  actionItem: "#2b6bdd",
  activeTabColor: "#fff",
  background: "#fff",
  buttonHover: "#173a78",
  currentThemeId: "default",
  divider: "#6d727a",
  error: null,
  loadingStatus: "not loaded",
  abovePrimary: "#000",
  aboveSecondary: "#fff",
  aboveSurface: "#000",
  primary: "#0e2245",
  secondary: "#2153aa",
  secondaryActionItem: "#2053ab",
  secondaryIcon: "#2153aa",
  secondaryLabel: "#6d727a",
  surface: "#0e2245",
  showNavigation: true
};

export const themeSlice = createSlice({
  name: THEME_FEATURE_KEY,
  initialState: initialThemeState,
  reducers: {
    set: (state, action) => {
      const theme = deriveTheme(action.payload);
      localStorage.setItem("vinconnectThemeId", action.payload);
      return { ...state, ...theme, currentThemeId: action.payload };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTheme.pending, (state: ThemeState) => {
        state.loadingStatus = "loading";
      })
      .addCase(
        fetchTheme.fulfilled,
        (state: ThemeState, action: PayloadAction<Partial<ThemeState>>) => {
          return { ...state, ...action.payload, loadingStatus: "loaded" };
        }
      )
      .addCase(fetchTheme.rejected, (state: ThemeState, action) => {
        state.loadingStatus = "error";
        state.error = action.error.message || null;
      });
  }
});

/*
 * Export reducer for store configuration.
 */
export const themeReducer = themeSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(themeActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const themeActions = themeSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllTheme);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */

export const getThemeState = (rootState: any): ThemeState =>
  rootState[THEME_FEATURE_KEY];
