import { shallowEqual, useSelector } from "react-redux";
import { getDealerDashboardSliceState } from "../dealer-dashboard.slice";
import { useGetDealerTimeZoneQuery } from "../rtk";

export function useGetDealerTimeZone() {
  const { jwt, dealerId } = useSelector(
    getDealerDashboardSliceState,
    shallowEqual
  );

  const {
    currentData: baseUtcOffsetDto,
    isSuccess,
    isFetching,
    isLoading,
    isUninitialized,
    isError
  } = useGetDealerTimeZoneQuery(
    {
      jwt,
      dealerId
    },
    {
      skip: jwt === "" || dealerId === 0
    }
  );

  return {
    baseUtcOffsetDto: baseUtcOffsetDto ?? {
      totalMinutes: 0,
      supportsDaylightSavingTime: false
    },
    isError,
    isSuccess: isSuccess && !isLoading && !isFetching && !isUninitialized
  };
}
