import { useReactOidc } from "@axa-fr/react-oidc-context";
import { getProfileState } from "@vinsolutions/ccrm/store";
import {
  UnifiedInboxModule,
  UnifiedInboxOverlayEvent,
  UnifiedInboxOverlayPosition
} from "@vinsolutions/sub-feature/unified-inbox";
import { useVinconnectFlags } from "@vinsolutions/core/third-party/launch-darkly";
import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled from "styled-components";
import classNames from "classnames";
import { IconClose } from "@vinsolutions/core-cx";
import { trackInNewRelic } from "@vinsolutions/core-third-party-newrelic";
import { environments, getEnvironmentName } from "@vinsolutions/core/config";

export interface UnifiedInboxOverlayState {
  customerId: string;
  initiatorLocation: string;
  overlayPosition: UnifiedInboxOverlayPosition;
  selectedLeadId: string;
  showOverlay: boolean;
}

const StyledUnifiedInboxOverlay = styled.div`
  grid-row: 2/3;
  padding: 16px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 22px 1fr;
  grid-template-areas:
    "header"
    "module";
  z-index: 1;

  &.left-pane {
    grid-column: 1/2;
  }

  &.right-pane {
    grid-column: 2/3;
  }

  #unified-inbox-overlay-header {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h3 {
      font-size: 20px;
      line-height: 22px;
      color: #16171a;
      font-weight: 400;
      margin: 0;
    }
  }

  #unified-inbox-overlay-module-wrapper {
    grid-area: module;
    position: relative;
  }

  #unified-inbox-overlay-header {
    #unified-inbox-overlay-title {
      margin-left: 8px;
    }

    #unified-inbox-overlay-close-button {
      cursor: pointer;
      position: relative;
      height: 23px; // I know it looks weird, but trust the magic
      width: 24px;

      #unified-inbox-overlay-close-icon {
        position: absolute;
        top: 0;
        right: 0px;
      }
    }
  }
`;

const initialState: UnifiedInboxOverlayState = {
  customerId: "",
  initiatorLocation: "",
  overlayPosition: UnifiedInboxOverlayPosition.left,
  selectedLeadId: "",
  showOverlay: false
};

const unifiedInboxSupportedEnvironments = [
  environments.qaCDN,
  environments.qaCDNImpersonation,
  environments.qaCoxAutoDomain,
  environments.qaOnPrem,
  environments.prod,
  environments.prodCoxAutoDomain
];

const unifiedInboxProdEnvironments = [
  environments.prod,
  environments.prodCoxAutoDomain
];

export function UnifiedInboxOverlay() {
  const [
    {
      customerId,
      initiatorLocation, // useful for analytics?
      overlayPosition,
      selectedLeadId,
      showOverlay
    },
    setState
  ] = useState<UnifiedInboxOverlayState>(initialState);

  const { oidcUser } = useReactOidc();
  const { isVinEmployee } = useSelector(getProfileState, shallowEqual);
  const vinconnectFlags = useVinconnectFlags();

  useEffect(() => {
    const handleOverlayEvent = ({
      detail: { action, data }
    }: CustomEvent<UnifiedInboxOverlayEvent>) => {
      if (action === "Open" && data) {
        // validate the data and call the error handler if its invalid
        setState(s => ({
          ...s,
          customerId: data.customerId,
          defaultTab: data.defaultTab,
          overlayPosition: data.overlayPosition,
          selectedLeadId: data.selectedLeadId || initialState.selectedLeadId,
          showOverlay: true
        }));
      } else {
        setState(initialState);
      }
    };

    // If the CRM wants to avoid ts-ignore comments, usage of the `as` keyword, etc, when utilizing custom events,
    // VinsolutionsUI should extend the EventTarget type. Here's a blog that more or less describes the issue/solve
    // https://adropincalm.com/blog/notes-on-typescript-custom-events/
    // I'm working around it in the same way use-history-push-cd.ts and use-dealer-change.ts do
    window.addEventListener(
      "unifiedInboxOverlayEvent",
      handleOverlayEvent as EventListener,
      true
    );

    return () => {
      window.removeEventListener(
        "unifiedInboxOverlayEvent",
        handleOverlayEvent as EventListener,
        true
      );
    };
  }, []);

  const getJwtOverride = useCallback(() => {
    return Promise.resolve(oidcUser.access_token);
  }, [oidcUser.access_token]);

  const errorCallback = useCallback((errorMessage: string) => {
    trackInNewRelic({
      componentAction: "UnifiedInboxInTheCRM",
      locationLoadedFrom: "unified-inbox-overlay",
      errorContext: errorMessage
    });
  }, []);

  const toggleOverlay = useCallback(() => {
    setState(s => ({ ...s, showOverlay: !s.showOverlay }));
  }, []);

  const isUnifiedInboxSupportedEnvironment = useMemo(() => {
    return unifiedInboxSupportedEnvironments.includes(getEnvironmentName());
  }, []);

  const environment = useMemo(
    () =>
      unifiedInboxProdEnvironments.includes(getEnvironmentName())
        ? "prod"
        : "nonprod",
    []
  );

  return vinconnectFlags["nx.vinconnect.show-comms-inbox"] &&
    showOverlay === true ? (
    <StyledUnifiedInboxOverlay
      className={classNames({
        "left-pane": overlayPosition === UnifiedInboxOverlayPosition.left,
        "right-pane": overlayPosition === UnifiedInboxOverlayPosition.right
      })}
      data-testid="unified-inbox-overlay"
    >
      <div
        data-testid="unified-inbox-overlay-header"
        id="unified-inbox-overlay-header"
      >
        <div
          data-testid="unified-inbox-overlay-title"
          id="unified-inbox-overlay-title"
        >
          <h3>Communications</h3>
        </div>
        <div
          data-testid="unified-inbox-overlay-close-button"
          id="unified-inbox-overlay-close-button"
          onClick={toggleOverlay}
        >
          <IconClose
            data-testid="unified-inbox-overlay-close-icon"
            htmlId="unified-inbox-overlay-close-icon"
          />
        </div>
      </div>
      <div
        data-testid="unified-inbox-overlay-module-wrapper"
        id="unified-inbox-overlay-module-wrapper"
      >
        {isUnifiedInboxSupportedEnvironment ? (
          <UnifiedInboxModule
            consumerId={customerId}
            customerSystem={"VIN"}
            disableReply={isVinEmployee}
            environment={environment}
            errorCallback={errorCallback}
            getJwtOverride={getJwtOverride}
            selectedLeadId={selectedLeadId}
          />
        ) : (
          "Unfortunately, this feature is unavailable in the CRM Development and Staging environments."
        )}
      </div>
    </StyledUnifiedInboxOverlay>
  ) : null;
}

export default UnifiedInboxOverlay;
