import { getBaseUrl } from "@vinsolutions/core/environment";
import { fetchWrapper } from "@vinsolutions/core/http";
import { createLogger } from "@vinsolutions/logger";
import { OemInsightsSummaryResponse } from "../models/OemInsightsSummaryResponse";
import { OemInsightsConfig } from "./vin.api.oeminsights";

const logger = createLogger("data-access-vin.api.oeminsights");

export const getOemInsightsSummary = async (
  dealerId: number,
  customerId: number,
  jwt: string
): Promise<OemInsightsSummaryResponse | null | undefined> => {
  const oemInsightsSummaryUrl = getOemInsightsSummaryUrl(customerId, dealerId);
  const response = await fetchWrapper.getAsync<OemInsightsSummaryResponse>(
    oemInsightsSummaryUrl,
    {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/vnd.coxauto.v3+json"
    }
  );

  switch (response.statusCode) {
    case 200:
    case 404:
      return response.data;
    default: {
      logger.error(
        "Error getting OEM Insights summary.",
        JSON.stringify(response)
      );
      return null;
    }
  }
};

export function getOemInsightsSummaryUrl(
  customerId?: number,
  dealerId?: number
) {
  const baseUrl = getBaseUrl(OemInsightsConfig.baseUrls);
  return `${baseUrl}insights/customer/summary?dealerId=${dealerId}&customerId=${customerId}`;
}
