enum AssignedUserType {
  MANAGER,
  BUSINESS_DEVELOPMENT_AGENT,
  SALES_REPRESENTATIVE,
  SERVICE_REPRESENTATIVE,
  CUSTOMER_SERVICE_AGENT,
  PARTS_DEPARTMENT_REPRESENTATIVE,
  SERVICE_DEPARTMENT_REPRESENTATIVE,
  SPLIT_SALES_REPRESENTATIVE
}

export default AssignedUserType;
