import { Button, IconDelete, IconModeEdit } from "cx";
import { StyledDMCTableAction } from "./styles/dmc-table-actions.style";
import { DMCType } from "../../../../types/dmc.type";
import { useDMCContext } from "../../../../hooks/useDmcContext.hook";
import { DMCDetail } from "../../../dmc-detail/dmc-detail.component";
import { DMCSave } from "../../../dmc-save/dmc-save.component";
import { DMCDelete } from "../../../dmc-delete/dmc-delete.component";
import { DMCActionEnum } from "../../../../Enums/dmcAction.enum";

type actionComponentType = {
  EDIT: {
    content: JSX.Element;
    footer: JSX.Element;
  };
  DELETE: {
    content: JSX.Element;
    footer: JSX.Element;
  };
};

export const DMCTableActions = (record: DMCType) => {
  const {
    dispatch,
    modalContext: { setModalContent }
  } = useDMCContext();

  const onHide = () => {
    if (dispatch) {
      dispatch({
        payload: {
          isSaveable: false
        },
        type: DMCActionEnum.DMC_UPDATE
      });
    }

    setModalContent({ show: false });
  };

  const onClickHandle = (action: keyof actionComponentType) => {
    if (dispatch) {
      dispatch({
        payload: {
          dealerManufacturerCode: record,
          isSaveable: true
        },
        type: DMCActionEnum.DMC_UPDATE
      });
    }

    const { content, footer } = actionComponent[action];

    setModalContent({
      content,
      footer,
      onHide,
      show: true
    });
  };

  const actionComponent: actionComponentType = Object.freeze({
    EDIT: {
      content: <DMCDetail />,
      footer: <DMCSave onCancelClickHandler={onHide} />
    },
    DELETE: {
      content: (
        <label>
          Are you sure you wish to delete this manufacturer code{" "}
          <b>{record.oemDealerId}</b>?
        </label>
      ),
      footer: <DMCDelete onCancelClickHandler={onHide} />
    }
  });

  return (
    <StyledDMCTableAction>
      <Button
        aria-label="Edit"
        buttonStyle="primary"
        data-testid={`dealer-manufacturer-codes-edit-button-${record.id}`}
        htmlId={`dealer-manufacturer-codes-edit-button-${record.id}`}
        icon={
          <IconModeEdit
            data-testid={`dealer-manufacturer-codes-icon-edit-${record.id}`}
            htmlId={`dealer-manufacturer-codes-icon-edit-${record.id}`}
          />
        }
        size="sm"
        onClick={() => {
          onClickHandle("EDIT");
        }}
      />

      <Button
        aria-label="Delete"
        buttonStyle="danger"
        data-testid={`dealer-manufacturer-codes-delete-button-${record.id}`}
        htmlId={`dealer-manufacturer-codes-delete-button-${record.id}`}
        icon={
          <IconDelete
            data-testid={`dealer-manufacturer-codes-icon-delete-${record.id}`}
            htmlId={`dealer-manufacturer-codes-icon-delete-${record.id}`}
          />
        }
        size="sm"
        onClick={() => {
          onClickHandle("DELETE");
        }}
      />
    </StyledDMCTableAction>
  );
};
