import { useCallback, useEffect } from "react";
import { SetupStatus } from "@vinsolutions/ccrm/interfaces";
import { getDealerInfoById, setSelectedDealer } from "@vinsolutions/ccrm/api";
import { getProfileState, profileActions } from "@vinsolutions/ccrm/store";
import { bustCacheSelectedDealer } from "@vinsolutions/tenant/api";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useReactOidc } from "@axa-fr/react-oidc-context";

export interface DealerChangeEvent {
  dealerId: number;
  source: string;
  originIframe: HTMLIFrameElement;
}

interface DealerChangeResponse {
  dealerId: number;
  source: string;
}

function dispatchResponseSetDealer(
  dealerId: number,
  source: string,
  originIframe: HTMLIFrameElement,
  response: "success" | "fail"
) {
  const ev = new CustomEvent<DealerChangeResponse>(
    `dealer:change:${response}`,
    {
      detail: {
        dealerId,
        source
      }
    }
  );
  if (originIframe?.tagName === "IFRAME") {
    originIframe.contentDocument?.body.dispatchEvent(ev);
  } else {
    console.warn(
      `the origin element with id ${originIframe?.id} send in dealer:change event from ${source} doesn't exist.`
    );
  }
}

export const useDealerChange = () => {
  const dispatch = useDispatch();
  const { oidcUser } = useReactOidc();
  const { dealerId: currentDealerId } = useSelector(
    getProfileState,
    shallowEqual
  );

  const dealerChangeHandler = useCallback(
    async (
      dealerId: number,
      dealerName: string,
      dealerStatus: SetupStatus | "",
      source?: string
    ) => {
      const res = await setSelectedDealer(dealerId, source);
      if (res.statusCode === 200 || res.data?.dealerID === dealerId) {
        dispatch(
          profileActions.update({
            dealerId,
            dealerName,
            dealerSetupStatus: dealerStatus
          })
        );
        bustCacheSelectedDealer(oidcUser.access_token);
      } else {
        dispatch(
          profileActions.update({
            error: "stuff broke",
            profileLoadingStatus: "error"
          })
        );
      }
      return res;
    },
    []
  );

  const dealerChangeEventHandler = useCallback(
    async (ev: CustomEvent<DealerChangeEvent>) => {
      const { dealerId, source, originIframe } = ev.detail;
      if (Boolean(dealerId) && dealerId !== currentDealerId) {
        if (!source) {
          console.warn(
            "If your changing the dealer please send source parameter."
          );
        }
        const res = await getDealerInfoById(oidcUser.access_token, dealerId);
        if (res.data && !res.hasError) {
          const { setupStatus, dealerName } = res.data;
          const setDealerRes = await dealerChangeHandler(
            dealerId,
            dealerName,
            setupStatus as SetupStatus,
            source
          );
          const evRes = setDealerRes.statusCode === 200 ? "success" : "fail";
          dispatchResponseSetDealer(dealerId, source, originIframe, evRes);
        } else {
          dispatchResponseSetDealer(dealerId, source, originIframe, "fail");
        }
      } else {
        dispatchResponseSetDealer(dealerId, source, originIframe, "success");
      }
    },
    []
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.addEventListener("dealer:change", dealerChangeEventHandler);
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.removeEventListener("dealer:change", dealerChangeEventHandler);
    };
  }, []);

  return {
    dealerChangeHandler
  };
};
