import styled from "styled-components";
import FordInsightsSummaryData from "../../interfaces/ford/ford-insights-summary-data";

export interface FordInsightsSummaryProps {
  fordInsights: FordInsightsSummaryData;
}

const StyledFordInsightsSummary = styled.span`
  float: left;
  font-weight: 500;
`;

export const FordInsightsSummary = ({
  fordInsights: { iciOfferCount, rmtOfferCount }
}: FordInsightsSummaryProps) => {
  return (
    <StyledFordInsightsSummary>
      ICI Offers: {iciOfferCount}, RMT Offers: {rmtOfferCount}
    </StyledFordInsightsSummary>
  );
};

export default FordInsightsSummary;
