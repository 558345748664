import { SubmitButton, toast } from "cx";
import { useDMCContext } from "../../hooks/useDmcContext.hook";
import { saveDealerManufacturerCode } from "../../services/dealerManufacturerCodes.service";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { DMCType } from "../../types/dmc.type";
import { useState } from "react";
import { DMCSaveParams } from "../../types/dmcParams.type";
import { DMCSaveData } from "../../types/dmcSaveData.type";
import { DMCActionEnum } from "../../Enums/dmcAction.enum";

export const DMCSave = ({ onCancelClickHandler }: DMCSaveParams) => {
  const {
    state: { dealerManufacturerCode, dealerManufacturerCodes, isSaveable },
    dispatch
  } = useDMCContext();
  const {
    oidcUser: { access_token: accessToken }
  } = useReactOidc();
  const [loading, setLoading] = useState(false);

  const showToastMsg = (hasError: boolean, message: string) => {
    if (hasError) {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  const onSaveClickHandler = async () => {
    setLoading(true);

    const dmcModel: DMCSaveData = {
      id: dealerManufacturerCode.id,
      dealerId: dealerManufacturerCode.dealerId,
      enableLeadTypeSwitching: false,
      enableNonOemLead: dealerManufacturerCode.dealerInitiatedLeadEnabled,
      enableOemLeadUpdate: dealerManufacturerCode.oemLeadUpdateEnabled,
      manufacturerSource: dealerManufacturerCode.oemName,
      oemDealerId: dealerManufacturerCode.oemDealerId,
      oemTypeId: dealerManufacturerCode.oemId,
      status: dealerManufacturerCode.status
    };

    const {
      data: { id },
      hasError,
      message
    } = await saveDealerManufacturerCode(accessToken, dmcModel);

    if (!hasError) {
      if (dispatch) {
        dispatch({
          payload: {
            dealerManufacturerCodes,
            dealerManufacturerCode: {
              ...dealerManufacturerCode,
              id,
              key: id
            } as DMCType
          },
          type: DMCActionEnum.UPDATE_DMC_COLLECTION
        });
      }
      onCancelClickHandler();
    }

    setLoading(false);
    showToastMsg(hasError, message);
  };

  return (
    <SubmitButton
      data-testid="dealer-manufacturer-codes-button-save"
      disabled={!isSaveable || loading}
      htmlId="dealer-manufacturer-codes-button-save"
      isLoading={loading}
      loadingText="Saving"
      onClick={onSaveClickHandler}
    >
      Save
    </SubmitButton>
  );
};
