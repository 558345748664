// eslint-disable-next-line @nx/enforce-module-boundaries
import { Button } from "cx";
import { FunctionComponent } from "react";
import { useSlideDrawer } from "../SliderDrawer/SliderDrawerIframe";

export interface VehicleInterestProps {
  autoLeadId: number;
}

export const VehicleInterest: FunctionComponent = () => {
  const { hideDrawer, dispatchCloseEv, componentProps } = useSlideDrawer();
  const { autoLeadId } = componentProps as VehicleInterestProps;

  const closedDrawer = () => {
    const detailData = {
      user: "jamie"
    };
    dispatchCloseEv(detailData);
    hideDrawer();
  };

  return (
    <div>
      VehicleInterest: {autoLeadId}
      <Button htmlId="vehicle-test" onClick={closedDrawer}>
        closed drawer
      </Button>
    </div>
  );
};
