import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CardashboardLazy } from "../cardashboard";
import { useGetPane } from "@vinsolutions/utility/hooks";
import styled from "styled-components";
import { StyledSidePane } from "./styled-side-panel";
import { createLogger } from "@vinsolutions/logger";
import { useRouteToDefaultDashboard } from "./use-route-to-default-dashboard";

const logger = createLogger("CardashboardLayout");

const CDStyled = styled.div`
  grid-column: 1 / -1;
  grid-row: 1;
`;

const TwoPaneGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  height: 100%;
  column-gap: 4px;
  position: relative;
`;

const OnePaneGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  height: 100%;
`;

export function CardashboardLayout({ children }: { children?: ReactNode }) {
  logger.debug("CardashboardLayout: Initializing CardashboardLayout.");
  const [showCd, setShowCd] = useState<boolean>(true);
  const { pane } = useGetPane();
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // show carDashboard whenever path app is / or /#/....
    if (hash || pathname === "/") {
      logger.debug("CardashboardLayout: setShowCd=true.");
      setShowCd(true);
    } else {
      logger.debug("CardashboardLayout: setShowCd=false.");
      setShowCd(false);
    }
  }, [pathname, hash]);

  useRouteToDefaultDashboard();

  let panes;
  switch (pane) {
    /*
    In the case of left and right they might not go through the react-router matching because a URL that doesn't match
    a route was pushed to history.  An example is the use useHistoryPushCd hook.  It push a URL that doesn't match
    a react-router route.  But, it still comes through here.
     */
    case "left":
    case "right": {
      logger.debug("CardashboardLayout: pane=left or right.");
      panes = (
        <>
          <StyledSidePane data-testid="side-pane" left={pane === "left"}>
            {children}
          </StyledSidePane>
          {showCd ? (
            <CDStyled data-testid="cd-container">
              <CardashboardLazy />
            </CDStyled>
          ) : null}
        </>
      );
      break;
    }
    case "cd":
      logger.debug("CardashboardLayout: pane=cd.");
      panes = showCd ? (
        <CDStyled data-testid="cd-container">
          <CardashboardLazy />
        </CDStyled>
      ) : null;
      break;
    /*
      For the "both" and "full" case the component associated with the route provide the children.
      The children should be wrapped by the StyledSidePane component
      */
    case "full": {
      logger.debug("CardashboardLayout: pane=full.");
      if (showCd) {
        logger.debug("CardashboardLayout: showCd=true.");
        return (
          <CDStyled data-testid="cd-container">
            <CardashboardLazy />
          </CDStyled>
        );
      } else {
        logger.debug("CardashboardLayout: showCd=false.");
        return (
          <OnePaneGrid data-testid="one-pane-grid">{children}</OnePaneGrid>
        );
      }
    }
    case "both": {
      logger.debug("CardashboardLayout: pane=both.");
      panes = children;
      break;
    }
    default:
      logger.debug("CardashboardLayout: pane=default.");
      panes = null;
  }
  logger.debug("CardashboardLayout: Rendering CardashboardLayout.");
  return <TwoPaneGrid>{panes}</TwoPaneGrid>;
}
