export const ExampleDashboard = () => {
  const divList = Array(100).fill(1);
  return (
    <div>
      <div>MockDashboard</div>
      <div>
        {divList.map((_, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <h1 key={idx}>Row</h1>
        ))}
      </div>
    </div>
  );
};
