import { MouseEvent } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getMainNavigationState,
  mainNavigationActions
} from "@vinsolutions/ccrm/store";
import {
  cardashboardActions,
  getCardashboardState
} from "@vinsolutions/legacy-cardashboard/store";
import { Button } from "cx";
import { deriveCardashboardBaseUrl } from "@vinsolutions/ccrm/util";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { useRouteManager } from "../router/use-route-manager";
import { useRenderRouteByAlias } from "../router/use-render-route-by-alias";
import { vinconnectHashPrefix } from "@vinsolutions/ccrm/util";
import { useNavigate } from "react-router-dom";

/* eslint-disable-next-line */
export interface NavigationTabButtonProps {
  id: string;
  label: string;
  url: string;
  target: string;
}

export function NavigationTabButton({
  id,
  label,
  url,
  target
}: NavigationTabButtonProps) {
  const renderRoute = useRenderRouteByAlias();
  const routeManager = useRouteManager();
  const navigate = useNavigate();
  const { frameBaseUrl } = useSelector(getCardashboardState, shallowEqual);

  const dispatch = useDispatch();
  const { activeTabId } = useSelector(getMainNavigationState);
  const { frameUrl } = useSelector(getCardashboardState);
  const hasValidLink = url && url !== "#";
  const fullHttpUrl =
    hasValidLink && url?.startsWith("http")
      ? url
      : deriveCardashboardBaseUrl() + url;

  const onClick = (e: MouseEvent<HTMLElement>) => {
    TagManager.event({
      eventElement: `TopNav: Global Navigation`,
      eventAction: `${label} clicked`,
      eventValue: `${id}`
    });

    if (e.button === 0 && e.ctrlKey === false) {
      e.preventDefault();

      if (hasValidLink) {
        if (target && target.toLowerCase() === "_blank") {
          window.open(fullHttpUrl, target);
        } else {
          if (routeManager?.isActiveRouteByAlias(id)) {
            renderRoute(id, false, undefined);
          } else {
            const framePath = url?.replace(frameBaseUrl, "");
            navigate(`${vinconnectHashPrefix}${framePath}`, { replace: false });
            dispatch(
              cardashboardActions.updateFrameUrl({
                name: label,
                frameUrl: url
              })
            );
          }
        }
      }
    }

    dispatch(mainNavigationActions.setActiveTab(id));
  };
  return (
    <Button
      buttonStyle={activeTabId === id ? "primary" : "link"}
      htmlId={id}
      {...{ onClick }}
    >
      {!routeManager?.isActiveRouteByAlias(id) ? (
        <a href={hasValidLink ? fullHttpUrl : frameUrl} target={target}>
          {label}
        </a>
      ) : (
        label
      )}
    </Button>
  );
}
