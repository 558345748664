import { memo } from "react";
import { Button } from "@vinsolutions/core/cx";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  TenantBridgeBarProps,
  shouldShowBridgeBar
} from "@vinsolutions/tenant/bridge-bar";
import { OidcUser } from "@vinsolutions/tenant/jwt-utils";
import { getEnvironmentName } from "@vinsolutions/core/config";
import styled from "styled-components";
import { useCardashboardChannel } from "@vinsolutions/legacy-cardashboard/hooks";
import { BroadcastChannelNames } from "@vinsolutions/ccrm/constants";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("LogoutButton");

const StyleButton = styled(Button)`
  margin: 0;
  padding: 0;

  > a {
    display: block;
  }
`;

export function LogoutButton() {
  logger.debug("LogoutButton: entering.");
  const { oidcUser } = useReactOidc();
  logger.debug("LogoutButton: oidcUser", oidcUser);
  const postMessageLogout = useCardashboardChannel<{ tabContext: string }>(
    BroadcastChannelNames.topNavLogoutOtherTabs,
    BroadcastChannelNames.topNavLogoutOtherTabsContext
  );

  // always going to rerender unless the consumer uses the memoized component
  const showLogout = !shouldShowBridgeBar({
    environment: getEnvironmentName(),
    odicUser: oidcUser as unknown as OidcUser,
    solutionGroupCode: "VIN"
  } as TenantBridgeBarProps);

  const handlerLogout = (ev: any) => {
    logger.debug("LogoutButton.handlerLogout");
    ev.preventDefault();
    logger.debug(
      "LogoutButton.handlerLogout: postMessageLogout",
      postMessageLogout
    );
    postMessageLogout({ tabContext: window.tabContextId as string });
    logger.debug(
      "LogoutButton.handlerLogout: window.location.origin",
      window.location.origin
    );
    window.location.href = `${window.location.origin}/cardashboard/logout.aspx`;
  };

  // the fragment here ensures we always return a ReactElement instead of 'false' so the memoization can work properly
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {showLogout && (
        <StyleButton
          buttonStyle="link"
          className="account-menu-sign-out"
          htmlId="sign-out"
        >
          <a onClick={handlerLogout}>Sign Out</a>
        </StyleButton>
      )}
    </>
  );
}

export default memo(LogoutButton);
// export default memo(LogoutButton, false); // for debugging memoized components
