import {
  KeyInformationBanner,
  KeyInformationTextIcon
} from "@vinsolutions/ui/key-information-banner";
import OemInsightsBannerProps from "../../interfaces/oem-insights-banner-interface-props";
import GenesisIconSmall, { GenesisIconFill } from "./genesis-icon";
import GenesisInsightsSummary from "./hyundai-genesis-insights-summary";
import { parseHyundaiGenesisInsights } from "../../data-parse/hyundai-genesis/hyundai-genesis-summary-data";
import { useEffect, useMemo, useState } from "react";
import OemInsightsDate from "../oem-banner-date";

const bannerName = "Genesis Insights";
export const GenesisBaseDisplayedBanner = {
  bannerName,
  iconName: "GenesisIconSmall",
  thumbnailName: "GenesisIconSmall",
  thumbnailIcon: (
    <GenesisIconSmall
      fillColor={GenesisIconFill.None}
      key={"genesis-thumbnail-icon"}
    />
  )
};

export function GenesisInsightsBanner({
  oemInsightsSummary,
  onLoad,
  onClick
}: OemInsightsBannerProps) {
  const [iconColor, setIconColor] = useState(GenesisIconFill.None);
  const genesisInsights = useMemo(
    () => parseHyundaiGenesisInsights(oemInsightsSummary, "genesis"),
    [oemInsightsSummary]
  );
  useEffect(() => {
    onLoad({
      ...GenesisBaseDisplayedBanner,
      isDisplayed: !!genesisInsights
    });
  }, [onLoad, genesisInsights]);
  return (
    genesisInsights && (
      <KeyInformationBanner
        bannerDataDetail={<OemInsightsDate oemInsights={genesisInsights} />}
        bannerIcon={
          <KeyInformationTextIcon
            icon={<GenesisIconSmall fillColor={iconColor} />}
            iconText="Genesis Intelligence"
          />
        }
        bannerName={bannerName}
        bannerSummary={
          <GenesisInsightsSummary hyundaiGenesisInsights={genesisInsights} />
        }
        onClick={() => onClick && onClick(bannerName, "genesis")}
        onMouseEnter={() => setIconColor(GenesisIconFill.Inverse)}
        onMouseLeave={() => setIconColor(GenesisIconFill.None)}
      />
    )
  );
}

export default GenesisInsightsBanner;
