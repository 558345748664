import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProfileData } from "@vinsolutions/ccrm/api";
import {
  ProfileEntity,
  ProfileState,
  SetupStatus
} from "@vinsolutions/ccrm/interfaces";

export const PROFILE_FEATURE_KEY = "profile";
/*
 * Update these interfaces according to your requirements.
 */

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchProfile())
 * }, [dispatch]);
 * ```
 */
export const fetchProfile = createAsyncThunk(
  "profile/fetchStatus",
  async (jwt: string) => {
    return await getProfileData(jwt);
  }
);

export const initialProfileState: ProfileState = {
  dealerId: null,
  dealerName: "",
  dealerSetupStatus: SetupStatus.Complete,
  error: null,
  hasViewingAsFeature: false,
  isVinEmployee: false,
  profileLoadingStatus: "not loaded",
  userHasAccessToMultipleDealersLoadingStatus: "not loaded",
  userFullName: "",
  userHasAccessToMoreThanOneDealer: false,
  userId: null,
  userName: "",
  userRole: "",
  viewingAsUsername: ""
};

export const profileSlice = createSlice({
  name: PROFILE_FEATURE_KEY,
  initialState: initialProfileState,
  reducers: {
    loaded: state => {
      return { ...state, profileLoadingStatus: "loaded" };
    },
    update: (state, action) => {
      return { ...state, ...action.payload };
    },
    pending: state => {
      return { ...state, profileLoadingStatus: "loading" };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProfile.pending, (state: ProfileState) => {
        state.profileLoadingStatus = "loading";
      })
      .addCase(
        fetchProfile.fulfilled,
        (
          state: ProfileState,
          action: PayloadAction<Partial<ProfileEntity>>
        ) => {
          return {
            ...state,
            ...action.payload,
            profileLoadingStatus: "loaded",
            error: null
          };
        }
      )
      .addCase(fetchProfile.rejected, (state: ProfileState, action) => {
        state.profileLoadingStatus = "error";
        state.error = action.error.message || null;
      });
  }
});

/*
 * Export reducer for store configuration.
 */
export const profileReducer = profileSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(profileActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const profileActions = profileSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllProfile);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */

export const getProfileState = (rootState: any): ProfileState =>
  rootState[PROFILE_FEATURE_KEY];
