// import { getApi } from "@vinsolutions/api/util";
import { fetchWrapper } from "@vinsolutions/core/http";
import { getClaim } from "@vinsolutions/tenant/jwt-utils";
import { envEnum, getBaseUrl } from "@vinsolutions/core/environment";

export interface UserBridgeStateResponse {
  BridgeStatus: null | number;
  BridgeUsername: null | string;
  PlatformId: null | string;
  GivenName: null | string;
  FamilyName: null | string;
  IsLocked: null | boolean;
}

export const userServiceApiConfigOld = {
  baseUrls: {
    local: "https://user.dev.vinstickers.com/",
    dev: "https://user.dev.vinstickers.com/",
    qa: "https://qa-user.vinsolutions.com/",
    staging: "https://staging-user.vinsolutions.com/",
    prod: "https://user.vinsolutions.com"
  },
  headers: {
    Accept: "application/json",
    "content-type": "application/json"
  }
};

export const userServiceApiConfig = {
  baseUrls: {
    [envEnum.LOCAL]: "https://user.dev.vinstickers.com/",
    [envEnum.DEV]: "https://user.dev.vinstickers.com/",
    [envEnum.QA]: "https://qa-user.vinsolutions.com/",
    [envEnum.STAGING]: "https://staging-user.vinsolutions.com/",
    [envEnum.PRODUCTION]: "https://user.vinsolutions.com/"
  },
  headers: {
    Accept: "application/json",
    "content-type": "application/json"
  }
};

export async function getUserBridgeStateById(jwt: string) {
  const userId = getClaim(jwt, "surrogate_id");
  const response = await fetchWrapper.getAsync<UserBridgeStateResponse>(
    `${getBaseUrl(
      userServiceApiConfig.baseUrls
    )}userbridgestate/id/user/${userId}`,
    { ...userServiceApiConfig.headers, Authorization: `Bearer ${jwt}` }
  );
  if (response.data && !response.hasError) {
    const data = response.data;
    const parseData = {
      bridgeStatus: data.BridgeStatus,
      bridgeUsername: data.BridgeUsername,
      familyName: data.FamilyName,
      givenName: data.GivenName,
      isLocked: data.IsLocked,
      platformId: data.PlatformId
    };
    return Promise.resolve(parseData);
  } else {
    return Promise.reject(response.error);
  }
}

// no return and no await because it is handled as a fire and forget
// https://rally1.rallydev.com/#/?detail=/userstory/629887851217&fdp=true
export function bustCacheSelectedDealer(jwt: string) {
  const userId = getClaim(jwt, "surrogate_id");
  fetchWrapper.getAsync(
    `${getBaseUrl(
      userServiceApiConfig.baseUrls
    )}userpreference/bust/id/key/MainSelectedDealer/user/id/${userId}`,
    { ...userServiceApiConfig.headers, Authorization: `Bearer ${jwt}` }
  );
}

interface ItemsVsSecurityFunctions {
  user: string;
  status: number;
  vsSecurityFunction: string;
  functionCode: string;
  functionName: string;
  superRole: boolean;
}

interface VsSecurityFunctionsResponse {
  items: Array<ItemsVsSecurityFunctions>;
  count: number;
}

export async function GetVSSecurityFunctions(jwt: string) {
  const userId = getClaim(jwt, "surrogate_id");

  const response = await fetchWrapper.getAsync<VsSecurityFunctionsResponse>(
    `${getBaseUrl(
      userServiceApiConfig.baseUrls
    )}vssecurityfunctions/users?user=${userId}&status=ACTIVE&(functioncode=reloginuser|functioncode=null)`,
    { ...userServiceApiConfig.headers, Authorization: `Bearer ${jwt}` }
  );

  return response;
}
