// Main repo: https://github.com/IdentityModel/oidc-client-js/wiki
// NPM used: https://github.com/AxaGuilDEv/react-oidc/tree/master/packages/context#readme.md
// Example: https://github.com/AxaGuilDEv/react-oidc/blob/master/examples/context/src/configuration.js

import React, { useEffect, useReducer } from "react";
// https://github.com/AxaGuilDEv/react-oidc/tree/master/packages/context#readme.md
import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  AuthInitialState,
  ReducerActionType,
  authReducer
} from "./reducers/authReducer";
import { getCarDashboardLoginStatus } from "@vinsolutions/ccrm/api";
// import { getCarDashboardLoginStatus, getStatus } from "@vinsolutions/ccrm/api";
import { AuthContextElement } from "./contexts/AuthContext";
import { getClaims } from "@vinsolutions/tenant/jwt-utils";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("InnerAuth");

export interface InnerAuthProps {
  children: JSX.Element;
  useCarDashboard: boolean;
  carDashboardLoginUrl: string;
  carDashboardLogoutUrl: string;
}

const InnerAuth = ({
  children,
  useCarDashboard,
  carDashboardLoginUrl,
  carDashboardLogoutUrl
}: InnerAuthProps) => {
  logger.debug("InnerAuth: Initializing InnerAuth.");
  const [state, dispatch] = useReducer(authReducer, AuthInitialState);
  const {
    isCdInit,
    logUserOut,
    isCdHealthy,
    cdLogoutIsComplete,
    initialCdHealthCheckComplete
  } = state;
  const { oidcUser, logout, login } = useReactOidc();

  useEffect(() => {
    logger.debug("InnerAuth: Initializing InnerAuth useEffect.");
    initializeAsync();

    if (cdLogoutIsComplete && logUserOut) {
      logger.debug("InnerAuth: CD Logout is complete. Logging user out.");
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser, isCdInit, logUserOut, isCdHealthy, cdLogoutIsComplete, logout]);

  const initializeAsync = async () => {
    logger.debug("InnerAuth: Initializing InnerAuth async.");
    if (useCarDashboard) {
      logger.debug("InnerAuth: Using CarDashboard.");
      await handleCarDashboardAuthentication();
    }
  };

  const redirectCountKey = "InnerAuthRedirectCount";
  const getRedirectCount = () => {
    logger.debug("InnerAuth: Getting redirect count.");
    const currentCount = localStorage.getItem(redirectCountKey);
    if (!currentCount) {
      logger.debug("InnerAuth: Current redirect count is: 0");
      return 0;
    }
    const currentCountInt = parseInt(currentCount, 10);
    logger.debug("InnerAuth: Current redirect count is: " + currentCountInt);
    if (currentCountInt) return currentCountInt;
    logger.debug("InnerAuth: Current redirect count is: 0");
    return 0;
  };
  const incrementRedirectCount = () => {
    logger.debug("InnerAuth: Incrementing redirect count.");
    const currentCount = getRedirectCount();
    const count = localStorage.setItem(
      redirectCountKey,
      (currentCount + 1).toString()
    );

    logger.debug("InnerAuth: Redirect count is now: " + count);

    return count;
  };
  const resetRedirectCount = () => {
    logger.debug("InnerAuth: Resetting redirect count to 0.");
    return localStorage.setItem(redirectCountKey, "0");
  };

  const handleCarDashboardAuthentication = async () => {
    logger.debug("InnerAuth: Handling CarDashboard Authentication.");
    if (!initialCdHealthCheckComplete && oidcUser) {
      logger.debug("InnerAuth: Initial CD Health Check is not complete.");
      const userIsLoggedIntoCarDashboard = await getCarDashboardLoginStatus();
      if (!userIsLoggedIntoCarDashboard && getRedirectCount() < 3) {
        incrementRedirectCount();
        logger.debug("InnerAuth: User is not logged into CarDashboard.");
        initializeCarDashboard(true);
      } else {
        logger.debug("InnerAuth: User is logged into CarDashboard.");
        resetRedirectCount();
        logger.debug("InnerAuth: Setting CD Init Status.");
        dispatch({
          type: ReducerActionType.SET_CD_INIT_STATUS,
          payload: {
            healthStatus: true, // carDashboardStatus.loginAllowed,
            initialCdHealthCheckComplete: true
          }
        });
      }
      // }
    }
  };

  const initializeCarDashboard = (cdHealthCheckResponse: boolean) => {
    logger.debug("InnerAuth: Initializing CarDashboard.");
    if (!isCdInit) {
      logger.debug("InnerAuth: CD is not initialized.");
      // Are we getting redirected back from CD letting us know CD is initialized?
      const urlParams = new URLSearchParams(window.location.search);
      const cdIsInitQueryParam = urlParams.get("cdInitialized");

      // Did we get redirected back from CarDashboard?
      // If we did then it's initialized and were done and can let the user navigate as normal.
      // Otherwise, if CD is healthy, we need to redirect them to CD to allow for iFraming in CD.
      if (cdIsInitQueryParam === "true") {
        logger.debug("InnerAuth: CD is initialized.");
        dispatch({
          type: ReducerActionType.CD_IS_INIT
        });
      } else if (cdHealthCheckResponse) {
        logger.debug("InnerAuth: CD is healthy.");
        // We need to initialize CarDashboard. We need the TopNav=true
        // So CD knows to redirect back to TopNav.
        window.location.href = carDashboardLoginUrl;
      }
    }

    dispatch({
      type: ReducerActionType.SET_CD_HEALTH_STATUS,
      payload: { healthStatus: cdHealthCheckResponse }
    });
  };

  const AuthContextObject = {
    isCdInit,
    user: {
      ...oidcUser,
      claims:
        oidcUser && oidcUser.access_token
          ? getClaims(oidcUser.access_token)
          : {}
    },
    logout: () =>
      dispatch({
        type: ReducerActionType.SSO_LOGOUT,
        payload: { useCarDashboard }
      }),
    login,
    forceCdRefresh: () =>
      dispatch({
        type: ReducerActionType.SET_CD_INIT_STATUS,
        payload: { initialCdHealthCheckComplete: true, healthStatus: true }
      })
  };

  return (
    <AuthContextElement value={AuthContextObject}>
      {children}
    </AuthContextElement>
  );
};

export default InnerAuth;
