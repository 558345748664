import styled from "styled-components";

export interface HyundaiGenesisConciergeUpdatesProps {
  conciergeMessagesCount: number;
}

const StyledHyundaiGenesisConciergeUpdatesCount = styled.span`
  margin-left: 5px;
  font-weight: 700;
`;

export const HyundaiGenesisConciergeUpdates = ({
  conciergeMessagesCount
}: HyundaiGenesisConciergeUpdatesProps) => {
  return (
    <span>
      Concierge Updates
      <StyledHyundaiGenesisConciergeUpdatesCount>
        {conciergeMessagesCount}
      </StyledHyundaiGenesisConciergeUpdatesCount>
    </span>
  );
};

export default HyundaiGenesisConciergeUpdates;
