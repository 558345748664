import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DMCDetailEnableNonOemLeadCollectionParams } from "../../../../types/dmcParams.type";
import { DMCCheckbox } from "../../../dmc-checkbox/dmc-checkbox.component";

export const DMCDetailEnableNonOemLeadCollection = ({
  enableNonOemLeadCollection,
  handleInputChange,
  name
}: DMCDetailEnableNonOemLeadCollectionParams) => {
  const onChange = (event: CheckboxChangeEvent) => {
    const { checked } = event.target;

    handleInputChange({ target: { value: checked, name } });
  };

  return (
    <DMCCheckbox
      data-testid="DMCEnableNonOemLeadCollection"
      id="DMCEnableNonOemLeadCollection"
      label={" Enable Non OEM Lead Collection"}
      note={
        "By checking this checkbox, the system will sent this dealership's leads to the manufacturer that did not originate from the manufacturer."
      }
      value={enableNonOemLeadCollection}
      {...{ name, onChange }}
    />
  );
};
