import { runCarDashboardScriptProps } from "../about-connect.types";
import { LDClient } from "launchdarkly-js-client-sdk";

export function getVinconnectServerName(
  runCarDashboardScript: (props: runCarDashboardScriptProps) => string | void
) {
  const _carDashboardTitleUnparsed = runCarDashboardScript({
    functionName: "document.title"
  });
  if (!_carDashboardTitleUnparsed) return;
  const _carDashboardTitleRegex = _carDashboardTitleUnparsed.match(/\[(.*?)\]/);
  if (!_carDashboardTitleRegex) return;
  return _carDashboardTitleRegex[1];
}

export type browserInformation = {
  browserName: string;
  fullVersion: string;
  majorVersion: number;
  appName: string;
  userAgent: string;
};

// https://gist.github.com/umit/e5c13bd17e76d308c40e60a349cd8861#file-js-L75
export function getBrowserInformation() {
  const nAgt = navigator.userAgent;
  let browserName = navigator.appName;
  let fullVersion = "" + parseFloat(navigator.appVersion);
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset, verOffset, ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  return {
    browserName,
    fullVersion,
    majorVersion,
    appName: navigator.appName,
    userAgent: navigator.userAgent
  } as browserInformation;
}

export interface PerformanceWithMemory extends Performance {
  memory: undefined | WindowPerformanceMemory;
}
export interface WindowPerformanceMemory {
  /** The maximum size of the heap, in bytes, that is available to the context. */
  jsHeapSizeLimit: number;

  /** The total allocated heap size, in bytes. */
  totalJSHeapSize: number;

  /** The currently active segment of JS heap, in bytes. */
  usedJSHeapSize: number;
}

export function getMemoryInformation(_results: AboutConnectResultListItem[]) {
  // memory api its not a standard https://developer.mozilla.org/en-US/docs/Web/API/Performance/memory
  // so some browsers doesn't have it, like firefox and safari https://caniuse.com/?search=memory
  // that its why we use optional chaining
  const performance = window.performance as PerformanceWithMemory;
  if (performance.memory) {
    _results.push({
      title: "Memory: Limit",
      value: performance.memory.jsHeapSizeLimit?.toLocaleString("en-US"),
      hideOnModal: true
    });
    _results.push({
      title: "Memory: Total",
      value: performance.memory.totalJSHeapSize?.toLocaleString("en-US"),
      hideOnModal: true
    });
    _results.push({
      title: "Memory: Used",
      value: performance.memory.usedJSHeapSize?.toLocaleString("en-US"),
      hideOnModal: true
    });
  }
}

export function getLDInformation(
  ldClient: LDClient | undefined,
  _results: AboutConnectResultListItem[]
) {
  if (!ldClient) return;
  const flags = ldClient.allFlags();
  Object.keys(flags).forEach(key => {
    _results.push({
      title: `Toggle: ${key}`,
      value: flags[key].toString(),
      hideOnModal: true
    });
  });
}

export function getAppVersion(
  _results: AboutConnectResultListItem[],
  currentAppVersion: string | null
) {
  if (currentAppVersion)
    _results.push({
      title: "System Version",
      value: currentAppVersion
    });
}

export function getBrowserInfo(_results: AboutConnectResultListItem[]) {
  const browserInfo = getBrowserInformation();
  if (browserInfo) {
    _results.push({ title: "Browser", value: browserInfo.browserName });
    _results.push({
      title: "Browser Version",
      value: browserInfo.fullVersion
    });
  }
}

export function getServerName(
  _results: AboutConnectResultListItem[],
  cardashboardServerName: string | undefined
) {
  const _carDashboardTitle = cardashboardServerName;
  if (_carDashboardTitle)
    _results.push({ title: "Server", value: _carDashboardTitle });
}

export interface AboutConnectResultListItem {
  title: string;
  value: string | number;
  isoDate?: string;
  hideOnModal?: boolean;
}
