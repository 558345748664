import {
  DynamicHeaderMenuItem,
  DynamicMenu,
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";
import { addItemToItemEntities } from "./add-item-to-item-entities";
import { addItemToEntities } from "./add-item-to-entities";
import { dynamicMenuToNavEntity } from "./dynamic-menu-to-nav-entity";

/**
 * This function will add dynamic menu items to the CarDashboard navigation data structures
 * @param {DynamicMenu}             dynamicMenu
 * @param {NavigationEntity[]}      navigationEntities
 * @param {NavigationItemsEntity[]} navigationItemEntities
 */
export const addDynamicMenuItems = (
  dynamicMenu: DynamicMenu | undefined | null,
  navigationEntities: NavigationEntity[] | undefined | null,
  navigationItemEntities: NavigationItemsEntity[] | undefined | null
) => {
  if (
    !dynamicMenu?.dynamicMenuItems ||
    !navigationEntities ||
    !navigationItemEntities
  ) {
    return;
  }
  // convert dynamic header menu items to Navigation Entity objects
  const dynamicMenuEntities = dynamicMenuToNavEntity(
    dynamicMenu.dynamicMenuItems
  );

  // add dynamicMenuItems to Navigation Item Entities
  dynamicMenu.dynamicMenuItems?.forEach((menuItem: DynamicHeaderMenuItem) => {
    const foundItem = navigationEntities.find(n => n.id === menuItem.id);
    if (foundItem) {
      addItemToItemEntities(menuItem, navigationItemEntities);
    }
  });
  // add dynamicMenuItems to Navigation Entities
  dynamicMenuEntities.forEach(e => {
    // first check to see if the dynamic menu item is in the root list
    const foundItem = navigationEntities.find(n => n.id === e.id);
    if (foundItem) {
      addItemToEntities(e, navigationEntities);
    }
  });
};
