export default function KbbIconLarge() {
  return (
    <svg
      height="47px"
      version="1.1"
      viewBox="0 0 108 47"
      width="108px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Kelley Blue Book Instant Cash Offer</title>
      <defs>
        <radialGradient
          cx="2.42402165%"
          cy="-42.3219792%"
          fx="2.42402165%"
          fy="-42.3219792%"
          gradientTransform="translate(0.024240,-0.423220),scale(1.000000,0.622616),translate(-0.024240,0.423220)"
          id="radialGradient-1"
          r="110.834027%"
        >
          <stop offset="0%" stopColor="#346AB2" />
          <stop offset="51.24%" stopColor="#346AB2" />
          <stop offset="94.32%" stopColor="#213E76" />
        </radialGradient>
        <radialGradient
          cx="9.22117084%"
          cy="1.15111139%"
          fx="9.22117084%"
          fy="1.15111139%"
          gradientTransform="translate(0.092212,0.011511),scale(1.000000,0.819817),translate(-0.092212,-0.011511)"
          id="radialGradient-2"
          r="103.993276%"
        >
          <stop offset="0.5%" stopColor="#D1DAE8" />
          <stop offset="41.72%" stopColor="#1A6AA0" />
          <stop offset="76.69%" stopColor="#025186" />
          <stop offset="100%" stopColor="#1E3B6F" />
        </radialGradient>
        <linearGradient
          id="linearGradient-3"
          x1="48.3932316%"
          x2="51.7074987%"
          y1="-5.98819537%"
          y2="106.259702%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="23.53%" stopColor="#F3D070" />
          <stop offset="41.33%" stopColor="#E3B54E" />
          <stop offset="46.57%" stopColor="#EDC55C" />
          <stop offset="52.83%" stopColor="#AE732A" />
          <stop offset="56.94%" stopColor="#F1D76F" />
          <stop offset="67.96%" stopColor="#AE732A" />
          <stop offset="77.33%" stopColor="#F6E57E" />
          <stop offset="84.41%" stopColor="#F8F3A6" />
          <stop offset="89.19%" stopColor="#FFF1C6" />
          <stop offset="93.76%" stopColor="#E4AE25" />
          <stop offset="94.39%" stopColor="#E4AE25" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-4"
          x1="49.5344049%"
          x2="51.0719789%"
          y1="31.1309384%"
          y2="93.4460283%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="37.63%" stopColor="#F3D070" />
          <stop offset="46.57%" stopColor="#EDC55C" />
          <stop offset="56.76%" stopColor="#E3B54E" />
          <stop offset="62.37%" stopColor="#AE732A" />
          <stop offset="65.28%" stopColor="#AE732A" />
          <stop offset="71.52%" stopColor="#F1D76F" />
          <stop offset="79.21%" stopColor="#F6E57E" />
          <stop offset="84.41%" stopColor="#F8F3A6" />
          <stop offset="89.19%" stopColor="#FFF1C6" />
          <stop offset="93.76%" stopColor="#E4AE25" />
          <stop offset="94.39%" stopColor="#E4AE25" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <radialGradient
          cx="2.42402165%"
          cy="-42.3221153%"
          fx="2.42402165%"
          fy="-42.3221153%"
          gradientTransform="translate(0.024240,-0.423221),scale(1.000000,0.819050),translate(-0.024240,0.423221)"
          id="radialGradient-5"
          r="110.835068%"
        >
          <stop offset="0%" stopColor="#346AB2" />
          <stop offset="51.24%" stopColor="#346AB2" />
          <stop offset="94.32%" stopColor="#213E76" />
        </radialGradient>
        <linearGradient
          id="linearGradient-6"
          x1="49.2427704%"
          x2="49.6447517%"
          y1="-26.3578547%"
          y2="14.1767505%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="37.63%" stopColor="#F3D070" />
          <stop offset="46.57%" stopColor="#EDC55C" />
          <stop offset="56.76%" stopColor="#E3B54E" />
          <stop offset="62.37%" stopColor="#AE732A" />
          <stop offset="65.28%" stopColor="#AE732A" />
          <stop offset="71.52%" stopColor="#F1D76F" />
          <stop offset="79.21%" stopColor="#F6E57E" />
          <stop offset="84.41%" stopColor="#F8F3A6" />
          <stop offset="89.19%" stopColor="#FFF1C6" />
          <stop offset="93.76%" stopColor="#E4AE25" />
          <stop offset="94.39%" stopColor="#E4AE25" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-7"
          x1="49.3965151%"
          x2="49.7987881%"
          y1="-10.809911%"
          y2="29.7251211%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="37.63%" stopColor="#F3D070" />
          <stop offset="46.57%" stopColor="#EDC55C" />
          <stop offset="56.76%" stopColor="#E3B54E" />
          <stop offset="62.37%" stopColor="#AE732A" />
          <stop offset="65.28%" stopColor="#AE732A" />
          <stop offset="71.52%" stopColor="#F1D76F" />
          <stop offset="79.21%" stopColor="#F6E57E" />
          <stop offset="84.41%" stopColor="#F8F3A6" />
          <stop offset="89.19%" stopColor="#FFF1C6" />
          <stop offset="93.76%" stopColor="#E4AE25" />
          <stop offset="94.39%" stopColor="#E4AE25" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <radialGradient
          cx="2.42402165%"
          cy="-42.323014%"
          fx="2.42402165%"
          fy="-42.323014%"
          gradientTransform="translate(0.024240,-0.423230),scale(1.000000,0.654214),translate(-0.024240,0.423230)"
          id="radialGradient-8"
          r="132.605121%"
        >
          <stop offset="0%" stopColor="#346AB2" />
          <stop offset="51.24%" stopColor="#346AB2" />
          <stop offset="83.44%" stopColor="#213E76" />
        </radialGradient>
        <linearGradient
          id="linearGradient-9"
          x1="17.7614355%"
          x2="66.5693051%"
          y1="-13.8889264%"
          y2="82.8328922%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6E57E" />
          <stop offset="37.63%" stopColor="#F3D070" />
          <stop offset="46.57%" stopColor="#EDC55C" />
          <stop offset="56.76%" stopColor="#E3B54E" />
          <stop offset="62.37%" stopColor="#E29A39" />
          <stop offset="65.28%" stopColor="#D89628" />
          <stop offset="71.52%" stopColor="#F1D76F" />
          <stop offset="79.21%" stopColor="#F6E57E" />
          <stop offset="84.41%" stopColor="#F8F3A6" />
          <stop offset="89.19%" stopColor="#FFF1C6" />
          <stop offset="93.76%" stopColor="#E4AE25" />
          <stop offset="94.39%" stopColor="#E4AE25" />
          <stop offset="100%" stopColor="#F9BE4A" />
        </linearGradient>
        <radialGradient
          cx="2.18990747%"
          cy="-12.1064141%"
          fx="2.18990747%"
          fy="-12.1064141%"
          gradientTransform="translate(0.021899,-0.121064),scale(0.999201,1.000000),translate(-0.021899,0.121064)"
          id="radialGradient-10"
          r="95.4699913%"
        >
          <stop offset="14.13%" stopColor="#4782C3" />
          <stop offset="27.75%" stopColor="#447EC1" />
          <stop offset="46.33%" stopColor="#3D74BA" />
          <stop offset="61.28%" stopColor="#346AB2" />
          <stop offset="68.36%" stopColor="#3162A4" />
          <stop offset="80.45%" stopColor="#2A528A" />
          <stop offset="96%" stopColor="#1B3A65" />
          <stop offset="100%" stopColor="#18335B" />
        </radialGradient>
        <linearGradient
          id="linearGradient-11"
          x1="-176.023804%"
          x2="239.6655%"
          y1="-231.535909%"
          y2="322.397349%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-12"
          x1="-110.868582%"
          x2="261.136804%"
          y1="-198.438023%"
          y2="376.021266%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-13"
          x1="-39.7639864%"
          x2="227.016078%"
          y1="-134.234569%"
          y2="433.241175%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-14"
          x1="-58.5619643%"
          x2="311.332655%"
          y1="-111.104857%"
          y2="534.81235%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-15"
          x1="-33.6715479%"
          x2="350.429798%"
          y1="-68.6629161%"
          y2="475.991798%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-16"
          x1="-39.7203215%"
          x2="391.588211%"
          y1="-47.3603049%"
          y2="417.559945%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-17"
          x1="-145.553884%"
          x2="124.392582%"
          y1="-352.745263%"
          y2="205.162105%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-18"
          x1="-108.387278%"
          x2="134.940689%"
          y1="-314.336002%"
          y2="245.503865%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-19"
          x1="-92.2401986%"
          x2="151.099162%"
          y1="-277.170072%"
          y2="282.694644%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-20"
          x1="-79.1461283%"
          x2="164.924091%"
          y1="-238.565614%"
          y2="324.355088%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-21"
          x1="-46.0318881%"
          x2="175.415606%"
          y1="-193.815605%"
          y2="368.435307%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-22"
          x1="-36.250581%"
          x2="207.74139%"
          y1="-158.410578%"
          y2="400.546973%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-23"
          x1="-23.856522%"
          x2="194.521637%"
          y1="-113.65614%"
          y2="449.021754%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-24"
          x1="-11.5864347%"
          x2="232.373581%"
          y1="-82.8112281%"
          y2="480.116491%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-25"
          x1="-346.075089%"
          x2="390.746642%"
          y1="-650.658732%"
          y2="652.897784%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-26"
          x1="-319.045224%"
          x2="304.626036%"
          y1="-433.961094%"
          y2="401.423777%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-27"
          x1="-394.135368%"
          x2="300.42535%"
          y1="-583.169378%"
          y2="423.261059%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-28"
          x1="-303.171816%"
          x2="223.195745%"
          y1="-818.59961%"
          y2="475.958474%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-29"
          x1="-412.577684%"
          x2="244.494604%"
          y1="-820.899295%"
          y2="415.84314%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-30"
          x1="-184.269723%"
          x2="135.281431%"
          y1="-830.657917%"
          y2="370.61785%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-31"
          x1="-471.359609%"
          x2="207.050482%"
          y1="-830.639759%"
          y2="335.062615%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-32"
          x1="-407.391092%"
          x2="157.065537%"
          y1="-897.003655%"
          y2="309.335699%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-33"
          x1="-575.473312%"
          x2="185.730046%"
          y1="-882.035254%"
          y2="252.358694%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-34"
          x1="-570.945732%"
          x2="179.091708%"
          y1="-812.650771%"
          y2="223.110807%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-35"
          x1="-753.062335%"
          x2="201.401415%"
          y1="-973.960027%"
          y2="243.005566%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-36"
          x1="-704.069564%"
          x2="198.782598%"
          y1="-829.97397%"
          y2="208.904629%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-37"
          x1="-696.82375%"
          x2="198.450168%"
          y1="-660.201079%"
          y2="191.187049%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="29.33%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-38"
          x1="-13.4678639%"
          x2="126.999055%"
          y1="29.983673%"
          y2="85.2509626%"
        >
          <stop offset="16.56%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-39"
          x1="-36.0841946%"
          x2="104.825543%"
          y1="11.5211492%"
          y2="66.4887644%"
        >
          <stop offset="16.56%" stopColor="#F3D070" />
          <stop offset="40%" stopColor="#F8F3A6" />
          <stop offset="43.11%" stopColor="#EDC55C" />
          <stop offset="52.89%" stopColor="#E3B54E" />
          <stop offset="60.44%" stopColor="#F1D76F" />
          <stop offset="64.89%" stopColor="#F6E57E" />
          <stop offset="80.89%" stopColor="#E4AE25" />
          <stop offset="88.89%" stopColor="#E5B32A" />
          <stop offset="95.11%" stopColor="#FFF1C6" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        id="logo/KBB"
        stroke="none"
        strokeWidth="1"
      >
        <g
          fillRule="nonzero"
          id="KBB-ICO"
          transform="translate(0.000000, 5.000000)"
        >
          <g id="Group">
            <g transform="translate(5.944954, 0.000000)">
              <polyline
                fill="url(#radialGradient-1)"
                id="Path"
                points="0.432495413 22.9797248 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 22.9797248"
              />
              <g id="Path" transform="translate(0.000000, 12.385321)">
                <polygon
                  fill="url(#radialGradient-2)"
                  points="0.430018349 6.82084404 0.430018349 24.2554128 7.57882569 18.7682202 14.7231743 24.2554128 14.7231743 6.82084404"
                />
                <polyline
                  fill="url(#linearGradient-3)"
                  points="13.8745321 23.606422 13.8735413 6.8193578 13.1908624 6.8193578 13.1948257 23.0837615"
                />
                <polyline
                  fill="url(#linearGradient-4)"
                  points="1.95291743 23.0931743 1.95291743 0.43893578 1.26974312 0.43893578 1.26825688 23.620789"
                />
              </g>
              <g id="Path">
                <polyline
                  fill="url(#radialGradient-5)"
                  points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
                />
                <polyline
                  fill="url(#linearGradient-6)"
                  points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
                />
                <polyline
                  fill="url(#linearGradient-7)"
                  points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
                />
              </g>
              <g id="Path">
                <polyline
                  fill="url(#radialGradient-5)"
                  points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
                />
                <polyline
                  fill="url(#linearGradient-6)"
                  points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
                />
                <polyline
                  fill="url(#linearGradient-7)"
                  points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
                />
              </g>
              <g id="Path">
                <polyline
                  fill="url(#radialGradient-5)"
                  points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
                />
                <polyline
                  fill="url(#linearGradient-6)"
                  points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
                />
                <polyline
                  fill="url(#linearGradient-7)"
                  points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
                />
              </g>
              <polyline
                fill="url(#radialGradient-5)"
                id="Path"
                points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
              />
              <polyline
                fill="url(#linearGradient-6)"
                id="Path"
                points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
              />
              <polyline
                fill="url(#linearGradient-7)"
                id="Path"
                points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
              />
              <polyline
                fill="url(#radialGradient-5)"
                id="Path"
                points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
              />
              <polyline
                fill="url(#linearGradient-6)"
                id="Path"
                points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
              />
              <polyline
                fill="url(#linearGradient-7)"
                id="Path"
                points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
              />
              <polyline
                fill="url(#radialGradient-5)"
                id="Path"
                points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
              />
              <polyline
                fill="url(#linearGradient-6)"
                id="Path"
                points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
              />
              <polyline
                fill="url(#linearGradient-7)"
                id="Path"
                points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
              />
              <rect
                fill="url(#radialGradient-8)"
                height="21.8273945"
                id="Rectangle"
                width="14.2797798"
                x="0.432495413"
                y="0.044587156"
              />
              <polyline
                fill="url(#linearGradient-6)"
                id="Path"
                points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
              />
              <polyline
                fill="url(#linearGradient-7)"
                id="Path"
                points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
              />
              <polyline
                fill="url(#linearGradient-6)"
                id="Path"
                points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
              />
              <polyline
                fill="url(#linearGradient-7)"
                id="Path"
                points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
              />
            </g>
            <g id="Path" transform="translate(0.000000, 2.972477)">
              <polygon
                fill="#1E4281"
                points="23.0778165 23.2779633 20.9876697 22.8182202 21.0292844 24.9584037 19.0684404 24.0993578 18.692422 26.206844 16.9356881 24.9826789 16.1559083 26.9767156 14.673633 25.4330092 13.5188257 27.2353211 12.3655046 25.4315229 10.8822385 26.9747339 10.1024587 24.9806972 8.34622018 26.2048624 7.97020183 24.0973761 6.0093578 24.9549358 6.05295413 22.8142569 3.9613211 23.2730092 4.42106422 21.1828624 2.28187156 21.2244771 3.13943119 19.2641284 1.03293578 18.8871193 2.25710092 17.1318716 0.264055046 16.3515963 1.80776147 14.8683303 0.00495412844 13.7140183 1.80875229 12.5606972 0.265045872 11.0769358 2.25858716 10.2976514 1.03491743 8.54141284 3.14240367 8.1653945 2.28484404 6.20455046 4.42502752 6.24765138 3.96677064 4.15700917 6.05691743 4.61675229 6.01530275 2.47656881 7.97565138 3.33412844 8.35166972 1.22763303 10.1084037 2.45278899 10.8886789 0.458752294 12.3714495 2.00295413 13.5262569 0.200146789 14.679578 2.00344954 16.162844 0.460238532 16.9421284 2.45377982 18.698367 1.23011009 19.0733945 3.33759633 21.0347339 2.4800367 20.9921284 4.61972477 23.0832661 4.16146789 22.6235229 6.25211009 24.763211 6.21 23.9046606 8.17034862 26.0116514 8.5473578 24.7874862 10.3035963 26.7810275 11.0833761 25.2368257 12.5661468 27.039633 13.7204587 25.2368257 14.8737798 26.7795413 16.3575413 24.786 17.1368257 26.0091743 18.8930642 23.9021835 19.2685872 24.7597431 21.2289358 22.6190642 21.1868257"
              />
              <polygon
                fill="url(#linearGradient-9)"
                points="23.0089541 23.2091009 20.9341651 22.7528257 20.9752844 24.8776514 19.0283119 24.0250459 18.6552661 26.1171743 16.9109174 24.9019266 16.1365872 26.8811009 14.665211 25.3482936 13.5188257 27.1382202 12.3739266 25.3473028 10.9010642 26.8791193 10.1272294 24.899945 8.38387156 26.1151927 8.01033028 24.0230642 6.0633578 24.8741835 6.10695413 22.7488624 4.03018349 23.2041468 4.48695413 21.1288624 2.36262385 21.1704771 3.21423853 19.224 1.12310092 18.8499633 2.33834862 17.1075963 0.359669725 16.3327706 1.89198165 14.8604037 0.102550459 13.7140183 1.89346789 12.5696147 0.36066055 11.0962569 2.33983486 10.322422 1.12508257 8.57906422 3.21721101 8.20601835 2.36559633 6.25904587 4.49042202 6.30165138 4.03563303 4.22636697 6.11042202 4.68313761 6.06930275 2.55831193 8.01577982 3.40893578 8.3893211 1.31779817 10.1326789 2.5340367 10.9075046 0.554862385 12.3793761 2.08766972 13.5262569 0.297743119 14.671156 2.08816514 16.1440183 0.556348624 16.9173578 2.53552294 18.661211 1.32077064 19.0332661 3.41240367 20.9802385 2.5612844 20.9381284 4.68561468 23.0139083 4.23033028 22.557633 6.30611009 24.6824587 6.264 23.8298532 8.21047706 25.9219817 8.58451376 24.7062385 10.328367 26.6854128 11.1026972 25.1526055 12.5745688 26.9425321 13.7204587 25.1521101 14.8653578 26.6839266 16.3387156 24.7047523 17.112055 25.9190092 18.8559083 23.8273761 19.2289541 24.6784954 21.1754312 22.5536697 21.1333211"
              />
            </g>
            <path
              d="M13.5163486,5.54366972 C7.34994495,5.54366972 2.35568807,10.5433761 2.35568807,16.7058165 C2.35568807,22.8642936 7.34994495,27.8545872 13.5163486,27.8545872 C19.6797798,27.8545872 24.6844404,22.8642936 24.6844404,16.7058165 C24.6844404,10.5433761 19.6797798,5.54366972 13.5163486,5.54366972 Z"
              fill="url(#radialGradient-10)"
              id="Path"
              stroke="#F6E57E"
              strokeWidth="0.1663"
            />
            <polygon
              fill="url(#linearGradient-11)"
              id="Path"
              points="20.2475229 12.3595596 19.17 13.1115963 18.8133028 12.6042936 19.8903303 11.8522569 20.8866055 9.98405505 21.2769908 10.5389174 20.5621101 11.7665505 21.9596697 11.5089358 22.350055 12.0637982"
            />
            <polygon
              fill="url(#linearGradient-12)"
              id="Path"
              points="16.8113394 10.7846422 18.7533578 8.24218349 20.4342936 9.51638532 20.0969174 9.9592844 18.9138716 9.06308257 18.4585872 9.65955963 19.4657615 10.4229908 19.1268991 10.8658899 18.1202202 10.1024587 17.6485872 10.7202385 18.8306422 11.6169358 18.4922752 12.0598349"
            />
            <polygon
              fill="url(#linearGradient-13)"
              id="Path"
              points="14.563156 10.0226972 15.4217064 6.94420183 16.0246239 7.11115596 15.3156881 9.65361468 16.7182018 10.0415229 16.5685872 10.5775596"
            />
            <polygon
              fill="url(#linearGradient-14)"
              id="Path"
              points="12.1430642 10.0950275 11.8542385 6.91249541 12.4779633 6.85651376 12.7157615 9.4846789 14.164844 9.35438532 14.2153761 9.90875229"
            />
            <polygon
              fill="url(#linearGradient-15)"
              id="Path"
              points="9.80124771 11.0353211 8.46511927 8.1307156 10.3858349 7.25433028 10.6186789 7.76014679 9.26719266 8.37693578 9.58029358 9.05862385 10.7311376 8.53299083 10.9639817 9.03880734 9.8126422 9.56394495 10.137633 10.2694128 11.4886239 9.65311927 11.7214679 10.1584404"
            />
            <polygon
              fill="url(#linearGradient-16)"
              id="Path"
              points="9.07001835 11.7224587 7.43119266 11.3746789 7.52779817 12.010789 8.25011009 12.6468991 7.83544954 13.113578 5.43319266 10.9976697 5.84834862 10.5304954 6.89366972 11.4519633 6.59642202 9.68730275 7.10075229 9.11906422 7.35143119 10.7529358 9.55304587 11.1775046"
            />
            <polygon
              fill="url(#linearGradient-17)"
              id="Path"
              points="22.8989725 18.4694862 22.1385138 16.8628624 21.756055 17.4083119 21.756055 18.4694862 21.1788991 18.4694862 21.1788991 14.9396697 21.756055 14.9396697 21.756055 16.4764404 22.7988991 14.9396697 23.5018899 14.9396697 22.5209725 16.3426789 23.5722385 18.4694862"
            />
            <path
              d="M20.505633,18.112789 C20.2975596,18.3604954 20.0444037,18.499211 19.7075229,18.499211 C19.3711376,18.499211 19.1135229,18.36 18.9054495,18.112789 C18.6062202,17.7555963 18.6146422,17.3146789 18.6146422,16.7048257 C18.6146422,16.0949725 18.6062202,15.654055 18.9054495,15.2973578 C19.1135229,15.0496514 19.3706422,14.9104404 19.7075229,14.9104404 C20.0439083,14.9104404 20.2975596,15.0496514 20.505633,15.2973578 C20.804367,15.654055 20.8004089,16.0954679 20.8004089,16.7048257 C20.8004089,17.3146789 20.804367,17.7555963 20.505633,18.112789 Z M20.0771009,15.7130092 C19.9938716,15.5990642 19.8655596,15.5247523 19.7075229,15.5247523 C19.5494862,15.5247523 19.4162202,15.5990642 19.3334862,15.7130092 C19.2210275,15.861633 19.1922936,16.0256147 19.1922936,16.7043303 C19.1922936,17.3835413 19.2210275,17.5465321 19.3334862,17.6956514 C19.4162202,17.8095963 19.5494862,17.8844037 19.7075229,17.8844037 C19.8655596,17.8844037 19.994367,17.8100917 20.0771009,17.6956514 C20.1895596,17.5465321 20.2227523,17.3835413 20.2227523,16.7043303 C20.2227523,16.0256147 20.1895596,15.8621284 20.0771009,15.7130092 Z"
              fill="url(#linearGradient-18)"
              id="Shape"
            />
            <path
              d="M18.0409541,18.112789 C17.8328807,18.3604954 17.5797248,18.499211 17.2433394,18.499211 C16.9069541,18.499211 16.648844,18.36 16.4412661,18.112789 C16.1415413,17.7555963 16.1504587,17.3146789 16.1504587,16.7048257 C16.1504587,16.0949725 16.1415413,15.654055 16.4412661,15.2973578 C16.6493394,15.0496514 16.9069541,14.9104404 17.2433394,14.9104404 C17.5797248,14.9104404 17.8333761,15.0496514 18.0409541,15.2973578 C18.3401835,15.654055 18.3362254,16.0954679 18.3362254,16.7048257 C18.3362254,17.3146789 18.3401835,17.7555963 18.0409541,18.112789 Z M17.6129174,15.7130092 C17.5296881,15.5990642 17.4008807,15.5247523 17.2433394,15.5247523 C17.0848073,15.5247523 16.9520367,15.5990642 16.8693028,15.7130092 C16.756844,15.861633 16.7281101,16.0256147 16.7281101,16.7043303 C16.7281101,17.3835413 16.756844,17.5465321 16.8693028,17.6956514 C16.9525321,17.8095963 17.0853028,17.8844037 17.2433394,17.8844037 C17.4008807,17.8844037 17.5301835,17.8100917 17.6129174,17.6956514 C17.7253761,17.5465321 17.7585688,17.3835413 17.7585688,16.7043303 C17.7585688,16.0256147 17.7253761,15.8621284 17.6129174,15.7130092 Z"
              fill="url(#linearGradient-19)"
              id="Shape"
            />
            <path
              d="M14.9654312,18.4694862 L13.7273945,18.4694862 L13.7273945,14.9396697 L14.9158899,14.9396697 C15.4935413,14.9396697 15.8299266,15.3260917 15.8299266,15.9309908 C15.8299266,16.322367 15.6139266,16.5755229 15.4648073,16.6597431 C15.643156,16.7588257 15.8720367,16.9817615 15.8720367,17.4528991 C15.8715413,18.112789 15.4935413,18.4694862 14.9654312,18.4694862 Z M14.8703119,15.5544771 L14.3050459,15.5544771 L14.3050459,16.3674495 L14.8703119,16.3674495 C15.1150459,16.3674495 15.2527706,16.2039633 15.2527706,15.9607156 C15.2522752,15.7184587 15.1150459,15.5544771 14.8703119,15.5544771 Z M14.9074679,16.9877064 L14.3045505,16.9877064 L14.3045505,17.8551743 L14.9074679,17.8551743 C15.1695413,17.8551743 15.2938899,17.6565138 15.2938899,17.4187156 C15.2938899,17.180422 15.1695413,16.9877064 14.9074679,16.9877064 Z"
              fill="url(#linearGradient-20)"
              id="Shape"
            />
            <polygon
              fill="url(#linearGradient-21)"
              id="Path"
              points="10.6731743 18.4694862 10.6731743 14.9396697 12.621633 14.9396697 12.621633 15.5544771 11.2503303 15.5544771 11.2503303 16.3823119 12.4180183 16.3823119 12.4180183 16.9971193 11.2503303 16.9971193 11.2503303 17.8551743 12.621633 17.8551743 12.621633 18.4699817 10.6731743 18.4699817"
            />
            <path
              d="M9.15176147,18.499211 C8.54488073,18.499211 8.06284404,18.0082569 8.06284404,17.2596881 L8.06284404,14.9396697 L8.64049541,14.9396697 L8.64049541,17.2349174 C8.64049541,17.6416514 8.84014679,17.8844037 9.15176147,17.8844037 C9.46337615,17.8844037 9.66699083,17.6416514 9.66699083,17.2349174 L9.66699083,14.9396697 L10.2401843,14.9396697 L10.2401843,17.2596881 C10.2406789,18.0087523 9.7586422,18.499211 9.15176147,18.499211 Z"
              fill="url(#linearGradient-22)"
              id="Path"
            />
            <polygon
              fill="url(#linearGradient-23)"
              id="Path"
              points="5.98161468 18.4694862 5.98161468 14.9396697 6.55926606 14.9396697 6.55926606 17.8546789 7.90133945 17.8546789 7.90133945 18.4694862"
            />
            <path
              d="M4.698,18.4694862 L3.4599633,18.4694862 L3.4599633,14.9396697 L4.6479633,14.9396697 C5.22561468,14.9396697 5.562,15.3260917 5.562,15.9309908 C5.562,16.322367 5.346,16.5755229 5.19638532,16.6597431 C5.37473394,16.7588257 5.60361468,16.9817615 5.60361468,17.4528991 C5.60311927,18.112789 5.22511927,18.4694862 4.698,18.4694862 Z M4.60188991,15.5544771 L4.03662385,15.5544771 L4.03662385,16.3674495 L4.60188991,16.3674495 C4.84711927,16.3674495 4.98434862,16.2039633 4.98434862,15.9607156 C4.98434862,15.7184587 4.84711927,15.5544771 4.60188991,15.5544771 Z M4.63904587,16.9877064 L4.03662385,16.9877064 L4.03662385,17.8551743 L4.63904587,17.8551743 C4.90111927,17.8551743 5.0259633,17.6565138 5.0259633,17.4187156 C5.0259633,17.180422 4.90111927,16.9877064 4.63904587,16.9877064 Z"
              fill="url(#linearGradient-24)"
              id="Shape"
            />
            <g transform="translate(5.449541, 19.816514)">
              <path
                d="M0.621743119,2.01533945 C0.441412844,1.98462385 0.293284404,1.89644037 0.17587156,1.72354128 C0.0584587156,1.5506422 0.0292293578,1.37625688 0.0644036697,1.19344954 C0.115431193,0.930385321 0.309137615,0.79959633 0.574183486,0.613321101 C0.838733945,0.427045872 1.02798165,0.287834862 1.28708257,0.332422018 C1.46741284,0.363137615 1.61702752,0.453302752 1.73444037,0.625706422 C1.8513578,0.798605505 1.87959633,0.971504587 1.84491743,1.15431193 C1.79388991,1.41688073 1.60117431,1.54965138 1.33612844,1.73592661 C1.07058716,1.92319266 0.880348624,2.05992661 0.621743119,2.01533945 Z M1.51398165,1.06216514 C1.53429358,0.984385321 1.52190826,0.895706422 1.46691743,0.814954128 C1.41192661,0.733706422 1.33266055,0.687633028 1.25438532,0.680201835 C1.15084404,0.667321101 1.06959633,0.702495413 0.775321101,0.910568807 C0.480550459,1.11765138 0.419614679,1.18304587 0.394348624,1.28609174 C0.373541284,1.36387156 0.387908257,1.45502752 0.44240367,1.53577982 C0.497394495,1.61702752 0.574678899,1.66012844 0.653449541,1.66805505 C0.757486239,1.67994495 0.839229358,1.64724771 1.13449541,1.43966972 C1.42926606,1.23209174 1.48822018,1.16521101 1.51398165,1.06216514 Z"
                fill="url(#linearGradient-25)"
                id="Shape"
              />
              <polygon
                fill="url(#linearGradient-26)"
                id="Path"
                points="2.21251376 1.8860367 1.88108257 2.20855046 2.37451376 2.73616514 2.13968807 2.96405505 1.64576147 2.43693578 1.0993211 2.96950459 0.855082569 2.70842202 2.20211009 1.39607339 3.02548624 2.27642202 2.79066055 2.50480734"
              />
              <polygon
                fill="url(#linearGradient-27)"
                id="Path"
                points="3.31926606 2.93730275 3.04877064 3.31480734 3.62642202 3.74433028 3.43519266 4.0113578 2.85754128 3.58133945 2.41166972 4.20407339 2.12581651 3.99154128 3.22513761 2.45724771 4.18822018 3.1746055 3.99699083 3.44212844"
              />
              <polygon
                fill="url(#linearGradient-28)"
                id="Path"
                points="3.55855046 4.95858716 4.41363303 3.27022018 4.72822018 3.43568807 3.8726422 5.12405505"
              />
              <path
                d="M4.88179817,5.56299083 C4.69552294,5.4926422 4.56770642,5.3653211 4.49289908,5.19588991 C4.3853945,4.95115596 4.4706055,4.72970642 4.58009174,4.42106422 C4.69056881,4.11242202 4.76587156,3.88750459 5.0026789,3.77108257 C5.16666055,3.69033028 5.34451376,3.67695413 5.53078899,3.74631193 C5.84884404,3.8647156 6.0286789,4.14809174 5.9553578,4.54343119 L5.61847706,4.41858716 C5.63680734,4.26302752 5.58974312,4.12084404 5.42278899,4.05842202 C5.33163303,4.02423853 5.24543119,4.03166972 5.17706422,4.07130275 C5.08541284,4.12282569 5.03587156,4.20110092 4.91251376,4.54442202 C4.78965138,4.88774312 4.77825688,4.97988991 4.81590826,5.07946789 C4.84315596,5.15477064 4.90458716,5.21768807 4.99574312,5.25187156 C5.16319266,5.31429358 5.28853211,5.23601835 5.37324771,5.10522936 L5.71062385,5.23106422 C5.51543119,5.58330275 5.19688073,5.68040367 4.88179817,5.56299083 Z"
                fill="url(#linearGradient-29)"
                id="Path"
              />
              <polygon
                fill="url(#linearGradient-30)"
                id="Path"
                points="5.92612844 5.88451376 6.30957798 4.02374312 6.65537615 4.09755963 6.27291743 5.95783486"
              />
              <path
                d="M7.71308257,6.13321101 L7.63282569,5.78840367 L6.99126606,5.73588991 L6.85601835,6.06187156 L6.48891743,6.03165138 L7.29495413,4.19317431 L7.5713945,4.2159633 L8.08117431,6.16343119 L7.71308257,6.13321101 Z M7.398,4.7633945 L7.11511927,5.43368807 L7.5639633,5.47084404 L7.398,4.7633945 Z"
                fill="url(#linearGradient-31)"
                id="Shape"
              />
              <polygon
                fill="url(#linearGradient-32)"
                id="Path"
                points="8.31451376 6.13222018 8.20651376 4.2352844 8.55974312 4.21447706 8.64842202 5.77998165 9.46882569 5.73192661 9.48765138 6.06187156"
              />
              <path
                d="M11.3038349,5.3033945 C11.2201101,5.50155963 11.0769358,5.61699083 10.8951193,5.68436697 C10.7083486,5.75322936 10.5304954,5.73886239 10.3665138,5.65811009 C10.1306972,5.54020183 10.0563853,5.3152844 9.94640367,5.00614679 C9.83691743,4.69750459 9.75319266,4.47605505 9.86119266,4.2313211 C9.93649541,4.06188991 10.0598532,3.93655046 10.2510826,3.86570642 C10.6454312,3.72005505 10.9426789,3.91673394 11.0952661,4.20159633 L10.7608624,4.32495413 C10.6622752,4.17088073 10.5314862,4.11440367 10.3615596,4.17682569 C10.2708991,4.21051376 10.2099633,4.27541284 10.183211,4.3507156 C10.1450642,4.44979817 10.1574495,4.53897248 10.2798165,4.8832844 C10.4016881,5.22759633 10.4482569,5.30636697 10.5399083,5.35788991 C10.6082752,5.39752294 10.6949725,5.40544954 10.785633,5.37176147 C10.8862018,5.3346055 10.9540734,5.26722936 10.9867706,5.184 C11.0184771,5.09829358 11.0120367,5.01011009 10.978844,4.91697248 L10.9555596,4.84910092 L10.6414679,4.96502752 L10.538422,4.67322936 L11.1814679,4.43592661 L11.2736147,4.69700917 C11.3647706,4.9566055 11.377156,5.12851376 11.3038349,5.3033945 Z"
                fill="url(#linearGradient-33)"
                id="Path"
              />
              <path
                d="M12.5572294,4.97444037 C12.2327339,5.15873394 11.848789,5.07451376 11.6565688,4.72227523 L11.0600917,3.63088073 L11.3687339,3.45550459 L11.9592661,4.53500917 C12.0642936,4.72623853 12.2327339,4.77974312 12.4001835,4.68511927 C12.5661468,4.59 12.613211,4.41462385 12.5091743,4.2233945 L11.9186422,3.14388991 L12.2257982,2.96950459 L12.8212844,4.06040367 C13.014,4.4126422 12.8827156,4.79014679 12.5572294,4.97444037 Z"
                fill="url(#linearGradient-34)"
                id="Path"
              />
              <polygon
                fill="url(#linearGradient-35)"
                id="Path"
                points="13.6124587 4.29423853 12.4358532 2.81988991 12.7103119 2.59249541 13.8859266 4.06634862"
              />
              <path
                d="M14.9298006,2.85110092 C14.9312477,3.02598165 14.8490092,3.18798165 14.7147523,3.32322936 L14.2495596,3.7913945 L12.9386972,2.43941284 L13.4033945,1.97075229 C13.5376514,1.836 13.6971743,1.75326606 13.8690826,1.75673394 C14.1613761,1.76317431 14.3273394,1.9633211 14.5245138,2.16644037 C14.7211927,2.37055046 14.9272844,2.5533578 14.9298006,2.85110092 Z M13.878,2.12433028 C13.7813945,2.11343119 13.6961835,2.14266055 13.6094862,2.23034862 L13.4192477,2.42207339 L14.2738349,3.30341284 L14.4630826,3.11168807 C14.5507706,3.024 14.5804954,2.93730275 14.5705872,2.8387156 C14.5586972,2.7307156 14.4724954,2.62618349 14.2733394,2.42108257 C14.0746789,2.21598165 13.9840183,2.13721101 13.878,2.12433028 Z"
                fill="url(#linearGradient-36)"
                id="Shape"
              />
              <polygon
                fill="url(#linearGradient-37)"
                id="Path"
                points="15.4395413 2.45724771 13.8888991 1.40449541 14.5497798 0.390385321 14.8197798 0.573688073 14.3550826 1.28708257 14.7182202 1.53379817 15.1145505 0.926422018 15.3845505 1.10972477 14.989211 1.7166055 15.3652294 1.97273394 15.830422 1.25933945 16.1009174 1.44214679"
              />
            </g>
            <g id="Path" transform="translate(7.926606, 10.899083)">
              <path
                d="M5.56398165,0.424073394 C7.7646055,0.424073394 9.65113761,1.75326606 10.4755046,3.65069725 L10.8049541,3.65069725 C9.96275229,1.58086239 7.93651376,0.118899083 5.56348624,0.118899083 C3.19045872,0.118899083 1.1647156,1.58086239 0.322018349,3.65069725 L0.65146789,3.65069725 C1.47633028,1.75326606 3.36286239,0.424073394 5.56398165,0.424073394 Z"
                fill="url(#linearGradient-38)"
              />
              <path
                d="M5.56398165,11.1403486 C3.37921101,11.1403486 1.50357798,9.83047706 0.669798165,7.95484404 L0.338862385,7.95484404 C1.19146789,10.0033761 3.20680734,11.4455229 5.56398165,11.4455229 C7.92115596,11.4455229 9.93649541,10.0028807 10.7891009,7.95484404 L10.4581651,7.95484404 C9.62388991,9.82998165 7.74875229,11.1403486 5.56398165,11.1403486 Z"
                fill="url(#linearGradient-39)"
              />
            </g>
            <path
              d="M22.1008624,36.0586239 C22.1008624,36.358844 21.8655413,36.5946606 21.5583853,36.5946606 C21.2551927,36.5946606 21.0129358,36.3593394 21.0129358,36.0586239 C21.0129358,35.7653394 21.2546972,35.5295214 21.5583853,35.5295214 C21.8655413,35.5290275 22.1008624,35.764844 22.1008624,36.0586239 Z M21.1486789,36.0586239 C21.1486789,36.2944404 21.3230642,36.481211 21.5618532,36.481211 C21.7942018,36.481211 21.9651193,36.2944404 21.9651193,36.0615963 C21.9651193,35.8257798 21.7942018,35.6355413 21.5583853,35.6355413 C21.3230642,35.6360367 21.1486789,35.8262752 21.1486789,36.0586239 Z M21.4746606,36.3365505 L21.3522936,36.3365505 L21.3522936,35.8074495 C21.400844,35.7975413 21.4682202,35.7911009 21.5559083,35.7911009 C21.6559817,35.7911009 21.7010642,35.8074495 21.7397064,35.8302385 C21.7689358,35.8525321 21.7912294,35.8946422 21.7912294,35.9461651 C21.7912294,36.0041284 21.7461468,36.0497064 21.6817431,36.0685321 L21.6817431,36.0749725 C21.7332661,36.0942936 21.7624954,36.1329358 21.7783486,36.2037798 C21.7946972,36.2845321 21.8041101,36.3167339 21.8169908,36.3365505 L21.6847156,36.3365505 C21.6688624,36.3167339 21.6589541,36.2686789 21.6426055,36.2072477 C21.6331927,36.1492844 21.6004954,36.1230275 21.5331193,36.1230275 L21.475156,36.1230275 L21.475156,36.3365505 L21.4746606,36.3365505 Z M21.4781284,36.0363303 L21.5360917,36.0363303 C21.6039633,36.0363303 21.6589541,36.0140367 21.6589541,35.9590459 C21.6589541,35.9104954 21.6232844,35.8782936 21.546,35.8782936 C21.5137982,35.8782936 21.4910092,35.8812661 21.4781284,35.8847339 L21.4781284,36.0363303 L21.4781284,36.0363303 Z"
              fill="#1E4281"
              id="Shape"
            />
          </g>
          <g
            fill="#070707"
            id="Group"
            transform="translate(28.733945, 16.844037)"
          >
            <polygon
              id="Path"
              points="2.30614679 8.45818349 0.411192661 8.45818349 0.411192661 0.581119266 2.30614679 0.581119266"
            />
            <path
              d="M3.1280367,2.75053211 L4.7946055,2.75053211 L4.7946055,3.18451376 C4.97741284,2.95612844 5.39950459,2.63658716 6.00440367,2.63658716 C7.12304587,2.63658716 8.10495413,3.13893578 8.10495413,4.87387156 L8.10495413,8.4586789 L6.39231193,8.4586789 L6.39231193,5.20530275 C6.39231193,4.44038532 6.20950459,4.09805505 5.66157798,4.09805505 C5.06807339,4.09805505 4.83968807,4.46317431 4.83968807,5.19390826 L4.83968807,8.4586789 L3.12704587,8.4586789 L3.12704587,2.75053211 L3.1280367,2.75053211 Z"
              id="Path"
            />
            <path
              d="M11.723945,4.49686239 C11.5074495,4.24568807 11.2790642,4.09755963 10.7539266,4.09755963 C10.5141468,4.09755963 10.2173945,4.14313761 10.2173945,4.39431193 C10.2173945,4.58851376 10.4002018,4.67966972 10.9253394,4.7827156 C12.4893578,5.07946789 13.0714679,5.70765138 13.0714679,6.75792661 C13.0714679,7.8993578 12.2609725,8.57262385 10.7881101,8.57262385 C9.62388991,8.57262385 8.87036697,8.32144954 8.35662385,7.6253945 L9.68086239,6.66677064 C9.87456881,6.92933945 10.1029541,7.11214679 10.7539266,7.11214679 C11.1304404,7.11214679 11.3588257,7.00959633 11.3588257,6.804 C11.3588257,6.58700917 11.1646239,6.50724771 10.6280917,6.39330275 C9.3380367,6.10794495 8.50475229,5.58280734 8.50475229,4.46416514 C8.50475229,3.32273394 9.3266422,2.63757798 10.7876147,2.63757798 C11.723945,2.63757798 12.5458349,2.85456881 12.9907156,3.58480734 L11.723945,4.49686239 Z"
              id="Path"
            />
            <path
              d="M13.0362936,2.75053211 L13.7670275,2.75053211 L13.7670275,1.49466055 L15.4796697,1.49466055 L15.4796697,2.75053211 L16.507156,2.75053211 L16.507156,4.18921101 L15.4796697,4.18921101 L15.4796697,6.32394495 C15.4796697,6.7807156 15.6050092,6.97442202 16.0275963,6.97442202 L16.507156,6.97442202 L16.507156,8.4586789 L15.8338899,8.4586789 C14.4412844,8.4586789 13.7675229,8.15053211 13.7675229,6.54093578 L13.7675229,4.18921101 L13.036789,4.18921101 L13.036789,2.75053211 L13.0362936,2.75053211 Z"
              id="Path"
            />
            <path
              d="M18.6295046,4.97642202 L19.6455963,4.97642202 L19.6455963,4.80550459 C19.6455963,4.24618349 19.5425505,4.02919266 18.743945,4.02919266 C18.2529908,4.02919266 18.0246055,4.12084404 17.8190092,4.41759633 L16.6201101,3.58431193 C17.0654862,2.87636697 17.7273578,2.63708257 18.9376514,2.63708257 C20.4214128,2.63708257 21.3121651,3.05917431 21.3121651,4.8173945 L21.3121651,8.45917431 L19.6455963,8.45917431 L19.6455963,7.99100917 C19.4513945,8.29915596 19.1432477,8.57311927 18.5155596,8.57311927 C17.3969174,8.57311927 16.5294495,7.95682569 16.5294495,6.75842202 C16.5294495,5.55902752 17.4424954,4.97642202 18.6295046,4.97642202 Z M19.6455963,6.1867156 L18.8692844,6.1867156 C18.4353028,6.1867156 18.1960183,6.33484404 18.1960183,6.71185321 C18.1960183,7.04278899 18.4585872,7.22559633 18.8925688,7.22559633 C19.4632844,7.22559633 19.6460917,6.88326606 19.6460917,6.35812844 L19.6460917,6.1867156 L19.6455963,6.1867156 Z"
              id="Shape"
            />
            <path
              d="M21.8486972,2.75053211 L23.5152661,2.75053211 L23.5152661,3.18451376 C23.6980734,2.95612844 24.1201651,2.63658716 24.7250642,2.63658716 C25.8437064,2.63658716 26.8256147,3.13893578 26.8256147,4.87387156 L26.8256147,8.4586789 L25.1129725,8.4586789 L25.1129725,5.20530275 C25.1129725,4.44038532 24.9301651,4.09805505 24.3822385,4.09805505 C23.7887339,4.09805505 23.5603486,4.46317431 23.5603486,5.19390826 L23.5603486,8.4586789 L21.8477064,8.4586789 L21.8477064,2.75053211 L21.8486972,2.75053211 Z"
              id="Path"
            />
            <path
              d="M26.962844,2.75053211 L27.693578,2.75053211 L27.693578,1.49466055 L29.4062202,1.49466055 L29.4062202,2.75053211 L30.4337064,2.75053211 L30.4337064,4.18921101 L29.4062202,4.18921101 L29.4062202,6.32394495 C29.4062202,6.7807156 29.5315596,6.97442202 29.9541468,6.97442202 L30.4337064,6.97442202 L30.4337064,8.4586789 L29.7604404,8.4586789 C28.3678349,8.4586789 27.6940734,8.15053211 27.6940734,6.54093578 L27.6940734,4.18921101 L26.9633394,4.18921101 L26.9633394,2.75053211 L26.962844,2.75053211 Z"
              id="Path"
            />
            <path
              d="M37.3050826,3.01310092 C37.2253211,2.51075229 36.8829908,2.04258716 36.2092294,2.04258716 C35.4215229,2.04258716 34.9994312,2.61330275 34.9994312,3.9033578 L34.9994312,5.13594495 C34.9994312,6.36902752 35.4106239,6.9967156 36.2092294,6.9967156 C36.8829908,6.9967156 37.1911376,6.65438532 37.3050826,6.02620183 L39.1088807,6.02620183 C38.8349174,7.7953211 37.8762936,8.57163303 36.2092294,8.57163303 C34.4168257,8.57163303 33.1039817,7.48717431 33.1039817,5.13544954 L33.1039817,3.90286239 C33.1039817,1.52834862 34.4168257,0.466678899 36.2092294,0.466678899 C37.7732477,0.466678899 38.8463119,1.2999633 39.1088807,3.0126055 L37.3050826,3.0126055 L37.3050826,3.01310092 Z"
              id="Path"
            />
            <path
              d="M41.1286789,4.97642202 L42.1447706,4.97642202 L42.1447706,4.80550459 C42.1447706,4.24618349 42.0417248,4.02919266 41.2431193,4.02919266 C40.7521651,4.02919266 40.5237798,4.12084404 40.3181835,4.41759633 L39.1192844,3.58431193 C39.5646606,2.87636697 40.2265321,2.63708257 41.4368257,2.63708257 C42.9205872,2.63708257 43.8113394,3.05917431 43.8113394,4.8173945 L43.8113394,8.45917431 L42.1447706,8.45917431 L42.1447706,7.99100917 C41.9505688,8.29915596 41.642422,8.57311927 41.0147339,8.57311927 C39.8960917,8.57311927 39.0286239,7.95682569 39.0286239,6.75842202 C39.0286239,5.55902752 39.9416697,4.97642202 41.1286789,4.97642202 Z M42.1452661,6.1867156 L41.3689541,6.1867156 C40.9349725,6.1867156 40.6956881,6.33484404 40.6956881,6.71185321 C40.6956881,7.04278899 40.9582569,7.22559633 41.3922385,7.22559633 C41.9629541,7.22559633 42.1457615,6.88326606 42.1457615,6.35812844 L42.1457615,6.1867156 L42.1452661,6.1867156 Z"
              id="Shape"
            />
            <path
              d="M47.4303303,4.49686239 C47.2138349,4.24568807 46.9854495,4.09755963 46.4603119,4.09755963 C46.2205321,4.09755963 45.9237798,4.14313761 45.9237798,4.39431193 C45.9237798,4.58851376 46.1065872,4.67966972 46.6317248,4.7827156 C48.1957431,5.07946789 48.7778532,5.70765138 48.7778532,6.75792661 C48.7778532,7.8993578 47.9673578,8.57262385 46.4944954,8.57262385 C45.3302752,8.57262385 44.5767523,8.32144954 44.0630092,7.6253945 L45.3872477,6.66677064 C45.5809541,6.92933945 45.8093394,7.11214679 46.4603119,7.11214679 C46.8368257,7.11214679 47.065211,7.00959633 47.065211,6.804 C47.065211,6.58700917 46.8710092,6.50724771 46.3344771,6.39330275 C45.044422,6.10794495 44.2111376,5.58280734 44.2111376,4.46416514 C44.2111376,3.32273394 45.0330275,2.63757798 46.494,2.63757798 C47.4303303,2.63757798 48.2522202,2.85456881 48.6971009,3.58480734 L47.4303303,4.49686239 Z"
              id="Path"
            />
            <path
              d="M49.1419817,0.581614679 L50.8546239,0.581614679 L50.8546239,3.13893578 C51.0374312,2.92194495 51.4481284,2.63658716 52.018844,2.63658716 C53.1374862,2.63658716 54.1193945,3.13893578 54.1193945,4.87387156 L54.1193945,8.4586789 L52.4067523,8.4586789 L52.4067523,5.20530275 C52.4067523,4.44038532 52.223945,4.09805505 51.6760183,4.09805505 C51.0825138,4.09805505 50.8541284,4.46317431 50.8541284,5.19390826 L50.8541284,8.4586789 L49.1414862,8.4586789 L49.1414862,0.581614679 L49.1419817,0.581614679 Z"
              id="Path"
            />
            <path
              d="M60.1237982,8.57262385 C58.3313945,8.57262385 57.0185505,7.48816514 57.0185505,5.13644037 L57.0185505,3.90385321 C57.0185505,1.52933945 58.3313945,0.467669725 60.1237982,0.467669725 C61.9162018,0.467669725 63.2290459,1.52933945 63.2290459,3.90385321 L63.2290459,5.13644037 C63.2285505,7.48816514 61.9157064,8.57262385 60.1237982,8.57262385 Z M58.914,5.13644037 C58.914,6.42649541 59.3360917,6.99721101 60.1237982,6.99721101 C60.9115046,6.99721101 61.3340917,6.42649541 61.3340917,5.13644037 L61.3340917,3.90385321 C61.3340917,2.61379817 60.9115046,2.04308257 60.1237982,2.04308257 C59.3360917,2.04308257 58.914,2.61379817 58.914,3.90385321 L58.914,5.13644037 Z"
              id="Shape"
            />
            <path
              d="M64.0960183,2.75053211 L64.0960183,2.4993578 C64.0960183,0.889761468 64.7697798,0.581614679 66.1623853,0.581614679 L66.6647339,0.581614679 L66.6647339,2.06587156 L66.3565872,2.06587156 C65.934,2.06587156 65.8086606,2.26007339 65.8086606,2.71634862 L65.8086606,2.75053211 L66.6647339,2.75053211 L66.6647339,4.18921101 L65.8086606,4.18921101 L65.8086606,8.4586789 L64.0960183,8.4586789 L64.0960183,4.18921101 L63.3652844,4.18921101 L63.3652844,2.75053211 L64.0960183,2.75053211 L64.0960183,2.75053211 Z"
              id="Path"
            />
            <path
              d="M67.1324037,2.75053211 L67.1324037,2.4993578 C67.1324037,0.889761468 67.8061651,0.581614679 69.1987706,0.581614679 L69.7011193,0.581614679 L69.7011193,2.06587156 L69.3929725,2.06587156 C68.9703853,2.06587156 68.8450459,2.26007339 68.8450459,2.71634862 L68.8450459,2.75053211 L69.7011193,2.75053211 L69.7011193,4.18921101 L68.8450459,4.18921101 L68.8450459,8.4586789 L67.1324037,8.4586789 L67.1324037,4.18921101 L66.4016697,4.18921101 L66.4016697,2.75053211 L67.1324037,2.75053211 L67.1324037,2.75053211 Z"
              id="Path"
            />
            <path
              d="M74.3693945,7.5907156 C73.9700917,8.28677064 73.2849358,8.57212844 72.246055,8.57212844 C70.4422569,8.57212844 69.665945,7.40790826 69.665945,5.99251376 L69.665945,5.19341284 C69.665945,3.42379817 70.5789908,2.63609174 72.0632477,2.63609174 C73.718422,2.63609174 74.4605505,3.5833211 74.4605505,5.36433028 L74.4605505,6.03759633 L71.3785872,6.03759633 C71.4013761,6.68807339 71.5613945,7.13344954 72.2574495,7.13344954 C72.7939817,7.13344954 72.9079266,6.97343119 73.1135229,6.7113578 L74.3693945,7.5907156 Z M71.3904771,4.89666055 L72.7488991,4.89666055 C72.7147156,4.26897248 72.4749358,4.07477064 72.075633,4.07477064 C71.6297615,4.07477064 71.4355596,4.38291743 71.3904771,4.89666055 Z"
              id="Shape"
            />
            <path
              d="M77.9651009,4.50875229 C77.8625505,4.33733945 77.7025321,4.09805505 77.3715963,4.09805505 C76.8806422,4.09805505 76.5952844,4.44038532 76.5952844,5.20530275 L76.5952844,8.4586789 L74.8826422,8.4586789 L74.8826422,2.75053211 L76.549211,2.75053211 L76.549211,3.18451376 C76.7206239,2.96752294 77.0971376,2.63658716 77.7481101,2.63658716 C78.3985872,2.63658716 78.9123303,2.95612844 79.2432661,3.59521101 L77.9651009,4.50875229 Z"
              id="Path"
            />
          </g>
          <g
            fill="#070707"
            id="Group"
            transform="translate(28.733945, 8.917431)"
          >
            <polygon
              id="Path"
              points="3.22612844 6.15897248 1.76317431 3.85431193 1.01708257 4.81293578 1.01708257 6.15897248 0.168440367 6.15897248 0.168440367 1.11121101 1.01708257 1.11121101 1.01708257 3.54715596 2.87537615 1.11121101 3.91376147 1.11121101 2.31902752 3.14488073 4.24321101 6.15897248"
            />
            <path
              d="M7.29495413,5.60311927 C7.03882569,6.04948624 6.62911927,6.22486239 5.98508257,6.22486239 C4.93877064,6.22486239 4.38291743,5.54466055 4.38291743,4.57166972 L4.38291743,4.07427523 C4.38291743,2.99873394 4.90954128,2.43544954 5.86816514,2.43544954 C6.87038532,2.43544954 7.34598165,3.0353945 7.34598165,4.11787156 L7.34598165,4.52014679 L5.15823853,4.52014679 L5.15823853,4.53451376 C5.15823853,5.10522936 5.37770642,5.55159633 5.95585321,5.55159633 C6.3873578,5.55159633 6.56273394,5.41981651 6.70937615,5.19291743 L7.29495413,5.60311927 Z M5.16616514,3.92020183 L6.56322936,3.92020183 C6.534,3.38614679 6.31453211,3.11565138 5.86816514,3.11565138 C5.41486239,3.11565138 5.20282569,3.42280734 5.16616514,3.92020183 Z"
              id="Shape"
            />
            <path
              d="M8.10049541,1.11121101 L8.88324771,1.11121101 L8.88324771,4.82730275 C8.88324771,5.22214679 8.95656881,5.47084404 9.43959633,5.47084404 L9.43959633,6.15847706 C8.48840367,6.15847706 8.10049541,5.8513211 8.10049541,5.01011009 L8.10049541,1.11121101 Z"
              id="Path"
            />
            <path
              d="M10.1638899,1.11121101 L10.9466422,1.11121101 L10.9466422,4.82730275 C10.9466422,5.22214679 11.0199633,5.47084404 11.5024954,5.47084404 L11.5024954,6.15847706 C10.5513028,6.15847706 10.1638899,5.8513211 10.1638899,5.01011009 L10.1638899,1.11121101 Z"
              id="Path"
            />
            <path
              d="M14.9857431,5.60311927 C14.7296147,6.04948624 14.3199083,6.22486239 13.6758716,6.22486239 C12.630055,6.22486239 12.0737064,5.54466055 12.0737064,4.57166972 L12.0737064,4.07427523 C12.0737064,2.99873394 12.6003303,2.43544954 13.5589541,2.43544954 C14.5611743,2.43544954 15.0367706,3.0353945 15.0367706,4.11787156 L15.0367706,4.52014679 L12.8490275,4.52014679 L12.8490275,4.53451376 C12.8490275,5.10522936 13.0684954,5.55159633 13.6466422,5.55159633 C14.0781468,5.55159633 14.2535229,5.41981651 14.4001651,5.19291743 L14.9857431,5.60311927 Z M12.8564587,3.92020183 L14.2535229,3.92020183 C14.2242936,3.38614679 14.0048257,3.11565138 13.5584587,3.11565138 C13.105156,3.11565138 12.8931193,3.42280734 12.8564587,3.92020183 Z"
              id="Shape"
            />
            <path
              d="M16.1351009,2.50084404 L17.064,5.076 L17.8982752,2.50084404 L18.7028257,2.50084404 L17.4152477,6.30511927 C17.1006606,7.24144954 16.8296697,7.5486055 15.8056514,7.5486055 L15.8056514,6.83174312 C16.3179083,6.83174312 16.5230092,6.75099083 16.7053211,6.20256881 L15.3082569,2.50084404 L16.1351009,2.50084404 L16.1351009,2.50084404 Z"
              id="Path"
            />
            <path
              d="M22.742422,1.11121101 C23.9274495,1.11121101 24.5348257,1.55014679 24.5348257,2.42058716 C24.5348257,2.94027523 24.2351009,3.35691743 23.7961651,3.51792661 C24.3668807,3.67893578 24.6958349,4.07377982 24.6958349,4.71038532 C24.6958349,5.47133945 24.2128073,6.15897248 22.9034312,6.15897248 L21.1184587,6.15897248 L21.1184587,1.11121101 L22.742422,1.11121101 Z M21.9666055,3.17411009 L22.7934495,3.17411009 C23.4226239,3.17411009 23.6861835,2.88875229 23.6861835,2.47161468 C23.6861835,2.07627523 23.4885138,1.84244037 22.6839633,1.84244037 L21.9671009,1.84244037 L21.9671009,3.17411009 L21.9666055,3.17411009 Z M21.9666055,5.42724771 L22.8519083,5.42724771 C23.5321101,5.42724771 23.8466972,5.1493211 23.8466972,4.66629358 C23.8466972,4.13223853 23.4954495,3.90533945 22.7493578,3.90533945 L21.9666055,3.90533945 L21.9666055,5.42724771 Z"
              id="Shape"
            />
            <path
              d="M25.3844587,1.11121101 L26.167211,1.11121101 L26.167211,4.82730275 C26.167211,5.22214679 26.2405321,5.47084404 26.7230642,5.47084404 L26.7230642,6.15847706 C25.7718716,6.15847706 25.3844587,5.8513211 25.3844587,5.01011009 L25.3844587,1.11121101 Z"
              id="Path"
            />
            <path
              d="M30.4545138,6.15897248 L29.6866239,6.15897248 L29.6866239,5.83001835 C29.5181835,6.09308257 29.2987156,6.22486239 28.7869541,6.22486239 C28.0334312,6.22486239 27.4478532,5.83744954 27.4478532,4.78370642 L27.4478532,2.50133945 L28.2306055,2.50133945 L28.2306055,4.70344954 C28.2306055,5.35442202 28.5233945,5.53722936 28.9331009,5.53722936 C29.4161284,5.53722936 29.6791927,5.21521101 29.6791927,4.71038532 L29.6791927,2.50084404 L30.4545138,2.50084404 L30.4545138,6.15897248 Z"
              id="Path"
            />
            <path
              d="M34.0546789,5.60311927 C33.7985505,6.04948624 33.388844,6.22486239 32.7448073,6.22486239 C31.6984954,6.22486239 31.1426422,5.54466055 31.1426422,4.57166972 L31.1426422,4.07427523 C31.1426422,2.99873394 31.6692661,2.43544954 32.6278899,2.43544954 C33.6301101,2.43544954 34.1057064,3.0353945 34.1057064,4.11787156 L34.1057064,4.52014679 L31.9179633,4.52014679 L31.9179633,4.53451376 C31.9179633,5.10522936 32.1374312,5.55159633 32.715578,5.55159633 C33.1470826,5.55159633 33.3224587,5.41981651 33.4691009,5.19291743 L34.0546789,5.60311927 Z M31.9258899,3.92020183 L33.3229541,3.92020183 C33.2937248,3.38614679 33.0742569,3.11565138 32.6278899,3.11565138 C32.1745872,3.11565138 31.9625505,3.42280734 31.9258899,3.92020183 Z"
              id="Shape"
            />
            <path
              d="M38.4524587,1.11121101 C39.6374862,1.11121101 40.2448624,1.55014679 40.2448624,2.42058716 C40.2448624,2.94027523 39.9451376,3.35691743 39.5062018,3.51792661 C40.0769174,3.67893578 40.4058716,4.07377982 40.4058716,4.71038532 C40.4058716,5.47133945 39.922844,6.15897248 38.6134679,6.15897248 L36.8284954,6.15897248 L36.8284954,1.11121101 L38.4524587,1.11121101 Z M37.6771376,3.17411009 L38.5039817,3.17411009 C39.133156,3.17411009 39.3967156,2.88875229 39.3967156,2.47161468 C39.3967156,2.07627523 39.1990459,1.84244037 38.3944954,1.84244037 L37.677633,1.84244037 L37.677633,3.17411009 L37.6771376,3.17411009 Z M37.6771376,5.42724771 L38.5624404,5.42724771 C39.2426422,5.42724771 39.5572294,5.1493211 39.5572294,4.66629358 C39.5572294,4.13223853 39.2059817,3.90533945 38.4598899,3.90533945 L37.6771376,3.90533945 L37.6771376,5.42724771 Z"
              id="Shape"
            />
            <path
              d="M44.0501284,4.55680734 C44.0501284,5.52979817 43.5745321,6.22486239 42.4989908,6.22486239 C41.4749725,6.22486239 40.940422,5.56645872 40.940422,4.55680734 L40.940422,4.09607339 C40.940422,3.02053211 41.5111376,2.43544954 42.4989908,2.43544954 C43.4863486,2.43544954 44.0501284,3.02053211 44.0501284,4.09607339 L44.0501284,4.55680734 Z M43.2748073,4.53451376 L43.2748073,4.11044037 C43.2748073,3.47383486 43.0186789,3.11565138 42.4994862,3.11565138 C41.9728624,3.11565138 41.7167339,3.48126606 41.7167339,4.11044037 L41.7167339,4.53451376 C41.7167339,5.10522936 41.9069725,5.55159633 42.4994862,5.55159633 C43.0553394,5.55159633 43.2748073,5.10522936 43.2748073,4.53451376 Z"
              id="Shape"
            />
            <path
              d="M47.6869541,4.55680734 C47.6869541,5.52979817 47.2113578,6.22486239 46.1358165,6.22486239 C45.1117982,6.22486239 44.5772477,5.56645872 44.5772477,4.55680734 L44.5772477,4.09607339 C44.5772477,3.02053211 45.1479633,2.43544954 46.1358165,2.43544954 C47.1231743,2.43544954 47.6869541,3.02053211 47.6869541,4.09607339 L47.6869541,4.55680734 Z M46.911633,4.53451376 L46.911633,4.11044037 C46.911633,3.47383486 46.6555046,3.11565138 46.1363119,3.11565138 C45.6096881,3.11565138 45.3535596,3.48126606 45.3535596,4.11044037 L45.3535596,4.53451376 C45.3535596,5.10522936 45.5437982,5.55159633 46.1363119,5.55159633 C46.6916697,5.55159633 46.911633,5.10522936 46.911633,4.53451376 Z"
              id="Shape"
            />
            <polygon
              id="Path"
              points="51.3307156 2.50084404 49.9336514 4.07377982 51.5501835 6.15847706 50.606422 6.15847706 49.3991009 4.68066055 49.1499083 4.95858716 49.1499083 6.15847706 48.367156 6.15847706 48.367156 1.11121101 49.1499083 1.11121101 49.1499083 3.92763303 50.3720917 2.50084404"
            />
            <path
              d="M52.871945,0.489963303 C52.7634495,0.349761468 52.6465321,0.247211009 52.3923853,0.247211009 C52.158055,0.247211009 52.0000183,0.352733945 52.0000183,0.563284404 C52.0000183,0.80306422 52.1813394,0.885302752 52.4766055,0.937816514 C52.866,1.00519266 53.1028073,1.1453945 53.1028073,1.51992661 C53.1028073,1.78299083 52.9274312,2.08469725 52.4240917,2.08469725 C52.0966239,2.08469725 51.856844,1.97372477 51.7047523,1.75425688 L51.9123303,1.61108257 C52.0441101,1.76614679 52.1610275,1.85680734 52.4240917,1.85680734 C52.7550275,1.85680734 52.8511376,1.7047156 52.8511376,1.53231193 C52.8511376,1.34207339 52.7550275,1.21326606 52.3745505,1.15480734 C51.9945688,1.09634862 51.7483486,0.915027523 51.7483486,0.563779817 C51.7483486,0.274458716 51.9385872,0.0198165138 52.4008073,0.0198165138 C52.7228257,0.0198165138 52.9244587,0.127816514 53.0681284,0.350256881 L52.871945,0.489963303 Z"
              id="Path"
            />
            <polygon
              id="Path"
              points="54.3443119 1.30045872 54.9467339 0.0426055046 55.1954312 0.0426055046 55.1954312 2.06091743 54.9437615 2.06091743 54.9437615 0.580623853 54.4523119 1.6393211 54.2268991 1.6393211 53.7438716 0.589045872 53.7438716 2.06042202 53.4922018 2.06042202 53.4922018 0.0421100917 53.7379266 0.0421100917"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
