import styled from "styled-components";
import { ThemeState } from "@vinsolutions/ccrm/interfaces";

export const StyledNavigationTabs = styled.div<
  Pick<ThemeState, "abovePrimary">
>`
  height: 38px;

  > .ant-menu-horizontal.ant-menu {
    background-color: inherit;
    border-bottom: none;
    height: 38px;

    > .ant-menu-item {
      padding: 0;

      &.ant-menu-item-selected::after,
      &:hover::after,
      &::after {
        border: none;
        transition: none;
      }
    }

    > .ant-menu-submenu-open.ant-menu-overflow-item::after,
    > .ant-menu-submenu-active.ant-menu-submenu::after,
    > .ant-menu-submenu::after {
      border: none;
      transition: none;
    }
  }

  .ant-menu-submenu-title .anticon-ellipsis svg {
    color: ${({ abovePrimary }) => abovePrimary};
    transition: none;
  }

  .btn {
    height: 38px;
    font-size: 16px;
    font-weight: 400px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: none;

    > a {
      color: ${({ abovePrimary }) => abovePrimary};
      transition: none;
    }

    &.btn-link {
      color: ${({ abovePrimary }) => abovePrimary};
      transition: none;

      > a {
        color: ${({ abovePrimary }) => abovePrimary};
        transition: none;
      }
    }
    &.btn-primary {
      background-color: #2053ab;
      border: #2053ab;
      box-shadow: none;
      transition: none;

      > a {
        color: #fff;
        transition: none;
      }
    }
  }
`;
