import React from "react";
import { TextInput } from "@vinsolutions/core/cx";

let timer: number;

const AutoTextInput = ({
  autoAction,
  autoActionDelay,
  name,
  onChange,
  ...other
}: AutoTextInputProps) => {
  const onKeyUp = ({ key }: { key: string }) => {
    if (key === "Enter") {
      window.clearTimeout(timer);
      autoAction();
    }
    if (key === "Escape") {
      window.clearTimeout(timer);
      onChange({ target: { name, value: "" } });
    }
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      autoAction();
    }, autoActionDelay);
  };

  return <TextInput {...{ onChange, name, onKeyUp }} {...other} />;
};

// the use of any should probably be resolved by CX in the future
export interface AutoTextInputProps {
  /** Any children to be passed through **after** the dropdown. */
  appendChild?: React.ReactNode;

  /** Action to occur automatically once the input delay has been reached */
  autoAction: () => void;

  /** Delay before the autoAction is called in milliseconds */
  autoActionDelay: number;

  /** Disable autoComplete on TextInputs by passing a unique string (such as "none", or the name of the input "passwordInput"). Browsers will ignore "false", and "new-password". */
  autoComplete?: string;

  /** Used to gain focus on mount. */
  autoFocus?: boolean;

  /** Additional class name. */
  className?: string;

  /** Set to true to disable the TextInput. */
  disabled?: boolean;

  /** Boolean displays label if set to true. */
  displayLabel?: boolean;

  /** String to display as error. Overrides standard error and warning handling. */
  error?: string;

  /** Function to pass custom error rules to, handy for specific input types. For an implementation example, review the source code of PriceInput. */
  getError?: (...args: any[]) => any;

  /** Globally unique and descriptive HTML ID. Used by QA for automated testing. */
  htmlId: string;

  /** Static text label that appears to the left of the input. */
  inputPrefix?: string;

  /** Static text label that appears to the right of the input. */
  inputSuffix?: string;

  /** Input label. */
  label: string;

  /** Set the input layout style. Either `vertical` or `horizontal`. */
  layout?: "vertical" | "horizontal";

  /** **Note:** Before using this prop, consider using one of the specialized inputs listed at the top of this page.
   * Object that determines masking rules for TextInput.
   * [react-text-mask](https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md) is being used for masking.
   * The guide, mask and pipe props all function as described in the [react-text-mask](https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md) documentation.
  *
  - guide: Boolean shows an underscore as placeholder character when field is empty.
  - mask: Mask template for input.  Accepts an array of characters to mask against or a masking function.
  The existence of this property determines whether masking will be used.

  - pipe: Function that allows you to modify the value entered. Examples: Capitalize a letter. Add a leading zero to a number.
  - maskedMaxLength: Expected maxLength of value you wish to return. Accepts number value.
  - unmask: Array of characters to strip out when returning a masked value.<br/>
 */

  masking?: {
    guide: boolean;
    mask: [
      Array<any>,
      (...args: any[]) => any,
      {
        mask: Array<any> | ((...args: any[]) => any);
        pipe: (...args: any[]) => any;
      }
    ];
    maskedMaxLength: number;
    pipe: (...args: any[]) => any;
    unmask: Array<any>;
  };

  /** Maximum length of characters allowed. */
  maxLength?: number;

  /** Minimum length of characters allowed. */
  minLength?: number;

  /** Input name. */
  name: string;

  /** onBlur callback. */
  onBlur?: (...args: any[]) => any;

  /** onChange callback. Called with `cb(cxEvent, isValid, domEvent)`.
   * Open the console to view returned data from fired onChange event.
   *
   * - cxEvent: Returns component specific data whose shape is `{target: {name, value}}`.
   * - isValid: Boolean that specifies if the control is in error.
   * - domEvent: The unaltered event returned from the input's interaction, if exists.
   */
  onChange: (...args: any[]) => any;

  /** Function to call onFocus. */
  onFocus?: (...args: any[]) => any;

  /** onKeyDown callback. */
  onKeyDown?: (...args: any[]) => any;

  /** Placeholder to display when the field is empty.  */
  placeholder?: string;

  /** Any children to be passed through **before** the dropdown. */
  prependChild?: React.ReactNode;

  /** Set to true to make the control readonly. */
  readOnly?: boolean;

  /** Mark label with asterisk and perform required field validation on blur if set to true. */
  required?: boolean;

  /** Private: Set the visible height of the textarea, in lines. */
  rows?: number;

  /** Input type. Accepts 'password', 'text', or 'textarea'. Need specialized masking or constraints? See the top of this page for a list of other specialized input components to consider.*/
  type?: "password" | "text" | "textarea";

  /** Text input value. */
  value: string | number;
}

export default AutoTextInput;
