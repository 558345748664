import { useMemo } from "react";
import styled from "styled-components";
import {
  AMP_SIGNALS_SETTING_NAME,
  CARDASHBOARD_LEAD_MANAGEMENT_BASEPATH,
  CARDASHBOARD_PAGES_SERVICE_BASEPATH,
  CARDASHBOARD_RIMS_BASEPATH,
  DIRECT_MAIL_SENT,
  EMAIL_SENT,
  TEXT_SENT
} from "../constants";
import { AmpInfoResponse } from "@vinsolutions/data-access/marketing/coxauto.amp.vinconnect.bff";

export interface AmpModelContentProps {
  ampSummary: AmpInfoResponse;
}

const StyledAmpModalContent = styled.iframe`
  width: 100%;
  height: 900px;
  border: none;
`;

const AmpModalContent = ({
  ampSummary: {
    autoLeadMessageId,
    eventType,
    globalCustomerServiceMessageId,
    dealerId,
    globalCustomerId,
    directMailContentHref
  }
}: AmpModelContentProps) => {
  const iframeUrl = useMemo(() => {
    switch (eventType) {
      case EMAIL_SENT:
        return autoLeadMessageId && autoLeadMessageId > 0
          ? `${CARDASHBOARD_LEAD_MANAGEMENT_BASEPATH}?source=customerpanel&RecordID=${autoLeadMessageId}&hideCancelButton=true`
          : // eslint-disable-next-line @cspell/spellchecker
            `${CARDASHBOARD_PAGES_SERVICE_BASEPATH}?GCSMID=${globalCustomerServiceMessageId}&hideCancelButton=true`;
      case TEXT_SENT:
        return `${CARDASHBOARD_RIMS_BASEPATH}?${AMP_SIGNALS_SETTING_NAME}&dealerId=${dealerId}&customerId=${globalCustomerId}`;
      case DIRECT_MAIL_SENT:
        return directMailContentHref;
      default:
        return null;
    }
  }, [
    eventType,
    autoLeadMessageId,
    globalCustomerServiceMessageId,
    dealerId,
    globalCustomerId,
    directMailContentHref
  ]);

  return (
    iframeUrl && <StyledAmpModalContent src={iframeUrl} title="AMP-Modal" />
  );
};

export default AmpModalContent;
