const formatTimeRemaining = (timeLeft: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  let messageArray: string[] = [];
  if (timeLeft.days > 0) {
    messageArray = [
      ...messageArray,
      `${timeLeft.days.toString().padStart(2, "0")}`
    ];
  }
  if (timeLeft.days > 0 || timeLeft.hours > 0) {
    messageArray = [
      ...messageArray,
      `${timeLeft.hours.toString().padStart(2, "0")}`
    ];
  }
  if (timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0) {
    messageArray = [
      ...messageArray,
      `${timeLeft.minutes.toString().padStart(2, "0")}`
    ];
  }
  if (
    timeLeft.days > 0 ||
    timeLeft.hours > 0 ||
    timeLeft.minutes > 0 ||
    timeLeft.seconds
  ) {
    messageArray = [
      ...messageArray,
      `${timeLeft.seconds.toString().padStart(2, "0")}`
    ];
  }
  return messageArray.join(":");
};

export default formatTimeRemaining;
