import { NavigationItemType } from "@vinsolutions/ccrm/interfaces";
import {
  NavigationButton,
  NavigationCarDashboardJavascriptButton,
  NavigationCommunicationsButton
} from "../navigation-buttons";

export const getButtonToRender = (
  navigationItemType: NavigationItemType,
  url: string,
  label: string,
  target: string,
  id: string
) => {
  let button = null;
  switch (navigationItemType) {
    case "CARDASHBOARD_JAVASCRIPT_URL":
      button = (
        <NavigationCarDashboardJavascriptButton {...{ id, label, url }} />
      );
      break;
    case "COMMUNICATIONS":
      button = (
        <NavigationCommunicationsButton {...{ id, label, url, target }} />
      );
      break;
    case "GENERIC_URL":
    default:
      button = <NavigationButton {...{ id, label, url, target }} />;
      break;
  }

  return button;
};
