import { useEffect, useRef } from "react";
import { createLogger } from "@vinsolutions/logger";
import { CarDashboardFrame } from "@vinsolutions/legacy-cardashboard/interfaces";
import { NavigationIframe } from "../cardashboard";
import {
  RouteBackButton,
  RouteButtonPosition
} from "@vinsolutions/ccrm/top-nav/route-config";
import styled from "styled-components";
import { Tooltip } from "@interstate/components/Tooltip";
import { Button } from "@interstate/components/Button";
import { ArrowUturnRightIcon } from "@interstate/components/Icons/ArrowUturnRightIcon";
import { useRenderRoute } from "./use-render-route";
import { ModalObserver } from "./modal-observer";
import { useLocation } from "react-router-dom";

export interface CdPathFrameProps {
  src?: string;
  routeBackButton?: RouteBackButton;
}

const logger = createLogger("cd-page-iframe");

function resolveHorizontal(buttonPosition: RouteButtonPosition) {
  return buttonPosition === RouteButtonPosition.TOP_LEFT ||
    buttonPosition === RouteButtonPosition.BOTTOM_LEFT
    ? "left: 50%"
    : "right: 20px";
}

function resolveVertical(buttonPosition: RouteButtonPosition) {
  return buttonPosition === RouteButtonPosition.TOP_LEFT ||
    buttonPosition === RouteButtonPosition.TOP_RIGHT
    ? "top: 5px"
    : "bottom: 10px";
}

type DynamicPaneProps = {
  buttonPosition: RouteButtonPosition;
};

const StyledDiv = styled.div<DynamicPaneProps>`
  && {
    position: absolute;

    ${props => {
      return resolveHorizontal(props.buttonPosition);
    }};

    ${props => {
      return resolveVertical(props.buttonPosition);
    }};

    background-color: white;
  }
`;

/**
 * Used to display a CD component
 * @prop {string} src The source for the CD Frame, a URL to a CD asp page
 * @prop {RouteBackButton} routeBackButton If provided, causes a back button to be displayed
 */
export const CdPathFrame = ({
  src,
  routeBackButton: backButton
}: CdPathFrameProps) => {
  const cdFrameRef = useRef<CarDashboardFrame>(null);
  const renderRoute = useRenderRoute();
  const location = useLocation();

  function backButtonClick(button: RouteBackButton) {
    logger.debug("backButtonClick");
    renderRoute(button.productName, button.viewName, {
      previousPathname: location?.pathname
    });
  }

  const observerRef = useRef<ModalObserver>();
  useEffect(() => {
    if (cdFrameRef !== undefined) {
      observerRef.current = new ModalObserver();
      observerRef.current.observe();
    }
    return () => {
      if (observerRef?.current) {
        observerRef.current.disconnect();
      }
    };
  });

  if (!src) {
    return null;
  }

  return (
    <>
      <NavigationIframe cdFrameRef={cdFrameRef} frameUrl={src} />
      {backButton !== undefined && (
        <StyledDiv buttonPosition={backButton.buttonPosition}>
          <Tooltip
            position={
              backButton.buttonPosition ===
              (RouteButtonPosition.TOP_LEFT || RouteButtonPosition.BOTTOM_LEFT)
                ? "right"
                : "left"
            }
            size="small"
            toolTipContent={`Back to ${backButton.title}`}
          >
            <Button
              buttonStyle="secondary"
              size="small"
              startIcon={<ArrowUturnRightIcon />}
              onClick={() => backButtonClick(backButton)}
            >
              {backButton.title}
            </Button>
          </Tooltip>
        </StyledDiv>
      )}
    </>
  );
};
