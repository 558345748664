import { useMemo } from "react";
import { SearchableSelect } from "cx";
import { DMC_TYPES } from "../../../../constants/dmcTypes.constant";
import { DMCSourceParams } from "../../../../types/dmcParams.type";

export const DMCDetailSource = ({
  handleInputChange,
  oemId
}: DMCSourceParams) => {
  const options = useMemo(() => {
    return Object.entries(DMC_TYPES)
      .map(([value, { name }]) => ({
        value,
        label: name
      }))
      .sort((first, second) => {
        return (first?.label ?? "")
          .toLowerCase()
          .localeCompare((second?.label ?? "").toLowerCase());
      });
  }, []);

  const onChange = (
    event: { target: { value: Array<{ value: string }>; name: string } },
    isValid: boolean
  ) => {
    const { value: dmcSource, name } = event.target;
    const value = dmcSource.length ? dmcSource[0].value : "";

    handleInputChange({ target: { value, name } }, isValid);
  };

  return (
    <SearchableSelect
      required
      data-testid="DMCSource"
      enableMultiSelect={false}
      htmlId="DMCSource"
      label="Source"
      maxHeight={150}
      name="DMCSource"
      options={options}
      value={oemId.toString()}
      {...{ onChange }}
    />
  );
};
