import { render, screen } from "./custom-render";
import { renderHook } from "./custom-render-hook";
import { getStore } from "./generateStore";
import { defaultPreloadedState } from "./defaultPreloadedState";
export const testUtils = {
  getStore,
  render,
  screen,
  defaultPreloadedState,
  renderHook
};
