import { useModalContext } from "../Modal/ModalIframe";
import { FunctionComponent } from "react";

export interface BadProps {
  text: string;
}

export const Bad: FunctionComponent = () => {
  const { componentProps } = useModalContext();
  return <>Bad: {JSON.stringify(componentProps as BadProps)}</>;
};
