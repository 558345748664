import { MouseEvent } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { cardashboardActions } from "@vinsolutions/legacy-cardashboard/store";
import { Button } from "cx";
import { getCommunicationsItemOnClick } from "@vinsolutions/ccrm/util";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { useNavigate } from "react-router-dom";
import { getCardashboardState } from "@vinsolutions/legacy-cardashboard/store";
import { vinconnectHashPrefix } from "@vinsolutions/ccrm/util";

/* eslint-disable-next-line */
export interface NavigationCommunicationsButtonProps {
  id: string;
  label: string;
  url: string;
  target: string;
}

export function NavigationCommunicationsButton({
  id,
  label,
  url,
  target
}: NavigationCommunicationsButtonProps) {
  const dispatch = useDispatch();
  const { dealerId } = useSelector(getProfileState);
  const link = window.location.href.split("#")[0] + "#" + url;
  const navigate = useNavigate();
  const { frameBaseUrl } = useSelector(getCardashboardState, shallowEqual);

  const processOnClick = () => {
    if (dealerId !== null) {
      const navItemOnClick = getCommunicationsItemOnClick(
        dealerId,
        id,
        (url: string) => {
          const framePath = url?.replace(frameBaseUrl, "");
          navigate(`${vinconnectHashPrefix}${framePath}`, { replace: false });
          dispatch(
            cardashboardActions.updateFrameUrl({
              name: label,
              frameUrl: url
            })
          );
        }
      );
      if (navItemOnClick) {
        navItemOnClick();
      }
    }
  };

  const onClick = (e: MouseEvent<HTMLElement>) => {
    TagManager.event({
      eventElement: `TopNav: Global Navigation`,
      eventAction: `${label} clicked`,
      eventValue: `${id}`
    });

    if (e.button === 0 && e.ctrlKey === false) {
      e.preventDefault();
      processOnClick();
    }
  };

  return (
    <Button buttonStyle={"link"} htmlId={id} {...{ onClick }}>
      {url && url !== "#" ? (
        <a href={link} target={target}>
          {label}
        </a>
      ) : (
        label
      )}
    </Button>
  );
}

export default NavigationCommunicationsButton;
