import { shallowEqual, useSelector } from "react-redux";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { useGetDefaultDashboardQuery } from "@vinsolutions/ccrm/api";

export function useGetDefaultDashboard() {
  const { dealerId, userId } = useSelector(getProfileState, shallowEqual);

  const {
    currentData: dashboardConfig,
    isSuccess,
    isLoading,
    isFetching,
    isUninitialized,
    isError
  } = useGetDefaultDashboardQuery(
    { dealerId: dealerId!, userId: userId! },
    { skip: !dealerId || !userId }
  );

  return {
    dealerId,
    userId,
    dashboardConfig,
    isError,
    isSuccess: isSuccess && !isLoading && !isFetching && !isUninitialized
  };
}
