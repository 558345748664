import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useRouteManager } from "./use-route-manager";
import { RouteBackButton } from "@vinsolutions/ccrm/top-nav/route-config";

/**
 * Hook that returns a function that will render a route
 * The route is rendered by pushing route to history. The route is then picked up by ReactRouter and matched to routes
 * that are defined in the Routes component.
 * The routes in the Routes component come from the routeConfig file.
 * The route id should be a value from routeConfig.
 */
export const useRenderRoute = () => {
  const navigate = useNavigate();
  const routeManager = useRouteManager();
  /**
   * Used to render a route
   * -- pane-{left|right}, this is a view that renders a react component or React/CarDashboard combo view
   * @param {string}  routeId The identifier of the route.
   */
  return useCallback(
    (
      productName: string,
      viewName: string,
      queryParams: Record<string, string | number> | null | undefined,
      routeBackButton?: RouteBackButton
    ) => {
      if (routeManager?.isActiveRoute(productName, viewName)) {
        navigate(routeManager.getPathForRoute(productName, viewName), {
          state: routeManager?.setRouteState(
            false,
            queryParams,
            routeBackButton
          ),
          replace: false
        });
      }
    },
    [navigate, routeManager]
  );
};
