import { AlertType } from "@vinsolutions/core/cx";

export type MarkLeadBadRouteParams = {
  leadId?: string;
};

export type MarkLeadLostRouteParams = {
  leadId?: string;
};

export enum LeadStatus {
  Bad = 4,
  Lost = 3
}

export interface MarkLeadStatusProps {
  leadId?: number;
  leadStatus: LeadStatus;
  onSuccess: (...args: any[]) => any;
  onCancel: (...args: any[]) => any;
}

export interface LeadStatusValue {
  leadStatusId: number;
  leadStatusName: string;
  leadStatusTypeName: string;
}

export interface LeadStatusResponse {
  leadStatusResponses: LeadStatusValue[];
}

export interface MarkLeadStatusResponse {
  triggerMarkProcessResult: TriggerMarkProcessResult;
  clearTaskProcessResult: ClearTaskProcessResult;
  addNoteProcessResult: AddNoteProcessResult;
}

export interface MarkLeadStatusState {
  selectedStatus: string;
  enteredNote: string;
  leadStatusOptions: LeadStatusValue[];
  alertText?: string;
  alertType?: AlertType;
  isSubmitting: boolean;
  isLeadStatusValid: boolean;
  isNoteValid: boolean;
  isSubmitDisabled: boolean;
}

interface TriggerMarkProcessResult {
  success: boolean;
  resultDescription: string;
}

interface ClearTaskProcessResult {
  statusCode: number;
  responseMessage: string;
}

interface AddNoteProcessResult {
  autoLeadMessageId: number;
  autoLeadMessageNumberRowsAffected: number;
  autoLeadNumberRowsAffected: number;
}
