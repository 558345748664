import KeyInformationKbbIcoModalContent from "./kbbico-modal-content";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { envEnum } from "@vinsolutions/core/environment";
import { KeyInformationBannerModal } from "@vinsolutions/ui/key-information-banner";

export interface KeyInformationKbbIcoModalProps {
  offerGuid?: string;
  dealerGuid?: string;
  showModal: boolean;
  onHide: () => void;
  env?: envEnum;
}

const KeyInformationKbbIcoModal = ({
  offerGuid,
  dealerGuid,
  showModal,
  onHide,
  env
}: KeyInformationKbbIcoModalProps) => {
  return (
    <KeyInformationBannerModal showModal={showModal} onHide={onHide}>
      {!offerGuid || offerGuid === "" ? (
        <h1>No Kbb Instant Cash Offer found!</h1>
      ) : (
        <KeyInformationKbbIcoModalContent
          dealerGuid={dealerGuid}
          env={env}
          offerGuid={offerGuid}
        />
      )}
    </KeyInformationBannerModal>
  );
};

export default KeyInformationKbbIcoModal;
