import {
  RouteComponent,
  RoutePanePlacement
} from "@vinsolutions/ccrm/top-nav/route-config";
import { RouteComponentView, useRouteManager } from ".";
import styled from "styled-components";
import { RouteQueryParams } from "./render-view";

type DynamicPaneProps = {
  isLeftPane?: boolean;
  isPaneExpanded?: boolean;
  isOtherPaneExpanded?: boolean;
};

export const resolveColumn = (
  isLeftPane?: boolean,
  isPaneExpanded?: boolean
) => {
  if (isPaneExpanded) {
    return "1 / span 2";
  }
  return isLeftPane ? 1 : 2;
};

/**
 * Styled side panel for displaying React components in CarDashboard layout
 */
export const StyledSidePane = styled.div<DynamicPaneProps>`
  grid-column: ${props => {
    return resolveColumn(props.isLeftPane, props.isPaneExpanded);
  }};
  grid-row: 1;
  height: 100%;
  ${props => {
    if (props.isOtherPaneExpanded === true) {
      return "display: none";
    }
    return;
  }};
`;

function getPlacementId(placement: RoutePanePlacement) {
  switch (placement) {
    case RoutePanePlacement.RIGHT:
      return "right-side";
    case RoutePanePlacement.FULL:
      return "full-side-component";

    case RoutePanePlacement.LEFT:
    default:
      return "left-side";
  }
}

interface Props {
  component: RouteComponent;
  pane: RoutePanePlacement;
  routeId: string;
  isPaneExpanded: boolean;
  isOtherPaneExpanded: boolean;
  queryParams: RouteQueryParams;
  refreshTimeStamp: string;
  setIsExpanded: (arg0: boolean) => void;
}

export function ReactComponentPane({
  component,
  pane,
  routeId,
  isPaneExpanded,
  isOtherPaneExpanded,
  queryParams,
  refreshTimeStamp,
  setIsExpanded
}: Props) {
  const routeManager = useRouteManager();

  if (!routeManager) {
    return null;
  }

  const placementId = getPlacementId(pane);

  const expansionButtonPosition =
    routeManager?.getExpansionButtonPositionForPane(routeId, pane);

  return (
    <StyledSidePane
      data-testid={`${placementId}-pane`}
      id={`${placementId}-pane`}
      isLeftPane={pane === RoutePanePlacement.LEFT}
      isOtherPaneExpanded={isOtherPaneExpanded}
      isPaneExpanded={isPaneExpanded}
      key={refreshTimeStamp}
      style={{ visibility: "visible" }}
    >
      <RouteComponentView
        component={component}
        data-testid={`${placementId}-component`}
        expansionButtonPosition={expansionButtonPosition}
        isExpanded={isPaneExpanded}
        pane={pane}
        props={queryParams}
        setIsExpanded={expansionButtonPosition ? setIsExpanded : undefined}
      />
    </StyledSidePane>
  );
}
