export default function KbbIconSmall() {
  return (
    <svg
      height="25px"
      version="1.1"
      viewBox="0 0 25 25"
      width="25px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Kelley Blue Book</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="KbbIconSmall"
        stroke="none"
        strokeWidth="1"
      >
        <g id="KBB" transform="translate(5.000000, 5.000000)">
          <image
            height="16"
            id="favicon"
            width="16"
            x="0"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEKADAAQAAAABAAAAEAAAAAA0VXHyAAACWUlEQVQ4EWVSTWtTQRQ9L03SD2LQ1laLCnFhFy2FdCG6TBd1KZa6l7qpilB3booUKfhBFZcKov4BaV24UbQBN91IFTGIIA3FtkZp85KX9zWf3okmfU0G5r17L/ecc+fMWGhZ2StLOWi9oqSEYD544EK6FSivCmg1XszfzUch8Whi4sD+haMHgcFehqH+AOAMhfUAmzsKW7ut3UAbwfJMKVtygWQ6jbXtPmiaZOKMDVFzMJDS2eE88lEaK5r4X+ZyP2rpldsfRvFzNwHJJSTjYIGHQ4kSHk79wfCAGu8eXWiSNAn8tfnMtkysX3t9GlU/BikUFBfkAwOj8/vOb3Sybby6mcSJVOfJ7rH5ohGPmY9ZukPOvvx2DHYNEAQ0YMl53UgWOhCBg/Kug2dvK/Xef6ioB5bOfS91QYSczCZ1YdR9Gr8K7pEHrg1NeWGjE6DedgKqCBaSUhxSclIPwEMX3LfBnR1IItFaNXDNf+QWrGLoVbKe40EZAlITQa0OlD6NrWQTRCMUG8meB7A+T5zasYPyJswOaXN7C8Ir7wOfP5uyNfU2CJq3YAre17m1mcfl7JtVZx+o0Xxx/AAWpw9/6hlZGGvUmhOYgsXik0+u9uZnp9I4fmTvdCa+dakPi5f786anAa5joomJry/eu9CTwNK5oRAjgwKaaoUNhfcfGUKGyQd37i9HMXsy/6tPV5UNMvvFagcsqZGgh9QTBPAcQSNadhRs4jaCLrsCYwzXKOpYbDLuenAtvUS1jAG0rjaCFKlB6eV40pouvntkG8lM7saYYPp5nbmF4S/5O0ABShOnxwAAAABJRU5ErkJggg=="
            y="0"
          />
        </g>
      </g>
    </svg>
  );
}
