import { Badge, Button } from "cx";
import React from "react";
import { AboutConnectButton, AboutConnectButtonProps } from "../about-connect";

export interface CcrmAccountMenuLinksProps extends AboutConnectButtonProps {
  newsItemCount: number;
  onFeedbackLinkClick: () => void;
  onNewsLinkClick: () => void;
  onPrivacyPolicyLinkClick: () => void;
  onSupportLinkClick: () => void;
  onTermsOfUseLinkClick: () => void;
  onTrainingLinkClick: () => void;
}

export function CcrmAccountMenuLinks({
  cardashboardServerName,
  dealerId,
  dealerName,
  dealerStatus,
  newsItemCount,
  userFullName,
  userName,
  userRole,
  bridgeUsername,
  onFeedbackLinkClick,
  onNewsLinkClick,
  onPrivacyPolicyLinkClick,
  onSupportLinkClick,
  onTermsOfUseLinkClick,
  onTrainingLinkClick
}: CcrmAccountMenuLinksProps) {
  return (
    <>
      <Button
        buttonStyle="link"
        className="account-menu-link-button"
        htmlId="account-menu-news-link"
        onClick={onNewsLinkClick}
      >
        <span className="account-menu-link-button-text">News</span>
        {newsItemCount > 0 && (
          <Badge
            className="account-menu-link-button-badge"
            color="count"
            htmlId={`account-menu-news-badge`}
          >
            {newsItemCount}
          </Badge>
        )}
      </Button>
      <Button
        buttonStyle="link"
        className="account-menu-link-button"
        htmlId="account-menu-training-link"
        onClick={onTrainingLinkClick}
      >
        <span className="account-menu-link-button-text">Training</span>
      </Button>
      <Button
        buttonStyle="link"
        className="account-menu-link-button"
        htmlId="account-menu-support-link"
        onClick={onSupportLinkClick}
      >
        <span className="account-menu-link-button-text">Support</span>
      </Button>
      <Button
        buttonStyle="link"
        className="account-menu-link-button"
        htmlId="account-menu-feedback-link"
        onClick={onFeedbackLinkClick}
      >
        <span className="account-menu-link-button-text">Feedback</span>
      </Button>
      <Button
        buttonStyle="link"
        className="account-menu-link-button"
        htmlId="account-menu-terms-of-use-link"
        onClick={onTermsOfUseLinkClick}
      >
        <span className="account-menu-link-button-text">User Agreement</span>
      </Button>
      <Button
        buttonStyle="link"
        className="account-menu-link-button"
        htmlId="account-menu-privacy-policy-link"
        onClick={onPrivacyPolicyLinkClick}
      >
        <span className="account-menu-link-button-text">Privacy Policy</span>
      </Button>
      <AboutConnectButton
        {...{
          bridgeUsername,
          cardashboardServerName,
          dealerId,
          dealerName,
          dealerStatus,
          userFullName,
          userName,
          userRole
        }}
      />
    </>
  );
}

export default CcrmAccountMenuLinks;
