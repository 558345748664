import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { getNewsCount } from "@vinsolutions/ccrm/api";
import {
  NotificationsEntity,
  NotificationsState
} from "@vinsolutions/ccrm/interfaces";
import { parseFloatOrReturnZero } from "@vinsolutions/ccrm/util";

export const NOTIFICATIONS_FEATURE_KEY = "notifications";

/*
 * Update these interfaces according to your requirements.
 */

export const notificationsAdapter = createEntityAdapter<NotificationsEntity>();

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchNotifications())
 * }, [dispatch]);
 * ```
 */
export const fetchNotifications = createAsyncThunk(
  "notifications/fetchStatus",
  async (_, thunkAPI) => {
    const { Count } = await getNewsCount();
    const news = {
      id: "news",
      label: "Unread News Count",
      count: parseFloatOrReturnZero(Count)
    };
    return [news] as NotificationsEntity[];
  }
);

export const initialNotificationsState: NotificationsState =
  notificationsAdapter.getInitialState({
    loadingStatus: "not loaded",
    error: null
  });

export const notificationsSlice = createSlice({
  name: NOTIFICATIONS_FEATURE_KEY,
  initialState: initialNotificationsState,
  reducers: {
    add: notificationsAdapter.addOne,
    remove: notificationsAdapter.removeOne
    // ...
  },
  extraReducers: builder => {
    builder
      .addCase(fetchNotifications.pending, (state: NotificationsState) => {
        state.loadingStatus = "loading";
      })
      .addCase(
        fetchNotifications.fulfilled,
        (
          state: NotificationsState,
          action: PayloadAction<NotificationsEntity[]>
        ) => {
          notificationsAdapter.setAll(state, action.payload);
          state.loadingStatus = "loaded";
        }
      )
      .addCase(
        fetchNotifications.rejected,
        (state: NotificationsState, action) => {
          state.loadingStatus = "error";
          state.error = action.error.message || null;
        }
      );
  }
});

/*
 * Export reducer for store configuration.
 */
export const notificationsReducer = notificationsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(notificationsActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const notificationsActions = notificationsSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllNotifications);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = notificationsAdapter.getSelectors();

export const getNotificationsState = (rootState: any): NotificationsState =>
  rootState[NOTIFICATIONS_FEATURE_KEY];

export const selectAllNotifications = createSelector(
  getNotificationsState,
  selectAll
);

export const selectNotificationsEntities = createSelector(
  getNotificationsState,
  selectEntities
);
