export const vinconnectLight = {
  actionItem: "#2b6bdd",
  activeTabColor: "#fff",
  background: "#fff",
  buttonHover: "#173a78",
  divider: "#6d727a",
  abovePrimary: "#000",
  aboveSecondary: "#fff",
  aboveSurface: "#000",
  primary: "#0d2245",
  secondary: "#2053ab",
  secondaryActionItem: "#2053ab",
  secondaryIcon: "#2053ab",
  secondaryLabel: "#6d727a",
  surface: "#0d2245"
};
