import { initialize } from "./initialize";
import { interaction } from "./interaction";
import { standard } from "./standard";
import { NewRelicConfig } from "@vinsolutions/core-interfaces-third-party-newrelic";

export type { NewRelicConfig };
export const NewRelic = {
  initialize,
  interaction,
  ...standard
};
