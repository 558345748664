import { useSetupPostMsgListener } from "../listen";
import { ModalDialog } from "@vinsolutions/core/cx";
import SelectModal, { ModalMessage } from "./SelectModal";
// eslint-disable-next-line no-restricted-imports
import { useModalContext } from "./ModalIframe";
import { ModalsEventTypes } from "../types";

export const ModalListener = () => {
  const { hideModal, showModal, setModalInfo, modalProps } = useModalContext();

  useSetupPostMsgListener<ModalMessage>(
    ModalsEventTypes.modalOpen,
    setModalInfo
  );

  return (
    <ModalDialog
      htmlId="vinconnect-modal"
      show={showModal}
      onHide={hideModal}
      {...modalProps}
    >
      <SelectModal />
    </ModalDialog>
  );
};
