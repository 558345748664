import React from "react";
import { useModalContext } from "../../Modal/ModalIframe";
import { MarkLeadStatus } from "@vinsolutions/ccrm-lead-mark-lead-status";
import { useMountEffect } from "@vinsolutions/ccrm/util";
import { LeadStatus } from "@vinsolutions/ccrm-lead-mark-lead-status";
import { useCardashboardTabChannel } from "@vinsolutions/legacy-cardashboard/hooks";
import { BroadcastChannelNames } from "@vinsolutions/ccrm/constants";

export interface MarkLeadBadModalProps {
  autoLeadId: number;
}

export const MarkLeadBadModal = () => {
  const { hideModal, componentProps, setModalProps } = useModalContext();
  const cardashboardFrameChannel = useCardashboardTabChannel(
    BroadcastChannelNames.rightpaneframe
  );
  const autoLeadId: number =
    (componentProps && (componentProps as MarkLeadBadModalProps).autoLeadId) ||
    -1;

  const onSuccess = () => {
    // display the success message before invoking...
    setTimeout(() => {
      hideModal();
    }, 1000);
    cardashboardFrameChannel("reload");
  };

  useMountEffect(() => {
    setModalProps(modalProps => {
      return {
        ...modalProps,
        header: <h3>Bad Lead</h3>
      };
    });
  });

  return (
    <MarkLeadStatus
      leadId={autoLeadId}
      leadStatus={LeadStatus.Bad}
      onCancel={hideModal}
      onSuccess={onSuccess}
    />
  );
};

export default MarkLeadBadModal;
