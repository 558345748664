import {
  config as configFiles,
  configType,
  getEnvironmentName
} from "@vinsolutions/core/config";

export const getConfigFromEnvironment = (
  environmentName: string | number
): configType => {
  return configFiles[environmentName] as configType;
};

export const getCarDashboardBaseUrl = (url?: string) => {
  const environmentName = getEnvironmentName();
  const config = configFiles[environmentName] as configType;
  return config.carDashboardUrl;
};
