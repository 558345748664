import { Suspense, useMemo } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { Loading } from "../lib/pages";
import { VersionView } from "../lib/pages/version/version-lazy";
import { CardashboardLayout } from "@vinsolutions/ccrm/top-nav/main";
import { MarkLeadBadPage } from "../lib/pages/customer-dashboard";
import { useRouteManager } from "@vinsolutions/ccrm/top-nav/main";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("Routes");
/**
 * Component that renders the routes for Vinconnect
 * It contains some predefined adhoc routes and routes for menu items
 * The RouteProvider component builds a list of routes based on the contents of the routeConfig object.
 * @constructor
 */
export const Routes = () => {
  logger.debug("Routes: Initializing Routes.");
  const routeManager = useRouteManager();

  /**
   * New routes should be added to the route configuration in this file: libs/ccrm/top-nav/main/src/lib/router/route-list.ts
   * A route can be connected to a menu button by using the menu-button-id as the key to the entry in the route-list.ts file
   */
  const routeDefs: RouteObject[] = useMemo(() => {
    logger.debug("Routes: Initializing routeDefs.");
    return [
      { path: "/", element: <CardashboardLayout /> },
      { path: "/version", element: <VersionView /> },
      {
        path: "/pane-left/mark-lead-bad/:leadId",
        element: (
          <CardashboardLayout>
            <MarkLeadBadPage />
          </CardashboardLayout>
        )
      },
      { path: "*", element: <Navigate to={"/"} /> },
      ...routeManager.routeBuilder()
    ];
  }, [routeManager]);
  const routes = useRoutes(routeDefs);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};

export default Routes;
