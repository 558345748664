import { SlidingDrawer } from "@vinsolutions/core/components";
import { useSetupPostMsgListener } from "../listen";
import { useSlideDrawer } from "./SliderDrawerIframe";
import { SelectDrawer, SliderDrawerDetailData } from "./SelectDrawer";
import { ModalsEventTypes } from "../types";

const DrawerListener = () => {
  const { drawerProps, showDrawer, setDrawerInfo, hideDrawer } =
    useSlideDrawer();

  useSetupPostMsgListener<SliderDrawerDetailData>(
    ModalsEventTypes.sliderDrawerOpen,
    setDrawerInfo
  );

  return (
    <SlidingDrawer
      htmlId="vinconnect-drawer"
      panelWidth="85vw"
      show={showDrawer}
      onHide={hideDrawer}
      {...drawerProps}
    >
      <SelectDrawer />
    </SlidingDrawer>
  );
};

export default DrawerListener;
