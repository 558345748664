import { envEnum, getEnvironment } from "@vinsolutions/core/environment";
import { BridgeAuthClient } from "@bridge/auth-js";
import { AuthConfiguration, BridgeRuntime, bridge } from "@bridge/okta-auth";

const configurations: { [key: string]: AuthConfiguration } = {
  [envEnum.DEV]: {
    idp: bridge(BridgeRuntime.NON_PRODUCTION),
    clientId: "0oayz4bt15dd9covl357",
    scopes: [
      "bridge.signin",
      "openid",
      "cxm-private.cxm-ui-bff.insights.read",
      "cxm-private.cxm-ui-bff.insights.read"
    ],
    redirectUri: `${window.location.origin}/`
  },
  [envEnum.PRODUCTION]: {
    clientId: "0oayz4f6wjOvf2AfX357",
    idp: bridge(BridgeRuntime.PRODUCTION),
    scopes: [
      "bridge.signin",
      "openid",
      "cxm-private.cxm-ui-bff.insights.read",
      "cxm-private.cxm-ui-bff.insights.read"
    ],
    redirectUri: `${window.location.origin}/`
  }
};

export function getCurrentConfig(): AuthConfiguration | never {
  let environment = getEnvironment(window.location.hostname);
  if (environment !== envEnum.PRODUCTION) {
    environment = envEnum.DEV;
  }
  const config = configurations[environment];
  if (!config) {
    throw new Error(`Runtime ${JSON.stringify(environment)} is not supported`);
  }
  return config;
}

const crwAuthClient = BridgeAuthClient.buildFrom(getCurrentConfig(), {
  init: async authClient => {
    const hasExpiredAccessToken = await authClient.hasExpiredAccessToken();

    if (hasExpiredAccessToken) {
      authClient.clearStorage();
    }
  }
});

export default crwAuthClient;
