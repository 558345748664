import { KeyInformationBanner } from "@vinsolutions/ui/key-information-banner";
import OemInsightsBannerProps from "../../interfaces/oem-insights-banner-interface-props";
import HondaIconSmall, { HondaIconFill } from "./honda-icon";
import HondaInsightsSummary from "./honda-acura-insights-summary";
import { parseHondaAcuraInsights } from "../../data-parse/honda-acura/honda-acura-summary-data";
import { useEffect, useMemo, useState } from "react";
import OemInsightsDate from "../oem-banner-date";

const bannerName = "Honda Insights";
export const HondaBaseDisplayedBanner = {
  bannerName,
  iconName: "HondaIconSmall",
  thumbnailName: "HondaIconSmall"
};

export function HondaInsightsBanner({
  oemInsightsSummary,
  onLoad,
  onClick
}: OemInsightsBannerProps) {
  const [iconColor, setIconColor] = useState(HondaIconFill.HondaBlue);
  const hondaInsights = useMemo(
    () => parseHondaAcuraInsights(oemInsightsSummary, "honda"),
    [oemInsightsSummary]
  );
  useEffect(() => {
    onLoad({
      ...HondaBaseDisplayedBanner,
      isDisplayed: !!hondaInsights
    });
  }, [onLoad, hondaInsights]);
  return (
    hondaInsights && (
      <KeyInformationBanner
        bannerDataDetail={<OemInsightsDate oemInsights={hondaInsights} />}
        bannerIcon={<HondaIconSmall fillColor={iconColor} />}
        bannerName={bannerName}
        bannerSummary={<HondaInsightsSummary />}
        onClick={() => onClick && onClick(bannerName, "honda")}
        onMouseEnter={() => setIconColor(HondaIconFill.HondaWhite)}
        onMouseLeave={() => setIconColor(HondaIconFill.HondaBlue)}
      />
    )
  );
}

export default HondaInsightsBanner;
