import { PartyBar } from "@vinsolutions/ccrm/interfaces";
import { HttpResponse, fetchWrapper } from "@vinsolutions/core/http";
import { createLogger } from "@vinsolutions/logger";
import { getConfig } from "@vinsolutions/core/config";

const logger = createLogger("legacy");
const getBaseUrl = () => getConfig().carDashboardUrl + "/";

const legacyConfig = {
  headers: {
    timeout: 150000,
    headers: {
      Accept: `application/json; text/plain, */*`
    }
  }
};
// timeout function doesn't exist on fetch
export async function clockInOrOut() {
  const response = await fetchWrapper.getAsync<boolean>(
    `${getBaseUrl()}CarDashboard/services/topnav.asmx/ClockInOrOut`,
    legacyConfig.headers.headers
  );
  // For this service we don't evalute response.data since this service responce 200 with data
  // true or false so if we evaluate response.data and response is false the code take it as error
  // but its just the status of the clockInClockOut
  if (!response.hasError) {
    return Promise.resolve(response.data as boolean);
  } else {
    return Promise.reject(response.error);
  }
}

export async function getCarDashboardLoginStatus() {
  logger.debug("getCarDashboardLoginStatus: entering");
  try {
    const response = await fetchWrapper.getAsync(
      `${getBaseUrl()}CarDashboard/services/auth.ashx`,
      legacyConfig.headers.headers
    );
    const returnData = response.statusCode === 200;
    logger.debug(`getCarDashboardLoginStatus: ${returnData}`);
    return returnData;
  } catch (error) {
    logger.error("getCarDashboardLoginStatus: failed", error);
    return false;
  }
}

type GetUnreadNewsCountResponse = {
  Count: string;
};

export async function getNewsCount() {
  logger.debug("getNewsCount: entering");
  const response = await fetchWrapper.getAsync<GetUnreadNewsCountResponse>(
    `${getBaseUrl()}CarDashboard/services/topnav.asmx/GetUnreadNewsCount`,
    legacyConfig.headers.headers
  );
  if (response.data && !response.hasError) {
    logger.debug(`getNewsCount: ${response.data.Count}`);
    return Promise.resolve(response.data);
  } else {
    logger.error(`getNewsCount: ${response.error}`);
    return Promise.reject(response.error);
  }
}

export async function getPartyBar(dealerId: number, rangeMarker: number) {
  logger.debug("getPartyBar: entering");
  const response = (await fetchWrapper.getAsync(
    `${getBaseUrl()}CarDashboard/services/topnav.asmx/GetPartyBar?dealerID=${dealerId}&rangeMarker=${rangeMarker}`,
    legacyConfig.headers.headers
  )) as HttpResponse<PartyBar>;

  logger.debug(`getPartyBar: ${response.data}`);

  return response;
}

export interface GetUserStatusResponse {
  TimeClockEnabled: boolean;
  UserClockedIn: boolean;
  UserCurrentStatus: string;
  UserIsBusy: boolean;
}

export async function getUserStatus() {
  logger.debug("getUserStatus: entering");
  const response = await fetchWrapper.getAsync<GetUserStatusResponse>(
    `${getBaseUrl()}CarDashboard/services/topnav.asmx/GetUserStatus`,
    legacyConfig.headers.headers
  );
  if (response.data && !response.hasError) {
    logger.debug(`getUserStatus: `, response.data);
    return Promise.resolve(response.data);
  } else {
    logger.error(`getUserStatus: `, response.error);
    return Promise.reject(response.error);
  }
}

export function optOutOfTopNav(username: string, frameUrl: string) {
  logger.debug("optOutOfTopNav: entering");
  sessionStorage.setItem(`cardashboard.cxtopnav.optedout_${username}`, "true");
  logger.debug(`optOutOfTopNav: ${frameUrl}`);
  // Make sure URL isn't just /cardashboard/. CarDashboard will just redirect to another page before the querystring is read
  window.location.href = frameUrl;
}

export async function sendFeedbackEmail(message: string) {
  logger.debug("sendFeedbackEmail: entering");
  try {
    const { statusCode } = await fetchWrapper.postAsyncText(
      `${getBaseUrl()}CarDashboard/services/topnav.asmx/SendFeedbackEmail`,
      {
        type: "General",
        data: message
      },
      legacyConfig.headers.headers
    );

    logger.debug(`sendFeedbackEmail: ${statusCode}`);
    return {
      statusCode
    };
  } catch (error) {
    logger.error("sendFeedbackEmail: failed", error);
    return false;
  }
}

type SetDealerResponse = {
  dealerID: number;
};
export async function setSelectedDealer(dealerId: number, source?: string) {
  logger.debug("setSelectedDealer: entering");
  const response = await fetchWrapper.getAsync<SetDealerResponse>(
    `${getBaseUrl()}CarDashboard/services/topnav.asmx/SetDealer?dealerID=${dealerId}&source=${
      source ? source : "top-nav"
    }`,
    legacyConfig.headers.headers
  );

  logger.debug(`setSelectedDealer: ${response.data}`);

  return response;
}

interface UpdateUserStatusResponse extends GetUserStatusResponse {
  UserCurrentStatus: string;
  UserIsBusy: boolean;
}

export async function updateUserStatus(statusText: string, busy: boolean) {
  logger.debug("updateUserStatus: entering");
  const response = await fetchWrapper.getAsync<UpdateUserStatusResponse>(
    `${getBaseUrl()}CarDashboard/services/topnav.asmx/UpdateUserStatus?statusText=${statusText}&busy=${busy}`,
    legacyConfig.headers.headers
  );
  if (response.data && !response.hasError) {
    const { UserCurrentStatus, UserIsBusy } = response.data;
    logger.debug(`updateUserStatus: ${UserCurrentStatus}, ${UserIsBusy}`);
    return Promise.resolve({
      userCurrentStatus: UserCurrentStatus,
      userIsBusy: UserIsBusy
    });
  } else {
    logger.error(`updateUserStatus: ${response.error}`);
    return Promise.reject(response.error);
  }
}
