import { shallowEqual, useSelector } from "react-redux";
import { getThemeState } from "@vinsolutions/ccrm/store";
import { StyledNavigationTabs } from "../navigation-tabs";
import { Menu } from "antd";
import { DynamicMenu } from "@vinsolutions/ccrm/interfaces";
import { NavigationTabButton } from "../navigation-buttons";
import { useDynamicHeader } from "./use-dynamic-header";

const buildMenuItems = (dynamicMenu: DynamicMenu | null | undefined) => {
  return dynamicMenu?.dynamicMenuItems?.map(i => ({
    label: (
      <NavigationTabButton
        id={i.id}
        label={i.label}
        target={""}
        url={i.url || "#"}
      />
    ),
    key: i.id
  }));
};

/**
 * Component for the DynamicHeader(skinny-top-nav) navigation tabs
 * @constructor
 */
export const DynamicNavigationTabs = () => {
  const { abovePrimary } = useSelector(getThemeState, shallowEqual);
  const { dynamicMenu } = useDynamicHeader();
  const menuItems = buildMenuItems(dynamicMenu);
  return (
    <StyledNavigationTabs {...{ abovePrimary }}>
      <Menu items={menuItems} mode="horizontal" />
    </StyledNavigationTabs>
  );
};
