import { Component } from "react";
import { Alert } from "cx";
import { ERROR_MSG } from "../../constants/messages.constant";

interface DMCErrorBoundaryProps {
  children: JSX.Element | JSX.Element[];
  forceError?: boolean;
  message?: string;
}

interface DMCErrorBoundaryState {
  hasError: boolean;
}

export class DMCErrorBoundary extends Component<
  DMCErrorBoundaryProps,
  DMCErrorBoundaryState
> {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  override state = { hasError: false };

  override componentDidCatch() {
    // TrackJS will log errors. It's pre-configured via CX, so nothing extra to do here. :)
  }

  override render() {
    if (this.state.hasError || this.props.forceError)
      return (
        <Alert
          data-testid="dealer-manufacturer-codes-error-boundary-alert"
          htmlId="dealer-manufacturer-codes-error-boundary-alert"
          type="danger"
        >
          {this.props.message || ERROR_MSG}
        </Alert>
      );
    return this.props.children;
  }
}
