import { shallowEqual, useSelector } from "react-redux";
import { getDealerDashboardSliceState } from "../dealer-dashboard.slice";
import { useGetUserSettingsQuery, useSetUserSettingsMutation } from "../rtk";
import { DateRangeType, LeadType, ResponseTimeType } from "../enums";
import { useCallback, useEffect, useState } from "react";
import { UserSettings } from "../models";

export function useGetUserSettings() {
  const [updateSettings, setUpdateSettings] = useState<Partial<UserSettings>>();

  const { jwt, dealerId } = useSelector(
    getDealerDashboardSliceState,
    shallowEqual
  );

  const [setUserSettings] = useSetUserSettingsMutation();

  const {
    currentData: userSettings,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    isUninitialized
  } = useGetUserSettingsQuery(
    {
      jwt,
      dealerId,
      request: undefined
    },
    {
      skip: jwt === "" || dealerId === 0
    }
  );

  useEffect(() => {
    if (updateSettings !== undefined) {
      setUserSettings({
        jwt,
        dealerId,
        request: { ...userSettings, ...updateSettings } as UserSettings
      });
    }
  }, [updateSettings]);

  const userGroupIdOrUserId = useCallback(() => {
    return userSettings?.userId && userSettings.userId > 0
      ? userSettings.userId
      : (userSettings?.userGroupId ?? 0).valueOf();
  }, [userSettings?.userGroupId, userSettings?.userId]);

  return {
    userSettings,
    dateRangeType: userSettings?.dateRangeType ?? DateRangeType.TODAY,
    leadType: userSettings?.opportunityType ?? LeadType.ALL,
    responseTimeType: userSettings?.responseTimeType ?? ResponseTimeType.ACTUAL,
    userGroupIdOrUserId: userGroupIdOrUserId(),
    userId: userSettings?.userId,
    userGroupId: userSettings?.userGroupId ?? 0,
    isSuccess: isSuccess && !isLoading && !isFetching && !isUninitialized,
    isError,
    setUserSettings: setUpdateSettings
  };
}
