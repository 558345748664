import { IVinManagerInventoryMaster } from "./vinmanager-inventory-master";
import { communicationsNavigationItemEntityIds } from "./navigation-ids";
import { generateDestinationUrl } from "./vinmanager-inventory-master";
import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";

export const getAllFacebookNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  const facebookNavigationItemsEntities: NavigationItemsEntity[] = [
    {
      id: communicationsNavigationItemEntityIds.facebookLog,
      label: "Facebook Log",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/rims2.aspx",
          leftQueryString: {
            urlSettingName: "brawndo.communicationsui.url",
            type: "facebook",
            direction: "Inbound",
            mp:
              vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging ??
              false
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.facebookMessenger,
      label: "Facebook Messenger",
      url: "#",
      target: "_self",
      type: "COMMUNICATIONS"
    }
  ];

  return facebookNavigationItemsEntities;
};

const filterFacebookNavigationItems = (
  facebookNavigationItems: NavigationItemsEntity[],
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  let validItems = [] as NavigationItemsEntity[];

  validItems = validItems.concat(facebookNavigationItems);

  if (
    !vinManagerInventoryMaster.IsDealerEnabledForAnyFacebookFeature ||
    !vinManagerInventoryMaster.HasFacebookGranularPermission
  ) {
    validItems = validItems.filter(
      item =>
        item.id !== communicationsNavigationItemEntityIds.facebookLog &&
        item.id !== communicationsNavigationItemEntityIds.facebookMessenger
    );
  }

  return validItems;
};

export const buildFacebookNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  const allFacebookNavigationItems = getAllFacebookNavigationItems(
    vinManagerInventoryMaster
  );

  const filteredFacebookNavigationItems = filterFacebookNavigationItems(
    allFacebookNavigationItems,
    vinManagerInventoryMaster
  );

  const facebookNavigationEntities: NavigationEntity[] | null =
    filteredFacebookNavigationItems.length > 0
      ? filteredFacebookNavigationItems.map(item => {
          return {
            id: item.id
          } as NavigationEntity;
        })
      : null;

  return {
    facebookNavigationItemEntities: filteredFacebookNavigationItems,
    facebookNavigationEntities
  };
};
