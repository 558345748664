import { Config, Options } from "@vinsolutions/ccrm/interfaces";
// Curious if i should split these out into separate object literals or if that makes things too complex...

export const adminMetricPropDictionary: {
  [index: string]: (args: Options) => Config;
} = {
  CancelledAppointments: ({
    dealerId,
    EndDate,
    StartDate
  }: Partial<Options>) => {
    return {
      usedForTotal: false,
      GAContextLog: {
        action: "Party Bar Appts Cancelled button clicked",
        label: "Appointment Report page displayed"
      },
      label: "Cancel",
      parent: "adminAppointments",
      sortValue: 4,
      url:
        "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=Reports" +
        `/AppointmentReport2.aspx&variableleft=startDate|${StartDate}|endDate|${EndDate}|dealerID|${dealerId}|Status|CANCEL&rightpaneframe=DealerDashboard1.aspx`
    } as Config;
  },
  CompletedAppointments: ({
    dealerId,
    EndDate,
    StartDate
  }: Partial<Options>) => {
    return {
      usedForTotal: false,
      GAContextLog: {
        action: "Party Bar Appts Complete button clicked",
        label: "Appointment Report page displayed"
      },
      label: "Complete",
      parent: "adminAppointments",
      sortValue: 3,
      url:
        "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=Reports" +
        `/AppointmentReport2.aspx&variableleft=startDate|${StartDate}|endDate|${EndDate}|dealerID|${dealerId}|Status|COMPLETE&rightpaneframe=DealerDashboard1.aspx`
    } as Config;
  },
  ConfirmedAppointments: ({
    StartDate,
    EndDate,
    dealerId
  }: Partial<Options>) => {
    return {
      usedForTotal: false,
      GAContextLog: {
        action: "Party Bar Appts Confirmed button clicked",
        label: "Appointment Report page displayed"
      },
      label: "Confirm",
      parent: "adminAppointments",
      sortValue: 2,
      url:
        "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=Reports/AppointmentReport2.aspx&" +
        `variableleft=startDate|${StartDate}|endDate|${EndDate}|dealerID|${dealerId}|Status|SET|SearchMethod|Confirm&rightpaneframe=DealerDashboard1.aspx`
    } as Config;
  },
  Demos: ({ EndDate, StartDate }: Options) => {
    return {
      usedForTotal: false,
      GAContextLog: {
        action: "Party Bar Showroom Demos button clicked",
        label: "Desk Log page with demos filter displayed"
      },
      label: "Demos",
      parent: "showroom",
      sortValue: 5,
      url:
        "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DeskLog.aspx" +
        `&variableleft=LeadVisitEvent|1|desklogfrom|${StartDate}|desklogto|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|ShowDemo`
    } as Config;
  },
  InboundCalls: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Activity In Phone button clicked",
        label: "Inbound Calls page displayed"
      },
      label: "Phone In",
      parent: "activity",
      sortValue: 4,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DailyLeadDetail.aspx` +
        `&variableleft=PageDisplayType|8|from|${StartDate}|to|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|PhIn`
    } as Config;
  },
  InboundEmails: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Activity In Email button clicked",
        label: "Inbound Email page displayed"
      },
      label: "Email In",
      parent: "activity",
      sortValue: 1,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DailyLeadDetail.aspx` +
        `&variableleft=PageDisplayType|6|from|${StartDate}|to|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|EmailIn`
    } as Config;
  },
  InternetLeads: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar New Leads Internet button clicked",
        label: "Desk Log page with internet lead filter displayed"
      },
      label: "Internet",
      parent: "leads",
      sortValue: 1,
      url: `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DeskLog.aspx&variableleft=LeadType|1|desklogfrom|${StartDate}|desklogto|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|ILead`
    } as Config;
  },
  MGRTOs: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: false,
      GAContextLog: {
        action: "Party Bar Showroom Mgrs Tos button clicked",
        label: "Desk Log page displayed"
      },
      label: "Mgrs Tos",
      parent: "showroom",
      sortValue: 3,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DeskLog.aspx` +
        `&variableleft=LeadVisitEvent|2|desklogfrom|${StartDate}|desklogto|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|MgrTos`
    } as Config;
  },
  MissedAppointments: ({ StartDate, EndDate, dealerId }: Partial<Options>) => {
    return {
      usedForTotal: false,
      GAContextLog: {
        action: "Party Bar Appts Missed button clicked",
        label: "Appointment Report page displayed"
      },
      label: "Missed",
      parent: "adminAppointments",
      sortValue: 5,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=Reports/AppointmentReport2.aspx` +
        `&variableleft=startDate|${StartDate}|endDate|${EndDate}|dealerID|${dealerId}|Status|MISSED&rightpaneframe=DealerDashboard1.aspx`
    } as Config;
  },
  OutboundCalls: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Activity Out Phone button clicked",
        label: "Outbound Calls page displayed"
      },
      label: "Phone Out",
      parent: "activity",
      sortValue: 5,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DailyLeadDetail.aspx` +
        `&variableleft=PageDisplayType|7|from|${StartDate}|to|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|PhOut`
    } as Config;
  },
  OutboundEmails: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Activity Out Email button clicked",
        label: "Outbound Email page displayed"
      },
      label: "Email Out",
      parent: "activity",
      sortValue: 2,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DailyLeadDetail.aspx` +
        `&variableleft=PageDisplayType|5|from|${StartDate}|to|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|EmailOut`
    } as Config;
  },
  PencilCount: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: false,
      GAContextLog: {
        action: "Party Bar Showroom Desked button clicked",
        label: "Desk Log page displayed"
      },
      label: "Desked",
      parent: "showroom",
      sortValue: 2,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DeskLog.aspx` +
        `&variableleft=LeadVisitEvent|128|desklogfrom|${StartDate}|desklogto|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|ShowDesked`
    } as Config;
  },
  PhoneLeads: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar New Leads Phone button clicked",
        label: "Desk Log page with phone lead filter displayed"
      },
      label: "Phone",
      parent: "leads",
      sortValue: 3,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DeskLog.aspx` +
        `&variableleft=LeadType|3|desklogfrom|${StartDate}|desklogto|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|PLead`
    } as Config;
  },
  ReadEmails: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: false,
      GAContextLog: {
        action: "Party Bar Activity Read button clicked",
        label: "Emails Read page displayed"
      },
      label: "Read",
      parent: "activity",
      sortValue: 3,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=EmailsRead.aspx` +
        `&variableleft=from|${StartDate}|to|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|EmailRead`
    } as Config;
  },
  SetAppointments: ({ dealerId, EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Appts Set button clicked",
        label: "Appointment Report page displayed"
      },
      label: "Scheduled",
      parent: "adminAppointments",
      sortValue: 1,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=Reports/AppointmentReport2.aspx` +
        `&variableleft=startDate|${StartDate}|endDate|${EndDate}|dealerID|${dealerId}|Status|SET&rightpaneframe=DealerDashboard1.aspx`
    } as Config;
  },
  Sold: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: false,
      GAContextLog: {
        action: "Party Bar Showroom Sold button clicked",
        label: "Sold Log page displayed"
      },
      label: "Sold",
      parent: "showroom",
      sortValue: 1,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=/CarDashboard/Pages/CRM/SoldLog.aspx` +
        `&variableleft=type|R|datefrom|${StartDate}|dateto|${EndDate}|fromdashboard|1&rightpaneframe=DealerDashboard1.aspx`
    } as Config;
  },
  Visits: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Showroom Visits button clicked",
        label: "Desk Log page with all visit types filter displayed"
      },
      label: "Visits",
      parent: "showroom",
      sortValue: 6,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DeskLog.aspx` +
        `&variableleft=LeadType|SV|desklogfrom|${StartDate}|desklogto|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|Visits`
    } as Config;
  },
  WalkinLeads: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar New Leads Walk Ins button clicked",
        label: "Desk Log page with walk ins lead filter displayed"
      },
      label: "Walk Ins",
      parent: "leads",
      sortValue: 4,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DeskLog.aspx` +
        `&variableleft=LeadType|2|desklogfrom|${StartDate}|desklogto|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|WLead`
    } as Config;
  },
  Writeups: ({ EndDate, StartDate }: Partial<Options>) => {
    return {
      usedForTotal: false,
      GAContextLog: {
        action: "Party Bar Showroom Write Ups button clicked",
        label: "Desk Log page with write up filter displayed"
      },
      label: "Write Ups",
      parent: "showroom",
      sortValue: 4,
      url:
        `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=DeskLog.aspx` +
        `&variableleft=LeadVisitEvent|4|desklogfrom|${StartDate}|desklogto|${EndDate}&rightpaneframe=DealerDashboard1.aspx&variableright=SubTarget|WriteUps`
    } as Config;
  }
};

export const metricPropDictionary: {
  [index: string]: (args: Options) => Config;
} = {
  Alerts: () => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Tasks Alerts button clicked",
        label: "Alerts page displayed"
      },
      label: "Alerts",
      parent: "tasks",
      sortValue: 1,
      url: "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=AlertTasks.aspx"
    } as Config;
  },
  NewLeads: () => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Tasks New Leads button clicked",
        label: "My Tasks page with new tab selected displayed"
      },
      label: "New Leads",
      parent: "tasks",
      sortValue: 2,
      url: `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=ActiveLeads_WorkList.aspx&variableleft=View|New`
    } as Config;
  },
  Replies: () => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Tasks Replies button clicked",
        label: "My Tasks page with replies tab selected displayed"
      },
      label: "Replies",
      parent: "tasks",
      sortValue: 3,
      url: `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=ActiveLeads_WorkList.aspx&variableleft=View|Replies`
    } as Config;
  },
  FollowUps: () => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Tasks Tasks Follow Ups button clicked",
        label: "My Tasks page with follow ups tab displayed"
      },
      label: "Follow Ups",
      parent: "tasks",
      sortValue: 4,
      url: "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=ActiveLeads_WorkList.aspx&variableleft=View|Followups"
    } as Config;
  },
  OverdueTasks: () => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Tasks Over Due Tasks button clicked",
        label: "My Tasks page with overdue tab displayed"
      },
      label: "Over Due Tasks",
      parent: "tasks",
      sortValue: 5,
      url: `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=ActiveLeads_WorkList.aspx&variableleft=View|Overdue`
    } as Config;
  },
  PastDueAppointments: () => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Appts Past Due button clicked",
        label: "My Tasks page displayed"
      },
      label: "Past Due",
      parent: "appointments",
      sortValue: 1,
      url: `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=ActiveLeads_WorkList.aspx`
    } as Config;
  },
  ScheduledAppointments: () => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Appts Scheduled button clicked",
        label: "My Tasks page displayed"
      },
      label: "Scheduled",
      parent: "appointments",
      sortValue: 2,
      url: `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=ActiveLeads_WorkList.aspx`
    } as Config;
  },
  ServiceAppointments: () => {
    return {
      usedForTotal: true,
      GAContextLog: {
        action: "Party Bar Appts Service button clicked",
        label: "My Tasks page displayed"
      },
      label: "Service",
      parent: "appointments",
      sortValue: 3,
      url: `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=ActiveLeads_WorkList.aspx`
    } as Config;
  }
};
