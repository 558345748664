import { LoadingIndicator } from "@vinsolutions/core/components";
import styled from "styled-components";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("Loading");

const StyledDisplay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;

export function Loading() {
  logger.debug("Loading: Initializing Loading.");
  return (
    <StyledDisplay>
      <LoadingIndicator action="suspense-route" htmlId="loading-path" />
    </StyledDisplay>
  );
}
export const path = "loading";
