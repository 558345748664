import { useEffect, useRef } from "react";
import {
  PortalContainerClass,
  PortalParentHostClass
} from "../styles/portal-style-classes";
import PortalComponentProps, { RegisteredPortal } from "../interfaces/portals";

/**
 * Creates DOM element to be used as React root.
 * @returns {HTMLElement}
 */
function createRootElement(id: string): HTMLElement {
  const rootContainer = document.createElement("div");
  rootContainer.setAttribute("id", id);
  rootContainer.classList.add(PortalParentHostClass);
  return rootContainer;
}

/**
 * Appends element as last child of body.
 * @param {HTMLElement} rootElem
 */
function addRootElement(rootElem: Element) {
  if (!document.body.lastElementChild) return;
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild.nextElementSibling
  );
}

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 * @param {RegisteredPortal<PortalComponentProps>} registeredPortal The registered portal to render
 * @param {HTMLElementTagNameMap} portalContainerElementTag Optional element tag type to use for the portal container (default is 'div')
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 */
const usePortal = (
  registeredPortal: RegisteredPortal<PortalComponentProps>,
  portalContainerElementTag?: keyof HTMLElementTagNameMap
): HTMLElement => {
  const rootElemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Parent is either a new root or the existing dom element
    const parentElem =
      registeredPortal.originElement ||
      createRootElement(registeredPortal.portalId);
    // If there is no existing DOM element, add a new one.
    if (!registeredPortal.originElement) {
      addRootElement(parentElem);
    }

    if (!rootElemRef.current) return;
    const portalContainer = rootElemRef.current;
    parentElem.classList.add(PortalParentHostClass);

    if (!registeredPortal.isHidden) {
      registeredPortal.replacedOriginChildren = Array.from(
        parentElem.querySelectorAll(`:scope > *:not( .${PortalContainerClass})`)
      );
      parentElem.replaceChildren();
    } else if (
      registeredPortal.replacedOriginChildren &&
      registeredPortal.replacedOriginChildren.length
    ) {
      parentElem.replaceChildren(...registeredPortal.replacedOriginChildren);
      registeredPortal.replacedOriginChildren = [];
    }
    if (!registeredPortal.isHidden) {
      parentElem.appendChild(portalContainer);
    }
    return () => {
      portalContainer?.remove();
    };
  }, [
    rootElemRef,
    registeredPortal,
    registeredPortal.originElement,
    registeredPortal.isHidden,
    registeredPortal.portalId
  ]);

  /**
   * It's important we evaluate this lazily:
   * - We need first render to contain the DOM element, so it shouldn't happen
   *   in useEffect. We would normally put this in the constructor().
   * - We can't do 'const rootElemRef = useRef(document.createElement('div))',
   *   since this will run every single render (that's a lot).
   * - We want the ref to consistently point to the same DOM element and only
   *   ever run once.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  const getRootElem = () => {
    if (!rootElemRef.current) {
      const portalContainer = document.createElement(
        portalContainerElementTag || "div"
      );
      portalContainer.setAttribute(
        "id",
        `${registeredPortal.portalId}-container`
      );
      portalContainer.classList.add(PortalContainerClass);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (rootElemRef as any).current = portalContainer;
    }
    return rootElemRef.current;
  };

  return getRootElem() as HTMLElement;
};

export default usePortal;
