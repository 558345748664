import React from "react";
import styled from "styled-components";
import {
  RouteButtonPosition,
  RouteComponent,
  RoutePanePlacement
} from "@vinsolutions/ccrm/top-nav/route-config";
import { RouteQueryParams } from "./render-view";
import { Button } from "@interstate/components/Button";
import { ChevronLeftIcon } from "@interstate/components/Icons/ChevronLeftIcon";
import { ChevronRightIcon } from "@interstate/components/Icons/ChevronRightIcon";
import { Tooltip } from "@interstate/components/Tooltip";

type DynamicPaneProps = {
  buttonPosition: RouteButtonPosition;
};

function resolveHorizontal(buttonPosition: RouteButtonPosition) {
  return buttonPosition === RouteButtonPosition.TOP_LEFT ||
    buttonPosition === RouteButtonPosition.BOTTOM_LEFT
    ? "left: 20px"
    : "right: 20px";
}

function resolveVertical(buttonPosition: RouteButtonPosition) {
  return buttonPosition === RouteButtonPosition.TOP_LEFT ||
    buttonPosition === RouteButtonPosition.TOP_RIGHT
    ? "top: 10px"
    : "bottom: 10px";
}

const StyledDiv = styled.div<DynamicPaneProps>`
  && {
    position: absolute;
    ${props => {
      return resolveHorizontal(props.buttonPosition);
    }};
    ${props => {
      return resolveVertical(props.buttonPosition);
    }};

    background-color: white;
  }
`;

export interface RouteComponentViewProps {
  component: RouteComponent;
  expansionButtonPosition?: RouteButtonPosition;
  pane: RoutePanePlacement;
  props: RouteQueryParams;
  isExpanded?: boolean;
  setIsExpanded?: (arg0: boolean) => void;
}

export const StyledView = styled.div`
  height: 100%;
  position: relative;
`;

/**
 * Used to display the components for a route
 * @prop {string | JSX.Element}  component The component to display
 * @prop {RouteButtonPosition}  expansionButtonPosition Where the expansion arrow will be shown if pane supports it
 * @prop {boolean} isExpanded Indicates if the pane is currently expanded
 * @prop {RoutePanePlacement} pane The pane the component should display in
 * @prop {object}  props The props passed through from the URL
 * @prop {(arg0: boolean) => void} setIsExpanded The setting function for isExpanded, or undefined if this pane doesn't support expansion
 * @constructor
 */
export const RouteComponentView = ({
  component,
  expansionButtonPosition,
  isExpanded,
  pane,
  props,
  setIsExpanded
}: RouteComponentViewProps) => {
  if (!component) {
    return null;
  }

  if (typeof component === "string") {
    return null;
  }

  const clonedComponent = React.cloneElement(component as React.ReactElement, {
    ...props
  });

  function expandIcon(pane: RoutePanePlacement, isExpanded?: boolean) {
    if (isExpanded) {
      return pane === RoutePanePlacement.LEFT ? (
        <ChevronLeftIcon />
      ) : (
        <ChevronRightIcon />
      );
    }

    return pane === RoutePanePlacement.LEFT ? (
      <ChevronRightIcon />
    ) : (
      <ChevronLeftIcon />
    );
  }

  return (
    <StyledView
      data-testid={`${pane}-component-jsx`}
      id={`${pane}-component-jsx`}
    >
      {clonedComponent}
      {expansionButtonPosition !== undefined && setIsExpanded !== undefined && (
        <StyledDiv buttonPosition={expansionButtonPosition}>
          <Tooltip
            position={
              expansionButtonPosition === RouteButtonPosition.TOP_LEFT ||
              expansionButtonPosition === RouteButtonPosition.BOTTOM_LEFT
                ? "right"
                : "left"
            }
            size="small"
            toolTipContent={isExpanded ? "Collapse" : "Expand"}
          >
            <Button
              autoFocus={true}
              buttonStyle="secondary"
              icon={expandIcon(pane, isExpanded)}
              size="small"
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </Tooltip>
        </StyledDiv>
      )}
    </StyledView>
  );
};
