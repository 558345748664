import { useMemo } from "react";
import styled from "styled-components";

export interface VinessaModalContentProps {
  customerId?: number;
  dealerId?: number;
}

export const CARDASHBOARD_RIMS_BASEPATH = "/CarDashboard/Pages/rims2.aspx";
export const VINESSA_SETTING_NAME = "Vin.Edison.Vinessa.keyinfomodal.url";

const StyledVinessaModalContent = styled.iframe`
  width: 100%;
  height: 600px;
  border: none;
`;

const VinessaModalContent = ({
  customerId,
  dealerId
}: VinessaModalContentProps) => {
  const iframeUrl = useMemo(() => {
    return customerId
      ? `${CARDASHBOARD_RIMS_BASEPATH}?urlSettingName=${VINESSA_SETTING_NAME}&customerId=${customerId}&dealerId=${dealerId}`
      : "";
  }, [customerId, dealerId]);

  return (
    iframeUrl && (
      <StyledVinessaModalContent
        id="VinessaKeyInfoIframe"
        src={iframeUrl}
        title="Vinessa-Modal"
      />
    )
  );
};

export default VinessaModalContent;
