import { KeyInformationBanner } from "@vinsolutions/ui/key-information-banner";
import OemInsightsBannerProps from "../../interfaces/oem-insights-banner-interface-props";
import AcuraIconSmall, { AcuraIconFill } from "./acura-icon";
import AcuraInsightsSummary from "./honda-acura-insights-summary";
import { parseHondaAcuraInsights } from "../../data-parse/honda-acura/honda-acura-summary-data";
import { useEffect, useMemo, useState } from "react";
import OemInsightsDate from "../oem-banner-date";

const bannerName = "Acura Insights";
export const AcuraBaseDisplayedBanner = {
  bannerName,
  iconName: "AcuraIconSmall",
  thumbnailName: "AcuraIconSmall"
};

export function AcuraInsightsBanner({
  oemInsightsSummary,
  onLoad,
  onClick
}: OemInsightsBannerProps) {
  const [iconColor, setIconColor] = useState<AcuraIconFill>(
    AcuraIconFill.AcuraBlack
  );
  const acuraInsights = useMemo(
    () => parseHondaAcuraInsights(oemInsightsSummary, "acura"),
    [oemInsightsSummary]
  );
  useEffect(() => {
    onLoad({
      ...AcuraBaseDisplayedBanner,
      isDisplayed: !!acuraInsights
    });
  }, [onLoad, acuraInsights]);
  return (
    acuraInsights && (
      <KeyInformationBanner
        bannerDataDetail={<OemInsightsDate oemInsights={acuraInsights} />}
        bannerIcon={<AcuraIconSmall fillColor={iconColor} />}
        bannerName={bannerName}
        bannerSummary={<AcuraInsightsSummary />}
        onClick={() => onClick && onClick(bannerName, "acura")}
        onMouseEnter={() => setIconColor(AcuraIconFill.AcuraWhite)}
        onMouseLeave={() => setIconColor(AcuraIconFill.AcuraBlack)}
      />
    )
  );
}

export default AcuraInsightsBanner;
