// TODO remove the next file when all slices have been moved to data-access library
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
export const PROFILE_FEATURE_KEY = "profile";

export const fetchProfile = createAsyncThunk(
  "profile/fetchStatus",
  async (_: string, { getState }) => {
    const state = getState() as ProfileState;

    return state.dealerId;
  }
);

export interface ProfileState {
  dealerId: number | null;
}

export const initialProfileState: ProfileState = {
  dealerId: null
};

const profileSlice = createSlice({
  name: PROFILE_FEATURE_KEY,
  initialState: initialProfileState,
  reducers: {}
});

export const profileReducer = profileSlice.reducer;

export const getProfileState = (rootState: any): ProfileState =>
  rootState[PROFILE_FEATURE_KEY];
