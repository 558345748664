import styled from "styled-components";
import { Button, IconWarning } from "@vinsolutions/core/cx";

export const StyledGrid = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 94px 186px 400px 186px 94px 1fr;
  grid-template-rows: 2fr min-content 35px 1fr 2fr;
  grid-template-areas:
    ". . . . . . ."
    ". warningLeft . errorHeader . warningRight ."
    ". . . . . . ."
    ". errorText errorText errorText errorText errorText ."
    ". . . . . . .";
  background-color: #ffffff;
`;

export const StyledErrorHeader = styled.h2`
  place-self: center;
  margin-bottom: 0px;
  font-size: 48pt;
  grid-area: errorHeader;
`;

export const StyledErrorText = styled.span`
  text-align: center;
  font-size: 24pt;
  grid-area: errorText;
`;

export const LeftWarningIcon = styled(IconWarning)`
  grid-area: warningLeft;
  color: #fcc63f;
  height: 94px;
  width: 94px;
  vertical-align: middle;
  flex-shrink: 0;
`;

export const RightWarningIcon = styled(IconWarning)`
  grid-area: warningRight;
  color: #fcc63f;
  height: 94px;
  width: 94px;
  vertical-align: middle;
  flex-shrink: 0;
`;

export const StyledFailureState = styled.div`
  color: #16171a;
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: Roboto, sans-serif;
  font-weight: 400;
`;
export const StyledFailureStateContent = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 75%;
`;
export const StyledErrorMessage = styled.pre`
  text-align: justify;
  font-family: calibri, arial, sans-serif;
  display: inline;
`;
export const AllButtons = styled(Button)`
  font-size: 24px;
  padding: 30px;
`;
export const StyledShowErrorButton = styled(AllButtons)``;
export const StyledReturnButton = styled(AllButtons)``;
export const StyledHeader = styled.h2`
  display: flex;
  padding-bottom: 40px;
  justify-content: center;
  font-size: 48px;
  line-height: 93px;
`;
export const WarningIcon = styled(IconWarning)`
  color: #fcc63f;
  height: 93px;
  width: 93px;
  vertical-align: middle;
  flex-shrink: 0;
`;
export const StyledHeaderText = styled.span`
  padding-left: 20px;
  padding-right: 20px;
`;
export const StyledSubHeader = styled.h4`
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
`;
export const StyledStackTrace = styled.p`
  margin-bottom: auto;
`;
export const CenteredFlex = styled.div`
  display: flex;
  justify-content: center;
`;
export const GrownFlex = styled.div`
  flex-grow: 1;
`;

export function getStackTraceMessage(error: Error | string) {
  if (typeof error === "string") {
    return <></>;
  } else if (error.stack)
    return error.stack
      .split("\n")
      .map(str => <StyledStackTrace key={str}>{str}</StyledStackTrace>);
  return <></>;
}
export function getErrorMessage(error: Error | string) {
  if (typeof error === "string") {
    return error;
  }
  const message = error.message ?? JSON.stringify(error);
  return message;
}
