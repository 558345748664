import { useMemo } from "react";
import styled from "styled-components";

export interface BuyingSignalsModelContentProps {
  customerId: number;
  dealerId: number;
}
export const CARDASHBOARD_RIMS_BASEPATH = "/CarDashboard/Pages/rims2.aspx";
export const BUYING_SIGNALS_SETTING_NAME =
  "Vin.Edison.BuyingSignals.keyinfomodal.url";

const StyledBuyingSignalsModalContent = styled.iframe`
  width: 100%;
  height: 900px;
  border: none;
`;

const BuyingSignalsModelContent = ({
  customerId,
  dealerId
}: BuyingSignalsModelContentProps) => {
  const iframeUrl = useMemo(() => {
    return customerId
      ? `${CARDASHBOARD_RIMS_BASEPATH}?urlSettingName=${BUYING_SIGNALS_SETTING_NAME}&customerId=${customerId}&dealerId=${dealerId}&HideCloseButton=true`
      : "";
  }, [customerId, dealerId]);

  return (
    iframeUrl && (
      <StyledBuyingSignalsModalContent
        src={iframeUrl}
        title="Buying-Signals-Modal"
      />
    )
  );
};

export default BuyingSignalsModelContent;
