import {
  OemInsightsSummaryResponse,
  OemPartnerKey
} from "@vinsolutions/data-access/oem/vin.api.oeminsights";

export const getOemDataFromSummaryResponse = (
  oemInsightsSummary: OemInsightsSummaryResponse | null,
  oemKey: OemPartnerKey
) => {
  return oemInsightsSummary?.data?.find(
    oemData => oemData.oem.toLowerCase() === oemKey
  )?.summary;
};

const dateStringOptions: Intl.DateTimeFormatOptions = {
  month: "2-digit",
  year: "numeric",
  day: "2-digit"
};

export const getFormattedUpdatedUtcDate = (updateUtcString: string): string => {
  const updatedDateUtc = updateUtcString ? new Date(updateUtcString) : null;
  return updatedDateUtc?.toLocaleDateString("en-US", dateStringOptions) || "";
};
