import { VinconnectFlagNames } from "@vinsolutions/core/third-party/launch-darkly";

interface DealerNotifyApps {
  route: string;
  flag: VinconnectFlagNames;
}

export const DEALER_NOTIFY_APPS: Array<DealerNotifyApps> = [
  {
    route: "CarDashboard/Desking/DeskingLoader.ashx",
    flag: "nx.vinconnect.send-event-for-desking"
  }
];
