import { envEnum } from "@vinsolutions/core/environment";
import { AutoTraderInfo } from "../models/autotrader-info.model";

export const mockAutoTraderResponses = {
  [envEnum.LOCAL]: {
    service: "Wallet Lead",
    date: 1722359384000
  } as AutoTraderInfo,
  [envEnum.DEV]: {
    service: "Wallet Lead",
    date: 1722359384000
  } as AutoTraderInfo
};
