import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cardashboardActions,
  getCardashboardState
} from "@vinsolutions/legacy-cardashboard/store";
import { usePrevious } from "@vinsolutions/ccrm/util";
import { LoadingIndicator } from "@vinsolutions/core/components";
import { CarDashboardFrame } from "@vinsolutions/legacy-cardashboard/interfaces";
import styled, { css } from "styled-components";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useGetPane } from "@vinsolutions/utility/hooks";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { NavigationIframe } from "@vinsolutions/ccrm/top-nav/main";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("cardashboard-iframe");

type Pane = "cd" | "left" | "right" | null;
type PaneAsProps = {
  pane: Pane;
};
const StyledCdFrameLoadingIndicator = styled(LoadingIndicator)<PaneAsProps>`
  background-color: #6d727a;
  opacity: 50%;

  ${props => {
    if (props.pane === "left") {
      return css`
        width: 50%;
        float: right;
      `;
    }
    if (props.pane === "right") {
      return css`
        width: 50%;
      `;
    }
    return "";
  }}

  .cx-loadingIndicator__dots {
    height: 100%;
    align-items: center;
  }
`;

export function CardashboardIframe() {
  logger.debug("Cardashboard iframe rendering");
  const cdFrameRef = useRef<CarDashboardFrame>(null);
  const dispatch = useDispatch();
  const { forceRefresh, frameUrl, loadingStatus } =
    useSelector(getCardashboardState);
  const previousForceRefresh = usePrevious(forceRefresh, null);
  const previousFrameUrl = usePrevious(frameUrl, null);
  const { pane } = useGetPane();

  useEffect(() => {
    if (
      cdFrameRef?.current &&
      ((previousFrameUrl !== frameUrl && cdFrameRef.current.src !== frameUrl) ||
        previousForceRefresh !== forceRefresh)
    ) {
      logger.debug("Cardashboard iframe dispatch pending");
      dispatch(cardashboardActions.pending());
      logger.debug("Cardashboard iframe dispatch loaded", frameUrl);
      cdFrameRef.current.src = frameUrl + "";
    }
  }, [
    dispatch,
    frameUrl,
    forceRefresh,
    previousForceRefresh,
    previousFrameUrl
  ]);

  logger.debug("Cardashboard iframe loading");
  return (
    <>
      {loadingStatus !== "loaded" && (
        <StyledCdFrameLoadingIndicator
          color="white"
          htmlId="cardashboard-iframe-loading-indicator"
          pane={pane as Pane}
          size="large"
        />
      )}
      <NavigationIframe cdFrameRef={cdFrameRef} frameUrl={frameUrl} />
    </>
  );
}
export const path = "CarDashboard";
