import React, { ChangeEvent, useEffect, useState } from "react";
import { Alert, Button, SelectInput, SubmitButton, TextArea } from "cx";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  loadLeadStatusOptionsFromApi,
  markLeadStatusViaApi
} from "./endpoints";
import { useMountEffect } from "@vinsolutions/ccrm/util";
import { MarkLeadStatusProps, MarkLeadStatusState } from "./interfaces";
import styled from "styled-components";

const initialState: MarkLeadStatusState = {
  selectedStatus: "",
  enteredNote: "",
  leadStatusOptions: [],
  alertText: "",
  alertType: "info",
  isSubmitting: false,
  isLeadStatusValid: false,
  isNoteValid: false,
  isSubmitDisabled: true
};

const StyledMarkLeadContainer = styled.div`
  padding: 12px;
`;

const StyledMarkLeadButtonContainer = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto;
  justify-content: end;

  .btn {
    min-width: 150px;
  }
`;

function MarkLeadStatus({
  leadId,
  leadStatus,
  onCancel,
  onSuccess
}: MarkLeadStatusProps) {
  const [
    {
      selectedStatus,
      enteredNote,
      leadStatusOptions,
      alertText,
      alertType,
      isSubmitting,
      isLeadStatusValid,
      isNoteValid,
      isSubmitDisabled
    },
    setState
  ] = useState<MarkLeadStatusState>(initialState);
  const { oidcUser } = useReactOidc();

  useMountEffect(() => {
    loadLeadStatusOptions();
    async function loadLeadStatusOptions() {
      const response = await loadLeadStatusOptionsFromApi(
        oidcUser.access_token,
        leadStatus
      );
      if (
        response.data?.leadStatusResponses === undefined ||
        response.data?.leadStatusResponses.length === 0
      ) {
        setState(s => {
          return {
            ...s,
            alertText: "Please try again later",
            alertType: "danger"
          };
        });
      } else {
        setState(s => {
          return {
            ...s,
            leadStatusOptions: response.data?.leadStatusResponses || []
          };
        });
      }
    }
  });

  useEffect(() => {
    setState(s => {
      return {
        ...s,
        alertText: leadId ? "" : "Missing lead ID",
        alertType: "danger"
      };
    });
  }, [leadId]);

  useEffect(() => {
    setState(s => {
      return {
        ...s,
        isSubmitDisabled:
          !leadId ||
          !isNoteValid ||
          !isLeadStatusValid ||
          isSubmitting ||
          alertType === "success"
      };
    });
  }, [leadId, isNoteValid, isLeadStatusValid, isSubmitting]);

  const onStatusDropdownChange = (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
    isValid: boolean
  ) => {
    // note - cx is sending true for isValid when required validation is failing
    setState(s => {
      return {
        ...s,
        selectedStatus: value || "",
        isLeadStatusValid: isValid && !!value
      };
    });
  };

  const onNoteFieldChanged = (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
    isValid: boolean
  ) => {
    // note - cx is sending true for isValid when required validation is failing
    setState(s => {
      return {
        ...s,
        enteredNote: value || "",
        isNoteValid: isValid && !!value
      };
    });
  };

  const onSubmitClick = async () => {
    setState(s => {
      return { ...s, isSubmitting: true };
    });

    const response = await markLeadStatusViaApi(
      leadId || -1,
      leadStatus,
      selectedStatus || "",
      enteredNote || "",
      oidcUser.access_token
    );

    setState(s => {
      return {
        ...s,
        alertText: response.hasError ? "Failed!" : "Success!",
        alertType: response.hasError ? "danger" : "success",
        isSubmitting: false
      };
    });
    if (!response.hasError) {
      onSuccess();
    }
  };

  return (
    <StyledMarkLeadContainer id="markLeadStatusContainer">
      {alertText && (
        <Alert htmlId="markLeadStatusAlert" type={alertType || "info"}>
          {alertText}
        </Alert>
      )}
      <SelectInput
        disabled={!!alertText}
        htmlId="markLeadStatus"
        label="Reason"
        name="lead-status"
        options={leadStatusOptions.map(option => ({
          label: option.leadStatusName,
          value: option.leadStatusId
        }))}
        required={true}
        onChange={onStatusDropdownChange}
      />
      <TextArea
        disabled={!leadId}
        htmlId="markLeadStatusNote"
        label="Note"
        minLength={3}
        name="note"
        required={true}
        value={enteredNote || ""}
        onChange={onNoteFieldChanged}
      />
      <StyledMarkLeadButtonContainer>
        <SubmitButton
          disabled={isSubmitDisabled}
          htmlId="markLeadStatusSubmit"
          isLoading={isSubmitting}
          loadingText="Submitting"
          onClick={onSubmitClick}
        >
          Save
        </SubmitButton>
        <Button
          buttonStyle="secondary"
          disabled={isSubmitting}
          htmlId="markLeadStatusCancel"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </StyledMarkLeadButtonContainer>
    </StyledMarkLeadContainer>
  );
}

export default MarkLeadStatus;
