import { DynamicHeaderEntity, Profile } from "@vinsolutions/ccrm/interfaces";
import DealerFromApi from "./DealerFromApi";
import { fetchWrapper } from "@vinsolutions/core/http";
import {
  BaseUrlsObj,
  envEnum,
  getBaseUrl,
  getEnvironment
} from "@vinsolutions/core/environment";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("ccrm-api-endpoints");

type Config = {
  baseUrls: BaseUrlsObj;
};

export const config: Config = {
  baseUrls: {
    [envEnum.LOCAL]:
      "https://dev-dealerdashboard.vinsolutions.com/vin.api.corecrm/",
    [envEnum.DEV]:
      "https://dev-dealerdashboard.vinsolutions.com/vin.api.corecrm/",
    [envEnum.QA]: "https://qa-crm.vinsolutions.com/vin.api.corecrm/",
    [envEnum.STAGING]: "https://staging-crm.vinsolutions.com/vin.api.corecrm/",
    [envEnum.PRODUCTION]: "https://crm.vinsolutions.com/vin.api.corecrm/"
  }
};

export async function getEpochTime() {
  try {
    const response = await fetchWrapper.getAsync<string>(
      `${getBaseUrl(config.baseUrls)}Status/CurrentEpochTime`
    );
    if (response.data && !response.hasError) {
      return Number(response.data);
    }

    return null;
  } catch {
    // On failure default to client machine time.
    return (Date.now() / 1000) | 0;
  }
}

export async function getProfileData(jwt: string) {
  const response = await fetchWrapper.getAsync<Profile>(
    `${getBaseUrl(config.baseUrls)}CrmUser/CrmUserInfo`,
    { Authorization: `Bearer ${jwt}` }
  );
  if (response.data && !response.hasError) {
    return Promise.resolve(response.data);
  }
  return Promise.reject(response.error);
}

export async function getDealerInfoById(jwt: string, dealerId: number) {
  return await fetchWrapper.getAsync<DealerFromApi>(
    `${getBaseUrl(
      config.baseUrls
    )}DealerSelector/GetDealerInfo?dealerId=${dealerId}`,
    { Authorization: `Bearer ${jwt}` }
  );
}

export interface GetAvailableDealersByPage {
  page: number;
  pageSize: number;
  totalDealers: number;
  dealers: DealerFromApi[];
}

export async function getDealersByPage(jwt: string, page: string) {
  return await fetchWrapper.getAsync<GetAvailableDealersByPage>(
    `${getBaseUrl(
      config.baseUrls
    )}DealerSelector/GetAvailableDealersByPage?page=${page}`,
    { Authorization: `Bearer ${jwt}` }
  );
}

export async function searchDealers(jwt: string, search: string) {
  return await fetchWrapper.getAsync<GetAvailableDealersByPage>(
    `${getBaseUrl(config.baseUrls)}DealerSelector/Search?searchQuery=${search}`,
    { Authorization: `Bearer ${jwt}` }
  );
}

export async function getDynamicHeaderData(args: {
  jwt: string;
  dealerId: number;
}) {
  const { jwt, dealerId } = args;
  const url = `${getBaseUrl(
    config.baseUrls
  )}DynamicTopNavigation/TopNavigationLoadInfo?selectedDealerId=${dealerId}`;
  const response = await fetchWrapper.getAsync<DynamicHeaderEntity>(url, {
    Authorization: `Bearer ${jwt}`
  });
  if (response.data && !response.hasError) {
    return Promise.resolve(response.data);
  }
  return Promise.reject(response.error);
}

export async function hasDealListProductAccess(token: string) {
  const environment = getEnvironment(window.location.hostname);
  const baseURL =
    environment === envEnum.PRODUCTION
      ? "https://cxm-ui-bff-api.ccxm.cloud"
      : "https://cxm-ui-bff-api.ccxmnp.cloud";

  const url = `${baseURL}/api/user-profile/products`;
  logger.info(`Calling ${url}`);
  const response = await fetchWrapper.getAsync<any>(url, {
    Authorization: `Bearer ${token}`
  });
  if (response.hasError) {
    logger.info(`Error Calling ${url} :: ${response.error}`);
  }
  return response.data.products.retail360.dealers.length > 0;
}
