import { useLocation } from "react-router-dom";

type Pane = "cd" | "left" | "right" | "both" | "full" | null;

export const useGetPane = () => {
  const { pathname } = useLocation();
  let pane: Pane = null;
  if (pathname.includes("pane-left")) {
    pane = "left";
  } else if (pathname.includes("pane-right")) {
    pane = "right";
  } else if (pathname.includes("pane-both")) {
    pane = "both";
  } else if (pathname.includes("pane-full")) {
    pane = "full";
  } else {
    pane = "cd";
  }
  return { pane };
};
