import styled from "styled-components";
import { LoadingIndicator } from "@vinsolutions/core/components";
const StyledDisplay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;
const NotAuthorized = () => (
  <StyledDisplay>
    <LoadingIndicator action="NotAuthorized" htmlId="loading" />
    NotAuthorized
  </StyledDisplay>
);
export default NotAuthorized;
