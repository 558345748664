import { useMemo } from "react";
import { css } from "styled-components";

const usePortalStylesCss = (portalStyles?: HTMLStyleElement[] | null) => {
  const portalStylesCss = useMemo(() => {
    let cssString = "";
    portalStyles?.forEach(portalStyle => {
      cssString += portalStyle.innerText;
    });
    return cssString;
  }, [portalStyles]);
  return css`
    ${portalStylesCss}
  `;
};

export default usePortalStylesCss;
