import BuyingSignalsModelContent from "./buying-signals-model-content";
import { KeyInformationBannerModal } from "@vinsolutions/ui/key-information-banner";

export interface KeyInformationBuyingSignalsModalProps {
  customerId: number;
  dealerId: number;
  showModal: boolean;
  onHide: () => void;
}

const KeyInformationBuyingSignalsModal = ({
  customerId,
  dealerId,
  showModal,
  onHide
}: KeyInformationBuyingSignalsModalProps) => {
  return (
    <KeyInformationBannerModal showModal={showModal} onHide={onHide}>
      <BuyingSignalsModelContent customerId={customerId} dealerId={dealerId} />
    </KeyInformationBannerModal>
  );
};

export default KeyInformationBuyingSignalsModal;
