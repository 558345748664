import { useCallback, useState } from "react";
import { Button, IconPerson } from "@vinsolutions/core/cx";
import { SlidingDrawer } from "@vinsolutions/core/components";
import styled from "styled-components";
import { shallowEqual, useSelector } from "react-redux";
import AccountMenu from "../account-menu/account-menu";
import { DarkThemeAccountIcon } from "./icons/DarkThemeAccount";
import { LightThemeAccountIcon } from "./icons/LightThemeAccount";
import { NotificationDotIcon } from "./icons/NotificationDot";
import FeedbackModal from "../feedback-modal/feedback-modal";
import {
  getThemeState,
  selectAllNotifications
} from "@vinsolutions/ccrm/store";
import { ThemeState } from "@vinsolutions/ccrm/interfaces";
import { StatusModal } from "@vinsolutions/ccrm/top-nav/status-selector";
// prettier-ignore
const StyledAccount = styled.div<Pick<ThemeState, "background" | "aboveSecondary">>`
  // making a choice to save time by not renaming all the classes. Someone should move to the new naming conventions though
  .cx-top-nav-account-menu-content {
    position: relative;
    margin-right: 16px;
    width: 45px;

    .cx-top-nav-icon-button {
      font-size: 20px;
      height: 45px;
      width: 45px;
      margin: 0;
      padding: 0;
      border-radius: 25px;
      color: ${({ aboveSecondary }) => aboveSecondary};
      overflow: hidden;
      border: transparent;
      background: transparent;

      &:focus-visible,
      &:focus-within,
      &:visited,
      &:focus,
      &:hover,
      &:active {
        border: transparent;
        background: transparent;
        box-shadow: none;
      }

      svg {
        height: 45px;
        width: 45px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      #cx-top-nav-account-menu-icon {
        height: 38px;
        width: 38px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${({background}) => background};
        text-shadow: 0 2px 4px rgba(22,23,26,0.50);
      }
    }

    .ux-wants-counts-and-overflow-hidden-so-this-exists {
      position: absolute;
      top: -4px;
      right: -2px;
    }

    #top-right-center-area {
      position: absolute;
      top: 35px;
      right: 80px;
      height: 70px;
    }
  }

  #cx-top-nav-account-menu-sliding-drawer {
    .cx-drawer__panel {
      background-color: #fff; //var($--theme-background);
      padding: 0;
    }

    .cx-drawer__background {
      z-index: 90;
    }

    .cx-top-nav-account-menu-sliding-drawer-person-icon-wrapper {
      display: inline-block;
      padding: 0;
      border: 2px solid #2153aa; //var($--theme-secondary-icon);
      border-radius: 20px;
      color: #2153aa; //var($--theme-secondary-icon);
      position: relative;
      height: 28px;
      width: 28px;
      margin-top: 1px;

      &:hover {
        color: #2153aa; //var($--theme-secondary-icon);
        border-color: #2153aa; //var($--theme-secondary-icon);
      }
    }

    .cx-top-nav-account-menu-sliding-drawer-title {
      display: flex;
      margin: 16px 16px 8px;
    }

    #cx-top-nav-account-menu-sliding-drawer-person-icon {
      height: 31px;
      width: 31px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .cx-top-nav-account-menu-sliding-drawer-title-text {
      display: inline-block;
      height: 28px;
      flex: 1 1 0;
      margin: 0;
      color: #000;
      font-size: 20px;
      line-height: 24px;
    }

    .cx-top-nav-account-menu-sliding-drawer-content {
      color: #000;

      .account-menu-link-button {
        text-align: left;
        color: #2b6bdd; //var($--theme-secondary-icon);
        margin: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 0;

        &#cx-top-nav-account-menu-status-button {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .badge {
          position: initial;
          top: initial;
        }

        &:hover,
        :focus {
          color: #fff; //var($--theme-on-primary);
          background-color: #2b6bdd; //var($--theme-background);
          border-color: #2b6bdd; //var($--theme-background);
        }
      }

      .account-menu-toggle-switch {
        display: flex;
        justify-content: space-between;
        margin: 16px;
        line-height: 20px;
        color: #2b6bdd;

        .cx-switch {
          margin: 0;
        }
      }

      .account-menu-divider {
        margin: 8px 16px;
        padding: 0;
      }

      .account-menu-sign-out {
        color: #2b6bdd; //var($--theme-on-primary);
        width: 100%;
        text-align: center;

        &:hover {
          color: #000; // var($--theme-on-primary);
          background-color: #fff; //var($--theme-background);
          border-color: #fff; //var($--theme-background);
        }
      }
    }
  }
`;

export function Account() {
  const [showAccountSlidingDrawer, setShowAccountSlidingDrawer] =
    useState(false);
  const [showFeedbackModal, setShowFeebackModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const notifications = useSelector(selectAllNotifications, shallowEqual);
  const { background, currentThemeId, aboveSecondary } = useSelector(
    getThemeState,
    shallowEqual
  );

  const toggleShowAccountSlidingDrawer = () => {
    setShowAccountSlidingDrawer(b => !b);
  };

  const toggleShowFeedbackModal = () => {
    setShowFeebackModal(b => !b);
  };

  const toggleShowStatusModal = () => {
    setShowStatusModal(b => !b);
  };

  const notificationCount = useCallback(() => {
    // Some day a metrics API should provide the total. I am against moving this to the store for now
    // A dependency on a single count it much simpler, but building logic into the store is abstracted a little too much
    // while this app is still in its infancy
    const count =
      notifications.length > 0
        ? notifications.reduce((sum, notification) => {
            return sum + notification.count;
          }, 0)
        : 0;
    return count;
  }, [notifications]);

  return (
    <StyledAccount
      {...{ background, aboveSecondary }}
      data-testid={"account-menu"}
    >
      <div className="cx-top-nav-account-menu-content">
        <Button
          className="cx-top-nav-icon-button"
          htmlId="cx-top-nav-account-menu-button"
          onClick={toggleShowAccountSlidingDrawer}
        >
          {currentThemeId === "vinconnectDark" ? (
            <DarkThemeAccountIcon id="dark-theme-account-icon" />
          ) : (
            <LightThemeAccountIcon id="light-theme-account-icon" />
          )}
          <IconPerson htmlId="cx-top-nav-account-menu-icon" />
        </Button>
        {notificationCount() > 0 && (
          <NotificationDotIcon className="ux-wants-counts-and-overflow-hidden-so-this-exists" />
        )}
      </div>
      <SlidingDrawer
        htmlId="cx-top-nav-account-menu-sliding-drawer"
        panelWidth="300px"
        position="right"
        show={showAccountSlidingDrawer}
        onHide={toggleShowAccountSlidingDrawer}
      >
        <AccountMenu
          {...{
            toggleShowAccountSlidingDrawer,
            toggleShowFeedbackModal,
            toggleShowStatusModal
          }}
        />
      </SlidingDrawer>
      <StatusModal {...{ showStatusModal, toggleShowStatusModal }} />
      <FeedbackModal {...{ showFeedbackModal, toggleShowFeedbackModal }} />
    </StyledAccount>
  );
}

export default Account;
