import styled from "styled-components";
import { Button } from "cx";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("NotAuthenticated");

const StyledDisplay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;
const NotAuthenticated = (
  carDashboardLogoutUrl: string,
  errorMessage?: string | undefined
) => {
  logger.debug("NotAuthenticated: Initializing NotAuthenticated.");
  const logUserOut = async () => {
    logger.debug("NotAuthenticated: Setting logout cookie.");
    document.cookie =
      "LogUserOutTopNav=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=-99999999;SameSite=None;Secure";
    logger.debug("NotAuthenticated: clearing session from browser.");
    sessionStorage.clear();
    logger.debug("NotAuthenticated: redirecting to carDashboardLogoutUrl.");
    window.location.href = carDashboardLogoutUrl;
  };

  return (
    <StyledDisplay>
      Not Authenticated {errorMessage}
      <br />
      <Button htmlId="NotAuthenticated" onClick={logUserOut}>
        Click to retry
      </Button>
    </StyledDisplay>
  );
};
export default NotAuthenticated;
