import { useReactOidc } from "@axa-fr/react-oidc-context";
import { KeyInformationBanner } from "@vinsolutions/ui/key-information-banner";
import { useCallback, useEffect, useState } from "react";
import VinessaModal from "./components/vinessa-modal";
import VinessaSummary from "./components/vinessa-summary";
import VinessaIcon from "./icons/vinessa-icon";
import VinessaIconSmall from "./icons/vinessa-icon-small";
import VinessaIconSmallInverse from "./icons/vinessa-icon-small-inverse";
import { VinessaBannerProps } from "./interfaces/vinessa-banner-props";
import VinessaDataDetail from "./components/vinessa-data-detail";

import {
  VinessaSummaryResponse,
  getVinessaSummary
} from "@vinsolutions/data-access/virtual-assistant/crm.workflow.keyinfo.vinessa";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";

const bannerName = "Vinessa";

export const BaseDisplayedBanner = {
  bannerName,
  iconName: "VinessaIconSmall",
  thumbnailName: "VinessaIconSmall",
  thumbnailIcon: <VinessaIconSmall />
};

export function VinessaBanner({
  customerId,
  dealerId,
  onInitialize,
  onLoad,
  onClickGtmEvent
}: VinessaBannerProps) {
  // State
  const [vinessaSummary, setVinessaSummary] = useState<
    VinessaSummaryResponse | null | undefined
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vinessaIcon, setVinessaIcon] = useState(VinessaIconSmall);
  const [showModal, setShowModal] = useState(false);

  const jwt = useReactOidc().oidcUser.access_token;

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "VinessaBanner",
    "Loading Vinessa Banner"
  );

  // Data Access
  const loadVinessaSummary = useCallback(async () => {
    const vinessaSummary = await getVinessaSummary(jwt, customerId, dealerId);

    setVinessaSummary(vinessaSummary);
    setIsLoading(false);
    const isDisplayed = !!vinessaSummary;
    onLoad({
      ...BaseDisplayedBanner,
      customerId,
      isDisplayed
    });
    setInteractionAttributes({
      customerId: customerId || 0,
      dealerId: dealerId || 0,
      isDisplayed
    });
    endInteraction();
  }, [
    onLoad,
    customerId,
    dealerId,
    jwt,
    endInteraction,
    setInteractionAttributes
  ]);

  useEffect(() => {
    onInitialize?.(1);
    loadVinessaSummary();
  }, [loadVinessaSummary, onInitialize]);

  const onBannerClick = useCallback(async () => {
    if (vinessaSummary?.errorMessage) {
      // retry loading banner
      setIsLoading(true);
      await loadVinessaSummary();
    } else {
      onClickGtmEvent?.(bannerName);
      setShowModal(true);
    }
  }, [loadVinessaSummary, vinessaSummary, onClickGtmEvent]);

  return (
    vinessaSummary && (
      <>
        <KeyInformationBanner
          bannerDataDetail={
            <VinessaDataDetail
              vinessaSummary={vinessaSummary}
              onDataErrorRetryClick={loadVinessaSummary}
            />
          }
          bannerIcon={<VinessaIcon icon={vinessaIcon} />}
          bannerName={bannerName}
          bannerSummary={
            <VinessaSummary textBlurb={vinessaSummary.textBlurb} />
          }
          isLoading={isLoading}
          onClick={onBannerClick}
          onMouseEnter={() => setVinessaIcon(VinessaIconSmallInverse)}
          onMouseLeave={() => setVinessaIcon(VinessaIconSmall)}
        />
        <VinessaModal
          customerId={customerId}
          dealerId={dealerId}
          showModal={showModal}
          onHide={() => setShowModal(false)}
        />
      </>
    )
  );
}
