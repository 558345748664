import { StyledNavigationMenu } from "../navigation-buttons";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getMainNavigationState,
  getThemeState,
  mainNavigationActions
} from "@vinsolutions/ccrm/store";
import {
  DynamicHeaderMenuItem,
  DynamicMenu
} from "@vinsolutions/ccrm/interfaces";
import { getButtonToRender } from "../navigation-menu";
import { RightOutlined } from "@ant-design/icons";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useDynamicHeader } from "./use-dynamic-header";

const buildItem = (menuItem: DynamicHeaderMenuItem): ItemType => {
  if (menuItem.menuItems && menuItem.menuItems.length > 0) {
    return {
      key: menuItem.id,
      label: <span id={menuItem.id}>{menuItem.label}</span>,
      children: buildItems(menuItem.menuItems)
    };
  } else {
    const target = menuItem.url?.startsWith("http") ? "_blank" : "";
    return {
      key: menuItem.id,
      label: getButtonToRender(
        "GENERIC_URL",
        menuItem.url || "#",
        menuItem.label,
        target,
        menuItem.id
      )
    };
  }
};
const buildItems = (items: DynamicHeaderMenuItem[]): ItemType[] => {
  return items.map(item => buildItem(item));
};
const generateSubMenu = (
  activeTabId: string | null | undefined,
  dynamicMenu: DynamicMenu | null | undefined
): ItemType[] => {
  const activeMenu = dynamicMenu?.dynamicMenuItems?.find(
    item => item.id === activeTabId
  );
  if (!activeMenu || !activeMenu.menuItems) {
    return [];
  }
  return buildItems(activeMenu.menuItems);
};

/**
 * Component for the DynamicHeader(Skinny-top-nav) menu
 * @constructor
 */
export const DynamicNavigationMenu = () => {
  const { secondary, aboveSecondary } = useSelector(getThemeState);
  const { activeTabId } = useSelector(getMainNavigationState);
  const { dynamicMenu } = useDynamicHeader();
  const dispatch = useDispatch();
  if (!dynamicMenu?.dynamicMenuItems?.find(i => i.id === activeTabId)) {
    dispatch(
      mainNavigationActions.setActiveTab(
        dynamicMenu?.dynamicMenuItems && dynamicMenu.dynamicMenuItems.length > 0
          ? dynamicMenu.dynamicMenuItems[0].id
          : ""
      )
    );
    return null;
  }
  return (
    <StyledNavigationMenu aboveSecondary={aboveSecondary} secondary={secondary}>
      <Menu
        className="clear"
        expandIcon={<RightOutlined />}
        id="navigation-sub-menus"
        items={generateSubMenu(activeTabId, dynamicMenu)}
        mode="horizontal"
        subMenuCloseDelay={0.25}
      />
    </StyledNavigationMenu>
  );
};
