import styled from "styled-components";
import { Button } from "cx";
import { useCallback, useEffect, useState } from "react";
import { getCarDashboardBaseUrl } from "legacy-crm-util";
import { GetVSSecurityFunctions as getVSSecurityFunctions } from "@vinsolutions/tenant/api";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { getClaims } from "@vinsolutions/tenant/jwt-utils";
import { environments, getEnvironmentName } from "@vinsolutions/core/config";

/* eslint-disable-next-line */
export interface ImpersonationButtonProps {}

const StyledImpersonationButton = styled(Button)`
  color: #2b6bdd; //var($--theme-on-primary);
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;

  &:hover,
  :focus {
    color: #000; // var($--theme-on-primary);
    background-color: #fff; //var($--theme-background);
    border-color: #fff; //var($--theme-background);
  }
`;

const StyledImpersonationButtonWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
`;

export function ImpersonationButton(props: ImpersonationButtonProps) {
  const [canImpersonateState, setCanImpersonate] = useState(false);
  const { oidcUser } = useReactOidc();

  useEffect(() => {
    canImpersonate();
  });

  const impersonateUser = async () => {
    const carDashboardUrl = getCarDashboardBaseUrl();
    Object.keys(localStorage).forEach(key => {
      if (key.includes("oidc")) {
        localStorage.removeItem(key);
      }
    });
    localStorage.removeItem("cdIsInitialized");

    document.cookie =
      "LogUserOutTopNav=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Max-Age=-99999999;SameSite=None;Secure";

    sessionStorage.clear();
    sessionStorage.setItem("impersonationStarted", "true");

    window.location.replace(
      `${carDashboardUrl}/CarDashboard/Pages/Admin/Userrelogin.aspx?SelectedTab=t_Admin&topNav=true&top-nav-opt-out=true`
    );
  };

  const getUserAccessLevel = useCallback((jwt: string) => {
    const claims = getClaims(jwt);
    return claims ? claims.useraccesslevel : "0";
  }, []);

  const canImpersonate = async (): Promise<void> => {
    const userAccessLevel = getUserAccessLevel(oidcUser.access_token);

    // Can't call Dev User Service from Dev as it stands.
    // Need Dev to become publicly accessible and auth with Dev IDS.
    const env = getEnvironmentName();
    if (
      userAccessLevel === "1" &&
      (env === environments.dev || env === environments.local)
    ) {
      setCanImpersonate(true);
      return;
    }

    const vsSecurityFunctionResponse = await getVSSecurityFunctions(
      oidcUser.access_token
    );

    if (
      vsSecurityFunctionResponse.statusCode === 200 &&
      vsSecurityFunctionResponse.data
    ) {
      const collection = vsSecurityFunctionResponse.data;

      if (collection.items) {
        collection.items.forEach(
          (item: { superRole: boolean; functionCode: string }) => {
            if (item.superRole && item.superRole) {
              setCanImpersonate(true);
              return;
            }

            if (item.functionCode && item.functionCode === "ReloginUser") {
              setCanImpersonate(true);
              return;
            }
          }
        );
      }
    }
  };

  return getUserAccessLevel(oidcUser.access_token) === "1" &&
    canImpersonateState ? (
    <StyledImpersonationButtonWrapper>
      <StyledImpersonationButton
        buttonStyle="link"
        className="account-menu-impersonation-button"
        htmlId="impersonationModalButton"
        onClick={impersonateUser}
      >
        Sign in as other user?
      </StyledImpersonationButton>
    </StyledImpersonationButtonWrapper>
  ) : null;
}

export default ImpersonationButton;
