import BuyingSignalsModelContent from "./buying-signals-modal-content";
import DealDetailsModelContent from "./deal-details-modal-content";
import { KeyInformationBannerModal } from "@vinsolutions/ui/key-information-banner";

export interface KeyInformationDealModalProps {
  customerId: number;
  leadId: number;
  dealerId: number;
  dealExchangeId: string | null;
  drActivityCount: number | null;
  showModal: boolean;
  onHide: () => void;
}

const KeyInformationDealModal = ({
  customerId,
  dealerId,
  leadId,
  dealExchangeId,
  drActivityCount,
  showModal,
  onHide
}: KeyInformationDealModalProps) => {
  return (
    <KeyInformationBannerModal showModal={showModal} onHide={onHide}>
      {drActivityCount ? (
        <BuyingSignalsModelContent
          customerId={customerId}
          dealExchangeId={dealExchangeId}
          dealerId={dealerId}
          leadId={leadId}
        />
      ) : (
        <DealDetailsModelContent
          customerId={customerId}
          dealExchangeId={dealExchangeId}
          dealerId={dealerId}
          leadId={leadId}
        />
      )}
    </KeyInformationBannerModal>
  );
};

export default KeyInformationDealModal;
