import styled from "styled-components";

export interface GmLeadScoreProps {
  leadScore: string;
  oemLeadId: string;
}

const StyledGmLeadScore = styled.span``;

export const GmLeadScore = ({ oemLeadId, leadScore }: GmLeadScoreProps) => {
  return (
    <StyledGmLeadScore>
      {leadScore ? `Score: ${leadScore} - ` : ""}Lead ID: #{oemLeadId}
    </StyledGmLeadScore>
  );
};

export default GmLeadScore;
