/**
 * Check the html element to see if it is one that we need to use to indicate if the React panes should open or close
 * @param e
 */
const checkHtmlElement = (e: HTMLElement) => {
  // The Edit button in Customer Dashboard is an example of this
  if (e?.id?.includes("RadWindowWrapper_ctl00__MasterRadWindowManager")) {
    return true;
  }

  if (e?.children) {
    if (Array.from(e?.children).find(s => s.id === "keyInfo-Modal-Dialog")) {
      return true;
    }
  }

  // Appointment view in Customer Dashboard is an example of this
  return e?.classList?.contains("ui-widget-overlay");
};

const observerOptions = {
  childList: true,
  attributes: true,
  subtree: true
};

export class ModalObserver {
  readonly #observer;
  constructor() {
    /**
     * Create the observer for the display of Cardashboard modals created by RadWindowManager
     * The strategy is to look for the addition of a node with an id that contains "RadWindowWrapper_ctl00__MasterRadWindowManager"
     * If this isn't specific enough then we can create a list of know modal titles and use that to improve specificity
     * If a node matches then the left and/or right jsx component is hidden
     * The target for the observer is the iframe#cardashboard.Form1 element
     */

    // some modals can open sub modals, so we need to track how many are open
    let openModals = 0;

    this.#observer = new MutationObserver(function callback(mutationList) {
      mutationList.forEach(mutation => {
        if (mutation.type === "childList") {
          // Look for any nodes that were added with the RadWindowWrapper_ctl00__MasterRadWindowManager
          // It might be necessary to add addition elements to watch for
          // If a node is added with the MasterRadWindowManager id then it is probably a modal and we want to hide the
          // React frame
          mutation.addedNodes.forEach(n => {
            if (checkHtmlElement(n as HTMLElement)) {
              const leftComponent =
                document.getElementById("left-component-jsx");
              const rightComponent = document.getElementById(
                "right-component-jsx"
              );

              if (leftComponent) {
                openModals += 1;
                leftComponent.style.visibility = "hidden";
              }
              if (rightComponent) {
                openModals += 1;
                rightComponent.style.visibility = "hidden";
              }
            }
          });
          mutation.removedNodes.forEach(n => {
            // Check for removal of nodes with the MasterRadWindowManager id
            // Check to see if the cardashboard left and right frames are not hidden
            // If they aren't hidden then make the react components visible
            if (checkHtmlElement(n as HTMLElement)) {
              const leftComponent =
                document.getElementById("left-component-jsx");
              const rightComponent = document.getElementById(
                "right-component-jsx"
              );
              const cdFrameRef = document.getElementById(
                "cardashboardframe"
              ) as HTMLIFrameElement;
              const leftCdPane =
                cdFrameRef?.contentWindow?.document?.getElementById(
                  "leftpaneframe"
                );
              const rightCdPane =
                cdFrameRef?.contentWindow?.document?.getElementById(
                  "rightpaneframe"
                );
              if (
                leftCdPane?.style?.visibility !== "visible" &&
                leftComponent
              ) {
                if (openModals === 1) {
                  leftComponent.style.visibility = "visible";
                }
                openModals -= 1;
              }
              if (
                rightCdPane?.style?.visibility !== "visible" &&
                rightComponent
              ) {
                if (openModals === 1) {
                  rightComponent.style.visibility = "visible";
                }
                openModals -= 1;
              }
            }
          });
        }
      });
    });
  }
  observe() {
    const frameElement = window?.top?.document?.getElementById(
      "cardashboardframe"
    ) as HTMLIFrameElement;

    const formElement =
      frameElement?.contentDocument?.getElementsByTagName("Body");

    if (
      formElement &&
      formElement.length > 0 &&
      formElement[0]?.children.length > 0
    ) {
      this.#observer.observe(formElement[0], observerOptions);
    }

    return this.#observer;
  }
  disconnect() {
    this.#observer.disconnect();
  }
}
