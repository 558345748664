const AMPIconSmallInverse = () => {
  return (
    <svg
      className="keyInfo-small-icon"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="0"
        d="M21.897 9.558A.999.999 0 0021 9h-7.883l.876-7.89a1 1 0 00-1.787-.721l-10 13A1 1 0 003 15h7.883l-.876 7.89a1 1 0 001.786.72l10-13c.232-.302.272-.71.104-1.052z"
        fill="#FFF"
      />
    </svg>
  );
};

export default AMPIconSmallInverse;
