import styled from "styled-components";

const StyledHondaAcuraInsightsSummary = styled.span`
  float: left;
  font-weight: 500;
`;

export const HondaAcuraInsightsSummary = () => {
  return (
    <StyledHondaAcuraInsightsSummary>
      Digital Sales Insights
    </StyledHondaAcuraInsightsSummary>
  );
};

export default HondaAcuraInsightsSummary;
