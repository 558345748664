import KeyInformationBannerIcon from "./key-information-banner-icon";
import KeyInformationBannerDataDetail from "./key-information-banner-data-detail";
import KeyInformationBannerSummary from "./key-information-banner-summary";
import styled from "styled-components";
import { KeyInformationBannerProps } from "./interfaces/key-information-banner-props";
import { Row } from "@vinsolutions/core-cx";
import { useCallback } from "react";

const StyledKeyInformationBanner = styled(Row)`
  border-top: 1px solid #cacfd9;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0em;
  margin-left: -1em;
  margin-right: -1em;
  &.banner-click-disabled {
    box-shadow: none;
    background-color: inherit;
    :hover {
      cursor: not-allowed;
      color: inherit;
      background-color: inherit;
      box-shadow: none;
      opacity: 0.8;
    }
  }
  :hover {
    background-color: #2b6bdd;
    color: #f2f2f4;
    box-shadow: inset 1px 1px 6px 4px #446fa4;
    cursor: pointer;
    .cx-loadingIndicator__dot {
      background: #f2f2f4;
    }
  }
  :hover.banner-loading {
    cursor: wait;
  }
`;

export function KeyInformationBanner({
  bannerName,
  bannerIcon,
  bannerDataDetail,
  bannerSummary,
  isLoading,
  onClick,
  onMouseEnter,
  onMouseLeave
}: KeyInformationBannerProps) {
  const bannerId = "keyInfo-" + bannerName.replace(/\s/g, "");

  const onBannerClick = useCallback(
    (mouseEvent: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (!isLoading && onClick) {
        onClick(mouseEvent);
      }
    },
    [onClick, isLoading]
  );

  return (
    <StyledKeyInformationBanner
      className={`${isLoading ? "banner-loading " : ""}${
        onClick ? "" : "banner-click-disabled"
      }`}
      htmlId={bannerId}
      onClick={onBannerClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <KeyInformationBannerIcon htmlId={`${bannerId}-icon`} icon={bannerIcon} />
      <KeyInformationBannerDataDetail
        dataDetail={bannerDataDetail}
        htmlId={`${bannerId}-date`}
        isLoading={isLoading}
      />
      <KeyInformationBannerSummary
        htmlId={`${bannerId}-blurb`}
        summary={bannerSummary}
      />
    </StyledKeyInformationBanner>
  );
}
