import AmpBannerProps from "./interfaces/amp-banner-props";
import { KeyInformationBanner } from "@vinsolutions/ui/key-information-banner";
import {
  AmpInfoResponse,
  getAMPInfoAsync
} from "@vinsolutions/data-access/marketing/coxauto.amp.vinconnect.bff";
import { useCallback, useEffect, useState } from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import AMPLightBulb from "./icons/amp-icon-small";
import AMPLightBulbInverse from "./icons/amp-icon-small-inverse";
import AmpIcon from "./icons/amp-icon";
import AmpSummary from "./components/amp-summary";
import KeyInformationAmpModal from "./components/amp-modal";
import { BANNER_NAME } from "./constants";
import AmpDataDetail from "./components/amp-data-detail";
import AMPIconSmall from "./icons/amp-icon-small";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";

export const BaseDisplayedBanner = {
  bannerName: BANNER_NAME,
  iconName: "AmpIconSmall",
  thumbnailName: "AmpIconSmall",
  thumbnailIcon: <AMPIconSmall />
};

export function AmpBanner({
  customerId,
  dealerId,
  onInitialize,
  onLoad,
  onClickGtmEvent
}: AmpBannerProps) {
  const [ampSummary, setAmpSummary] = useState<
    AmpInfoResponse | null | undefined
  >(null);
  const jwt = useReactOidc().oidcUser.access_token;
  const [isLoading, setIsLoading] = useState(false);
  const [iconBulb, setIconBulb] = useState(AMPLightBulb);
  const [showModal, setShowModal] = useState(false);

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "AmpBanner",
    "Loading Amp Banner"
  );

  const loadSummary = useCallback(async () => {
    const summaryResult = customerId
      ? await getAMPInfoAsync(customerId, dealerId, jwt)
      : null;
    setAmpSummary(summaryResult);
    setIsLoading(false);
    const isDisplayed = !!summaryResult;
    onLoad({
      ...BaseDisplayedBanner,
      customerId,
      isDisplayed
    });
    setInteractionAttributes({
      customerId: customerId || 0,
      dealerId,
      isDisplayed
    });
    endInteraction();
  }, [
    customerId,
    dealerId,
    jwt,
    onLoad,
    endInteraction,
    setInteractionAttributes
  ]);

  const onBannerClick = useCallback(async () => {
    if (ampSummary?.errorMessage) {
      // retry loading banner
      setIsLoading(true);
      await loadSummary();
    } else {
      onClickGtmEvent?.(BANNER_NAME);
      setShowModal(true);
    }
  }, [loadSummary, ampSummary, onClickGtmEvent]);

  useEffect(() => {
    if (onInitialize) {
      onInitialize(1);
    }
    loadSummary();
  }, [loadSummary, onInitialize]);

  return (
    ampSummary && (
      <>
        <KeyInformationBanner
          bannerDataDetail={
            <AmpDataDetail
              ampSummary={ampSummary}
              onDataErrorRetryClick={loadSummary}
            />
          }
          bannerIcon={<AmpIcon icon={iconBulb} />}
          bannerName={BANNER_NAME}
          bannerSummary={<AmpSummary ampSummary={ampSummary.summary} />}
          isLoading={isLoading}
          onClick={onBannerClick}
          onMouseEnter={() => setIconBulb(AMPLightBulbInverse)}
          onMouseLeave={() => setIconBulb(AMPLightBulb)}
        />
        <KeyInformationAmpModal
          ampSummary={ampSummary}
          showModal={showModal}
          onHide={() => setShowModal(false)}
        />
      </>
    )
  );
}

export default AmpBanner;
