import { useContext } from "react";
import { RouteManagerContext, RouteManagerType } from "./route-manager";

/**
 * Provides the current instance of the router manager
 * It will return undefined if the router manager is not initialized
 */
export const useRouteManager = () => {
  return useContext<RouteManagerType>(RouteManagerContext);
};
