import { environments } from "@vinsolutions/core/config";
export default {
  scriptUrl: "https://assets.bridge.coxautoservices.com/bridge-bar.js",
  enabledEnvironments: [
    environments.qaOnPrem,
    environments.qaCoxAutoDomain,
    environments.qaCDN,
    environments.prod,
    environments.prodCoxAutoDomain
  ],
  bridgeBarHeight: "35px"
};
