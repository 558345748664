import {
  GAContextLog,
  Metric,
  MetricsSection,
  PartyBar
} from "@vinsolutions/ccrm/interfaces";
import {
  adminMetricPropDictionary,
  metricPropDictionary
} from "./activity-dictionary";
import titleDictionary from "./title-dictionary";

// If we update the API with better contracts, this can all die...
export default function mapPartyBar(
  { Counts, DealerId, EndDate, StartDate }: PartyBar,
  hasManagerMetrics: boolean,
  onMetricClick: (GAContextLog: GAContextLog, url: string) => void,
  onWidgetMetricClick: () => void
) {
  const sections: { [index: string]: InternalMetrics } = {};
  const propDictionary = hasManagerMetrics
    ? adminMetricPropDictionary
    : metricPropDictionary;
  for (const [key, value] of Object.entries(Counts)) {
    if (Object.prototype.hasOwnProperty.call(propDictionary, key)) {
      // consider updating the options contract to take DealerId
      const { GAContextLog, label, usedForTotal, parent, sortValue, url } =
        propDictionary[key]({
          dealerId: DealerId,
          EndDate,
          StartDate
        });
      if (!Object.prototype.hasOwnProperty.call(sections, parent)) {
        sections[parent] = createMetricsSection(parent);
      }
      const onClick = () => {
        onMetricClick(GAContextLog, url);
      };
      const metric = {
        count: value || 0,
        label,
        usedForTotal,
        sortValue,
        onClick
      } as SortableMetric;
      sections[parent].metrics = [...sections[parent].metrics, metric];
      if (usedForTotal) sections[parent].titleCount += value || 0;
    }
  }
  const cardMetrics = [] as MetricsSection[];
  const widgetMetrics = [] as Metric[];

  Object.values(sections).forEach(section => {
    section.metrics.sort((a, b) => a.sortValue - b.sortValue);
    cardMetrics.push(section);
    widgetMetrics.push({
      count: section.titleCount,
      label: section.titleAbbr,
      onClick: onWidgetMetricClick
    });
  });

  return { cardMetrics, widgetMetrics };
}

export function createMetricsSection(key: string) {
  const { title, titleAbbr } = titleDictionary[key];
  return {
    metrics: [],
    title,
    titleAbbr,
    titleCount: 0
  } as InternalMetrics;
}

interface InternalMetrics {
  metrics: SortableMetric[];
  title: string;
  titleAbbr: string;
  titleCount: number;
}

interface SortableMetric extends Metric {
  sortValue: number;
}
