import {
  PortalMessage,
  RegisteredPortal,
  RegisteredPortalFactory
} from "@vinsolutions/utility/portals";
import CustomerDashboardCommunicationsButton, {
  CustomerDashboardCommunicationsButtonProps
} from "./customer-dashboard-communications-button";

export class CustomerDashboardCommunicationsButtonPortalFactory
  implements
    RegisteredPortalFactory<CustomerDashboardCommunicationsButtonProps>
{
  create({
    originDocument,
    originElement,
    portalId,
    portalKey,
    portalProps
  }: PortalMessage): RegisteredPortal<CustomerDashboardCommunicationsButtonProps> {
    const dataIsland: {
      customerId?: string | number;
      selectedLeadId?: string;
    } = portalProps?.data || {};

    return {
      originDocument,
      originElement,
      portalId,
      portalKey,
      portalComponent: CustomerDashboardCommunicationsButton,
      portalComponentProps: {
        customerId: dataIsland.customerId,
        portalId,
        selectedLeadId: dataIsland.selectedLeadId
      }
    };
  }
}
