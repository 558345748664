import {
  KeyInformationBanner,
  KeyInformationTextIcon
} from "@vinsolutions/ui/key-information-banner";
import OemInsightsBannerProps from "../../interfaces/oem-insights-banner-interface-props";
import MiniIconSmall from "./mini-icon";
import MiniInsightsSummary from "./bmw-mini-insights-summary";
import { parseBmwMiniInsights } from "../../data-parse/bmw-mini/bmw-summary-data";
import OemInsightsDate from "../oem-banner-date";
import { useEffect, useMemo } from "react";

const bannerName = "Mini Insights";
export const MiniBaseDisplayedBanner = {
  bannerName,
  iconName: "MiniIconSmall",
  thumbnailName: "MiniIconSmall",
  thumbnailIcon: <MiniIconSmall key={"mini-thumbnail-icon"} />
};

export function MiniInsightsBanner({
  oemInsightsSummary,
  onLoad,
  onClick
}: OemInsightsBannerProps) {
  const miniInsights = useMemo(
    () => parseBmwMiniInsights(oemInsightsSummary, "mini"),
    [oemInsightsSummary]
  );
  useEffect(() => {
    onLoad({
      ...MiniBaseDisplayedBanner,
      isDisplayed: !!miniInsights
    });
  }, [onLoad, miniInsights]);
  return (
    miniInsights && (
      <KeyInformationBanner
        bannerDataDetail={<OemInsightsDate oemInsights={miniInsights} />}
        bannerIcon={
          <KeyInformationTextIcon icon={<MiniIconSmall />} iconText="MINI" />
        }
        bannerName={bannerName}
        bannerSummary={<MiniInsightsSummary bmwMiniInsights={miniInsights} />}
        onClick={() => onClick && onClick(bannerName, "mini")}
      />
    )
  );
}

export default MiniInsightsBanner;
