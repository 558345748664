import { MetricsFilter } from "@vinsolutions/ccrm/interfaces";
const filters = [
  {
    value: 1,
    label: "Today"
  } as MetricsFilter,
  {
    value: 2,
    label: "Yesterday"
  } as MetricsFilter,
  {
    value: 3,
    label: "Last 7 Days"
  } as MetricsFilter,
  {
    value: 4,
    label: "Week to Date"
  } as MetricsFilter,
  {
    value: 5,
    label: "Month to Date"
  } as MetricsFilter,
  {
    value: 6,
    label: "Last Month"
  } as MetricsFilter
];

export default filters;
