import styled from "styled-components";
import { Alert, SelectInput } from "cx";
import { LoadingIndicator } from "@vinsolutions/core/components";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { MetricsFilter, MetricsSection } from "@vinsolutions/ccrm/interfaces";
import { filters } from "../util";
import { MemoizedCards } from "./memoized-cards";

/* eslint-disable-next-line */
export interface ActivityCardProps {
  hasError: boolean;
  metrics: MetricsSection[];
  hasManagerMetrics?: boolean;
  isLoading?: boolean;
  onFilterChange: (name: string, value: number) => void;
  savedFilter: MetricsFilter;
}

const StyledActivityCards = styled.div`
  line-height: 12px;
  width: 100%;
  height: 100%;
`;

const StyledFilterHeader = styled.h3`
  &:hover {
    cursor: pointer;
  }

  .ant-radio-wrapper {
    display: block;
    margin: 16px 4px;
    color: #000; //var($--theme-on-primary);
  }
`;

const StyledFilterTitle = styled.span`
  vertical-align: top;
  color: #000;
`;

const StyledActivityLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`;

const StyledActivityErrorAlertWrapper = styled.div`
  .cx-alert {
    line-height: 16px;
    color: #000;
  }
`;

const StyledNonManagerMetricHeader = styled.h4`
  color: #6d727a;
`;

const filterLookup = filters.reduce(
  (
    obj: { [key: string]: string },
    { label, value }: { label: string; value: number }
  ) => {
    obj[value.toString()] = label;
    return obj;
  },
  {}
);

export function ActivityCards({
  hasManagerMetrics,
  hasError,
  isLoading,
  metrics,
  onFilterChange,
  savedFilter
}: ActivityCardProps) {
  const handleFilterChange = ({ target: { value } }: RadioChangeEvent) => {
    onFilterChange(filterLookup[value], value);
  };

  return (
    <StyledActivityCards id="ccrm-metrics-cards">
      <StyledFilterHeader id="ccrm-metrics-cards-header">
        <div id="ccrm-metrics-cards-filter-title">
          <StyledFilterTitle id="ccrm-metrics-cards-filter-label">
            Activity
          </StyledFilterTitle>
        </div>
      </StyledFilterHeader>
      {hasError && (
        <StyledActivityErrorAlertWrapper>
          <Alert
            displayCloseButton
            htmlId="ccrm-metrics-cards-ui-error-alert"
            type="danger"
          >
            Unable to complete request at this time. Please try again later.
          </Alert>
        </StyledActivityErrorAlertWrapper>
      )}
      {isLoading && metrics.length === 0 && (
        <StyledActivityLoadingWrapper>
          <LoadingIndicator
            htmlId="ccrm-metric-cards-ui-loading-indicator"
            size="large"
          />
        </StyledActivityLoadingWrapper>
      )}
      {metrics.length > 0 && hasManagerMetrics && (
        <SelectInput
          displayDeselectOption={false}
          htmlId="ccrm-metrics-cards-filter"
          name="ccrm-metrics-cards-filter"
          options={filters}
          value={savedFilter.label}
          onChange={handleFilterChange}
        />
      )}
      {metrics.length > 0 && !hasManagerMetrics && (
        <div id="ccrm-non-manager-metrics-header">
          <StyledNonManagerMetricHeader>Today</StyledNonManagerMetricHeader>
        </div>
      )}
      <MemoizedCards {...{ isLoading, metrics }} />
    </StyledActivityCards>
  );
}

export default ActivityCards;
