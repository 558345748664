export const reverseMapStringEnum = <T extends { [index: string]: string }>(
  stringEnum: T,
  value: string
) => {
  if (value) {
    const reverseMapped = Object.keys(stringEnum).find(
      x => stringEnum[x] === value
    );

    return reverseMapped ? reverseMapped : null;
  }

  return null;
};
