import { DealerDashboardPage } from "@vinsolutions/page/dealer-dashboard";
import { RouteBuilder } from "../route-builder";
import { RouteButtonPosition } from "../route-config-types";

export const dealerDashboardRoute = RouteBuilder.create()
  .withRouteId("vinconnect", "dealer-dashboard")
  .withAliasId("navigation-sub-menu-tab-crm-dashboard-dealer-dashboard")
  .withLeftPaneComponent(<DealerDashboardPage />, {
    expansionButtonPosition: RouteButtonPosition.BOTTOM_RIGHT
  })
  .withRightPaneComponent("DealerDashboard1.aspx")
  .build();
