// Export enum to avoid typos when referencing environments throughout the app.
export enum envEnum {
  LOCAL = "localhost",
  DEV = "dev",
  QA = "qa",
  STAGING = "staging",
  PRODUCTION = "production"
}

const environmentConfig: { [key: string]: envEnum } = {
  // localhost
  localhost: envEnum.LOCAL,
  "127.0.0.1": envEnum.LOCAL,

  // legacy domains
  "dev-apps.vinmanager.com": envEnum.DEV,
  "dev-vinconnect.vinmanager.com": envEnum.DEV,
  "moto.qa.vinsolutions.com": envEnum.QA,
  "qa-apps.vinmanager.com": envEnum.QA,
  "staging-apps.vinmanager.com": envEnum.STAGING,
  "apps.vinmanager.com": envEnum.PRODUCTION,

  // New  domains
  "dev-vinsolutions.app.coxautoinc.com": envEnum.DEV,
  "qa-vinsolutions.app.coxautoinc.com": envEnum.QA,
  "staging-vinsolutions.app.coxautoinc.com": envEnum.STAGING,
  "vinsolutions.app.coxautoinc.com": envEnum.PRODUCTION
};

export const getEnvironment = (hostname: string): envEnum => {
  const lowerCaseHostName = hostname.toLowerCase();
  if (environmentConfig[lowerCaseHostName]) {
    return environmentConfig[lowerCaseHostName] as envEnum;
  }
  return envEnum.PRODUCTION;
};

const env = getEnvironment(window.location.hostname);

const returnValue = {
  env
};

export default returnValue;
