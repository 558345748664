import { SelectInput } from "cx";
import { DMCStatusParams, EventParam } from "../../../../types/dmcParams.type";

export const DMCDetailStatus = ({
  handleInputChange,
  status
}: DMCStatusParams) => {
  const onChange = (event: EventParam) => {
    handleInputChange(event, event.target.value !== null);
  };

  return (
    <SelectInput
      required
      data-testid="DMCStatus"
      htmlId="DMCStatus"
      label="Status"
      name="DMCStatus"
      options={[
        { value: "A", label: "Active" },
        { value: "I", label: "Inactive" }
      ]}
      value={status}
      {...{ onChange }}
    />
  );
};
