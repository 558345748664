import { lazy } from "react";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("VersionLazy");

export const VersionView = lazy(() => {
  logger.debug("VersionLazy: Initializing VersionLazy.");
  return import("@vinsolutions/ccrm/version").then(module => ({
    default: module.CcrmVersionWrapper
  }));
});
