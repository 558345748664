import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectNavigationItemsEntities } from "@vinsolutions/ccrm/store";
import {
  cardashboardActions,
  getCardashboardState
} from "@vinsolutions/legacy-cardashboard/store";
import { useNavigate } from "react-router-dom";
import { vinconnectHashPrefix } from "@vinsolutions/ccrm/util";

export const useGetHomePageUrl = () => {
  const navigationItemsDictionary = useSelector(
    selectNavigationItemsEntities,
    shallowEqual
  );
  const { frameBaseUrl } = useSelector(getCardashboardState, shallowEqual);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const homepagePath =
    (
      navigationItemsDictionary[
        "navigation-sub-menu-crm-dashboard-dashboard"
      ] || {}
    ).url || "/Cardashboard";
  const homepageUrl = frameBaseUrl + homepagePath;

  const goInitPage = () => {
    dispatch(
      cardashboardActions.updateFrameUrl({
        frameUrl: homepagePath,
        name: "initial homepage"
      })
    );

    if (window.location.pathname?.toLowerCase() === "/vinconnect/") {
      navigate(`${vinconnectHashPrefix}/CarDashboard`, { replace: false });
    } else {
      navigate(`/`, { replace: false });
    }
  };

  return { homepagePath, homepageUrl, goInitPage };
};
