import { DMCDetailDealerCode } from "./components/dmc-detail-dealer-code/dmc-detail-dealer-code.component";
import { DMCDetailSource } from "./components/dmc-detail-source/dmc-detail-source.component";
import { DMCDetailStatus } from "./components/dmc-detail-status/dmc-detail-status.component";
import { DMCDetailEnableNonOemLeadCollection } from "./components/dmc-detail-enable-non-oem-lead-collection/dmc-detail-enable-non-oem-lead-collection.component";
import { DMCDetailEnableOemLeadStatusUpdate } from "./components/dmc-detail-enable-oem-lead-status-update/dmc-detail-enable-oem-lead-status-update.component";
import { DMCAlert } from "../dmc-alert/dmc-alert.component";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { useDMCContext } from "../../hooks/useDmcContext.hook";
import {
  DMC_TYPES,
  rollbackHondaAcuraUpdate
} from "../../constants/dmcTypes.constant";
import { EventParam } from "../../types/dmcParams.type";
import { DMCActionEnum } from "../../Enums/dmcAction.enum";
import { useVinconnectFlags } from "@vinsolutions/core/third-party/launch-darkly";

export const DMCDetail = () => {
  // LD support to be removed
  const ldFlags = useVinconnectFlags();
  const hondaAcuraUpdateFlag = ldFlags["oem.honda.disposition.update.enabled"];
  if (!hondaAcuraUpdateFlag) rollbackHondaAcuraUpdate();

  const { dealerId } = useSelector(getProfileState, shallowEqual);
  const {
    state: { dealerManufacturerCode },
    dispatch
  } = useDMCContext();
  const [
    {
      DMCDealerCode,
      DMCSource,
      DMCStatus,
      DMCEnableNonOemLeadCollection,
      DMCEnableOemLeadStatusUpdate
    },
    setDMCFields
  ] = useState({
    DMCDealerCode: {
      value: dealerManufacturerCode.oemDealerId || "",
      isValid: dealerManufacturerCode.oemDealerId !== ""
    },
    DMCSource: {
      value: dealerManufacturerCode.oemId,
      isValid: true
    },
    DMCStatus: {
      value: dealerManufacturerCode.status,
      isValid: true
    },
    DMCEnableNonOemLeadCollection: {
      value: dealerManufacturerCode.dealerInitiatedLeadEnabled
    },
    DMCEnableOemLeadStatusUpdate: {
      value: dealerManufacturerCode.oemLeadUpdateEnabled
    }
  });
  const [warningMsg, setWarningMessage] = useState(
    DMC_TYPES[DMCSource.value]?.sourceWarning
  );
  const [selectNonOemLeadCollection, setSelectNonOemLeadCollection] = useState(
    DMC_TYPES[DMCSource.value]?.selectNonOemLeadCollection || false
  );
  const [selectOemLeadStatusUpdate, setSelectOemLeadStatusUpdate] = useState(
    DMC_TYPES[DMCSource.value]?.selectOemLeadStatusUpdate || false
  );

  useEffect(() => {
    const {
      name,
      selectNonOemLeadCollection: enableNonOemLeadCollection = false,
      selectOemLeadStatusUpdate: enableOemLeadStatusUpdate = false
    } = DMC_TYPES[DMCSource.value] || {};
    const isDMCDetailValid =
      DMCDealerCode.isValid && DMCSource.isValid && DMCStatus.isValid;
    const dmc = {
      ...dealerManufacturerCode,
      oemDealerId: DMCDealerCode.value,
      oemId: DMCSource.value,
      oemName: name,
      status: DMCStatus.value,
      oemLeadUpdateEnabled: enableOemLeadStatusUpdate
        ? DMCEnableOemLeadStatusUpdate.value
        : false,
      dealerInitiatedLeadEnabled: enableNonOemLeadCollection
        ? DMCEnableNonOemLeadCollection.value
        : false,
      dealerId: dealerId || 0
    };

    if (dispatch) {
      dispatch({
        payload: {
          dealerManufacturerCode: dmc,
          isSaveable: isDMCDetailValid
        },
        type: DMCActionEnum.DMC_UPDATE
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    DMCDealerCode,
    DMCStatus,
    DMCSource,
    DMCEnableOemLeadStatusUpdate,
    DMCEnableNonOemLeadCollection
  ]);

  const handleInputChange = (event: EventParam, isValid?: boolean) => {
    const { name, value } = event.target;

    if (name === "DMCSource") {
      const {
        sourceWarning = "",
        selectNonOemLeadCollection = false,
        selectOemLeadStatusUpdate = false
      } = DMC_TYPES[Number(value) as keyof typeof DMC_TYPES] || {};

      setWarningMessage(sourceWarning);
      setSelectNonOemLeadCollection(selectNonOemLeadCollection);
      setSelectOemLeadStatusUpdate(selectOemLeadStatusUpdate);
    }

    setDMCFields(prevFieldValues => ({
      ...prevFieldValues,
      [name]: { value, isValid }
    }));
  };

  return (
    <>
      <DMCDetailDealerCode
        dealerCode={DMCDealerCode.value}
        oemId={DMCSource.value}
        {...{ handleInputChange }}
      />
      <DMCDetailSource {...{ handleInputChange }} oemId={DMCSource.value} />
      <DMCDetailStatus {...{ handleInputChange }} status={DMCStatus.value} />
      {selectNonOemLeadCollection ? (
        <DMCDetailEnableNonOemLeadCollection
          enableNonOemLeadCollection={DMCEnableNonOemLeadCollection.value}
          name="DMCEnableNonOemLeadCollection"
          {...{ handleInputChange }}
        />
      ) : null}
      {selectOemLeadStatusUpdate ? (
        <DMCDetailEnableOemLeadStatusUpdate
          enableOemLeadStatusUpdate={DMCEnableOemLeadStatusUpdate.value}
          name="DMCEnableOemLeadStatusUpdate"
          {...{ handleInputChange }}
        />
      ) : null}
      {warningMsg ? (
        <DMCAlert
          htmlId="dealer-manufacturer-codes-warning-alert"
          type="danger"
          value={warningMsg}
        />
      ) : null}
    </>
  );
};
