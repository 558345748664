export default function KbbIconInverse() {
  return (
    <svg
      height="47px"
      version="1.1"
      viewBox="0 0 108 47"
      width="108px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Kelley Blue Book Instant Cash Offer</title>
      <defs>
        <radialGradient
          cx="2.42402165%"
          cy="-42.3219792%"
          fx="2.42402165%"
          fy="-42.3219792%"
          gradientTransform="translate(0.024240,-0.423220),scale(1.000000,0.622616),translate(-0.024240,0.423220)"
          id="radialGradient-1"
          r="110.834027%"
        >
          <stop offset="0%" stopColor="#346AB2" />
          <stop offset="51.24%" stopColor="#346AB2" />
          <stop offset="94.32%" stopColor="#213E76" />
        </radialGradient>
        <radialGradient
          cx="2.42402165%"
          cy="-42.3221153%"
          fx="2.42402165%"
          fy="-42.3221153%"
          gradientTransform="translate(0.024240,-0.423221),scale(1.000000,0.819050),translate(-0.024240,0.423221)"
          id="radialGradient-2"
          r="110.835068%"
        >
          <stop offset="0%" stopColor="#346AB2" />
          <stop offset="51.24%" stopColor="#346AB2" />
          <stop offset="94.32%" stopColor="#213E76" />
        </radialGradient>
        <linearGradient
          id="linearGradient-3"
          x1="49.2427704%"
          x2="49.6447517%"
          y1="-26.3578547%"
          y2="14.1767505%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="37.63%" stopColor="#F3D070" />
          <stop offset="46.57%" stopColor="#EDC55C" />
          <stop offset="56.76%" stopColor="#E3B54E" />
          <stop offset="62.37%" stopColor="#AE732A" />
          <stop offset="65.28%" stopColor="#AE732A" />
          <stop offset="71.52%" stopColor="#F1D76F" />
          <stop offset="79.21%" stopColor="#F6E57E" />
          <stop offset="84.41%" stopColor="#F8F3A6" />
          <stop offset="89.19%" stopColor="#FFF1C6" />
          <stop offset="93.76%" stopColor="#E4AE25" />
          <stop offset="94.39%" stopColor="#E4AE25" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
        <linearGradient
          id="linearGradient-4"
          x1="49.3965151%"
          x2="49.7987881%"
          y1="-10.809911%"
          y2="29.7251211%"
        >
          <stop offset="0%" stopColor="#FFEEC3" />
          <stop offset="17.67%" stopColor="#F6ECA3" />
          <stop offset="37.63%" stopColor="#F3D070" />
          <stop offset="46.57%" stopColor="#EDC55C" />
          <stop offset="56.76%" stopColor="#E3B54E" />
          <stop offset="62.37%" stopColor="#AE732A" />
          <stop offset="65.28%" stopColor="#AE732A" />
          <stop offset="71.52%" stopColor="#F1D76F" />
          <stop offset="79.21%" stopColor="#F6E57E" />
          <stop offset="84.41%" stopColor="#F8F3A6" />
          <stop offset="89.19%" stopColor="#FFF1C6" />
          <stop offset="93.76%" stopColor="#E4AE25" />
          <stop offset="94.39%" stopColor="#E4AE25" />
          <stop offset="100%" stopColor="#FBCB4B" />
        </linearGradient>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        id="logo/KBB-white"
        stroke="none"
        strokeWidth="1"
      >
        <g
          fillRule="nonzero"
          id="KBB-ICO"
          transform="translate(0.004954, 5.000000)"
        >
          <g id="Group">
            <g transform="translate(5.940000, 0.000000)">
              <polyline
                fill="url(#radialGradient-1)"
                id="Path"
                points="0.432495413 22.9797248 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 22.9797248"
              />
              <g id="Path" transform="translate(0.430018, 12.824257)">
                <polygon
                  fill="#FFFFFF"
                  points="0 6.38190826 0 23.816477 7.14880734 18.3292844 14.293156 23.816477 14.293156 6.38190826"
                />
                <polyline
                  fill="#2B6BDD"
                  points="13.4445138 23.1674862 13.443523 6.38042202 12.7608441 6.38042202 12.7648074 22.6448257"
                />
                <polyline
                  fill="#2B6BDD"
                  points="1.52289908 22.6542385 1.52289908 0 0.839724771 0 0.838238531 23.1818532"
                />
              </g>
              <g id="Path">
                <polyline
                  fill="url(#radialGradient-2)"
                  points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
                />
                <polyline
                  fill="url(#linearGradient-3)"
                  points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
                />
                <polyline
                  fill="url(#linearGradient-4)"
                  points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
                />
              </g>
              <g id="Path">
                <polyline
                  fill="url(#radialGradient-2)"
                  points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
                />
                <polyline
                  fill="url(#linearGradient-3)"
                  points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
                />
                <polyline
                  fill="url(#linearGradient-4)"
                  points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
                />
              </g>
              <g id="Path">
                <polyline
                  fill="url(#radialGradient-2)"
                  points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
                />
                <polyline
                  fill="url(#linearGradient-3)"
                  points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
                />
                <polyline
                  fill="url(#linearGradient-4)"
                  points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
                />
              </g>
              <polyline
                fill="url(#radialGradient-2)"
                id="Path"
                points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
              />
              <polyline
                fill="url(#linearGradient-3)"
                id="Path"
                points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
              />
              <polyline
                fill="url(#linearGradient-4)"
                id="Path"
                points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
              />
              <polyline
                fill="url(#radialGradient-2)"
                id="Path"
                points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
              />
              <polyline
                fill="url(#linearGradient-3)"
                id="Path"
                points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
              />
              <polyline
                fill="url(#linearGradient-4)"
                id="Path"
                points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
              />
              <polyline
                fill="url(#radialGradient-2)"
                id="Path"
                points="0.432495413 17.479156 0.432495413 0.044587156 14.7122752 0.044587156 14.7122752 17.479156"
              />
              <polyline
                fill="url(#linearGradient-3)"
                id="Path"
                points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
              />
              <polyline
                fill="url(#linearGradient-4)"
                id="Path"
                points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
              />
              <rect
                fill="#FFFFFF"
                height="21.8273945"
                id="Rectangle"
                width="14.2797798"
                x="0.432495413"
                y="0.044587156"
              />
              <polyline
                fill="url(#linearGradient-3)"
                id="Path"
                points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
              />
              <polyline
                fill="url(#linearGradient-4)"
                id="Path"
                points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
              />
              <polyline
                fill="#2B6BDD"
                id="Path"
                points="13.8735413 23.2581468 13.8735413 0.044587156 13.1908624 0.044587156 13.1908624 23.2581468"
              />
              <polyline
                fill="#2B6BDD"
                id="Path"
                points="1.95291743 23.2581468 1.95291743 0.044587156 1.26974312 0.044587156 1.26974312 23.2581468"
              />
            </g>
            <g id="Path" transform="translate(0.000000, 3.172624)">
              <polygon
                fill="#2B6BDD"
                points="23.0728624 23.0778165 20.9827156 22.6180734 21.0243303 24.7582569 19.0634863 23.899211 18.6874679 26.0066972 16.930734 24.7825321 16.1509542 26.7765688 14.6686789 25.2328624 13.5138716 27.0351743 12.3605505 25.2313761 10.8772844 26.7745871 10.0975046 24.7805504 8.34126605 26.0047156 7.9652477 23.8972293 6.00440367 24.754789 6.048 22.6141101 3.95636697 23.0728624 4.41611009 20.9827156 2.27691743 21.0243303 3.13447706 19.0639816 1.02798165 18.6869725 2.25214679 16.9317248 0.259100918 16.1514495 1.80280734 14.6681835 0 13.5138715 1.80379816 12.3605504 0.260091744 10.876789 2.25363303 10.0975046 1.0299633 8.34126605 3.13744954 7.96524771 2.27988991 6.00440367 4.42007339 6.04750459 3.96181651 3.95686238 6.0519633 4.4166055 6.01034862 2.27642202 7.97069725 3.13398165 8.34671559 1.02748624 10.1034496 2.2526422 10.8837248 0.258605505 12.3664954 1.80280734 13.5213028 0 14.6746239 1.80330275 16.1578899 0.260091743 16.9371743 2.25363303 18.6934129 1.0299633 19.0684404 3.13744954 21.0297798 2.27988991 20.9871743 4.41957798 23.078312 3.9613211 22.6185688 6.0519633 24.7582569 6.00985321 23.8997065 7.97020183 26.0066973 8.34721101 24.7825321 10.1034495 26.7760734 10.8832293 25.2318716 12.366 27.0346789 13.5203119 25.2318716 14.673633 26.7745872 16.1573945 24.7810459 16.9366789 26.0042202 18.6929174 23.8972294 19.0684404 24.754789 21.028789 22.6141101 20.9866789"
              />
              <polygon
                fill="#FFFFFF"
                points="23.004 23.0089541 20.929211 22.5526789 20.9703303 24.6775046 19.0233578 23.8248991 18.650312 25.9170275 16.9059633 24.7017798 16.1316331 26.6809541 14.6602569 25.1481468 13.5138716 26.9380734 12.3689725 25.147156 10.8961101 26.6789725 10.1222753 24.6997982 8.37891743 25.9150459 8.00537615 23.8229174 6.05840367 24.6740367 6.102 22.5487156 4.02522936 23.004 4.482 20.9287156 2.35766972 20.9703303 3.2092844 19.0238532 1.11814679 18.6498165 2.33339449 16.9074495 0.354715597 16.1326238 1.88702752 14.6602569 0.0975963306 13.5138715 1.88851376 12.3694679 0.355706422 10.8961101 2.33488073 10.1222752 1.12012844 8.37891743 3.21225688 8.00587156 2.3606422 6.05889908 4.48546789 6.10150459 4.0306789 4.02622018 6.10546789 4.48299082 6.06434862 2.35816514 8.01082569 3.20878899 8.38436697 1.11765138 10.1277248 2.33388991 10.9025505 0.354715596 12.374422 1.88752293 13.5213028 0.09759633 14.6662019 1.88801835 16.1390642 0.356201835 16.9124037 2.33537615 18.6562569 1.12062385 19.028312 3.21225688 20.9752844 2.36113761 20.9331743 4.48546789 23.0089542 4.03018349 22.5526789 6.1059633 24.6775046 6.06385321 23.8248991 8.01033027 25.9170276 8.38436697 24.7012844 10.1282202 26.6804587 10.9025504 25.1476514 12.374422 26.937578 13.5203119 25.147156 14.665211 26.6789725 16.1385688 24.6997982 16.9119082 25.9140551 18.6557615 23.822422 19.0288073 24.6735413 20.9752844 22.5487156 20.9331743"
              />
            </g>
            <path
              d="M13.5113945,5.54366972 C7.34499082,5.54366972 2.35073394,10.5433761 2.35073394,16.7058165 C2.35073394,22.8642936 7.34499082,27.8545872 13.5113945,27.8545872 C19.6748257,27.8545872 24.6794863,22.8642936 24.6794863,16.7058165 C24.6794863,10.5433761 19.6748257,5.54366972 13.5113945,5.54366972 Z"
              fill="#FFFFFF"
              id="Path"
              stroke="#2B6BDD"
              strokeWidth="0.1663"
            />
            <polygon
              fill="#2B6BDD"
              id="Path"
              points="20.2425688 12.3595596 19.1650459 13.1115963 18.8083487 12.6042936 19.8853762 11.8522569 20.8816514 9.98405505 21.2720367 10.5389174 20.557156 11.7665505 21.9547156 11.5089358 22.3451009 12.0637982"
            />
            <polygon
              fill="#2B6BDD"
              id="Path"
              points="16.8063853 10.7846422 18.7484037 8.24218349 20.4293395 9.51638532 20.0919633 9.9592844 18.9089175 9.06308257 18.4536331 9.65955963 19.4608074 10.4229908 19.121945 10.8658899 18.1152661 10.1024587 17.6436331 10.7202385 18.8256881 11.6169358 18.4873211 12.0598349"
            />
            <polygon
              fill="#2B6BDD"
              id="Path"
              points="14.5582019 10.0226972 15.4167523 6.94420183 16.0196698 7.11115596 15.310734 9.65361468 16.7132477 10.0415229 16.5636331 10.5775596"
            />
            <polygon
              fill="#2B6BDD"
              id="Path"
              points="12.1381101 10.0950275 11.8492844 6.91249541 12.4730092 6.85651376 12.7108074 9.4846789 14.1598899 9.35438532 14.210422 9.90875229"
            />
            <polygon
              fill="#2B6BDD"
              id="Path"
              points="9.79629358 11.0353211 8.46016514 8.1307156 10.3808808 7.25433028 10.6137248 7.76014679 9.26223853 8.37693578 9.57533945 9.05862385 10.7261835 8.53299083 10.9590276 9.03880734 9.80768807 9.56394495 10.1326789 10.2694128 11.4836698 9.65311927 11.7165138 10.1584404"
            />
            <polygon
              fill="#2B6BDD"
              id="Path"
              points="9.06506422 11.7224587 7.42623853 11.3746789 7.52284404 12.010789 8.24515596 12.6468991 7.83049541 13.113578 5.42823853 10.9976697 5.84339449 10.5304954 6.88871559 11.4519633 6.59146789 9.68730275 7.09579816 9.11906422 7.34647706 10.7529358 9.54809174 11.1775046"
            />
            <polygon
              fill="#2B6BDD"
              id="Path"
              points="22.8940184 18.4694862 22.1335597 16.8628624 21.7511009 17.4083119 21.7511009 18.4694862 21.173945 18.4694862 21.173945 14.9396697 21.7511009 14.9396697 21.7511009 16.4764404 22.793945 14.9396697 23.4969358 14.9396697 22.5160184 16.3426789 23.5672844 18.4694862"
            />
            <path
              d="M20.5006789,18.112789 C20.2926055,18.3604954 20.0394496,18.499211 19.7025688,18.499211 C19.3661835,18.499211 19.1085688,18.36 18.9004954,18.112789 C18.6012661,17.7555963 18.6096881,17.3146789 18.6096881,16.7048257 C18.6096881,16.0949725 18.6012661,15.654055 18.9004954,15.2973578 C19.1085688,15.0496514 19.3656881,14.9104404 19.7025688,14.9104404 C20.0389542,14.9104404 20.2926055,15.0496514 20.5006789,15.2973578 C20.7994129,15.654055 20.7954548,16.0954679 20.7954548,16.7048257 C20.7954548,17.3146789 20.7994129,17.7555963 20.5006789,18.112789 Z M20.0721468,15.7130092 C19.9889175,15.5990642 19.8606055,15.5247523 19.7025688,15.5247523 C19.5445321,15.5247523 19.4112661,15.5990642 19.3285321,15.7130092 C19.2160734,15.861633 19.1873395,16.0256147 19.1873395,16.7043303 C19.1873395,17.3835413 19.2160734,17.5465321 19.3285321,17.6956514 C19.4112661,17.8095963 19.5445321,17.8844037 19.7025688,17.8844037 C19.8606055,17.8844037 19.9894129,17.8100917 20.0721468,17.6956514 C20.1846055,17.5465321 20.2177982,17.3835413 20.2177982,16.7043303 C20.2177982,16.0256147 20.1846055,15.8621284 20.0721468,15.7130092 Z"
              fill="#2B6BDD"
              id="Shape"
            />
            <path
              d="M18.036,18.112789 C17.8279266,18.3604954 17.5747707,18.499211 17.2383853,18.499211 C16.902,18.499211 16.6438899,18.36 16.436312,18.112789 C16.1365872,17.7555963 16.1455046,17.3146789 16.1455046,16.7048257 C16.1455046,16.0949725 16.1365872,15.654055 16.436312,15.2973578 C16.6443853,15.0496514 16.902,14.9104404 17.2383853,14.9104404 C17.5747707,14.9104404 17.828422,15.0496514 18.036,15.2973578 C18.3352294,15.654055 18.3312713,16.0954679 18.3312713,16.7048257 C18.3312713,17.3146789 18.3352294,17.7555963 18.036,18.112789 Z M17.6079633,15.7130092 C17.524734,15.5990642 17.3959266,15.5247523 17.2383853,15.5247523 C17.0798532,15.5247523 16.9470826,15.5990642 16.8643487,15.7130092 C16.7518899,15.861633 16.723156,16.0256147 16.723156,16.7043303 C16.723156,17.3835413 16.7518899,17.5465321 16.8643487,17.6956514 C16.947578,17.8095963 17.0803487,17.8844037 17.2383853,17.8844037 C17.3959266,17.8844037 17.5252294,17.8100917 17.6079633,17.6956514 C17.720422,17.5465321 17.7536147,17.3835413 17.7536147,16.7043303 C17.7536147,16.0256147 17.720422,15.8621284 17.6079633,15.7130092 Z"
              fill="#2B6BDD"
              id="Shape"
            />
            <path
              d="M14.9604771,18.4694862 L13.7224404,18.4694862 L13.7224404,14.9396697 L14.9109358,14.9396697 C15.4885872,14.9396697 15.8249725,15.3260917 15.8249725,15.9309908 C15.8249725,16.322367 15.6089725,16.5755229 15.4598532,16.6597431 C15.6382019,16.7588257 15.8670826,16.9817615 15.8670826,17.4528991 C15.8665872,18.112789 15.4885872,18.4694862 14.9604771,18.4694862 Z M14.8653578,15.5544771 L14.3000918,15.5544771 L14.3000918,16.3674495 L14.8653578,16.3674495 C15.1100918,16.3674495 15.2478165,16.2039633 15.2478165,15.9607156 C15.2473211,15.7184587 15.1100918,15.5544771 14.8653578,15.5544771 Z M14.9025138,16.9877064 L14.2995964,16.9877064 L14.2995964,17.8551743 L14.9025138,17.8551743 C15.1645872,17.8551743 15.2889358,17.6565138 15.2889358,17.4187156 C15.2889358,17.180422 15.1645872,16.9877064 14.9025138,16.9877064 Z"
              fill="#2B6BDD"
              id="Shape"
            />
            <polygon
              fill="#2B6BDD"
              id="Path"
              points="10.6682202 18.4694862 10.6682202 14.9396697 12.6166789 14.9396697 12.6166789 15.5544771 11.2453762 15.5544771 11.2453762 16.3823119 12.4130642 16.3823119 12.4130642 16.9971193 11.2453762 16.9971193 11.2453762 17.8551743 12.6166789 17.8551743 12.6166789 18.4699817 10.6682202 18.4699817"
            />
            <path
              d="M9.14680734,18.499211 C8.5399266,18.499211 8.05788991,18.0082569 8.05788991,17.2596881 L8.05788991,14.9396697 L8.63554128,14.9396697 L8.63554128,17.2349174 C8.63554128,17.6416514 8.83519266,17.8844037 9.14680734,17.8844037 C9.45842202,17.8844037 9.6620367,17.6416514 9.6620367,17.2349174 L9.6620367,14.9396697 L10.2352302,14.9396697 L10.2352302,17.2596881 C10.2357248,18.0087523 9.75368807,18.499211 9.14680734,18.499211 Z"
              fill="#2B6BDD"
              id="Path"
            />
            <polygon
              fill="#2B6BDD"
              id="Path"
              points="5.97666055 18.4694862 5.97666055 14.9396697 6.55431193 14.9396697 6.55431193 17.8546789 7.89638532 17.8546789 7.89638532 18.4694862"
            />
            <path
              d="M4.69304587,18.4694862 L3.45500917,18.4694862 L3.45500917,14.9396697 L4.64300917,14.9396697 C5.22066055,14.9396697 5.55704587,15.3260917 5.55704587,15.9309908 C5.55704587,16.322367 5.34104587,16.5755229 5.19143119,16.6597431 C5.36977981,16.7588257 5.59866055,16.9817615 5.59866055,17.4528991 C5.59816514,18.112789 5.22016514,18.4694862 4.69304587,18.4694862 Z M4.59693578,15.5544771 L4.03166972,15.5544771 L4.03166972,16.3674495 L4.59693578,16.3674495 C4.84216514,16.3674495 4.97939449,16.2039633 4.97939449,15.9607156 C4.97939449,15.7184587 4.84216514,15.5544771 4.59693578,15.5544771 Z M4.63409174,16.9877064 L4.03166972,16.9877064 L4.03166972,17.8551743 L4.63409174,17.8551743 C4.89616514,17.8551743 5.02100917,17.6565138 5.02100917,17.4187156 C5.02100917,17.180422 4.89616514,16.9877064 4.63409174,16.9877064 Z"
              fill="#2B6BDD"
              id="Shape"
            />
            <g fill="#2B6BDD" transform="translate(5.495034, 20.140562)">
              <path
                d="M0.571296457,1.69129183 C0.390966182,1.66057623 0.242837742,1.57239275 0.125424898,1.39949366 C0.00801205372,1.22659458 -0.0212173041,1.05220926 0.0139570078,0.869401922 C0.0649845311,0.606337703 0.258690953,0.475548712 0.523736824,0.289273483 C0.788287283,0.102998254 0.977534988,-0.0362127556 1.23663591,0.00837440045 C1.41696618,0.0390899974 1.56658086,0.129255134 1.68399371,0.301658804 C1.80091114,0.474557887 1.82914967,0.647456969 1.79447077,0.830264312 C1.74344325,1.09283311 1.55072765,1.22560376 1.28568178,1.41187899 C1.0201405,1.59914504 0.829901962,1.73587899 0.571296457,1.69129183 Z M1.46353499,0.738117522 C1.48384692,0.660337703 1.4714616,0.571658804 1.41647077,0.49090651 C1.36147995,0.409658804 1.28221389,0.36358541 1.20393866,0.356154217 C1.10039738,0.343273483 1.01914967,0.378447795 0.724874439,0.586521189 C0.430103797,0.793603762 0.369168017,0.858998252 0.343901962,0.962044122 C0.323094622,1.03982394 0.337461595,1.1309799 0.391957008,1.2117322 C0.446947833,1.2929799 0.524232237,1.33608082 0.603002879,1.34400743 C0.707039577,1.35589733 0.788782696,1.32320009 1.08404875,1.1156221 C1.3788194,0.908044122 1.43777352,0.841163392 1.46353499,0.738117522 Z"
                id="Shape"
              />
              <polygon
                id="Path"
                points="2.1620671 1.56198908 1.83063591 1.88450284 2.3240671 2.41211752 2.08924141 2.64000743 1.59531481 2.11288816 1.04887444 2.64545697 0.804635907 2.3843744 2.15166343 1.07202577 2.97503958 1.9523744 2.74021389 2.18075972"
              />
              <polygon
                id="Path"
                points="3.2688194 2.61325513 2.99832398 2.99075972 3.57597536 3.42028266 3.384746 3.68731018 2.80709462 3.25729183 2.36122306 3.88002577 2.07536985 3.66749366 3.17469095 2.13320009 4.13777352 2.85055788 3.94654417 3.11808082"
              />
              <polygon
                id="Path"
                points="3.5081038 4.63453954 4.36318637 2.94617256 4.67777352 3.11164045 3.82219554 4.80000743"
              />
              <path
                d="M4.83135151,5.23894321 C4.64507628,5.16859458 4.51725976,5.04127348 4.44245242,4.87184229 C4.33494784,4.62710834 4.42015884,4.4056588 4.52964508,4.0970166 C4.64012215,3.7883744 4.7154249,3.56345697 4.95223224,3.44703495 C5.11621389,3.36628266 5.2940671,3.35290651 5.48034233,3.42226431 C5.79839738,3.54066798 5.97823224,3.82404412 5.90491114,4.21938357 L5.5680304,4.09453954 C5.58636068,3.9389799 5.53929646,3.79679642 5.37234233,3.7343744 C5.28118637,3.70019091 5.19498453,3.7076221 5.12661756,3.74725513 C5.03496618,3.79877807 4.9854249,3.8770533 4.8620671,4.2203744 C4.73920472,4.5636955 4.72781022,4.65584229 4.7654616,4.75542027 C4.7927093,4.83072302 4.8541405,4.89364045 4.94529646,4.92782394 C5.112746,4.99024596 5.23808545,4.91197073 5.32280105,4.78118174 L5.66017719,4.9070166 C5.46498453,5.25925513 5.14643407,5.35635605 4.83135151,5.23894321 Z"
                id="Path"
              />
              <polygon
                id="Path"
                points="5.87568178 5.56046614 6.25913132 3.6996955 6.60492949 3.77351201 6.22247077 5.63378724"
              />
              <path
                d="M7.66263591,5.80916339 L7.58237903,5.46435605 L6.9408194,5.41184229 L6.80557169,5.73782394 L6.43847077,5.70760376 L7.24450747,3.86912669 L7.52094784,3.89191568 L8.03072765,5.83938357 L7.66263591,5.80916339 Z M7.34755334,4.43934688 L7.06467261,5.10964045 L7.51351664,5.14679642 L7.34755334,4.43934688 Z"
                id="Shape"
              />
              <polygon
                id="Path"
                points="8.2640671 5.80817256 8.1560671 3.91123678 8.50929646 3.89042944 8.59797536 5.45593403 9.41837903 5.40787899 9.43720472 5.73782394"
              />
              <path
                d="M11.2533882,4.97934688 C11.1696634,5.17751201 11.0264891,5.29294321 10.8446726,5.36031935 C10.6579019,5.42918174 10.4800487,5.41481477 10.3160671,5.33406247 C10.0802505,5.21615421 10.0059386,4.99123678 9.89595701,4.68209917 C9.78647077,4.37345697 9.702746,4.15200743 9.810746,3.90727348 C9.88604875,3.73784229 10.0094065,3.61250284 10.2006359,3.5416588 C10.5949845,3.39600743 10.8922322,3.59268632 11.0448194,3.87754871 L10.7104157,4.00090651 C10.6118285,3.84683311 10.4810395,3.79035605 10.3111129,3.85277807 C10.2204524,3.88646614 10.1595166,3.95136522 10.1327643,4.02666798 C10.0946175,4.12575055 10.1070028,4.21492486 10.2293698,4.55923678 C10.3512414,4.90354871 10.3978102,4.98231935 10.4894616,5.03384229 C10.5578285,5.07347532 10.6445258,5.08140192 10.7351863,5.04771385 C10.8357551,5.01055788 10.9036267,4.94318174 10.9363239,4.85995238 C10.9680304,4.77424596 10.96159,4.68606247 10.9283973,4.59292486 L10.9051129,4.5250533 L10.5910212,4.6409799 L10.4879753,4.34918174 L11.1310212,4.11187899 L11.223168,4.37296155 C11.3143239,4.63255788 11.3267093,4.80446614 11.2533882,4.97934688 Z"
                id="Path"
              />
              <path
                d="M12.5067827,4.65039275 C12.1822872,4.83468632 11.7983423,4.75046614 11.6061221,4.39822761 L11.009645,3.30683311 L11.3182872,3.13145697 L11.9088194,4.21096155 C12.0138469,4.40219091 12.1822872,4.4556955 12.3497368,4.36107165 C12.5157001,4.26595238 12.5627643,4.09057623 12.4587276,3.89934688 L11.8681955,2.81984229 L12.1753515,2.64545697 L12.7708377,3.73635605 C12.9635533,4.08859458 12.8322689,4.46609917 12.5067827,4.65039275 Z"
                id="Path"
              />
              <polygon
                id="Path"
                points="13.562012 3.97019091 12.3854065 2.49584229 12.6598652 2.26844779 13.8354799 3.742301"
              />
              <path
                d="M14.8793539,2.5270533 C14.880801,2.70193403 14.7985625,2.86393403 14.6643056,2.99918174 L14.1991129,3.46734688 L12.8882505,2.11536522 L13.3529478,1.64670467 C13.4872047,1.51195238 13.6467276,1.42921844 13.8186359,1.43268632 C14.1109294,1.43912669 14.2768927,1.63927348 14.4740671,1.84239275 C14.670746,2.04650284 14.8768377,2.22931018 14.8793539,2.5270533 Z M13.8275533,1.80028266 C13.7309478,1.78938357 13.6457368,1.81861293 13.5590395,1.906301 L13.368801,2.09802577 L14.2233882,2.97936522 L14.4126359,2.78764045 C14.5003239,2.69995238 14.5300487,2.61325513 14.5201405,2.51466798 C14.5082505,2.40666798 14.4220487,2.30213587 14.2228927,2.09703495 C14.0242322,1.89193403 13.9335716,1.81316339 13.8275533,1.80028266 Z"
                id="Shape"
              />
              <polygon
                id="Path"
                points="15.3890946 2.13320009 13.8384524 1.08044779 14.4993331 0.0663377034 14.7693331 0.249640455 14.3046359 0.963034952 14.6677735 1.20975055 15.0641038 0.6023744 15.3341038 0.785677152 14.9387643 1.39255788 15.3147827 1.64868632 15.7799753 0.935291832 16.0504707 1.11809917"
              />
            </g>
            <g
              fill="#2B6BDD"
              id="Path"
              transform="translate(8.243670, 11.017982)"
            >
              <path d="M5.2419633,0.305174311 C7.44258715,0.305174311 9.32911926,1.63436698 10.1534863,3.53179817 L10.4829358,3.53179817 C9.64073394,1.46196331 7.61449541,0 5.24146789,0 C2.86844037,0 0.842697251,1.46196331 0,3.53179817 L0.329449541,3.53179817 C1.15431193,1.63436698 3.04084404,0.305174311 5.2419633,0.305174311 Z" />
              <path d="M5.2419633,11.0214495 C3.05719266,11.0214495 1.18155963,9.71157798 0.347779816,7.83594496 L0.016844036,7.83594496 C0.869449541,9.88447702 2.88478899,11.3266238 5.2419633,11.3266238 C7.59913761,11.3266238 9.61447706,9.88398162 10.4670826,7.83594496 L10.1361468,7.83594496 C9.30187156,9.71108257 7.42673394,11.0214495 5.2419633,11.0214495 Z" />
            </g>
            <path
              d="M22.0959083,36.0586239 C22.0959083,36.358844 21.8605872,36.5946606 21.5534312,36.5946606 C21.2502386,36.5946606 21.0079817,36.3593394 21.0079817,36.0586239 C21.0079817,35.7653394 21.2497431,35.5295214 21.5534312,35.5295214 C21.8605872,35.5290275 22.0959083,35.764844 22.0959083,36.0586239 Z M21.1437248,36.0586239 C21.1437248,36.2944404 21.3181101,36.481211 21.5568991,36.481211 C21.7892477,36.481211 21.9601652,36.2944404 21.9601652,36.0615963 C21.9601652,35.8257798 21.7892477,35.6355413 21.5534312,35.6355413 C21.3181101,35.6360367 21.1437248,35.8262752 21.1437248,36.0586239 Z M21.4697065,36.3365505 L21.3473395,36.3365505 L21.3473395,35.8074495 C21.3958899,35.7975413 21.4632661,35.7911009 21.5509542,35.7911009 C21.6510276,35.7911009 21.6961101,35.8074495 21.7347523,35.8302385 C21.7639817,35.8525321 21.7862753,35.8946422 21.7862753,35.9461651 C21.7862753,36.0041284 21.7411927,36.0497064 21.676789,36.0685321 L21.676789,36.0749725 C21.728312,36.0942936 21.7575413,36.1329358 21.7733945,36.2037798 C21.7897431,36.2845321 21.799156,36.3167339 21.8120367,36.3365505 L21.6797615,36.3365505 C21.6639083,36.3167339 21.654,36.2686789 21.6376514,36.2072477 C21.6282386,36.1492844 21.5955413,36.1230275 21.5281652,36.1230275 L21.4702019,36.1230275 L21.4702019,36.3365505 L21.4697065,36.3365505 Z M21.4731743,36.0363303 L21.5311376,36.0363303 C21.5990092,36.0363303 21.654,36.0140367 21.654,35.9590459 C21.654,35.9104954 21.6183303,35.8782936 21.5410459,35.8782936 C21.5088441,35.8782936 21.4860551,35.8812661 21.4731743,35.8847339 L21.4731743,36.0363303 L21.4731743,36.0363303 Z"
              fill="#FFFFFF"
              id="Shape"
            />
          </g>
          <g
            fill="#FFFFFF"
            id="Group"
            transform="translate(29.140184, 17.310716)"
          >
            <polygon
              id="Path"
              points="1.89495413 7.99150459 0 7.99150459 0 0.114440367 1.89495413 0.114440367"
            />
            <path
              d="M2.71684404,2.28385321 L4.38341284,2.28385321 L4.38341284,2.71783486 C4.56622018,2.48944954 4.98831193,2.16990826 5.59321101,2.16990826 C6.71185321,2.16990826 7.69376147,2.67225688 7.69376147,4.40719266 L7.69376147,7.992 L5.98111927,7.992 L5.98111927,4.73862385 C5.98111927,3.97370642 5.79831193,3.63137615 5.25038532,3.63137615 C4.65688073,3.63137615 4.42849541,3.99649541 4.42849541,4.72722936 L4.42849541,7.992 L2.71585321,7.992 L2.71585321,2.28385321 L2.71684404,2.28385321 Z"
              id="Path"
            />
            <path
              d="M11.3127523,4.03018349 C11.0962568,3.77900917 10.8678715,3.63088073 10.3427339,3.63088073 C10.1029541,3.63088073 9.80620184,3.67645871 9.80620184,3.92763303 C9.80620184,4.12183486 9.98900914,4.21299082 10.5141467,4.3160367 C12.0781651,4.61278899 12.6602752,5.24097248 12.6602752,6.29124771 C12.6602752,7.4326789 11.8497798,8.10594495 10.3769174,8.10594495 C9.21269725,8.10594495 8.45917431,7.85477064 7.94543119,7.1587156 L9.26966973,6.20009174 C9.46337615,6.46266055 9.69176144,6.64546789 10.3427339,6.64546789 C10.7192477,6.64546789 10.947633,6.54291743 10.947633,6.3373211 C10.947633,6.12033027 10.7534312,6.04056881 10.216899,5.92662385 C8.92684404,5.64126605 8.09355963,5.11612844 8.09355963,3.99748624 C8.09355963,2.85605504 8.91544954,2.17089908 10.376422,2.17089908 C11.3127523,2.17089908 12.1346422,2.38788991 12.5795229,3.11812844 L11.3127523,4.03018349 Z"
              id="Path"
            />
            <path
              d="M12.6251009,2.28385321 L13.3558348,2.28385321 L13.3558348,1.02798165 L15.068477,1.02798165 L15.068477,2.28385321 L16.0959633,2.28385321 L16.0959633,3.72253211 L15.068477,3.72253211 L15.068477,5.85726605 C15.068477,6.3140367 15.1938165,6.50774312 15.6164036,6.50774312 L16.0959633,6.50774312 L16.0959633,7.992 L15.4226972,7.992 C14.0300917,7.992 13.3563302,7.68385321 13.3563302,6.07425688 L13.3563302,3.72253211 L12.6255963,3.72253211 L12.6255963,2.28385321 L12.6251009,2.28385321 Z"
              id="Path"
            />
            <path
              d="M18.2183119,4.50974312 L19.2344036,4.50974312 L19.2344036,4.33882569 C19.2344036,3.77950459 19.1313578,3.56251376 18.3327523,3.56251376 C17.8417981,3.56251376 17.6134128,3.65416514 17.4078165,3.95091743 L16.2089174,3.11763303 C16.6542935,2.40968807 17.3161651,2.17040367 18.5264587,2.17040367 C20.0102201,2.17040367 20.9009724,2.59249541 20.9009724,4.3507156 L20.9009724,7.99249541 L19.2344036,7.99249541 L19.2344036,7.52433027 C19.0402018,7.83247706 18.732055,8.10644037 18.1043669,8.10644037 C16.9857247,8.10644037 16.1182568,7.49014679 16.1182568,6.29174312 C16.1182568,5.09234862 17.0313027,4.50974312 18.2183119,4.50974312 Z M19.2344036,5.7200367 L18.4580917,5.7200367 C18.0241101,5.7200367 17.7848256,5.86816514 17.7848256,6.24517431 C17.7848256,6.57611009 18.0473945,6.75891743 18.4813761,6.75891743 C19.0520917,6.75891743 19.234899,6.41658716 19.234899,5.89144954 L19.234899,5.7200367 L19.2344036,5.7200367 Z"
              id="Shape"
            />
            <path
              d="M21.4375045,2.28385321 L23.1040734,2.28385321 L23.1040734,2.71783486 C23.2868807,2.48944954 23.7089724,2.16990826 24.3138715,2.16990826 C25.4325137,2.16990826 26.414422,2.67225688 26.414422,4.40719266 L26.414422,7.992 L24.7017798,7.992 L24.7017798,4.73862385 C24.7017798,3.97370642 24.5189724,3.63137615 23.9710458,3.63137615 C23.3775412,3.63137615 23.1491559,3.99649541 23.1491559,4.72722936 L23.1491559,7.992 L21.4365137,7.992 L21.4365137,2.28385321 L21.4375045,2.28385321 Z"
              id="Path"
            />
            <path
              d="M26.5516513,2.28385321 L27.2823853,2.28385321 L27.2823853,1.02798165 L28.9950275,1.02798165 L28.9950275,2.28385321 L30.0225137,2.28385321 L30.0225137,3.72253211 L28.9950275,3.72253211 L28.9950275,5.85726605 C28.9950275,6.3140367 29.1203669,6.50774312 29.5429541,6.50774312 L30.0225137,6.50774312 L30.0225137,7.992 L29.3492477,7.992 C27.9566422,7.992 27.2828807,7.68385321 27.2828807,6.07425688 L27.2828807,3.72253211 L26.5521467,3.72253211 L26.5521467,2.28385321 L26.5516513,2.28385321 Z"
              id="Path"
            />
            <path
              d="M36.8938899,2.54642202 C36.8141284,2.04407339 36.4717981,1.57590826 35.7980367,1.57590826 C35.0103302,1.57590826 34.5882385,2.14662385 34.5882385,3.4366789 L34.5882385,4.66926605 C34.5882385,5.90234862 34.9994312,6.5300367 35.7980367,6.5300367 C36.4717981,6.5300367 36.7799449,6.18770642 36.8938899,5.55952293 L38.697688,5.55952293 C38.4237247,7.3286422 37.4651009,8.10495413 35.7980367,8.10495413 C34.005633,8.10495413 32.692789,7.02049541 32.692789,4.66877064 L32.692789,3.43618349 C32.692789,1.06166972 34.005633,0 35.7980367,0 C37.362055,0 38.4351192,0.833284401 38.697688,2.5459266 L36.8938899,2.5459266 L36.8938899,2.54642202 Z"
              id="Path"
            />
            <path
              d="M40.7174862,4.50974312 L41.7335779,4.50974312 L41.7335779,4.33882569 C41.7335779,3.77950459 41.6305321,3.56251376 40.8319266,3.56251376 C40.3409724,3.56251376 40.1125871,3.65416514 39.9069908,3.95091743 L38.7080917,3.11763303 C39.1534679,2.40968807 39.8153394,2.17040367 41.025633,2.17040367 C42.5093945,2.17040367 43.4001467,2.59249541 43.4001467,4.3507156 L43.4001467,7.99249541 L41.7335779,7.99249541 L41.7335779,7.52433027 C41.5393761,7.83247706 41.2312293,8.10644037 40.6035412,8.10644037 C39.484899,8.10644037 38.6174312,7.49014679 38.6174312,6.29174312 C38.6174312,5.09234862 39.530477,4.50974312 40.7174862,4.50974312 Z M41.7340734,5.7200367 L40.9577614,5.7200367 C40.5237798,5.7200367 40.2844954,5.86816514 40.2844954,6.24517431 C40.2844954,6.57611009 40.5470642,6.75891743 40.9810458,6.75891743 C41.5517614,6.75891743 41.7345688,6.41658716 41.7345688,5.89144954 L41.7345688,5.7200367 L41.7340734,5.7200367 Z"
              id="Shape"
            />
            <path
              d="M47.0191376,4.03018349 C46.8026422,3.77900917 46.5742568,3.63088073 46.0491192,3.63088073 C45.8093394,3.63088073 45.5125871,3.67645871 45.5125871,3.92763303 C45.5125871,4.12183486 45.6953945,4.21299082 46.2205321,4.3160367 C47.7845504,4.61278899 48.3666605,5.24097248 48.3666605,6.29124771 C48.3666605,7.4326789 47.5561651,8.10594495 46.0833027,8.10594495 C44.9190825,8.10594495 44.1655596,7.85477064 43.6518165,7.1587156 L44.976055,6.20009174 C45.1697614,6.46266055 45.3981467,6.64546789 46.0491192,6.64546789 C46.425633,6.64546789 46.6540183,6.54291743 46.6540183,6.3373211 C46.6540183,6.12033027 46.4598165,6.04056881 45.9232844,5.92662385 C44.6332293,5.64126605 43.7999449,5.11612844 43.7999449,3.99748624 C43.7999449,2.85605504 44.6218348,2.17089908 46.0828073,2.17089908 C47.0191376,2.17089908 47.8410275,2.38788991 48.2859082,3.11812844 L47.0191376,4.03018349 Z"
              id="Path"
            />
            <path
              d="M48.730789,0.11493578 L50.4434312,0.11493578 L50.4434312,2.67225688 C50.6262385,2.45526605 51.0369357,2.16990826 51.6076513,2.16990826 C52.7262935,2.16990826 53.7082018,2.67225688 53.7082018,4.40719266 L53.7082018,7.992 L51.9955596,7.992 L51.9955596,4.73862385 C51.9955596,3.97370642 51.8127523,3.63137615 51.2648256,3.63137615 C50.6713211,3.63137615 50.4429357,3.99649541 50.4429357,4.72722936 L50.4429357,7.992 L48.7302935,7.992 L48.7302935,0.11493578 L48.730789,0.11493578 Z"
              id="Path"
            />
            <path
              d="M59.7126055,8.10594495 C57.9202018,8.10594495 56.6073578,7.02148624 56.6073578,4.66976147 L56.6073578,3.43717431 C56.6073578,1.06266055 57.9202018,0.000990826 59.7126055,0.000990826 C61.5050091,0.000990826 62.8178532,1.06266055 62.8178532,3.43717431 L62.8178532,4.66976147 C62.8173578,7.02148624 61.5045137,8.10594495 59.7126055,8.10594495 Z M58.5028073,4.66976147 C58.5028073,5.95981651 58.924899,6.53053211 59.7126055,6.53053211 C60.5003119,6.53053211 60.922899,5.95981651 60.922899,4.66976147 L60.922899,3.43717431 C60.922899,2.14711927 60.5003119,1.57640367 59.7126055,1.57640367 C58.924899,1.57640367 58.5028073,2.14711927 58.5028073,3.43717431 L58.5028073,4.66976147 Z"
              id="Shape"
            />
            <path
              d="M63.6848256,2.28385321 L63.6848256,2.0326789 C63.6848256,0.423082569 64.3585871,0.11493578 65.7511926,0.11493578 L66.2535412,0.11493578 L66.2535412,1.59919266 L65.9453945,1.59919266 C65.5228073,1.59919266 65.3974679,1.79339449 65.3974679,2.24966972 L65.3974679,2.28385321 L66.2535412,2.28385321 L66.2535412,3.72253211 L65.3974679,3.72253211 L65.3974679,7.992 L63.6848256,7.992 L63.6848256,3.72253211 L62.9540917,3.72253211 L62.9540917,2.28385321 L63.6848256,2.28385321 L63.6848256,2.28385321 Z"
              id="Path"
            />
            <path
              d="M66.721211,2.28385321 L66.721211,2.0326789 C66.721211,0.423082569 67.3949724,0.11493578 68.7875779,0.11493578 L69.2899266,0.11493578 L69.2899266,1.59919266 L68.9817798,1.59919266 C68.5591926,1.59919266 68.4338532,1.79339449 68.4338532,2.24966972 L68.4338532,2.28385321 L69.2899266,2.28385321 L69.2899266,3.72253211 L68.4338532,3.72253211 L68.4338532,7.992 L66.721211,7.992 L66.721211,3.72253211 L65.990477,3.72253211 L65.990477,2.28385321 L66.721211,2.28385321 L66.721211,2.28385321 Z"
              id="Path"
            />
            <path
              d="M73.9582018,7.1240367 C73.558899,7.82009174 72.8737431,8.10544954 71.8348623,8.10544954 C70.0310642,8.10544954 69.2547523,6.94122936 69.2547523,5.52583486 L69.2547523,4.72673394 C69.2547523,2.95711927 70.1677981,2.16941284 71.652055,2.16941284 C73.3072293,2.16941284 74.0493578,3.1166422 74.0493578,4.89765138 L74.0493578,5.57091743 L70.9673945,5.57091743 C70.9901834,6.22139449 71.1502018,6.66677064 71.8462568,6.66677064 C72.382789,6.66677064 72.4967339,6.50675229 72.7023302,6.2446789 L73.9582018,7.1240367 Z M70.9792844,4.42998165 L72.3377064,4.42998165 C72.3035229,3.80229358 72.0637431,3.60809174 71.6644403,3.60809174 C71.2185688,3.60809174 71.0243669,3.91623853 70.9792844,4.42998165 Z"
              id="Shape"
            />
            <path
              d="M77.5539082,4.04207339 C77.4513578,3.87066055 77.2913394,3.63137615 76.9604036,3.63137615 C76.4694495,3.63137615 76.1840917,3.97370642 76.1840917,4.73862385 L76.1840917,7.992 L74.4714495,7.992 L74.4714495,2.28385321 L76.1380183,2.28385321 L76.1380183,2.71783486 C76.3094312,2.50084404 76.6859449,2.16990826 77.3369174,2.16990826 C77.9873945,2.16990826 78.5011376,2.48944954 78.8320734,3.12853211 L77.5539082,4.04207339 Z"
              id="Path"
            />
          </g>
          <g
            fill="#FFFFFF"
            id="Group"
            transform="translate(28.897431, 8.937248)"
          >
            <polygon
              id="Path"
              points="3.05768807 6.13915597 1.59473394 3.83449542 0.848642203 4.79311927 0.848642203 6.13915597 0 6.13915597 0 1.0913945 0.848642203 1.0913945 0.848642203 3.52733945 2.70693578 1.0913945 3.7453211 1.0913945 2.15058715 3.12506422 4.07477064 6.13915597"
            />
            <path
              d="M7.12651376,5.58330276 C6.87038532,6.02966973 6.4606789,6.20504588 5.8166422,6.20504588 C4.77033027,6.20504588 4.21447706,5.52484404 4.21447706,4.55185321 L4.21447706,4.05445872 C4.21447706,2.97891743 4.74110091,2.41563303 5.69972477,2.41563303 C6.70194495,2.41563303 7.17754128,3.01557799 7.17754128,4.09805505 L7.17754128,4.50033028 L4.98979816,4.50033028 L4.98979816,4.51469725 C4.98979816,5.08541285 5.20926605,5.53177982 5.78741284,5.53177982 C6.21891743,5.53177982 6.39429357,5.4 6.54093578,5.17310092 L7.12651376,5.58330276 Z M4.99772477,3.90038532 L6.39478899,3.90038532 C6.36555963,3.36633028 6.14609174,3.09583487 5.69972477,3.09583487 C5.24642202,3.09583487 5.03438532,3.40299083 4.99772477,3.90038532 Z"
              id="Shape"
            />
            <path
              d="M7.93205504,1.0913945 L8.71480734,1.0913945 L8.71480734,4.80748624 C8.71480734,5.20233028 8.78812844,5.45102753 9.27115596,5.45102753 L9.27115596,6.13866055 C8.3199633,6.13866055 7.93205504,5.83150459 7.93205504,4.99029358 L7.93205504,1.0913945 Z"
              id="Path"
            />
            <path
              d="M9.99544953,1.0913945 L10.7782018,1.0913945 L10.7782018,4.80748624 C10.7782018,5.20233028 10.8515229,5.45102753 11.334055,5.45102753 L11.334055,6.13866055 C10.3828624,6.13866055 9.99544953,5.83150459 9.99544953,4.99029358 L9.99544953,1.0913945 Z"
              id="Path"
            />
            <path
              d="M14.8173027,5.58330276 C14.5611743,6.02966973 14.1514679,6.20504588 13.5074312,6.20504588 C12.4616146,6.20504588 11.905266,5.52484404 11.905266,4.55185321 L11.905266,4.05445872 C11.905266,2.97891743 12.4318899,2.41563303 13.3905137,2.41563303 C14.3927339,2.41563303 14.8683302,3.01557799 14.8683302,4.09805505 L14.8683302,4.50033028 L12.6805871,4.50033028 L12.6805871,4.51469725 C12.6805871,5.08541285 12.900055,5.53177982 13.4782018,5.53177982 C13.9097064,5.53177982 14.0850825,5.4 14.2317247,5.17310092 L14.8173027,5.58330276 Z M12.6880183,3.90038532 L14.0850825,3.90038532 C14.0558532,3.36633028 13.8363853,3.09583487 13.3900183,3.09583487 C12.9367156,3.09583487 12.7246789,3.40299083 12.6880183,3.90038532 Z"
              id="Shape"
            />
            <path
              d="M15.9666605,2.48102753 L16.8955596,5.05618349 L17.7298348,2.48102753 L18.5343853,2.48102753 L17.2468073,6.28530276 C16.9322202,7.22163303 16.6612293,7.52878899 15.637211,7.52878899 L15.637211,6.81192661 C16.1494679,6.81192661 16.3545688,6.73117432 16.5368807,6.1827523 L15.1398165,2.48102753 L15.9666605,2.48102753 L15.9666605,2.48102753 Z"
              id="Path"
            />
            <path
              d="M22.5739816,1.0913945 C23.7590091,1.0913945 24.3663853,1.53033028 24.3663853,2.40077065 C24.3663853,2.92045872 24.0666605,3.33710092 23.6277247,3.4981101 C24.1984403,3.65911927 24.5273945,4.05396331 24.5273945,4.69056881 C24.5273945,5.45152294 24.0443669,6.13915597 22.7349908,6.13915597 L20.9500183,6.13915597 L20.9500183,1.0913945 L22.5739816,1.0913945 Z M21.7981651,3.15429358 L22.6250091,3.15429358 C23.2541835,3.15429358 23.5177431,2.86893578 23.5177431,2.45179817 C23.5177431,2.05645872 23.3200734,1.82262386 22.5155229,1.82262386 L21.7986605,1.82262386 L21.7986605,3.15429358 L21.7981651,3.15429358 Z M21.7981651,5.4074312 L22.6834679,5.4074312 C23.3636697,5.4074312 23.6782568,5.12950459 23.6782568,4.64647707 C23.6782568,4.11242202 23.3270091,3.88552294 22.5809174,3.88552294 L21.7981651,3.88552294 L21.7981651,5.4074312 Z"
              id="Shape"
            />
            <path
              d="M25.2160183,1.0913945 L25.9987706,1.0913945 L25.9987706,4.80748624 C25.9987706,5.20233028 26.0720917,5.45102753 26.5546238,5.45102753 L26.5546238,6.13866055 C25.6034312,6.13866055 25.2160183,5.83150459 25.2160183,4.99029358 L25.2160183,1.0913945 Z"
              id="Path"
            />
            <path
              d="M30.2860734,6.13915597 L29.5181835,6.13915597 L29.5181835,5.81020184 C29.3497431,6.07326606 29.1302752,6.20504588 28.6185137,6.20504588 C27.8649908,6.20504588 27.2794128,5.81763303 27.2794128,4.76388991 L27.2794128,2.48152294 L28.0621651,2.48152294 L28.0621651,4.68363303 C28.0621651,5.33460551 28.3549541,5.51741285 28.7646605,5.51741285 C29.247688,5.51741285 29.5107523,5.1953945 29.5107523,4.69056881 L29.5107523,2.48102753 L30.2860734,2.48102753 L30.2860734,6.13915597 Z"
              id="Path"
            />
            <path
              d="M33.8862385,5.58330276 C33.6301101,6.02966973 33.2204036,6.20504588 32.5763669,6.20504588 C31.530055,6.20504588 30.9742018,5.52484404 30.9742018,4.55185321 L30.9742018,4.05445872 C30.9742018,2.97891743 31.5008257,2.41563303 32.4594495,2.41563303 C33.4616697,2.41563303 33.937266,3.01557799 33.937266,4.09805505 L33.937266,4.50033028 L31.7495229,4.50033028 L31.7495229,4.51469725 C31.7495229,5.08541285 31.9689908,5.53177982 32.5471376,5.53177982 C32.9786422,5.53177982 33.1540183,5.4 33.3006605,5.17310092 L33.8862385,5.58330276 Z M31.7574495,3.90038532 L33.1545137,3.90038532 C33.1252844,3.36633028 32.9058165,3.09583487 32.4594495,3.09583487 C32.0061468,3.09583487 31.7941101,3.40299083 31.7574495,3.90038532 Z"
              id="Shape"
            />
            <path
              d="M38.2840183,1.0913945 C39.4690458,1.0913945 40.076422,1.53033028 40.076422,2.40077065 C40.076422,2.92045872 39.7766972,3.33710092 39.3377614,3.4981101 C39.908477,3.65911927 40.2374312,4.05396331 40.2374312,4.69056881 C40.2374312,5.45152294 39.7544036,6.13915597 38.4450275,6.13915597 L36.660055,6.13915597 L36.660055,1.0913945 L38.2840183,1.0913945 Z M37.5086972,3.15429358 L38.3355413,3.15429358 C38.9647156,3.15429358 39.2282752,2.86893578 39.2282752,2.45179817 C39.2282752,2.05645872 39.0306055,1.82262386 38.226055,1.82262386 L37.5091926,1.82262386 L37.5091926,3.15429358 L37.5086972,3.15429358 Z M37.5086972,5.4074312 L38.394,5.4074312 C39.0742018,5.4074312 39.388789,5.12950459 39.388789,4.64647707 C39.388789,4.11242202 39.0375413,3.88552294 38.2914495,3.88552294 L37.5086972,3.88552294 L37.5086972,5.4074312 Z"
              id="Shape"
            />
            <path
              d="M43.881688,4.53699083 C43.881688,5.50998166 43.4060917,6.20504588 42.3305504,6.20504588 C41.3065321,6.20504588 40.7719816,5.54664221 40.7719816,4.53699083 L40.7719816,4.07625688 C40.7719816,3.0007156 41.3426972,2.41563303 42.3305504,2.41563303 C43.3179082,2.41563303 43.881688,3.0007156 43.881688,4.07625688 L43.881688,4.53699083 Z M43.1063669,4.51469725 L43.1063669,4.09062386 C43.1063669,3.45401835 42.8502385,3.09583487 42.3310458,3.09583487 C41.804422,3.09583487 41.5482935,3.46144955 41.5482935,4.09062386 L41.5482935,4.51469725 C41.5482935,5.08541285 41.7385321,5.53177982 42.3310458,5.53177982 C42.886899,5.53177982 43.1063669,5.08541285 43.1063669,4.51469725 Z"
              id="Shape"
            />
            <path
              d="M47.5185137,4.53699083 C47.5185137,5.50998166 47.0429174,6.20504588 45.9673761,6.20504588 C44.9433578,6.20504588 44.4088073,5.54664221 44.4088073,4.53699083 L44.4088073,4.07625688 C44.4088073,3.0007156 44.9795229,2.41563303 45.9673761,2.41563303 C46.9547339,2.41563303 47.5185137,3.0007156 47.5185137,4.07625688 L47.5185137,4.53699083 Z M46.7431926,4.51469725 L46.7431926,4.09062386 C46.7431926,3.45401835 46.4870642,3.09583487 45.9678715,3.09583487 C45.4412477,3.09583487 45.1851192,3.46144955 45.1851192,4.09062386 L45.1851192,4.51469725 C45.1851192,5.08541285 45.3753578,5.53177982 45.9678715,5.53177982 C46.5232293,5.53177982 46.7431926,5.08541285 46.7431926,4.51469725 Z"
              id="Shape"
            />
            <polygon
              id="Path"
              points="51.1622752 2.48102753 49.765211 4.05396331 51.3817431 6.13866055 50.4379816 6.13866055 49.2306605 4.66084404 48.9814679 4.93877065 48.9814679 6.13866055 48.1987156 6.13866055 48.1987156 1.0913945 48.9814679 1.0913945 48.9814679 3.90781652 50.2036513 2.48102753"
            />
            <path
              d="M52.7035046,0.470146789 C52.5950091,0.329944954 52.4780917,0.227394495 52.2239449,0.227394495 C51.9896146,0.227394495 51.8315779,0.332917431 51.8315779,0.54346789 C51.8315779,0.783247706 52.012899,0.865486238 52.3081651,0.918 C52.6975596,0.985376146 52.9343669,1.12557799 52.9343669,1.5001101 C52.9343669,1.76317432 52.7589908,2.06488074 52.2556513,2.06488074 C51.9281835,2.06488074 51.6884036,1.95390826 51.5363119,1.73444037 L51.7438899,1.59126606 C51.8756697,1.74633028 51.9925871,1.83699083 52.2556513,1.83699083 C52.5865871,1.83699083 52.6826972,1.68489909 52.6826972,1.51249542 C52.6826972,1.32225688 52.5865871,1.19344955 52.2061101,1.13499083 C51.8261284,1.07653211 51.5799082,0.895211009 51.5799082,0.543963303 C51.5799082,0.254642202 51.7701468,0 52.2323669,0 C52.5543853,0 52.7560183,0.108 52.899688,0.330440367 L52.7035046,0.470146789 Z"
              id="Path"
            />
            <polygon
              id="Path"
              points="54.1758715 1.28064221 54.7782935 0.0227889908 55.0269908 0.0227889908 55.0269908 2.04110092 54.7753211 2.04110092 54.7753211 0.560807339 54.2838715 1.61950459 54.0584587 1.61950459 53.5754312 0.569229358 53.5754312 2.04060551 53.3237614 2.04060551 53.3237614 0.0222935779 53.5694862 0.0222935779"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
