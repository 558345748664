import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LeadStatus, MarkLeadLostRouteParams } from "./interfaces";
import MarkLeadStatus from "./mark-lead-status";
import { useMemo } from "react";

export function MarkLeadLostPage() {
  const { leadId } = useParams<MarkLeadLostRouteParams>();
  const navigate = useNavigate();
  const parsedLeadId = useMemo(() => parseInt(leadId || "", 10), [leadId]);

  const onClosePage = () => {
    navigate(-1);
  };

  return (
    <MarkLeadStatus
      leadId={parsedLeadId}
      leadStatus={LeadStatus.Lost}
      onCancel={onClosePage}
      onSuccess={onClosePage}
    />
  );
}

export default MarkLeadLostPage;
