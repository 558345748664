import AmpModalContent from "./amp-modal-content";
import { AmpInfoResponse } from "@vinsolutions/data-access/marketing/coxauto.amp.vinconnect.bff";
import { KeyInformationBannerModal } from "@vinsolutions/ui/key-information-banner";

export interface KeyInformationAmpModalProps {
  ampSummary: AmpInfoResponse;
  showModal: boolean;
  onHide: () => void;
}

const KeyInformationAmpModal = ({
  ampSummary,
  showModal,
  onHide
}: KeyInformationAmpModalProps) => {
  return (
    <KeyInformationBannerModal showModal={showModal} onHide={onHide}>
      <AmpModalContent ampSummary={ampSummary} />
    </KeyInformationBannerModal>
  );
};

export default KeyInformationAmpModal;
