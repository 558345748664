import { ReactElement } from "react";
import styled from "styled-components";
import { LoadingIndicator } from "@vinsolutions/core-cx";
import KeyInformationHeaderIcon from "./header-icon";

export interface IconBarIcon {
  icon: ReactElement | undefined;
  iconId: string;
}

export interface KeyInformationIconBarProps {
  icons: IconBarIcon[] | null;
  isLoading: boolean;
}

const StyledKeyInformationIconBar = styled.div`
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  display: flex;
  align-items: center;
`;

export function KeyInformationIconBar({
  icons,
  isLoading
}: KeyInformationIconBarProps) {
  return (
    <StyledKeyInformationIconBar id="keyInfo-iconbar">
      {icons?.map(
        barIcon =>
          barIcon?.icon && (
            <KeyInformationHeaderIcon
              htmlId={barIcon.iconId}
              icon={barIcon.icon}
              key={barIcon.iconId}
            />
          )
      )}
      {isLoading && <LoadingIndicator htmlId="keyInfo-loading-dots-iconbar" />}
    </StyledKeyInformationIconBar>
  );
}

export default KeyInformationIconBar;
