import styled from "styled-components";

const StyledCustomerEnrichmentSummary = styled.span``;

export function CustomerEnrichmentSummary() {
  const textBlurb = `Lifestyle insights to help understand your customer.`;

  return (
    <StyledCustomerEnrichmentSummary>
      {textBlurb}
    </StyledCustomerEnrichmentSummary>
  );
}
