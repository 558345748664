import { NavigationItemsEntity } from "@vinsolutions/ccrm/interfaces";

export interface NewNavigationItemsEntity extends NavigationItemsEntity {
  parentTabId: string;
  predecessorTabId: string;
  insertPosition?: string;
}

export function deskingDealListNavItems() {
  const newNavigationItemsEntities: NewNavigationItemsEntity[] = [
    {
      id: "tab-desking",
      label: "Deals",
      target: "",
      url: "",
      type: "GENERIC_URL",
      parentTabId: "",
      predecessorTabId: "tab-inventory"
    },
    {
      id: "navigation-sub-menu-tab-desking-deal-list",
      label: "Deal List",
      target: "",
      url: "/vinconnect/pane-full/vinconnect-crw",
      type: "GENERIC_URL",
      parentTabId: "tab-desking",
      predecessorTabId: ""
    }
  ];

  return newNavigationItemsEntities;
}

export function blocklistNavItems() {
  const newNavigationItemsEntities: NewNavigationItemsEntity[] = [
    {
      id: "navigation-sub-menu-tab-blocklist",
      label: "Manage Blocklist Rules",
      target: "",
      url: "/vinconnect/pane-full/vinconnect-blocklist",
      type: "GENERIC_URL",
      parentTabId: "tab-admin",
      predecessorTabId: "",
      insertPosition: "end"
    }
  ];

  return newNavigationItemsEntities;
}
