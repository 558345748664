import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Alert, ModalDialog, ToggleSwitch } from "@vinsolutions/core/cx";
import { Dealer } from "@vinsolutions/ccrm/interfaces";
import { DealerTable } from "../table";
import { ToggleSwitchId } from "./dealer-selector-ids";
import { usePrevious } from "@vinsolutions/ccrm/util";
import AutoTextInput from "../auto-text-input";

/* eslint-disable-next-line */
export interface DealerSelectorModalProps {
  isEmployee: boolean;
  onDealerSearch: (searchText: string, activeDealersOnly: boolean) => void;
  dealers: Dealer[];
  error: boolean;
  isLoading: boolean;
  show: boolean;
  onHide: () => void;
  onDealerSelect: (record: Dealer) => void;
  onMoreDealers: () => void;
  isLastPage: boolean;
  pagination?: {
    page: number;
    onPageChange: (page: number) => void;
  };
}

const StyledModalDialog = styled(ModalDialog)`
  .modal-dialog.modal-dialog,
  .modal-content.modal-content {
    min-height: 650px;
    width: 750px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .modal-header {
      padding: 8px 16px 0 16px;
      .close {
        margin-top: 2px;
      }
    }
  }
`;

const StyledModalBody = styled.div`
  #ccrm-dealer-selector-ui-error-alert {
    margin-bottom: 0px;
  }
`;

const StyledModalHeader = styled.div<{ isEmployee: boolean }>`
  .ccrm-dealer-selector-ui-header {
    display: grid;
    grid-template-columns: ${props =>
      props.isEmployee ? "auto auto 1fr auto" : "auto 1fr auto"};
    grid-template-areas: "title toggle . search";
    grid-gap: 16px;
    padding-right: 8px;

    .ccrm-dealer-selector-ui-title {
      padding-top: 8px;
      grid-area: title;
    }

    .ccrm-dealer-selector-ui-active-dealer-toggle {
      grid-area: toggle;

      #dealer-selector-active-dealer-toggle-label {
        padding: 2px 8px;
      }
    }

    .ccrm-dealer-selector-ui-dealer-search-input {
      grid-area: search;
    }
  }
`;

export function DealerSelectorModal({
  dealers,
  error,
  isEmployee,
  isLoading,
  show,
  onDealerSearch,
  onDealerSelect,
  onMoreDealers,
  onHide,
  isLastPage,
  pagination
}: DealerSelectorModalProps) {
  const [searchText, setSearchText] = useState("");
  const [activeDealersOnly, setActiveDealersOnly] = useState(true);
  const prevShow = usePrevious(show, false);

  useEffect(() => {
    if (show === false && prevShow === true) {
      setSearchText("");
      setActiveDealersOnly(true);
      onDealerSearch("", true);
    }
  }, [onDealerSearch, prevShow, show]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [activeDealersOnly]);

  const handleSearch = useCallback(() => {
    onDealerSearch(searchText, activeDealersOnly);
  }, [activeDealersOnly, onDealerSearch, searchText]);

  const handleToggleSwitch = () => {
    setActiveDealersOnly(s => !s);
  };

  const handleAutoTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <StyledModalDialog
      displayCloseButton={true}
      header={
        <StyledModalHeader isEmployee={isEmployee}>
          <div className="ccrm-dealer-selector-ui-header">
            <h4 className="ccrm-dealer-selector-ui-title">Dealer Selector</h4>
            {isEmployee && (
              <ToggleSwitch
                aria-label="toggle active dealers"
                checked={activeDealersOnly}
                className="ccrm-dealer-selector-ui-active-dealer-toggle"
                htmlId={ToggleSwitchId}
                label="Active Dealers Only"
                name="activeDealersOnly"
                onClick={handleToggleSwitch}
              />
            )}
            <AutoTextInput
              autoFocus
              aria-label="Search Dealers"
              autoAction={handleSearch}
              autoActionDelay={500}
              className="ccrm-dealer-selector-ui-dealer-search-input"
              displayLabel={false}
              htmlId="dealer-selector-dealer-selector-input"
              label=""
              name="searchText"
              placeholder="Search Dealers"
              value={searchText}
              onChange={handleAutoTextInputChange}
            />
          </div>
        </StyledModalHeader>
      }
      htmlId="ccrm-dealer-selector-modal-custom"
      show={show}
      onHide={onHide}
    >
      <StyledModalBody>
        {error && (
          <Alert htmlId="ccrm-dealer-selector-ui-error-alert" type="danger">
            Request could not be completed, please try again later.
          </Alert>
        )}
        <DealerTable
          {...{
            dealers,
            isEmployee,
            isLoading,
            onDealerSelect,
            onMoreDealers,
            isLastPage,
            pagination
          }}
        />
      </StyledModalBody>
    </StyledModalDialog>
  );
}

export default DealerSelectorModal;
