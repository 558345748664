import { MouseEvent } from "react";
import { Button } from "cx";
import {
  crmNavigationItemEntityIds,
  deriveCardashboardBaseUrl
} from "@vinsolutions/ccrm/util";
import {
  cardashboardActions,
  getCardashboardState
} from "@vinsolutions/legacy-cardashboard/store";

import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRouteManager } from "../router/use-route-manager";
import { useRenderRouteByAlias } from "../router/use-render-route-by-alias";
import { vinconnectHashPrefix } from "@vinsolutions/ccrm/util";
/* eslint-disable-next-line */
export interface NavigationButtonProps {
  id: string;
  label: string;
  target: string | null;
  url: string | null;
}

export function NavigationButton({
  id,
  label,
  url,
  target
}: NavigationButtonProps) {
  const renderRoute = useRenderRouteByAlias();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routeManager = useRouteManager();
  const { frameBaseUrl } = useSelector(getCardashboardState, shallowEqual);

  const hasValidLink = url && url !== "#";
  const fullHttpUrl =
    hasValidLink && url && url.startsWith("http")
      ? url
      : deriveCardashboardBaseUrl() + url;
  const onClick = (e: MouseEvent<HTMLElement>) => {
    if (isRecentCustomer()) {
      TagManager.event({
        eventElement: `TopNav: Global Navigation`,
        eventAction: `Recent customer clicked`,
        eventValue: `CRM > Recent Viewed Customer > X Customer`
      });
    } else {
      TagManager.event({
        eventElement: `TopNav: Global Navigation`,
        eventAction: `${label} clicked`,
        eventValue: `${id}`
      });
    }

    if (e.button === 0 && !e.ctrlKey) {
      e.preventDefault();

      if (hasValidLink) {
        if (target && target.toLowerCase() === "_blank") {
          window.open(fullHttpUrl, target);
        } else {
          if (routeManager?.isActiveRouteByAlias(id)) {
            renderRoute(id, true);
          } else {
            const framePath = url?.replace(frameBaseUrl, "");
            navigate(`${vinconnectHashPrefix}${framePath}`, { replace: false });
            dispatch(
              cardashboardActions.updateFrameUrl({
                name: isRecentCustomer() ? "Recent Customer Dashboard" : label,
                frameUrl: url || ""
              })
            );
          }
        }
      }
    }

    function isRecentCustomer() {
      return (
        id &&
        id !== crmNavigationItemEntityIds.recentViewedCustomers &&
        id.startsWith(crmNavigationItemEntityIds.recentViewedCustomers)
      );
    }
  };

  return (
    <Button buttonStyle={"link"} htmlId={id} {...{ onClick }}>
      {hasValidLink && !routeManager?.isActiveRouteByAlias(id) ? (
        <a href={fullHttpUrl} target={target || ""}>
          {label}
        </a>
      ) : (
        label
      )}
    </Button>
  );
}

export default NavigationButton;
