import styled from "styled-components";
import { DealKeyInformation } from "@vinsolutions/data-access/lead/crm.workflow.keyinfo.accelerate";

/* eslint-disable-next-line */
export interface KeyInformationDealSummaryProps {
  dealSummary: DealKeyInformation;
}

const StyledKeyInformationDealSummary = styled.span``;

export function FeatureKeyInformationDealSummary({
  dealSummary: { drActivityCount, description }
}: KeyInformationDealSummaryProps) {
  return (
    <StyledKeyInformationDealSummary>
      {description}
      {description && drActivityCount ? <br /> : ""}
      {drActivityCount ? `Online Activities: ${drActivityCount}` : ""}
    </StyledKeyInformationDealSummary>
  );
}

export default FeatureKeyInformationDealSummary;
