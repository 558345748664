import { getUserManager } from "@axa-fr/react-oidc-core";
import styled from "styled-components";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useEffect } from "react";

import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("Logout");

/* eslint-disable-next-line */
export interface LogoutProps {}

const StyledLogout = styled.div`
  color: #000;
`;

export function Logout(props: LogoutProps) {
  logger.debug("Logout: Initializing Logout.");
  const { oidcUser, logout } = useReactOidc();

  useEffect(() => {
    (async function () {
      const endSessionEndpoint =
        await getUserManager().metadataService.getEndSessionEndpoint();

      logger.debug("Logout: getting redirect url.");
      const redirectUrl = window.location.origin + "/vinconnect/";

      logger.debug("Logout: clearing session storage.");
      sessionStorage.clear();

      logger.debug("Logout: calling logout function.");
      await logout();

      logger.debug("Logout: redirecting to end session endpoint for ids.");
      window.location.href = `${endSessionEndpoint}?id_token_hint=${oidcUser.id_token}&post_logout_redirect_uri=${redirectUrl}`;
    })();
  }, [oidcUser.id_token, logout]);

  return (
    <StyledLogout>
      <h1>Logging out...</h1>
    </StyledLogout>
  );
}

export default Logout;
