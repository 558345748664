import { fetchWrapper } from "@vinsolutions/core/http";
import { createLogger } from "@vinsolutions/logger";
import { AmpInfoResponse } from "./models/amp-info.response";
import { AmpFeatureStatusResponse } from "./models/amp-feature-status-response";
import { getAmpBaseUrl } from "./amp.api";
import { errorAmpInfoResponse } from "./models/mock-amp-info-response";
import { SUMMARY, TRY_AGAIN_CODE } from "./constants";

const logger = createLogger("data-access-amp");

export async function getAMPInfoAsync(
  customerId: number,
  dealerId: number,
  jwt: string
): Promise<AmpInfoResponse | null | undefined> {
  const summaryUrl = getAmpSummaryUrl(customerId, dealerId);
  const { statusCode, data: response } =
    await fetchWrapper.getAsync<AmpFeatureStatusResponse>(summaryUrl, {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/vnd.coxauto.v1+json"
    });
  if (statusCode === 200) {
    return response?.isAmpEnabled && response?.summary
      ? ({
          ...response?.summary,
          summary: SUMMARY
        } as AmpInfoResponse)
      : null;
  }
  if (
    response &&
    statusCode === 503 &&
    response?.errors?.find(er => er.code === TRY_AGAIN_CODE)
  ) {
    return getErrorAmpInfoResponse(statusCode, response);
  }

  return null;
}

export function getAmpSummaryUrl(customerId?: number, dealerId?: number) {
  const baseUrl: string = getAmpBaseUrl();
  return `${baseUrl}keyinfo/summary?dealerId=${dealerId}&customerId=${customerId}`;
}

const getErrorAmpInfoResponse = (
  statusCode: number | undefined,
  response: AmpFeatureStatusResponse | undefined | null
) => {
  logger.error("Error getting AMPInfo", statusCode, JSON.stringify(response));
  return errorAmpInfoResponse;
};
