import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";
import { generateNavigationIdFromParentId } from "./navigation-overrides/navigation-ids";

// This POS can die when we have a navigation API

interface LegacyNavItem {
  label: string;
  url?: string;
  target?: string;
  items: LegacyNavItem[];
}

interface ParentIdLabelPair {
  parentId?: string;
  label: string;
}

const fetchLegacyNavItems = async (getNavItems: () => LegacyNavItem[]) => {
  try {
    const res = await getNavItems();
    return res;
  } catch (ex) {
    return null;
  }
};

const transformLegacyNavItems = (
  legacyNavItems: LegacyNavItem[],
  parentId?: string
) => {
  const navigationEntities = [] as NavigationEntity[];
  let navigationItemEntities = [] as NavigationItemsEntity[];
  const parentIdLabelPairs = [] as ParentIdLabelPair[];

  legacyNavItems.forEach(item => {
    const { items, label, target = "_self", url = "#" } = item;

    const id = generateNavigationIdFromParentId(
      label,
      parentIdLabelPairs,
      parentId
    );

    parentIdLabelPairs.push({ parentId, label });

    const navItem: NavigationItemsEntity = {
      id,
      label,
      target,
      url,
      type: "GENERIC_URL"
    };

    if (items && items.length > 0) {
      const { nestedNavIds, nestedNavItems } = transformLegacyNavItems(
        items,
        id
      );
      navigationEntities.push({ id, items: nestedNavIds });
      navigationItemEntities = [
        ...navigationItemEntities,
        navItem,
        ...nestedNavItems
      ];
    } else {
      navigationEntities.push({ id });
      navigationItemEntities = [...navigationItemEntities, navItem];
    }
  });

  return {
    nestedNavIds: navigationEntities,
    nestedNavItems: navigationItemEntities
  };
};

export const deriveMainNavigation = async (
  getNavItems: () => LegacyNavItem[]
) => {
  let navigationItemsEntities = [] as NavigationItemsEntity[];
  let navigationEntities = [] as NavigationEntity[];
  const legacyNavItems = await fetchLegacyNavItems(getNavItems);

  if (legacyNavItems !== null && legacyNavItems.length > 0) {
    const { nestedNavIds, nestedNavItems } =
      transformLegacyNavItems(legacyNavItems);
    navigationItemsEntities = nestedNavItems;
    navigationEntities = nestedNavIds;
  }

  return { navigationItemsEntities, navigationEntities };
};
