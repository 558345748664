import {
  getVinManagerInventoryMaster,
  navigateToDestination
} from "./vinmanager-inventory-master";
import { communicationsNavigationItemEntityIds } from "./navigation-ids";
import { deriveCardashboardBaseUrl } from "../derive-cardashboard-url";

export const getCommunicationsItemOnClick = (
  dealerId: number,
  navigationItemId: string,
  setCarDashboardFrameUrl: (url: string) => void
) => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 1);
  startDate.setHours(5, 0, 0, 0);

  const navItemOnClickDictionary = getCommunicationsOnClickDictionary(
    dealerId,
    startDate,
    setCarDashboardFrameUrl
  );

  if (navItemOnClickDictionary.has(navigationItemId)) {
    return navItemOnClickDictionary.get(navigationItemId);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }
};

type OnClick = () => void;

const getCommunicationsOnClickDictionary = (
  dealerId: number,
  startDate: Date,
  setCarDashboardFrameUrl: (url: string) => void
) => {
  const vinManagerInventoryMaster = getVinManagerInventoryMaster();

  const retval = new Map<string, OnClick>([
    [
      communicationsNavigationItemEntityIds.emailInbound,
      () => {
        navigateToDestination(
          {
            leftPage: "/CarDashboard/Pages/LeadManagement/DailyLeadDetail.aspx",
            leftQueryString: {
              pageDisplayType: "6"
            }
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.emailOutbound,
      () => {
        navigateToDestination(
          {
            leftPage: "/CarDashboard/Pages/LeadManagement/DailyLeadDetail.aspx",
            leftQueryString: {
              pageDisplayType: "5"
            }
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.emailRead,
      () => {
        navigateToDestination(
          {
            leftPage: "/CarDashboard/Pages/LeadManagement/EmailsRead.aspx"
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.emailUndelivered,
      () => {
        navigateToDestination(
          {
            leftPage:
              "/CarDashboard/Pages/LeadManagement/DailyEmailDetail.aspx",
            leftQueryString: {
              almType: "13",
              from: vinManagerInventoryMaster.formatDateTwo(new Date()),
              to: vinManagerInventoryMaster.formatDateTwo(
                new Date(new Date().setDate(new Date().getDate() - 1))
              )
            }
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.textInbound,
      () => {
        let destination;
        if (vinManagerInventoryMaster.IsRimsEnabledForTextMessagingWfe) {
          destination = {
            leftPage: "/CarDashboard/Pages/rims2.aspx",
            leftQueryString: {
              urlSettingName: "brawndo.communicationsui.url",
              type: "text",
              direction: "Inbound",
              mp:
                vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging ??
                false
            }
          };
        } else {
          destination = {
            leftPage:
              "/CarDashboard/VinAnalytics/Dashboards/DashboardCommunicationLog.aspx",
            leftQueryString: {
              direction: "Inbound",
              channel: "Text",
              knownCustomer: "B",
              communicationType: "Sales,Service,Unknown",
              dateRange: "15",
              start: vinManagerInventoryMaster.formatDate(startDate),
              end: vinManagerInventoryMaster.formatDate(new Date())
            }
          };
        }
        navigateToDestination(
          destination,
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.textOutbound,
      () => {
        let destination;
        if (vinManagerInventoryMaster.IsRimsEnabledForTextMessagingWfe) {
          destination = {
            leftPage: "/CarDashboard/Pages/rims2.aspx",
            leftQueryString: {
              urlSettingName: "brawndo.communicationsui.url",
              type: "text",
              direction: "Outbound",
              mp:
                vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging ??
                false
            }
          };
        } else {
          destination = {
            leftPage:
              "/CarDashboard/VinAnalytics/Dashboards/DashboardCommunicationLog.aspx",
            leftQueryString: {
              direction: "Outbound",
              channel: "Text",
              knownCustomer: "B",
              communicationType: "Sales,Service,Unknown",
              dateRange: "15",
              start: vinManagerInventoryMaster.formatDate(startDate),
              end: vinManagerInventoryMaster.formatDate(new Date())
            }
          };
        }
        navigateToDestination(
          destination,
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.callsInbound,
      () => {
        navigateToDestination(
          {
            leftPage: "/CarDashboard/Pages/LeadManagement/DailyLeadDetail.aspx",
            leftQueryString: {
              pageDisplayType: "8"
            }
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.callsOutbound,
      () => {
        navigateToDestination(
          {
            leftPage: "/CarDashboard/Pages/LeadManagement/DailyLeadDetail.aspx",
            leftQueryString: {
              pageDisplayType: "7"
            }
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.callsMonitoredInbound,
      () => {
        let destination;
        if (vinManagerInventoryMaster.IsDealerEnabledForNewCallProviderLog) {
          destination = {
            leftPage: "/CarDashboard/Pages/rims2.aspx",
            leftQueryString: {
              urlSettingName: "brawndo.communicationsui.url",
              type: "phone",
              direction: "Inbound",
              mp: vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging
            }
          };
        } else {
          destination = {
            leftPage: "/CarDashboard/Pages/LeadManagement/CallProviderLog.aspx",
            leftQueryString: {
              callProviderType: "1"
            }
          };
        }
        navigateToDestination(
          destination,
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.callsMonitoredOutbound,
      () => {
        let destination;
        if (vinManagerInventoryMaster.IsDealerEnabledForNewCallProviderLog) {
          destination = {
            leftPage: "/CarDashboard/Pages/rims2.aspx",
            leftQueryString: {
              urlSettingName: "brawndo.communicationsui.url",
              type: "phone",
              direction: "Outbound",
              mp: vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging
            }
          };
        } else {
          destination = {
            leftPage: "/CarDashboard/Pages/LeadManagement/CallProviderLog.aspx",
            leftQueryString: {
              callProviderType: "2"
            }
          };
        }
        navigateToDestination(
          destination,
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.callsVCTInbound,
      () => {
        navigateToDestination(
          {
            leftPage: "/CarDashboard/Pages/LeadManagement/CallProviderLog.aspx",
            leftQueryString: {
              callProviderType: "4"
            }
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.callsVCTOutbound,
      () => {
        navigateToDestination(
          {
            leftPage: "/CarDashboard/Pages/LeadManagement/CallProviderLog.aspx",
            leftQueryString: {
              callProviderType: "3"
            }
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.callsCDR,
      () => {
        navigateToDestination(
          {
            leftPage: "/CarDashboard/Pages/CRM/CDR/CDRByUserReport.aspx",
            leftQueryString: {
              callProviderType: "3"
            }
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.callsUnmatched,
      () => {
        navigateToDestination(
          {
            leftPage: "/CarDashboard/Pages/rims2.aspx",
            leftQueryString: {
              urlSettingName: "brawndo.communicationsui.url",
              type: "phone",
              direction: "both",
              unmatched: true,
              mp:
                vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging ??
                false
            }
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.facebookLog,
      () => {
        const destination = {
          leftPage: "/CarDashboard/Pages/rims2.aspx",
          leftQueryString: {
            urlSettingName: "brawndo.communicationsui.url",
            type: "facebook",
            direction: "Inbound",
            mp:
              vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging ??
              false
          }
        };
        navigateToDestination(
          destination,
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ],
    [
      communicationsNavigationItemEntityIds.facebookMessenger,
      () => {
        const fbMessagingUrl = "communications.fbmessagingui.url";
        const cv = vinManagerInventoryMaster.getUuid();
        let url = [
          deriveCardashboardBaseUrl(),
          "/CarDashboard/Pages/rims2.aspx?urlSettingName=",
          fbMessagingUrl,
          "&cv=",
          cv,
          "&dealerid=",
          dealerId
        ].join("");
        if (
          vinManagerInventoryMaster.IsDealerEnabledForFacebookFullIntegration
        ) {
          const fb = vinManagerInventoryMaster.getUuid();
          const additionalUrl = ["&fb=", fb].join("");
          url = url.concat(additionalUrl);
        }
        const win = window.open(url, "Facebook Messenger");
        win?.focus();
      }
    ],
    [
      communicationsNavigationItemEntityIds.messageProviders,
      () => {
        navigateToDestination(
          {
            leftPage: "/CarDashboard/Pages/rims2.aspx",
            leftQueryString: {
              urlSettingName: "brawndo.communicationsui.url",
              type: "third party providers",
              mp: true
            }
          },
          setCarDashboardFrameUrl,
          vinManagerInventoryMaster
        );
      }
    ]
  ]);

  return retval;
};
