import { Table } from "cx";
import { getDMCTableColumnsConfig } from "./config/dmc-table-columns.config";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { StyledDMCTable } from "./styles/dmc-table.style";
import { useEffect, useState } from "react";
import { getDealerManufacturerCodesByDealerIdAsync } from "../../services/dealerManufacturerCodes.service";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useDMCContext } from "../../hooks/useDmcContext.hook";
import { DMCTableAdd } from "./components/dmc-table-add/dmc-table-add.component";
import { DMCAlert } from "../dmc-alert/dmc-alert.component";
import { ERROR_MSG } from "../../constants/messages.constant";
import { DMCActionEnum } from "../../Enums/dmcAction.enum";

const DMCColumns = getDMCTableColumnsConfig();

export const DMCTable = () => {
  const {
    state: { isLoading, dealerManufacturerCodes },
    dispatch
  } = useDMCContext();
  const [hasError, setHasError] = useState(false);
  const { dealerId } = useSelector(getProfileState, shallowEqual);
  const {
    oidcUser: { access_token: accessToken }
  } = useReactOidc();

  useEffect(() => {
    if (dealerId && dealerId != null && dealerId > 0) {
      (async () => {
        const { data, hasError } =
          await getDealerManufacturerCodesByDealerIdAsync(
            accessToken,
            dealerId
          );

        if (hasError) {
          setHasError(true);
        } else {
          if (dispatch) {
            dispatch({
              payload: { dealerManufacturerCodes: data, isLoading: false },
              type: DMCActionEnum.SET_DMC_COLLECTION
            });
          }
        }
      })();
    }
  }, [dealerId, accessToken, dispatch]);

  if (!hasError) {
    return (
      <StyledDMCTable>
        <DMCTableAdd />
        <Table
          columns={DMCColumns}
          data={dealerManufacturerCodes}
          data-testid="dealer-manufacturer-codes-list-table"
          enablePagination={false}
          htmlId="dealer-manufacturer-codes-list-table"
          loadingIndicatorDelay={0}
          scrollY={550}
          {...{ isLoading }}
        />
      </StyledDMCTable>
    );
  } else {
    return (
      <DMCAlert
        htmlId="dealer-manufacturer-codes-table-alert"
        type="danger"
        value={ERROR_MSG}
      />
    );
  }
};
