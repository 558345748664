import styled from "styled-components";
import AutoTraderIconHorizontal from "../icons/autotrader-icon-horizontal";

const StyledAutoTraderIcon = styled.span`
  margin-top: 10px;
`;

const AutoTraderIcon = () => {
  return (
    <StyledAutoTraderIcon>
      <AutoTraderIconHorizontal />
    </StyledAutoTraderIcon>
  );
};

export default AutoTraderIcon;
