// Polyfill "window.fetch" used in the React component.
import "whatwg-fetch";
import { PreloadedState, configureStore } from "@reduxjs/toolkit";
import {
  DYNAMIC_HEADER_FEATURE_KEY,
  MAIN_NAVIGATION_FEATURE_KEY,
  NAVIGATIONITEMS_FEATURE_KEY,
  NAVIGATION_FEATURE_KEY,
  NOTIFICATIONS_FEATURE_KEY,
  PROFILE_FEATURE_KEY,
  THEME_FEATURE_KEY,
  USER_STATUS_FEATURE_KEY,
  dynamicHeaderReducer,
  mainNavigationReducer,
  navigationItemsReducer,
  navigationReducer,
  notificationsReducer,
  profileReducer,
  themeReducer,
  userStatusReducer
} from "../../";

import {
  CARDASHBOARD_FEATURE_KEY,
  cardashboardReducer
} from "@vinsolutions/legacy-cardashboard/store";

import {
  CardashboardState,
  DynamicHeaderState,
  MainNavigationState,
  NavigationItemsState,
  NavigationState,
  NotificationsState,
  ProfileState,
  ThemeState,
  UserStatusState,
  VersionControlInformation
} from "@vinsolutions/ccrm/interfaces";
import {
  CcrmApi,
  defaultDashboardApi,
  versionControlApi
} from "@vinsolutions/ccrm/api";
import {
  USER_BRIDGE_STATE_FEATURE_KEY,
  UserBridgeState,
  userBridgeStateReducer
} from "@vinsolutions/tenant/store";
import {
  META_DATA_FEATURE_KEY,
  MetaDataState,
  metaDataReducer
} from "../meta-data.slice";

export interface GetStoreProps {
  cardashboard?: PreloadedState<CardashboardState>;
  ccrmApi?: PreloadedState<unknown>;
  mainNavigation?: PreloadedState<MainNavigationState>;
  metaData?: PreloadedState<MetaDataState>;
  navigation?: PreloadedState<NavigationState>;
  navigationItems?: PreloadedState<NavigationItemsState>;
  notifications?: PreloadedState<NotificationsState>;
  profile?: PreloadedState<ProfileState>;
  theme?: PreloadedState<ThemeState>;
  userStatus?: PreloadedState<UserStatusState>;
  versionControlInformation?: PreloadedState<VersionControlInformation>;
  userBridgeState?: PreloadedState<UserBridgeState>;
  dynamicHeader?: PreloadedState<DynamicHeaderState>;
}

export const getStore = ({
  cardashboard,
  mainNavigation,
  metaData,
  navigation,
  navigationItems,
  notifications,
  profile,
  theme,
  userStatus,
  userBridgeState,
  dynamicHeader
}: GetStoreProps) => {
  return configureStore({
    reducer: {
      [CARDASHBOARD_FEATURE_KEY]: cardashboardReducer,
      [CcrmApi.reducerPath]: CcrmApi.reducer,
      [MAIN_NAVIGATION_FEATURE_KEY]: mainNavigationReducer,
      [META_DATA_FEATURE_KEY]: metaDataReducer,
      [NAVIGATION_FEATURE_KEY]: navigationReducer,
      [NAVIGATIONITEMS_FEATURE_KEY]: navigationItemsReducer,
      [NOTIFICATIONS_FEATURE_KEY]: notificationsReducer,
      [PROFILE_FEATURE_KEY]: profileReducer,
      [THEME_FEATURE_KEY]: themeReducer,
      [USER_STATUS_FEATURE_KEY]: userStatusReducer,
      [versionControlApi.reducerPath]: versionControlApi.reducer,
      [USER_BRIDGE_STATE_FEATURE_KEY]: userBridgeStateReducer,
      [DYNAMIC_HEADER_FEATURE_KEY]: dynamicHeaderReducer,
      [defaultDashboardApi.reducerPath]: defaultDashboardApi.reducer
    },
    preloadedState: {
      cardashboard,
      mainNavigation,
      metaData,
      navigation,
      navigationItems,
      notifications,
      profile,
      theme,
      userStatus,
      userBridgeState,
      dynamicHeader
    },
    // Additional middleware can be passed to this array
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware(),
      CcrmApi.middleware,
      versionControlApi.middleware,
      defaultDashboardApi.middleware
    ],
    devTools: process.env["NODE_ENV"] !== "production",
    // Optional Redux store enhancers
    enhancers: []
  });
};
