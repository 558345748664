import { KeyInformationBannerLoad } from "@vinsolutions/ui/key-information-banner";
import { Reducer } from "react";

export const initialKeyInformationState: KeyInformationState = {
  accordionExpanded: true,
  initializedBanners: 0,
  loadedBanners: [],
  display: "none"
};

export const enum KeyInformationBannerActionTypes {
  bannerInitialized = "initialize",
  bannerLoaded = "load",
  deleteBanner = "delete",
  toggleAccordion = "toggleAccordion"
}

export interface KeyInformationBannerAction {
  value?: KeyInformationBannerLoad;
  initializedBannerCount?: number;
  type: KeyInformationBannerActionTypes;
}

export interface KeyInformationState {
  accordionExpanded: boolean;
  initializedBanners: number;
  loadedBanners: KeyInformationBannerLoad[];
  display: string | undefined;
}

const KeyInformationReducer: Reducer<
  KeyInformationState,
  KeyInformationBannerAction
> = (prevState: KeyInformationState, action: KeyInformationBannerAction) => {
  switch (action.type) {
    case KeyInformationBannerActionTypes.bannerLoaded: {
      const loadedBanners = [...(prevState.loadedBanners || [])];
      let addLoadedBanner = true;
      loadedBanners.forEach(existingBanner => {
        if (
          existingBanner.bannerName === action.value?.bannerName &&
          existingBanner.customerId === action.value?.customerId
        ) {
          existingBanner.iconName = action.value.iconName;
          existingBanner.isDisplayed = action.value.isDisplayed;
          existingBanner.thumbnailIcon = action.value.thumbnailIcon;
          existingBanner.thumbnailName = action.value.thumbnailName;
          addLoadedBanner = false;
        }
      });
      if (addLoadedBanner && action.value) {
        loadedBanners.push(action.value);
      }
      const display = loadedBanners.find(banner => banner.isDisplayed)
        ? undefined
        : "none";
      return { ...prevState, loadedBanners, display };
    }
    case KeyInformationBannerActionTypes.deleteBanner: {
      const loadedBanners = [...prevState.loadedBanners].filter(
        loadedBanner =>
          loadedBanner.bannerName !== action.value?.bannerName &&
          loadedBanner.customerId !== action.value?.customerId
      );
      const display = loadedBanners.find(banner => banner.isDisplayed)
        ? undefined
        : "none";
      return {
        ...prevState,
        loadedBanners,
        display
      };
    }
    case KeyInformationBannerActionTypes.toggleAccordion: {
      return {
        ...prevState,
        accordionExpanded: prevState.accordionExpanded ? false : true
      };
    }
    case KeyInformationBannerActionTypes.bannerInitialized: {
      const initializedBanners =
        prevState.initializedBanners + (action.initializedBannerCount || 0);
      return {
        ...prevState,
        initializedBanners
      };
    }
    default:
      return { ...prevState };
  }
};

export default KeyInformationReducer;
