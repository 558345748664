export interface Status {
  systemStatusId: systemStatuses;
  loginAllowed: boolean;
  statusMessages: string[];
  refreshIntervalInSeconds: number;
  loginLimited: boolean;
  cdAvailable: boolean;
}

export enum systemStatuses {
  Operational = 1,
  UnderMaintenance = 2,
  Degraded = 3,
  PartialOutage = 4,
  MajorOutage = 5
}
