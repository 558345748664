import React, { Suspense } from "react";
import styled from "styled-components";
import { loadRemoteModule } from "../load-remote-module";
import { LoadingIndicator } from "@vinsolutions/core-components";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { useVinconnectFlagEnabled } from "@vinsolutions/core/third-party/launch-darkly";
import { getEnvironmentName } from "@vinsolutions/core/config";

const Blocklist = React.lazy(() =>
  loadRemoteModule("customer", "./BlockListModule")
);

const StyledDealListWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export function BlocklistModule() {
  const { dealerId } = useSelector(getProfileState, shallowEqual);
  const blocklistLdFlag = useVinconnectFlagEnabled(
    "cardashboard.vinconnect.showblocklist-ui"
  );
  const envIsProd = getEnvironmentName() === "prodCoxAutoDomain";
  return blocklistLdFlag ? (
    <StyledDealListWrapper>
      <Suspense
        fallback={
          <LoadingIndicator
            action="blocklist-loaded"
            htmlId="blocklist-loading"
          />
        }
      >
        <Blocklist
          environment={envIsProd ? "prod" : "nonprod"}
          solutionDealerId={`${dealerId}`}
          solutionId="VIN"
        />
      </Suspense>
    </StyledDealListWrapper>
  ) : (
    <div />
  );
}

export default BlocklistModule;
