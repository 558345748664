import { memo } from "react";
import { Metric } from "@vinsolutions/ccrm/interfaces";
import styled from "styled-components";
import { Theme } from "../../util";
import { StyledLabel, StyledMetric } from "../activity-bar";

/* eslint-disable-next-line */
export interface MetricsProps {
  actionItem: string;
  htmlId: string;
  metrics: Metric[];
  secondaryLabel: string;
}

const StyledCount = styled.h2<Pick<Theme, "actionItem">>`
  color: ${({ actionItem }) => actionItem};
  margin: 0;
  padding: 0;
  height: 32px;
  line-height: 24px;
`;

export function Metrics({
  actionItem,
  htmlId,
  metrics,
  secondaryLabel
}: MetricsProps) {
  return (
    <>
      {metrics.map(({ label, onClick, count }) => {
        const id = `${htmlId}-${label.toLowerCase().split(" ").join("-")}`;
        return (
          <StyledMetric
            key={id}
            {...{ id, onClick, secondaryLabel, actionItem }}
          >
            <StyledCount id={`${id}-count`} {...{ actionItem }}>
              {count}
            </StyledCount>
            <StyledLabel id={`${id}-label`} {...{ secondaryLabel }}>
              {label}
            </StyledLabel>
          </StyledMetric>
        );
      })}
    </>
  );
}

const MemoizedMetrics = memo(Metrics);
export default MemoizedMetrics;
