import { Dealer, SetupStatus } from "@vinsolutions/ccrm/interfaces";

const filterDealerListByAlphaNumericCharacters = (
  dealerList: Dealer[],
  searchText: string,
  activeDealersOnly: boolean
) => {
  return dealerList.filter(dealer => {
    const { dealerName, setupStatus } = dealer;
    const textCheck = dealerName
      .toLowerCase()
      .includes(searchText.toLowerCase());

    return activeDealersOnly
      ? setupStatus !== SetupStatus.Inactive && textCheck
      : textCheck;
  });
};

const filterDealerListByDealerId = (
  dealerList: Dealer[],
  searchText: string,
  activeDealersOnly: boolean
) => {
  return dealerList.filter(dealer => {
    const { dealerID, setupStatus } = dealer;
    const textCheck = dealerID.toString() === searchText;

    return activeDealersOnly
      ? setupStatus !== SetupStatus.Inactive && textCheck
      : textCheck;
  });
};

export const removeInactiveDealers = (dealerList: Dealer[]) => {
  return dealerList.filter(({ setupStatus }) => {
    return setupStatus !== SetupStatus.Inactive;
  });
};

export const filterDealerList = (
  dealerList: Dealer[],
  searchText: string,
  activeDealersOnly: boolean
) => {
  // for the record, I think this search is counter intuitive - SG
  return isNaN(parseInt(searchText, 10))
    ? filterDealerListByAlphaNumericCharacters(
        dealerList,
        searchText,
        activeDealersOnly
      )
    : filterDealerListByDealerId(dealerList, searchText, activeDealersOnly);
};
