import {
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import {
  NavigationItemsEntity,
  NavigationItemsState
} from "@vinsolutions/ccrm/interfaces";
export const NAVIGATIONITEMS_FEATURE_KEY = "navigationItems";

/*
 * Update these interfaces according to your requirements.
 */

export const navigationItemsAdapter =
  createEntityAdapter<NavigationItemsEntity>();

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchNavigationItems())
 * }, [dispatch]);
 * ```
 */

export const initialNavigationItemsState: NavigationItemsState =
  navigationItemsAdapter.getInitialState({
    loadingStatus: "not loaded",
    error: null
  });

export const navigationItemsSlice = createSlice({
  name: NAVIGATIONITEMS_FEATURE_KEY,
  initialState: initialNavigationItemsState,
  reducers: {
    update: navigationItemsAdapter.updateOne,
    upsert: navigationItemsAdapter.upsertOne,
    set: navigationItemsAdapter.setAll,
    removeMany: navigationItemsAdapter.removeMany,
    upsertMany: navigationItemsAdapter.upsertMany
  }
});

/*
 * Export reducer for store configuration.
 */
export const navigationItemsReducer = navigationItemsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(navigationItemsActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const navigationItemsActions = navigationItemsSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllNavigationItems);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = navigationItemsAdapter.getSelectors();

export const getNavigationItemsState = (rootState: any): NavigationItemsState =>
  rootState[NAVIGATIONITEMS_FEATURE_KEY];

export const selectAllNavigationItems = createSelector(
  getNavigationItemsState,
  selectAll
);

export const selectNavigationItemsEntities = createSelector(
  getNavigationItemsState,
  selectEntities
);
