export interface Theme {
  primary: string;
  secondary: string;
  secondaryIcon: string;
  surface: string;
  background: string;
  abovePrimary: string;
  aboveSecondary: string;
  aboveSurface: string;
  buttonHover: string;
  activeTabColor: string;
  secondaryLabel: string;
  actionItem: string;
  secondaryActionItem: string;
}

export default function getTheme(isDarkTheme: boolean) {
  const darkTheme = {
    primary: "#0e2245",
    secondary: "#2153aa",
    secondaryIcon: "#fff",
    surface: "#0e2245",
    background: "#0e2245",
    abovePrimary: "#fff",
    aboveSecondary: "#fff",
    aboveSurface: "#f5f1f1",
    buttonHover: "#173a78",
    activeTabColor: "#fff",
    secondaryLabel: "#fff",
    actionItem: "#fff",
    secondaryActionItem: "#fff"
  };

  const lightTheme = {
    primary: "#0e2245",
    secondary: "#2153aa",
    secondaryIcon: "#2153aa",
    surface: "#0e2245",
    background: "#fff",
    abovePrimary: "#000",
    aboveSecondary: "#fff",
    aboveSurface: "#000",
    buttonHover: "#173a78",
    activeTabColor: "#fff",
    secondaryLabel: "#6d727a",
    actionItem: "#2b6bdd",
    secondaryActionItem: "#2053ab"
  };

  return isDarkTheme ? darkTheme : lightTheme;
}
