import {
  BaseUrlsObj,
  envEnum,
  getBaseUrl,
  getCurrentEnv
} from "@vinsolutions/core/environment";
import { fetchWrapper } from "@vinsolutions/core/http";
import { createLogger } from "@vinsolutions/logger";
import { mockKbbIcoInfoResponses } from "./mocks/mock-kbbico-responses";
import { mockAutoTraderResponses } from "./mocks/mock-autotrader-responses";
import { OpportunityAttributesApiConfig } from "./vin-crm-opportunityattributes.api";
import {
  AutoTraderOpportunityAttributesList,
  KbbIcoOpportunityAttributesList,
  OpportunityAttributesResponse
} from "./models/opportunity-attributes-response.model";
import { KbbIcoInfo } from "./models/kbbico-info.model";
import { AutoTraderInfo } from "./models/autotrader-info.model";

const logger = createLogger("data-access-lead-vin-crm-opportunityattributes");

type Config = {
  baseUrls: BaseUrlsObj;
};

const config: Config = {
  baseUrls: {
    [envEnum.LOCAL]: `${OpportunityAttributesApiConfig.baseUrls.localhost}`,
    [envEnum.DEV]: `${OpportunityAttributesApiConfig.baseUrls.dev}`,
    [envEnum.QA]: `${OpportunityAttributesApiConfig.baseUrls.qa}`,
    [envEnum.STAGING]: `${OpportunityAttributesApiConfig.baseUrls.staging}`,
    [envEnum.PRODUCTION]: `${OpportunityAttributesApiConfig.baseUrls.production}`
  }
};

export async function getKbbIcoInfo(
  customerId: number,
  jwt: string
): Promise<KbbIcoInfo | null | undefined> {
  const env = getCurrentEnv();

  if (env === envEnum.LOCAL || env === envEnum.DEV) {
    return mockKbbIcoInfoResponses[env];
  }

  const opportunityAttributesUrl = getOpportunityAttributesUrl(
    customerId,
    "KBB_ICO"
  );
  const response = await fetchWrapper.getAsync<OpportunityAttributesResponse>(
    opportunityAttributesUrl,
    {
      Authorization: `Bearer ${jwt}`,
      Accept: "*/*"
    }
  );

  if (response.statusCode !== 200) {
    logger.error("Error getting KbbIcoInfo", JSON.stringify(response));
    return null;
  }

  if (response.data !== null && response.data?.Attributes) {
    const attributes: KbbIcoOpportunityAttributesList = JSON.parse(
      response.data.Attributes
    );

    return {
      offerGuid: attributes.ParentOfferGuid
        ? attributes.ParentOfferGuid
        : attributes.OfferGuid,
      dealerGuid: attributes.DealerGuid,
      offerPrice: attributes.OfferPrice,
      date: response.data.LastUpdatedUtc,
      env
    } as KbbIcoInfo;
  }

  return null;
}

export async function getAutoTraderInfo(
  customerId: number,
  jwt: string
): Promise<AutoTraderInfo | null | undefined> {
  const env = getCurrentEnv();

  if (env === envEnum.LOCAL || env === envEnum.DEV) {
    return mockAutoTraderResponses[env];
  }

  const opportunityAttributesUrl =
    getOpportunityAttributesAutoTraderUrl(customerId);
  const response = await fetchWrapper.getAsync<OpportunityAttributesResponse>(
    opportunityAttributesUrl,
    {
      Authorization: `Bearer ${jwt}`,
      Accept: "*/*"
    }
  );

  if (response.statusCode !== 200) {
    logger.error("Error getting AutoTraderInfo", JSON.stringify(response));
    return null;
  }

  if (response.data !== null && response.data?.Attributes) {
    const attributes: AutoTraderOpportunityAttributesList = JSON.parse(
      response.data.Attributes
    );

    return {
      service: attributes.Service,
      details: attributes.Details,
      date: response.data.LastUpdatedUtc,
      env
    } as AutoTraderInfo;
  }

  return null;
}

export function getOpportunityAttributesUrl(
  customerId?: number,
  namespacePrefix?: string
) {
  const baseUrl = getBaseUrl(config.baseUrls);
  return `${baseUrl}/opportunity-attributes?namespace_prefix=${namespacePrefix}&id=${customerId}`;
}

export function getOpportunityAttributesAutoTraderUrl(customerId?: number) {
  const baseUrl = getBaseUrl(config.baseUrls);
  return `${baseUrl}/autotrader?id=${customerId}`;
}
