import styled from "styled-components";

/* eslint-disable-next-line */
export interface VinessaErrorProps {
  errorMessage?: string;
  onRetryClick: () => void;
}

const StyledVinessaBannerError = styled.span`
  cursor: pointer;
  :hover {
    font-weight: 700;
  }
`;

export const VinessaError = ({
  errorMessage,
  onRetryClick
}: VinessaErrorProps) => {
  return (
    errorMessage && (
      <StyledVinessaBannerError onClick={onRetryClick}>
        {errorMessage}
      </StyledVinessaBannerError>
    )
  );
};

export default VinessaError;
