// eslint-disable-next-line @nx/enforce-module-boundaries
import { BaseUrlsObj, envEnum } from "@vinsolutions/core/environment";

type Config = {
  baseUrls: BaseUrlsObj;
};

export const OemInsightsConfig: Config = {
  baseUrls: {
    [envEnum.LOCAL]: "https://oem-insights-api-dev.vinsolutions.com/api/v3/",
    [envEnum.DEV]: "https://oem-insights-api-qa.vinsolutions.com/api/v3/",
    [envEnum.QA]: "https://oem-insights-api-qa.vinsolutions.com/api/v3/",
    [envEnum.STAGING]:
      "https://oem-insights-api-staging.vinsolutions.com/api/v3/",
    [envEnum.PRODUCTION]: "https://oem-insights-api.vinsolutions.com/api/v3/"
  }
};
