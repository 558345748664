export const addVersionControlChangeEventListener = (
  callback: (e: StorageEvent) => void
) => {
  window.addEventListener("storage", callback, true);
};

export const removeVersionControlChangeEventListener = (
  callback: (e: StorageEvent) => void
) => {
  window.removeEventListener("storage", callback, true);
};
