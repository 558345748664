import { IVinManagerInventoryMaster } from "./vinmanager-inventory-master";
import { communicationsNavigationItemEntityIds } from "./navigation-ids";
import { generateDestinationUrl } from "./vinmanager-inventory-master";
import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";

const getAllCallNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  const callNavigationItemsEntities: NavigationItemsEntity[] = [
    {
      id: communicationsNavigationItemEntityIds.callsInbound,
      label: "Inbound",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/LeadManagement/DailyLeadDetail.aspx",
          leftQueryString: {
            pageDisplayType: "8"
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.callsOutbound,
      label: "Outbound",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/LeadManagement/DailyLeadDetail.aspx",
          leftQueryString: {
            pageDisplayType: "7"
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.callsMonitoredInbound,
      label: "Monitored Inbound",
      url: generateDestinationUrl(
        vinManagerInventoryMaster.IsDealerEnabledForNewCallProviderLog
          ? {
              leftPage: "/CarDashboard/Pages/rims2.aspx",
              leftQueryString: {
                urlSettingName: "brawndo.communicationsui.url",
                type: "phone",
                direction: "Inbound",
                mp: vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging
              }
            }
          : {
              leftPage:
                "/CarDashboard/Pages/LeadManagement/CallProviderLog.aspx",
              leftQueryString: {
                callProviderType: "1"
              }
            },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.callsMonitoredOutbound,
      label: "Monitored Outbound",
      url: generateDestinationUrl(
        vinManagerInventoryMaster.IsDealerEnabledForNewCallProviderLog
          ? {
              leftPage: "/CarDashboard/Pages/rims2.aspx",
              leftQueryString: {
                urlSettingName: "brawndo.communicationsui.url",
                type: "phone",
                direction: "Outbound",
                mp: vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging
              }
            }
          : {
              leftPage:
                "/CarDashboard/Pages/LeadManagement/CallProviderLog.aspx",
              leftQueryString: {
                callProviderType: "2"
              }
            },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.callsVCTInbound,
      label: "VCT Inbound",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/LeadManagement/CallProviderLog.aspx",
          leftQueryString: {
            callProviderType: "4"
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.callsVCTOutbound,
      label: "VCT Outbound",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/LeadManagement/CallProviderLog.aspx",
          leftQueryString: {
            callProviderType: "3"
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.callsCDR,
      label: "CDR",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/CRM/CDR/CDRByUserReport.aspx",
          leftQueryString: {
            callProviderType: "3"
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.callsUnmatched,
      label: "Unmatched",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/rims2.aspx",
          leftQueryString: {
            urlSettingName: "brawndo.communicationsui.url",
            type: "phone",
            direction: "both",
            unmatched: true,
            mp:
              vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging ??
              false
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    }
  ];

  return callNavigationItemsEntities;
};

const filterCallNavigationItems = (
  callNavigationItems: NavigationItemsEntity[],
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  let validItems = [] as NavigationItemsEntity[];

  validItems = validItems.concat(callNavigationItems);

  if (!vinManagerInventoryMaster.SecurityPrincipalILMUserIsManagerOrAdmin) {
    if (vinManagerInventoryMaster.IsDealerEnabledForNewCallProviderLog) {
      // only show Monitored In & Out
      const callKeys = [
        "Inbound",
        "Outbound",
        "VCT Inbound",
        "VCT Outbound",
        "CDR"
      ];
      validItems = validItems.filter(item => !callKeys.includes(item.label));
    } else {
      // remove all call types
      const callKeys = [
        "Inbound",
        "Outbound",
        "VCT Inbound",
        "VCT Outbound",
        "Monitored Inbound",
        "Monitored Outbound",
        "CDR"
      ];
      validItems = validItems.filter(item => !callKeys.includes(item.label));
    }
  }

  if (!vinManagerInventoryMaster.SelectedDealerProfile_IsCallTrackingBillable) {
    const callKeys = ["VCT Inbound", "VCT Outbound"];
    validItems = validItems.filter(item => !callKeys.includes(item.label));
  }

  if (!vinManagerInventoryMaster.IsDealerEnabledForNewCallProviderLog) {
    validItems.splice(
      validItems.findIndex(item => item.label === "Unmatched"),
      1
    );
  }

  return validItems;
};

export const buildCallNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  const allCallNavigationItems = getAllCallNavigationItems(
    vinManagerInventoryMaster
  );

  const filteredCallNavigationItems = filterCallNavigationItems(
    allCallNavigationItems,
    vinManagerInventoryMaster
  );

  const callNavigationEntity: NavigationEntity | null =
    filteredCallNavigationItems.length > 0
      ? {
          id: communicationsNavigationItemEntityIds.calls,
          items: filteredCallNavigationItems.map(item => {
            return {
              id: item.id
            };
          })
        }
      : null;

  return {
    callNavigationItemEntities: filteredCallNavigationItems,
    callNavigationEntity
  };
};
