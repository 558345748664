import { useNavigate } from "react-router-dom";
import { useGetDefaultDashboard } from "./use-get-default-dashboard";
import { useEffect } from "react";

const vinConnectDefaultUrl = "/vinconnect/#/cardashboard";

const customerDashboard = "CustomerDashboard.aspx";
const dealerDashboard1 = "DealerDashboard1.aspx";
const dealerDashboard2 = "DealerDashboard2.aspx";
const emailRepliesNotRead = "EmailRepliesNotRead.aspx";

const welcomeDashboardPath = "/pane-both/vinconnect-dealer-dashboard";
const welcomeDashboardCustomerPath =
  "/pane-both/vinconnect-dealer-dashboard-customer";
const emailRepliesNotReadPath =
  "/pane-both/vinconnect-dealer-dashboard-unanswered-email-replies";

function hrefIsDashboardWithUrl(url: string) {
  if (!url) {
    return;
  }

  const href = window.location.href.toLowerCase();

  return (
    href.includes(url.toLowerCase()) &&
    href.includes(dealerDashboard2.toLowerCase())
  );
}

function hrefEndsWith(url: string) {
  return window.location.href.toLowerCase().endsWith(url);
}

export function useRouteToDefaultDashboard() {
  const navigate = useNavigate();

  const { dashboardConfig, isSuccess, isError } = useGetDefaultDashboard();

  useEffect(() => {
    if (
      !isSuccess ||
      isError ||
      dashboardConfig?.LeftPane !== dealerDashboard2
    ) {
      return;
    }

    if (
      // is href default page or does it contain DealerDashboard2 & DealerDashboard1? If so go to the welcome dashboard
      hrefEndsWith(vinConnectDefaultUrl) ||
      hrefIsDashboardWithUrl(dealerDashboard1)
    ) {
      navigate(welcomeDashboardPath, {
        replace: true
      });
    }

    // does href contain DealerDashboard2 and CustomerDashboard? If so go to the welcome dashboard with customer
    if (hrefIsDashboardWithUrl(customerDashboard)) {
      const globalCustomerId = new URLSearchParams(window.location.search).get(
        "GlobalCustomerID"
      );

      if (globalCustomerId !== null) {
        navigate(welcomeDashboardCustomerPath, {
          state: {
            queryParams: {
              GlobalCustomerID: globalCustomerId,
              r: Math.random()
            }
          },
          replace: true
        });
      }
    }

    // does href contain DealerDashboard2 and EmailRepliesNotRead? If so go to the welcome dashboard with EmailRepliesNotRead
    if (hrefIsDashboardWithUrl(emailRepliesNotRead)) {
      navigate(emailRepliesNotReadPath, {
        replace: true
      });
    }
  }, [dashboardConfig, window.location.href]);
}
