import { createLogger } from "@vinsolutions/logger";
import { trackInNewRelic } from "@vinsolutions/core-third-party-newrelic";
import { getModuleUrl } from "./get-module-url";

const logger = createLogger("Main");
const moduleFederationManifest = getModuleUrl();

/**
 * Loads the module federation manifest.
 * @returns The parsed manifest as an object.
 * @throws Error if the manifest cannot be fetched.
 */
export async function loadManifest(): Promise<Record<string, string>> {
  try {
    const response = await fetch(moduleFederationManifest);

    if (!response.ok) {
      logger.error(
        `Main: Error loading Module Federation Manifest at ${moduleFederationManifest}`,
        response
      );

      throw new Error("Failed to fetch remote module location manifest");
    }

    return response.json();
  } catch (error) {
    trackInNewRelic({
      componentAction: "ModuleFederationManifestRequest",
      locationLoadedFrom: moduleFederationManifest,
      errorContext: (error as Error).message
    });

    throw new Error("Failed to fetch remote module location manifest");
  }
}
