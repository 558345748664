import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";
import {
  adminNavigationItemEntityIds,
  crmNavigationItemEntityIds,
  ilmNavigationItemEntityIds,
  settingsNavigationItemEntityIds
} from "./navigation-ids";

const generateDuplicatePrimaryMenuItemNavigationEntity = (
  navigationEntities: NavigationEntity[],
  tabId: string,
  menuId: string,
  primaryMenuItemId: string
) => {
  const tabNavigationEntity = navigationEntities.find(
    entity => entity.id === tabId
  );

  if (tabNavigationEntity && tabNavigationEntity.items) {
    const menuNavigationEntity = tabNavigationEntity.items.find(
      entity => entity.id === menuId
    );

    if (menuNavigationEntity) {
      menuNavigationEntity.items = [
        {
          id: primaryMenuItemId
        }
      ].concat(menuNavigationEntity.items ?? []);
    }
  }
};

const generateDuplicateNavigationItemEntity = (
  navigationItemsEntities: NavigationItemsEntity[],
  parentId: string,
  duplicateItemId: string
) => {
  const parentItemEntity = navigationItemsEntities.find(
    item => item.id === parentId
  );

  if (parentItemEntity) {
    navigationItemsEntities.push({
      id: duplicateItemId,
      url: parentItemEntity.url,
      label: parentItemEntity.label,
      target: parentItemEntity.target,
      type: parentItemEntity.type
    });
  }
};

const generateDuplicateLeads = (
  navigationEntities: NavigationEntity[],
  navigationItemsEntities: NavigationItemsEntity[]
) => {
  generateDuplicatePrimaryMenuItemNavigationEntity(
    navigationEntities,
    crmNavigationItemEntityIds.crm,
    crmNavigationItemEntityIds.leads,
    crmNavigationItemEntityIds.leadsDuplicate
  );
  generateDuplicateNavigationItemEntity(
    navigationItemsEntities,
    crmNavigationItemEntityIds.leads,
    crmNavigationItemEntityIds.leadsDuplicate
  );
  generateDuplicatePrimaryMenuItemNavigationEntity(
    navigationEntities,
    ilmNavigationItemEntityIds.ilm,
    ilmNavigationItemEntityIds.leads,
    ilmNavigationItemEntityIds.leadsDuplicate
  );
  generateDuplicateNavigationItemEntity(
    navigationItemsEntities,
    ilmNavigationItemEntityIds.leads,
    ilmNavigationItemEntityIds.leadsDuplicate
  );
};

const generateDuplicateDealerSettings = (
  navigationEntities: NavigationEntity[],
  navigationItemsEntities: NavigationItemsEntity[]
) => {
  generateDuplicatePrimaryMenuItemNavigationEntity(
    navigationEntities,
    settingsNavigationItemEntityIds.settings,
    settingsNavigationItemEntityIds.dealerSettings,
    settingsNavigationItemEntityIds.dealerSettingsDuplicate
  );
  generateDuplicateNavigationItemEntity(
    navigationItemsEntities,
    settingsNavigationItemEntityIds.dealerSettings,
    settingsNavigationItemEntityIds.dealerSettingsDuplicate
  );
};

const generateDuplicateDashboard = (
  navigationEntities: NavigationEntity[],
  navigationItemsEntities: NavigationItemsEntity[]
) => {
  generateDuplicatePrimaryMenuItemNavigationEntity(
    navigationEntities,
    crmNavigationItemEntityIds.crm,
    crmNavigationItemEntityIds.dashboard,
    crmNavigationItemEntityIds.dashboardDuplicate
  );
  generateDuplicateNavigationItemEntity(
    navigationItemsEntities,
    crmNavigationItemEntityIds.dashboard,
    crmNavigationItemEntityIds.dashboardDuplicate
  );
  generateDuplicatePrimaryMenuItemNavigationEntity(
    navigationEntities,
    ilmNavigationItemEntityIds.ilm,
    ilmNavigationItemEntityIds.dashboard,
    ilmNavigationItemEntityIds.dashboardDuplicate
  );
  generateDuplicateNavigationItemEntity(
    navigationItemsEntities,
    ilmNavigationItemEntityIds.dashboard,
    ilmNavigationItemEntityIds.dashboardDuplicate
  );
};

const generateDuplicateCrmEmail = (
  navigationEntities: NavigationEntity[],
  navigationItemsEntities: NavigationItemsEntity[]
) => {
  generateDuplicatePrimaryMenuItemNavigationEntity(
    navigationEntities,
    crmNavigationItemEntityIds.crm,
    crmNavigationItemEntityIds.email,
    crmNavigationItemEntityIds.duplicateEmail
  );
  generateDuplicateNavigationItemEntity(
    navigationItemsEntities,
    crmNavigationItemEntityIds.email,
    crmNavigationItemEntityIds.duplicateEmail
  );
  generateDuplicatePrimaryMenuItemNavigationEntity(
    navigationEntities,
    ilmNavigationItemEntityIds.ilm,
    ilmNavigationItemEntityIds.email,
    ilmNavigationItemEntityIds.duplicateEmail
  );
  generateDuplicateNavigationItemEntity(
    navigationItemsEntities,
    ilmNavigationItemEntityIds.email,
    ilmNavigationItemEntityIds.duplicateEmail
  );
};

const generateDuplicateDealerList = (
  navigationEntities: NavigationEntity[],
  navigationItemsEntities: NavigationItemsEntity[]
) => {
  generateDuplicatePrimaryMenuItemNavigationEntity(
    navigationEntities,
    adminNavigationItemEntityIds.admin,
    adminNavigationItemEntityIds.dealerList,
    adminNavigationItemEntityIds.duplicateDealerList
  );
  generateDuplicateNavigationItemEntity(
    navigationItemsEntities,
    adminNavigationItemEntityIds.dealerList,
    adminNavigationItemEntityIds.duplicateDealerList
  );
};

export const generateDuplicateNavigationItems = (
  navigationEntities: NavigationEntity[],
  navigationItemsEntities: NavigationItemsEntity[]
) => {
  generateDuplicateDealerList(navigationEntities, navigationItemsEntities);
  generateDuplicateCrmEmail(navigationEntities, navigationItemsEntities);
  generateDuplicateLeads(navigationEntities, navigationItemsEntities);
  generateDuplicateDealerSettings(navigationEntities, navigationItemsEntities);
  generateDuplicateDashboard(navigationEntities, navigationItemsEntities);
};
