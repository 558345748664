import { verifyNewRelicTrackingLoaded } from "./helper";

/**
 * Returns a new API object that is bound to the current SPA interaction.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/interaction-browser-spa-api/
 */
export function interaction() {
  if (!verifyNewRelicTrackingLoaded()) {
    return null;
  }
  return window.newrelic!.interaction();
}
