import { useCallback, useEffect, useState } from "react";
import { getDealKeyInformation } from "@vinsolutions/data-access/lead/crm.workflow.keyinfo.accelerate";
import { DealKeyInformation } from "@vinsolutions/data-access/lead/crm.workflow.keyinfo.accelerate";
import { KeyInformationBanner } from "@vinsolutions/ui/key-information-banner";
import KeyInformationDealSummary from "./components/key-information-deal-summary";
import KeyInformationDealDate from "./components/key-information-deal-date";
import AccelerateIcon from "./icons/accelerate-icon";
import KeyInformationDealModal from "./components/key-information-deal-modal";
import KeyInformationDealBannerProps from "./interfaces/key-information-deal-banner-props";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import AccelerateThumbnail from "./icons/accelerate-thumbnail";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";

const accelerateBlue = "#14377D";
const accelerateInverse = "#FFFFFF";
const bannerName = "Accelerate";
export const BaseDisplayedBanner = {
  bannerName,
  iconName: "AccelerateIconSmall",
  thumbnailName: "AccelerateIconSmall",
  thumbnailIcon: <AccelerateThumbnail />
};

export const KeyInformationDealBanner = ({
  customerId,
  selectedLeadId,
  activeLeadIds,
  dealerId,
  onInitialize,
  onLoad,
  onClickGtmEvent
}: KeyInformationDealBannerProps) => {
  const [dealSummary, setDealSummary] = useState<
    DealKeyInformation | null | undefined
  >(null);
  const jwt = useReactOidc().oidcUser.access_token;
  const [iconFillColor, setIconFillColor] = useState(accelerateBlue);
  const [showModal, setShowModal] = useState(false);

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "DealBanner",
    "Loading Deal Banner Summary"
  );
  setInteractionAttributes({
    customerId: customerId || 0,
    dealerId: dealerId || 0
  });

  const loadDealSummary = useCallback(async () => {
    const leadId =
      selectedLeadId || (activeLeadIds?.length && activeLeadIds[0]);
    const summaryResult =
      customerId && leadId
        ? await getDealKeyInformation(customerId, leadId, jwt)
        : null;
    setDealSummary(summaryResult);
    const isDisplayed = !!summaryResult;
    onLoad({
      ...BaseDisplayedBanner,
      customerId,
      isDisplayed
    });
    setInteractionAttributes({ isDisplayed });
    endInteraction();
  }, [
    customerId,
    selectedLeadId,
    activeLeadIds,
    jwt,
    onLoad,
    endInteraction,
    setInteractionAttributes
  ]);

  useEffect(() => {
    onInitialize?.(1);
    loadDealSummary();
  }, [loadDealSummary, onInitialize]);

  const onBannerClick = useCallback(() => {
    onClickGtmEvent?.(bannerName);
    setShowModal(true);
  }, [onClickGtmEvent]);

  return (
    dealSummary && (
      <>
        <KeyInformationBanner
          bannerDataDetail={
            <KeyInformationDealDate dealSummary={dealSummary} />
          }
          bannerIcon={<AccelerateIcon fillColor={iconFillColor} />}
          bannerName={bannerName}
          bannerSummary={
            <KeyInformationDealSummary dealSummary={dealSummary} />
          }
          onClick={onBannerClick}
          onMouseEnter={() => setIconFillColor(accelerateInverse)}
          onMouseLeave={() => setIconFillColor(accelerateBlue)}
        />
        <KeyInformationDealModal
          customerId={customerId || 0}
          dealExchangeId={dealSummary.dealId}
          dealerId={dealerId}
          drActivityCount={dealSummary.drActivityCount}
          leadId={dealSummary.autoLeadId}
          showModal={showModal}
          onHide={() => setShowModal(false)}
        />
      </>
    )
  );
};

export default KeyInformationDealBanner;
