import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  margin-right: 0.25rem;
`;
export function CopyIcon() {
  return (
    <StyledSvg
      height={18}
      viewBox="0 0 10 12"
      width={18}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{"document-copy"}</title>
      <g fill="#2B6BDD" fillRule="nonzero">
        <path d="M12 12h-1.09V1.09H1.635V0h9.819c.3 0 .545.244.545.545V12z" />
        <path d="M9.273 2.182H.545A.546.546 0 000 2.727v9.818c0 .302.244.546.545.546h8.728a.546.546 0 00.545-.546V2.727a.546.546 0 00-.545-.545zm-3.818 8.182H2.182V9.273h3.273v1.09zm2.181-2.182H2.182V7.09h5.454v1.09zm0-2.182H2.182V4.91h5.454V6z" />
      </g>
    </StyledSvg>
  );
}
