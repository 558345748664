import { useCallback, useEffect } from "react";
import { createLogger } from "@vinsolutions/logger";
import { PortalEventMessage } from "../interfaces/portals";

const logger = createLogger("use-portal-listener");

export const usePortalListener = (
  eventName: string,
  onEvent: (portalEvent: PortalEventMessage) => void
) => {
  const handlerCustomEvents = useCallback(
    (event: CustomEvent<PortalEventMessage>) => {
      if (
        !event.detail.originDocument ||
        !event.detail.registerPortals?.length
      ) {
        logger.error(
          "The portal event detail requires originFrameElement containing at least one entry in registeredPortals."
        );
        return;
      }
      onEvent(event.detail);
    },
    [onEvent]
  );

  useEffect(() => {
    window.addEventListener(eventName, handlerCustomEvents as EventListener);
    return () => {
      window.removeEventListener(
        eventName,
        handlerCustomEvents as EventListener
      );
    };
  }, [handlerCustomEvents, eventName]);
};
