enum AppointmentStatus {
  COMPLETED = 1,
  MISSED = 2,
  CANCELLED = 3,
  RESCHEDULED = 4,
  CONFIRMED = 5,
  SET = 6
}

export default AppointmentStatus;
