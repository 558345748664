import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";
import { NewNavigationItemsEntity } from "./nav-items-catalog";

export interface NavigationArrays {
  navigationItemsEntities: NavigationItemsEntity[];
  navigationEntities: NavigationEntity[];
  newNavItems: NewNavigationItemsEntity[];
}

export function addNavigationItems({
  navigationItemsEntities,
  navigationEntities,
  newNavItems
}: NavigationArrays) {
  newNavItems.forEach((navItem: NewNavigationItemsEntity) => {
    const itemToReplace: NavigationItemsEntity | undefined =
      navigationItemsEntities.find(item => item.id === navItem.id);

    let newItem: NavigationItemsEntity = {
      id: navItem.id,
      label: navItem.label,
      target: navItem.target,
      url: navItem.url,
      type: navItem.type
    };

    if (itemToReplace) {
      newItem = {
        id: newItem.id.length > 0 ? newItem.id : itemToReplace.id,
        label: newItem.label.length > 0 ? newItem.label : itemToReplace.label,
        target:
          newItem.target.length > 0 ? newItem.target : itemToReplace.target,
        url: newItem.url.length > 0 ? newItem.url : itemToReplace.url,
        type: newItem.type.length > 0 ? newItem.type : itemToReplace.type
      };

      const index = navigationItemsEntities.findIndex(
        item => item.id === navItem.id
      );
      navigationItemsEntities.splice(index, 1, newItem);
    } else {
      if (!navItem.parentTabId && navItem.predecessorTabId) {
        // add the tab to the parent tab list
        const predecessorNavEntitiesIndex = navigationEntities.findIndex(
          item => item.id === navItem.predecessorTabId
        );
        if (predecessorNavEntitiesIndex !== -1) {
          navigationEntities.splice(predecessorNavEntitiesIndex + 1, 0, {
            id: navItem.id,
            items: []
          });
        }

        // identify the final predecessor in the complete nav item list
        let lastIndexOfPredecessor = -1;
        for (let i = navigationItemsEntities.length - 1; i >= 0; i--) {
          if (
            navigationItemsEntities[i].id.includes(navItem.predecessorTabId)
          ) {
            lastIndexOfPredecessor = i;
            break;
          }
        }

        // add the tab to the complete nav item list
        if (lastIndexOfPredecessor !== -1) {
          navigationItemsEntities.splice(lastIndexOfPredecessor + 1, 0, {
            id: navItem.id,
            label: navItem.label,
            target: navItem.target,
            url: navItem.url,
            type: navItem.type
          });
        }
      }
      if (navItem.parentTabId && !navItem.predecessorTabId) {
        const parentNavEntitiesIndex = navigationEntities.findIndex(
          item => item.id === navItem.parentTabId
        );
        if (
          parentNavEntitiesIndex !== -1 &&
          navigationEntities[parentNavEntitiesIndex].items
        ) {
          navigationEntities[parentNavEntitiesIndex].items?.[
            navItem.insertPosition === "end" ? "push" : "unshift"
          ]({
            id: navItem.id
          });
        }

        const parentNavItemsEntitiesIndex = navigationItemsEntities.findIndex(
          item => item.id === navItem.parentTabId
        );

        if (parentNavItemsEntitiesIndex !== -1) {
          navigationItemsEntities.splice(parentNavItemsEntitiesIndex + 1, 0, {
            id: navItem.id,
            label: navItem.label,
            target: navItem.target,
            url: navItem.url,
            type: navItem.type
          });
        }
      }
    }
  });

  return { navigationItemsEntities, navigationEntities };
}
