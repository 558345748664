/* eslint-disable @nx/enforce-module-boundaries */
import { getBaseUrl } from "@vinsolutions/core/environment";
import { fetchWrapper } from "@vinsolutions/core/http";
import { createLogger } from "@vinsolutions/logger";
import { WorkflowGatewayConfig } from "./crm.workflow.api";
import { vinessaSummaryErrorResponse } from "./mocks/mock-vinessa-summary-error";
import { VinessaSummaryResponse } from "./models/vinessa-summary.model";

const logger = createLogger(
  "data-access-virtual-assistant-crm.workflow.keyinfo.vinessa"
);

export async function getVinessaSummary(
  jwt: string,
  customerId?: number,
  dealerId?: number
): Promise<VinessaSummaryResponse | null | undefined> {
  if (!customerId) {
    throw new Error("Invalid customerId");
  }
  if (!dealerId) {
    throw new Error("Invalid dealerId");
  }

  const summaryUrl = getVinessaSummaryUrl(customerId, dealerId);
  const response = await fetchWrapper.getAsync<VinessaSummaryResponse>(
    summaryUrl,
    {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/vnd.coxauto.v1+json"
    }
  );

  if (response && response.statusCode === 200) {
    return response.data;
  }

  if (response && response.statusCode === 503) {
    const tryAgainError = response.data?.errors?.find(
      error => error.code === "try_again"
    );
    if (tryAgainError) {
      logger.error("Error getting Vinessa summary", tryAgainError.message);
      return vinessaSummaryErrorResponse;
    }
  }

  return null;
}

export function getVinessaSummaryUrl(customerId?: number, dealerId?: number) {
  const baseUrl = getBaseUrl(WorkflowGatewayConfig.baseUrls);
  return `${baseUrl}keyinfo/vinessa/summary?dealerId=${dealerId}&customerId=${customerId}`;
}
