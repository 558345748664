import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { Alert, Button, SelectInput, SubmitButton, TextArea } from "cx";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { TagManager } from "@vinsolutions/core-third-party-gtm";

/* eslint-disable-next-line */
export interface StatusSelectorProps {
  hasError?: boolean;
  isBusy: boolean;
  isLoading?: boolean;
  isUserClockedIn: boolean;
  onCancelButtonClick: () => void;
  onClockInClick: () => void;
  onSaveClickCallback: (status: string, isBusy: boolean) => void;
  reactGAApplicationName: string;
  savedStatus: string;
}

const statusOptions = [
  "Here",
  "On break",
  "Test drive",
  "Customer meeting",
  "Dealership meeting",
  "On the lot",
  "Out to lunch",
  "Custom"
];

const dropDownOptions = statusOptions.map(x => {
  return { value: x };
});

const StyledStatusSelector = styled.div`
  .status-modal-button-row {
    text-align: right;

    .btn {
      margin: 0 0 0 8px;
    }
  }

  #status-selector-clock-in-button {
    margin: 0;
    padding: 0;
    line-height: 14px;
    vertical-align: baseline;
  }
`;

export function StatusSelector({
  hasError,
  isBusy,
  isLoading,
  isUserClockedIn,
  onCancelButtonClick,
  onClockInClick,
  onSaveClickCallback,
  reactGAApplicationName,
  savedStatus
}: StatusSelectorProps) {
  const [
    { customStatusText, hasCustomStatusTextBox, isBusyChecked, selectedStatus },
    setState
  ] = useState({
    customStatusText:
      statusOptions.indexOf(savedStatus) === -1 ? savedStatus : "",
    hasCustomStatusTextBox:
      savedStatus === "Custom" || statusOptions.indexOf(savedStatus) === -1,
    isBusyChecked: isBusy,
    selectedStatus:
      statusOptions.indexOf(savedStatus) === -1 ? "Custom" : savedStatus
  });

  useEffect(() => {
    setState({
      isBusyChecked: isBusy,
      customStatusText:
        statusOptions.indexOf(savedStatus) === -1 ? savedStatus : "",
      hasCustomStatusTextBox:
        savedStatus === "Custom" || statusOptions.indexOf(savedStatus) === -1,
      selectedStatus:
        statusOptions.indexOf(savedStatus) === -1 ? "Custom" : savedStatus
    });
  }, [isBusy, savedStatus]);

  const onStatusDropdownChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setState(s => {
      return {
        ...s,
        selectedStatus: value || "",
        hasCustomStatusTextBox: value === "Custom"
      };
    });
  };

  const onCustomStatusTextAreaChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setState(s => {
      return { ...s, customStatusText: value };
    });
  };

  const onBusyCheckboxClick = ({
    target: { checked }
  }: CheckboxChangeEvent) => {
    setState(s => {
      return { ...s, isBusyChecked: checked };
    });
  };

  const onSaveClick = () => {
    onSaveClickCallback(
      selectedStatus === "Custom" ? customStatusText : selectedStatus,
      isBusyChecked
    );
  };

  return (
    <StyledStatusSelector id="status-selector">
      {hasError && (
        <Alert htmlId="status-selector-error-alert" type="danger">
          Unable to complete request, please try again later.
        </Alert>
      )}
      {isUserClockedIn === false && (
        <Alert htmlId="status-selector-clock-in-alert" type="warning">
          Please{" "}
          <Button
            buttonStyle="link"
            htmlId="status-selector-clock-in-button"
            onClick={() => {
              TagManager.event({
                eventElement: "TopNav: Status Log In Button",
                eventAction: "Clicked",
                eventValue: "Log In"
              });
              onClockInClick();
            }}
          >
            clock in
          </Button>{" "}
          to change status.
        </Alert>
      )}
      <SelectInput
        htmlId="status-selector-status-select-input"
        label="Current Status"
        name="status-dropdown"
        options={dropDownOptions}
        value={selectedStatus}
        onChange={onStatusDropdownChange}
      />
      {hasCustomStatusTextBox && (
        <TextArea
          displayLabel={false}
          htmlId="status-selector-custom-status-text-input"
          maxLength={200}
          name="status-text-input"
          placeholder="enter status"
          value={customStatusText || ""}
          onChange={onCustomStatusTextAreaChange}
        />
      )}
      <Checkbox
        checked={isBusyChecked}
        id="status-selector-is-busy-checkbox"
        name="busy checkbox"
        onChange={onBusyCheckboxClick}
      >
        Busy
      </Checkbox>
      <div className="status-modal-button-row">
        <Button
          buttonStyle="link"
          className="account-menu-cancel-button"
          disabled={isLoading}
          htmlId="status-selector-cancel-button"
          onClick={() => {
            TagManager.event({
              eventElement: `${reactGAApplicationName}: Cancel Status Button`,
              eventAction: "Clicked",
              eventValue: "Cancel"
            });
            onCancelButtonClick();
          }}
        >
          Cancel
        </Button>
        <SubmitButton
          buttonStyle="primary"
          className="account-menu-link-button"
          disabled={isLoading || isUserClockedIn === false}
          htmlId="status-selector-save-button"
          onClick={() => {
            TagManager.event({
              eventElement: `${reactGAApplicationName}: Save Status Button`,
              eventAction: "Clicked",
              eventValue: isBusyChecked
                ? "Busy"
                : selectedStatus === "Custom"
                ? customStatusText
                : selectedStatus
            });
            onSaveClick();
          }}
        >
          Save
        </SubmitButton>
      </div>
    </StyledStatusSelector>
  );
}

export default StatusSelector;
