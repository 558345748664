import {
  LoadingIndicator as CXLoadingIndicator,
  LoadingIndicatorProps as CXLoadingIndicatorProps
} from "@vinsolutions/core-cx";
import { useEffect, useState } from "react";
import { NewRelic } from "@vinsolutions/core-third-party-newrelic";

export interface LoadingIndicatorProps extends CXLoadingIndicatorProps {
  /** Action name that will be logged to NewRelic. Otherwise htmlId is used */
  action?: string;
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  const [startTime] = useState(new Date());
  useEffect(() => {
    return () => {
      const endTime = new Date();
      const duration = endTime.getTime() - startTime.getTime();
      const actionName = getActionName(props.htmlId, props.action);
      NewRelic.addPageAction("Loading Spinner Finished", {
        Duration: duration,
        Action: actionName
      });
    };
  }, [props.htmlId, props.action, startTime]);
  return (
    <CXLoadingIndicator
      className={props.className}
      color={props.color}
      floatToSide={props.floatToSide}
      htmlId={props.htmlId}
      size={props.size}
    />
  );
}
function getActionName(htmlId: string, actionName: string | undefined): string {
  if (actionName) return actionName;
  if (htmlId.endsWith("loading")) return htmlId;
  return htmlId + "-loading";
}
