/**
 * Defines the shape of a pane object
 */
import { VinconnectFlagNames } from "@vinsolutions/core/third-party/launch-darkly";
import React from "react";

export type RouteComponent = React.ReactNode | string | undefined;

/**
 * Options to apply to RouteComponents
 *
 * @type {RouteComponentOptions}
 * @property {RouteButtonPosition} expansionButtonPosition Specify the position of the optional button  that causes the pane to expand to full width
 * @property {string[]} requiredQueryParams Optionally require a list of query parameter keys for the CarDashboard Component to load. If requiredQueryParams are not found then the page is not displayed
 * @property {RouteComponent} fallbackComponent Optionally specify a RouteComponent to fallback to in requiredQueryParams are not present. Component specified should not require query params or state.
 */
export type RouteComponentOptions = {
  expansionButtonPosition?: RouteButtonPosition;
  requiredQueryParams?: string[];
  fallbackComponent?: RouteComponent;
};
export type RoutePaneType = {
  component: RouteComponent;
  options?: RouteComponentOptions;
};

/**
 * Defines the panes that a route can have
 */
export type RoutePanesType = {
  left: RoutePaneType | undefined;
  right: RoutePaneType | undefined;
  full: RoutePaneType | undefined;
};

/**
 * Providing this as a quereParam when calling renderRoute will cause a hovering back button to be displayed
 *
 * @interface {RouteBackButton}
 * @property {string} title Text that will displayed on the back button
 * @property {string} productName The productName to route to
 * @property {string} viewName The viewName to route to
 * @property {RouteButtonPosition} buttonPosition Where the buttom should be displayed
 */
export interface RouteBackButton {
  title: string;
  productName: string;
  viewName: string;
  buttonPosition: RouteButtonPosition;
}

export type FeatureFlagType = VinconnectFlagNames | null | undefined;

/**
 * Defines a route object
 * A route can have a left and right pane and a cdPath defined
 * The left and right pane define the react component
 * The cdPath defines the CD Page that can display
 * The placement of the CD page depends on the leftframe and rightframe parameters of the cdPath
 */
export type RouteEntityType = {
  routeId: string;
  aliasId: string | undefined;
  featureFlag: FeatureFlagType;
  panes: RoutePanesType;
};

/**
 * The route configuration type
 * The key is of type RouteIdType
 */
export type RouteConfigType = {
  [key: string]: RouteEntityType;
};

export enum RoutePanePlacement {
  RIGHT = "right",
  LEFT = "left",
  BOTH = "both",
  FULL = "full",
  UNDEFINED = "undefined"
}

/**
 * Enum describing the position of various optional button that can be displayed when rendering RouteComponents
 *
 */
export enum RouteButtonPosition {
  TOP_LEFT = "top-left",
  TOP_RIGHT = "top-right",
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_RIGHT = "bottom-right"
}

export type RouteList = RouteEntityType[];
