import { KeyInformationBannerLoad } from "@vinsolutions/ui/key-information-banner";
import styled from "styled-components";
import { KeyInformationProps } from "./interfaces/key-information-props";
import { useCallback, useReducer } from "react";
import { AccordionGroup } from "@vinsolutions/core-cx";
import BannerCollection from "./components/banner-collection";
import KeyInformationReducer, {
  KeyInformationBannerActionTypes,
  initialKeyInformationState
} from "./state/key-information-state";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import KeyInformationAccordionHeader from "./components/accordion-header";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";

/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
/* @ts-ignore */
const StyledFeatureKeyInformationAccordion = styled(AccordionGroup.Container)<{
  $display?: string;
}>`
  display: ${props => props.$display};
  .cx-accordion-collapse .cx-accordion-body {
    padding: 0em;
    margin: 0em;
  }
`;

/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
/* @ts-ignore */
const StyledKeyInformationAccordionItem = styled(AccordionGroup.Item)`
  .cx-accordion
    .cx-accordion-title
    > a[aria-expanded="true"]
    svg.keyInfo-small-icon {
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
  }
`;

export function FeatureKeyInformation(
  keyInformationProps: KeyInformationProps
) {
  const [keyInformationState, keyInformationDispatch] = useReducer(
    KeyInformationReducer,
    initialKeyInformationState
  );
  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "FeatureKeyInformation",
    "Loading Key Information"
  );
  setInteractionAttributes({
    customerId: keyInformationProps.customerId || 0,
    dealerId: keyInformationProps.dealerId,
    selectedLeadId: keyInformationProps.selectedLeadId || 0
  });

  const onBannerLoaded = useCallback((banner: KeyInformationBannerLoad) => {
    keyInformationDispatch({
      type: KeyInformationBannerActionTypes.bannerLoaded,
      value: banner
    });
  }, []);

  const onBannerInitialized = useCallback((initializedBannerCount: number) => {
    keyInformationDispatch({
      type: KeyInformationBannerActionTypes.bannerInitialized,
      initializedBannerCount
    });
  }, []);

  const onToggle = useCallback(() => {
    if (!keyInformationState.accordionExpanded) {
      TagManager.event({
        event: "userEvent",
        eventElement: "Accordian",
        eventAction: "Expanded",
        eventResult: "Expanded key information to view banners",
        eventLocation: `${
          keyInformationProps?.gtmEventLocationParent
            ? keyInformationProps?.gtmEventLocationParent + " > "
            : ""
        }Key Information`
      });
    }
    keyInformationDispatch({
      type: KeyInformationBannerActionTypes.toggleAccordion
    });
  }, [keyInformationState.accordionExpanded, keyInformationProps]);

  const onBannerClick = useCallback(
    (bannerName: string, eventElement = "Modal") => {
      TagManager.event({
        event: "userEvent",
        eventElement,
        eventAction: `${bannerName} banner clicked`,
        eventResult: `Displayed ${bannerName} ${eventElement}`,
        eventLocation: `${
          keyInformationProps.gtmEventLocationParent
            ? keyInformationProps.gtmEventLocationParent + " > "
            : ""
        }Key Information > ${bannerName}`
      });
    },
    [keyInformationProps.gtmEventLocationParent]
  );

  return (
    <StyledFeatureKeyInformationAccordion
      independent
      $display={keyInformationState.display}
      htmlId="keyInfo-AccordionGroup"
    >
      <StyledKeyInformationAccordionItem
        defaultExpanded={true}
        eventKey="1"
        header={
          <KeyInformationAccordionHeader
            intializedBanners={keyInformationState.initializedBanners}
            loadedBanners={keyInformationState.loadedBanners}
            onHeaderLoadComplete={endInteraction}
          />
        }
        htmlId="keyInfo-Accordion"
        onToggle={onToggle}
      >
        <BannerCollection
          {...keyInformationProps}
          onClickGtmEvent={onBannerClick}
          onInitialize={onBannerInitialized}
          onLoad={onBannerLoaded}
        />
      </StyledKeyInformationAccordionItem>
    </StyledFeatureKeyInformationAccordion>
  );
}

export default FeatureKeyInformation;
