import { DealerDashboardPage } from "@vinsolutions/page/dealer-dashboard";
import { RouteBuilder } from "../route-builder";
import { RouteButtonPosition } from "../route-config-types";

export const dealerDashboardWithUnansweredEmailRepliesRoute =
  RouteBuilder.create()
    .withRouteId("vinconnect", "dealer-dashboard-unanswered-email-replies")
    .withAliasId(
      "navigation-sub-menu-tab-crm-links-6---monitor-unanswered-email-replies"
    )
    .withLeftPaneComponent("EmailRepliesNotRead.aspx")
    .withRightPaneComponent(<DealerDashboardPage />, {
      expansionButtonPosition: RouteButtonPosition.BOTTOM_LEFT
    })
    .build();
