import {
  PortalEventMessage,
  PortalIdMapping
} from "@vinsolutions/utility/portals";
import VinConnectPortalEventChannels from "./vinconnect-portal-event-channels";

const RegisterVinconnectReactPortals = (
  originDocument: Document,
  originFrameUrl: URL,
  registerPortals: PortalIdMapping[],
  data?: unknown
) => {
  const portalEventMessage: PortalEventMessage = {
    originDocument,
    originFrameUrl,
    registerPortals,
    data
  };
  const vinconnectPortalEvent = new CustomEvent(
    VinConnectPortalEventChannels.createPortals,
    {
      detail: portalEventMessage
    }
  );
  window.top?.dispatchEvent(vinconnectPortalEvent);
};

export default RegisterVinconnectReactPortals;
