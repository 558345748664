import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DMCDetailEnableOemLeadStatusUpdateParams } from "../../../../types/dmcParams.type";
import { DMCCheckbox } from "../../../dmc-checkbox/dmc-checkbox.component";

export const DMCDetailEnableOemLeadStatusUpdate = ({
  enableOemLeadStatusUpdate,
  handleInputChange,
  name
}: DMCDetailEnableOemLeadStatusUpdateParams) => {
  const onChange = (event: CheckboxChangeEvent) => {
    const { checked } = event.target;

    handleInputChange({ target: { value: checked, name } });
  };

  return (
    <DMCCheckbox
      data-testid="DMCEnableOemLeadStatusUpdate"
      id="DMCEnableOemLeadStatusUpdate"
      label={" Enable OEM Lead Status Update"}
      note={
        "By checking this checkbox, the system will send lead updates to the manufacturer. (i.e. contact information, address, vehicle of interest, etc) Not all manufacturer's have integrated with this piece."
      }
      value={enableOemLeadStatusUpdate}
      {...{ name, onChange }}
    />
  );
};
