export const vinconnectDark = {
  actionItem: "#fff",
  activeTabColor: "#fff",
  background: "#173a78",
  buttonHover: "#173a78",
  divider: "#fff",
  abovePrimary: "#fff",
  aboveSecondary: "#fff",
  aboveSurface: "#f5f6f7",
  primary: "#173a78",
  secondary: "#2053ab",
  secondaryActionItem: "#fff",
  secondaryIcon: "#fff",
  secondaryLabel: "#fff",
  surface: "#173a78"
};
