import { getCardashboardState } from "@vinsolutions/legacy-cardashboard/store";
import { shallowEqual, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("cardashboard-helper");

declare global {
  interface Window {
    postMessageToCarDashboard: (message: any) => boolean;
  }
}
export const cleanCDInitialized = () => {
  // Remove cdInitialized=true from the end if it exists. Need to get rid of the code in CD that assigns this query parameter.
  logger.debug("cleanCDInitialized");
  const urlParams = new URLSearchParams(window.location.search);
  const cdIsInitQueryParam = urlParams.get("cdInitialized");

  if (cdIsInitQueryParam) {
    logger.debug("cleanCDInitialized: removing cdInitialized query param");
    const url = window.location.hash
      ? `/vinconnect/${window.location.hash}`
      : "/vinconnect/#/CarDashboard";
    window.history.replaceState({}, "", url);
  }

  if (window.location.pathname?.toLowerCase() === "/vinconnect") {
    logger.debug("cleanCDInitialized: adding /#/CarDashboard to url");
    window.history.replaceState({}, "", "/vinconnect/#/CarDashboard");
  }
};

export function useUpdateCarDashboardPath() {
  logger.debug("useUpdateCarDashboardPath");
  const { frameBaseUrl, frameUrl } = useSelector(
    getCardashboardState,
    shallowEqual
  );
  const { pathname } = useLocation();

  useEffect(() => {
    const framePath = frameUrl.replace(frameBaseUrl, "");
    const urlArray = window.location.href.split("#");
    if (pathname === "/" && urlArray[1] !== framePath) {
      const newURL = urlArray[0].endsWith("/")
        ? `${urlArray[0]}#${framePath}`
        : `${urlArray[0]}/#${framePath}`;
      logger.debug(`useUpdateCarDashboardPath: ${newURL}`);
      window.history.pushState(null, "", newURL);
    }
  }, [frameBaseUrl, frameUrl, pathname]);
}
