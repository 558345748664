import { envEnum, getEnvironment } from "@vinsolutions/core/environment";

/**
 * Determines the base URL based on the current environment.
 * @returns The URL for the module federation manifest.
 */
export function getModuleUrl(): string {
  const environment = getEnvironment(window.location.hostname);
  const baseURL =
    environment === envEnum.PRODUCTION
      ? "https://pipelines.app.coxautoinc.com"
      : "https://np-pipelines.app.coxautoinc.com"; // For local dev experience use: "http://localhost:4200"

  return `${baseURL}/module-federation.manifest.json`;
}
