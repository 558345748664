// this file could be moved to a util lib if we feel like it. It also needs tests

import { getEnvironmentName } from "@vinsolutions/core/config";
import { environments } from "@vinsolutions/core/config";
import {
  GoogleAnalyticsDataLayer,
  tagManagerArgs
} from "@vinsolutions/core-interfaces-third-party-gtm";
import { userRoles } from "@vinsolutions/ccrm/interfaces";

export const getGTMNotReadyConfig = () => {
  const env = getEnvironmentName();
  const isProduction =
    env === environments.prodCoxAutoDomain || env === environments.prod;

  const retval: tagManagerArgs = {
    dataLayer: {
      dataLayerReady: false,
      common: {
        dataLayerVersion: 2,
        application: {
          businessUnitName: "VinSolutions",
          name: "VinConnect",
          version: "1.0",
          environment: env,
          isProduction
        }
      }
    },
    dataLayerName: "dataLayer",
    gtmId: isProduction ? "GTM-WJQR8G" : "GTM-N8XNBR9"
  };
  return retval;
};

// https://coxautoinc.sharepoint.com/sites/department-user-experience/SitePages/Common%20Data%20Layer%20Variables%20schema%20for%20Google%20Tag%20Manager.aspx#handling-delayed-values-(spas%2C-etc)
export const getGTMReadyConfig = (
  isVinEmployee: boolean,
  loggedInUserId: number,
  dealerId: string,
  dealerName: string,
  userRole: userRoles,
  bridgeUser: string,
  platformId: string
) => {
  const retval: GoogleAnalyticsDataLayer = {
    dataLayerReady: true,
    event: "dataLayerReady",
    userRole,
    common: {
      user: {
        bridgeUsername: bridgeUser,
        bridgePlatformId: platformId,
        solutionPrincipalId: loggedInUserId,
        userType: isVinEmployee ? "internal" : "dealer",
        isInternalUser: isVinEmployee
      },

      context: { dealershipId: dealerId, dealershipName: dealerName }
    }
  };
  return retval;
};
