import { TextInput } from "cx";
import { DMC_TYPES } from "../../../../constants/dmcTypes.constant";
import { DMCDealerCodeParams } from "../../../../types/dmcParams.type";

export const DMCDetailDealerCode = ({
  dealerCode,
  handleInputChange,
  oemId
}: DMCDealerCodeParams) => {
  const handleCustomError = (_: unknown, value: string) => {
    if (oemId) {
      const { regexDealerCodeValidation, codeValidationErrorMsg } =
        DMC_TYPES[oemId];

      if (!regexDealerCodeValidation.test(value)) {
        return codeValidationErrorMsg;
      }
    }

    return "";
  };

  return (
    <TextInput
      autoFocus
      required
      data-testid="DealerCode"
      getError={handleCustomError}
      htmlId="DMCDealerCode"
      label="Dealer code"
      maxLength={50}
      name="DMCDealerCode"
      value={dealerCode}
      onChange={handleInputChange}
    />
  );
};
