import styled from "styled-components";
import GmInsightsSummaryData from "../../interfaces/gm/gm-insights-summary-data";
import GmNoModalDetailsMessage from "./gm-no-modal-details-message";
import GmLeadScore from "./gm-lead-score";

export interface GmInsightsSummaryProps {
  gmInsights: GmInsightsSummaryData;
}

const StyledGmInsightsSummary = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
`;

export const GmInsightsSummary = ({
  gmInsights: { oemLeadId, leadScore }
}: GmInsightsSummaryProps) => {
  return (
    <StyledGmInsightsSummary>
      <GmLeadScore leadScore={leadScore} oemLeadId={oemLeadId} />
      <GmNoModalDetailsMessage />
    </StyledGmInsightsSummary>
  );
};

export default GmInsightsSummary;
