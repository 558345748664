import React, { useState } from "react";
import { Button, ModalDialog } from "cx";
import { AboutConnectModal } from "../modal/about-connect-modal";
import { shallowEqual, useSelector } from "react-redux";
import { getMetaDataState } from "@vinsolutions/ccrm/store";
import { SetupStatus } from "@vinsolutions/ccrm/interfaces";
export interface AboutConnectButtonProps {
  cardashboardServerName: string;
  dealerId: number | null;
  dealerName: string;
  dealerStatus: SetupStatus;
  userFullName: string;
  userName: string;
  userRole: string;
  bridgeUsername: string | null;
}

export function AboutConnectButton({
  cardashboardServerName,
  dealerId,
  dealerName,
  dealerStatus,
  userFullName,
  userName,
  userRole,
  bridgeUsername
}: AboutConnectButtonProps) {
  const [show, setShow] = useState(false);
  const { currentAppVersion } = useSelector(getMetaDataState, shallowEqual);

  const onHide = () => {
    setShow(b => !b);
  };

  return (
    <>
      <Button
        buttonStyle="link"
        className="account-menu-link-button"
        htmlId="account-menu-about-connect-link"
        onClick={() => {
          setShow(true);
        }}
      >
        About Connect
      </Button>

      <ModalDialog
        displayCloseButton={true}
        header={<h2>About Connect</h2>}
        htmlId="account-menu-about-connect-modal"
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <AboutConnectModal
          {...{
            bridgeUsername,
            cardashboardServerName,
            currentAppVersion,
            dealerId,
            dealerName,
            dealerStatus,
            userFullName,
            userName,
            userRole,
            onHide
          }}
        />
      </ModalDialog>
    </>
  );
}

export default AboutConnectButton;
