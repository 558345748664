import styled from "styled-components";
import { AboutConnectResultListItem } from "./about-connect-helper";

const StyledAboutConnectItem = styled.div``;
const StyledAboutConnectItemTitle = styled.span`
  font-weight: 800;
  font-size: 14pt;
  color: #16171a;
`;

const StyledAboutConnectItemValue = styled.span`
  font-weight: 700;
  font-size: 10.5pt;
  float: right;
  color: #6d727a;
`;

export function AboutConnectItem(props: AboutConnectResultListItem) {
  if (props.hideOnModal === true) return <></>;
  const testId = `about-connect-item-${props.title
    .replace(/ /g, "-")
    .toLowerCase()}`;
  return (
    <StyledAboutConnectItem>
      <StyledAboutConnectItemTitle
        data-testid={testId + "-label"}
        id={testId + "-label"}
      >
        {props.title}
      </StyledAboutConnectItemTitle>
      <StyledAboutConnectItemValue
        data-iso={props.isoDate}
        data-testid={testId}
        id={testId}
      >
        {props.value}
      </StyledAboutConnectItemValue>
    </StyledAboutConnectItem>
  );
}

export default AboutConnectItem;
