import { useEffect, useState } from "react";
import styled from "styled-components";
import { CARDASHBOARD_RIMS_BASEPATH } from "../constants/rims-frame-url";
import { getDealDetailsIframeUrl } from "./deal-details-modal-content";

export interface BuyingSignalsModelContentProps {
  customerId: number;
  leadId: number;
  dealerId: number;
  dealExchangeId: string | null;
}

const BUYING_SIGNALS_SETTING_NAME = "Vin.Edison.BuyingSignals.keyinfomodal.url";

const getBuyingSignalsIframeUrl = (
  customerId: number,
  leadId: number,
  dealerId: number,
  dealExchangeId: string | null
) => {
  const onlineActivityOnlyParameter = dealExchangeId
    ? ""
    : "onlineActivityOnly=true&";
  const dealDetailsFrameUrl = getDealDetailsIframeUrl(
    customerId,
    leadId,
    dealerId,
    dealExchangeId
  );
  const encodedDealDetailsUrl = dealDetailsFrameUrl
    ? encodeURIComponent(dealDetailsFrameUrl)
    : "";
  return `${CARDASHBOARD_RIMS_BASEPATH}?${onlineActivityOnlyParameter}urlSettingName=${BUYING_SIGNALS_SETTING_NAME}&customerId=${customerId}&dealerId=${dealerId}&HideCloseButton=true&accelerateurl=${encodedDealDetailsUrl}`;
};

const StyledBuyingSignalsModalContent = styled.iframe`
  width: 100%;
  height: 1050px;
  border: none;
`;

const BuyingSignalsModelContent = ({
  customerId,
  dealerId,
  dealExchangeId,
  leadId
}: BuyingSignalsModelContentProps) => {
  const [iframeUrl, setIFrameUrl] = useState("");

  useEffect(() => {
    const buyingSignalsFrameUrl = getBuyingSignalsIframeUrl(
      customerId,
      leadId,
      dealerId,
      dealExchangeId
    );
    setIFrameUrl(buyingSignalsFrameUrl);
  }, [customerId, dealerId, dealExchangeId, leadId]);

  return (
    iframeUrl && (
      <StyledBuyingSignalsModalContent
        src={iframeUrl}
        title="Accelerate-Modal"
      />
    )
  );
};

export default BuyingSignalsModelContent;
