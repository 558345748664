import React, { useState } from "react";
import { FallbackProps } from "./ErrorBoundary";
import * as Helper from "./FailureStateHelper";

export function FailureState({
  error,
  homepageUrl,
  isConsoleErrorEnabled = true
}: FallbackProps) {
  const [showError, setShowError] = useState(false);

  const onHomeClick = () => {
    if (homepageUrl) {
      window.location.href = homepageUrl;
    } else {
      window.location.hash = "";
      window.location.reload();
    }
  };

  return (
    <Helper.StyledFailureState>
      <Helper.StyledFailureStateContent>
        <Helper.StyledHeader>
          <Helper.GrownFlex>
            <Helper.WarningIcon />
          </Helper.GrownFlex>
          <Helper.StyledHeaderText>Application Error</Helper.StyledHeaderText>
          <Helper.GrownFlex>
            <Helper.WarningIcon />
          </Helper.GrownFlex>
        </Helper.StyledHeader>
        <Helper.StyledSubHeader>
          The application has encountered an error and isn't currently
          available. To see details of the error, select Error Details or try
          refreshing the page. If the error persists, select Connect Home to
          return to the Connect CRM. <br />
          Your error message is:
          <Helper.StyledErrorMessage>
            {Helper.getErrorMessage(error)}
          </Helper.StyledErrorMessage>
        </Helper.StyledSubHeader>
        <Helper.CenteredFlex>
          {isConsoleErrorEnabled && !showError && (
            <Helper.GrownFlex>
              <Helper.StyledShowErrorButton
                buttonStyle="link"
                htmlId="show-error"
                onClick={() => {
                  setShowError(true);
                }}
              >
                Error Details
              </Helper.StyledShowErrorButton>
            </Helper.GrownFlex>
          )}
          <Helper.GrownFlex>
            <Helper.StyledReturnButton
              buttonStyle="primary"
              htmlId="return"
              onClick={onHomeClick}
            >
              Connect Home
            </Helper.StyledReturnButton>
          </Helper.GrownFlex>
        </Helper.CenteredFlex>
        {showError && (
          <Helper.StyledErrorMessage>
            {Helper.getStackTraceMessage(error)}
          </Helper.StyledErrorMessage>
        )}
      </Helper.StyledFailureStateContent>
    </Helper.StyledFailureState>
  );
}

export default FailureState;
