import { useCallback, useEffect, useState } from "react";
import AutomotiveIntelligenceKeyInformationProps from "./interfaces/automotive-intelligence-key-information-props";
import {
  AutomotiveIntelligenceSettings,
  getAutomotiveIntelligenceSettings
} from "@vinsolutions/data-access/workflow/crm.workflow.keyinfo.automotiveintelligence";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  CustomerEnrichmentBanner,
  CustomerEnrichmentBaseDisplayedBanner
} from "./customer-enrichment-banner";
import {
  BuyingSignalsBanner,
  BuyingSignalsBaseDisplayedBanner
} from "./buying-signals-banner";
import { shallowEqual, useSelector } from "react-redux";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getProfileState } from "@vinsolutions/ccrm/store";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";

export function AutomotiveIntelligenceBanners({
  customerId,
  dealerId,
  onClickGtmEvent,
  onInitialize,
  onLoad
}: AutomotiveIntelligenceKeyInformationProps) {
  // State
  const [automotiveIntelligenceSettings, setAutomotiveIntelligenceSettings] =
    useState<AutomotiveIntelligenceSettings | null | undefined>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { viewingAsId, userId } = useSelector(getProfileState, shallowEqual);
  const jwt = useReactOidc().oidcUser.access_token;

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "AutomotiveIntelligenceBanners",
    "Getting Automotive Intelligence Settings"
  );
  setInteractionAttributes({
    customerId: customerId ?? 0,
    dealerId: dealerId ?? 0
  });

  // Data Access
  const loadCustomerEnrichmentData = useCallback(async () => {
    if (!isLoaded) {
      const automotiveIntelligenceSettings =
        await getAutomotiveIntelligenceSettings(
          jwt,
          dealerId,
          viewingAsId || userId
        );
      setAutomotiveIntelligenceSettings(automotiveIntelligenceSettings);
      setIsLoaded(true);
    }
  }, [userId, dealerId, jwt, viewingAsId, isLoaded]);

  useEffect(() => {
    onInitialize?.(2);
    loadCustomerEnrichmentData();
  }, [loadCustomerEnrichmentData, onInitialize]);

  useEffect(() => {
    if (
      isLoaded &&
      !automotiveIntelligenceSettings?.isAutomotiveIntelligenceEnabled
    ) {
      const isDisplayed = false;
      onLoad({
        ...BuyingSignalsBaseDisplayedBanner,
        customerId,
        isDisplayed
      });
      onLoad({
        ...CustomerEnrichmentBaseDisplayedBanner,
        customerId,
        isDisplayed
      });
      setInteractionAttributes({ isDisplayed });
    }
    endInteraction();
  }, [
    isLoaded,
    automotiveIntelligenceSettings,
    customerId,
    onLoad,
    setInteractionAttributes,
    endInteraction
  ]);

  return (
    automotiveIntelligenceSettings &&
    automotiveIntelligenceSettings?.isAutomotiveIntelligenceEnabled && (
      <>
        <CustomerEnrichmentBanner
          automotiveIntelligenceSettings={automotiveIntelligenceSettings}
          customerId={customerId}
          dealerId={dealerId}
          onClickGtmEvent={onClickGtmEvent}
          onLoad={onLoad}
        />
        <BuyingSignalsBanner
          customerId={customerId}
          dealerId={dealerId ?? 0}
          onClickGtmEvent={onClickGtmEvent}
          onLoad={onLoad}
        />
      </>
    )
  );
}
