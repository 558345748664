import { Button } from "cx";
import {
  crmNavigationItemEntityIds,
  deriveCardashboardBaseUrl
} from "@vinsolutions/ccrm/util";
import { TagManager } from "@vinsolutions/core-third-party-gtm";

/* eslint-disable-next-line */
export interface NavigationCarDashboardJavascriptButtonProps {
  id: string;
  label: string;
  url: string;
}

export function NavigationCarDashboardJavascriptButton({
  id,
  label,
  url
}: NavigationCarDashboardJavascriptButtonProps) {
  const processOpenSmarterMail = (url: string) => {
    const carDashboardBaseUrl = deriveCardashboardBaseUrl();
    const destination = url
      /* eslint-disable-next-line no-script-url */
      .replace("javascript:OpenSmarterMailWindow('", carDashboardBaseUrl)
      .replace("');", "");

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line new-cap
    window.top.OpenSmarterMailWindow(destination);
  };

  const processOpenWindow = (url: string) => {
    const carDashboardBaseUrl = deriveCardashboardBaseUrl();
    const windowPath = url
      /* eslint-disable-next-line no-script-url */
      .replace("javascript:OpenWindow('", carDashboardBaseUrl)
      .replace("');", "");
    if (windowPath.includes("','")) {
      const slicePoint = windowPath.indexOf("','");
      window.open(windowPath.slice(0, slicePoint), "_blank");
    } else {
      window.open(windowPath, "_blank");
    }
  };

  const onClick = () => {
    if (
      id &&
      id !== crmNavigationItemEntityIds.recentViewedCustomers &&
      id.startsWith(crmNavigationItemEntityIds.recentViewedCustomers)
    ) {
      TagManager.event({
        eventElement: `TopNav: Global Navigation`,
        eventAction: `Recent customer clicked`,
        eventValue: `CRM > Recent Viewed Customer > X Customer`
      });
    } else {
      TagManager.event({
        eventElement: `TopNav: Global Navigation`,
        eventAction: `${label} clicked`,
        eventValue: `${id}`
      });
    }

    if (url.includes("OpenWindow(")) {
      processOpenWindow(url);
    } else if (url.includes("OpenSmarterMailWindow(")) {
      processOpenSmarterMail(url);
    }
  };

  return (
    <Button buttonStyle={"link"} htmlId={id} {...{ onClick }}>
      {label}
    </Button>
  );
}

export default NavigationCarDashboardJavascriptButton;
