import {
  BaseUrlsObj,
  envEnum,
  getBaseUrl
} from "@vinsolutions/core/environment";
import { fetchWrapper } from "@vinsolutions/core/http";
import { createLogger } from "@vinsolutions/logger";
import { InsightsSummaryResponseItem } from "./models/InsightsSummaryResponse";
import { AutomotiveIntelligenceSettings } from "./models/automotive-intelligence-settings";

const logger = createLogger(
  "data-access-workflow-crm.workflow.keyinfo.automotiveintelligence"
);

type Config = {
  baseUrls: BaseUrlsObj;
};

export const config: Config = {
  baseUrls: {
    [envEnum.LOCAL]:
      "https://workflow-dev.np.vinsolutions.com/api/keyinfo/automotive-intelligence/",
    [envEnum.DEV]:
      "https://workflow-dev.np.vinsolutions.com/api/keyinfo/automotive-intelligence/",
    [envEnum.QA]:
      "https://workflow-qa.np.vinsolutions.com/api/keyinfo/automotive-intelligence/",
    [envEnum.STAGING]:
      "https://workflow-staging.np.vinsolutions.com/api/keyinfo/automotive-intelligence/",
    [envEnum.PRODUCTION]:
      "https://workflow.vinsolutions.com/api/keyinfo/automotive-intelligence/"
  }
};

export const getBuyingSignalsSummary = async (
  dealerId: number,
  customerId: number,
  jwt: string
): Promise<InsightsSummaryResponseItem | null | undefined> => {
  const buyingSignalsSummaryUrl = getBuyingSignalsSummaryUrl(
    dealerId,
    customerId
  );
  const response = await fetchWrapper.getAsync<InsightsSummaryResponseItem>(
    buyingSignalsSummaryUrl,
    {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/vnd.coxauto.v1+json"
    }
  );

  switch (response.statusCode) {
    case 200:
    case 404: // not found indicates ai is enabled, but there are no insights
      return response.data;
    default: {
      if (response.statusCode === 403) {
        logger.debug(
          "Automotive intelligence is not enabled.",
          JSON.stringify(response)
        );
      } else {
        logger.error(
          "Error getting buying signals insights summary.",
          JSON.stringify(response)
        );
      }
      return null;
    }
  }
};

export function getBuyingSignalsSummaryUrl(
  dealerId: number,
  customerId: number
) {
  const baseUrl = getBaseUrl(config.baseUrls);
  return `${baseUrl}buyingsignals/summary?dealerId=${dealerId}&customerId=${customerId}`;
}

export const getAutomotiveIntelligenceSettings = async (
  jwt: string,
  dealerId?: number,
  userId?: number | null
): Promise<AutomotiveIntelligenceSettings | null | undefined> => {
  const automotiveIntelligenceSettingsUrl =
    getAutomotiveIntelligenceSettingsUrl(dealerId, userId);
  const response = await fetchWrapper.getAsync<AutomotiveIntelligenceSettings>(
    automotiveIntelligenceSettingsUrl,
    {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/vnd.coxauto.v1+json",
      "Cache-Control": "max-age=18000"
    }
  );

  switch (response.statusCode) {
    case 200:
      return response.data;
    default: {
      logger.error(
        "Error getting customer enrichment summary.",
        JSON.stringify(response)
      );
      return null;
    }
  }
};

export function getAutomotiveIntelligenceSettingsUrl(
  dealerId?: number,
  userId?: number | null
) {
  const baseUrl = getBaseUrl(config.baseUrls);
  return `${baseUrl}settings/dealer/${dealerId}?userId=${userId}`;
}
