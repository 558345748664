import { shallowEqual, useSelector } from "react-redux";
import {
  getThemeState,
  selectAllNavigation,
  selectNavigationItemsEntities
} from "@vinsolutions/ccrm/store";
import { NavigationTabButton } from "../navigation-buttons";
import { Menu } from "antd";
import { StyledNavigationTabs } from "./styled-navigation-tabs";
import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";
import { Dictionary } from "@reduxjs/toolkit";

/* eslint-disable-next-line */
export interface NavigationTabsProps {}

const buildItems = (
  navigationArray: NavigationEntity[],
  navigationItemDictionary: Dictionary<NavigationItemsEntity>
) => {
  return navigationArray.map(item => {
    const { label, target, url } = navigationItemDictionary[item.id] || {
      label: "",
      target: "",
      url: ""
    };

    return {
      key: item.id,
      label: (
        <NavigationTabButton
          id={item.id}
          label={label}
          target={target}
          url={url}
        />
      )
    };
  });
};
export function NavigationTabs(props: NavigationTabsProps) {
  // TODO: Add overflow functionality.
  const navigationItemDictionary = useSelector(
    selectNavigationItemsEntities,
    shallowEqual
  );
  const navigationArray = useSelector(selectAllNavigation, shallowEqual);
  const { abovePrimary } = useSelector(getThemeState, shallowEqual);

  return (
    <StyledNavigationTabs {...{ abovePrimary }}>
      <Menu
        items={buildItems(navigationArray, navigationItemDictionary)}
        mode="horizontal"
      />
    </StyledNavigationTabs>
  );
}

export default NavigationTabs;
