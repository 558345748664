import styled from "styled-components";
import { InsightsSummaryResponseItem } from "@vinsolutions/data-access/workflow/crm.workflow.keyinfo.automotiveintelligence";

/* eslint-disable-next-line */
export interface BuyingSignalsSummarySummaryProps {
  insightsSummary: InsightsSummaryResponseItem;
}

const StyledBuyingInsightsBannerSummary = styled.span``;

export function BuyingSignalsSummary({
  insightsSummary: { summary }
}: BuyingSignalsSummarySummaryProps) {
  return (
    <StyledBuyingInsightsBannerSummary>
      {summary}
    </StyledBuyingInsightsBannerSummary>
  );
}

export default BuyingSignalsSummary;
