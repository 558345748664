import { IVinManagerInventoryMaster } from "./vinmanager-inventory-master";
import { communicationsNavigationItemEntityIds } from "./navigation-ids";
import { generateDestinationUrl } from "./vinmanager-inventory-master";
import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";

const getAllTextNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 1);
  startDate.setHours(5, 0, 0, 0);

  const textNavigationItemsEntities: NavigationItemsEntity[] = [
    {
      id: communicationsNavigationItemEntityIds.textInbound,
      label: "Inbound",
      url: generateDestinationUrl(
        vinManagerInventoryMaster.IsRimsEnabledForTextMessagingWfe
          ? {
              leftPage: "/CarDashboard/Pages/rims2.aspx",
              leftQueryString: {
                urlSettingName: "brawndo.communicationsui.url",
                type: "text",
                direction: "Inbound",
                mp:
                  vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging ??
                  false
              }
            }
          : {
              leftPage:
                "/CarDashboard/VinAnalytics/Dashboards/DashboardCommunicationLog.aspx",
              leftQueryString: {
                direction: "Inbound",
                channel: "Text",
                knownCustomer: "B",
                communicationType: "Sales,Service,Unknown",
                dateRange: "15",
                start: vinManagerInventoryMaster.formatDate(startDate),
                end: vinManagerInventoryMaster.formatDate(new Date())
              }
            },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    },
    {
      id: communicationsNavigationItemEntityIds.textOutbound,
      label: "Outbound",
      url: generateDestinationUrl(
        vinManagerInventoryMaster.IsRimsEnabledForTextMessagingWfe
          ? {
              leftPage: "/CarDashboard/Pages/rims2.aspx",
              leftQueryString: {
                urlSettingName: "brawndo.communicationsui.url",
                type: "text",
                direction: "Outbound",
                mp:
                  vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging ??
                  false
              }
            }
          : {
              leftPage:
                "/CarDashboard/VinAnalytics/Dashboards/DashboardCommunicationLog.aspx",
              leftQueryString: {
                direction: "Outbound",
                channel: "Text",
                knownCustomer: "B",
                communicationType: "Sales,Service,Unknown",
                dateRange: "15",
                start: vinManagerInventoryMaster.formatDate(startDate),
                end: vinManagerInventoryMaster.formatDate(new Date())
              }
            },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    }
  ];

  return textNavigationItemsEntities;
};

const filterTextNavigationItems = (
  textNavigationItems: NavigationItemsEntity[],
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  let validItems = [] as NavigationItemsEntity[];

  validItems = validItems.concat(textNavigationItems);

  // hide only for salesperson if texting is not enabled
  if (
    !vinManagerInventoryMaster.SecurityPrincipalILMUserIsManagerOrAdmin &&
    !vinManagerInventoryMaster.IsRimsEnabledForTextMessagingWfe
  ) {
    const textKeys = ["Inbound", "Outbound"];
    validItems = validItems.filter(item => !textKeys.includes(item.label));
  }

  return validItems;
};

export const buildTextNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  const allTextNavigationItems = getAllTextNavigationItems(
    vinManagerInventoryMaster
  );

  const filteredTextNavigationItems = filterTextNavigationItems(
    allTextNavigationItems,
    vinManagerInventoryMaster
  );

  const textNavigationEntity: NavigationEntity | null =
    filteredTextNavigationItems
      ? {
          id: communicationsNavigationItemEntityIds.text,
          items: filteredTextNavigationItems.map(item => {
            return {
              id: item.id
            };
          })
        }
      : null;

  return {
    textNavigationItemEntities: filteredTextNavigationItems,
    textNavigationEntity
  };
};
