import styled from "styled-components";

export interface VinessaSummaryProps {
  textBlurb: string;
}
const StyledVinessaSummary = styled.span``;

export const VinessaSummary = ({ textBlurb }: VinessaSummaryProps) => {
  return (
    <StyledVinessaSummary dangerouslySetInnerHTML={{ __html: textBlurb }} />
  );
};

export default VinessaSummary;
