import {
  RouteBackButton,
  RouteComponent,
  RoutePanePlacement
} from "@vinsolutions/ccrm/top-nav/route-config";
import { RouteComponentView } from "./";
import { useLocation } from "react-router-dom";
import { buildCarDashboardUrl } from "./build-cardashboard-url";
import { useRouteManager } from "./use-route-manager";
import { createLogger } from "@vinsolutions/logger";
import { ReactComponentPane } from "./react-component-pane";
import { CarDashboardComponentPane } from "./cd-component-pane";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getMainNavigationState,
  mainNavigationActions
} from "@vinsolutions/ccrm/store";

const logger = createLogger("render-view");

export interface RenderViewProps {
  routeId: string;
}

export type RouteState = {
  refreshTimeStamp: string;
  routeBackButton?: RouteBackButton;
  queryParams: RouteQueryParams;
};

export type RouteQueryParams =
  | Record<string, string | number>
  | null
  | undefined;

/**
 * Renders a view that contains a react component that is in Vinconnect.
 * This component will render a React component and a CarDashboard page
 * The placement of the React component depends on the routeConfig.componentPane value
 * For CarDashboard components the URL is an ActiveLeadLayout URL with the right and left frame content determined by the
 * component string in the configuration
 * @prop {string} routeId The identifier for the route
 * @constructor
 */
export const RenderView = ({ routeId }: RenderViewProps) => {
  const routeManager = useRouteManager();
  const routeState = useLocation()?.state as RouteState;

  const {
    isLeftPaneExpanded: isLeftExpanded,
    isRightPaneExpanded: isRightExpanded
  } = useSelector(getMainNavigationState, shallowEqual);

  const dispatch = useDispatch();

  function setIsLeftExpanded(isExpanded: boolean) {
    dispatch(mainNavigationActions.setLeftPaneExpanded(isExpanded));
  }

  function setIsRightExpanded(isExpanded: boolean) {
    dispatch(mainNavigationActions.setRightPaneExpanded(isExpanded));
  }

  function isCarDashboardComponentPane(component: RouteComponent) {
    return typeof component === "string";
  }

  function validateQueryParams(routeId: string, pane: RoutePanePlacement) {
    let component = routeManager?.getComponentForPane(routeId, pane);

    const options = routeManager?.getOptionsForPane(routeId, pane);

    if (
      !options?.requiredQueryParams ||
      options.requiredQueryParams.length === 0
    ) {
      return component;
    }

    // queryParams should be split into left and right (e.g. leftQueryParams, rightQueryParams) because right now it isn't specific about which pane the params are for
    if (!routeState?.queryParams) {
      return options.fallbackComponent ?? undefined;
    }

    options.requiredQueryParams.forEach(element => {
      if (routeState?.queryParams && !routeState.queryParams[element]) {
        component = options.fallbackComponent ?? undefined;
      }
    });

    return component;
  }

  if (!routeManager) {
    return null;
  }

  const paneType = routeManager.getPanePlacement(routeId);

  const leftComponent = validateQueryParams(routeId, RoutePanePlacement.LEFT);

  const rightComponent = validateQueryParams(routeId, RoutePanePlacement.RIGHT);

  const fullComponent = routeManager.getComponentForPane(
    routeId,
    RoutePanePlacement.FULL
  );

  const path = buildCarDashboardUrl(
    leftComponent,
    rightComponent,
    fullComponent,
    paneType === RoutePanePlacement.FULL ? "dynamic-frame" : "active-lead"
  );

  const leftIsCd = isCarDashboardComponentPane(leftComponent);
  const rightIsCd = isCarDashboardComponentPane(rightComponent);

  switch (paneType) {
    case RoutePanePlacement.BOTH: {
      logger.debug("both");

      if (leftIsCd && rightIsCd) {
        return CarDashboardComponentPane({
          path,
          queryParams: routeState?.queryParams
        });
      }

      return (
        <>
          {leftIsCd
            ? CarDashboardComponentPane({
                path,
                // if right is expanded, hide the routeBackButton on left
                routeBackButton: isRightExpanded
                  ? undefined
                  : routeState?.routeBackButton,
                queryParams: routeState?.queryParams
              })
            : ReactComponentPane({
                component: leftComponent,
                pane: RoutePanePlacement.LEFT,
                isPaneExpanded: isLeftExpanded,
                isOtherPaneExpanded: isRightExpanded,
                routeId,
                queryParams: routeState?.queryParams,
                refreshTimeStamp: routeState?.refreshTimeStamp,
                setIsExpanded: setIsLeftExpanded
              })}
          {rightIsCd
            ? CarDashboardComponentPane({
                path,
                // if left is expanded, hide the routeBackButton on right
                routeBackButton: isLeftExpanded
                  ? undefined
                  : routeState?.routeBackButton,
                queryParams: routeState?.queryParams
              })
            : ReactComponentPane({
                component: rightComponent,
                pane: RoutePanePlacement.RIGHT,
                isPaneExpanded: isRightExpanded,
                isOtherPaneExpanded: isLeftExpanded,
                routeId,
                queryParams: routeState?.queryParams,
                refreshTimeStamp: routeState?.refreshTimeStamp,
                setIsExpanded: setIsRightExpanded
              })}
        </>
      );
    }

    case RoutePanePlacement.LEFT: {
      logger.debug("left");

      return leftIsCd
        ? CarDashboardComponentPane({
            path,
            queryParams: routeState?.queryParams
          })
        : ReactComponentPane({
            component: leftComponent,
            pane: RoutePanePlacement.LEFT,
            isPaneExpanded: isLeftExpanded,
            isOtherPaneExpanded: isRightExpanded,
            routeId,
            queryParams: routeState?.queryParams,
            refreshTimeStamp: routeState?.refreshTimeStamp,
            setIsExpanded: setIsLeftExpanded
          });
    }

    case RoutePanePlacement.RIGHT: {
      logger.debug("right");

      return rightIsCd
        ? CarDashboardComponentPane({
            path,
            queryParams: routeState?.queryParams
          })
        : ReactComponentPane({
            component: rightComponent,
            pane: RoutePanePlacement.RIGHT,
            isPaneExpanded: isRightExpanded,
            isOtherPaneExpanded: isLeftExpanded,
            routeId,
            queryParams: routeState?.queryParams,
            refreshTimeStamp: routeState?.refreshTimeStamp,
            setIsExpanded: setIsRightExpanded
          });
    }

    case RoutePanePlacement.FULL: {
      logger.debug("full");

      if (isCarDashboardComponentPane(fullComponent)) {
        return CarDashboardComponentPane({
          path,
          queryParams: routeState?.queryParams
        });
      } else {
        return (
          <RouteComponentView
            component={fullComponent}
            data-testid={`full-side-component`}
            pane={RoutePanePlacement.FULL}
            props={routeState?.queryParams}
          />
        );
      }
    }

    default:
      return null;
  }
};
