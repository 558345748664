import { useNavigate } from "react-router-dom";
import { useRouteManager } from "./use-route-manager";
import { useCallback } from "react";
import { RouteBackButton } from "@vinsolutions/ccrm/top-nav/route-config";

/**
 * Used to render a route by alias
 */
export const useRenderRouteByAlias = () => {
  const navigate = useNavigate();
  const routeManager = useRouteManager();
  /**
   * Used to render a route
   * -- pane-{left|right}, this is a view that renders a react component or React/CarDashboard combo view
   * @param {string}  routeId The identifier of the route.
   */
  return useCallback(
    (
      aliasId: string,
      forceRender: boolean,
      queryParams?: Record<string, string | number> | null | undefined,
      routeBackButton?: RouteBackButton
    ) => {
      if (routeManager?.isActiveRouteByAlias(aliasId)) {
        const config = routeManager?.getConfigForRoute(aliasId);
        const routeState = routeManager?.setRouteState(
          forceRender,
          queryParams,
          routeBackButton
        );
        if (config) {
          navigate(routeManager.getRoutePath(config.routeId), {
            state: routeState,
            replace: false
          });
        }
      }
    },
    [navigate, routeManager]
  );
};
