export const generateTabId = (tabLabel: string) => {
  return `tab-${formatLabel(tabLabel)}`;
};

export const generateMenuId = (
  tabLabel: string,
  menuLabel: string,
  duplicateNumber?: number
) => {
  return generateNavigationId([tabLabel, menuLabel], duplicateNumber);
};

export const generatePrimaryMenuItemId = (
  tabLabel: string,
  menuLabel: string,
  primaryMenuItemLabel: string,
  duplicateNumber?: number
) => {
  return generateNavigationId(
    [tabLabel, menuLabel, primaryMenuItemLabel],
    duplicateNumber
  );
};

export const generateSecondaryMenuItemId = (
  tabLabel: string,
  menuLabel: string,
  primaryMenuItemLabel: string,
  secondaryMenuItemLabel: string,
  duplicateNumber?: number
) => {
  return generateNavigationId(
    [tabLabel, menuLabel, primaryMenuItemLabel, secondaryMenuItemLabel],
    duplicateNumber
  );
};

export const generateTertiaryMenuItemId = (
  tabLabel: string,
  menuLabel: string,
  primaryMenuItemLabel: string,
  secondaryMenuItemLabel: string,
  tertiaryMenuItemLabel: string,
  duplicateNumber?: number
) => {
  return generateNavigationId(
    [
      tabLabel,
      menuLabel,
      primaryMenuItemLabel,
      secondaryMenuItemLabel,
      tertiaryMenuItemLabel
    ],
    duplicateNumber
  );
};

export const generateQuaternaryMenuItemId = (
  tabLabel: string,
  menuLabel: string,
  primaryMenuItemLabel: string,
  secondaryMenuItemLabel: string,
  tertiaryMenuItemLabel: string,
  quaternaryMenuItemLabel: string,
  duplicateNumber?: number
) => {
  return generateNavigationId(
    [
      tabLabel,
      menuLabel,
      primaryMenuItemLabel,
      secondaryMenuItemLabel,
      tertiaryMenuItemLabel,
      quaternaryMenuItemLabel
    ],
    duplicateNumber
  );
};

const generateMenuItemIdFromParentId = (
  parentId: string,
  menuItemLabel: string
) => {
  return `${parentId}-${formatLabel(menuItemLabel)}`;
};

const generateMenuIdFromParentId = (parentId: string, menuLabel: string) => {
  return `navigation-sub-menu-${parentId}-${formatLabel(menuLabel)}`;
};

const generateNavigationId = (labels: string[], duplicateNumber?: number) => {
  let id = "";

  // Generate Base Id
  for (let k = 0; k < labels.length; k++) {
    if (k === 0) {
      id = generateTabId(labels[k]);
    }

    if (k === 1) {
      // CD Dynamically generates unmatched inbox. This is to strip the dynamically generated text.
      if (labels[k].startsWith("Unmatched Inbox")) {
        id = generateMenuIdFromParentId(id, "Unmatched Inbox");
      } else {
        id = generateMenuIdFromParentId(id, labels[k]);
      }
    }

    if (k > 1) {
      id = generateMenuItemIdFromParentId(id, labels[k]);
    }
  }

  // Handle duplicates
  if (duplicateNumber) {
    id += `-${duplicateNumber}`;
  }

  return id;
};

interface ParentIdLabelPair {
  parentId?: string;
  label: string;
}
export const generateNavigationIdFromParentId = (
  label: string,
  parentIdLabelPairs: ParentIdLabelPair[],
  parentId?: string
) => {
  let id = "";

  // Generate the base ID.
  if (!parentId) {
    id = generateTabId(label);
  } else if (parentId.indexOf("navigation-sub-menu-") === -1) {
    // CD Dynamically generates unmatched inbox. This is to strip the dynamically generated text.
    id = label.startsWith("Unmatched Inbox")
      ? generateMenuIdFromParentId(parentId, "Unmatched Inbox")
      : generateMenuIdFromParentId(parentId, label);
  } else {
    id = generateMenuItemIdFromParentId(parentId, label); // primary, secondary, tertiary, quaternary menu items
  }

  // Handling duplicate items.
  const numberOfExistingItems = parentIdLabelPairs.filter(
    pair =>
      pair.label.toLowerCase() === label.toLowerCase() &&
      (pair.parentId === parentId || pair.parentId == null)
  ).length;

  if (numberOfExistingItems > 0) id += `-${numberOfExistingItems + 1}`;

  return id;
};

export const formatLabel = (label: string) => {
  return label
    .trim()
    .replace(/\s+|'/g, m => {
      // remove white concate space and single quotes
      if (m === "'") {
        return "";
      } else {
        return "-";
      }
    })
    .toLowerCase();
};

export const communicationsNavigationItemEntityIds = {
  communicationsTab: generateTabId("Communications"),
  email: generateMenuId("Communications", "Email"),
  text: generateMenuId("Communications", "Text"),
  calls: generateMenuId("Communications", "Call"),
  messageProviders: generateMenuId("Communications", "Message Providers"),
  facebookLog: generateMenuId("Communications", "Facebook Log"),
  facebookMessenger: generateMenuId("Communications", "Facebook Messenger"),
  emailUnmatched: generatePrimaryMenuItemId(
    "Communications",
    "Email",
    "Unmatched"
  ),
  emailInbound: generatePrimaryMenuItemId("Communications", "Email", "Inbound"),
  emailOutbound: generatePrimaryMenuItemId(
    "Communications",
    "Email",
    "Outbound"
  ),
  emailRead: generatePrimaryMenuItemId("Communications", "Email", "Read"),
  emailUndelivered: generatePrimaryMenuItemId(
    "Communications",
    "Email",
    "Undelivered"
  ),
  textInbound: generatePrimaryMenuItemId("Communications", "Text", "Inbound"),
  textOutbound: generatePrimaryMenuItemId("Communications", "Text", "Outbound"),
  callsInbound: generatePrimaryMenuItemId("Communications", "Calls", "Inbound"),
  callsOutbound: generatePrimaryMenuItemId(
    "Communications",
    "Calls",
    "Outbound"
  ),
  callsMonitoredInbound: generatePrimaryMenuItemId(
    "Communications",
    "Calls",
    "Monitored Inbound"
  ),
  callsMonitoredOutbound: generatePrimaryMenuItemId(
    "Communications",
    "Calls",
    "Monitored Outbound"
  ),
  callsVCTInbound: generatePrimaryMenuItemId(
    "Communications",
    "Calls",
    "VCT Inbound"
  ),
  callsVCTOutbound: generatePrimaryMenuItemId(
    "Communications",
    "Calls",
    "VCT Outbound"
  ),
  callsCDR: generatePrimaryMenuItemId("Communications", "Calls", "CDR"),
  callsUnmatched: generatePrimaryMenuItemId(
    "Communications",
    "Calls",
    "Unmatched"
  )
};

export const inventoryNavigationItemEntityIds = {
  viewBmwPipeline: generateMenuId("Inventory", "View BMW Pipeline Vehicles"),
  locateGmVehicle: generateMenuId("Inventory", "Locate GM Vehicle")
};

export const campaignsNavigationItemEntityIds = {
  campaigns: generateTabId("Campaigns"),
  autoMarketing: generateMenuId("Campaigns", "AutoMarketing")
};

export const adminNavigationItemEntityIds = {
  admin: generateTabId("Admin"),
  dealerList: generateMenuId("Admin", "Dealer List"),
  duplicateDealerList: generatePrimaryMenuItemId(
    "Admin",
    "Dealer List",
    "Dealer List"
  ),
  cdrImportSettings: generatePrimaryMenuItemId(
    "Admin",
    "Selected Dealer",
    "CDR Import Settings"
  )
};

export const settingsNavigationItemEntityIds = {
  settings: generateTabId("Settings"),
  billing: generateMenuId("Settings", "Billing"),
  dealerSettings: generateMenuId("Settings", "Dealer Settings"),
  dealerSettingsDuplicate: generatePrimaryMenuItemId(
    "Settings",
    "Dealer Settings",
    "Dealer Settings"
  ),
  usersManageUserGroups: generatePrimaryMenuItemId(
    "Settings",
    "Users",
    "Manage User Groups"
  )
};

export const vinLensNavigationItemEntityIds = {
  vinlens: generateTabId("VinLens")
};

export const insightsNavigationItemEntityIds = {
  insights: generateTabId("Insights")
};

export const crmNavigationItemEntityIds = {
  crm: generateTabId("CRM"),
  email: generateMenuId("CRM", "Email"),
  duplicateEmail: generatePrimaryMenuItemId("CRM", "Email", "Email"),
  resetEmailPassword: generatePrimaryMenuItemId(
    "CRM",
    "Email",
    "Reset Email Password"
  ),
  dashboard: generateMenuId("CRM", "Dashboard"),
  dashboardDuplicate: generatePrimaryMenuItemId(
    "CRM",
    "Dashboard",
    "Dashboard"
  ),
  unmatchedEmail: generateMenuId("CRM", "Unmatched Inbox"),
  recentViewedCustomers: generateMenuId("CRM", "Recent Viewed Customers"),
  leads: generateMenuId("CRM", "Leads"),
  leadsDuplicate: generatePrimaryMenuItemId("CRM", "Leads", "Leads")
};

export const ilmNavigationItemEntityIds = {
  ilm: generateTabId("ILM"),
  email: generateMenuId("ILM", "Email"),
  duplicateEmail: generatePrimaryMenuItemId("ILM", "Email", "Email"),
  resetEmailPassword: generatePrimaryMenuItemId(
    "ILM",
    "Email",
    "Reset Email Password"
  ),
  dashboard: generateMenuId("ILM", "Dashboard"),
  dashboardDuplicate: generatePrimaryMenuItemId(
    "ILM",
    "Dashboard",
    "Dashboard"
  ),
  unmatchedEmail: generateMenuId("ILM", "Unmatched Inbox"),
  recentViewedCustomers: generateMenuId("ILM", "Recent Viewed Customers"),
  leads: generateMenuId("ILM", "Leads"),
  leadsDuplicate: generatePrimaryMenuItemId("ILM", "Leads", "Leads")
};

export const serviceCenterNavigationItemEntityIds = {
  serviceCenter: generateTabId("Service Center")
};
