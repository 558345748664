import styled from "styled-components";
import { ModalDialog } from "cx";

export const StyledDMCModal = styled(ModalDialog)`
  .modal-dialog.modal-dialog,
  .modal-content.modal-content {
    min-height: 100px;
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .modal-header {
      padding: 8px 16px 0 16px;
      .close {
        margin-top: 2px;
      }
    }
  }
`;
