import {
  getProfileState,
  mainNavigationActions,
  profileActions,
  selectAllNavigation,
  selectNavigationItemsEntities
} from "@vinsolutions/ccrm/store";
import { useVinconnectFlags } from "@vinsolutions/core/third-party/launch-darkly";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { usePrevious } from "@vinsolutions/ccrm/util";
import {
  cardashboardActions,
  getCardashboardState
} from "@vinsolutions/legacy-cardashboard/store";
import { useEffect } from "react";
import { useGetHomePageUrl } from "../useGetHomePageUrl/useGetHomePageUrl";
import { BroadcastChannelNames } from "@vinsolutions/ccrm/constants";
import { DEALER_NOTIFY_APPS } from "./constants";
import { useCardashboardTabChannel } from "@vinsolutions/legacy-cardashboard/hooks";

interface DealerChangePostMessage {
  dealerId: number;
}

export function useHandlerDealerIdChanges() {
  const { dealerId, dealerName } = useSelector(getProfileState, shallowEqual);
  const previousDealerId = usePrevious(dealerId, null);
  const dispatch = useDispatch();
  const navigationList = useSelector(selectAllNavigation, shallowEqual);
  const vinconnectFlags = useVinconnectFlags();
  const dealerChangePostMessage =
    useCardashboardTabChannel<DealerChangePostMessage>(
      BroadcastChannelNames.topNavDealerChange
    );
  const { goInitPage } = useGetHomePageUrl();

  const navigationItemsDictionary = useSelector(
    selectNavigationItemsEntities,
    shallowEqual
  );

  const { frameBaseUrl, frameUrl } = useSelector(
    getCardashboardState,
    shallowEqual
  );

  function checkCdSources() {
    const cd = document.getElementById("cardashboardframe");
    if (cd) {
      const leftFrame = (
        cd as HTMLIFrameElement
      ).contentDocument?.getElementById("leftcardashboardframe");
      const rightFrame = (
        cd as HTMLIFrameElement
      ).contentDocument?.getElementById("rightcardashboardframe");

      if (leftFrame || rightFrame) {
        return (
          frameShouldSendEvent((leftFrame as HTMLIFrameElement).src) ||
          frameShouldSendEvent((rightFrame as HTMLIFrameElement).src)
        );
      } else {
        return frameShouldSendEvent((cd as HTMLIFrameElement).src);
      }
    } else {
      return false;
    }
  }

  const frameShouldSendEvent = (src: string) => {
    return DEALER_NOTIFY_APPS.some(({ route, flag }) => {
      return src.includes(route) && vinconnectFlags[flag];
    });
  };

  useEffect(() => {
    if (
      dealerId !== null &&
      previousDealerId !== null &&
      previousDealerId !== dealerId
    ) {
      // use promise to allow method to run async rather than blocking execution
      localStorage.setItem(
        "selectedDealerId",
        JSON.stringify({
          dealerId,
          dealerName
        })
      );
      if (checkCdSources()) {
        // whenever dealer change on top nav the rest of the apps loads should know
        dealerChangePostMessage({ dealerId });
      } else {
        dispatch(cardashboardActions.pending());
        batch(() => {
          goInitPage();
          dispatch(
            mainNavigationActions.setActiveTab(
              navigationList.length > 0 ? navigationList[0].id : "crm-tab"
            )
          );
          dispatch(cardashboardActions.forceRefresh(Date.now()));
          dispatch(profileActions.loaded());
        });
      }
    }
  }, [
    dealerId,
    dealerName,
    dispatch,
    frameBaseUrl,
    frameUrl,
    navigationItemsDictionary,
    navigationList,
    previousDealerId,
    vinconnectFlags,
    goInitPage
  ]);
}

declare global {
  interface Window {
    postMessageToCarDashboard: (message: any) => boolean;
  }
}
