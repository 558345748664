export const DarkThemeConnectLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    version="1.1"
    viewBox="0 0 605.4 126"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    y="0px"
    {...props}
  >
    <g>
      <g>
        <path
          d="M174.4,61.6l-20-56.9h8.1L178.8,54h0.2l16.4-49.3h7.9L183,61.6H174.4z"
          style={{ fill: "#FFF" }}
        />
      </g>
      <g>
        <path
          d="M207.2,13V4.7h6.8V13H207.2z M213.9,20.4v41.2h-6.8V20.4H213.9z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M230.8,20.4v6.5h0.2c2.8-5,7.3-7.5,13.4-7.5c2.7,0,5,0.4,6.8,1.1s3.3,1.8,4.4,3.1c1.1,1.3,1.9,2.9,2.3,4.7
			s0.7,3.9,0.7,6.1v27.1h-6.8V33.7c0-2.6-0.7-4.6-2.2-6.1s-3.5-2.2-6.1-2.2c-2.1,0-3.9,0.3-5.4,1c-1.5,0.6-2.8,1.5-3.8,2.7
			s-1.8,2.5-2.3,4.1c-0.5,1.6-0.8,3.3-0.8,5.1v23.3h-6.8V20.4H230.8L230.8,20.4z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M298.4,12.5c-2.5-1.8-5.8-2.8-9.7-2.8c-1.6,0-3.2,0.2-4.7,0.5s-2.9,0.9-4.1,1.6c-1.2,0.7-2.2,1.7-2.9,3
			c-0.7,1.2-1.1,2.8-1.1,4.7c0,1.8,0.5,3.2,1.6,4.3c1,1.1,2.4,2,4.1,2.7s3.7,1.3,5.9,1.7c2.2,0.4,4.4,0.9,6.7,1.5
			c2.3,0.5,4.5,1.2,6.7,1.9s4.1,1.7,5.9,2.9c1.7,1.2,3.1,2.8,4.1,4.6s1.6,4.2,1.6,7c0,3-0.7,5.6-2,7.8c-1.4,2.2-3.1,3.9-5.2,5.3
			c-2.1,1.4-4.5,2.3-7.1,2.9S293,63,290.3,63c-3.2,0-6.2-0.4-9-1.2s-5.3-2-7.5-3.6s-3.9-3.7-5.1-6.2s-1.9-5.5-1.9-9h7.2
			c0,2.4,0.5,4.5,1.4,6.2s2.2,3.2,3.7,4.3s3.3,1.9,5.3,2.5c2,0.5,4.1,0.8,6.2,0.8c1.7,0,3.4-0.2,5.1-0.5s3.3-0.9,4.7-1.6
			c1.4-0.8,2.5-1.8,3.3-3.2c0.9-1.3,1.3-3,1.3-5.1c0-2-0.5-3.6-1.6-4.8c-1-1.2-2.4-2.2-4.1-3s-3.7-1.4-5.9-1.9
			c-2.2-0.5-4.4-1-6.7-1.5c-2.3-0.5-4.5-1.1-6.7-1.8s-4.1-1.5-5.9-2.6c-1.7-1.1-3.1-2.5-4.1-4.2s-1.6-3.9-1.6-6.5
			c0-2.9,0.6-5.4,1.8-7.4c1.2-2.1,2.7-3.8,4.7-5.2c1.9-1.4,4.1-2.4,6.6-3c2.5-0.7,5-1,7.6-1c2.9,0,5.6,0.3,8.1,1s4.7,1.8,6.6,3.3
			s3.4,3.4,4.5,5.6s1.7,5,1.8,8.1h-7.2C302.4,17.3,300.9,14.3,298.4,12.5L298.4,12.5z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M319.5,32.6c0.9-2.6,2.1-4.9,3.8-6.9c1.7-1.9,3.8-3.5,6.3-4.6s5.4-1.7,8.6-1.7s6.2,0.6,8.6,1.7
			c2.5,1.1,4.6,2.6,6.3,4.6c1.7,1.9,3,4.2,3.8,6.9c0.9,2.6,1.3,5.5,1.3,8.5s-0.4,5.8-1.3,8.4c-0.9,2.6-2.1,4.9-3.8,6.8
			c-1.7,1.9-3.8,3.5-6.3,4.5c-2.5,1.1-5.4,1.6-8.6,1.6s-6.1-0.5-8.6-1.6s-4.6-2.6-6.3-4.5c-1.7-1.9-3-4.2-3.8-6.8
			c-0.9-2.6-1.3-5.4-1.3-8.4C318.2,38,318.6,35.2,319.5,32.6z M326.3,47.7c0.7,1.9,1.6,3.6,2.8,4.9c1.2,1.3,2.5,2.3,4.1,3s3.2,1,5,1
			s3.4-0.3,5-1s2.9-1.7,4.1-3s2.1-2.9,2.8-4.9c0.7-1.9,1-4.2,1-6.7s-0.3-4.7-1-6.7c-0.7-1.9-1.6-3.6-2.8-4.9c-1.2-1.3-2.5-2.3-4.1-3
			s-3.2-1-5-1s-3.4,0.3-5,1s-2.9,1.7-4.1,3s-2.1,3-2.8,4.9c-0.7,1.9-1,4.2-1,6.7C325.3,43.6,325.7,45.8,326.3,47.7z"
          style={{ fill: "#FFF" }}
        />
        <path d="M373.3,4.7v56.9h-6.8V4.7H373.3z" style={{ fill: "#FFF" }} />
        <path
          d="M411.6,61.6v-6.5h-0.2c-1.4,2.6-3.3,4.4-5.5,5.6c-2.2,1.2-4.9,1.8-7.9,1.8c-2.7,0-5-0.4-6.8-1.1
			s-3.3-1.7-4.4-3.1c-1.1-1.3-1.9-2.9-2.3-4.7s-0.7-3.9-0.7-6.1V20.4h6.8v27.9c0,2.6,0.7,4.6,2.2,6.1s3.5,2.2,6.1,2.2
			c2.1,0,3.9-0.3,5.4-1c1.5-0.6,2.8-1.5,3.8-2.7s1.8-2.5,2.3-4.1s0.8-3.3,0.8-5.1V20.4h6.8v41.2H411.6L411.6,61.6z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M445.8,20.4v6h-8.2V52c0,0.8,0.1,1.4,0.2,1.9s0.4,0.9,0.8,1.1c0.4,0.3,0.9,0.4,1.6,0.5s1.5,0.1,2.6,0.1h3.1v6
			h-5.2c-1.8,0-3.3-0.1-4.5-0.4c-1.2-0.2-2.3-0.7-3-1.3c-0.8-0.6-1.3-1.5-1.7-2.7c-0.4-1.2-0.6-2.7-0.6-4.6V26.4h-7v-6h7V8.1h6.8
			v12.4L445.8,20.4L445.8,20.4z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M453.7,13V4.7h6.8V13H453.7z M460.4,20.4v41.2h-6.8V20.4H460.4z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M470,32.6c0.9-2.6,2.1-4.9,3.8-6.9c1.7-1.9,3.8-3.5,6.3-4.6s5.4-1.7,8.6-1.7s6.2,0.6,8.6,1.7
			c2.5,1.1,4.6,2.6,6.3,4.6c1.7,1.9,3,4.2,3.8,6.9c0.9,2.6,1.3,5.5,1.3,8.5s-0.4,5.8-1.3,8.4c-0.9,2.6-2.1,4.9-3.8,6.8
			c-1.7,1.9-3.8,3.5-6.3,4.5c-2.5,1.1-5.4,1.6-8.6,1.6c-3.3,0-6.1-0.5-8.6-1.6s-4.6-2.6-6.3-4.5c-1.7-1.9-3-4.2-3.8-6.8
			c-0.9-2.6-1.3-5.4-1.3-8.4C468.7,38,469.2,35.2,470,32.6z M476.9,47.7c0.7,1.9,1.6,3.6,2.8,4.9c1.2,1.3,2.5,2.3,4.1,3s3.2,1,5,1
			c1.7,0,3.4-0.3,5-1s2.9-1.7,4.1-3s2.1-2.9,2.8-4.9c0.7-1.9,1-4.2,1-6.7s-0.3-4.7-1-6.7s-1.6-3.6-2.8-4.9c-1.2-1.3-2.5-2.3-4.1-3
			s-3.2-1-5-1c-1.7,0-3.4,0.3-5,1s-2.9,1.7-4.1,3s-2.1,3-2.8,4.9c-0.7,1.9-1,4.2-1,6.7C475.9,43.6,476.2,45.8,476.9,47.7z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M523.1,20.4v6.5h0.2c2.8-5,7.3-7.5,13.4-7.5c2.7,0,5,0.4,6.8,1.1s3.3,1.8,4.4,3.1c1.1,1.3,1.9,2.9,2.3,4.7
			c0.4,1.8,0.7,3.9,0.7,6.1v27.1H544V33.7c0-2.6-0.7-4.6-2.2-6.1s-3.5-2.2-6.1-2.2c-2.1,0-3.9,0.3-5.4,1c-1.5,0.6-2.8,1.5-3.8,2.7
			s-1.8,2.5-2.3,4.1c-0.5,1.6-0.8,3.3-0.8,5.1v23.3h-6.8V20.4H523.1L523.1,20.4z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M566.2,52.4c0.6,1,1.5,1.9,2.5,2.5s2.2,1,3.5,1.3s2.6,0.4,4,0.4c1.1,0,2.2-0.1,3.3-0.2s2.2-0.4,3.2-0.9
			s1.8-1,2.4-1.9s1-1.9,1-3.2c0-1.8-0.7-3.1-2-4s-3-1.6-5-2.2c-2-0.6-4.2-1.1-6.5-1.5s-4.5-1.1-6.5-1.9s-3.7-2-5-3.5s-2-3.6-2-6.2
			c0-2.1,0.5-3.9,1.4-5.3s2.1-2.7,3.6-3.6s3.1-1.6,4.9-2.1c1.8-0.4,3.6-0.7,5.5-0.7c2.3,0,4.5,0.2,6.5,0.6s3.7,1.1,5.2,2.1
			s2.7,2.3,3.6,4c0.9,1.7,1.4,3.8,1.6,6.3h-6.8c-0.1-1.3-0.4-2.4-1-3.3s-1.3-1.6-2.2-2.1s-1.9-0.9-3-1.2c-1.1-0.2-2.2-0.4-3.3-0.4
			c-1,0-2,0.1-3.1,0.2c-1,0.2-2,0.4-2.8,0.8c-0.9,0.4-1.5,0.9-2.1,1.6c-0.5,0.7-0.8,1.5-0.8,2.6c0,1.2,0.4,2.1,1.2,2.9
			c0.8,0.8,1.9,1.4,3.2,1.9s2.7,0.9,4.3,1.3c1.6,0.3,3.2,0.7,4.8,1.1c1.7,0.4,3.4,0.8,5,1.4c1.6,0.5,3.1,1.2,4.3,2.1s2.3,2,3,3.3
			c0.8,1.3,1.2,3,1.2,4.9c0,2.5-0.5,4.6-1.6,6.2c-1,1.6-2.4,3-4.1,4c-1.7,1-3.6,1.7-5.7,2.1c-2.1,0.4-4.2,0.6-6.3,0.6
			c-2.3,0-4.5-0.2-6.5-0.7c-2.1-0.5-3.9-1.3-5.5-2.3c-1.6-1.1-2.9-2.5-3.8-4.3c-1-1.8-1.5-3.9-1.6-6.5h6.8
			C565.2,50.1,565.6,51.4,566.2,52.4L566.2,52.4z"
          style={{ fill: "#FFF" }}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M158.1,78.9v11.6h0.1c0.6-1.2,1.6-2.2,2.9-2.7s2.7-0.8,4.3-0.8c1.7,0,3.2,0.3,4.5,1c1.3,0.6,2.4,1.5,3.2,2.6
			c0.9,1.1,1.5,2.3,1.9,3.8c0.4,1.4,0.7,2.9,0.7,4.5s-0.2,3.1-0.6,4.5c-0.4,1.4-1.1,2.7-1.9,3.7c-0.9,1.1-1.9,1.9-3.2,2.5
			s-2.8,0.9-4.5,0.9c-0.6,0-1.2-0.1-1.8-0.2c-0.7-0.1-1.4-0.3-2-0.6c-0.7-0.3-1.3-0.6-1.9-1.1s-1.1-1-1.5-1.7H158v3h-3.7v-31
			L158.1,78.9L158.1,78.9z M171.3,95.4c-0.3-1-0.7-1.9-1.3-2.7c-0.6-0.8-1.3-1.4-2.2-1.9s-1.9-0.7-3.1-0.7c-1.2,0-2.3,0.2-3.2,0.7
			s-1.6,1.1-2.1,1.9c-0.6,0.8-0.9,1.7-1.2,2.7c-0.2,1-0.4,2-0.4,3.1s0.1,2.2,0.4,3.2c0.3,1,0.7,1.9,1.2,2.7s1.3,1.4,2.2,1.9
			s2,0.7,3.3,0.7s2.3-0.2,3.2-0.7s1.5-1.1,2.1-1.9s0.9-1.7,1.1-2.8c0.2-1,0.3-2.1,0.3-3.2C171.7,97.4,171.6,96.4,171.3,95.4
			L171.3,95.4z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M188.8,112.9c-0.4,1.1-0.9,2-1.3,2.8c-0.4,0.8-0.9,1.4-1.4,1.8s-1.1,0.8-1.7,1s-1.3,0.3-2.1,0.3
			c-0.4,0-0.9,0-1.3-0.1c-0.4-0.1-0.9-0.2-1.3-0.3V115c0.3,0.1,0.7,0.3,1.1,0.4c0.4,0.1,0.8,0.2,1.1,0.2c0.8,0,1.4-0.2,1.9-0.5
			c0.5-0.4,0.9-0.9,1.2-1.5l1.5-3.8l-8.9-22.3h4.2l6.5,18.3h0.1l6.3-18.3h3.9L188.8,112.9L188.8,112.9z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M233,83.3c-1.6-1.1-3.4-1.7-5.6-1.7c-1.8,0-3.4,0.3-4.8,1c-1.3,0.7-2.4,1.6-3.3,2.8c-0.9,1.2-1.5,2.5-1.9,4
			s-0.6,3.1-0.6,4.7c0,1.8,0.2,3.4,0.6,5c0.4,1.6,1,2.9,1.9,4.1s1.9,2.1,3.3,2.8c1.3,0.7,2.9,1,4.8,1c1.4,0,2.6-0.2,3.6-0.7
			c1-0.4,2-1.1,2.7-1.9c0.8-0.8,1.4-1.7,1.8-2.8c0.4-1.1,0.7-2.3,0.8-3.6h4.1c-0.4,3.9-1.8,6.9-4,9.1c-2.3,2.2-5.4,3.3-9.4,3.3
			c-2.4,0-4.5-0.4-6.3-1.2s-3.3-2-4.5-3.4s-2.1-3.1-2.7-5.1s-0.9-4.1-0.9-6.3s0.3-4.4,1-6.4c0.6-2,1.6-3.7,2.8-5.2s2.8-2.6,4.6-3.5
			s3.9-1.3,6.3-1.3c1.6,0,3.1,0.2,4.6,0.7c1.4,0.4,2.7,1.1,3.9,1.9c1.1,0.8,2.1,1.9,2.8,3.1c0.8,1.3,1.2,2.7,1.5,4.4H236
			C235.6,86,234.6,84.4,233,83.3L233,83.3z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M244.5,94.1c0.5-1.4,1.2-2.7,2.1-3.7c0.9-1,2.1-1.9,3.4-2.5c1.4-0.6,2.9-0.9,4.7-0.9s3.4,0.3,4.7,0.9
			s2.5,1.4,3.4,2.5s1.6,2.3,2.1,3.7c0.5,1.4,0.7,3,0.7,4.6c0,1.7-0.2,3.2-0.7,4.6s-1.2,2.7-2.1,3.7c-0.9,1.1-2.1,1.9-3.4,2.5
			s-2.9,0.9-4.7,0.9s-3.3-0.3-4.7-0.9s-2.5-1.4-3.4-2.5s-1.6-2.3-2.1-3.7c-0.5-1.4-0.7-2.9-0.7-4.6S244,95.5,244.5,94.1L244.5,94.1z
			 M248.2,102.3c0.4,1.1,0.9,1.9,1.5,2.6s1.4,1.3,2.2,1.6c0.9,0.4,1.8,0.6,2.7,0.6s1.9-0.2,2.7-0.6s1.6-0.9,2.2-1.6s1.1-1.6,1.5-2.6
			s0.5-2.3,0.5-3.6c0-1.4-0.2-2.6-0.5-3.6s-0.9-1.9-1.5-2.7c-0.6-0.7-1.4-1.3-2.2-1.7s-1.8-0.6-2.7-0.6s-1.9,0.2-2.7,0.6
			c-0.9,0.4-1.6,0.9-2.2,1.7c-0.6,0.7-1.1,1.6-1.5,2.7s-0.5,2.3-0.5,3.6S247.9,101.2,248.2,102.3z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M268.1,87.4h4.7l5.4,7.8l5.6-7.8h4.4l-7.7,10.3l8.7,12.1h-4.7l-6.2-9.3l-6.2,9.3h-4.5L276,98L268.1,87.4
			L268.1,87.4z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M317.9,78.9l12.1,31h-4.6l-3.4-9.3h-13l-3.5,9.3h-4.2l12-31H317.9z M320.8,97.1l-5.2-14.4h-0.1l-5.2,14.4
			H320.8L320.8,97.1z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M347.5,109.9v-3.6h-0.1c-0.8,1.4-1.8,2.4-3,3.1s-2.6,1-4.3,1c-1.5,0-2.7-0.2-3.7-0.6s-1.8-0.9-2.4-1.7
			c-0.6-0.7-1-1.6-1.3-2.6c-0.2-1-0.4-2.1-0.4-3.3V87.5h3.7v15.2c0,1.4,0.4,2.5,1.2,3.3c0.8,0.8,1.9,1.2,3.3,1.2
			c1.1,0,2.1-0.2,2.9-0.5s1.5-0.8,2.1-1.5c0.6-0.6,1-1.4,1.2-2.2c0.3-0.9,0.4-1.8,0.4-2.8V87.5h3.7v22.4H347.5L347.5,109.9z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M366.1,87.4v3.3h-4.5v13.9c0,0.4,0,0.8,0.1,1c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.5,0.2,0.8,0.3
			c0.4,0,0.8,0.1,1.4,0.1h1.7v3.3h-2.8c-1,0-1.8-0.1-2.4-0.2c-0.7-0.1-1.2-0.4-1.7-0.7c-0.4-0.3-0.7-0.8-0.9-1.5
			c-0.2-0.6-0.3-1.5-0.3-2.5V90.7h-3.8v-3.3h3.8v-6.7h3.7v6.7L366.1,87.4L366.1,87.4z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M369.7,94.1c0.5-1.4,1.2-2.7,2.1-3.7s2.1-1.9,3.4-2.5c1.4-0.6,2.9-0.9,4.7-0.9s3.4,0.3,4.7,0.9
			c1.3,0.6,2.5,1.4,3.4,2.5s1.6,2.3,2.1,3.7c0.5,1.4,0.7,3,0.7,4.6c0,1.7-0.2,3.2-0.7,4.6s-1.2,2.7-2.1,3.7
			c-0.9,1.1-2.1,1.9-3.4,2.5s-2.9,0.9-4.7,0.9c-1.8,0-3.3-0.3-4.7-0.9c-1.4-0.6-2.5-1.4-3.4-2.5s-1.6-2.3-2.1-3.7
			c-0.5-1.4-0.7-2.9-0.7-4.6S369.2,95.5,369.7,94.1z M373.4,102.3c0.4,1.1,0.9,1.9,1.5,2.6s1.4,1.3,2.2,1.6c0.9,0.4,1.8,0.6,2.7,0.6
			s1.9-0.2,2.7-0.6c0.9-0.4,1.6-0.9,2.2-1.6s1.1-1.6,1.5-2.6s0.5-2.3,0.5-3.6c0-1.4-0.2-2.6-0.5-3.6c-0.4-1-0.9-1.9-1.5-2.7
			c-0.6-0.7-1.4-1.3-2.2-1.7c-0.9-0.4-1.8-0.6-2.7-0.6s-1.9,0.2-2.7,0.6c-0.9,0.4-1.6,0.9-2.2,1.7c-0.6,0.7-1.1,1.6-1.5,2.7
			s-0.5,2.3-0.5,3.6S373.1,101.2,373.4,102.3z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M398.6,87.4v3.3h0.1c1.7-2.5,4.1-3.8,7.2-3.8c1.4,0,2.6,0.3,3.8,0.9c1.1,0.6,1.9,1.6,2.4,2.9
			c0.8-1.2,1.7-2.2,3-2.8c1.2-0.7,2.6-1,4.1-1c1.1,0,2.1,0.1,3.1,0.4c0.9,0.2,1.7,0.6,2.3,1.2c0.7,0.5,1.2,1.2,1.5,2
			c0.4,0.8,0.5,1.8,0.5,3v16.4h-3.7V95.2c0-0.7-0.1-1.3-0.2-1.9s-0.3-1.1-0.7-1.6c-0.3-0.4-0.8-0.8-1.3-1.1
			c-0.6-0.3-1.3-0.4-2.2-0.4c-1.8,0-3.3,0.5-4.3,1.6s-1.6,2.4-1.6,4.2v14H409V95.2c0-0.7-0.1-1.4-0.2-2s-0.4-1.1-0.7-1.6
			c-0.3-0.4-0.7-0.8-1.3-1c-0.5-0.2-1.2-0.4-2.1-0.4c-1.1,0-2,0.2-2.8,0.7s-1.4,1-1.9,1.6s-0.8,1.2-1,1.9s-0.3,1.2-0.3,1.6v14H395
			V87.4H398.6L398.6,87.4z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M431.6,94.1c0.5-1.4,1.2-2.7,2.1-3.7s2.1-1.9,3.4-2.5c1.4-0.6,2.9-0.9,4.7-0.9s3.4,0.3,4.7,0.9
			c1.3,0.6,2.5,1.4,3.4,2.5s1.6,2.3,2.1,3.7c0.5,1.4,0.7,3,0.7,4.6c0,1.7-0.2,3.2-0.7,4.6s-1.2,2.7-2.1,3.7
			c-0.9,1.1-2.1,1.9-3.4,2.5s-2.9,0.9-4.7,0.9s-3.3-0.3-4.7-0.9s-2.5-1.4-3.4-2.5s-1.6-2.3-2.1-3.7c-0.5-1.4-0.7-2.9-0.7-4.6
			S431.1,95.5,431.6,94.1z M435.3,102.3c0.4,1.1,0.9,1.9,1.5,2.6s1.4,1.3,2.2,1.6c0.9,0.4,1.8,0.6,2.7,0.6c1,0,1.9-0.2,2.7-0.6
			s1.6-0.9,2.2-1.6s1.1-1.6,1.5-2.6s0.5-2.3,0.5-3.6c0-1.4-0.2-2.6-0.5-3.6c-0.4-1-0.9-1.9-1.5-2.7c-0.6-0.7-1.4-1.3-2.2-1.7
			s-1.8-0.6-2.7-0.6c-1,0-1.9,0.2-2.7,0.6s-1.6,0.9-2.2,1.7c-0.6,0.7-1.1,1.6-1.5,2.7s-0.5,2.3-0.5,3.6S435,101.2,435.3,102.3z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M466.5,87.4v3.3H462v13.9c0,0.4,0,0.8,0.1,1c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.5,0.2,0.8,0.3
			c0.4,0,0.8,0.1,1.4,0.1h1.7v3.3h-2.8c-1,0-1.8-0.1-2.4-0.2c-0.7-0.1-1.2-0.4-1.7-0.7c-0.4-0.3-0.7-0.8-0.9-1.5
			c-0.2-0.6-0.3-1.5-0.3-2.5V90.7h-3.8v-3.3h3.8v-6.7h3.7v6.7L466.5,87.4L466.5,87.4z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M470.8,83.4v-4.5h3.7v4.5H470.8z M474.5,87.5v22.4h-3.7V87.5H474.5z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M486.4,109.9l-8.3-22.4h4.1l6.3,18.7h0.1l6.1-18.7h3.9l-8.2,22.4H486.4z"
          style={{ fill: "#FFF" }}
        />
        <path
          d="M517.8,108.4c-1.7,1.3-3.8,1.9-6.3,1.9c-1.8,0-3.3-0.3-4.7-0.9c-1.3-0.6-2.4-1.4-3.3-2.4s-1.6-2.3-2-3.7
			c-0.4-1.4-0.7-3-0.8-4.7c0-1.7,0.3-3.3,0.8-4.7c0.5-1.4,1.3-2.6,2.2-3.7c0.9-1,2-1.8,3.3-2.4s2.7-0.9,4.2-0.9c2,0,3.6,0.4,5,1.2
			c1.3,0.8,2.4,1.9,3.2,3.1c0.8,1.3,1.3,2.7,1.7,4.2c0.3,1.5,0.4,2.9,0.4,4.3h-16.8c0,1,0.1,1.9,0.3,2.8c0.3,0.9,0.7,1.7,1.3,2.3
			c0.6,0.7,1.3,1.2,2.2,1.6c0.9,0.4,1.9,0.6,3.2,0.6c1.6,0,2.8-0.4,3.8-1.1s1.7-1.8,2-3.3h3.6C520.5,105.3,519.5,107.2,517.8,108.4z
			 M516.9,94c-0.3-0.8-0.8-1.4-1.3-2c-0.6-0.6-1.2-1-2-1.3s-1.6-0.5-2.5-0.5c-0.9,0-1.8,0.2-2.6,0.5c-0.8,0.3-1.4,0.8-2,1.4
			s-1,1.3-1.3,2c-0.3,0.8-0.5,1.6-0.6,2.4h12.9C517.4,95.6,517.2,94.8,516.9,94L516.9,94z"
          style={{ fill: "#FFF" }}
        />
      </g>
    </g>
    <g>
      <path
        d="M63,4.7C31.5,4.7,6,30.3,6,61.8s25.5,57.1,57.1,57.1s57.1-25.5,57.1-57.1S94.5,4.7,63,4.7z M63,22.9
		c5.5,0,9.9,4.3,9.9,9.9s-4.5,10-9.9,10s-9.9-4.7-9.9-10S57.6,22.9,63,22.9z M82.6,54.3L82.6,54.3C79,68.1,73.8,82.6,67.3,97.7
		c-1.7,4-6.9,4-8.6,0c-6.5-15-11.7-29.5-15.2-43.3c-0.9-3.3,1.4-6.6,4.6-6.6H78C81.2,47.7,83.5,51,82.6,54.3z"
        style={{ fill: "#FFF" }}
      />
    </g>
  </svg>
);
