import styled from "styled-components";
import { useEffect, useState } from "react";
import { DealKeyInformation } from "@vinsolutions/data-access/lead/crm.workflow.keyinfo.accelerate";

/* eslint-disable-next-line */
export interface KeyInformationDealDateProps {
  dealSummary: DealKeyInformation;
}

const StyledKeyInformationDealDate = styled.span``;
const dateStringOptions: Intl.DateTimeFormatOptions = {
  month: "2-digit",
  year: "numeric",
  day: "2-digit"
};

export function KeyInformationDealDate({
  dealSummary
}: KeyInformationDealDateProps) {
  const [date, setDate] = useState("");

  useEffect(() => {
    let applyDate = null;
    const lastDealActivity = dealSummary.lastModified
      ? new Date(dealSummary.lastModified)
      : null;
    const lastDrActivityDate = dealSummary.lastDrActivityDate
      ? new Date(dealSummary.lastDrActivityDate)
      : null;
    if (lastDealActivity && lastDrActivityDate) {
      applyDate =
        lastDealActivity > lastDrActivityDate
          ? lastDealActivity
          : lastDrActivityDate;
    } else {
      applyDate = lastDealActivity || lastDrActivityDate || null;
    }
    setDate(
      applyDate ? applyDate.toLocaleDateString("en-US", dateStringOptions) : ""
    );
  }, [dealSummary.lastDrActivityDate, dealSummary.lastModified]);

  return <StyledKeyInformationDealDate>{date}</StyledKeyInformationDealDate>;
}

export default KeyInformationDealDate;
