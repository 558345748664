/* eslint-disable prettier/prettier -- Conflicts with Eslint */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@vinsolutions/core/environment";
import { baseUrlsObj } from "./baseUrlsObj";
import { DateRangeType, LeadType, ReportType, ResponseTimeType } from "./enums";
import {
  DealerTimeZone,
  Detail,
  DetailResponse,
  RequestWrapper,
  Response,
  SummaryResponse,
  User,
  UserGroup,
  UserSettings,
  UserSettingsDto
} from "./models";
import { BaseUtcOffsetDto } from "./types";
import { EmptyDetailRequest } from "./models/emptyDetailRequest";
import { DetailRequest } from "./models/detailRequest";
import { SummaryRequest } from "./models/summaryRequest";

const baseUrl = getBaseUrl(baseUrlsObj);

function detailsQueryParams(args: DetailRequest) {
  // for details endpoint, the userGroup needs to be the positive value
  let userGroupId =
    args.userGroupId && args.userGroupId < 0
      ? args.userGroupId * -1
      : args.userGroupId;

  // if userId is set, dont sent userGroup
  if (args.userId && args.userId > 0) {
    userGroupId = undefined;
  }

  return Object.entries({
    dealerId: args.dealerId,
    startDateUtc: args.startDateLocal.toUTCString(),
    endDateUtc: args.endDateLocal.toUTCString(),
    leadType: args.leadType,
    responseTimeType: args.responseTimeType,
    reportType: args.reportType,
    userId: args.userId,
    userGroupId: userGroupId === 0 ? undefined : userGroupId,
    r: args.refresh
  })
    .filter(entry => entry[1] !== undefined && entry[1] !== null)
    .map(([k, v]) => `${k}=${v}`)
    .join("&");
}

function summaryQueryParams(args: SummaryRequest) {
  // for summary endpoint, the userGroup needs to be the positive value
  let userGroupId =
    args.userGroupId && args.userGroupId < 0
      ? args.userGroupId * -1
      : args.userGroupId;

  // if userId is set, dont sent userGroup
  if (args.userId && args.userId > 0) {
    userGroupId = undefined;
  }

  return Object.entries({
    dealerId: args.dealerId,
    startDateUtc: args.startDateLocal.toUTCString(),
    endDateUtc: args.endDateLocal.toUTCString(),
    leadType: args.leadType,
    responseTimeType: args.responseTimeType,
    userId: args.userId,
    userGroupId: userGroupId === 0 ? undefined : userGroupId,
    r: args.refresh
  })
    .filter(entry => entry[1] !== undefined && entry[1] !== null)
    .map(([k, v]) => `${k}=${v}`)
    .join("&");
}

function getHeaders(jwt: string) {
  return {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json",
    "content-type": "application/vnd.coxauto.v1+json"
  };
}

function compareUserGroupNames(a: UserGroup, b: UserGroup) {
  return a.name.localeCompare(b.name);
}

function compareUserNames(a: User, b: User) {
  const result = a.lastName.localeCompare(b.lastName);

  return result !== 0 ? result : a.firstName.localeCompare(b.firstName);
}

export const DealerDashboardApi = createApi({
  reducerPath: "DealerDashboardApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ["Detail"],
  endpoints: builder => ({
    getUsers: builder.query<User[], { jwt: string; dealerId: number }>({
      query: args => ({
        url: `user/list?dealerId=${args.dealerId}`,
        headers: getHeaders(args.jwt)
      }),
      transformResponse: (response: User[]) => response.sort(compareUserNames)
    }),

    getUserGroups: builder.query<
      UserGroup[],
      { jwt: string; dealerId: number }
    >({
      query: args => ({
        url: `dealer/userGroups?dealerId=${args.dealerId}`,
        headers: getHeaders(args.jwt)
      }),
      transformResponse: (response: UserGroup[]) =>
        response
          .map<UserGroup>(x => {
            return {
              // for the UI and UserSettings, we need the id to be negative
              id: x.id * -1,
              name: x.name
            };
          })
          .sort(compareUserGroupNames)
    }),

    getUserSettings: builder.query<UserSettings, RequestWrapper<void>>({
      query: args => ({
        url: `user/settings?dealerId=${args.dealerId}`,
        headers: getHeaders(args.jwt)
      }),
      transformResponse: (response: UserSettingsDto) => {
        // previously userGroup was an enum and the API was sending it as a string, so if we get a string then default to 0 (All)
        // this code can be removed when api and ui are both deployed
        // api returns userGroup, but we want userGroupId everywhere else
        let userGroupId =
          typeof response.userGroup === "string" ? 0 : response.userGroup;

        if (response.userId && response.userId > 0) {
          userGroupId = undefined;
        }

        // when the api sends enums as string, they aren't getting mapped correctly, so here we force that mapping to the enum
        return {
          dateRangeType:
            DateRangeType[response.dateRangeType as keyof typeof DateRangeType],
          opportunityType:
            LeadType[response.opportunityType as keyof typeof LeadType],
          responseTimeType:
            ResponseTimeType[
              response.responseTimeType as keyof typeof ResponseTimeType
            ],
          userGroupId,
          userId: response.userId
        };
      }
    }),

    setUserSettings: builder.mutation<void, RequestWrapper<UserSettings>>({
      query: args => ({
        url: `user/settings`,
        headers: getHeaders(args.jwt),
        method: "PUT",
        body: JSON.stringify({
          dealerId: args.dealerId,
          userSettings: {
            opportunityType: args.request.opportunityType,
            responseTimeType: args.request.responseTimeType,
            dateRangeType: args.request.dateRangeType,
            // api expects userGroup, but we want userGroupId everywhere else
            userGroup: args.request.userGroupId,
            userId: args.request.userId
          }
        })
      }),

      // this eagerly loads the new value so we can use it right away instead of having to wait for a response
      async onQueryStarted({ jwt, dealerId, request }, { dispatch }) {
        dispatch(
          DealerDashboardApi.util.updateQueryData(
            "getUserSettings",
            { jwt, dealerId, request: undefined },
            draft => {
              Object.assign(draft, request);
            }
          )
        );
      }
    }),

    getDealerTimeZone: builder.query<
      BaseUtcOffsetDto,
      { jwt: string; dealerId: number }
    >({
      query: args => ({
        url: `dealer/timezone?dealerId=${args.dealerId}`,
        headers: getHeaders(args.jwt)
      }),
      transformResponse: (response: DealerTimeZone) => ({
        totalMinutes: response.baseUtcOffset.totalMinutes,
        supportsDaylightSavingTime: response.supportsDaylightSavingTime
      })
    }),

    getDetails: builder.query<DetailResponse, DetailRequest>({
      query: args => ({
        url: `detail?${detailsQueryParams(args)}`,
        headers: getHeaders(args.jwt)
      }),
      providesTags: (_result, _error, arg) => {
        const id = Object.entries({
          dealerId: arg.dealerId,
          startDateUtc: arg.startDateLocal.toUTCString(),
          endDateUtc: arg.endDateLocal.toUTCString(),
          leadType: arg.leadType,
          responseTimeType: arg.responseTimeType,
          reportType: arg.reportType,
          userId: arg.userId,
          userGroupId: arg.userGroupId
        })
          .filter(entry => entry[1] !== undefined && entry[1] !== null)
          .map(([k, v]) => `${k}=${v}`)
          .join("&");

        return [{ type: "Detail", id }];
      }
    }),

    // when we get 0 counts from summary, this is used to add empty values to rtk cache so we dont call the api for data when there isn't any
    setEmptyDetails: builder.query<null, EmptyDetailRequest>({
      queryFn: () => {
        return { data: null };
      },
      async onQueryStarted(
        {
          jwt,
          dealerId,
          startDateLocal,
          endDateLocal,
          leadType,
          responseTimeType,
          userId,
          userGroupId,
          summaryResponse,
          refresh: r
        },
        { dispatch }
      ) {
        function upsertEmptyDetails(reportType: ReportType, count: number) {
          if (count > 0) {
            return;
          }

          dispatch(
            DealerDashboardApi.util.upsertQueryData(
              "getDetails",
              {
                jwt,
                dealerId,
                startDateLocal,
                endDateLocal,
                leadType,
                responseTimeType,
                reportType,
                userId,
                userGroupId,
                refresh: r
              },
              {
                startDateUtc: new Date(startDateLocal.toUTCString()),
                endDateUtc: new Date(endDateLocal.toUTCString()),
                reportType,
                result: [] as Detail[]
              } as DetailResponse
            )
          );
        }

        if (!summaryResponse) {
          return;
        }

        const { salesFunnelCounts } = summaryResponse;

        if (salesFunnelCounts) {
          upsertEmptyDetails(
            ReportType.SALES_FUNNEL_CUSTOMERS,
            salesFunnelCounts.allCustomers
          );

          upsertEmptyDetails(
            ReportType.SALES_FUNNEL_APPOINTMENTS_SET,
            salesFunnelCounts.appointmentCustomers
          );

          upsertEmptyDetails(
            ReportType.SALES_FUNNEL_APPOINTMENTS_SHOWN,
            salesFunnelCounts.appointmentCustomersCompleted
          );

          upsertEmptyDetails(
            ReportType.SALES_FUNNEL_CONTACTED,
            salesFunnelCounts.contactedCustomers
          );

          upsertEmptyDetails(
            ReportType.SALES_FUNNEL_SOLD,
            salesFunnelCounts.soldCustomers
          );
        }

        upsertEmptyDetails(
          ReportType.KPI_OPEN_VISITS,
          summaryResponse?.openShowroomVisitsCount
        );

        upsertEmptyDetails(
          ReportType.KPI_PENDING_SALES,
          summaryResponse?.pendingSalesCount
        );

        upsertEmptyDetails(
          ReportType.KPI_UNANSWERED_COMMUNICATIONS,
          summaryResponse?.unAnsweredCommunicationCount
        );
      }
    }),

    getSummary: builder.query<SummaryResponse, SummaryRequest>({
      query: args => ({
        url: `summary?${summaryQueryParams(args)}`,
        headers: getHeaders(args.jwt)
      }),
      transformResponse: (response: Response<SummaryResponse>) =>
        response.result
    })
  })
});

export const {
  useGetUsersQuery,
  useGetUserGroupsQuery,
  useGetDealerTimeZoneQuery,
  useGetDetailsQuery,
  useGetSummaryQuery,
  useGetUserSettingsQuery,
  useSetUserSettingsMutation,
  useSetEmptyDetailsQuery
} = DealerDashboardApi;
