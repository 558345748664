import { BrowserRouter } from "react-router-dom";
import { Auth } from "@vinsolutions/tenant/auth";
import App from "./app/lib/App";
import { Provider } from "react-redux";
import { ErrorBoundary, FailureState } from "@vinsolutions/core/errors";
import { NewRelic } from "@vinsolutions/core/third-party/newrelic";
import { persistor, store } from "./store";
import { initialize } from "./main.helper";
import { CxThemeProvider } from "@vinsolutions/core/cx";
import { PersistGate } from "redux-persist/integration/react";
import { OidcSecure } from "@axa-fr/react-oidc-context";
import { createRoot } from "react-dom/client";
import { createLogger } from "@vinsolutions/logger";
import { getConfig } from "@vinsolutions/core/config";
import { InterstateThemeProvider } from "@interstate/components/InterstateThemeProvider";
import { interstateThemeRegistry } from "@interstate/components/InterstateTheming/themes/interstateThemeRegistry";
import { createGlobalStyle } from "styled-components";

const logger = createLogger("Main");

const config = getConfig();
logger.debug("Main: config", config);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.top.tabContextId = crypto.randomUUID();
logger.debug("Main: window.top.tabContextId", window.top.tabContextId);
const domNode = document.getElementById("root");
logger.debug("Main: domNode", domNode);

const GlobalStyle = createGlobalStyle`
  .ant-menu-submenu-popup::before {
    content: none !important;
  }
  .clear {
    clear: both;
  }
  #root {
    height: 100%;
    #CarDashboard-CX {
      height: 100%;
      .ant-layout {
        height: 100%;
        .ant-layout-content {
          position: relative;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
          #cardashboardframeContainer {
            background-color: #dddddd;
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            left: 0;
            iframe {
              border: none;
              height: 100%;
            }
            .cx-loadingIndicator {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(5, 5, 5, 0.5);
              & > div {
                align-items: center;
                height: 100%;
              }
            }
          }
        }
      }

      .Toastify__toast-container {
        width: auto;
      }
    }
  }

  .MuiPopper-root.interstate-reference {
    z-index: 100;
  }

  .version-control-toast {
    width: auto;

    .Toastify__toast {
      margin: 0;
      padding: 0;
      border: none;

      .Toastify__toast-body {
        margin: 0;
        padding: 0;

        .cx-alert {
          margin: 0;

          .cx-alert__content {
            white-space: nowrap;
          }
        }
      }

      .Toastify__progress-bar {
        margin: 0 1px 1px 1px;
        background: #2b6bdd;
      }
    }
  }
`;

createRoot(domNode).render(
  <CxThemeProvider themeName={"Cx"}>
    <InterstateThemeProvider
      applicationName="VinSolutions CRM"
      scopeName="VinSolutions CRM"
      themeName="Interstate"
      themeRegistries={[interstateThemeRegistry]}
    >
      <ErrorBoundary
        FallbackComponent={FailureState}
        onError={NewRelic.noticeError}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Auth {...{ config }}>
              <BrowserRouter basename="/vinconnect">
                <OidcSecure>
                  <App />
                  <GlobalStyle />
                </OidcSecure>
              </BrowserRouter>
            </Auth>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </InterstateThemeProvider>
  </CxThemeProvider>
);
logger.debug("Main: Initializing Main.");
initialize();
logger.debug("Main: Main initialized.");
