// eslint-disable-next-line @nx/enforce-module-boundaries
import { BaseUrlsObj, envEnum } from "@vinsolutions/core/environment";

type Config = {
  baseUrls: BaseUrlsObj;
};

export const WorkflowGatewayConfig: Config = {
  baseUrls: {
    [envEnum.LOCAL]: "https://workflow-dev.np.vinsolutions.com/api/",
    [envEnum.DEV]: "https://workflow-dev.np.vinsolutions.com/api/",
    [envEnum.QA]: "https://workflow-qa.np.vinsolutions.com/api/",
    [envEnum.STAGING]: "https://workflow-staging.np.vinsolutions.com/api/",
    [envEnum.PRODUCTION]: "https://workflow.vinsolutions.com/api/"
  }
};
