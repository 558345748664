import React, { useCallback } from "react";
import styled from "styled-components";
import { Alert, LoadingIndicator } from "@vinsolutions/core/cx";

/* eslint-disable-next-line */
export interface MetricCardProps {
  /** className for the root element */
  className?: string;
  /** number of metric columns to display */
  columnCount?: number;
  /** width of each metric used to calculate the width of columns */
  columnWidth?: string;
  /** error message to display when hasError is true */
  errorMessage?: string;
  /** displays an error message in place of metrics when true */
  hasError?: boolean;
  /** html id for the component wrapper propagated to children */
  htmlId: string;
  /** displays a loading indicator when true */
  isLoading?: boolean;
  /** list of metrics to display */
  metrics: Metric[];
  /** message to display when there are no metrics */
  noMetricsMessage?: string;
  /** height of each metric row */
  rowHeight?: string;
  /** title displayed at the top of the card */
  title?: string;
  /** count displayed next to the title */
  titleCount?: number;
}

export interface Metric {
  count: number;
  label: string;
  onClick?: () => void;
}

interface StyledMetricsProps
  extends Pick<MetricCardProps, "columnCount" | "columnWidth" | "rowHeight"> {
  rowCount: number;
}

const StyledMetricCard = styled.div`
  border: 1px solid #cacfd9;
  border-radius: 3px;
  padding: 8px;
  margin: 16px;
  position: relative;

  .metrics-card-loading-indicator {
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .cx-loadingIndicator__dots {
      height: 100%;
      align-items: center;
    }
  }
  // cx alert is immune to styled-components
  .cx-alert {
    margin-bottom: 0;
  }
`;

const StyledMetrics = styled.div<StyledMetricsProps>`
  display: grid;
  grid-template-columns: ${({ columnCount, columnWidth }) =>
    `repeat(${columnCount}, ${columnWidth})`};
  grid-template-rows: ${({ rowCount, rowHeight }) =>
    `repeat(${rowCount}, ${rowHeight})`};
`;

const StyledMetric = styled.div`
  cursor: pointer;
`;

const StyledCount = styled.span`
  color: #2153aa;
  display: block;
  text-align: center;
  line-height: 20px;
`;

const StyledLabel = styled.span`
  color: #000;
  display: block;
  text-align: center;
  line-height: 20px;
`;

const StyledTitleCount = styled.span`
  margin-left: 8px;
`;

export function MetricsCard({
  className,
  columnCount = 3,
  columnWidth = "33%",
  hasError = false,
  errorMessage = "An error occurred",
  htmlId,
  isLoading = false,
  metrics,
  noMetricsMessage = "No MetricsContainer Available",
  rowHeight = "54px",
  title,
  titleCount
}: MetricCardProps) {
  const getRows = useCallback(() => {
    return Math.ceil(metrics.length / columnCount);
  }, [columnCount, metrics.length]);
  return (
    <StyledMetricCard className={className} id={htmlId}>
      {title !== undefined && title !== null ? (
        <h4 id={`${htmlId}-title`}>
          <span id={`${htmlId}-title-text`}>{title}</span>
          {titleCount !== undefined && titleCount !== null ? (
            <StyledTitleCount id={`${htmlId}-title-count`}>
              {titleCount}
            </StyledTitleCount>
          ) : null}
        </h4>
      ) : null}
      {hasError ? (
        <Alert htmlId={`${htmlId}-error-message`} type="warning">
          {errorMessage}
        </Alert>
      ) : metrics && metrics.length > 0 ? (
        <StyledMetrics
          id={`${htmlId}-metrics-wrapper`}
          rowCount={getRows()}
          {...{ columnCount, columnWidth, rowHeight }}
        >
          {metrics.map(({ count, label, onClick }: Metric) => {
            const name = label.toLowerCase().split(" ").join("-");
            return (
              <StyledMetric
                id={`${htmlId}-${name}`}
                key={name}
                {...{ onClick }}
              >
                <StyledCount id={`${htmlId}-${name}-count`}>
                  {count}
                </StyledCount>
                <StyledLabel id={`${htmlId}-${name}-label`} title="label">
                  {label}
                </StyledLabel>
              </StyledMetric>
            );
          })}
        </StyledMetrics>
      ) : (
        <Alert htmlId={`${htmlId}-no-metrics`} type="info">
          {noMetricsMessage}
        </Alert>
      )}
      {isLoading && (
        <LoadingIndicator
          className="metrics-card-loading-indicator"
          htmlId={`${htmlId}-loading-indicator`}
        />
      )}
    </StyledMetricCard>
  );
}

export default MetricsCard;
