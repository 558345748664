import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected
} from "@reduxjs/toolkit";

import {
  clockInOrOut,
  getUserStatus,
  updateUserStatus
} from "@vinsolutions/ccrm/api";
import {
  UpdateStatusRequest,
  UserStatusEntity,
  UserStatusState
} from "@vinsolutions/ccrm/interfaces";
export const USER_STATUS_FEATURE_KEY = "userStatus";

/*
 * Update these interfaces according to your requirements.
 */

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchUserStatus())
 * }, [dispatch]);
 * ```
 */
export const fetchUserStatus = createAsyncThunk(
  "userStatus/fetchUserStatus",
  async (_, thunkAPI) => {
    const res = await getUserStatus();
    const { TimeClockEnabled, UserClockedIn, UserCurrentStatus, UserIsBusy } =
      res;
    return {
      timeClockEnabled: TimeClockEnabled,
      userClockedIn: UserClockedIn,
      userCurrentStatus: UserCurrentStatus,
      userIsBusy: UserIsBusy
    } as UserStatusEntity;
  }
);

export const setUserStatus = createAsyncThunk(
  "userStatus/setUserStatus",
  async ({ currentStatus, userIsBusy }: UpdateStatusRequest, thunkAPI) => {
    return await updateUserStatus(currentStatus, userIsBusy);
  }
);

export const toggleClockedIn = createAsyncThunk(
  "userStatus/toggleClockedIn",
  async (_, thunkAPI) => {
    return await clockInOrOut();
  }
);

export const initialUserStatusState: UserStatusState = {
  timeClockEnabled: false,
  userClockedIn: false,
  userCurrentStatus: "",
  userIsBusy: false,
  loadingStatus: "not loaded",
  error: null
};

export const userStatusSlice = createSlice({
  name: USER_STATUS_FEATURE_KEY,
  initialState: initialUserStatusState,
  reducers: {
    set: (state, action) => {
      return { ...state, ...action.payload };
    }
    // ...
  },
  extraReducers: builder => {
    builder
      .addCase(
        fetchUserStatus.fulfilled,
        (state: UserStatusState, action: PayloadAction<UserStatusEntity>) => {
          return { ...state, loadingStatus: "loaded", ...action.payload };
        }
      )
      .addCase(setUserStatus.fulfilled, (state, action) => {
        return {
          ...(state as UserStatusState),
          loadingStatus: "loaded",
          ...action.payload
        };
      })
      .addCase(toggleClockedIn.fulfilled, (state: UserStatusState, action) => {
        return {
          ...state,
          loadingStatus: "loaded",
          userClockedIn: action.payload
        };
      })
      .addMatcher(
        isPending(toggleClockedIn, setUserStatus, fetchUserStatus),
        (state: UserStatusState) => {
          state.loadingStatus = "loading";
        }
      )
      .addMatcher(
        isRejected(toggleClockedIn, setUserStatus, fetchUserStatus),
        (state, action) => {
          state.loadingStatus = "error";
          state.error = action.error.message || null;
        }
      );
  }
});

/*
 * Export reducer for store configuration.
 */
export const userStatusReducer = userStatusSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(userStatusActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const userStatusActions = userStatusSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllUserStatus);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
export const getUserStatusState = (rootState: any): UserStatusState =>
  rootState[USER_STATUS_FEATURE_KEY];
