/*  ----------------  These are test routes used for integration tests ------------------*/
/*  ----------------  Changes to these routes will affect integration tests -------------*/
import { RouteEntityType } from "@vinsolutions/ccrm/top-nav/route-config";
import { ExampleLeftPane } from "./examples/example-left-pane";
import { ExampleRightPane } from "./examples/example-right-pane";
import { ExampleFullPane } from "./examples/example-full-pane";
import { ExampleDashboard } from "./examples/example-dashboard";

export const testRouteList: RouteEntityType[] = [
  {
    routeId: "leftReactRightCdPage",
    aliasId: undefined,
    featureFlag: undefined,
    panes: {
      left: {
        component: <ExampleLeftPane />
      },
      right: {
        component: "DealerDashboard1.aspx"
      },
      full: undefined
    }
  },
  {
    routeId: "leftCdPageRightReact",
    aliasId: undefined,
    featureFlag: undefined,
    panes: {
      left: {
        component: "DealerDashboard1.aspx"
      },
      right: {
        component: <ExampleRightPane />
      },
      full: undefined
    }
  },
  {
    routeId: "leftCdPageRightCdPage",
    aliasId: undefined,
    featureFlag: undefined,
    panes: {
      left: {
        component: "DealerDashboard2.aspx"
      },
      right: {
        component: "DealerDashboard1.aspx"
      },
      full: undefined
    }
  },
  {
    routeId: "leftReactRightReact",
    aliasId: undefined,
    featureFlag: undefined,
    panes: {
      left: {
        component: <ExampleLeftPane />
      },
      right: {
        component: <ExampleRightPane />
      },
      full: undefined
    }
  },

  {
    routeId: "fullPage",
    aliasId: undefined,
    featureFlag: undefined,
    panes: {
      left: undefined,
      right: undefined,
      full: {
        component: <ExampleFullPane />
      }
    }
  },
  {
    routeId: "fullCdPage",
    aliasId: undefined,
    featureFlag: undefined,
    panes: {
      left: undefined,
      right: undefined,
      full: {
        component: "LeadManagement/DealerDashboard1.aspx"
      }
    }
  },

  {
    routeId: "leftReact",
    aliasId: undefined,
    featureFlag: undefined,
    panes: {
      left: {
        component: <ExampleLeftPane />
      },
      right: undefined,
      full: undefined
    }
  },
  {
    routeId: "rightReact",
    aliasId: undefined,
    featureFlag: undefined,
    panes: {
      left: undefined,
      right: { component: <ExampleRightPane /> },
      full: undefined
    }
  },
  {
    routeId: "rightCdPage",
    aliasId: undefined,
    featureFlag: undefined,
    panes: {
      left: undefined,
      right: {
        component: "DealerDashboard1.aspx"
      },
      full: undefined
    }
  },
  {
    routeId: "leftCdPage",
    aliasId: undefined,
    featureFlag: undefined,
    panes: {
      left: {
        component: "DealerDashboard2.aspx"
      },
      right: undefined,
      full: undefined
    }
  },
  {
    routeId: "vinconnect-dashboard",
    aliasId: "navigation-sub-menu-tab-crm-dashboard-dashboard-noop",
    featureFlag: undefined,
    panes: {
      left: {
        component: <ExampleDashboard />
      },
      right: {
        component: <ExampleDashboard />
      },
      full: undefined
    }
  }
];
